import {connect} from 'react-redux';

import * as GuildDuck from '../../../ducks/guild';

import RankSettings, {IMapProps} from './RankSettings';

interface IOwnProps {
	baseUrl: string;
	guildId: number;

	params: {
		index?: string;
	};
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const guild = GuildDuck.getGuild(state.guilds, props.guildId);
	let index = Number.parseInt(props.params.index || '', 10);

	let selectedName: string | undefined;
	if (guild && props.params.index) {
		if (index < 0 || index > 9) index = 0;

		selectedName = guild.normalRankNames[index];
	}

	return {
		isSubmitting: GuildDuck.getRankRenaming(state.guilds),

		baseUrl: `${props.baseUrl}/rank-names`,
		rankNames: guild ? guild.normalRankNames : [],
		rankName: selectedName || '',
		index
	};
}

export default connect(mapStateToProps, {
	onUpdate: GuildDuck.renameRank
})(RankSettings);
