import type * as TagDuck from '.';

import type {TagAssignment} from '../../models/tag-assignment';
// import {Tag} from '../../models/tag';

export function getTagsSubmitting(state: TagDuck.IState) {
	return state.isSubmitting;
}

export function getTagsDeleting(state: TagDuck.IState) {
	return state.isDeleting;
}

export function getTag(state: TagDuck.IState, id: number) {
	return state.tagById[id];
}

export function getTagsForGuild(
	state: TagDuck.IState,
	guildId: number | undefined,
	withoutDeleted = false
) {
	let tags = Object.values(state.tagById).filter((tag) => tag.guildId === guildId);

	if (withoutDeleted) {
		tags = tags.filter((tag) => !tag.isDeleted);
	}

	tags.sort((a, b) => b.order - a.order);

	return tags;
}

export function getAssignmentsByTagId(
	state: TagDuck.IState,
	tagId: number
): TagAssignment[] {
	return state.assignmentsByTagId[tagId] || [];
}
