/*
	HOW TO USE

	1. Add info to the comment below so there's some record of it
	2. Add that info to the `itemLevelBucketsByBonus` object
	3. Add rare item info to `rareItemBucketIndexByItemId`
	4. Run find_item_levels script in wow-static
	5. Copy script output to `tierByItemId`
	6. Do some manual inspection
*/

/*
	VAULT
	---
	NORMAL
	standard		389	395	398

	HEROIC
	standard		402	408	411

	MYTHIC
	standard		415	421	424


	ABERRUS
	---
	NORMAL
	standard		415	418	421	424
	rare			421	424	-		431

	HEROIC
	standard		428	431	434	437
	rare			434	437	-		444

	MYTHIC
	standard		441	444	447	450
	rare			447	450	-		457


	AMIRDRASSIL
	---
	NORMAL
	standard		454	457	460	463
	rare			-		463	463	470

	HEROIC
	standard		467	470	473	476
	rare			-		476	476	483

	MYTHIC
	standard		480	483	486	489
	rare			-		489	489	496

	NERUB-AR PALACE
	---
	NORMAL
	standard		597,	600,	603,	606
	rare			0,		613,	613,	613

	HEROIC
	standard		610,	613,	616,	619
	rare			0,		626,	626,	626

	MYTHIC
	standard		623,	626,	629,	632
	rare			0,		639,	639,	639
*/

const itemLevelBucketsByBonus: Record<
	string,
	| {
			standardBuckets: number[];
			rareBuckets: number[];
			wowheadPrefix: string | undefined;
	  }
	| undefined
> = {
	// Vault
	'0': {
		standardBuckets: [493, 499, 502],
		rareBuckets: [499, 505, 509],
		wowheadPrefix: undefined
	},
	'7980': {
		standardBuckets: [506, 512, 515],
		rareBuckets: [512, 518, 522],
		wowheadPrefix: undefined
	},
	'7981': {
		standardBuckets: [519, 525, 528],
		rareBuckets: [525, 531, 535],
		wowheadPrefix: undefined
	},

	// Aberrus
	'14663-normal': {
		standardBuckets: [493, 496, 499, 502],
		rareBuckets: [499, 502, 505, 509],
		wowheadPrefix: undefined
	},
	'14663-heroic': {
		standardBuckets: [506, 509, 512, 515],
		rareBuckets: [512, 515, 518, 522],
		wowheadPrefix: undefined
	},
	'14663-mythic': {
		standardBuckets: [519, 522, 525, 528],
		rareBuckets: [525, 528, 531, 535],
		wowheadPrefix: undefined
	},

	// Amirdrassil
	'1485': {
		standardBuckets: [493, 496, 499, 502],
		rareBuckets: [499, 502, 505, 509],
		wowheadPrefix: undefined
	},
	'1498': {
		standardBuckets: [506, 509, 512, 515],
		rareBuckets: [512, 515, 518, 522],
		wowheadPrefix: undefined
	},
	'1511': {
		standardBuckets: [519, 522, 525, 528],
		rareBuckets: [525, 528, 531, 535],
		wowheadPrefix: undefined
	},

	'nerub-normal': {
		standardBuckets: [597, 600, 603, 606],
		rareBuckets: [0, 613, 613, 613],
		wowheadPrefix: undefined
	},
	'nerub-heroic': {
		standardBuckets: [610, 613, 616, 619],
		rareBuckets: [0, 626, 626, 626],
		wowheadPrefix: undefined
	},
	'nerub-mythic': {
		standardBuckets: [623, 626, 629, 632],
		rareBuckets: [0, 639, 639, 639],
		wowheadPrefix: undefined
	}
};

const rareItemBucketIndexByItemId: Record<string, number | undefined> = {
	// Vault
	'195480': 0,
	'194301': 0,

	'195526': 2,
	'195527': 2,

	// Aberrus
	'202612': 0,
	'202569': 1,
	'204201': 3,
	'204211': 3,
	'204202': 3,
	'204465': 3,

	// Amirdrassil
	'210214': 1,
	'208616': 2,
	'208614': 3,
	'207171': 3,
	'207174': 3,

	// Nerub-ar Palace
	'225577': 1,
	'225574': 1,
	'219877': 2,
	'225578': 3
};

const tierByItemId: Record<string, number | undefined> = {
	// vault
	'194299': 0,
	'194300': 0,
	'194301': 0,
	'194302': 1,
	'194303': 0,
	'194304': 0,
	'194305': 1,
	'194306': 1,
	'194307': 2,
	'194308': 2,
	'194309': 2,
	'194310': 2,
	'195475': 0,
	'195476': 0,
	'195477': 0,
	'195478': 0,
	'195479': 0,
	'195480': 0,
	'195481': 1,
	'195482': 0,
	'195483': 1,
	'195484': 0,
	'195485': 0,
	'195486': 0,
	'195487': 0,
	'195488': 0,
	'195489': 0,
	'195490': 0,
	'195491': 1,
	'195492': 1,
	'195493': 1,
	'195494': 1,
	'195495': 1,
	'195496': 1,
	'195497': 0,
	'195498': 0,
	'195499': 0,
	'195500': 0,
	'195501': 0,
	'195502': 0,
	'195503': 0,
	'195504': 0,
	'195505': 0,
	'195506': 0,
	'195507': 0,
	'195508': 0,
	'195509': 0,
	'195510': 0,
	'195511': 0,
	'195512': 1,
	'195513': 1,
	'195514': 1,
	'195515': 1,
	'195516': 1,
	'195517': 1,
	'195518': 0,
	'195519': 2,
	'195520': 2,
	'195521': 2,
	'195522': 2,
	'195523': 2,
	'195524': 2,
	'195525': 2,
	'195526': 2,
	'195527': 2,
	'195528': 2,
	'195529': 2,
	'195530': 2,
	'195531': 2,
	'195532': 2,
	'195533': 2,
	'196586': 1,
	'196587': 1,
	'196588': 0,
	'196589': 2,
	'196590': 2,
	'196591': 1,
	'196592': 1,
	'196593': 0,
	'196594': 2,
	'196595': 2,
	'196596': 1,
	'196597': 1,
	'196598': 0,
	'196599': 2,
	'196600': 2,
	'196601': 1,
	'196602': 1,
	'196603': 0,
	'196604': 2,
	'196605': 2,

	// aberrus
	'202555': 2,
	'202557': 0,
	'202558': 3,
	'202559': 0,
	'202560': 2,
	'202563': 0,
	'202564': 3,
	'202565': 3,
	'202566': 1,
	'202568': 0,
	'202569': 1,
	'202570': 2,
	'202571': 1,
	'202572': 0,
	'202573': 0,
	'202574': 0,
	'202575': 1,
	'202576': 0,
	'202577': 0,
	'202578': 0,
	'202579': 0,
	'202580': 0,
	'202582': 1,
	'202583': 0,
	'202584': 3,
	'202585': 3,
	'202586': 0,
	'202587': 3,
	'202588': 1,
	'202589': 0,
	'202590': 0,
	'202591': 0,
	'202592': 1,
	'202593': 0,
	'202594': 0,
	'202595': 0,
	'202596': 0,
	'202597': 0,
	'202598': 0,
	'202599': 3,
	'202600': 0,
	'202601': 3,
	'202602': 0,
	'202603': 1,
	'202604': 0,
	'202605': 0,
	'202606': 3,
	'202607': 0,
	'202610': 2,
	'202612': 0,
	'202613': 0,
	'202614': 1,
	'202615': 0,
	'202616': 0,
	'202617': 0,
	'202618': 0,
	'202621': 3,
	'202622': 3,
	'202623': 3,
	'202624': 1,
	'202625': 1,
	'202626': 1,
	'202627': 2,
	'202628': 2,
	'202629': 2,
	'202630': 2,
	'202631': 2,
	'202632': 2,
	'202633': 2,
	'202634': 1,
	'202635': 1,
	'202636': 1,
	'202637': 3,
	'202638': 1,
	'202639': 2,
	'202640': 1,
	'202652': 1,
	'202655': 1,
	'202659': 1,
	'203714': 3,
	'203729': 1,
	'203963': 3,
	'203996': 2,
	'204201': 3,
	'204202': 3,
	'204211': 3,
	'204279': 0,
	'204318': 1,
	'204319': 1,
	'204320': 2,
	'204322': 2,
	'204324': 3,
	'204390': 3,
	'204391': 2,
	'204392': 3,
	'204393': 2,
	'204394': 2,
	'204395': 2,
	'204396': 2,
	'204397': 2,
	'204398': 3,
	'204399': 3,
	'204400': 2,
	'204424': 3,
	'204465': 3,
	'204466': 1,
	'204467': 2,
	'204696': 0,
	'204975': 2,
	'205036': 2,
	'205140': 1,
	'205144': 1,
	'205256': 0,

	// amirdrassil
	'87068': 3,
	'207115': 3,
	'207116': 2,
	'207117': 0,
	'207118': 0,
	'207119': 1,
	'207120': 0,
	'207121': 1,
	'207122': 1,
	'207123': 2,
	'207124': 3,
	'207126': 0,
	'207127': 1,
	'207128': 1,
	'207129': 2,
	'207130': 1,
	'207131': 0,
	'207132': 3,
	'207133': 0,
	'207134': 3,
	'207135': 2,
	'207137': 3,
	'207138': 1,
	'207139': 1,
	'207140': 0,
	'207141': 1,
	'207142': 0,
	'207143': 2,
	'207144': 0,
	'207145': 3,
	'207146': 1,
	'207148': 1,
	'207149': 2,
	'207150': 0,
	'207151': 1,
	'207152': 1,
	'207153': 0,
	'207154': 3,
	'207155': 2,
	'207156': 2,
	'207157': 3,
	'207159': 2,
	'207160': 0,
	'207161': 2,
	'207162': 0,
	'207163': 3,
	'207165': 0,
	'207166': 2,
	'207167': 2,
	'207168': 1,
	'207169': 0,
	'207170': 2,
	'207171': 3,
	'207172': 3,
	'207173': 1,
	'207174': 3,
	'207175': 1,
	'207462': 2,
	'207463': 2,
	'207464': 2,
	'207465': 2,
	'207466': 0,
	'207467': 0,
	'207468': 0,
	'207469': 0,
	'207470': 3,
	'207471': 3,
	'207472': 3,
	'207473': 3,
	'207474': 2,
	'207475': 2,
	'207476': 2,
	'207477': 2,
	'207478': 2,
	'207479': 2,
	'207480': 2,
	'207481': 2,
	'207780': 3,
	'207781': 3,
	'207782': 1,
	'207783': 0,
	'207784': 1,
	'207785': 1,
	'207786': 3,
	'207787': 0,
	'207788': 3,
	'207789': 1,
	'207790': 2,
	'207791': 2,
	'207792': 2,
	'207793': 3,
	'207794': 0,
	'207795': 3,
	'207796': 1,
	'207797': 0,
	'207798': 2,
	'207799': 2,
	'207800': 0,
	'208445': 2,
	'208614': 3,
	'208615': 2,
	'208616': 2,
	'210203': 2,
	'210204': 2,
	'210205': 1,
	'210206': 1,
	'210214': 1,

	// nerub-ar palace
	'212386': 0,
	'212387': 2,
	'212388': 0,
	'212389': 2,
	'212391': 1,
	'212392': 1,
	'212394': 3,
	'212395': 0,
	'212397': 3,
	'212398': 1,
	'212399': 1,
	'212400': 2,
	'212401': 3,
	'212404': 0,
	'212405': 1,
	'212407': 3,
	'212409': 0,
	'212413': 1,
	'212414': 0,
	'212415': 1,
	'212416': 1,
	'212417': 0,
	'212418': 2,
	'212419': 0,
	'212420': 3,
	'212421': 0,
	'212422': 0,
	'212423': 0,
	'212424': 0,
	'212425': 0,
	'212426': 0,
	'212427': 1,
	'212428': 0,
	'212429': 3,
	'212430': 0,
	'212431': 0,
	'212432': 3,
	'212433': 3,
	'212434': 3,
	'212435': 3,
	'212436': 3,
	'212437': 1,
	'212438': 0,
	'212439': 0,
	'212440': 1,
	'212441': 2,
	'212442': 0,
	'212443': 3,
	'212444': 3,
	'212445': 1,
	'212446': 0,
	'212447': 0,
	'212448': 1,
	'212449': 1,
	'212450': 3,
	'212451': 0,
	'212452': 2,
	'212453': 1,
	'212454': 3,
	'212456': 2,
	'219313': 1,
	'219877': 2,
	'219915': 0,
	'219917': 0,
	'220202': 3,
	'220305': 2,
	'221023': 2,
	'221143': 1,
	'221144': 1,
	'221145': 1,
	'221146': 1,
	'221147': 1,
	'221148': 1,
	'221149': 1,
	'223048': 2,
	'223097': 1,
	'224435': 1,
	'225574': 1,
	'225575': 3,
	'225576': 2,
	'225577': 1,
	'225578': 3,
	'225579': 3,
	'225580': 2,
	'225581': 2,
	'225582': 2,
	'225583': 1,
	'225584': 3,
	'225585': 3,
	'225586': 1,
	'225587': 3,
	'225588': 2,
	'225589': 2,
	'225590': 0,
	'225591': 2,
	'225614': 2,
	'225615': 2,
	'225616': 2,
	'225617': 2,
	'225618': 1,
	'225619': 1,
	'225620': 1,
	'225621': 1,
	'225622': 3,
	'225623': 3,
	'225624': 3,
	'225625': 3,
	'225626': 2,
	'225627': 2,
	'225628': 2,
	'225629': 2,
	'225630': 1,
	'225631': 1,
	'225632': 1,
	'225633': 1,
	'225634': 3,
	'225636': 2
};

export function getItemLevel(data: {itemId: string; bonus: string}): number | undefined {
	const itemLevelBucket = itemLevelBucketsByBonus[data.bonus];
	if (!itemLevelBucket) return undefined;

	const rareItemBucketIndex = rareItemBucketIndexByItemId[data.itemId];
	if (rareItemBucketIndex !== undefined) {
		return itemLevelBucket.rareBuckets[rareItemBucketIndex]; // may be undefined
	}

	const itemBucketIndex = tierByItemId[data.itemId];
	if (itemBucketIndex === undefined) return undefined;

	return itemLevelBucket.standardBuckets[itemBucketIndex];
}

export function getWowheadPrefix(bonus: string | undefined): string | undefined {
	return (
		(bonus && itemLevelBucketsByBonus[bonus]?.wowheadPrefix) || 'http://wowhead.com'
	);
}
