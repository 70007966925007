import React from 'react';

import type {BossViewType} from '@constants';

import {connect, CooldownSheetDuck, CooldownEventDuck, CooldownActionDuck} from '@ducks';

import {usePrevious} from '@hooks/usePrevious';

import type {RosterBoss} from '@models/roster-boss';
import type {IRaidBoss} from '@models/raid-data';
import type {CooldownSheet} from '@models/cooldownSheet';

import {Loading} from '@components/Utils/Loading';
import {BossView} from '../BossElements';
import {BossHeading} from './BossHeading';
import {Content} from './Content';

/** Load CooldownSheet data for a given RosterBoss ID */
function useFetchCooldownSheet({
	onFetchCooldownSheet,
	rosterBossId,
	cooldownSheet
}: {
	onFetchCooldownSheet: (rosterBossId: RosterBossId) => void;
	cooldownSheet: CooldownSheet | undefined;
	rosterBossId: RosterBossId;
}): void {
	const prev = usePrevious(rosterBossId);

	React.useEffect((): void => {
		// don't try fetch if we've already got the sheet
		if (cooldownSheet) return;

		// don't try refetch if we're on the same boss
		if (rosterBossId === prev) return;

		onFetchCooldownSheet(rosterBossId);
	}, [rosterBossId, prev, onFetchCooldownSheet, cooldownSheet]);
}

interface OwnProps {
	onFetchCooldownSheet: BoundThunk<
		typeof CooldownSheetDuck['fetchCooldownSheetForRosterBoss']
	>;
	onCreateCooldownEvent: BoundThunk<typeof CooldownEventDuck['createCooldownEvent']>;
	onUpdateCooldownEventName: BoundThunk<
		typeof CooldownEventDuck['updateNameCooldownEvent']
	>;
	onUpdateCooldownEventTime: BoundThunk<
		typeof CooldownEventDuck['updateTimeCooldownEvent']
	>;
	onReorderCooldownEvent: BoundThunk<typeof CooldownEventDuck['reorderCooldownEvent']>;
	onDeleteCooldownEvent: BoundThunk<typeof CooldownEventDuck['deleteCooldownEvent']>;
	onCreateCooldownAction: BoundThunk<typeof CooldownActionDuck['createCooldownAction']>;
	onUpdateCooldownActionActor: BoundThunk<
		typeof CooldownActionDuck['updateActorCooldownAction']
	>;
	onUpdateCooldownActionAbility: BoundThunk<
		typeof CooldownActionDuck['updateAbilityCooldownAction']
	>;
	onDeleteCooldownAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;
	onChangeView(view: BossViewType): void;

	isAdmin: boolean;
	isEditor: boolean;

	viewType: BossViewType;

	rosterBoss: RosterBoss;
	raidBoss: IRaidBoss;
}

interface MapProps {
	isFetchingCooldownSheet: boolean;

	cooldownSheet: CooldownSheet | undefined;
}

type Props = OwnProps & MapProps;

const BossCooldownViewComp: React.FC<Props> = (props) => {
	useFetchCooldownSheet({
		onFetchCooldownSheet: props.onFetchCooldownSheet,
		cooldownSheet: props.cooldownSheet,
		rosterBossId: props.rosterBoss.id
	});

	let content: React.ReactNode = null;
	if (props.cooldownSheet) {
		content = (
			<Content
				onCreateEvent={props.onCreateCooldownEvent}
				onUpdateEventName={props.onUpdateCooldownEventName}
				onUpdateEventTime={props.onUpdateCooldownEventTime}
				onReorderEvent={props.onReorderCooldownEvent}
				onDeleteEvent={props.onDeleteCooldownEvent}
				onCreateAction={props.onCreateCooldownAction}
				onUpdateActionActor={props.onUpdateCooldownActionActor}
				onUpdateActionAbility={props.onUpdateCooldownActionAbility}
				onDeleteAction={props.onDeleteCooldownAction}
				isEditor={props.isEditor}
				rosterBossId={props.rosterBoss.id}
				cooldownSheet={props.cooldownSheet}
			/>
		);
	} else if (props.isFetchingCooldownSheet) {
		content = <Loading className="sheet-is-loading" isSmall={true} />;
	}

	return (
		<BossView viewName="boss-cooldown-view">
			<BossHeading
				onChangeView={props.onChangeView}
				isAdmin={props.isAdmin}
				cooldownSheetId={props.cooldownSheet?.id}
				viewType={props.viewType}
				bossName={props.raidBoss.name}
			/>

			{content}
		</BossView>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const cooldownSheet = props.rosterBoss.cooldownSheetId
		? CooldownSheetDuck.getCooldownSheetForId(
				state.cooldownSheets,
				props.rosterBoss.cooldownSheetId
		  )
		: undefined;

	return {
		isFetchingCooldownSheet: CooldownSheetDuck.getIsFetchingForRosterBossId(
			state.cooldownSheets,
			props.rosterBoss.id
		),

		cooldownSheet
	};
}

export const BossCooldownView = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(BossCooldownViewComp);
