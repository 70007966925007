import React from 'react';

interface IProps {
	name?: string;
}

export default function IsAlreadySetup(props: IProps) {
	return (
		<div className="page-container">
			<div className="content-card card guild-already-setup">
				<div className="heading">
					<i className="red lnr lnr-sad" />

					<div>
						Your character hasn't been added to <b>{props.name}</b> yet
					</div>
				</div>

				<div className="content">
					<div className="description">
						<p>To gain access, please:</p>

						<ol>
							<li>
								Contact one of your guild's leadership team (e.g. your Guild
								Master or an officer)
							</li>

							<li>
								Ask them to add your character to the guild at{' '}
								<span className="directions">
									Readycheck.io {'>'} Guild Settings {'>'} Import Characters
								</span>
							</li>

							<li>Refresh this page after they've added your character</li>
						</ol>

						<p className="help">
							<span>If the problem persists please head over to the </span>
							<a
								href="https://readycheck.io/contact"
								target="_blank"
								rel="noreferrer noopener"
								className="styled-link"
							>
								contact page
							</a>
							<span> and reach out to us and we'll see what we can do.</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
