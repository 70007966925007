import React from 'react';
import {Link} from 'react-router';

import {RegionPicker} from './RegionPicker';
import Support from '../Utils/Support';

interface IProps {
	onRegionSelect(region: string): void;
	onRegionClose(): void;
	onConnect(): void;
	isSelectingRegion: boolean;
	btag: string | undefined;
}

export const SharingDisabled: React.FC<IProps> = (props) => {
	const accountText = props.btag || 'your account';

	return (
		<div className="page-container">
			<Support />

			{props.isSelectingRegion && (
				<RegionPicker
					onClose={props.onRegionClose}
					onClick={props.onRegionSelect}
					isCacheBust={false}
				/>
			)}

			<div className="auth-page">
				<div className="auth-content card">
					<div className="heading">Issues syncing your characters</div>

					<div className="content">
						<div className="description">
							<p>
								<span className="brand-name">Readycheck</span> was unable to sync
								the characters for <b>{accountText}</b>.
							</p>

							<p>
								If you've disabled sharing your game data with community
								developers then that would prevent{' '}
								<span className="brand-name">Readycheck</span> from performing the
								character sync.
							</p>

							<p>
								Please follow these steps to check to see if sharing is disabled
								and enable it again if so:
							</p>

							<ol>
								<li>
									<span>Sign in to </span>
									<a
										href="https://blizzard.com"
										target="_blank"
										rel="noreferrer noopener"
									>
										https://blizzard.com
									</a>
									<span> and go to "Account Settings"</span>
								</li>

								<li>Click on "Privacy" in the left side menu</li>

								<li>Scroll down to "GAME DATA AND PROFILE PRIVACY"</li>

								<li>
									"Share my game data with community developers" should be
									"Enabled", otherwise update it if it's disabled
									<br />
									Note: It may take a few minutes for Blizzard to restore the
									sharing ability for your account
								</li>

								<li>
									<Link to="/connect">Reconnect</Link>
									<span> your account to </span>
									<span className="brand-name">Readycheck</span>
								</li>
							</ol>

							<p>
								<span>If you get stuck, head over to the </span>
								<a
									href="https://readycheck.io/contact"
									target="_blank"
									rel="noreferrer noopener"
								>
									contact page
								</a>
								<span>
									{' '}
									and reach out to us and and we'll see what we can do.
								</span>
							</p>
						</div>

						<div
							onClick={props.onConnect}
							className="auth-button large primary faded button"
						>
							Try again
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
