import type {
	IFetchAllForUserInput,
	IFetchAllForUserResult,
	IFetchGuildPayloadInput,
	IFetchGuildPayloadResult,
	IRenameRankInput,
	IRenameRankResult,
	IAdminRankInput,
	IAdminRankResult,
	IAutoInviteRankInput,
	IAutoInviteRankResult,
	IAdminImporterInput,
	IAdminImporterResult,
	IRosterAdminOnlyInput,
	IRosterAdminOnlyResult,
	ILootSheetAdminOnlyInput,
	ILootSheetAdminOnlyResult,
	AddUserAdminInput,
	AddUserAdminResult,
	RemoveUserAdminInput,
	RemoveUserAdminResult
} from 'api-types';

import * as rpc from '../../../shared/constants/rpc';

import * as toolbox from '../../helpers/toolbox';
import * as LS from '../../helpers/localstorage';
import api from '../../helpers/api';

import {BannerDuck, GuildDuck, CharacterDuck} from '@ducks';

import {Character} from '../../models/character';
import {LootSelection} from '../../models/loot-selection';
import {LootOption} from '../../models/loot-option';
import {LootCoin} from '../../models/loot-coin';
import {RosterRoleAssignment} from '../../models/roster-role-assignment';
import {RosterBoss} from '../../models/roster-boss';
import {Roster} from '../../models/roster';
import {GuildSponsorship} from '../../models/guildSponsorship';
import {Guild} from '../../models/guild';
import type {User} from '../../models/user';
import {TagAssignment} from '../../models/tag-assignment';
import {Tag} from '../../models/tag';
import {DroptimizerReport} from '../../models/droptimizerReport';

export function setActive(user?: User, id?: number) {
	if (user && id) {
		LS.setItem(`${user.id}.activeGuildId`, String(id));
	}

	const action: GuildDuck.IActions['SET_ACTIVE'] = {
		type: GuildDuck.SET_ACTIVE,
		payload: {id}
	};

	return action;
}

export function fetchGuilds(): Thunk<void> {
	return (dispatch) => {
		const action: GuildDuck.IActions['FETCH'] = {type: GuildDuck.FETCH};
		dispatch(action);

		return api
			.call<IFetchAllForUserInput, IFetchAllForUserResult>(rpc.GUILDS_FETCH_ALL, {})
			.then(
				(message) => {
					const successAction: GuildDuck.IActions['FETCH_SUCCESS'] = {
						type: GuildDuck.FETCH_SUCCESS,
						payload: {
							guilds: message.data.guilds.map((guild: any) => new Guild(guild))
						}
					};

					dispatch(successAction);
				},

				(message) => {
					const failureAction: GuildDuck.IActions['FETCH_FAILURE'] = {
						type: GuildDuck.FETCH_FAILURE
					};

					dispatch(failureAction);
					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

export function fetchGuildData(id: number): Thunk<void> {
	return (dispatch) => {
		const action: GuildDuck.IActions['FETCH_DATA'] = {type: GuildDuck.FETCH_DATA};
		dispatch(action);

		return api
			.call<IFetchGuildPayloadInput, IFetchGuildPayloadResult>(rpc.GUILDS_FETCH_DATA, {
				id
			})
			.then(
				(message) => {
					const successAction: GuildDuck.IActions['FETCH_DATA_SUCCESS'] = {
						type: GuildDuck.FETCH_DATA_SUCCESS,
						payload: {
							characters: message.data.characters.map(
								(c: any) => new Character(c)
							),

							guildSponsorships: message.data.guildSponsorships.map(
								(x) => new GuildSponsorship(x)
							),

							lootSelections: message.data.lootSelections.map(
								(s: any) => new LootSelection(s)
							),
							lootOptions: message.data.lootOptions.map((o) => new LootOption(o)),
							lootCoins: message.data.lootCoins.map((c: any) => new LootCoin(c)),

							tagAssignments: message.data.tagAssignments.map(
								(x) => new TagAssignment(x)
							),
							tags: message.data.tags.map((x) => new Tag(x)),

							rosterBosses: message.data.rosterBosses.map(
								(b: any) => new RosterBoss(b)
							),
							rosters: message.data.rosters.map((r) => new Roster(r)),
							rosterRoleAssignments: message.data.rosterRoleAssignments.map(
								(a) => new RosterRoleAssignment(a)
							),

							droptimizerReports: message.data.droptimizerReports.map(
								(x) => new DroptimizerReport(x)
							)
						}
					};

					dispatch(successAction);
				},

				(message) => {
					const failureAction: GuildDuck.IActions['FETCH_DATA_FAILURE'] = {
						type: GuildDuck.FETCH_DATA_FAILURE
					};

					dispatch(failureAction);
					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

interface IRenameRank {
	index: number;
	name: string;
}

export function renameRank(data: IRenameRank): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);
		if (!guildId) return Promise.reject();

		dispatch<GuildDuck.IActions['RANK_RENAME']>({type: GuildDuck.RANK_RENAME});

		return api
			.call<IRenameRankInput, IRenameRankResult>(rpc.GUILD_RANK_RENAME, {
				index: data.index,
				name: data.name,
				id: guildId
			})
			.then(
				() => {
					dispatch<GuildDuck.IActions['RANK_RENAME_SUCCESS']>({
						type: GuildDuck.RANK_RENAME_SUCCESS
					});
				},

				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<GuildDuck.IActions['RANK_RENAME_FAILURE']>({
						type: GuildDuck.RANK_RENAME_FAILURE
					});
				}
			);
	};
}

export function updateAdminRank(index: number, isAdmin: boolean): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds)!;
		const guild = GuildDuck.getGuild(state.guilds, guildId);

		const optimisticId = toolbox.optimistic.getId();
		dispatch<GuildDuck.IActions['ADMIN_RANK']>({
			...toolbox.optimistic.begin(optimisticId),
			type: GuildDuck.ADMIN_RANK,
			payload: {
				guild: new Guild({
					...guild,
					adminRanks: guild.adminRanks.map((v, i) => (i === index ? isAdmin : v))
				})
			}
		});

		return api
			.call<IAdminRankInput, IAdminRankResult>(rpc.GUILD_ADMIN_RANK, {
				id: guildId,
				isAdmin,
				index
			})
			.then(
				() => {
					dispatch<GuildDuck.IActions['ADMIN_RANK_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: GuildDuck.ADMIN_RANK_SUCCESS
					});
				},

				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<GuildDuck.IActions['ADMIN_RANK_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: GuildDuck.ADMIN_RANK_FAILURE
					});
				}
			);
	};
}

export function updateAutoInviteRank(index: number, isAutoInvite: boolean): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds)!;
		const guild = GuildDuck.getGuild(state.guilds, guildId);

		const optimisticId = toolbox.optimistic.getId();

		dispatch<GuildDuck.IActions['AUTO_INVITE_RANK']>({
			...toolbox.optimistic.begin(optimisticId),
			type: GuildDuck.AUTO_INVITE_RANK,
			payload: {
				guild: new Guild({
					...guild,
					autoInviteRanks: guild.autoInviteRanks.map((v, i) =>
						i === index ? isAutoInvite : v
					)
				})
			}
		});

		return api
			.call<IAutoInviteRankInput, IAutoInviteRankResult>(rpc.GUILD_RANK_AUTO_INVITE, {
				id: guildId,
				isAutoInvite,
				index
			})
			.then(
				() => {
					dispatch<GuildDuck.IActions['AUTO_INVITE_RANK_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: GuildDuck.AUTO_INVITE_RANK_SUCCESS
					});
				},

				(message) => {
					dispatch<GuildDuck.IActions['AUTO_INVITE_RANK_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: GuildDuck.AUTO_INVITE_RANK_FAILURE
					});

					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

/** Adds a new user admin to a guild */
export function addUserAdmin(data: {characterId: CharacterId}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();
		const guild = GuildDuck.getActiveGuild(state.guilds);
		if (!guild) return Promise.reject();

		const character = CharacterDuck.getCharacterForId(
			state.characters,
			data.characterId
		);
		if (!character || !character.userId) return Promise.reject();

		const optimisticId = toolbox.optimistic.getId();
		dispatchEvent<GuildDuck.IActions['USER_ADMIN_ADD']>({
			...toolbox.optimistic.begin(optimisticId),
			type: GuildDuck.USER_ADMIN_ADD,
			payload: {
				guild: new Guild({
					...guild,

					// add in the character's user id
					adminUserIds: toolbox.dedupeArray([
						...guild.adminUserIds,
						character.userId
					])
				})
			}
		});

		return api
			.call<AddUserAdminInput, AddUserAdminResult>(rpc.GUILD_USER_ADMIN_ADD, {
				guildId: guild.id,
				characterId: data.characterId
			})
			.then(
				() => {
					dispatchEvent<GuildDuck.IActions['USER_ADMIN_ADD_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: GuildDuck.USER_ADMIN_ADD_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<GuildDuck.IActions['USER_ADMIN_ADD_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: GuildDuck.USER_ADMIN_ADD_FAILURE
					});
				}
			);
	};
}

/** Removes an existing user admin from a guild */
export function removeUserAdmin(data: {adminUserId: UserId}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();
		const guild = GuildDuck.getActiveGuild(state.guilds);
		if (!guild) return Promise.reject();

		const optimisticId = toolbox.optimistic.getId();
		dispatchEvent<GuildDuck.IActions['USER_ADMIN_REMOVE']>({
			...toolbox.optimistic.begin(optimisticId),
			type: GuildDuck.USER_ADMIN_REMOVE,
			payload: {
				guild: new Guild({
					...guild,

					// remove the user id
					adminUserIds: guild.adminUserIds.filter((x) => x !== data.adminUserId)
				})
			}
		});

		return api
			.call<RemoveUserAdminInput, RemoveUserAdminResult>(rpc.GUILD_USER_ADMIN_REMOVE, {
				guildId: guild.id,
				adminUserId: data.adminUserId
			})
			.then(
				() => {
					dispatchEvent<GuildDuck.IActions['USER_ADMIN_REMOVE_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: GuildDuck.USER_ADMIN_REMOVE_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<GuildDuck.IActions['USER_ADMIN_REMOVE_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: GuildDuck.USER_ADMIN_REMOVE_FAILURE
					});
				}
			);
	};
}

export function updateAdminImporter(isAdmin: boolean): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds)!;
		const guild = GuildDuck.getGuild(state.guilds, guildId);

		const optimisticId = toolbox.optimistic.getId();
		dispatch<GuildDuck.IActions['ADMIN_IMPORTER']>({
			...toolbox.optimistic.begin(optimisticId),
			type: GuildDuck.ADMIN_IMPORTER,
			payload: {
				guild: new Guild({
					...guild,
					isImporterAdmin: isAdmin
				})
			}
		});

		return api
			.call<IAdminImporterInput, IAdminImporterResult>(rpc.GUILD_ADMIN_IMPORTER, {
				id: guildId,
				isAdmin
			})
			.then(
				() => {
					dispatch<GuildDuck.IActions['ADMIN_IMPORTER_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: GuildDuck.ADMIN_IMPORTER_SUCCESS
					});
				},

				(message) => {
					dispatch<GuildDuck.IActions['ADMIN_IMPORTER_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: GuildDuck.ADMIN_IMPORTER_FAILURE
					});

					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

export function updateRosterAdminOnly(data: {
	isRosterAssignmentsAdminOnly?: boolean | undefined;
	isRosterNotesAdminOnly?: boolean | undefined;
	isRosterCooldownsAdminOnly?: boolean | undefined;
}): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds)!;
		const guild = GuildDuck.getGuild(state.guilds, guildId);

		const optimisticId = toolbox.optimistic.getId();
		dispatch<GuildDuck.IActions['ROSTER_ADMIN_ONLY']>({
			...toolbox.optimistic.begin(optimisticId),
			type: GuildDuck.ROSTER_ADMIN_ONLY,
			payload: {
				guild: new Guild({
					...guild,
					isRosterAssignmentsAdminOnly:
						data.isRosterAssignmentsAdminOnly ?? guild.isRosterAssignmentsAdminOnly,
					isRosterNotesAdminOnly:
						data.isRosterNotesAdminOnly ?? guild.isRosterNotesAdminOnly,
					isRosterCooldownsAdminOnly:
						data.isRosterCooldownsAdminOnly ?? guild.isRosterCooldownsAdminOnly
				})
			}
		});

		return api
			.call<IRosterAdminOnlyInput, IRosterAdminOnlyResult>(
				rpc.GUILD_ROSTER_ADMIN_ONLY,
				{
					id: guildId,
					isRosterAssignmentsAdminOnly: data.isRosterAssignmentsAdminOnly,
					isRosterCooldownsAdminOnly: data.isRosterCooldownsAdminOnly,
					isRosterNotesAdminOnly: data.isRosterNotesAdminOnly
				}
			)
			.then(
				() => {
					dispatch<GuildDuck.IActions['ROSTER_ADMIN_ONLY_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: GuildDuck.ROSTER_ADMIN_ONLY_SUCCESS
					});
				},

				(message) => {
					dispatch<GuildDuck.IActions['ROSTER_ADMIN_ONLY_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: GuildDuck.ROSTER_ADMIN_ONLY_FAILURE
					});

					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

export function updateLootSheetAdminOnly(isLootSheetAdminOnly: boolean): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds)!;
		const guild = GuildDuck.getGuild(state.guilds, guildId);

		const optimisticId = toolbox.optimistic.getId();
		dispatch<GuildDuck.IActions['LOOTSHEET_ADMIN_ONLY']>({
			...toolbox.optimistic.begin(optimisticId),
			type: GuildDuck.LOOTSHEET_ADMIN_ONLY,
			payload: {
				guild: new Guild({
					...guild,
					isLootSheetAdminOnly
				})
			}
		});

		return api
			.call<ILootSheetAdminOnlyInput, ILootSheetAdminOnlyResult>(
				rpc.GUILD_LOOTSHEET_ADMIN_ONLY,
				{
					id: guildId,
					isLootSheetAdminOnly
				}
			)
			.then(
				() => {
					dispatch<GuildDuck.IActions['LOOTSHEET_ADMIN_ONLY_SUCCESS']>({
						...toolbox.optimistic.commit(optimisticId),
						type: GuildDuck.LOOTSHEET_ADMIN_ONLY_SUCCESS
					});
				},

				(message) => {
					dispatch<GuildDuck.IActions['LOOTSHEET_ADMIN_ONLY_FAILURE']>({
						...toolbox.optimistic.revert(optimisticId),
						type: GuildDuck.LOOTSHEET_ADMIN_ONLY_FAILURE
					});

					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}
