import {browserHistory as router} from 'react-router';

import type {
	IUserBumpActiveInput,
	IUserBumpActiveResult,
	IFetchUserResult,
	UserUpdateRosterRedesignViewedHelpInput,
	UserUpdateRosterRedesignViewedHelpResult,
	UserUpdateRosterViewVersionInput,
	UserUpdateRosterViewVersionResult
} from 'api-types';

import type {RosterViewVersion} from '../constants';
import * as rpc from '../../shared/constants/rpc';
import * as feed from '../constants/feed';

import {loadRecorder} from '../helpers/recorder';
import {signOutUrl} from '../helpers/urls';
import api from '../helpers/api';

import type {RootAction} from '.';

import {GuildSponsorship} from '../models/guildSponsorship';
import {UserBilling} from '../models/userBilling';
import {User} from '../models/user';

export interface IState {
	readonly user?: User;

	readonly isLoading: boolean;

	readonly urlBeforeSettings?: string;
}

// types
export const FETCH = 'user/FETCH';
export const FETCH_SUCCESS = 'user/FETCH_SUCCESS';
const FETCH_FAILURE = 'user/FETCH_FAILURE';

const ROSTER_VIEW_VERSION = 'user/ROSTER_VIEW_VERSION';
const ROSTER_VIEW_VERSION_SUCCESS = 'user/ROSTER_VIEW_VERSION_SUCCESS';
const ROSTER_VIEW_VERSION_FAILURE = 'user/ROSTER_VIEW_VERSION_FAILURE';

const VIEWED_ROSTER_REDESIGN_HELP = 'user/VIEWED_ROSTER_REDESIGN_HELP';
const VIEWED_ROSTER_REDESIGN_HELP_SUCCESS = 'user/VIEWED_ROSTER_REDESIGN_HELP_SUCCESS';
const VIEWED_ROSTER_REDESIGN_HELP_FAILURE = 'user/VIEWED_ROSTER_REDESIGN_HELP_FAILURE';

const SET_URL = 'user/SET_URL';

export interface IActions {
	FETCH: {
		readonly type: typeof FETCH;
	};

	FETCH_SUCCESS: {
		readonly type: typeof FETCH_SUCCESS;
		readonly payload: {
			readonly user: User;
			readonly billing: UserBilling | undefined;
			readonly guildSponsorships: GuildSponsorship[];
		};
	};

	FETCH_FAILURE: {
		readonly type: typeof FETCH_FAILURE;
	};

	SET_URL: {
		readonly type: typeof SET_URL;
		readonly payload: {
			readonly url: string;
		};
	};

	ROSTER_VIEW_VERSION: {
		readonly type: typeof ROSTER_VIEW_VERSION;
		readonly payload: {
			readonly rosterViewVersion: RosterViewVersion;
		};
	};
	ROSTER_VIEW_VERSION_SUCCESS: {readonly type: typeof ROSTER_VIEW_VERSION_SUCCESS};
	ROSTER_VIEW_VERSION_FAILURE: {readonly type: typeof ROSTER_VIEW_VERSION_FAILURE};

	VIEWED_ROSTER_REDESIGN_HELP: {readonly type: typeof VIEWED_ROSTER_REDESIGN_HELP};
	VIEWED_ROSTER_REDESIGN_HELP_SUCCESS: {
		readonly type: typeof VIEWED_ROSTER_REDESIGN_HELP_SUCCESS;
	};
	VIEWED_ROSTER_REDESIGN_HELP_FAILURE: {
		readonly type: typeof VIEWED_ROSTER_REDESIGN_HELP_FAILURE;
	};
}

// selectors
export function getUser(state: IState) {
	return state.user;
}

/** **Must** only be used when the user is actually logged in */
export function getActiveUser(state: IState): User {
	return state.user!;
}

export function getUserLoading(state: IState) {
	return state.isLoading;
}

export function getUrl(state: IState) {
	return state.urlBeforeSettings || '/select-guild';
}

// actions
export function fetchUser(): Thunk<void> {
	return (dispatch, getState) => {
		dispatch({type: FETCH});

		return api
			.getHttp<IFetchUserResult>(rpc.USER_FETCH)
			.then(({error, data}) => {
				if (error) {
					dispatch<IActions['FETCH_FAILURE']>({
						type: FETCH_FAILURE
					});

					router.push('/connect');
					return;
				}

				if (data) {
					const user = new User(data.user);

					if (window.Raven) {
						window.Raven.setUserContext({
							bnetBattleTag: user.bnetBattleTag,
							id: user.id
						});
					}

					dispatch<IActions['FETCH_SUCCESS']>({
						type: FETCH_SUCCESS,
						payload: {
							guildSponsorships: data.guildSponsorships.map(
								(x) => new GuildSponsorship(x)
							),
							billing: data.billing ? new UserBilling(data.billing) : undefined,
							user
						}
					});

					api.connect();

					if (data.useRecording) loadRecorder(user);

					const userBumpInterval = window.setInterval(() => {
						const currentState = getState();
						const currentUser = getUser(currentState.user);

						if (!currentUser || currentUser.id !== user.id) {
							window.clearInterval(userBumpInterval);
							return;
						}

						api.call<IUserBumpActiveInput, IUserBumpActiveResult>(
							rpc.USER_BUMP,
							{}
						).then(
							() => console.log('bumped active date'),
							() => console.log('failed to bump active date')
						);
					}, 1000 * 60 * 60);
				}
			})
			.catch(() => {
				const failureAction: IActions['FETCH_FAILURE'] = {
					type: FETCH_FAILURE
				};

				dispatch(failureAction);
				router.push('/connect');
			});
	};
}

export function setUrl(url: string) {
	const action: IActions['SET_URL'] = {
		type: SET_URL,
		payload: {url}
	};

	return action;
}

export function signIn(region: string) {
	window.location.href = `/api/auth/bnet?region=${region}`;
}

export function signOut() {
	window.location.href = signOutUrl();
}

export function updateRosterViewVersion(
	rosterViewVersion: RosterViewVersion
): Thunk<void> {
	return (dispatch) => {
		dispatch<IActions['ROSTER_VIEW_VERSION']>({
			type: ROSTER_VIEW_VERSION,
			payload: {
				rosterViewVersion
			}
		});

		return api
			.call<UserUpdateRosterViewVersionInput, UserUpdateRosterViewVersionResult>(
				rpc.USER_UPDATE_ROSTER_VERSION,
				{
					rosterViewVersion
				}
			)
			.then(
				() => {
					dispatch<IActions['ROSTER_VIEW_VERSION_SUCCESS']>({
						type: ROSTER_VIEW_VERSION_SUCCESS
					});
				},
				() => {
					dispatch<IActions['ROSTER_VIEW_VERSION_FAILURE']>({
						type: ROSTER_VIEW_VERSION_FAILURE
					});
				}
			);
	};
}

export function viewedRosterRedesignHelp(): Thunk<void> {
	return (dispatch) => {
		dispatch<IActions['VIEWED_ROSTER_REDESIGN_HELP']>({
			type: VIEWED_ROSTER_REDESIGN_HELP
		});

		return api
			.call<
				UserUpdateRosterRedesignViewedHelpInput,
				UserUpdateRosterRedesignViewedHelpResult
			>(rpc.USER_VIEWED_ROSTER_REDESIGN_HELP, {})
			.then(
				() => {
					dispatch<IActions['VIEWED_ROSTER_REDESIGN_HELP_SUCCESS']>({
						type: VIEWED_ROSTER_REDESIGN_HELP_SUCCESS
					});
				},
				() => {
					dispatch<IActions['VIEWED_ROSTER_REDESIGN_HELP_FAILURE']>({
						type: VIEWED_ROSTER_REDESIGN_HELP_FAILURE
					});
				}
			);
	};
}

// reducer
const initialState: IState = {
	user: undefined,
	urlBeforeSettings: undefined,

	isLoading: false
};

export default function reducer(
	state: IState = initialState,
	action: RootAction
): IState {
	switch (action.type) {
		// pre settings url
		case SET_URL: {
			return {
				...state,
				urlBeforeSettings: action.payload.url
			};
		}

		// fetch
		case FETCH: {
			return {...state, isLoading: true};
		}

		case FETCH_SUCCESS: {
			return {
				...state,
				user: action.payload.user,
				isLoading: false
			};
		}

		case FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			};
		}

		// update roster version
		case ROSTER_VIEW_VERSION: {
			return {
				...state,
				user: state.user
					? new User({
							...state.user,
							rosterViewVersion: action.payload.rosterViewVersion
					  })
					: state.user
			};
		}

		// hide roster redesign help
		case VIEWED_ROSTER_REDESIGN_HELP: {
			return {
				...state,
				user: state.user
					? new User({
							...state.user,
							hasViewedRosterRedesignHelp: true
					  })
					: state.user
			};
		}

		// feed
		case feed.USER_UPDATE: {
			return {
				...state,
				user: new User(action.payload.newRecord)
			};
		}

		default:
			return state;
	}
}
