import type {Dispatch} from 'redux';

import * as rpc from '../../shared/constants/rpc';
import {RAID_ITEM_SORT_ORDER_BY_SLOT, RaidGroupId} from '@constants/wow';

import * as LS from '../helpers/localstorage';
const {LocalStorageKeys} = LS;
import api, {demoAPI} from '../helpers/api';

import * as BannerDuck from './banner';
import type {RootAction} from '.';

import {RaidInstance, RaidItem} from '../models/raid-data';

export interface IState {
	readonly isLoading: boolean;

	readonly instancesById: {
		readonly [key: string]: RaidInstance;
	};

	readonly itemsById: {
		readonly [wowItemId: string]: RaidItem;
	};

	readonly sortedItems: RaidItem[];
}

// types
const STORE = 'raid-data/STORE';

const FETCH = 'raid-data/FETCH';
const FETCH_SUCCESS = 'raid-data/FETCH_SUCCESS';
const FETCH_FAILURE = 'raid-data/FETCH_FAILURE';

export interface IActions {
	STORE: {
		readonly type: typeof STORE;
		readonly payload: {
			instances?: RaidInstance[];
			items?: RaidItem[];
		};
	};

	FETCH: {readonly type: typeof FETCH};
	FETCH_SUCCESS: {readonly type: typeof FETCH_SUCCESS};
	FETCH_FAILURE: {readonly type: typeof FETCH_FAILURE};
}

// selectors
export function getRaidDataLoading(state: IState) {
	return state.isLoading;
}

export function getRaidInstance(state: IState, id: string): RaidInstance | undefined {
	return state.instancesById[id];
}

export function getAllRaidInstances(state: IState) {
	return Object.values(state.instancesById).sort((a, b) => (a.order > b.order ? -1 : 1));
}

export function getRaidInstancesForRaidGroupName(
	state: IState,
	raidGroupName: RaidGroupId
): RaidInstance[] {
	return getAllRaidInstances(state).filter(
		(raidInstance) => raidInstance.raidGroupName === raidGroupName
	);
}

export function getAllRaidItems(state: IState) {
	return state.sortedItems;
}

export function getItemForId(state: IState, itemId: string): RaidItem | undefined {
	return state.itemsById[itemId];
}

// actions
interface IStoreRaidData {
	instances: RaidInstance[] | undefined;
	items: RaidItem[] | undefined;
}

function addDataToState(data: IStoreRaidData) {
	const action: IActions['STORE'] = {
		type: STORE,
		payload: {
			instances: data.instances
				? data.instances.map((i: any) => new RaidInstance(i))
				: undefined,
			items: data.items ? data.items.map((i: any) => new RaidItem(i)) : undefined
		}
	};

	return action;
}

function getRaidDataKeys(isDemo: boolean) {
	return {
		instances: isDemo
			? LocalStorageKeys.DEMO_RAID_DATA_INSTANCES
			: LocalStorageKeys.RAID_DATA_INSTANCES,
		instancesHash: isDemo
			? LocalStorageKeys.DEMO_RAID_DATA_INSTANCES_HASH
			: LocalStorageKeys.RAID_DATA_INSTANCES_HASH,

		items: isDemo
			? LocalStorageKeys.DEMO_RAID_DATA_ITEMS
			: LocalStorageKeys.RAID_DATA_ITEMS,
		itemsHash: isDemo
			? LocalStorageKeys.DEMO_RAID_DATA_ITEMS_HASH
			: LocalStorageKeys.RAID_DATA_ITEMS_HASH
	};
}

interface IRaidDataRequestPayload {
	instances?: string | null;
	items?: string | null;
}

function useCachedData(dispatch: Dispatch<RootAction>, isDemo: boolean) {
	const keys = getRaidDataKeys(isDemo);

	const requestPayload: IRaidDataRequestPayload = {};
	const data: IStoreRaidData = {
		instances: undefined,
		items: undefined
	};

	const instancesString = LS.getItem(keys.instances);
	if (instancesString) {
		try {
			data.instances = JSON.parse(instancesString);
			requestPayload.instances = LS.getItem(keys.instancesHash);
		} catch {
			// ignore
		}
	}

	const itemsString = LS.getItem(keys.items);
	if (itemsString) {
		try {
			data.items = JSON.parse(itemsString);
			requestPayload.items = LS.getItem(keys.itemsHash);
		} catch {
			// ignore
		}
	}

	dispatch(addDataToState(data));

	return requestPayload;
}

function setCachedData(
	isDemo: boolean,
	data: {
		instances: RaidInstance[] | undefined;
		instancesHash: string;
		items: RaidItem[] | undefined;
		itemsHash: string;
	}
) {
	const keys = getRaidDataKeys(isDemo);

	if (data.instances) {
		LS.setItem(keys.instances, JSON.stringify(data.instances));
		LS.setItem(keys.instancesHash, data.instancesHash);
	}

	if (data.items) {
		LS.setItem(keys.items, JSON.stringify(data.items));
		LS.setItem(keys.itemsHash, data.itemsHash);
	}
}

/** In-place mutative sort of RaidItems */
function sortRaidItems(items: RaidItem[]): RaidItem[] {
	return items.sort((a, b) => {
		const ai = RAID_ITEM_SORT_ORDER_BY_SLOT[a.slot];
		const bi = RAID_ITEM_SORT_ORDER_BY_SLOT[b.slot];

		if (!ai) return 1;
		if (!bi) return -1;

		if (ai === bi) return a.name > b.name ? 1 : -1;

		return ai - bi;
	});
}

export function fetchRaidData(isDemo = false): Thunk<void> {
	return (dispatch) => {
		const action: IActions['FETCH'] = {type: FETCH};
		dispatch(action);

		const payload: IRaidDataRequestPayload = useCachedData(dispatch, isDemo);

		return (isDemo ? demoAPI : api).call(rpc.RAID_DATA_FETCH, payload).then(
			(message) => {
				dispatch(
					addDataToState({
						instances: message.data.instances,
						items: message.data.items
					})
				);

				const successAction: IActions['FETCH_SUCCESS'] = {type: FETCH_SUCCESS};
				dispatch(successAction);

				window.setTimeout(() => {
					setCachedData(isDemo, {
						instances: message.data.instances,
						instancesHash: message.data.hashes.instances,
						items: message.data.items,
						itemsHash: message.data.hashes.items
					});
				}, 0);
			},

			(message) => {
				const failureAction: IActions['FETCH_FAILURE'] = {
					type: FETCH_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

// reducer
const initialState: IState = {
	instancesById: {},

	itemsById: {},
	sortedItems: [],

	isLoading: false
};

export default function reducer(
	state: IState = initialState,
	action: RootAction
): IState {
	switch (action.type) {
		// store
		case STORE: {
			const newState = {...state};

			if (action.payload.instances) {
				const instancesById: {[key: string]: RaidInstance} = {};
				action.payload.instances.forEach((instance) => {
					instancesById[instance.id] = instance;
				});

				newState.instancesById = instancesById;
			}

			if (action.payload.items) {
				const itemsById: {[key: string]: RaidItem} = {};
				action.payload.items.forEach((item) => {
					itemsById[item.id] = item;
				});

				newState.sortedItems = sortRaidItems([...action.payload.items]);
				newState.itemsById = itemsById;
			}

			return newState;
		}

		// fetch
		case FETCH: {
			return {...state, isLoading: true};
		}

		case FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			};
		}

		case FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			};
		}

		default:
			return state;
	}
}
