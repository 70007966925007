import {Role, Classes} from '@constants/wow';

export enum BuffTypes {
	BUFF_STAMINA = 'buff-stam',
	BUFF_ATTACK_POWER = 'buff-attack-power',
	BUFF_INTELLECT = 'buff-intellect',
	BUFF_VERSATILITY = 'buff-versatility',
	BUFF_SKYFURY = 'buff-skyfury',
	DEBUFF_PHYSICAL_DAMAGE = 'debuff-physical-damage',
	DEBUFF_MAGICAL_DAMAGE = 'debuff-magical-damage',
	DEBUFF_DAMAGE = 'debuff-damage',

	UTIL_LUST = 'util-lust',
	UTIL_BATTLE_REZ = 'util-brez',
	UTIL_HEALTHSTONES = 'util-hs',
	UTIL_WARLOCK_GATE = 'util-gate',
	UTIL_RUN_SPEED = 'util-run-speed',
	UTIL_RUN_SPEED_CDR = 'util-run-speed-cdr',
	UTIL_BOSS_DAMAGE_REDUCTION = 'util-boss-damage-reduction',

	PERSONAL_IMMUNITIES = 'personal-immunities',
	EXTERNAL_IMMUNITIES = 'external-immunities',
	RAID_DAMAGE_MITIGATION = 'raid-damage-migtigation'
}

interface BuffAbility {
	abilityName: string;
	roles: Role[];
	isTalent: boolean;
}

type BuffAbilitiesByClass = Record<Classes, BuffAbility[]>;

const RAW_BUFF_DATA: Record<BuffTypes, BuffAbilitiesByClass> = {
	// BUFFS
	[BuffTypes.BUFF_STAMINA]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [
			{
				abilityName: 'Power Word: Fortitude',
				roles: [Role.RANGED, Role.HEALER],
				isTalent: false
			}
		],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.BUFF_ATTACK_POWER]: {
		[Classes.WARRIOR]: [
			{
				abilityName: 'Battle Shout',
				roles: [Role.MELEE, Role.TANK],
				isTalent: false
			}
		],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.BUFF_INTELLECT]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [
			{
				abilityName: 'Arcane Intellect',
				roles: [Role.RANGED],
				isTalent: false
			}
		],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.BUFF_VERSATILITY]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [
			{
				abilityName: 'Mark of the Wild',
				roles: [Role.RANGED, Role.MELEE, Role.HEALER, Role.TANK],
				isTalent: false
			}
		],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.BUFF_SKYFURY]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [
			{
				abilityName: 'Skyfury',
				roles: [Role.RANGED, Role.MELEE, Role.HEALER],
				isTalent: false
			}
		],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	// DEBUFFS
	[BuffTypes.DEBUFF_PHYSICAL_DAMAGE]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [
			{
				abilityName: 'Mystic Touch',
				roles: [Role.HEALER, Role.MELEE, Role.TANK],
				isTalent: false
			}
		],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.DEBUFF_MAGICAL_DAMAGE]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [
			{
				abilityName: 'Chaos Brand',
				roles: [Role.TANK, Role.MELEE],
				isTalent: false
			}
		],
		[Classes.EVOKER]: []
	},

	[BuffTypes.DEBUFF_DAMAGE]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [
			{
				abilityName: "Hunter's Mark",
				roles: [Role.RANGED, Role.MELEE],
				isTalent: false
			}
		],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	// UTILITIES
	[BuffTypes.UTIL_BATTLE_REZ]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [
			{
				abilityName: 'Intercession',
				roles: [Role.MELEE, Role.TANK, Role.HEALER],
				isTalent: false
			}
		],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [
			{
				abilityName: 'Raise Ally',
				roles: [Role.MELEE, Role.TANK],
				isTalent: false
			}
		],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [
			{
				abilityName: 'Soulstone',
				roles: [Role.RANGED],
				isTalent: false
			}
		],
		[Classes.MONK]: [],
		[Classes.DRUID]: [
			{
				abilityName: 'Rebirth',
				roles: [Role.MELEE, Role.HEALER, Role.RANGED, Role.TANK],
				isTalent: false
			}
		],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.UTIL_LUST]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [
			{
				abilityName: 'Bloodlust',
				roles: [Role.HEALER, Role.MELEE, Role.RANGED],
				isTalent: false
			}
		],
		[Classes.MAGE]: [
			{
				abilityName: 'Time Warp',
				roles: [Role.RANGED],
				isTalent: false
			}
		],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: [
			{
				abilityName: 'Fury of the Aspects',
				roles: [Role.HEALER, Role.RANGED],
				isTalent: false
			}
		]
	},

	[BuffTypes.UTIL_HEALTHSTONES]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [
			{
				abilityName: 'Healthstones',
				roles: [Role.RANGED],
				isTalent: false
			}
		],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.UTIL_WARLOCK_GATE]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [
			{
				abilityName: 'Demonic Gateway',
				roles: [Role.RANGED],
				isTalent: false
			}
		],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.UTIL_RUN_SPEED]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [
			{
				abilityName: 'Wind Rush Totem',
				roles: [Role.HEALER, Role.MELEE, Role.RANGED],
				isTalent: true
			}
		],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [
			{
				abilityName: 'Stampeding Roar',
				roles: [Role.HEALER, Role.MELEE, Role.RANGED, Role.TANK],
				isTalent: false
			}
		],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.UTIL_RUN_SPEED_CDR]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: [
			{
				abilityName: 'Blessing of the Bronze',
				roles: [Role.HEALER, Role.MELEE, Role.RANGED],
				isTalent: false
			}
		]
	},

	[BuffTypes.UTIL_BOSS_DAMAGE_REDUCTION]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [
			{abilityName: 'Atrophic Poison', roles: [Role.MELEE], isTalent: true}
		],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	// COOLDOWNS
	[BuffTypes.PERSONAL_IMMUNITIES]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [
			{
				abilityName: 'Divine Shield',
				roles: [Role.HEALER, Role.MELEE, Role.TANK],
				isTalent: false
			}
		],
		[Classes.HUNTER]: [
			{
				abilityName: 'Aspect of the Turtle',
				roles: [Role.RANGED, Role.MELEE],
				isTalent: false
			}
		],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [
			{
				abilityName: 'Ice Block',
				roles: [Role.RANGED],
				isTalent: false
			}
		],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.EXTERNAL_IMMUNITIES]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [
			{
				abilityName: 'Blessing of Protection',
				roles: [Role.HEALER, Role.MELEE, Role.TANK],
				isTalent: false
			}
		],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [],
		[Classes.DEATH_KNIGHT]: [],
		[Classes.SHAMAN]: [],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [],
		[Classes.EVOKER]: []
	},

	[BuffTypes.RAID_DAMAGE_MITIGATION]: {
		[Classes.WARRIOR]: [],
		[Classes.PALADIN]: [
			{
				abilityName: 'Aura Mastery',
				roles: [Role.HEALER],
				isTalent: false
			}
		],
		[Classes.HUNTER]: [],
		[Classes.ROGUE]: [],
		[Classes.PRIEST]: [
			{
				abilityName: 'Power Word: Barrier',
				roles: [Role.HEALER],
				isTalent: false
			}
		],
		[Classes.DEATH_KNIGHT]: [
			{
				abilityName: 'Anti-Magic Zone',
				roles: [Role.MELEE, Role.TANK],
				isTalent: false
			}
		],
		[Classes.SHAMAN]: [
			{
				abilityName: 'Spirit Link Totem',
				roles: [Role.HEALER],
				isTalent: false
			}
		],
		[Classes.MAGE]: [],
		[Classes.WARLOCK]: [],
		[Classes.MONK]: [],
		[Classes.DRUID]: [],
		[Classes.DEMON_HUNTER]: [
			{
				abilityName: 'Darkness',
				roles: [Role.MELEE, Role.TANK],
				isTalent: false
			}
		],
		[Classes.EVOKER]: []
	}
};

interface TypedBuffAbility {
	type: BuffTypes;
	buffAbility: BuffAbility;
}
type BuffAbilitiesByRole = Map<Role, TypedBuffAbility[]>;
type RoleGroupByClass = Map<Classes, BuffAbilitiesByRole>;

function getEmptyRoleGroup(): BuffAbilitiesByRole {
	return new Map([
		[Role.HEALER, []],
		[Role.MELEE, []],
		[Role.RANGED, []],
		[Role.TANK, []]
	]);
}

function makeData(): RoleGroupByClass {
	// create the overall structure of the data
	const roleGroupByClass: RoleGroupByClass = new Map(
		Object.values(Classes).map((wowClass): [Classes, BuffAbilitiesByRole] => {
			return [wowClass, getEmptyRoleGroup()];
		})
	);

	// populate all of the data
	Object.entries(RAW_BUFF_DATA).forEach(([rawType, buffAbilitiesByClass]): void => {
		const type = rawType as BuffTypes;

		Object.entries(buffAbilitiesByClass).forEach(([wowClass, buffAbilities]) => {
			const classRoleGroup = roleGroupByClass.get(wowClass as Classes);
			if (!classRoleGroup) {
				console.error('No class group', wowClass, roleGroupByClass);
				return;
			}

			buffAbilities.forEach((buffAbility) => {
				buffAbility.roles.forEach((role) => {
					classRoleGroup.set(role, [
						...(classRoleGroup.get(role) || []),
						{
							type,
							buffAbility
						}
					]);
				});
			});
		});
	});

	return roleGroupByClass;
}

export const BUFF_DATA_BY_CLASS = makeData();
