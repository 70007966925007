import {connect} from 'react-redux';

import * as GuildDuck from '../../ducks/guild';
import * as UserDuck from '../../ducks/user';

import Header from './Header';

function mapStateToProps(state: IRootState) {
	const isGuildNav =
		window.location.pathname.includes('/guild/') ||
		window.location.pathname.includes('/demo');
	const guild = isGuildNav && GuildDuck.getActiveGuild(state.guilds);

	return {
		user: UserDuck.getUser(state.user),
		guild
	};
}

export default connect(mapStateToProps, {
	onEnterSettings: UserDuck.setUrl
})(Header);
