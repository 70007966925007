import React from 'react';

import {LinkButton, Button, ButtonGroup} from '../../Utils/Button';

interface IProps {
	onCancel(): void;
	doneUrl: string;
}

export const NoRows: React.FC<IProps> = (props) => {
	return (
		<div className="no-rows">
			<div className="emoji">🎉</div>

			<p>
				No changes needed! Everyone's loot preferences are already up to date with
				this import.
			</p>

			<ButtonGroup alignment="center">
				{props.onCancel && (
					<Button onClick={props.onCancel} className="small underlined link">
						Import another
					</Button>
				)}

				<LinkButton to={props.doneUrl} className="large primary">
					Go to loot sheet
				</LinkButton>
			</ButtonGroup>
		</div>
	);
};
