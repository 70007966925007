import {connect} from 'react-redux';

import {GuildDuck} from '@ducks';

import type {ISettingsPageProps} from '../GuildSettingsContainer';
import {AutoInvite} from './AutoInvite';

interface IRankGroup {
	name: string;
	isAutoInvite: boolean;
}

interface IMapProps {
	rankGroups: IRankGroup[];
}

export interface IProps extends ISettingsPageProps, IMapProps {
	onToggleRank: typeof GuildDuck.updateAutoInviteRank;
}

function mapStateToProps(state: IRootState, props: ISettingsPageProps): IMapProps {
	const rankGroups = props.guild.normalRankNames.map((rankName, index): IRankGroup => {
		return {
			isAutoInvite: props.guild.autoInviteRanks[index],
			name: rankName
		};
	});

	return {
		rankGroups
	};
}

export const AutoInviteContainer = connect(mapStateToProps, {
	onToggleRank: GuildDuck.updateAutoInviteRank
})(AutoInvite);
