import React from 'react';
import {Link} from 'react-router';

import {DifficultyDisplay} from '@constants/wow';

import type * as RosterDuck from '../../../ducks/roster';

import type {Roster} from '../../../models/roster';

import planRaids from '../../../images/plan-raids.png';

import * as BillingLink from '../../Utils/BillingLink';
import RosterDetailsContent from './RosterDetailsContent';
import type {ISelectOption} from '../../Utils/SelectInput';

export interface IMapProps {
	canUseMultipleRostersForInstance: boolean;
	canUseTagging: boolean;

	isHidingInstances: boolean;
	isSubmitting: boolean;
	isDeleting: boolean;

	instanceOptions: ISelectOption[];
	tagOptions: ISelectOption[];
	rosters: Roster[];
	roster?: Roster;

	settingsUrl: string;
	baseUrl: string;
}

interface IListProps extends IMapProps {
	onCreate: typeof RosterDuck.createRoster;
	onUpdate: typeof RosterDuck.updateRoster;
	onDelete: typeof RosterDuck.deleteRoster;
}

export default function RosterSettings(props: IListProps) {
	const rosters = props.rosters.map((roster) => (
		<ListItem key={roster.id} baseUrl={props.baseUrl} roster={roster} />
	));

	const showUpgradeState = !props.roster && !props.instanceOptions.length;

	return (
		<div className="settings-content no-padding rosters">
			<div className="settings-split-content">
				<div className="list-content-container">
					<div className="settings-heading">Rosters</div>

					<div className="list-content">
						<Link
							to={`${props.baseUrl}/new`}
							activeClassName="active"
							className="list-item add-new"
						>
							<div className="name">Create new roster</div>
						</Link>

						{rosters}
					</div>
				</div>

				{showUpgradeState && <UpgradeView />}

				{!showUpgradeState && (
					<RosterDetailsContent
						onCreate={props.onCreate}
						onUpdate={props.onUpdate}
						onDelete={props.onDelete}
						canUseTagging={props.canUseTagging}
						isHidingInstances={props.isHidingInstances}
						isSubmitting={props.isSubmitting}
						isDeleting={props.isDeleting}
						instanceOptions={props.instanceOptions}
						tagOptions={props.tagOptions}
						roster={props.roster}
						settingsUrl={props.settingsUrl}
					/>
				)}
			</div>
		</div>
	);
}

interface IItemProps {
	roster: Roster;

	baseUrl: string;
}

function ListItem(props: IItemProps) {
	return (
		<Link
			to={`${props.baseUrl}/${props.roster.id}`}
			activeClassName="active"
			className="list-item"
		>
			<div className="name">{props.roster.name}</div>

			<div className="description">
				{DifficultyDisplay[props.roster.difficulty]} - {props.roster.wowInstanceName}
			</div>
		</Link>
	);
}

function UpgradeView() {
	return (
		<div className="details-content hero-view">
			<img className="hero-icon" src={planRaids} />

			<div className="hero-description">
				To create more than one roster for an instance you'll need a buff.
			</div>

			{BillingLink.createMultipleRostersForInstanceLink(
				undefined,
				'large primary button'
			)}
		</div>
	);
}
