import type {Difficulties} from '@constants/wow';

export type DroptimizerReportId = Nominal<number, 'DroptimizerReport'>;

const relativeFormat = new Intl.NumberFormat(undefined, {
	style: 'percent',
	minimumFractionDigits: 1,
	maximumFractionDigits: 1
});

const absoluteFormat = new Intl.NumberFormat(undefined, {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0
});

interface DroptimizerItemData {
	wowItemId: WowItemId;

	/** Absolute dps gain e.g. 2813.14 */
	absoluteDpsGain: number;
	/** Percentage gain (between 0-1) e.g. 0.0123 */
	relativeDpsGain: number;
}

export interface DroptimizerItem extends DroptimizerItemData {
	displayRelativeDpsGain: string;
	displayAbsoluteDpsGain: string;
}

export interface DroptimizerReportData {
	readonly id: DroptimizerReportId;
	readonly guildId: GuildId;
	readonly characterId: CharacterId;

	readonly createdAt: string;

	readonly originalReportUrl: string;

	readonly raidbotsReportId: string;
	readonly raidbotsReportCreatedAt: string;
	readonly raidbotsReportVersion: string;
	readonly raidbotsReportTitle: string;

	readonly difficulty: Difficulties;
	readonly items: DroptimizerItemData[];
}

export class DroptimizerReport
	implements OmitStrict<DroptimizerReportData, 'createdAt' | 'raidbotsReportCreatedAt'>
{
	readonly id: DroptimizerReportId;
	readonly guildId: GuildId;
	readonly characterId: CharacterId;

	readonly createdAt: Date;

	readonly originalReportUrl: string;
	readonly raidbotsReportUrl: string;

	readonly raidbotsReportId: string;
	readonly raidbotsReportCreatedAt: Date;
	readonly raidbotsReportVersion: string;
	readonly raidbotsReportTitle: string;

	readonly difficulty: Difficulties;
	readonly items: DroptimizerItem[];

	constructor(data: DroptimizerReportData) {
		this.id = data.id;
		this.guildId = data.guildId;
		this.characterId = data.characterId;

		this.createdAt = new Date(data.createdAt);

		this.originalReportUrl = data.originalReportUrl;

		this.raidbotsReportUrl = `https://www.raidbots.com/simbot/report/${data.raidbotsReportId}`;

		this.raidbotsReportId = data.raidbotsReportId;
		this.raidbotsReportCreatedAt = new Date(data.raidbotsReportCreatedAt);
		this.raidbotsReportVersion = data.raidbotsReportVersion;
		this.raidbotsReportTitle = data.raidbotsReportTitle;

		this.difficulty = data.difficulty;

		this.items = data.items.map((item): DroptimizerItem => {
			return {
				...item,

				displayAbsoluteDpsGain: absoluteFormat.format(item.absoluteDpsGain),

				displayRelativeDpsGain: relativeFormat
					.format(item.relativeDpsGain)
					.replace('.0', '')
					.replace(/^-0$/, '0')
			};
		});
	}
}
