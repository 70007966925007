import React from 'react';
import classnames from 'classnames';

import type * as LootContainer from '../LootContainer';
import {ViewNote} from './ViewNote';

interface IProps {
	want: LootContainer.IItemWant | undefined;
}

export const ViewSelection: React.FC<IProps> = (props) => {
	const want = props.want;

	const selectionClass = classnames(
		{
			faded: !want
		},
		'selection'
	);

	return (
		<div className="selection-column viewable-selection">
			<div className={selectionClass}>{want ? want.optionName : 'Does not want'}</div>

			{want?.note && <ViewNote onClick={null} value={want.note} />}
		</div>
	);
};
