import React from 'react';

function getPlatform() {
	return window.navigator.platform;
}

export function isMac() {
	const platform = getPlatform();
	if (!platform) return false;

	if (['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].includes(platform)) return true;

	return false;
}

export const HotkeyCombo: React.FC = (props) => {
	return <div className="util-hotkey-combo">{props.children}</div>;
};

export const HotkeyComboJoiner: React.FC = () => {
	return <div className="util-hotkey-combo-joiner">+</div>;
};

export const Hotkey: React.FC = (props) => {
	return (
		<div className="util-hotkey">
			<div className="key-edge">
				<div className="key-top">{props.children}</div>
			</div>
		</div>
	);
};
