import React from 'react';

import type {RaidItem} from '../../../models/raid-data';

import {LootItemRow} from './LootItemRow';

interface IProps {
	wowheadBonus: string;

	selections: Array<{
		note: string | null;
		optionName: string;
		item: RaidItem;
	}>;
}

export const CharacterLoot: React.FC<IProps> = (props) => {
	const itemRows = props.selections.map((selection) => (
		<LootItemRow
			key={selection.item.id}
			wowheadBonus={props.wowheadBonus}
			optionName={selection.optionName}
			note={selection.note}
			item={selection.item}
		/>
	));

	return <div className="character-loot">{itemRows}</div>;
};
