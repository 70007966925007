import React from 'react';

interface Props {
	className?: string;

	/** Whether to lazy load the image; Defaults to true */
	isLazy?: boolean;

	src: string;
}

export const Image: React.FC<Props> = (props) => {
	const isLazy = props.isLazy ?? true;

	return (
		<img
			className={props.className}
			loading={isLazy ? 'lazy' : 'eager'}
			src={props.src}
		/>
	);
};
