import difference from 'lodash/difference';

import {Role, ORDERED_ROLES} from '@constants/wow';

import {connect, RosterBossDuck, CharacterDuck} from '@ducks';

import {sortCharactersByName} from '@helpers/toolbox';

import type {RosterBoss} from '@models/roster-boss';
import type {Character} from '@models/character';

import {ChangesSection as ChangesSectionComp} from './ChangesSection';

export interface Change {
	character: Character;
	change: 'added' | 'removed';
}

interface RoleChanges {
	role: Role;
	changes: Change[];
}

interface OwnProps {
	rosterBoss: RosterBoss;
}

interface MapProps {
	roleChanges: RoleChanges[];
}

export type Props = OwnProps & MapProps;

function getPreviousBossId(
	rosterBossState: IRootState['rosterBosses'],
	rosterId: RosterId,
	rosterBossId: RosterBossId
): RosterBossId | undefined {
	const bosses = RosterBossDuck.getBossesForRoster(rosterBossState, rosterId);

	const currentBossIndex = bosses.findIndex((x) => x.id === rosterBossId);

	const previousBoss: RosterBoss | undefined = bosses[currentBossIndex - 1];
	return previousBoss?.id;
}

function getRoleAssignmentsForBosses(
	rosterBossState: IRootState['rosterBosses'],
	rosterId: RosterId,
	rosterBossId: RosterBossId
) {
	const previousBossId = getPreviousBossId(rosterBossState, rosterId, rosterBossId);

	return {
		currentAssignedCharacterIdsByRole: RosterBossDuck.getAssignmentsForBoss(
			rosterBossState,
			rosterBossId
		),
		previousAssignedCharacterIdsByRole: previousBossId
			? RosterBossDuck.getAssignmentsForBoss(rosterBossState, previousBossId)
			: null
	};
}

export function getRoleChanges(
	rosterBossState: IRootState['rosterBosses'],
	characterState: IRootState['characters'],
	rosterId: RosterId,
	rosterBossId: RosterBossId
): RoleChanges[] {
	const {currentAssignedCharacterIdsByRole, previousAssignedCharacterIdsByRole} =
		getRoleAssignmentsForBosses(rosterBossState, rosterId, rosterBossId);

	const roleChanges = ORDERED_ROLES.map((role): RoleChanges => {
		const currentCharacterIds = currentAssignedCharacterIdsByRole[role];
		const previousCharacterIds = previousAssignedCharacterIdsByRole
			? previousAssignedCharacterIdsByRole[role]
			: [];

		const changes: Change[] = [];

		// handle added characters
		difference(currentCharacterIds, previousCharacterIds).forEach((charId) => {
			const char = CharacterDuck.getCharacterForId(characterState, charId);
			if (!char) return;

			changes.push({
				change: 'added',
				character: char
			});
		});

		// handle removed characters
		difference(previousCharacterIds, currentCharacterIds).forEach((charId) => {
			const char = CharacterDuck.getCharacterForId(characterState, charId);
			if (!char) return;

			changes.push({
				change: 'removed',
				character: char
			});
		});

		changes.sort((a, b) => {
			if (a.change !== b.change) {
				return a.change < b.change ? -1 : 1;
			}

			return sortCharactersByName(a.character, b.character);
		});

		return {
			role,
			changes
		};
	});

	return roleChanges;
}

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const roleChanges = getRoleChanges(
		state.rosterBosses,
		state.characters,
		props.rosterBoss.rosterId,
		props.rosterBoss.id
	);

	return {
		roleChanges
	};
}

export const ChangesSection = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(ChangesSectionComp);
