import React from 'react';

import type {Classes} from '@constants/wow';

import type {Character} from '@models/character';

interface Ability {
	id: string;
	abilityName: string;
	isTalent: boolean;
	count: number;
	class: Classes;
}

interface Contributor {
	character: Character;
	abilityName: string;
	isTalent: boolean;
}

function makeKey(wowClass: Classes, abilityName: string): string {
	return `${wowClass}~${abilityName}`;
}

function groupContributorsIntoAbilities(contributors: Contributor[]): Ability[] {
	const abilityByKey = new Map<string, Ability>();

	contributors.forEach((contributor) => {
		const key = makeKey(contributor.character.class, contributor.abilityName);

		const ability: Ability = abilityByKey.get(key) || {
			id: key,
			abilityName: contributor.abilityName,
			isTalent: contributor.isTalent,
			class: contributor.character.class,
			count: 0
		};

		abilityByKey.set(key, {
			...ability,
			count: ability.count + 1
		});
	});

	return [...abilityByKey.values()];
}

interface BuffAbilityProps {
	ability: Ability;
}

const BuffAbility: React.FC<BuffAbilityProps> = (props) => {
	return (
		<div className="ability">
			<div className="count">{props.ability.count}</div>
			<div className={`ability-name wow-style text ${props.ability.class}`}>
				{props.ability.abilityName}
			</div>
		</div>
	);
};

interface BuffContributorsProps {
	/** The name of the buff/cooldown/utility */
	title: string;

	contributors: Contributor[];
}

export const BuffContributors: React.FC<BuffContributorsProps> = (props) => {
	const abilities = groupContributorsIntoAbilities(props.contributors);

	const abilityItems = abilities.map((ability) => (
		<BuffAbility key={ability.id} ability={ability} />
	));

	return (
		<div className="buff-contributors">
			<div className="title">{props.title}</div>

			<div className="abilities">{abilityItems}</div>
		</div>
	);
};
