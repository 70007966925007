import React from 'react';
import {Link} from 'react-router';
import classnames from 'classnames';

import {userNewBillingUrl} from '@helpers/urls';

import {UserBillingDuck, GuildSponsorshipDuck, connect} from '@ducks';

enum BillingCta {
	BUFFED = 'Your guild is buffed',
	BUFF = 'Buff your guild'
}

interface IOwnProps {
	guildId: number;
	userId: number;
}

interface IMapProps {
	isHighlighted: boolean;
	text: string;

	toLocation: {
		pathname: string;
		query?: {
			guild: number;
		};
	};
}

type IProps = IOwnProps & IMapProps;

const BuffButtonComp: React.FC<IProps> = (props) => {
	const linkClass = classnames(
		{
			focus: props.isHighlighted
		},
		'billing-link nav-item'
	);

	return (
		<Link to={props.toLocation} className={linkClass}>
			{props.text}
		</Link>
	);
};

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const billing = UserBillingDuck.getForUser(state.userBilling, props.userId);
	const allSponsorshipsForGuild = GuildSponsorshipDuck.getSponsorshipsForGuildId(
		state.guildSponsorships,
		props.guildId
	);

	const hasActiveBilling = !!billing?.status;
	const selfSponsorship = allSponsorshipsForGuild.find(
		(x) => x.userBillingId === billing?.id
	);
	const isOtherSponsored = allSponsorshipsForGuild.some(
		(x) => x.userBillingId !== billing?.id
	);

	// User has active billing:
	if (hasActiveBilling) {
		// 1) User is sponsoring guild -> [Not highlighted] BUFFED
		if (selfSponsorship) {
			return {
				isHighlighted: false,
				text: BillingCta.BUFFED,

				toLocation: {
					pathname: userNewBillingUrl()
				}
			};
		}

		// 2) Guild is sponsored by someone else -> [Not highlighted] BUFF
		if (isOtherSponsored) {
			return {
				isHighlighted: false,
				text: BillingCta.BUFF,

				toLocation: {
					pathname: userNewBillingUrl()
				}
			};
		}

		// 3) Guild isn't sponsored -> [Highlighted] BUFF
		return {
			isHighlighted: true,
			text: BillingCta.BUFF,

			toLocation: {
				pathname: userNewBillingUrl()
			}
		};
	}

	// User doesn't have active billing:
	// 4) Guild is sponsored by someone else -> [Not highlighted] BUFFED
	if (isOtherSponsored) {
		return {
			isHighlighted: false,
			text: BillingCta.BUFFED,

			toLocation: {
				pathname: userNewBillingUrl(),
				query: {guild: props.guildId}
			}
		};
	}

	// 5) Guild isn't sponsored -> [Highlighted] BUFF
	return {
		isHighlighted: true,
		text: BillingCta.BUFF,

		toLocation: {
			pathname: userNewBillingUrl(),
			query: {guild: props.guildId}
		}
	};
}

export const BuffButton = connect<IMapProps, {}, IOwnProps>(
	mapStateToProps,
	{}
)(BuffButtonComp);
