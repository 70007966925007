import React from 'react';

import {Link} from 'react-router';

import * as wow from '../../constants/wow';

import {signOutUrl, supportUrl} from '@helpers/urls';

import * as UserDuck from '../../ducks/user';

import type {IGuildOption} from './SelectGuildContainer';

import allianceImage from '../../images/faction-alliance.png';
import hordeImage from '../../images/faction-horde.png';

const MissingGuildsNotice: React.FC = () => {
	return (
		<div className="missing-guilds-notice">
			Missing a guild? <a href={signOutUrl()}>Re-sync your characters</a> or{' '}
			<a href={supportUrl()}>contact support</a>
		</div>
	);
};

interface IGuildGroupProps {
	heading: string;
}

const GuildGroup: React.FC<IGuildGroupProps> = (props) => {
	return (
		<div className="guild-group">
			<h2 key="heading" className="description">
				{props.heading}
			</h2>

			<div key="guilds" className="guild-options">
				{props.children}
			</div>
		</div>
	);
};

interface ISelectGuildProps {
	notSetupGuilds: IGuildOption[];
	setupGuilds: IGuildOption[];

	battletag: string;
	region: string;
}

export default function SelectGuild(props: ISelectGuildProps) {
	const setupGuilds = props.setupGuilds.map((guild) => (
		<Guild key={guild.url} guild={guild} />
	));
	const notSetupGuilds = props.notSetupGuilds.map((guild) => (
		<Guild key={guild.url} guild={guild} />
	));

	const hasGuild = !!(setupGuilds.length || notSetupGuilds.length);

	return (
		<div className="select-guild">
			<h1 className="title">{props.battletag}</h1>

			{!!setupGuilds.length && (
				<GuildGroup heading="Select a guild to view">{setupGuilds}</GuildGroup>
			)}

			{!!notSetupGuilds.length && (
				<GuildGroup heading="Set up a guild">{notSetupGuilds}</GuildGroup>
			)}

			{!hasGuild && <NoGuilds region={props.region} />}

			<MissingGuildsNotice />
		</div>
	);
}

function Guild(props: {guild: IGuildOption}) {
	return (
		<div className="guild-wrapper">
			<Link to={props.guild.url} className="guild">
				<div className="details">
					<div className="name">{props.guild.name}</div>

					<div className="realm">{props.guild.realm}</div>
				</div>

				<img
					src={
						props.guild.faction === wow.FACTIONS.HORDE ? hordeImage : allianceImage
					}
				/>
			</Link>
		</div>
	);
}

interface INoGuldsProps {
	region: string;
}

function NoGuilds(props: INoGuldsProps) {
	return (
		<div className="no-guilds card">
			<p>
				<span>
					It appears that you don't have any max level characters in a guild.{' '}
				</span>
				<span className="brand-name">Readycheck</span>
				<span>
					{' '}
					is designed to help manage current raid instances, which require max level
					characters.
				</span>
			</p>

			<div>
				<span>
					If you do have max level characters in guilds, please ensure you're
					connected to the right region. You are currently connected to:
				</span>

				<div className="current-region">{wow.REGIONS[props.region]}</div>
			</div>

			<div onClick={() => UserDuck.signOut()} className="large primary faded button">
				Reconnect
			</div>
		</div>
	);
}
