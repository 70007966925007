import React from 'react';
import classnames from 'classnames';
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda';

import * as wow from '../../../constants/wow';

import type {TagDuck, SettingsDuck} from '@ducks';

import type {Character} from '../../../models/character';
import type {Tag} from '../../../models/tag';

import type {IGroup, ICharacterGroup} from './TagSettings';
import DeleteTag from './DeleteTag';
import {SwitchField} from '../../Utils/Switch';
import Input from '../../Utils/Input';

interface IDetailsProps {
	onGroupByStatus: typeof SettingsDuck['setGuildSettingsGroupTagsByStatus'];
	onTag: typeof TagDuck.makeAssignment;
	onCreate: typeof TagDuck.createTag;
	onUpdate: typeof TagDuck.updateTag;
	onDelete: typeof TagDuck.deleteTag;

	isGroupingByStatus: boolean;
	isSubmitting: boolean;
	isDeleting: boolean;

	groups: IGroup[];
	tag?: Tag;
}

interface IDetailsState {
	isShowingDelete: boolean;
	isDisabled: boolean;
}

export default class RosterDetailsContent extends React.Component<
	IDetailsProps,
	IDetailsState
> {
	fields: {[key: string]: any} = {};
	state: IDetailsState;

	constructor(props: IDetailsProps) {
		super(props);

		this.state = {
			isDisabled: !props.tag,
			isShowingDelete: false
		};
	}

	componentDidMount() {
		this.checkForDisabled();
	}

	componentWillReceiveProps(nextProps: IDetailsProps) {
		const oldId = this.props.tag?.id;
		const newId = nextProps.tag?.id;

		if (oldId !== newId) this.resetView(nextProps.tag);
		else this.checkForDisabled(nextProps.tag);
	}

	checkForDisabledHelper = (tag: Tag | undefined) => {
		const name = this.fields.name.getValue();
		if (!name) return true;

		// if there's no tag then we're all good
		if (!tag) return false;

		if (name === tag.name) return true;

		return false;
	};

	checkForDisabled = (tag: Tag | undefined = this.props.tag) => {
		const isDisabled = this.checkForDisabledHelper(tag);

		if (isDisabled !== this.state.isDisabled) {
			this.setState({isDisabled});
		}
	};

	resetView(tag?: Tag) {
		this.fields.name.setValue(tag ? tag.name : '');
		this.fields.name.focus();

		this.setState(
			{
				isShowingDelete: false
			},
			this.checkForDisabled
		);
	}

	handleSubmit = () => {
		const name = this.fields.name.getValue();
		if (!name) return;

		const data = {
			name
		};

		if (this.props.tag) {
			this.props.onUpdate({
				...data,
				id: this.props.tag.id
			});
		} else {
			this.props.onCreate({
				...data
			});
		}
	};

	handleDelete = () => {
		this.props.onDelete(this.props.tag!.id);
	};

	handleTag = (charId: number, isTagged: boolean) => {
		this.props.onTag({
			characterId: charId,
			tagId: this.props.tag!.id,
			isTagged
		});
	};

	render() {
		const buttonClass = classnames(
			{
				disabled: this.state.isDisabled || this.props.isSubmitting
			},
			'medium faded primary button'
		);

		const hideSubmit = this.props.tag && this.state.isDisabled;

		const groups = this.props.groups.map((group) => (
			<RankGroup
				key={group.id}
				onTag={this.handleTag}
				characterGroups={group.characterGroups}
				name={group.name}
			/>
		));

		return (
			<div className="details-content">
				{this.state.isShowingDelete && (
					<DeleteTag
						onClose={() => this.setState({isShowingDelete: false})}
						onDelete={this.handleDelete}
						isDeleting={this.props.isDeleting}
						name={this.props.tag!.name}
					/>
				)}

				{!this.props.tag && (
					<div className="help-block">
						<p>
							You can group your guild's characters by tags (e.g. "Mains" and
							"Alts"). Characters can have any number of tags to give you the
							freedom to manage your rosters however you want.
						</p>

						<p>
							Each roster is able to be configured to only show characters from
							certain tags.
						</p>
					</div>
				)}

				<div className="fields">
					<Input
						ref={(r: Input) => (this.fields.name = r)}
						onChange={() => this.checkForDisabled()}
						autoFocus={true}
						label="Tag name"
						placeholder="e.g. Mains"
						defaultValue={this.props.tag?.name}
					/>
				</div>

				<div className="button-group">
					{this.props.tag && (
						<div
							onClick={() => this.setState({isShowingDelete: true})}
							className="medium left red outline button"
						>
							Delete
						</div>
					)}

					{!hideSubmit && (
						<LaddaButton
							onClick={this.handleSubmit}
							className={buttonClass}
							loading={this.props.isSubmitting}
							data-style={EXPAND_RIGHT}
						>
							{this.props.tag ? 'Save changes' : 'Create'}
						</LaddaButton>
					)}
				</div>

				{this.props.tag && (
					<div className="tagging-group-switch">
						<SwitchField
							onChange={this.props.onGroupByStatus}
							isControlled={true}
							label="Group by tagged status"
							value={this.props.isGroupingByStatus}
						/>
					</div>
				)}

				{this.props.tag && !!this.props.groups.length && (
					<div className="rank-groups margin-top">{groups}</div>
				)}
			</div>
		);
	}
}

interface IRankGroupProps {
	onTag(charId: number, isTagged: boolean): void;
	characterGroups: ICharacterGroup[];
	name: string;
}

function RankGroup(props: IRankGroupProps) {
	const characters = props.characterGroups.map((group) => (
		<CharacterRow
			key={group.character.id}
			onTag={props.onTag}
			character={group.character}
			isTagged={group.isTagged}
		/>
	));

	return (
		<div className="rank-group">
			<div className="rank-info">
				<span className="rank-name">{props.name}</span>
			</div>

			<div className="characters">{characters}</div>
		</div>
	);
}

interface ICharacterRowProps {
	onTag(charId: number, isTagged: boolean): void;
	isTagged: boolean;
	character: Character;
}

function CharacterRow(props: ICharacterRowProps) {
	const char = props.character;

	const characterClass = classnames(
		char.class,
		'settings-character-row wow-style bg-before'
	);

	const buttonClass = classnames(
		{
			// disabled: props.isRestoring,
			'red outline': props.isTagged,
			primary: !props.isTagged
		},
		'button'
	);

	return (
		<div className={characterClass}>
			<div className="left">
				<img src={char.getAvatarUrl()} className="thumbnail" />

				<div className="details">
					<div className="name">{char.name}</div>

					<div className="secondary">{wow.CLASS_TO_DISPLAY[char.class]}</div>
				</div>
			</div>

			{!props.isTagged && (
				<LaddaButton
					onClick={() => props.onTag(props.character.id, true)}
					className={buttonClass}
					loading={false}
					data-style={EXPAND_RIGHT}
				>
					Add to tag
				</LaddaButton>
			)}

			{props.isTagged && (
				<LaddaButton
					onClick={() => props.onTag(props.character.id, false)}
					className={buttonClass}
					loading={false}
					data-style={EXPAND_RIGHT}
				>
					Remove from tag
				</LaddaButton>
			)}
		</div>
	);
}
