export function generateWowheadSpellLinkData(wowAbilityId: WowAbilityId, isNpc: boolean) {
	const url = `http://wowhead.com/${isNpc ? 'npc' : 'spell'}=${wowAbilityId}`;
	const rel = '';

	return {url, rel};
}

export function forceRemoveWowheadTooltip(): void {
	window.WH?.Tooltip?.hide?.();
}
