import React from 'react';
import {connect} from 'react-redux';
import type {PlainRoute} from 'react-router';

import {
	BossKillsDuck,
	CharacterDuck,
	RosterBossDuck,
	RosterDuck,
	UserDuck
} from '../../ducks';

import {router} from '@helpers/urls';

import type {Roster} from '../../models/roster';
import type {Guild} from '../../models/guild';

import RosterContainer from './RosterContainer';

interface IOwnProps {
	guild: Guild;

	route: PlainRoute;

	params: {
		rosterId: string;
	};
}

interface IMapProps {
	isUpdatingNotes: boolean;
	isAdmin: boolean;

	disabledViewRedirect:
		| {isDisabled: false; redirectUrl: undefined}
		| {isDisabled: true; redirectUrl: string};

	userId?: number;
	roster?: Roster;
}

interface IDispatchProps {
	onFetchBossKills: typeof BossKillsDuck.fetchForInstance;
	onUpdateNotes: typeof RosterBossDuck.updateNotes;
	onUpdateLimit: typeof RosterBossDuck.updateLimit;
	onUnrosterAll: typeof RosterBossDuck.unrosterAll;
	onRoster: typeof RosterBossDuck.rosterCharacter;
}

type IProps = IOwnProps & IMapProps & IDispatchProps;

function RealRosterWrapper(props: IProps) {
	if (!props.roster || !props.guild) return null;

	// if the roster is disabled then redirect them
	if (props.disabledViewRedirect.isDisabled) {
		const redirectUrl = props.disabledViewRedirect.redirectUrl;
		setTimeout(() => router.push(redirectUrl), 10);
		return null;
	}

	return (
		<RosterContainer
			onFetchBossKills={props.onFetchBossKills}
			onUpdateNotes={props.onUpdateNotes}
			onUpdateLimit={props.onUpdateLimit}
			onUnrosterAll={props.onUnrosterAll}
			onRoster={props.onRoster}
			isUpdatingNotes={props.isUpdatingNotes}
			isAdmin={props.isAdmin}
			isDemo={false}
			userId={props.userId}
			roster={props.roster}
			guild={props.guild}
		/>
	);
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const rosterId = Number.parseInt(props.params.rosterId, 10);
	const roster = RosterDuck.getRoster(state.rosters, rosterId);

	const user = UserDuck.getUser(state.user);

	let isAdmin = false;
	if (user) {
		const characters = CharacterDuck.getAllCharactersForGuild(
			state.characters,
			props.guild.id
		);
		isAdmin = props.guild.isUserAdmin(user, characters);
	}

	return {
		isUpdatingNotes: RosterBossDuck.getIsUpdatingNotes(state.rosterBosses),
		isAdmin,

		disabledViewRedirect: isAdmin
			? {isDisabled: false, redirectUrl: undefined}
			: props.guild.getDisabledViewInfo('roster'),

		userId: user ? user.id : undefined,
		roster
	};
}

export default connect<IMapProps, IDispatchProps, IOwnProps>(mapStateToProps, {
	onFetchBossKills: BossKillsDuck.fetchForInstance,
	onUpdateNotes: RosterBossDuck.updateNotes,
	onUpdateLimit: RosterBossDuck.updateLimit,
	onUnrosterAll: RosterBossDuck.unrosterAll,
	onRoster: RosterBossDuck.rosterCharacter
})(RealRosterWrapper);
