import React from 'react';

import type {BossViewType} from '@constants';

import type {RosterBossDuck} from '@ducks';

import {useModal} from '@hooks/useModal';

import type {RosterBoss} from '@models/roster-boss';
import type {IRaidBoss} from '@models/raid-data';

import {Markdown} from '@components/Utils/Markdown';
import {BossView, BossContent} from '../BossElements';
import {BossNotesEditor} from './BossNotesEditor';
import {BossHeading} from './BossHeading';

interface NotesProps {
	notes: string | null;
}

const Notes: React.FC<NotesProps> = (props) => {
	return (
		<div className="notes">
			{props.notes ? (
				<Markdown content={props.notes} />
			) : (
				<div className="no-notes">There are no notes for this boss</div>
			)}
		</div>
	);
};

interface BossNotesViewProps {
	onUpdateNotes: BoundThunk<typeof RosterBossDuck['updateNotes']>;
	onChangeView(view: BossViewType): void;

	viewType: BossViewType;

	isUpdatingNotes: boolean;
	isAdmin: boolean;

	rosterBoss: RosterBoss;
	raidBoss: IRaidBoss;
}

export const BossNotesView: React.FC<BossNotesViewProps> = (props) => {
	const editNotesModal = useModal();

	function handleNotesUpdate(notes: string) {
		void props.onUpdateNotes(props.rosterBoss.id, notes, editNotesModal.close);
	}

	return (
		<>
			{editNotesModal.isOpen && (
				<BossNotesEditor
					onSubmit={handleNotesUpdate}
					onClose={editNotesModal.close}
					isSubmitting={props.isUpdatingNotes}
					notes={props.rosterBoss.notes}
					name={props.raidBoss.name}
				/>
			)}

			<BossView viewName="boss-notes-view">
				<BossHeading
					onEditNotes={editNotesModal.open}
					onChangeView={props.onChangeView}
					viewType={props.viewType}
					isAdmin={props.isAdmin}
					bossName={props.raidBoss.name}
					notes={props.rosterBoss.notes}
				/>

				<BossContent
					isBossy={true}
					wowBossId={props.rosterBoss.bossId}
					leftColumn={null}
					rightColumn={() => <Notes notes={props.rosterBoss.notes} />}
				/>
			</BossView>
		</>
	);
};
