import React from 'react';

import {getPricingDataForStripePriceId} from '@shared/billing/plans';

import {connect} from '@ducks';

import type {Guild} from '@models/guild';

import {SponsorshipItem} from './SponsorshipItem';
import {Button} from '../../Utils/Button';
import {Modal} from '../../Utils/Modal';

interface IOwnProps {
	onSubmit(sponsorGuildIds: number[]): void;
	onClose(): void;

	isActioning: boolean;

	guilds: Guild[];
	initialSponsorGuilIds: number[];
	stripePriceId: string;
}

interface IMapProps {
	maxSponsorCount: number;
}

type IProps = IMapProps & IOwnProps;

const SelectGuildsModalComp: React.FC<IProps> = (props) => {
	const [pendingGuildIds, setPendingGuildIds] = React.useState<number[]>(
		props.initialSponsorGuilIds
	);
	const canSponsorMore = pendingGuildIds.length < props.maxSponsorCount;

	function addToSponsored(guildId: number): void {
		if (!pendingGuildIds.includes(guildId)) {
			setPendingGuildIds([...pendingGuildIds, guildId]);
		}
	}

	function removeFromSponsored(guildId: number): void {
		setPendingGuildIds(pendingGuildIds.filter((x) => x !== guildId));
	}

	const items = props.guilds.map((guild) => (
		<SponsorshipItem
			key={guild.id}
			onUnsponsor={() => removeFromSponsored(guild.id)}
			onSponsor={() => addToSponsored(guild.id)}
			canSponsorMore={canSponsorMore}
			willBeSponsored={pendingGuildIds.includes(guild.id)}
			guildRealm={guild.realm}
			guildName={guild.name}
			guildId={guild.id}
		/>
	));

	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content select-sponsorships-modal">
				<div className="heading">
					{props.maxSponsorCount === 1
						? 'Select a guild to buff'
						: 'Select guilds to buff'}
				</div>

				<div className="buffs-info">
					<div className="buff-usage">
						<span className="stats">
							{pendingGuildIds.length} / {props.maxSponsorCount}
						</span>
						&#32;guild buffs used
					</div>

					<div className="buffs">{items}</div>
				</div>

				<div className="actions">
					<Button onClick={props.onClose} className="grey">
						Back
					</Button>

					<Button
						onClick={() => props.onSubmit(pendingGuildIds)}
						isDisabled={!pendingGuildIds.length}
						isLoading={props.isActioning}
						className="primary"
					>
						Checkout
					</Button>
				</div>
			</div>
		</Modal>
	);
};

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const pricingData = getPricingDataForStripePriceId(props.stripePriceId);

	return {
		maxSponsorCount: pricingData.maxBuffedGuilds
	};
}

export const SelectGuildsModal = connect<IMapProps, {}, IOwnProps>(
	mapStateToProps,
	{}
)(SelectGuildsModalComp);
