import React from 'react';

import {BrandName} from '../../../Utils/BrandName';

export const NoBuffsWarning: React.FC<{}> = () => {
	return (
		<div className="no-buffed-guilds">
			<i className="lnr lnr-warning" />

			<div className="right">
				<span>
					You have not selected any guilds to be buffed and gain the <BrandName />{' '}
					perks
				</span>
			</div>
		</div>
	);
};
