interface ITagData {
	guildId: number;
	name: string;
	order: number;
}

export interface ITagClass extends ITagData {
	id: number;
	isDeleted: boolean;
}

export class Tag implements ITagClass {
	readonly id: number;
	readonly guildId: number;

	readonly isDeleted: boolean;

	readonly name: string;
	readonly order: number;

	constructor(data?: ITagClass) {
		if (!data) throw new Error('invalid tag data');

		this.id = data.id;
		this.guildId = data.guildId;

		this.isDeleted = data.isDeleted;

		this.name = data.name;
		this.order = Number(data.order);
	}
}
