import React from 'react';

import type {Difficulties} from '@constants/wow';
import {BossViewType} from '@constants';

import {
	connect,
	RosterBossDuck,
	CooldownSheetDuck,
	CooldownEventDuck,
	CooldownActionDuck
} from '@ducks';

import type {RaidInstance, IRaidBoss} from '@models/raid-data';
import type {RosterBoss} from '@models/roster-boss';

import {BossRosterView} from './BossRosterView';
import {BossNotesView} from './BossNotesView';
import {BossCooldownView} from './BossCooldownView';

interface OwnProps {
	onFetchCooldownSheet: BoundThunk<
		typeof CooldownSheetDuck['fetchCooldownSheetForRosterBoss']
	>;
	onCreateCooldownEvent: BoundThunk<typeof CooldownEventDuck['createCooldownEvent']>;
	onUpdateCooldownEventName: BoundThunk<
		typeof CooldownEventDuck['updateNameCooldownEvent']
	>;
	onUpdateCooldownEventTime: BoundThunk<
		typeof CooldownEventDuck['updateTimeCooldownEvent']
	>;
	onReorderCooldownEvent: BoundThunk<typeof CooldownEventDuck['reorderCooldownEvent']>;
	onDeleteCooldownEvent: BoundThunk<typeof CooldownEventDuck['deleteCooldownEvent']>;
	onCreateCooldownAction: BoundThunk<typeof CooldownActionDuck['createCooldownAction']>;
	onUpdateCooldownActionActor: BoundThunk<
		typeof CooldownActionDuck['updateActorCooldownAction']
	>;
	onUpdateCooldownActionAbility: BoundThunk<
		typeof CooldownActionDuck['updateAbilityCooldownAction']
	>;
	onDeleteCooldownAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;
	onUpdateNotes: BoundThunk<typeof RosterBossDuck['updateNotes']>;
	onUpdateLimit: BoundThunk<typeof RosterBossDuck.updateLimit>;
	onUnrosterAll: BoundThunk<typeof RosterBossDuck['unrosterAll']>;
	onRoster: BoundThunk<typeof RosterBossDuck.rosterCharacter>;
	onSearchCharacter(charId: CharacterId): void;

	isUpdatingNotes: boolean;
	isAdmin: boolean;
	isDemo: boolean;

	initialBossView: BossViewType;
	difficulty: Difficulties;
	raidInstance: RaidInstance;
	rosterBossId: RosterBossId;
	includedTagIds: TagId[];
	excludedTagIds: TagId[];
	rosterId: RosterId;
}

interface MapProps {
	rosterBoss: RosterBoss | undefined;
	raidBoss: IRaidBoss | undefined;
}

type Props = OwnProps & MapProps;

const BossViewComp: React.FC<Props> = (props) => {
	const [viewType, setViewType] = React.useState(props.initialBossView);

	// handle the boss not existing
	if (!props.rosterBoss || !props.raidBoss) return null;

	// ensure we only ever render bosses that are actually from the same roster
	if (props.rosterBoss.rosterId !== props.rosterId) return null;

	if (viewType === BossViewType.ROSTER) {
		return (
			<BossRosterView
				onUnrosterAll={props.onUnrosterAll}
				onChangeView={setViewType}
				onSearchCharacter={props.onSearchCharacter}
				onUpdateLimit={props.onUpdateLimit}
				onRoster={props.onRoster}
				viewType={viewType}
				isShowingKillCount={props.raidInstance.isKillCountSupported}
				isAdmin={props.isAdmin}
				isDemo={props.isDemo}
				rosterBoss={props.rosterBoss}
				raidBoss={props.raidBoss}
				wowheadBonus={props.raidInstance.wowheadBonuses[props.difficulty]}
				difficulty={props.difficulty}
				includedTagIds={props.includedTagIds}
				excludedTagIds={props.excludedTagIds}
			/>
		);
	}

	if (viewType === BossViewType.NOTES) {
		return (
			<BossNotesView
				onUpdateNotes={props.onUpdateNotes}
				onChangeView={setViewType}
				viewType={viewType}
				isUpdatingNotes={props.isUpdatingNotes}
				isAdmin={props.isAdmin}
				rosterBoss={props.rosterBoss}
				raidBoss={props.raidBoss}
			/>
		);
	}

	if (viewType === BossViewType.COOLDOWN_SHEET) {
		return (
			<BossCooldownView
				onFetchCooldownSheet={props.onFetchCooldownSheet}
				onCreateCooldownEvent={props.onCreateCooldownEvent}
				onUpdateCooldownEventName={props.onUpdateCooldownEventName}
				onUpdateCooldownEventTime={props.onUpdateCooldownEventTime}
				onReorderCooldownEvent={props.onReorderCooldownEvent}
				onDeleteCooldownEvent={props.onDeleteCooldownEvent}
				onCreateCooldownAction={props.onCreateCooldownAction}
				onUpdateCooldownActionActor={props.onUpdateCooldownActionActor}
				onUpdateCooldownActionAbility={props.onUpdateCooldownActionAbility}
				onDeleteCooldownAction={props.onDeleteCooldownAction}
				onChangeView={setViewType}
				isAdmin={props.isAdmin}
				isEditor={props.isAdmin}
				viewType={viewType}
				rosterBoss={props.rosterBoss}
				raidBoss={props.raidBoss}
			/>
		);
	}

	return null;
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const rosterBoss = RosterBossDuck.getForId(state.rosterBosses, props.rosterBossId);
	const raidBoss = rosterBoss
		? props.raidInstance.bosses.find((x) => x.id === rosterBoss.bossId)
		: undefined;

	return {
		rosterBoss,
		raidBoss
	};
}

export const BossView = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(BossViewComp);
