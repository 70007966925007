import {connect} from 'react-redux';
import difference from 'lodash/difference';
import dayjs from 'dayjs';

import * as wow from '../../constants/wow';
import {BossViewType} from '@constants';

import * as toolbox from '../../helpers/toolbox';

import {
	GuildSponsorshipDuck,
	BossKillsDuck,
	RaidDataDuck,
	CharacterDuck,
	RosterBossDuck,
	SettingsDuck,
	UserDuck,
	LootDuck,
	TagDuck
} from '@ducks';

import {
	BossKillByCharacterId,
	getBossKillByCharacterIdForEncounterId
} from './Container/bossKills';
import {getCharactersByUserId} from './Container/charactersByUserId';
import {
	AssignedRoleByCharacterId,
	calculateAssignedRoleByCharacterId
} from './Container/assignedRoleByCharacterId';
import {
	AssignmentsByUserId,
	calculateAssignmentsByUserId
} from './Container/assignmentsByUserId';
export type {AssignmentsByUserId, BossKillByCharacterId};

import type {IEncounterMode} from '../../models/boss-kills';
import type {IRosterBossClass} from '../../models/roster-boss';
import type {Roster} from '../../models/roster';
import type {LootOption} from '../../models/loot-option';
import type {Character} from '../../models/character';
import type {RaidItem} from '../../models/raid-data';
import type {Guild} from '../../models/guild';

import RosterView, {IRosterMapProps} from './RosterView';

export interface IRosterViewBoss extends IRosterBossClass {
	name: string;

	roleAssignments: {
		melee: number[];
		ranged: number[];
		healers: number[];
		tanks: number[];
	};

	bossKillByCharacterId: Map<number, IEncounterMode>;

	assignedRoleByCharacterId: Map<number, wow.ROLES>;
	assignmentsByUserId: AssignmentsByUserId;
}

interface DesiredItem {
	note: string | null;
	optionName: string;
	item: RaidItem;
}

interface ICharMeta {
	assignedBossCount: number;

	/** Whether the lootsheet should be classes as outdated */
	isLootsheetOutdated: boolean;
	/** The point at which the lootsheet was last updated */
	lootsheetAgeInDays: number;

	coiningWowBossIds: string[];

	itemsByWowBossId: Record<WowBossId, DesiredItem[]>;
}

export interface ICharMetaMap {
	[key: number]: ICharMeta;
}

interface ICharacterMap {
	[key: number]: Character;
}

interface ICharacterChange {
	id: number;
	class: string;
	name: string;
	change: 'added' | 'removed';
}

interface IChangeSet {
	[key: string]: ICharacterChange[];
}

interface IChanges {
	[key: number]: IChangeSet;
}

interface IOwnProps {
	onFetchBossKills: typeof BossKillsDuck.fetchForInstance;
	onUpdateNotes: typeof RosterBossDuck.updateNotes;
	onUpdateLimit: typeof RosterBossDuck.updateLimit;
	onRoster: typeof RosterBossDuck.rosterCharacter;

	isUpdatingNotes: boolean;
	isAdmin: boolean;
	isDemo: boolean;

	userId?: number;
	roster: Roster;
	guild: Guild;
}

function calculateChanges(bosses: IRosterViewBoss[], charMap: ICharacterMap) {
	const changes: IChanges = {};

	const dummyRoleAssignments: {[key: string]: number[]} = {};
	Object.values(wow.ROLES_ORDERED).forEach((role) => (dummyRoleAssignments[role] = []));

	bosses.forEach((boss, i) => {
		const changesForThisBoss: IChangeSet = {};

		const current = boss.roleAssignments as {[key: string]: number[]};
		let previous = dummyRoleAssignments;
		if (i !== 0) previous = bosses[i - 1].roleAssignments as {[key: string]: number[]};

		Object.keys(current).forEach((role) => {
			const changesForRole: ICharacterChange[] = [];

			const left = difference<number>(previous[role], current[role]);
			const joined = difference<number>(current[role], previous[role]);

			left.forEach((charId) => {
				const char = charMap[charId];
				if (!char) return;

				const change: ICharacterChange = {
					id: char.id,
					name: char.name,
					class: char.class,
					change: 'removed'
				};

				changesForRole.push(change);
			});

			joined.forEach((charId) => {
				const char = charMap[charId];
				if (!char) return;

				const change: ICharacterChange = {
					id: char.id,
					name: char.name,
					class: char.class,
					change: 'added'
				};

				changesForRole.push(change);
			});

			changesForRole.sort((a, b) => {
				if (a.change !== b.change) {
					return a.change < b.change ? -1 : 1;
				}

				return toolbox.sortCharactersByName(a, b);
			});

			changesForThisBoss[role] = changesForRole;
		});

		changes[boss.id] = changesForThisBoss;
	});

	return changes;
}

interface ICalculateMeta {
	state: IRootState;
	characters: Character[];
	bosses: IRosterViewBoss[];
	difficulty: string;
	guildId: number;
}

function calculateCharacterMeta({
	state,
	characters,
	bosses,
	guildId,
	difficulty
}: ICalculateMeta) {
	const charMetaMap: ICharMetaMap = {};

	const wowBossIds = new Set(bosses.map((boss) => boss.bossId));

	const itemMap: {[key: string]: RaidItem} = {};
	RaidDataDuck.getAllRaidItems(state.raidData).forEach((i) => (itemMap[i.id] = i));

	const optionMap: {[key: number]: LootOption} = {};
	LootDuck.getOptionsForGuild(state.loot, guildId).forEach((o) => (optionMap[o.id] = o));

	// make a quick look up map to find out if a character is assigned to a boss
	// so we don't have to iterate over each role for each boss for each character
	const allAssignmentsByBossId: {[key: number]: number[]} = {};
	bosses.forEach((boss) => {
		let assignments: number[] = [];
		Object.values(boss.roleAssignments).forEach(
			(a) => (assignments = [...assignments, ...a])
		);
		allAssignmentsByBossId[boss.id] = assignments;
	});

	characters.forEach((char) => {
		const selections = LootDuck.getSelectionsForCharacter(state.loot, char.id);
		const coins = LootDuck.getCoinsForCharacter(state.loot, char.id).filter((coin) =>
			wowBossIds.has(coin.wowBossId)
		);

		let isLootsheetOutdated = false;
		let lootsheetAgeInDays = 0;
		if (char.lootUpdatedAt) {
			lootsheetAgeInDays = dayjs().diff(char.lootUpdatedAt, 'days');
			isLootsheetOutdated = lootsheetAgeInDays >= 7;
		}

		const meta: ICharMeta = {
			assignedBossCount: 0,
			itemsByWowBossId: {},

			isLootsheetOutdated,
			lootsheetAgeInDays,

			coiningWowBossIds: coins.map((coin) => coin.wowBossId)
		};

		bosses.forEach((boss) => {
			// bump the boss count if they're assigned to the boss for any role
			if (allAssignmentsByBossId[boss.id].includes(char.id)) {
				meta.assignedBossCount += 1;
			}

			// items
			meta.itemsByWowBossId[boss.bossId] = selections.reduce<DesiredItem[]>(
				(desires, selection) => {
					if (selection.difficulty !== difficulty) return desires;

					const item = itemMap[selection.wowItemId];
					if (!item || item.sourceId !== boss.bossId) return desires;

					const option = optionMap[selection.optionId];

					// don't show selections from options that are set to be hidden on the roster
					if (!option?.isVisibleInRoster) return desires;

					desires.push({
						optionName: option.name,
						note: selection.note,
						item
					});

					return desires;
				},
				[]
			);
		});

		charMetaMap[char.id] = meta;
	});

	return charMetaMap;
}

function createRoleGroups(characters: Character[], isAlphabeticalSort: boolean) {
	characters.sort(
		isAlphabeticalSort
			? toolbox.sortCharactersByName
			: toolbox.sortCharactersByClassAndName
	);

	const roleGroups: {[key: string]: Character[]} = {};

	Object.values(wow.ROLES_ORDERED).forEach((role) => {
		roleGroups[role] = characters.filter((char) => char.roles.includes(role));
	});

	return roleGroups;
}

interface IFilterCharactersBasedOnTags {
	tagState: IRootState['tag'];
	rosterTagIds: number[];
	isWhitelist: boolean;
	characters: Character[];

	/**
	 * a map of characters who are actually rostered to a boss that get
	 * included in the returned character set regardless of whether they
	 * pass the tag filtering process
	 */
	rosteredCharacterMap: {[charId: number]: boolean};
}

export function filterCharactersBasedOnTags(data: IFilterCharactersBasedOnTags) {
	const tagsIds = data.rosterTagIds.filter((tagId) => {
		const tag = TagDuck.getTag(data.tagState, tagId);
		if (!tag || tag.isDeleted) return false;
		return true;
	});

	// if no valid tags just return original characters
	if (!tagsIds.length) return data.characters;

	const taggedCharacters: {[charId: number]: boolean} = {};

	// go through each tag and work out which characters are part of the tags
	data.rosterTagIds.forEach((tagId) => {
		const assignments = TagDuck.getAssignmentsByTagId(data.tagState, tagId);
		const assignmentByCharacterId: {[charId: number]: boolean} = {};
		assignments.forEach(
			(assignment) => (assignmentByCharacterId[assignment.characterId] = true)
		);

		data.characters.forEach((char) => {
			if (assignmentByCharacterId[char.id]) taggedCharacters[char.id] = true;
		});
	});

	// filter characters based on the tagging process
	const taggedCharacterIds = Object.keys(taggedCharacters);
	let newCharacters: Character[];
	if (data.isWhitelist) {
		newCharacters = data.characters.filter(
			(char) =>
				!!data.rosteredCharacterMap[char.id] ||
				taggedCharacterIds.includes(`${char.id}`)
		);
	} else {
		newCharacters = data.characters.filter(
			(char) =>
				data.rosteredCharacterMap[char.id] ||
				!taggedCharacterIds.includes(`${char.id}`)
		);
	}

	return newCharacters;
}

export function addRoleAssignmentsToRosteredMap(
	roleAssignments: RosterBossDuck.IBossAssignments,
	rosteredCharacterMap: {[charId: number]: boolean}
) {
	roleAssignments.melee.forEach((charId) => (rosteredCharacterMap[charId] = true));
	roleAssignments.ranged.forEach((charId) => (rosteredCharacterMap[charId] = true));
	roleAssignments.healers.forEach((charId) => (rosteredCharacterMap[charId] = true));
	roleAssignments.tanks.forEach((charId) => (rosteredCharacterMap[charId] = true));
}

function mapStateToProps(state: IRootState, props: IOwnProps): IRosterMapProps {
	const isAlphabeticalSort = SettingsDuck.getRosterIsAlphabeticalSort(state.settings);

	const rosterBosses = RosterBossDuck.getBossesForRoster(
		state.rosterBosses,
		props.roster.id
	);
	const raidInstance = RaidDataDuck.getRaidInstance(
		state.raidData,
		props.roster.wowInstanceId
	);

	const guildCharacters = CharacterDuck.getCharactersForGuild(
		state.characters,
		props.roster.guildId,
		{
			withMissing: true
		}
	);

	const characterById = new Map<number, Character>();
	const charMap: ICharacterMap = {};
	guildCharacters.forEach((char) => {
		charMap[char.id] = char;
		characterById.set(char.id, char);
	});

	const charactersByUserId = getCharactersByUserId(guildCharacters);

	const isShowingAdminControls =
		props.isAdmin && SettingsDuck.getRosterIsShowingAdminControls(state.settings);
	const isUsingTagging =
		!!props.roster.tagWhitelist.length || !!props.roster.tagBlacklist.length;
	const rosteredCharacterMap: {[charId: number]: boolean} = {};

	// build up the list of boss data to be shown
	const bosses: IRosterViewBoss[] = rosterBosses.map((rosterBoss) => {
		const raidBoss = raidInstance
			? raidInstance.bosses.find((b) => b.id === rosterBoss.bossId)
			: undefined;

		const roleAssignments = RosterBossDuck.getAssignmentsForBoss(
			state.rosterBosses,
			rosterBoss.id
		);
		if (!isUsingTagging) {
			addRoleAssignmentsToRosteredMap(roleAssignments, rosteredCharacterMap);
		}

		const assignedRoleByCharacterId: AssignedRoleByCharacterId = isShowingAdminControls
			? calculateAssignedRoleByCharacterId(roleAssignments)
			: new Map();
		const assignmentsByUserId: AssignmentsByUserId = isShowingAdminControls
			? calculateAssignmentsByUserId({
					charactersByUserId,
					assignedRoleByCharacterId
			  })
			: new Map();

		const bossKillByCharacterId: BossKillByCharacterId =
			isShowingAdminControls && raidInstance?.isKillCountSupported && raidBoss
				? getBossKillByCharacterIdForEncounterId({
						bossKillState: state.bossKills,
						difficulty: props.roster.difficulty,
						encounterId: raidBoss.encounterId
				  })
				: new Map();

		const boss: IRosterViewBoss = {
			...rosterBoss,

			name: raidBoss ? raidBoss.name : `Boss ${rosterBoss.bossId}`,
			bossKillByCharacterId,
			assignedRoleByCharacterId,
			assignmentsByUserId,
			roleAssignments
		};

		return boss;
	});

	// ascending order
	bosses.sort((a, b) => a.order - b.order);

	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		props.roster.guildId
	);

	let characters = guildCharacters;
	if (isUsingTagging) {
		characters = filterCharactersBasedOnTags({
			tagState: state.tag,
			rosterTagIds: props.roster.tagWhitelist,
			isWhitelist: true,
			rosteredCharacterMap,
			characters
		});

		characters = filterCharactersBasedOnTags({
			tagState: state.tag,
			rosterTagIds: props.roster.tagBlacklist,
			isWhitelist: false,
			rosteredCharacterMap,
			characters
		});
	}

	let characterMetaMap: ICharMetaMap = {};
	if (props.isAdmin) {
		const canShowBasicLootInRoster = fc.canShowBasicLootInRoster();

		if (canShowBasicLootInRoster) {
			characterMetaMap = calculateCharacterMeta({
				difficulty: props.roster.difficulty,
				guildId: props.roster.guildId,
				characters,
				bosses,
				state
			});
		}
	}

	let initialViewType: BossViewType | undefined;
	const rosterViewInfo = props.guild.getRosterViewInfo({
		isAdmin: props.isAdmin,
		featureChecker: fc
	});
	if (rosterViewInfo.canUseAssignmentsView) initialViewType = BossViewType.ROSTER;
	if (rosterViewInfo.canUseNotesView) initialViewType = BossViewType.NOTES;

	return {
		isUpdatingNotes: props.isUpdatingNotes,
		isAdmin: props.isAdmin,
		isDemo: props.isDemo,

		reorderRostersUrl: `/guild/${props.roster.guildId}/guild-settings/roster-bosses/${props.roster.id}`,

		encounterInstanceId: raidInstance?.encounterInstanceId || '',
		isKillCountSupported: raidInstance?.isKillCountSupported || false,

		wowheadBonus: raidInstance
			? raidInstance.wowheadBonuses[props.roster.difficulty]
			: '',

		changes: props.isAdmin ? calculateChanges(bosses, charMap) : undefined,
		roleGroups: createRoleGroups(characters, isAlphabeticalSort),
		characterMetaMap,
		characterById,

		roster: props.roster,
		bosses,

		initialBossView: initialViewType,

		canUseAssignmentsView: rosterViewInfo.canUseAssignmentsView,
		canUseNotesView: rosterViewInfo.canUseNotesView,

		filterOptions: characters.sort(toolbox.sortCharactersByName).map((char) => ({
			label: char.name,
			value: char.id
		})),

		selectedRole: SettingsDuck.getRosterSelectedRole(state.settings),
		isShowingChanges: SettingsDuck.getRosterIsShowingChanges(state.settings),
		isShowingAdminControls,
		isAlphabeticalSort
	};
}

export default connect(mapStateToProps, {
	onToggleAdminControls: SettingsDuck.setRosterIsShowingAdminControls,
	onToggleAlphabeticalSort: SettingsDuck.setRosterIsAlphabeticalSort,
	onToggleChanges: SettingsDuck.setRosterIsShowingChanges,
	onSelectRole: SettingsDuck.setRosterSelectedRole,
	onEnterSettings: UserDuck.setUrl
})(RosterView);
