import React from 'react';
import classnames from 'classnames';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

interface IProps {
	onClick(): void;
	isSubmitting: boolean;
}

export const BumpButton: React.FC<IProps> = (props) => {
	const buttonClass = classnames(
		{
			disabled: props.isSubmitting
		},
		'primary button'
	);

	return (
		<div className="bump-button-container">
			<LaddaButton
				onClick={props.onClick}
				className={buttonClass}
				loading={props.isSubmitting}
				data-style={ZOOM_OUT}
			>
				Mark loot as current
			</LaddaButton>
		</div>
	);
};
