import type {IUserCharacter} from '../types';

import {NO_GUILD_STRING} from '../constants';
import {UNKNOWN_CHARACTER_RANK} from '../constants/wow';

/** Group elements of an array by a certain property of theirs */
export function groupByProperty<Element, Prop extends keyof Element>(
	elements: readonly Element[],
	prop: Prop
): Map<Element[Prop], Element[]> {
	return elements.reduce<Map<Element[Prop], Element[]>>(
		(map, element): Map<Element[Prop], Element[]> => {
			const key = element[prop];

			map.set(key, [...(map.get(key) || []), element]);

			return map;
		},
		new Map()
	);
}

/** Shallowly removes duplicates in an array */
export function dedupeArray<T>(array: T[]): T[] {
	return [...new Set(array)];
}

export function filterUserCharactersToGuild(data: {
	characters: IUserCharacter[];
	guildRegion: string;
	guildRealm: string;
	guildName: string;
}): IUserCharacter[] {
	return data.characters.filter(
		(char) =>
			char.guildName === data.guildName &&
			char.guildRealm === data.guildRealm &&
			char.region === data.guildRegion
	);
}

export interface CharacterComboFields {
	region: string;
	guildRealm: string | undefined;
	guildName: string | undefined;
	realm: string;
	name: string;
}

export function createCharacterCombo(data: CharacterComboFields): string {
	// preference guildRealm, but fall back to realm
	// XXX should guildRealm actually be preferenced?
	const realm = data.guildRealm || data.realm;
	const guildName = data.guildName || NO_GUILD_STRING;

	// XXX don't change this unless migrating all existing characters
	return `${data.region}-${realm}-${guildName}-${data.name}`;
}

const IMPORT_CHARACTER_SEPARATOR = '~~';

export function composeImportCharacterCombo(data: {name: string; realm: string}): string {
	return [data.name, data.realm].join(IMPORT_CHARACTER_SEPARATOR);
}

export function decomposeImportCharacterCombo(combo: string): {
	name: string;
	realm: string;
} {
	const [name, realm] = combo.split(IMPORT_CHARACTER_SEPARATOR);
	return {name, realm};
}

interface SortName {
	name?: string;
	characterName?: string;
}

interface SortClass {
	class?: string;
	characterClass?: string;
}

type SortClassAndName = SortClass & SortName;

export function sortCharactersByClassAndName(a: SortClassAndName, b: SortClassAndName) {
	const ac = (a.class || a.characterClass)!;
	const bc = (b.class || b.characterClass)!;
	const an = (a.name || a.characterName)!;
	const bn = (b.name || b.characterName)!;

	if (ac !== bc) {
		return ac > bc ? -1 : 1;
	}

	return an < bn ? -1 : 1;
}

export function sortCharactersByName(a: SortName, b: SortName) {
	const aName = (a.name || a.characterName)!;
	const bName = (b.name || b.characterName)!;

	return aName.localeCompare(bName);
}

/** Add the "unknown" rank to the rank list */
export function addUnknownRank(list: readonly string[]): string[] {
	const copy = [...list];
	copy[UNKNOWN_CHARACTER_RANK] = 'Unknown rank';
	return copy;
}
