import React from 'react';
import {connect} from 'react-redux';

import * as toolbox from '../../helpers/toolbox';
import {setRecorderTag} from '../../helpers/recorder';

import * as ReimportDuck from '../../ducks/reimport';
import * as GuildDuck from '../../ducks/guild';

import type {Character, ICharacterData} from '../../models/character';

import Is404 from './Is404';
import IsLoading from './IsLoading';
import Reimport from './Reimport';

export interface IRankGroup {
	characters: (Character | ICharacterData)[];
	rankName: string;
	rank: number;
}

interface IProps {
	onInitiate: typeof ReimportDuck.initiate;
	onSubmit: typeof ReimportDuck.submit;
	onReset: typeof ReimportDuck.reset;

	isSubmitting: boolean;
	isInitiationRequred: boolean;
	isLoading: boolean;
	is404: boolean;

	rankGroups: IRankGroup[];
	guildName: string;
}

class ReimportContainer extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		this.handleInitiate();

		setRecorderTag('is_using_reimport', 'true');
	}

	handleInitiate() {
		this.props.onInitiate();
	}

	render() {
		if (this.props.isLoading) return <IsLoading name={this.props.guildName} />;
		if (this.props.is404) return <Is404 name={this.props.guildName} />;

		return (
			<Reimport
				onSubmit={this.props.onSubmit}
				isSubmitting={this.props.isSubmitting}
				guildName={this.props.guildName}
				rankGroups={this.props.rankGroups}
			/>
		);
	}
}

function makeRankGroups(rankNames: string[], characters: (Character | ICharacterData)[]) {
	const rankGroups: {[key: string]: IRankGroup} = {};

	characters.forEach((char) => {
		if (!rankGroups[char.rank]) {
			rankGroups[char.rank] = {
				rankName: rankNames[char.rank],
				rank: char.rank,
				characters: []
			};
		}

		rankGroups[char.rank].characters.push(char);
	});

	return Object.values(rankGroups).sort((a, b) => a.rank - b.rank);
}

function mapStateToProps(state: IRootState) {
	const isLoading = ReimportDuck.getLoading(state.reimport);
	const isLoaded = ReimportDuck.getLoaded(state.reimport);

	const guild = GuildDuck.getActiveGuild(state.guilds);

	const rankNames = guild ? guild.normalRankNames : [];
	const characters = ReimportDuck.getMembers(state.reimport);
	characters.sort(toolbox.sortCharactersByName);

	return {
		isSubmitting: ReimportDuck.getSubmitting(state.reimport),
		is404: ReimportDuck.get404(state.reimport),
		isInitiationRequred: !isLoaded && !isLoading,
		isLoading,

		rankGroups: makeRankGroups(rankNames, characters),
		guildName: guild ? guild.name : ''
	};
}

export default connect(mapStateToProps, {
	onInitiate: ReimportDuck.initiate,
	onSubmit: ReimportDuck.submit,
	onReset: ReimportDuck.reset
})(ReimportContainer);
