import type * as LootDuck from '.';

import type {LootSelection} from '../../models/loot-selection';
import type {LootCoin} from '../../models/loot-coin';
import type {LootOption} from '@models/loot-option';

export function getIsSubmittingRclc(state: LootDuck.IState): boolean {
	return state.isSubmittingRclc;
}

export function getIsBumping(state: LootDuck.IState) {
	return state.isBumping;
}

export function getOptionsSubmitting(state: LootDuck.IState) {
	return state.isSubmitting;
}

export function getOptionsDeleting(state: LootDuck.IState) {
	return state.isDeleting;
}

export function getOption(state: LootDuck.IState, id: number): LootOption | undefined {
	return state.optionsById[id];
}

export function getOptionsForGuild(
	state: LootDuck.IState,
	guildId: number | undefined,
	withoutDeleted = false
) {
	let options = Object.values(state.optionsById).filter(
		(option) => option.guildId === guildId
	);

	if (withoutDeleted) {
		options = options.filter((option) => !option.isDeleted);
	}

	options.sort((a, b) => b.order - a.order);

	return options;
}

export function getSelectionsForCharacter(
	state: LootDuck.IState,
	charId: number
): LootSelection[] {
	return state.selectionsByCharacterId[charId] || [];
}

export function getCoinsForCharacter(state: LootDuck.IState, charId: number): LootCoin[] {
	return state.coinsByCharacterId[charId] || [];
}
