import React from 'react';

import {useModal} from '@hooks/useModal';

import {SwitchField} from '@components/Utils/Switch';
import {ConfirmModal} from '@components/Utils/ConfirmModal';
import {BrandName} from '@components/Utils/BrandName';

interface ImportAdminProps {
	onToggle(isToggled: boolean): Promise<void>;

	isImporterAdmin: boolean;
	isUserImporter: boolean;
}

export const ImporterAdmin: React.FC<ImportAdminProps> = (props) => {
	const confirmRemovalModal = useModal();

	function handleToggle(isToggled: boolean): void {
		if (isToggled || !props.isUserImporter) {
			void props.onToggle(isToggled);
			return;
		}

		confirmRemovalModal.open();
	}

	return (
		<>
			{confirmRemovalModal.isOpen && (
				<ConfirmModal
					onClose={confirmRemovalModal.close}
					onSubmit={() => props.onToggle(false).then(confirmRemovalModal.close)}
					isSubmitting={false}
					heading="Admin Removal"
					submitButtonText="Confirm"
					submitColour="red"
				>
					This will remove your admin privledges unless you have a character in one
					of the other admin ranks.
				</ConfirmModal>
			)}

			<div className="settings-field-group">
				<div className="group-heading">Additional admins</div>

				<div className="group-item">
					<SwitchField
						onChange={handleToggle}
						isControlled={true}
						label="User who set up Readycheck is an admin"
						value={props.isImporterAdmin}
						note={
							<>
								<span>Should the person who initially added the guild to </span>
								<BrandName />
								<span> be an admin?</span>
							</>
						}
					/>
				</div>
			</div>
		</>
	);
};
