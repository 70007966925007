import React from 'react';
import {syncHistoryWithStore} from 'react-router-redux';
import {
	Router,
	IndexRedirect,
	IndexRoute,
	Route,
	browserHistory,
	Redirect
} from 'react-router';

import {PathSegments} from '@helpers/urls';

import ImageBackground from './components/Utils/ImageBackground';

import AuthedContainer from './components/AuthedContainer';
import {IndexRedirectContainer} from './components/IndexRedirect';
import GeneralContainer from './components/GeneralContainer';

import SignIn from './components/Auth/SignIn';

import {BillingContainer} from './components/Billing/BillingContainer';
import {ChangePlanContainer} from './components/Billing/ChangePlan/ChangePlanContainer';

import SelectGuildContainer from './components/SelectGuild/SelectGuildContainer';

import SetupGuildContainer from './components/SetupGuild/SetupGuildContainer';
import ReimportContainer from './components/Reimport/ReimportContainer';

import UserSettingsContainer from './components/UserSettings/UserSettingsContainer';
import MyAccount from './components/UserSettings/MyAccount';

import GuildContainer from './components/Guild/GuildContainer';
import RedirectToDefaultRosterContainer from './components/Guild/RedirectToDefaultRosterContainer';
import SelectRolesContainer from './components/SelectRoles/SelectRolesContainer';
import RealRosterWrapper from './components/RosterOld/RealRosterWrapper';
import RealLootWrapper from './components/Loot/RealLootWrapper';
import RosterListContainer from './components/RosterList/RosterListContainer';

import {RealRosterPage} from './components/RosterNew/Page/RealRosterPage';

import GuildSettingsContainer from './components/GuildSettings/GuildSettingsContainer';
import RosterSettingsContainer from './components/GuildSettings/Rosters/RosterSettingsContainer';
import LootSettingsContainer from './components/GuildSettings/Loot/LootSettingsContainer';
import RankSettingsContainer from './components/GuildSettings/Ranks/RankSettingsContainer';
import AdminSettingsContainer from './components/GuildSettings/Admins/AdminSettingsContainer';
import RemoveCharactersContainer from './components/GuildSettings/RemoveCharacters/RemoveCharactersContainer';
import TagSettingsContainer from './components/GuildSettings/Tagging/TaggingContainer';
import {MiscSettingsContainer} from './components/GuildSettings/Misc/MiscSettingsContainer';
import {AutoInviteContainer} from './components/GuildSettings/AutoInvite/AutoInviteContainer';
import {RosterBosses} from './components/GuildSettings/RosterBosses/RosterBosses';
import {Roles} from './components/GuildSettings/Roles/Roles';
import {ExternalCharacters} from './components/GuildSettings/ExternalCharacters/ExternalCharacters';

import DemoContainer from './components/Demo/DemoContainer';
import DemoLootWrapper from './components/Demo/DemoLootWrapper';
import DemoRosterWrapper from './components/Demo/DemoRosterWrapper';

import {RclcView} from './components/Rclc/RclcView';

import {DroptimizerReportsView} from './components/DroptimizerReports';

import {AllViewsDisabled} from '@components/AllViewsDisabled';

function ScrollToTop(Comp: any) {
	return (props: any) => {
		window.scrollTo(0, 0);
		return <Comp {...props} />;
	};
}

export default function makeRouter(store: any) {
	const history = syncHistoryWithStore(browserHistory, store);

	return (
		<Router history={history}>
			<Redirect from="/sign-in" to="/connect" />

			<Route path="/connect" component={ImageBackground}>
				<IndexRoute component={SignIn} />
			</Route>

			<Route path="/demo" component={DemoContainer}>
				<IndexRedirect to="roster" />

				<Route
					path={`${PathSegments.DEMO_ROSTER}(/:rosterBossId)`}
					component={DemoRosterWrapper}
					isHeaderFixed={true}
				/>

				<Route path={PathSegments.LOOT} component={DemoLootWrapper} />
			</Route>

			<Route path="/" component={AuthedContainer}>
				<IndexRoute component={IndexRedirectContainer} />

				<Route path="user-settings" component={UserSettingsContainer}>
					<IndexRedirect to="my-account" />

					<Route path="my-account" component={MyAccount} />
				</Route>

				<Route component={GeneralContainer}>
					<Route path={PathSegments.USER_BILLING_NEW} component={ImageBackground}>
						<IndexRoute component={ScrollToTop(BillingContainer)} />

						<Route
							path={PathSegments.CHANGE_PLAN}
							component={ScrollToTop(ChangePlanContainer)}
						/>
					</Route>

					<Route path="select-guild" component={ImageBackground}>
						<IndexRoute component={SelectGuildContainer} />
					</Route>

					<Route
						path="setup-guild/:region/:realm/:name"
						component={SetupGuildContainer}
					/>
				</Route>

				<Route path={`${PathSegments.GUILD}/:guildId`} component={GuildContainer}>
					<IndexRoute component={RedirectToDefaultRosterContainer} />

					{/* Was the old guild billing route, retired around June 2021 */}
					<Redirect from="billing" to="/" />

					<Route
						path={PathSegments.ALL_VIEWS_DISABLED}
						component={AllViewsDisabled}
					/>

					<Route
						path={PathSegments.GUILD_SETTINGS}
						component={GuildSettingsContainer}
					>
						<IndexRedirect to="add-characters" />
						<Redirect from="auto-add-characters" to="add-characters" />

						<Route path="admins" component={AdminSettingsContainer} />

						<Route path="rank-names">
							<IndexRedirect to="0" />

							<Route path=":index" component={RankSettingsContainer} />
						</Route>

						<Route path="misc" component={MiscSettingsContainer} />

						<Route path={PathSegments.GUILD_SETTINGS_ROSTERS}>
							<IndexRedirect to="new" />

							<Route path=":rosterId" component={RosterSettingsContainer} />
						</Route>

						<Route path="roster-bosses/:rosterId" component={RosterBosses} />

						<Route path="loot-options">
							<IndexRedirect to="new" />

							<Route path=":optionId" component={LootSettingsContainer} />
						</Route>

						<Route path="add-characters" component={AutoInviteContainer} />
						<Route path="remove-characters" component={RemoveCharactersContainer} />
						<Route
							path={PathSegments.GUILD_SETTINGS_EXTERNAL_CHARACTERS}
							component={ExternalCharacters}
						/>

						<Route path="character-roles" component={Roles} />

						<Route path="tags">
							<IndexRedirect to="new" />

							<Route path=":tagId" component={TagSettingsContainer} />
						</Route>
					</Route>

					<Route path="import-characters" component={ReimportContainer} />
					<Route path="select-roles" component={SelectRolesContainer} />

					<Redirect
						from="roster-new/:rosterId(/:rosterBossId)"
						to="roster/:rosterId(/:rosterBossId)"
					/>
					<Route
						path="roster/:rosterId(/:rosterBossId)"
						component={RealRosterPage}
					/>
					<Route
						key="roster-old"
						path="roster-old/:rosterId"
						component={RealRosterWrapper}
					/>
					<Route path={PathSegments.ROSTERS} component={ImageBackground}>
						<IndexRoute component={RosterListContainer} />
					</Route>

					<Route path={PathSegments.LOOT} component={RealLootWrapper} />

					<Route path={PathSegments.RCLC} component={RclcView} />

					<Route path={PathSegments.DROPTIMIZER_REPORTS} component={ImageBackground}>
						<IndexRoute component={DroptimizerReportsView} />
					</Route>
				</Route>
			</Route>
		</Router>
	);
}
