// XXX client doesn't need guildId

export interface IRosterRoleAssignmentClass {
	// guildId: number
	characterId: number;
	bossId: number;
	role: string;
}

export class RosterRoleAssignment implements IRosterRoleAssignmentClass {
	// readonly guildId: number;
	readonly characterId: number;
	readonly bossId: number;

	readonly role: string;

	constructor(data?: IRosterRoleAssignmentClass) {
		if (!data) throw new Error('invalid roster-role-assignment data');

		// this.guildId = data.guildId;
		this.characterId = data.characterId;
		this.bossId = data.bossId;

		this.role = data.role;
	}
}
