import React from 'react';
import classnames from 'classnames';

import type {DroptimizerItem} from '@models/droptimizerReport';

import type * as LootContainer from '../LootContainer';
import SelectInput, {ISelectOption} from '../../Utils/SelectInput';
import {Actioning} from '../../Utils/Actioning';
import {EditableNote} from './EditableNote';

interface IProps {
	/** `undefined` for clearing the selection */
	onSelection(optionId: number | undefined): Promise<void> | undefined;
	onUpdateNote(note: string | null): Promise<void>;

	/** Whether this character has any droptimizer data */
	hasDroptimizerData: boolean;
	droptimizerItemData: DroptimizerItem | undefined;
	want: LootContainer.IItemWant | undefined;
	lootOptions: ISelectOption[];
}

export const EditSelection: React.FC<IProps> = (props) => {
	const actioning = React.useRef<Actioning>(null);

	const want = props.want;

	const neverOption: ISelectOption = {
		name: 'Do not want item',
		id: 0
	};

	const options: ISelectOption[] = [neverOption, ...props.lootOptions];

	const handleSelection = (optionId: number) => {
		const actionId = actioning.current?.getActionId();
		actioning.current?.load(actionId);

		// need to convert `0` to `undefined`
		props
			.onSelection(optionId || undefined)
			?.then(() => actioning.current?.success(actionId))
			.catch(() => actioning.current?.error(actionId));
	};

	return (
		<div className="editable-selection selection-column">
			<SelectInput
				onChange={handleSelection}
				items={options}
				className={classnames(
					{'has-droptimizer': props.hasDroptimizerData},
					'selection no-bottom-margin'
				)}
				selectedId={want?.optionId}
				placeholder={want ? want.optionName : neverOption.name}
				highlightId={!want && neverOption.id}
			>
				{!!props.droptimizerItemData && (
					<div
						className={classnames(
							{
								gain: props.droptimizerItemData.absoluteDpsGain > 0
							},
							'dps-change'
						)}
					>
						<div>{props.droptimizerItemData.displayAbsoluteDpsGain}</div>
						<div>{props.droptimizerItemData.displayRelativeDpsGain}</div>
					</div>
				)}

				<Actioning ref={actioning} />
			</SelectInput>

			{want && (
				<EditableNote onSubmit={props.onUpdateNote} value={want.note || null} />
			)}
		</div>
	);
};
