import React from 'react';
import classnames from 'classnames';
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda';

import type * as LootDuck from '../../../ducks/loot';

import type {LootOption} from '../../../models/loot-option';

import DeleteOption from './DeleteOption';
import {Input} from '../../Utils/Input';
import {SwitchField} from '../../Utils/Switch';

function getIsVisibleRosterValue(option: LootOption | undefined): boolean {
	return option?.isVisibleInRoster ?? true;
}

interface IDetailsProps {
	onCreate: typeof LootDuck.createOption;
	onUpdate: typeof LootDuck.updateOption;
	onDelete: typeof LootDuck.deleteOption;

	isSubmitting: boolean;
	isDeleting: boolean;

	option?: LootOption;
}

interface IDetailsState {
	isShowingDelete: boolean;
	isDisabled: boolean;
}

export default class RosterDetailsContent extends React.Component<
	IDetailsProps,
	IDetailsState
> {
	fields: {[key: string]: any} = {};
	state: IDetailsState;

	constructor(props: IDetailsProps) {
		super(props);

		this.state = {
			isDisabled: !props.option,
			isShowingDelete: false
		};
	}

	componentDidMount() {
		this.checkForDisabled();
	}

	componentWillReceiveProps(nextProps: IDetailsProps) {
		const oldId = this.props.option?.id;
		const newId = nextProps.option?.id;

		if (oldId !== newId) this.resetView(nextProps.option);
		else this.checkForDisabled(nextProps.option);
	}

	checkForDisabledHelper = (option: LootOption | undefined) => {
		const name = this.fields.name.getValue();
		if (!name) return true;

		// if there's no option then we're all good
		if (!option) return false;

		// check to see if any of the fields differ
		if (name !== option.name) return false;
		if (this.fields.isVisibleInRoster.getValue() !== option.isVisibleInRoster) {
			return false;
		}

		// if nothing objects to being disable then we're disabled
		return true;
	};

	checkForDisabled = (option: LootOption | undefined = this.props.option) => {
		const isDisabled = this.checkForDisabledHelper(option);

		if (isDisabled !== this.state.isDisabled) {
			this.setState({isDisabled});
		}
	};

	resetView(option?: LootOption) {
		this.fields.name.setValue(option ? option.name : '');
		this.fields.name.focus();

		this.fields.isVisibleInRoster.setValue(getIsVisibleRosterValue(option));

		this.setState(
			{
				isShowingDelete: false
			},
			this.checkForDisabled
		);
	}

	handleSubmit = () => {
		const name = this.fields.name.getValue();
		if (!name) return;

		const isVisibleInRoster = this.fields.isVisibleInRoster.getValue();

		if (this.props.option) {
			this.props.onUpdate({
				id: this.props.option.id,
				isVisibleInRoster,
				name
			});
		} else {
			this.props.onCreate({
				isVisibleInRoster,
				name
			});
		}
	};

	handleDelete = () => {
		this.props.onDelete(this.props.option!.id);
	};

	render() {
		const buttonClass = classnames(
			{
				disabled: this.state.isDisabled || this.props.isSubmitting
			},
			'medium faded primary button'
		);

		const hideSubmit = this.props.option && this.state.isDisabled;

		return (
			<div className="details-content">
				{this.state.isShowingDelete && (
					<DeleteOption
						onClose={() => this.setState({isShowingDelete: false})}
						onDelete={this.handleDelete}
						isDeleting={this.props.isDeleting}
						optionName={this.props.option!.name}
					/>
				)}

				{!this.props.option && (
					<div className="help-block">
						<p>
							These loot options are the values your guild members can select to
							indicate how important an item is to them.
						</p>

						<p>
							Ideally these options should match the options that your guild use
							for in-game loot distribution.
						</p>
					</div>
				)}

				<div className="fields">
					<Input
						ref={(r: Input) => (this.fields.name = r)}
						onChange={() => this.checkForDisabled()}
						autoFocus={true}
						label="Option name"
						placeholder="e.g. Upgrade"
						defaultValue={this.props.option?.name}
					/>

					<SwitchField
						ref={(r: SwitchField) => (this.fields.isVisibleInRoster = r)}
						onChange={() => this.checkForDisabled()}
						label="Show selections made with this option in Roster"
						note="The Roster view surfaces loot that characters want from each boss, you can control whether or not selections made with this option are shown."
						defaultValue={getIsVisibleRosterValue(this.props.option)}
					/>
				</div>

				<div className="button-group">
					{this.props.option && (
						<div
							onClick={() => this.setState({isShowingDelete: true})}
							className="medium left red outline button"
						>
							Delete option
						</div>
					)}

					{!hideSubmit && (
						<LaddaButton
							onClick={this.handleSubmit}
							className={buttonClass}
							loading={this.props.isSubmitting}
							data-style={EXPAND_RIGHT}
						>
							{this.props.option ? 'Save changes' : 'Create option'}
						</LaddaButton>
					)}
				</div>
			</div>
		);
	}
}
