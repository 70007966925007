import {FeatureChecker} from '@shared/billing/features';

import {GuildSponsorshipDuck, GuildDuck} from '@ducks';

import type {GuildSponsorship} from '@models/guildSponsorship';

export function getSponsoringGuildId(state: GuildSponsorshipDuck.IState) {
	return state.sponsoringGuildId;
}

export function getUnsponsoringGuildId(state: GuildSponsorshipDuck.IState) {
	return state.unsponsoringGuildId;
}

export function getSponsorshipsForBillingId(
	state: GuildSponsorshipDuck.IState,
	userBillingId: number
): GuildSponsorship[] {
	return Object.values(state.guildSponsorshipById).filter(
		(x) => x.userBillingId === userBillingId
	);
}

export function getSponsorshipsForGuildId(
	state: GuildSponsorshipDuck.IState,
	guildId: number
): GuildSponsorship[] {
	return Object.values(state.guildSponsorshipById).filter((x) => x.guildId === guildId);
}

export function createFcForGuildId(
	sponsorshipState: GuildSponsorshipDuck.IState,
	guildState: GuildDuck.IState,
	guildId: number
): FeatureChecker {
	const sponsorships = getSponsorshipsForGuildId(sponsorshipState, guildId);
	const guild = GuildDuck.getGuild(guildState, guildId);

	return new FeatureChecker({
		additionalAccessFeatures: guild?.additionalAccessFeatures || [],
		sponsorships
	});
}
