import {Regions} from './wow';

interface IRealm {
	name: string;
	slug: string;
}

export const RealmList: Record<Regions, IRealm[]> = {
	[Regions.US]: [
		{name: 'Aegwynn', slug: 'aegwynn'},
		{name: 'Aerie Peak', slug: 'aerie-peak'},
		{name: 'Agamaggan', slug: 'agamaggan'},
		{name: 'Aggramar', slug: 'aggramar'},
		{name: 'Akama', slug: 'akama'},
		{name: 'Alexstrasza', slug: 'alexstrasza'},
		{name: 'Alleria', slug: 'alleria'},
		{name: 'Altar of Storms', slug: 'altar-of-storms'},
		{name: 'Alterac Mountains', slug: 'alterac-mountains'},
		{name: "Aman'Thul", slug: 'amanthul'},
		{name: 'Andorhal', slug: 'andorhal'},
		{name: 'Anetheron', slug: 'anetheron'},
		{name: 'Antonidas', slug: 'antonidas'},
		{name: "Anub'arak", slug: 'anubarak'},
		{name: 'Anvilmar', slug: 'anvilmar'},
		{name: 'Arathor', slug: 'arathor'},
		{name: 'Archimonde', slug: 'archimonde'},
		{name: 'Area 52', slug: 'area-52'},
		{name: 'Argent Dawn', slug: 'argent-dawn'},
		{name: 'Arthas', slug: 'arthas'},
		{name: 'Arygos', slug: 'arygos'},
		{name: 'Auchindoun', slug: 'auchindoun'},
		{name: 'Azgalor', slug: 'azgalor'},
		{name: 'Azjol-Nerub', slug: 'azjolnerub'},
		{name: 'Azralon', slug: 'azralon'},
		{name: 'Azshara', slug: 'azshara'},
		{name: 'Azuremyst', slug: 'azuremyst'},
		{name: 'Baelgun', slug: 'baelgun'},
		{name: 'Balnazzar', slug: 'balnazzar'},
		{name: 'Barthilas', slug: 'barthilas'},
		{name: 'Black Dragonflight', slug: 'black-dragonflight'},
		{name: 'Blackhand', slug: 'blackhand'},
		{name: 'Blackrock', slug: 'blackrock'},
		{name: 'Blackwater Raiders', slug: 'blackwater-raiders'},
		{name: 'Blackwing Lair', slug: 'blackwing-lair'},
		{name: "Blade's Edge", slug: 'blades-edge'},
		{name: 'Bladefist', slug: 'bladefist'},
		{name: 'Bleeding Hollow', slug: 'bleeding-hollow'},
		{name: 'Blood Furnace', slug: 'blood-furnace'},
		{name: 'Bloodhoof', slug: 'bloodhoof'},
		{name: 'Bloodscalp', slug: 'bloodscalp'},
		{name: 'Bonechewer', slug: 'bonechewer'},
		{name: 'Borean Tundra', slug: 'borean-tundra'},
		{name: 'Boulderfist', slug: 'boulderfist'},
		{name: 'Bronzebeard', slug: 'bronzebeard'},
		{name: 'Burning Blade', slug: 'burning-blade'},
		{name: 'Burning Legion', slug: 'burning-legion'},
		{name: 'Caelestrasz', slug: 'caelestrasz'},
		{name: 'Cairne', slug: 'cairne'},
		{name: 'Cenarion Circle', slug: 'cenarion-circle'},
		{name: 'Cenarius', slug: 'cenarius'},
		{name: "Cho'gall", slug: 'chogall'},
		{name: 'Chromaggus', slug: 'chromaggus'},
		{name: 'Coilfang', slug: 'coilfang'},
		{name: 'Crushridge', slug: 'crushridge'},
		{name: 'Daggerspine', slug: 'daggerspine'},
		{name: 'Dalaran', slug: 'dalaran'},
		{name: 'Dalvengyr', slug: 'dalvengyr'},
		{name: 'Dark Iron', slug: 'dark-iron'},
		{name: 'Darkspear', slug: 'darkspear'},
		{name: 'Darrowmere', slug: 'darrowmere'},
		{name: "Dath'Remar", slug: 'dathremar'},
		{name: 'Dawnbringer', slug: 'dawnbringer'},
		{name: 'Deathwing', slug: 'deathwing'},
		{name: 'Demon Soul', slug: 'demon-soul'},
		{name: 'Dentarg', slug: 'dentarg'},
		{name: 'Destromath', slug: 'destromath'},
		{name: 'Dethecus', slug: 'dethecus'},
		{name: 'Detheroc', slug: 'detheroc'},
		{name: 'Doomhammer', slug: 'doomhammer'},
		{name: 'Draenor', slug: 'draenor'},
		{name: 'Dragonblight', slug: 'dragonblight'},
		{name: 'Dragonmaw', slug: 'dragonmaw'},
		{name: "Drak'Tharon", slug: 'draktharon'},
		{name: "Drak'thul", slug: 'drakthul'},
		{name: 'Draka', slug: 'draka'},
		{name: 'Drakkari', slug: 'drakkari'},
		{name: 'Dreadmaul', slug: 'dreadmaul'},
		{name: 'Drenden', slug: 'drenden'},
		{name: 'Dunemaul', slug: 'dunemaul'},
		{name: 'Durotan', slug: 'durotan'},
		{name: 'Duskwood', slug: 'duskwood'},
		{name: 'Earthen Ring', slug: 'earthen-ring'},
		{name: 'Echo Isles', slug: 'echo-isles'},
		{name: 'Eitrigg', slug: 'eitrigg'},
		{name: "Eldre'Thalas", slug: 'eldrethalas'},
		{name: 'Elune', slug: 'elune'},
		{name: 'Emerald Dream', slug: 'emerald-dream'},
		{name: 'Eonar', slug: 'eonar'},
		{name: 'Eredar', slug: 'eredar'},
		{name: 'Executus', slug: 'executus'},
		{name: 'Exodar', slug: 'exodar'},
		{name: 'Farstriders', slug: 'farstriders'},
		{name: 'Feathermoon', slug: 'feathermoon'},
		{name: 'Fenris', slug: 'fenris'},
		{name: 'Firetree', slug: 'firetree'},
		{name: 'Fizzcrank', slug: 'fizzcrank'},
		{name: 'Frostmane', slug: 'frostmane'},
		{name: 'Frostmourne', slug: 'frostmourne'},
		{name: 'Frostwolf', slug: 'frostwolf'},
		{name: 'Galakrond', slug: 'galakrond'},
		{name: 'Gallywix', slug: 'gallywix'},
		{name: 'Garithos', slug: 'garithos'},
		{name: 'Garona', slug: 'garona'},
		{name: 'Garrosh', slug: 'garrosh'},
		{name: 'Ghostlands', slug: 'ghostlands'},
		{name: 'Gilneas', slug: 'gilneas'},
		{name: 'Gnomeregan', slug: 'gnomeregan'},
		{name: 'Goldrinn', slug: 'goldrinn'},
		{name: 'Gorefiend', slug: 'gorefiend'},
		{name: 'Gorgonnash', slug: 'gorgonnash'},
		{name: 'Greymane', slug: 'greymane'},
		{name: 'Grizzly Hills', slug: 'grizzly-hills'},
		{name: "Gul'dan", slug: 'guldan'},
		{name: 'Gundrak', slug: 'gundrak'},
		{name: 'Gurubashi', slug: 'gurubashi'},
		{name: 'Hakkar', slug: 'hakkar'},
		{name: 'Haomarush', slug: 'haomarush'},
		{name: 'Hellscream', slug: 'hellscream'},
		{name: 'Hydraxis', slug: 'hydraxis'},
		{name: 'Hyjal', slug: 'hyjal'},
		{name: 'Icecrown', slug: 'icecrown'},
		{name: 'Illidan', slug: 'illidan'},
		{name: 'Jaedenar', slug: 'jaedenar'},
		{name: "Jubei'Thos", slug: 'jubeithos'},
		{name: "Kael'thas", slug: 'kaelthas'},
		{name: 'Kalecgos', slug: 'kalecgos'},
		{name: 'Kargath', slug: 'kargath'},
		{name: "Kel'Thuzad", slug: 'kelthuzad'},
		{name: 'Khadgar', slug: 'khadgar'},
		{name: 'Khaz Modan', slug: 'khaz-modan'},
		{name: "Khaz'goroth", slug: 'khazgoroth'},
		{name: "Kil'jaeden", slug: 'kiljaeden'},
		{name: 'Kilrogg', slug: 'kilrogg'},
		{name: 'Kirin Tor', slug: 'kirin-tor'},
		{name: 'Korgath', slug: 'korgath'},
		{name: 'Korialstrasz', slug: 'korialstrasz'},
		{name: 'Kul Tiras', slug: 'kul-tiras'},
		{name: 'Laughing Skull', slug: 'laughing-skull'},
		{name: 'Lethon', slug: 'lethon'},
		{name: 'Lightbringer', slug: 'lightbringer'},
		{name: "Lightning's Blade", slug: 'lightnings-blade'},
		{name: 'Lightninghoof', slug: 'lightninghoof'},
		{name: 'Llane', slug: 'llane'},
		{name: 'Lothar', slug: 'lothar'},
		{name: 'Madoran', slug: 'madoran'},
		{name: 'Maelstrom', slug: 'maelstrom'},
		{name: 'Magtheridon', slug: 'magtheridon'},
		{name: 'Maiev', slug: 'maiev'},
		{name: "Mal'Ganis", slug: 'malganis'},
		{name: 'Malfurion', slug: 'malfurion'},
		{name: 'Malorne', slug: 'malorne'},
		{name: 'Malygos', slug: 'malygos'},
		{name: 'Mannoroth', slug: 'mannoroth'},
		{name: 'Medivh', slug: 'medivh'},
		{name: 'Misha', slug: 'misha'},
		{name: "Mok'Nathal", slug: 'moknathal'},
		{name: 'Moon Guard', slug: 'moon-guard'},
		{name: 'Moonrunner', slug: 'moonrunner'},
		{name: "Mug'thol", slug: 'mugthol'},
		{name: 'Muradin', slug: 'muradin'},
		{name: 'Nagrand', slug: 'nagrand'},
		{name: 'Nathrezim', slug: 'nathrezim'},
		{name: 'Nazgrel', slug: 'nazgrel'},
		{name: 'Nazjatar', slug: 'nazjatar'},
		{name: 'Nemesis', slug: 'nemesis'},
		{name: "Ner'zhul", slug: 'nerzhul'},
		{name: 'Nesingwary', slug: 'nesingwary'},
		{name: 'Nordrassil', slug: 'nordrassil'},
		{name: 'Norgannon', slug: 'norgannon'},
		{name: 'Onyxia', slug: 'onyxia'},
		{name: 'Perenolde', slug: 'perenolde'},
		{name: 'Proudmoore', slug: 'proudmoore'},
		{name: "Quel'Thalas", slug: 'quelthalas'},
		{name: "Quel'dorei", slug: 'queldorei'},
		{name: 'Ragnaros', slug: 'ragnaros'},
		{name: 'Ravencrest', slug: 'ravencrest'},
		{name: 'Ravenholdt', slug: 'ravenholdt'},
		{name: 'Rexxar', slug: 'rexxar'},
		{name: 'Rivendare', slug: 'rivendare'},
		{name: 'Runetotem', slug: 'runetotem'},
		{name: 'Sargeras', slug: 'sargeras'},
		{name: 'Saurfang', slug: 'saurfang'},
		{name: 'Scarlet Crusade', slug: 'scarlet-crusade'},
		{name: 'Scilla', slug: 'scilla'},
		{name: "Sen'jin", slug: 'senjin'},
		{name: 'Sentinels', slug: 'sentinels'},
		{name: 'Shadow Council', slug: 'shadow-council'},
		{name: 'Shadowmoon', slug: 'shadowmoon'},
		{name: 'Shadowsong', slug: 'shadowsong'},
		{name: 'Shandris', slug: 'shandris'},
		{name: 'Shattered Halls', slug: 'shattered-halls'},
		{name: 'Shattered Hand', slug: 'shattered-hand'},
		{name: "Shu'halo", slug: 'shuhalo'},
		{name: 'Silver Hand', slug: 'silver-hand'},
		{name: 'Silvermoon', slug: 'silvermoon'},
		{name: 'Sisters of Elune', slug: 'sisters-of-elune'},
		{name: 'Skullcrusher', slug: 'skullcrusher'},
		{name: 'Skywall', slug: 'skywall'},
		{name: 'Smolderthorn', slug: 'smolderthorn'},
		{name: 'Spinebreaker', slug: 'spinebreaker'},
		{name: 'Spirestone', slug: 'spirestone'},
		{name: 'Staghelm', slug: 'staghelm'},
		{name: 'Steamwheedle Cartel', slug: 'steamwheedle-cartel'},
		{name: 'Stonemaul', slug: 'stonemaul'},
		{name: 'Stormrage', slug: 'stormrage'},
		{name: 'Stormreaver', slug: 'stormreaver'},
		{name: 'Stormscale', slug: 'stormscale'},
		{name: 'Suramar', slug: 'suramar'},
		{name: 'Tanaris', slug: 'tanaris'},
		{name: 'Terenas', slug: 'terenas'},
		{name: 'Terokkar', slug: 'terokkar'},
		{name: 'Thaurissan', slug: 'thaurissan'},
		{name: 'The Forgotten Coast', slug: 'the-forgotten-coast'},
		{name: 'The Scryers', slug: 'the-scryers'},
		{name: 'The Underbog', slug: 'the-underbog'},
		{name: 'The Venture Co', slug: 'the-venture-co'},
		{name: 'Thorium Brotherhood', slug: 'thorium-brotherhood'},
		{name: 'Thrall', slug: 'thrall'},
		{name: 'Thunderhorn', slug: 'thunderhorn'},
		{name: 'Thunderlord', slug: 'thunderlord'},
		{name: 'Tichondrius', slug: 'tichondrius'},
		{name: 'Tol Barad', slug: 'tol-barad'},
		{name: 'Tortheldrin', slug: 'tortheldrin'},
		{name: 'Trollbane', slug: 'trollbane'},
		{name: 'Turalyon', slug: 'turalyon'},
		{name: 'Twisting Nether', slug: 'twisting-nether'},
		{name: 'Uldaman', slug: 'uldaman'},
		{name: 'Uldum', slug: 'uldum'},
		{name: 'Undermine', slug: 'undermine'},
		{name: 'Ursin', slug: 'ursin'},
		{name: 'Uther', slug: 'uther'},
		{name: 'Vashj', slug: 'vashj'},
		{name: "Vek'nilash", slug: 'veknilash'},
		{name: 'Velen', slug: 'velen'},
		{name: 'Warsong', slug: 'warsong'},
		{name: 'Whisperwind', slug: 'whisperwind'},
		{name: 'Wildhammer', slug: 'wildhammer'},
		{name: 'Windrunner', slug: 'windrunner'},
		{name: 'Winterhoof', slug: 'winterhoof'},
		{name: 'Wyrmrest Accord', slug: 'wyrmrest-accord'},
		{name: 'Ysera', slug: 'ysera'},
		{name: 'Ysondre', slug: 'ysondre'},
		{name: 'Zangarmarsh', slug: 'zangarmarsh'},
		{name: "Zul'jin", slug: 'zuljin'},
		{name: 'Zuluhed', slug: 'zuluhed'}
	],

	[Regions.EU]: [
		{name: 'Aegwynn', slug: 'aegwynn'},
		{name: 'Aerie Peak', slug: 'aerie-peak'},
		{name: 'Agamaggan', slug: 'agamaggan'},
		{name: 'Aggra (Português)', slug: 'aggra-português'},
		{name: 'Aggramar', slug: 'aggramar'},
		{name: "Ahn'Qiraj", slug: 'ahnqiraj'},
		{name: "Al'Akir", slug: 'alakir'},
		{name: 'Alexstrasza', slug: 'alexstrasza'},
		{name: 'Alleria', slug: 'alleria'},
		{name: 'Alonsus', slug: 'alonsus'},
		{name: "Aman'Thul", slug: 'amanthul'},
		{name: 'Ambossar', slug: 'ambossar'},
		{name: 'Anachronos', slug: 'anachronos'},
		{name: 'Anetheron', slug: 'anetheron'},
		{name: 'Antonidas', slug: 'antonidas'},
		{name: "Anub'arak", slug: 'anubarak'},
		{name: 'Arak-arahm', slug: 'arakarahm'},
		{name: 'Arathi', slug: 'arathi'},
		{name: 'Arathor', slug: 'arathor'},
		{name: 'Archimonde', slug: 'archimonde'},
		{name: 'Area 52', slug: 'area-52'},
		{name: 'Argent Dawn', slug: 'argent-dawn'},
		{name: 'Arthas', slug: 'arthas'},
		{name: 'Arygos', slug: 'arygos'},
		{name: 'Ashenvale', slug: 'ashenvale'},
		{name: 'Aszune', slug: 'aszune'},
		{name: 'Auchindoun', slug: 'auchindoun'},
		{name: 'Azjol-Nerub', slug: 'azjolnerub'},
		{name: 'Azshara', slug: 'azshara'},
		{name: 'Azuregos', slug: 'azuregos'},
		{name: 'Azuremyst', slug: 'azuremyst'},
		{name: 'Baelgun', slug: 'baelgun'},
		{name: 'Balnazzar', slug: 'balnazzar'},
		{name: 'Blackhand', slug: 'blackhand'},
		{name: 'Blackmoore', slug: 'blackmoore'},
		{name: 'Blackrock', slug: 'blackrock'},
		{name: 'Blackscar', slug: 'blackscar'},
		{name: "Blade's Edge", slug: 'blades-edge'},
		{name: 'Bladefist', slug: 'bladefist'},
		{name: 'Bloodfeather', slug: 'bloodfeather'},
		{name: 'Bloodhoof', slug: 'bloodhoof'},
		{name: 'Bloodscalp', slug: 'bloodscalp'},
		{name: 'Blutkessel', slug: 'blutkessel'},
		{name: 'Booty Bay', slug: 'booty-bay'},
		{name: 'Borean Tundra', slug: 'borean-tundra'},
		{name: 'Boulderfist', slug: 'boulderfist'},
		{name: 'Bronze Dragonflight', slug: 'bronze-dragonflight'},
		{name: 'Bronzebeard', slug: 'bronzebeard'},
		{name: 'Burning Blade', slug: 'burning-blade'},
		{name: 'Burning Legion', slug: 'burning-legion'},
		{name: 'Burning Steppes', slug: 'burning-steppes'},
		{name: "C'Thun", slug: 'cthun'},
		{name: 'Chamber of Aspects', slug: 'chamber-of-aspects'},
		{name: 'Chants éternels', slug: 'chants-éternels'},
		{name: "Cho'gall", slug: 'chogall'},
		{name: 'Chromaggus', slug: 'chromaggus'},
		{name: 'Colinas Pardas', slug: 'colinas-pardas'},
		{name: 'Confrérie du Thorium', slug: 'confrérie-du-thorium'},
		{name: 'Conseil des Ombres', slug: 'conseil-des-ombres'},
		{name: 'Crushridge', slug: 'crushridge'},
		{name: 'Culte de la Rive noire', slug: 'culte-de-la-rive-noire'},
		{name: 'Daggerspine', slug: 'daggerspine'},
		{name: 'Dalaran', slug: 'dalaran'},
		{name: 'Dalvengyr', slug: 'dalvengyr'},
		{name: 'Darkmoon Faire', slug: 'darkmoon-faire'},
		{name: 'Darksorrow', slug: 'darksorrow'},
		{name: 'Darkspear', slug: 'darkspear'},
		{name: 'Das Konsortium', slug: 'das-konsortium'},
		{name: 'Das Syndikat', slug: 'das-syndikat'},
		{name: 'Deathguard', slug: 'deathguard'},
		{name: 'Deathweaver', slug: 'deathweaver'},
		{name: 'Deathwing', slug: 'deathwing'},
		{name: 'Deepholm', slug: 'deepholm'},
		{name: 'Defias Brotherhood', slug: 'defias-brotherhood'},
		{name: 'Dentarg', slug: 'dentarg'},
		{name: 'Der Mithrilorden', slug: 'der-mithrilorden'},
		{name: 'Der Rat von Dalaran', slug: 'der-rat-von-dalaran'},
		{name: 'Der abyssische Rat', slug: 'der-abyssische-rat'},
		{name: 'Destromath', slug: 'destromath'},
		{name: 'Dethecus', slug: 'dethecus'},
		{name: 'Die Aldor', slug: 'die-aldor'},
		{name: 'Die Arguswacht', slug: 'die-arguswacht'},
		{name: 'Die Nachtwache', slug: 'die-nachtwache'},
		{name: 'Die Silberne Hand', slug: 'die-silberne-hand'},
		{name: 'Die Todeskrallen', slug: 'die-todeskrallen'},
		{name: 'Die ewige Wacht', slug: 'die-ewige-wacht'},
		{name: 'Doomhammer', slug: 'doomhammer'},
		{name: 'Draenor', slug: 'draenor'},
		{name: 'Dragonblight', slug: 'dragonblight'},
		{name: 'Dragonmaw', slug: 'dragonmaw'},
		{name: "Drak'thul", slug: 'drakthul'},
		{name: "Drek'Thar", slug: 'drekthar'},
		{name: 'Dun Modr', slug: 'dun-modr'},
		{name: 'Dun Morogh', slug: 'dun-morogh'},
		{name: 'Dunemaul', slug: 'dunemaul'},
		{name: 'Durotan', slug: 'durotan'},
		{name: 'Earthen Ring', slug: 'earthen-ring'},
		{name: 'Echsenkessel', slug: 'echsenkessel'},
		{name: 'Eitrigg', slug: 'eitrigg'},
		{name: "Eldre'Thalas", slug: 'eldrethalas'},
		{name: 'Elune', slug: 'elune'},
		{name: 'Emerald Dream', slug: 'emerald-dream'},
		{name: 'Emeriss', slug: 'emeriss'},
		{name: 'Eonar', slug: 'eonar'},
		{name: 'Eredar', slug: 'eredar'},
		{name: 'Eversong', slug: 'eversong'},
		{name: 'Executus', slug: 'executus'},
		{name: 'Exodar', slug: 'exodar'},
		{name: 'Festung der Stürme', slug: 'festung-der-stürme'},
		{name: 'Fordragon', slug: 'fordragon'},
		{name: 'Forscherliga', slug: 'forscherliga'},
		{name: 'Frostmane', slug: 'frostmane'},
		{name: 'Frostmourne', slug: 'frostmourne'},
		{name: 'Frostwhisper', slug: 'frostwhisper'},
		{name: 'Frostwolf', slug: 'frostwolf'},
		{name: 'Galakrond', slug: 'galakrond'},
		{name: 'Garona', slug: 'garona'},
		{name: 'Garrosh', slug: 'garrosh'},
		{name: 'Genjuros', slug: 'genjuros'},
		{name: 'Ghostlands', slug: 'ghostlands'},
		{name: 'Gilneas', slug: 'gilneas'},
		{name: 'Goldrinn', slug: 'goldrinn'},
		{name: 'Gordunni', slug: 'gordunni'},
		{name: 'Gorgonnash', slug: 'gorgonnash'},
		{name: 'Greymane', slug: 'greymane'},
		{name: 'Grim Batol', slug: 'grim-batol'},
		{name: 'Grom', slug: 'grom'},
		{name: "Gul'dan", slug: 'guldan'},
		{name: 'Hakkar', slug: 'hakkar'},
		{name: 'Haomarush', slug: 'haomarush'},
		{name: 'Hellfire', slug: 'hellfire'},
		{name: 'Hellscream', slug: 'hellscream'},
		{name: 'Howling Fjord', slug: 'howling-fjord'},
		{name: 'Hyjal', slug: 'hyjal'},
		{name: 'Illidan', slug: 'illidan'},
		{name: 'Jaedenar', slug: 'jaedenar'},
		{name: "Kael'thas", slug: 'kaelthas'},
		{name: 'Karazhan', slug: 'karazhan'},
		{name: 'Kargath', slug: 'kargath'},
		{name: 'Kazzak', slug: 'kazzak'},
		{name: "Kel'Thuzad", slug: 'kelthuzad'},
		{name: 'Khadgar', slug: 'khadgar'},
		{name: 'Khaz Modan', slug: 'khaz-modan'},
		{name: "Khaz'goroth", slug: 'khazgoroth'},
		{name: "Kil'jaeden", slug: 'kiljaeden'},
		{name: 'Kilrogg', slug: 'kilrogg'},
		{name: 'Kirin Tor', slug: 'kirin-tor'},
		{name: "Kor'gall", slug: 'korgall'},
		{name: "Krag'jin", slug: 'kragjin'},
		{name: 'Krasus', slug: 'krasus'},
		{name: 'Kul Tiras', slug: 'kul-tiras'},
		{name: 'Kult der Verdammten', slug: 'kult-der-verdammten'},
		{name: 'La Croisade écarlate', slug: 'la-croisade-écarlate'},
		{name: 'Laughing Skull', slug: 'laughing-skull'},
		{name: 'Les Clairvoyants', slug: 'les-clairvoyants'},
		{name: 'Les Sentinelles', slug: 'les-sentinelles'},
		{name: 'Lich King', slug: 'lich-king'},
		{name: 'Lightbringer', slug: 'lightbringer'},
		{name: "Lightning's Blade", slug: 'lightnings-blade'},
		{name: 'Lordaeron', slug: 'lordaeron'},
		{name: 'Los Errantes', slug: 'los-errantes'},
		{name: 'Lothar', slug: 'lothar'},
		{name: 'Madmortem', slug: 'madmortem'},
		{name: 'Magtheridon', slug: 'magtheridon'},
		{name: "Mal'Ganis", slug: 'malganis'},
		{name: 'Malfurion', slug: 'malfurion'},
		{name: 'Malorne', slug: 'malorne'},
		{name: 'Malygos', slug: 'malygos'},
		{name: 'Mannoroth', slug: 'mannoroth'},
		{name: 'Marécage de Zangar', slug: 'marécage-de-zangar'},
		{name: 'Mazrigos', slug: 'mazrigos'},
		{name: 'Medivh', slug: 'medivh'},
		{name: 'Minahonda', slug: 'minahonda'},
		{name: 'Moonglade', slug: 'moonglade'},
		{name: "Mug'thol", slug: 'mugthol'},
		{name: 'Nagrand', slug: 'nagrand'},
		{name: 'Nathrezim', slug: 'nathrezim'},
		{name: 'Naxxramas', slug: 'naxxramas'},
		{name: 'Nazjatar', slug: 'nazjatar'},
		{name: 'Nefarian', slug: 'nefarian'},
		{name: 'Nemesis', slug: 'nemesis'},
		{name: 'Neptulon', slug: 'neptulon'},
		{name: "Ner'zhul", slug: 'nerzhul'},
		{name: "Nera'thor", slug: 'nerathor'},
		{name: 'Nethersturm', slug: 'nethersturm'},
		{name: 'Nordrassil', slug: 'nordrassil'},
		{name: 'Norgannon', slug: 'norgannon'},
		{name: 'Nozdormu', slug: 'nozdormu'},
		{name: 'Onyxia', slug: 'onyxia'},
		{name: 'Outland', slug: 'outland'},
		{name: 'Perenolde', slug: 'perenolde'},
		{name: "Pozzo dell'Eternità", slug: 'pozzo-delleternità'},
		{name: 'Proudmoore', slug: 'proudmoore'},
		{name: "Quel'Thalas", slug: 'quelthalas'},
		{name: 'Ragnaros', slug: 'ragnaros'},
		{name: 'Rajaxx', slug: 'rajaxx'},
		{name: 'Rashgarroth', slug: 'rashgarroth'},
		{name: 'Ravencrest', slug: 'ravencrest'},
		{name: 'Ravenholdt', slug: 'ravenholdt'},
		{name: 'Razuvious', slug: 'razuvious'},
		{name: 'Rexxar', slug: 'rexxar'},
		{name: 'Runetotem', slug: 'runetotem'},
		{name: 'Sanguino', slug: 'sanguino'},
		{name: 'Sargeras', slug: 'sargeras'},
		{name: 'Saurfang', slug: 'saurfang'},
		{name: 'Scarshield Legion', slug: 'scarshield-legion'},
		{name: "Sen'jin", slug: 'senjin'},
		{name: 'Shadowsong', slug: 'shadowsong'},
		{name: 'Shattered Halls', slug: 'shattered-halls'},
		{name: 'Shattered Hand', slug: 'shattered-hand'},
		{name: 'Shattrath', slug: 'shattrath'},
		{name: "Shen'dralar", slug: 'shendralar'},
		{name: 'Silvermoon', slug: 'silvermoon'},
		{name: 'Sinstralis', slug: 'sinstralis'},
		{name: 'Skullcrusher', slug: 'skullcrusher'},
		{name: 'Soulflayer', slug: 'soulflayer'},
		{name: 'Spinebreaker', slug: 'spinebreaker'},
		{name: 'Sporeggar', slug: 'sporeggar'},
		{name: 'Steamwheedle Cartel', slug: 'steamwheedle-cartel'},
		{name: 'Stormrage', slug: 'stormrage'},
		{name: 'Stormreaver', slug: 'stormreaver'},
		{name: 'Stormscale', slug: 'stormscale'},
		{name: 'Sunstrider', slug: 'sunstrider'},
		{name: 'Suramar', slug: 'suramar'},
		{name: 'Sylvanas', slug: 'sylvanas'},
		{name: 'Taerar', slug: 'taerar'},
		{name: 'Talnivarr', slug: 'talnivarr'},
		{name: 'Tarren Mill', slug: 'tarren-mill'},
		{name: 'Teldrassil', slug: 'teldrassil'},
		{name: 'Temple noir', slug: 'temple-noir'},
		{name: 'Terenas', slug: 'terenas'},
		{name: 'Terokkar', slug: 'terokkar'},
		{name: 'Terrordar', slug: 'terrordar'},
		{name: 'The Maelstrom', slug: 'the-maelstrom'},
		{name: "The Sha'tar", slug: 'the-shatar'},
		{name: 'The Venture Co', slug: 'the-venture-co'},
		{name: 'Theradras', slug: 'theradras'},
		{name: 'Thermaplugg', slug: 'thermaplugg'},
		{name: 'Thrall', slug: 'thrall'},
		{name: "Throk'Feroth", slug: 'throkferoth'},
		{name: 'Thunderhorn', slug: 'thunderhorn'},
		{name: 'Tichondrius', slug: 'tichondrius'},
		{name: 'Tirion', slug: 'tirion'},
		{name: 'Todeswache', slug: 'todeswache'},
		{name: 'Trollbane', slug: 'trollbane'},
		{name: 'Turalyon', slug: 'turalyon'},
		{name: "Twilight's Hammer", slug: 'twilights-hammer'},
		{name: 'Twisting Nether', slug: 'twisting-nether'},
		{name: 'Tyrande', slug: 'tyrande'},
		{name: 'Uldaman', slug: 'uldaman'},
		{name: 'Ulduar', slug: 'ulduar'},
		{name: 'Uldum', slug: 'uldum'},
		{name: "Un'Goro", slug: 'ungoro'},
		{name: 'Varimathras', slug: 'varimathras'},
		{name: 'Vashj', slug: 'vashj'},
		{name: "Vek'lor", slug: 'veklor'},
		{name: "Vek'nilash", slug: 'veknilash'},
		{name: "Vol'jin", slug: 'voljin'},
		{name: 'Wildhammer', slug: 'wildhammer'},
		{name: 'Wrathbringer', slug: 'wrathbringer'},
		{name: 'Xavius', slug: 'xavius'},
		{name: 'Ysera', slug: 'ysera'},
		{name: 'Ysondre', slug: 'ysondre'},
		{name: 'Zenedar', slug: 'zenedar'},
		{name: 'Zirkel des Cenarius', slug: 'zirkel-des-cenarius'},
		{name: "Zul'jin", slug: 'zuljin'},
		{name: 'Zuluhed', slug: 'zuluhed'}
	]
};

export const ALL_REALMS = [...RealmList.us, ...RealmList.eu];

type RealmNameBySlug = Map<string, string>;

export const REALM_NAME_BY_SLUG = ALL_REALMS.reduce<RealmNameBySlug>(
	(map, realmData): RealmNameBySlug => {
		map.set(realmData.slug, realmData.name);
		return map;
	},
	new Map()
);
