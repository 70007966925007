import React from 'react';
import ReactDOM from 'react-dom';
// import Mousetrap from 'mousetrap';

import {useLockBodyScroll} from '../../hooks/useLockBodyScroll';

import Banner from './Banner';

interface IProps {
	onClose(): void;

	isOverlayCloseDisabled?: boolean;
}

export const Modal: React.FC<IProps> = (props) => {
	useLockBodyScroll();

	const $overlay = React.useRef(null);

	const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (props.isOverlayCloseDisabled) return;
		if (e.target === $overlay.current) props.onClose();
	};

	return ReactDOM.createPortal(
		<div className="modal-container">
			<div className="modal-overlay">
				<Banner />

				<div ref={$overlay} onClick={handleOverlayClick} className="modal-box">
					{props.children}
				</div>
			</div>
		</div>,
		document.querySelector('#modal-root')!
	);
};

export default Modal;
