import React from 'react';
import {FiX} from 'react-icons/fi';

import {connect, UserDuck} from '@ducks';

interface MapProps {
	isShowingHelp: boolean;
}

interface DispatchProps {
	onViewedRosterRedesignHelp: BoundThunk<typeof UserDuck['viewedRosterRedesignHelp']>;
}

type Props = MapProps & DispatchProps;

const VersionHelpComp: React.FC<Props> = (props) => {
	if (!props.isShowingHelp) return null;

	return (
		<div className="roster-redesign-help">
			<div onClick={props.onViewedRosterRedesignHelp} className="close-icon">
				<FiX />
			</div>

			<p>
				This is a major redesign of the Roster view, allowing you to see more things
				at a glance as well as some information about the Buffs/Utilities/Cooldowns
				your selected roster brings.
			</p>

			<p>
				If you have any feedback please reach out via the Contact button in the bottom
				right.
			</p>
		</div>
	);
};

function mapStateToProps(state: IRootState): MapProps {
	const user = UserDuck.getUser(state.user);

	return {
		isShowingHelp: !!user && !user.hasViewedRosterRedesignHelp
	};
}

export const VersionHelp = connect<MapProps, DispatchProps, {}>(mapStateToProps, {
	onViewedRosterRedesignHelp: UserDuck.viewedRosterRedesignHelp
})(VersionHelpComp);
