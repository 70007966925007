import type {BillingStatus} from '@constants';

export interface IUserBillingClass {
	readonly id: number;
	readonly userId: number;

	readonly createdAt: string;
	readonly updatedAt: string;

	readonly billingEmail: string | null;

	readonly stripeCustomerId: string;
	readonly stripeSubscriptionId: string | null;
	readonly stripePriceId: string | null;

	readonly status: BillingStatus | null;
	readonly currentPeriodEndAt: string | null;
}

export type ActiveUserBilling = NonNullableKeys<
	IUserBillingClass,
	| 'stripeCustomerId'
	| 'stripeSubscriptionId'
	| 'stripePriceId'
	| 'status'
	| 'currentPeriodEndAt'
>;

export class UserBilling implements IUserBillingClass {
	readonly id: number;
	readonly userId: number;

	readonly createdAt: string;
	readonly updatedAt: string;

	readonly billingEmail: string | null;

	readonly stripeCustomerId: string;
	readonly stripeSubscriptionId: string | null;
	readonly stripePriceId: string | null;

	readonly status: BillingStatus | null;
	readonly currentPeriodEndAt: string | null;

	constructor(data: IUserBillingClass) {
		this.id = data.id;
		this.userId = data.userId;

		this.createdAt = data.createdAt;
		this.updatedAt = data.updatedAt;

		this.billingEmail = data.billingEmail;

		this.stripeCustomerId = data.stripeCustomerId;
		this.stripeSubscriptionId = data.stripeSubscriptionId;
		this.stripePriceId = data.stripePriceId;

		this.status = data.status;
		this.currentPeriodEndAt = data.currentPeriodEndAt;
	}
}
