import type {RaidItemSlots, RaidGroupId} from '@constants/wow';

export interface IRaidBoss {
	readonly encounterId: WowBossEncounterId;
	readonly id: WowBossId;
	readonly name: string;
}

interface IRaidInstance {
	id: WowInstanceId;
	name: string;
	order: number;

	encounterInstanceId: WowInstanceEncounterId;
	isKillCountSupported: boolean;

	raidGroupName: RaidGroupId | undefined;

	wowheadBonuses: {
		normal: string;
		heroic: string;
		mythic: string;
	};

	bosses: IRaidBoss[];
}

export interface IRaidItem {
	id: WowItemId;
	sourceId: WowBossId;
	sourceIds?: WowBossId[];

	name: string;
	slot: RaidItemSlots;
	icon: string;

	allowed: {
		melee: string[];
		ranged: string[];
		healers: string[];
		tanks: string[];
	};
}

export class RaidInstance implements IRaidInstance {
	readonly id: WowInstanceId;
	readonly name: string;
	readonly order: number;

	readonly encounterInstanceId: WowInstanceEncounterId;
	readonly isKillCountSupported: boolean;

	readonly raidGroupName: RaidGroupId | undefined;

	readonly wowheadBonuses: {
		readonly [key: string]: string;

		readonly normal: string;
		readonly heroic: string;
		readonly mythic: string;
	};

	readonly bosses: IRaidBoss[];

	constructor(data?: IRaidInstance) {
		if (!data) throw new Error('invalid raid instance data');

		this.id = data.id;
		this.name = data.name;
		this.order = data.order;

		this.encounterInstanceId = data.encounterInstanceId;
		this.isKillCountSupported = !!data.isKillCountSupported;

		this.raidGroupName = data.raidGroupName;

		this.wowheadBonuses = data.wowheadBonuses;

		this.bosses = data.bosses;
	}
}

export class RaidItem implements IRaidItem {
	readonly id: WowItemId;
	readonly sourceId: WowBossId;
	readonly sourceIds: WowBossId[] | undefined;

	readonly name: string;
	readonly slot: RaidItemSlots;
	readonly icon: string;

	readonly allowed: {
		melee: string[];
		ranged: string[];
		healers: string[];
		tanks: string[];
	};

	constructor(data?: IRaidItem) {
		if (!data) throw new Error('invalid raid item data');

		this.id = data.id;
		this.sourceId = data.sourceId;
		this.sourceIds = data.sourceIds;

		this.name = data.name;
		this.slot = data.slot;
		this.icon = data.icon;

		this.allowed = data.allowed;
	}
}
