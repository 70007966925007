import React from 'react';
import classnames from 'classnames';
import {FiChevronDown} from 'react-icons/fi';

import {
	CharacterAbility,
	characterAbilityById
} from '@shared/constants/characterAbilities';
import type {Classes, Role} from '@constants/wow';

import {connect2} from '@ducks';

import {useBoolean} from '@hooks/useBoolean';

import {AbilityIcon} from '@components/Utils/AbilityIcon';
import {AbilitySelect} from './AbilitySelect';

interface TextProps {
	isSubmitting: boolean;

	type: 'wow-ability' | 'freeform' | 'placeholder';
	children: React.ReactNode;
}

const Text: React.FC<TextProps> = (props) => {
	const textClass = classnames(
		{submitting: props.isSubmitting},
		props.type,
		'action-field-text'
	);

	return <div className={textClass}>{props.children}</div>;
};

interface OwnProps {
	onUpdateAbility(data: {
		wowAbilityId: WowAbilityId | undefined;
		abilityText: string | undefined;
	}): Promise<void>;

	isEditor: boolean;

	actorClass: Classes | undefined;
	/** The role the character is rostered in for */
	actorRole: Role | undefined;

	wowAbilityId: WowAbilityId | null;
	abilityText: string | null;
}

interface StateProps {
	wowAbility: CharacterAbility | undefined;
}

type AbilityProps = OwnProps & StateProps;

const AbilityComp: React.FC<AbilityProps> = (props) => {
	const editing = useBoolean(false);
	const submitting = useBoolean(false);

	async function handleUpdate(data: {
		wowAbilityId: WowAbilityId | undefined;
		abilityText: string | undefined;
	}): Promise<void> {
		submitting.setTrue();
		editing.setFalse();

		await props.onUpdateAbility({
			wowAbilityId: data.wowAbilityId,
			abilityText: data.abilityText
		});

		submitting.setFalse();
	}

	let content;
	if (props.wowAbilityId && props.wowAbility) {
		content = (
			<>
				<AbilityIcon
					className="action-field-icon"
					type="wow-ability"
					iconName={props.wowAbility.iconName}
				/>

				<Text isSubmitting={submitting.value} type="wow-ability">
					{props.wowAbility.name}
				</Text>
			</>
		);
	} else if (props.abilityText) {
		content = (
			<>
				<AbilityIcon className="action-field-icon" type="custom" />

				<Text isSubmitting={submitting.value} type="freeform">
					{props.abilityText}
				</Text>
			</>
		);
	} else {
		content = (
			<>
				<AbilityIcon className="action-field-icon" type="placeholder" />

				<Text isSubmitting={submitting.value} type="placeholder">
					Ability
				</Text>
			</>
		);
	}

	return (
		<div className="action-field-wrapper">
			<div
				onClick={() => props.isEditor && editing.setTrue()}
				className="action-field ability"
			>
				{content}

				{props.isEditor && <FiChevronDown className="dropdown-icon" />}
			</div>

			{editing.value && (
				<AbilitySelect
					onClose={editing.setFalse}
					onSelect={(wowAbilityId) =>
						handleUpdate({wowAbilityId, abilityText: undefined})
					}
					onFreeformText={(abilityText) =>
						handleUpdate({wowAbilityId: undefined, abilityText})
					}
					actorClass={props.actorClass}
					actorRole={props.actorRole}
				/>
			)}
		</div>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const wowAbility =
		(props.wowAbilityId && characterAbilityById.get(props.wowAbilityId)) || undefined;

	return {
		wowAbility
	};
}

export const Ability = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: {};
}>(
	mapStateToProps,
	{}
)(AbilityComp);
