import {connect2, UserDuck, GuildDuck, CharacterDuck} from '@ducks';

import type {Guild} from '@models/guild';

import {AdminSettings} from './AdminSettings';

interface OwnProps {
	guild: Guild;
}

interface StateProps {
	isUserImporter: boolean;
	isImporterAdmin: boolean;
	adminRanks: boolean[];
	rankNames: string[];
	adminUserIds: UserId[];
	usersCharacterRanks: Set<number>;
}

interface DispatchProps {
	onToggleImporter: BoundThunk<typeof GuildDuck['updateAdminImporter']>;
	onToggleRank: BoundThunk<typeof GuildDuck['updateAdminRank']>;
}

export type Props = OwnProps & StateProps & DispatchProps;

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const user = UserDuck.getActiveUser(state.user);

	const characters = CharacterDuck.getCharactersForUser(
		state.characters,
		props.guild.id,
		user
	);

	return {
		isUserImporter: user.id === props.guild.importerId,
		isImporterAdmin: props.guild.isImporterAdmin,
		adminRanks: props.guild.adminRanks,
		rankNames: props.guild.normalRankNames,
		adminUserIds: props.guild.adminUserIds,
		usersCharacterRanks: new Set(characters.map((x) => x.rank))
	};
}

export default connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: DispatchProps;
}>(mapStateToProps, {
	onToggleImporter: GuildDuck.updateAdminImporter,
	onToggleRank: GuildDuck.updateAdminRank
})(AdminSettings);
