import React from 'react';
import {connect} from 'react-redux';

import {CharacterDuck} from '@ducks';

import type {Character} from '@models/character';

import {ExternalCharacter} from './ExternalCharacter';

interface IOwnProps {
	guildId: number;
}

interface IMapProps {
	isUpdating: boolean;
	isRevoking: boolean;
	characters: Character[];
}

interface IDispatchProps {
	onUpdateCharacter: typeof CharacterDuck.updateExternal;
	onRevokeCharacter: typeof CharacterDuck.revoke;
}

type IProps = IOwnProps & IMapProps & IDispatchProps;

const ListExternalCharactersComp: React.FC<IProps> = (props) => {
	if (!props.characters.length) return null;

	const characters = props.characters.map((char) => (
		<ExternalCharacter
			key={char.id}
			onUpdateCharacter={props.onUpdateCharacter}
			onRevokeCharacer={props.onRevokeCharacter}
			isUpdating={props.isUpdating}
			isRevoking={props.isRevoking}
			character={char}
		/>
	));

	return (
		<div className="settings-field-group list-external-characters">
			<div className="settings-heading">Current external characters</div>

			<div className="characters-list">{characters}</div>
		</div>
	);
};

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	return {
		isUpdating: CharacterDuck.getIsUpdatingExternal(state.characters),
		isRevoking: CharacterDuck.getRevoking(state.characters),
		characters: CharacterDuck.getRosteredExternalCharactersForGuild(
			state.characters,
			props.guildId
		)
	};
}

export const ListExternalCharacters = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onUpdateCharacter: CharacterDuck.updateExternal,
		onRevokeCharacter: CharacterDuck.revoke
	}
)(ListExternalCharactersComp);
