import type {Role} from '@constants/wow';

import {getBossImageUrl} from '@helpers/images';

import {RosterBossDuck} from '@ducks';

import type {RaidInstance} from '@models/raid-data';

export interface BossTile {
	rosterBossId: RosterBossId;
	bossName: string;
	bossImageUrl: string;

	/** The Role the Character is assigned to for the boss */
	assignedRole: Role;
}

/**
 * Get a minimal set of information about each of the bosses a given character is
 * assigned to for a certain roster
 */
export function getBossesForCharacter(
	state: IRootState,
	data: {
		raidInstance: RaidInstance;
		characterId: CharacterId;
		rosterId: RosterId;
	}
): BossTile[] {
	const rosterBosses = RosterBossDuck.getBossesForRoster(
		state.rosterBosses,
		data.rosterId
	);

	return rosterBosses.reduce<BossTile[]>((bossTiles, boss): BossTile[] => {
		const assignedCharacterIdsByRole = RosterBossDuck.getAssignmentsForBoss(
			state.rosterBosses,
			boss.id
		);

		// Work out if this Character is assigned to the boss in any of the Roles
		const assignedRoleGroup = Object.entries(assignedCharacterIdsByRole).find(
			([, assignedCharacterIdsForRole]) => {
				return assignedCharacterIdsForRole.includes(data.characterId);
			}
		);

		// If the Character isn't assigned then don't include this Boss
		if (!assignedRoleGroup) return bossTiles;

		const raidBoss = data.raidInstance.bosses.find((x) => x.id === boss.bossId);

		return [
			...bossTiles,
			{
				bossImageUrl: getBossImageUrl(boss.bossId),
				bossName: raidBoss ? raidBoss.name : `Boss ${boss.id}`,
				rosterBossId: boss.id,
				assignedRole: assignedRoleGroup[0] as Role
			}
		];
	}, []);
}
