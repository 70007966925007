import React from 'react';

import type {Character} from '../../../models/character';

interface IProps {
	onCharacterSelect(characterId: number | null): void;

	ownCharacters: Character[];
}

export const OwnCharacters: React.FC<IProps> = (props) => {
	if (!props.ownCharacters.length) return null;

	const ownCharacterLinks = props.ownCharacters.map((char) => (
		<div
			key={char.id}
			onClick={() => props.onCharacterSelect(char.id)}
			className={`link wow-style bg-before ${char.class}`}
		>
			{char.name}
		</div>
	));

	return (
		<div className="own-character-links">
			<div className="link-intro">Edit loot for your characters:</div>

			{ownCharacterLinks}
		</div>
	);
};
