// XXX omitting guildId for efficiency

interface ILootCoinData {
	// guildId: number

	characterId: number;
	difficulty: string;
	wowBossId: WowBossId;
}

export interface ILootCoinClass extends ILootCoinData {}

export class LootCoin implements ILootCoinClass {
	// readonly guildId: number;

	readonly characterId: number;
	readonly difficulty: string;
	readonly wowBossId: WowBossId;

	constructor(data?: ILootCoinClass) {
		if (!data) throw new Error('invalid loot-coin data');

		// this.guildId = data.guildId;

		this.characterId = data.characterId;
		this.difficulty = data.difficulty;
		this.wowBossId = data.wowBossId;
	}
}
