import type {CooldownSheetDuck} from '@ducks';

import type {CooldownSheet, CooldownSheetId} from '@models/cooldownSheet';

/**
 * Get whether a CooldownSheet is in the process of being fetched for a given
 * RosterBossId
 */
export function getIsFetchingForRosterBossId(
	state: CooldownSheetDuck.State,
	rosterBossId: RosterBossId
): boolean {
	return !!state.isFetchingByRosterBossId[rosterBossId];
}

/** Get a CooldownSheet for a given ID */
export function getCooldownSheetForId(
	state: CooldownSheetDuck.State,
	id: CooldownSheetId
): CooldownSheet | undefined {
	return state.sheetById[id];
}
