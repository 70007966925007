import React from 'react';

import {useBoolean} from '@hooks/useBoolean';

import {EventSelect} from './EventSelect';

interface AddEventProps {
	onCreate(data: {
		wowAbilityId: WowAbilityId | undefined;
		name: string | undefined;
	}): Promise<void>;

	wowBossId: WowBossId;
}

export const AddEvent: React.FC<AddEventProps> = (props) => {
	const editing = useBoolean(false);
	const submitting = useBoolean(false);

	async function handleSelect(data: {
		wowAbilityId: WowAbilityId | undefined;
		name: string | undefined;
	}): Promise<void> {
		submitting.setTrue();
		editing.setFalse();

		await props.onCreate({
			wowAbilityId: data.wowAbilityId,
			name: data.name
		});

		submitting.setFalse();
	}

	return (
		<div className="add-event-container">
			<div onClick={editing.setTrue} className="prompt">
				{submitting.value ? 'Saving...' : 'Add new event'}
			</div>

			{editing.value && (
				<EventSelect
					onClose={editing.setFalse}
					onSelect={(wowAbilityId) => handleSelect({wowAbilityId, name: undefined})}
					onFreeformText={(abilityText) =>
						handleSelect({wowAbilityId: undefined, name: abilityText})
					}
					wowBossId={props.wowBossId}
				/>
			)}
		</div>
	);
};
