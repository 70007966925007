import React from 'react';

import {useModal} from '@hooks/useModal';

import {ActionsMenu, MenuItem} from '@components/Utils/Menu';
import {ClearRosterAssignmentsModal} from './ClearRosterAssignmentsModal';
import {PostToDiscord} from './PostToDiscord';

interface Props {
	onNavigateToRosterReorder(): void;
	onNavigateToRoster(): void;

	isAdmin: boolean;
	isDemo: boolean;

	rosterId: RosterId;
}

export const Actions: React.FC<Props> = (props) => {
	const postToDiscordModal = useModal();
	const clearRosterAssignmentsModal = useModal();

	if (!props.isAdmin || props.isDemo) return null;

	return (
		<>
			{postToDiscordModal.isOpen && (
				<PostToDiscord onClose={postToDiscordModal.close} rosterId={props.rosterId} />
			)}

			{clearRosterAssignmentsModal.isOpen && (
				<ClearRosterAssignmentsModal
					onClose={clearRosterAssignmentsModal.close}
					rosterId={props.rosterId}
				/>
			)}

			<ActionsMenu className="roster-actions-menu">
				<MenuItem onClick={props.onNavigateToRoster}>Update roster details</MenuItem>

				<MenuItem onClick={props.onNavigateToRosterReorder}>
					Reorder roster bosses
				</MenuItem>

				<MenuItem onClick={clearRosterAssignmentsModal.open}>
					Remove all assignments from roster
				</MenuItem>

				<MenuItem onClick={postToDiscordModal.open}>Post to Discord</MenuItem>
			</ActionsMenu>
		</>
	);
};
