import React from 'react';

import {ORDERED_EXPORT_ROLES} from '@constants/wow';

import {connect, RosterBossDuck, CharacterDuck, SettingsDuck} from '@ducks';

import {sortCharactersByClassAndName, sortCharactersByName} from '@helpers/toolbox';

import {SwitchField} from '@components/Utils/Switch';
import {Modal} from '@components/Utils/Modal';

import type {Character} from '@models/character';

interface OwnProps {
	onClose(): void;

	rosterBossId: RosterBossId;
}

interface MapProps {
	names: string[];
	isCsv: boolean;
}

interface DispatchProps {
	onToggleCsv: BoundThunk<typeof SettingsDuck['setExportCharactersAsCsv']>;
}

type Props = OwnProps & MapProps & DispatchProps;

const CopyRosteredModalComp: React.FC<Props> = (props) => {
	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content copy-rostered-modal">
				<div className="heading">Rostered character names</div>

				<div className="inputs">
					<SwitchField
						onChange={(isCsv) => props.onToggleCsv(isCsv)}
						isControlled={true}
						label="Separate names with a comma"
						value={props.isCsv}
					/>

					<textarea
						className="input"
						value={props.names.join(props.isCsv ? ', ' : '\n')}
						autoFocus={true}
						readOnly={true}
					/>
				</div>
			</div>
		</Modal>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const isAlphabeticalSort = SettingsDuck.getRosterIsAlphabeticalSort(state.settings);

	const assignedCharacterIdsByRole = RosterBossDuck.getAssignmentsForBoss(
		state.rosterBosses,
		props.rosterBossId
	);

	const characterNames: string[] = [];
	ORDERED_EXPORT_ROLES.forEach((role) => {
		const roleCharacters = assignedCharacterIdsByRole[role]
			.reduce<Character[]>((chars, charId) => {
				const char = CharacterDuck.getCharacterForId(state.characters, charId);
				if (!char) return chars;

				return [...chars, char];
			}, [])
			.sort(isAlphabeticalSort ? sortCharactersByName : sortCharactersByClassAndName);

		roleCharacters.forEach((char) => characterNames.push(char.name));
	});

	return {
		isCsv: SettingsDuck.getExportCharactersAsCsv(state.settings),
		names: characterNames
	};
}

export const CopyRosteredModal = connect<MapProps, DispatchProps, OwnProps>(
	mapStateToProps,
	{
		onToggleCsv: SettingsDuck.setExportCharactersAsCsv
	}
)(CopyRosteredModalComp);
