import React from 'react';
import classnames from 'classnames';

import {Button} from '../../../../../../Utils/Button';

interface Props {
	onToggleAssignment(): void;

	/** Whether this role has met it's set limit */
	isAtRoleLimit: boolean;

	isAssigned: boolean;
}

export const Actions: React.FC<Props> = (props) => {
	const buttonClass = classnames(
		{
			'primary subtle': !props.isAssigned,
			'grey outline': props.isAssigned
		},
		'assign-button normal-weight'
	);

	return (
		<div className="character-actions">
			<Button
				onClick={props.onToggleAssignment}
				isDisabled={props.isAtRoleLimit && !props.isAssigned}
				className={buttonClass}
			>
				{props.isAssigned ? 'Unassign' : 'Assign'}
			</Button>
		</div>
	);
};
