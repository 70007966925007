export type CooldownSheetId = Nominal<number, 'CooldownSheet'>;

export interface CooldownSheetData {
	readonly id: CooldownSheetId;
	readonly guildId: GuildId;

	readonly createdAt: string;
	readonly updatedAt: string;
	readonly editedAt: string | null;

	readonly wowBossId: WowBossId;

	readonly name: string;
}

export class CooldownSheet
	implements Omit<CooldownSheetData, 'createdAt' | 'updatedAt' | 'editedAt'>
{
	readonly id: CooldownSheetId;
	readonly guildId: GuildId;

	readonly createdAt: Date;
	readonly updatedAt: Date;
	readonly editedAt: Date | null;

	readonly wowBossId: WowBossId;

	readonly name: string;

	constructor(data: CooldownSheetData) {
		this.id = data.id;
		this.guildId = data.guildId;

		this.createdAt = new Date(data.createdAt);
		this.updatedAt = new Date(data.updatedAt);
		this.editedAt = data.editedAt ? new Date(data.editedAt) : null;

		this.wowBossId = data.wowBossId;

		this.name = data.name;
	}
}
