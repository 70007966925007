import type {CooldownEvent, CooldownEventId} from '@models/cooldownEvent';

export interface State {
	readonly eventById: Record<CooldownEventId, CooldownEvent>;

	readonly isUpdatingNameById: Record<CooldownEventId, boolean>;
	readonly isUpdatingTimeById: Record<CooldownEventId, boolean>;

	readonly isCreatingEvent: boolean;
}

export const CREATE = 'cooldown-event/CREATE';
export const CREATE_SUCCESS = 'cooldown-event/CREATE_SUCCESS';
export const CREATE_FAILURE = 'cooldown-event/CREATE_FAILURE';

export const UPDATE_NAME = 'cooldown-event/UPDATE_NAME';
export const UPDATE_NAME_SUCCESS = 'cooldown-event/UPDATE_NAME_SUCCESS';
export const UPDATE_NAME_FAILURE = 'cooldown-event/UPDATE_NAME_FAILURE';

export const UPDATE_TIME = 'cooldown-event/UPDATE_TIME';
export const UPDATE_TIME_SUCCESS = 'cooldown-event/UPDATE_TIME_SUCCESS';
export const UPDATE_TIME_FAILURE = 'cooldown-event/UPDATE_TIME_FAILURE';

export const REORDER = 'cooldown-event/REORDER';
export const REORDER_SUCCESS = 'cooldown-event/REORDER_SUCCESS';
export const REORDER_FAILURE = 'cooldown-event/REORDER_FAILURE';

export const DELETE = 'cooldown-event/DELETE';
export const DELETE_SUCCESS = 'cooldown-event/DELETE_SUCCESS';
export const DELETE_FAILURE = 'cooldown-event/DELETE_FAILURE';

export interface Actions {
	CREATE: {readonly type: typeof CREATE};
	CREATE_SUCCESS: {readonly type: typeof CREATE_SUCCESS};
	CREATE_FAILURE: {readonly type: typeof CREATE_FAILURE};

	UPDATE_NAME: {
		readonly type: typeof UPDATE_NAME;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly cooldownEvent: CooldownEvent;
		};
	};
	UPDATE_NAME_SUCCESS: {
		readonly type: typeof UPDATE_NAME_SUCCESS;
		readonly optimist: Optimist.ICommit;
		readonly payload: {
			readonly cooldownEventId: CooldownEventId;
		};
	};
	UPDATE_NAME_FAILURE: {
		readonly type: typeof UPDATE_NAME_FAILURE;
		readonly optimist: Optimist.IRevert;
		readonly payload: {
			readonly cooldownEventId: CooldownEventId;
		};
	};

	UPDATE_TIME: {
		readonly type: typeof UPDATE_TIME;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly cooldownEvent: CooldownEvent;
		};
	};
	UPDATE_TIME_SUCCESS: {
		readonly type: typeof UPDATE_TIME_SUCCESS;
		readonly optimist: Optimist.ICommit;
		readonly payload: {
			readonly cooldownEventId: CooldownEventId;
		};
	};
	UPDATE_TIME_FAILURE: {
		readonly type: typeof UPDATE_TIME_FAILURE;
		readonly optimist: Optimist.IRevert;
		readonly payload: {
			readonly cooldownEventId: CooldownEventId;
		};
	};

	REORDER: {
		readonly type: typeof REORDER;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly cooldownEvent: CooldownEvent;
		};
	};
	REORDER_SUCCESS: {
		readonly type: typeof REORDER_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	REORDER_FAILURE: {
		readonly type: typeof REORDER_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	DELETE: {
		readonly type: typeof DELETE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly cooldownEventId: CooldownEventId;
		};
	};
	DELETE_SUCCESS: {
		readonly type: typeof DELETE_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	DELETE_FAILURE: {
		readonly type: typeof DELETE_FAILURE;
		readonly optimist: Optimist.IRevert;
	};
}
