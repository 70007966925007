import React from 'react';

import * as images from '../../helpers/images';

function random(lower: number, upper: number) {
	return lower + Math.floor(Math.random() * (upper - lower + 1));
}

interface IShape {
	key: string;
	src: string;
	top: number;
	left: number;
}

interface IState {
	shapes: IShape[];
}

export default class ImageBackground extends React.Component<{}, IState> {
	state: IState = {shapes: []};
	resizeTimeout?: number;

	componentDidMount() {
		this.handleMeasure();

		window.addEventListener('resize', this.resizeListener, false);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeListener, false);
	}

	resizeListener = () => {
		if (this.resizeTimeout) return;

		this.resizeTimeout = window.setTimeout(() => {
			this.resizeTimeout = undefined;
			this.handleMeasure();
		});
	};

	handleMeasure = () => {
		const numCols = Math.ceil(window.innerWidth / 220);
		const numRows = Math.ceil(window.innerHeight / 220);

		const shapes: IShape[] = [];

		for (let i = 0; i < numCols; i += 1) {
			for (let j = 0; j < numRows; j += 1) {
				shapes.push({
					key: `${i}::${j}`,
					src: images.getBgImageUrl(),
					top: j * 220 + 20 + random(-50, 80),
					left: i * 220 + 20 + random(-50, 80)
				});
			}
		}

		this.setState({shapes});
	};

	render() {
		const shapes = this.state.shapes.map((shape) => (
			<img
				key={shape.key}
				src={shape.src}
				style={{top: shape.top, left: shape.left}}
				alt=""
			/>
		));

		return (
			<div className="image-background-wrapper">
				<div className="image-background">{shapes}</div>

				{React.cloneElement(
					this.props.children as React.ReactElement<any>,
					this.props
				)}
			</div>
		);
	}
}
