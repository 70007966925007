import React from 'react';
import {Link} from 'react-router';

import {useModal} from '@hooks/useModal';

import Support from '../Utils/Support';
import {BrandName} from '../Utils/BrandName';
import {RegionPicker} from './RegionPicker';

interface MethodProps {
	isEasy: boolean;
}

const Method: React.FC<MethodProps> = (props) => {
	return (
		<div className="method">
			<div className={`method-label ${props.isEasy ? 'easy' : 'hard'}`}>
				{props.isEasy ? 'Low Effort Method' : 'More Effort Method'}
			</div>

			{props.children}
		</div>
	);
};

interface MissingScopeProps {
	onRegionSelect(region: string): void;
}

export const MissingScope: React.FC<MissingScopeProps> = (props) => {
	const regionPickerModal = useModal();

	return (
		<div className="page-container">
			{regionPickerModal.isOpen && (
				<RegionPicker
					onClick={props.onRegionSelect}
					onClose={regionPickerModal.close}
					isCacheBust={true}
				/>
			)}

			<Support />

			<div className="auth-page no-wow-permission">
				<div className="auth-content card">
					<div className="heading">This is a bit of a pickle...</div>

					<div className="content">
						<div className="description">
							<p>
								<span>Unfortunately in order for </span>
								<span className="brand-name">Readycheck</span>
								<span>
									{' '}
									to function, it needs to know which characters you own and you
									did not grant that permission.
								</span>
							</p>

							<p>
								<span>If you'd like to use </span>
								<span className="brand-name">Readycheck</span>
								<span>
									{' '}
									we're going to need to go on a bit of a journey to get this all
									sorted as Blizzard remembers your previous settings for 30
									days.
								</span>
							</p>

							<p>
								<span>If you get stuck, head over to the </span>
								<a
									href="https://readycheck.io/contact"
									target="_blank"
									rel="noreferrer noopener"
								>
									contact page
								</a>
								<span> and we'll see what we can do to help.</span>
							</p>

							<Method isEasy={true}>
								<BrandName /> can force the permissions flow again by including
								the "Starcraft 2 Profile" option, you can untick this if you want.
								<ul>
									<li>Be sure to keep the "WoW Profile" option ticked</li>
								</ul>
								<div
									onClick={regionPickerModal.open}
									className="primary faded button"
								>
									Give it another go
								</div>
							</Method>

							<Method isEasy={false}>
								<ol>
									<li>
										<span>Sign in to </span>
										<a
											href="https://blizzard.com"
											target="_blank"
											rel="noreferrer noopener"
										>
											https://blizzard.com
										</a>
										<span> and go to "Account Settings"</span>
									</li>

									<li>Click on "Connections" in the left side menu</li>

									<li>Scroll down to AUTHORIZED APPLICATIONS</li>

									<li>
										<span>Remove your connection to </span>
										<span className="brand-name">Readycheck</span>
									</li>

									<li>
										<Link to="/connect">Reconnect</Link>
										<span> your account to </span>
										<span className="brand-name">Readycheck</span>
										<span>, ensuring the "WoW Profile" option is selected</span>
									</li>
								</ol>
							</Method>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
