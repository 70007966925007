import type {Role} from '@constants/wow';

import {sortCharactersByClassAndName, sortCharactersByName} from '@helpers/toolbox';

import type {Character} from '@models/character';

/**
 * Get the list of relevant characters for this roster while taking into account
 * the guild the roster is in and any tags used in the roster itself
 */
export function getCharacters(data: {
	isAlphabeticalSort: boolean;

	selectedRole: Role;

	assignedCharacterIds: CharacterId[];
	guildCharacters: Character[];

	includedTagCharacterIds: CharacterId[];
	excludedTagCharacterIds: CharacterId[];
}): Character[] {
	// if there's "included" tags, then be sure to initially include all of those character,
	// otherwise fall back to included every character in the guild
	const initiallyIncludedCharacterIds = data.includedTagCharacterIds.length
		? data.includedTagCharacterIds
		: data.guildCharacters.map((x) => x.id);

	const allowedCharacterIds = new Set<CharacterId>(initiallyIncludedCharacterIds);

	// exclude all the character IDs from the excluded tags
	data.excludedTagCharacterIds.forEach((x) => allowedCharacterIds.delete(x));

	// add in the assigned character IDs at the end to ensure they're always included
	// even after handling the excluded tags
	data.assignedCharacterIds.forEach((x) => allowedCharacterIds.add(x));

	// filter the guild characters to just those in the allowed list that can also
	// perform the selected role
	return data.guildCharacters
		.filter((character) => {
			// filter out characters that can't do the selected role
			if (!character.roles.includes(data.selectedRole)) return false;

			// filter out character that aren't in the "allowed" set
			if (!allowedCharacterIds.has(character.id)) return false;

			return true;
		})
		.sort(
			data.isAlphabeticalSort ? sortCharactersByName : sortCharactersByClassAndName
		);
}
