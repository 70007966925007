import React from 'react';
import {connect} from 'react-redux';

import * as RaidDataDuck from '../ducks/raid-data';
import * as GuildDuck from '../ducks/guild';
import * as UserDuck from '../ducks/user';

import type {User} from '../models/user';

import Loading from './Utils/Loading';
import Support from './Utils/Support';

interface IAuthedContainerProps {
	fetchRaidData: typeof RaidDataDuck.fetchRaidData;
	fetchGuilds: typeof GuildDuck.fetchGuilds;
	fetchUser: typeof UserDuck.fetchUser;

	isLoading: boolean;

	user: User | undefined;
}

class AuthedContainer extends React.Component<IAuthedContainerProps> {
	constructor(props: IAuthedContainerProps) {
		super(props);

		this.handleFetchData(props.user);
	}

	handleFetchData = (user?: User) => {
		if (!user) this.props.fetchUser();
		this.props.fetchGuilds();
		this.props.fetchRaidData();
	};

	render() {
		if (!this.props.user && !this.props.isLoading) {
			return false;
		}

		let content = this.props.children;

		if (this.props.isLoading) {
			content = <Loading isCentered={true} />;
		}

		return (
			<div className="app">
				<Support />

				{content}
			</div>
		);
	}
}

function getLoading(state: IRootState) {
	return (
		UserDuck.getUserLoading(state.user) ||
		GuildDuck.getGuildsLoading(state.guilds) ||
		RaidDataDuck.getRaidDataLoading(state.raidData)
	);
}

function mapStateToProps(state: IRootState) {
	return {
		isLoading: getLoading(state),

		user: UserDuck.getUser(state.user)
	};
}

export default connect(mapStateToProps, {
	fetchRaidData: RaidDataDuck.fetchRaidData,
	fetchGuilds: GuildDuck.fetchGuilds,
	fetchUser: UserDuck.fetchUser
})(AuthedContainer);
