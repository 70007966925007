interface ISetupGuildClass {
	faction: string;
	region: string;
	name: string;
	realm: string;
}

export class SetupGuild implements ISetupGuildClass {
	readonly faction: string;
	readonly region: string;
	readonly name: string;
	readonly realm: string;

	constructor(data?: ISetupGuildClass) {
		if (!data) throw new Error('invalid setup guild data');

		this.faction = data.faction;
		this.region = data.region;
		this.name = data.name;
		this.realm = data.realm;
	}
}

interface ISetupCharacterClass {
	faction: string;
	name: string;
	realm: string;
	class: string;
	race: string;
	gender: string;
	level: number;
	thumbnail: string;

	// guild stuff isn't optional in this case
	region: string;
	guildRealm: string;
	guildName: string;
	rank: number;
}

export class SetupCharacter implements ISetupCharacterClass {
	readonly faction: string;
	readonly name: string;
	readonly realm: string;
	readonly class: string;
	readonly race: string;
	readonly gender: string;
	readonly level: number;
	readonly thumbnail: string;

	readonly region: string;
	readonly guildRealm: string;
	readonly guildName: string;
	readonly rank: number;

	constructor(data?: ISetupCharacterClass) {
		if (!data) throw new Error('invalid setup character data');

		this.faction = data.faction;
		this.name = data.name;
		this.realm = data.realm;
		this.class = data.class;
		this.race = data.race;
		this.gender = data.gender;
		this.level = data.level;
		this.thumbnail = data.thumbnail;

		this.guildRealm = data.guildRealm;
		this.guildName = data.guildName;
		this.region = data.region;
		this.rank = data.rank;
	}
}
