import React from 'react';
import {Link} from 'react-router';

import {guildSettingsExternalCharactersUrl} from '@helpers/urls';

import type {IProps} from './AutoInviteContainer';
import Switch from '../../Utils/Switch';

interface IRankRowProps {
	onChange(index: number, isAutoInvite: boolean): void;

	isAutoInvite: boolean;
	rankName: string;
	index: number;
}

function RankRow(props: IRankRowProps) {
	return (
		<div className="group-item">
			<Switch
				onChange={(isAutoInvite) => props.onChange(props.index, isAutoInvite)}
				isControlled={true}
				label={props.rankName}
				note={`Rank ${props.index + 1}`}
				value={props.isAutoInvite}
			/>
		</div>
	);
}

export const AutoInvite: React.FC<IProps> = (props) => {
	const items = props.rankGroups.map((rankGroup, i) => (
		<RankRow
			// eslint-disable-next-line react/no-array-index-key
			key={i}
			onChange={props.onToggleRank}
			isAutoInvite={rankGroup.isAutoInvite}
			rankName={rankGroup.name}
			index={i}
		/>
	));

	return (
		<div className="settings-content auto-invite-settings">
			<div className="settings-heading">Add Characters</div>

			<div className="settings-field-group">
				<div className="group-description">
					Manually select characters from your guild to be added to{' '}
					<span className="brand-name">Readycheck</span>.
				</div>

				<Link
					to={`${props.baseGuildUrl}/import-characters`}
					className="medium faded primary button"
				>
					Import characters from {props.guild.name}
				</Link>
			</div>

			<div className="settings-field-group">
				<div className="group-description">
					Add characters from outside your guild to{' '}
					<span className="brand-name">Readycheck</span>.
				</div>

				<Link
					to={guildSettingsExternalCharactersUrl(props.guild.id)}
					className="medium faded primary button"
				>
					Add non-guild character
				</Link>
			</div>

			<div className="settings-field-group">
				<div className="settings-heading">Automatically add characters</div>
				<div className="group-heading">Which ranks are set to automatic import</div>

				<div className="group-description">
					When a rank is marked as "automatic import", all characters of that rank in
					your guild will be automatically imported into{' '}
					<span className="brand-name">Readycheck</span> so you don't have to
					manually add them. For example you could use this to automatically add new
					trial raiders that join your guild.
				</div>

				{items}
			</div>
		</div>
	);
};
