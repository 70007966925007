import React from 'react';

import type {CooldownEventDuck, CooldownActionDuck} from '@ducks';

import type {CooldownSheet} from '@models/cooldownSheet';

import {Table} from './Table';

interface ContentProps {
	onCreateEvent: BoundThunk<typeof CooldownEventDuck['createCooldownEvent']>;
	onUpdateEventName: BoundThunk<typeof CooldownEventDuck['updateNameCooldownEvent']>;
	onUpdateEventTime: BoundThunk<typeof CooldownEventDuck['updateTimeCooldownEvent']>;
	onReorderEvent: BoundThunk<typeof CooldownEventDuck['reorderCooldownEvent']>;
	onDeleteEvent: BoundThunk<typeof CooldownEventDuck['deleteCooldownEvent']>;
	onCreateAction: BoundThunk<typeof CooldownActionDuck['createCooldownAction']>;
	onUpdateActionActor: BoundThunk<
		typeof CooldownActionDuck['updateActorCooldownAction']
	>;
	onUpdateActionAbility: BoundThunk<
		typeof CooldownActionDuck['updateAbilityCooldownAction']
	>;
	onDeleteAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;

	isEditor: boolean;

	rosterBossId: RosterBossId;

	cooldownSheet: CooldownSheet;
}

export const Content: React.FC<ContentProps> = (props) => {
	return (
		<div className="cooldown-view-content">
			<Table
				onCreateEvent={props.onCreateEvent}
				onUpdateEventName={props.onUpdateEventName}
				onUpdateEventTime={props.onUpdateEventTime}
				onReorderEvent={props.onReorderEvent}
				onDeleteEvent={props.onDeleteEvent}
				onCreateAction={props.onCreateAction}
				onUpdateActionActor={props.onUpdateActionActor}
				onUpdateActionAbility={props.onUpdateActionAbility}
				onDeleteAction={props.onDeleteAction}
				isEditor={props.isEditor}
				rosterBossId={props.rosterBossId}
				cooldownSheet={props.cooldownSheet}
			/>
		</div>
	);
};
