import React from 'react';

import {connect, RosterDuck, RaidDataDuck, RosterBossDuck} from '@ducks';

import {useBoolean} from '@hooks/useBoolean';

import {ConfirmModal} from '@components/Utils/ConfirmModal';
import {Input} from '@components/Utils/Input';
import {SwitchField} from '@components/Utils/Switch';

interface BossItem {
	name: string;
	rosterBossId: RosterBossId;
}

interface OwnProps {
	onClose(): void;

	rosterId: RosterId;
}

interface MapProps {
	isSubmitting: boolean;

	bossItems: BossItem[];
	discordWebhookUrl: string | undefined;
	discordWebhookPretext: string | undefined;
	isShowingFirstSwapIn: boolean;
	isShowingSwaps: boolean;
}

interface DispatchProps {
	onSubmit: BoundThunk<typeof RosterDuck['postToDiscord']>;
}

type Props = OwnProps & MapProps & DispatchProps;

const PostToDiscordComp: React.FC<Props> = (props) => {
	const showingFirstSwapIn = useBoolean(props.isShowingFirstSwapIn);
	const showingSwaps = useBoolean(props.isShowingSwaps);
	const [rosterBossIds, setRosterBossIds] = React.useState<RosterBossId[]>(
		props.bossItems.map((x) => x.rosterBossId)
	);
	const $webhookUrl = React.useRef<Input>(null);
	const $pretext = React.useRef<Input>(null);

	async function handleSubmit(): Promise<void> {
		const webhookUrl = $webhookUrl.current?.getValue();
		if (!webhookUrl) return;

		if (!rosterBossIds.length) return;

		await props.onSubmit({
			rosterId: props.rosterId,
			rosterBossIds,

			pretext: $pretext.current?.getValue() || undefined,
			isShowingFirstSwapIn: showingFirstSwapIn.value,
			isShowingSwaps: showingSwaps.value,
			webhookUrl
		});

		props.onClose();
	}

	function handleBossToggle(isSwitched: boolean, rosterBossId: RosterBossId): void {
		if (isSwitched) setRosterBossIds([...rosterBossIds, rosterBossId]);
		else setRosterBossIds(rosterBossIds.filter((x) => x !== rosterBossId));
	}

	const bossItems = props.bossItems.map((bossItem) => (
		<SwitchField
			key={bossItem.rosterBossId}
			onChange={(isSwitched) => handleBossToggle(isSwitched, bossItem.rosterBossId)}
			withTopMargin={true}
			defaultValue={true}
			label={bossItem.name}
		/>
	));

	return (
		<ConfirmModal
			modalClassName="normal-alignment"
			onSubmit={handleSubmit}
			onClose={props.onClose}
			isSubmitting={props.isSubmitting}
			heading="Post to Discord"
			submitButtonText="Post"
			submitColour="primary"
		>
			<Input
				ref={$webhookUrl}
				defaultValue={props.discordWebhookUrl}
				label="Discord channel webhook URL"
				note={
					<span>
						<a
							className="linkify"
							href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
							target="_blank"
							rel="noreferrer noopener"
						>
							Learn more
						</a>{' '}
						about Discord webhooks
					</span>
				}
			/>

			<Input
				ref={$pretext}
				defaultValue={props.discordWebhookPretext}
				label="Message"
				labelHint="(optional)"
				isTextarea={true}
			/>

			<SwitchField
				onChange={(isSwitched) => showingSwaps.setValue(isSwitched)}
				withTopMargin={true}
				isControlled={true}
				value={showingSwaps.value}
				label="Show information about the swaps to make between each boss"
			/>

			<SwitchField
				onChange={(isSwitched) => showingFirstSwapIn.setValue(isSwitched)}
				withTopMargin={true}
				isControlled={true}
				value={showingFirstSwapIn.value}
				label="Hide information about the swaps-in for the boss"
			/>

			<div className="boss-items" style={{marginTop: '2rem'}}>
				{bossItems}
			</div>
		</ConfirmModal>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const roster = RosterDuck.getRoster(state.rosters, props.rosterId);

	const rosterBosses = RosterBossDuck.getBossesForRoster(
		state.rosterBosses,
		props.rosterId
	);
	const wowBosses =
		(roster &&
			RaidDataDuck.getRaidInstance(state.raidData, roster.wowInstanceId)?.bosses) ||
		[];

	const bossItems = rosterBosses.reduce<BossItem[]>((items, rosterBoss): BossItem[] => {
		const wowBoss = wowBosses.find((x) => rosterBoss.bossId === x.id);
		if (!wowBoss) return items;

		return [
			...items,
			{
				name: wowBoss.name,
				rosterBossId: rosterBoss.id
			}
		];
	}, []);

	return {
		isSubmitting: RosterDuck.getPostingToDiscord(state.rosters),

		discordWebhookUrl: roster?.discordWebhookUrl || undefined,
		discordWebhookPretext: roster?.discordWebhookPretext || undefined,
		isShowingFirstSwapIn: roster?.isShowingDiscordFirstSwapIn ?? false,
		isShowingSwaps: roster?.isShowingDiscordSwaps ?? false,
		bossItems
	};
}

export const PostToDiscord = connect<MapProps, DispatchProps, OwnProps>(mapStateToProps, {
	onSubmit: RosterDuck.postToDiscord
})(PostToDiscordComp);
