import React from 'react';

export const DelayedFallback: React.FC = () => {
	const [isShowing, setIsShowing] = React.useState(false);

	React.useEffect(() => {
		const timeout = setTimeout(() => setIsShowing(true), 200);

		return () => clearTimeout(timeout);
	}, []);

	return <>{isShowing && <div>Loading...</div>}</>;
};
