import React from 'react';
import {components, OptionProps, OnChangeValue} from 'react-select';
import Select from 'react-select/creatable';

import {CharacterAbility, characterAbilities} from '@shared/constants/characterAbilities';
import type {Classes, Role} from '@constants/wow';

import {getSpellIconUrl} from '@helpers/images';

import {connect2} from '@ducks';

import {Pane} from '@components/Utils/Pane';
import {Image} from '@components/Utils/Image';
import {selectSize, createCustomSelectComponents} from './ActorSelect';

interface SelectOption {
	ability: CharacterAbility;

	// react select defaults
	label: string;
	value: string;
}

const CustomReactSelect = createCustomSelectComponents<SelectOption>();

const CustomSelectOption: React.FC<OptionProps<SelectOption, false>> = (props) => {
	// since this is a creatable select we also need to handle the creation option case
	// eslint-disable-next-line no-underscore-dangle
	if ((props.data as {__isNew__?: unknown}).__isNew__) {
		return <components.Option {...props} />;
	}

	const {ability} = props.data;

	return (
		<components.Option {...props}>
			<Image
				src={getSpellIconUrl(ability.iconName)}
				className="action-select-option-image"
			/>

			<div className="action-select-option-name">{ability.name}</div>
		</components.Option>
	);
};

interface OwnProps {
	onSelect(wowAbilityId: WowAbilityId): void;
	onFreeformText(text: string): void;
	onClose(): void;

	actorClass: Classes | undefined;
	/** The role the character is rostered in for */
	actorRole: Role | undefined;
}

interface StateProps {
	options: SelectOption[];
}

type AbilitySelectProps = OwnProps & StateProps;

const AbilitySelectComp: React.FC<AbilitySelectProps> = (props) => {
	function handleCreate(text: string): void {
		if (!text || !text.trim()) return;
		props.onFreeformText(text);
	}

	function handleChange(value: OnChangeValue<SelectOption, false>): void {
		if (!value) return;
		props.onSelect(value.ability.wowAbilityId);
	}

	return (
		<Pane onClose={props.onClose} className="action-field-select" withBorder={true}>
			<Select<SelectOption>
				onCreateOption={handleCreate}
				onChange={handleChange}
				className={`react-select ${selectSize}`}
				classNamePrefix="react-select-prefix"
				components={{
					DropdownIndicator: null,
					IndicatorSeparator: null,
					Input: CustomReactSelect.CustomSelectInput,
					Option: CustomSelectOption
				}}
				autoFocus={true}
				backspaceRemovesValue={false}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				menuIsOpen={true}
				options={props.options}
				placeholder="Select ability"
				formatCreateLabel={CustomReactSelect.formatCreateLabel}
				styles={CustomReactSelect.selectStyleOverrides}
			/>
		</Pane>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const options: SelectOption[] = characterAbilities.reduce<SelectOption[]>(
		(tempOptions, ability): SelectOption[] => {
			// filter out abilities that don't relate to the actor's class
			if (props.actorClass && !ability.classes.has(props.actorClass)) {
				return tempOptions;
			}

			// filter out abilities that aren't part of the actor's role
			if (props.actorRole && !ability.roles.has(props.actorRole)) return tempOptions;

			return [
				...tempOptions,

				{
					ability,

					label: ability.name,
					value: ability.wowAbilityId
				}
			];
		},
		[]
	);

	return {
		options
	};
}

export const AbilitySelect = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: {};
}>(
	mapStateToProps,
	{}
)(AbilitySelectComp);
