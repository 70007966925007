import * as Feed from '@constants/feed';

import {RootAction, GuildSponsorshipDuck, UserDuck, GuildDuck} from '@ducks';

import {GuildSponsorship} from '@models/guildSponsorship';

const initialState: GuildSponsorshipDuck.IState = {
	guildSponsorshipById: {},

	sponsoringGuildId: null,
	unsponsoringGuildId: null
};

export default function reducer(
	state = initialState,
	action: RootAction
): GuildSponsorshipDuck.IState {
	switch (action.type) {
		// load inital records
		case UserDuck.FETCH_SUCCESS:
		case GuildDuck.FETCH_DATA_SUCCESS: {
			const sponsorshipById = action.payload.guildSponsorships.reduce<
				Record<number, GuildSponsorship>
			>((carry, sponsorship) => {
				return {
					...carry,
					[sponsorship.id]: sponsorship
				};
			}, {});

			return {
				...state,

				guildSponsorshipById: {
					...state.guildSponsorshipById,
					...sponsorshipById
				}
			};
		}

		// sponsor
		case GuildSponsorshipDuck.SPONSOR: {
			return {
				...state,
				sponsoringGuildId: action.payload.guildId
			};
		}

		case GuildSponsorshipDuck.SPONSOR_SUCCESS:
		case GuildSponsorshipDuck.SPONSOR_FAILURE: {
			return {
				...state,
				sponsoringGuildId: null
			};
		}

		// unsponsor
		case GuildSponsorshipDuck.UNSPONSOR: {
			return {
				...state,
				unsponsoringGuildId: action.payload.guildId
			};
		}

		case GuildSponsorshipDuck.UNSPONSOR_SUCCESS:
		case GuildSponsorshipDuck.UNSPONSOR_FAILURE: {
			return {
				...state,
				unsponsoringGuildId: null
			};
		}

		// feed
		case Feed.GUILD_SPONSORSHIP_INSERT:
		case Feed.GUILD_SPONSORSHIP_UPDATE: {
			return {
				...state,

				guildSponsorshipById: {
					...state.guildSponsorshipById,
					[action.payload.newRecord.id]: new GuildSponsorship(
						action.payload.newRecord
					)
				}
			};
		}

		case Feed.GUILD_SPONSORSHIP_DELETE: {
			const {[action.payload.oldRecord.id]: deleted, ...rest} =
				state.guildSponsorshipById;

			return {
				...state,

				guildSponsorshipById: rest
			};
		}

		default:
			return state;
	}
}
