import React from 'react';

import {RosterViewVersion} from '@constants';

import {connect, UserDuck} from '@ducks';

import {router, rosterUrl} from '@helpers/urls';

import {Button} from '@components/Utils/Button';

interface OwnProps {
	currentVersion: 'new' | 'old';
	rosterId: RosterId;
	guildId: GuildId;
}

interface DispatchProps {
	onUpdateRosterViewVersion: BoundThunk<typeof UserDuck['updateRosterViewVersion']>;
}

type Props = OwnProps & DispatchProps;

const VersionSwitcherComp: React.FC<Props> = (props) => {
	function handleSwitch() {
		void props.onUpdateRosterViewVersion(
			props.currentVersion === 'new' ? RosterViewVersion.ONE : RosterViewVersion.TWO
		);

		router.push(rosterUrl(props.guildId, props.rosterId, undefined));
	}

	return (
		<div style={{marginRight: '0.75rem'}}>
			<Button onClick={handleSwitch} className="underlined link">
				{props.currentVersion === 'new'
					? 'Switch back to old version'
					: 'Go to the new roster view'}
			</Button>
		</div>
	);
};

export const VersionSwitcher = connect<{}, DispatchProps, OwnProps>(() => ({}), {
	onUpdateRosterViewVersion: UserDuck.updateRosterViewVersion
})(VersionSwitcherComp);
