import {FACTIONS, Regions} from '@constants/wow';

import type * as GuildDuck from '.';
import {UserDuck, CharacterDuck} from '@ducks';

import {addUnknownRank} from '@helpers/toolbox';

import {Guild} from '@models/guild';

function makeBackupGuild(): Guild {
	const rankNames = [
		'GM',
		'Rank 2',
		'Rank 3',
		'Rank 4',
		'Rank 5',
		'Rank 6',
		'Rank 7',
		'Rank 8',
		'Rank 9',
		'Rank 10'
	];

	return new Guild({
		id: 0,
		importerId: 0,

		normalRankNames: rankNames,
		fullRankNames: addUnknownRank(rankNames),

		adminRanks: [true, true, false, false, false, false, false, false, false, false],
		autoInviteRanks: [
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false
		],

		adminUserIds: [],

		isLootSheetAdminOnly: false,
		isRosterAssignmentsAdminOnly: false,
		isRosterCooldownsAdminOnly: false,
		isRosterNotesAdminOnly: false,
		isImporterAdmin: false,

		faction: FACTIONS.HORDE,
		region: Regions.US,
		name: 'Unknown',
		realm: 'Unknown',

		additionalAccessFeatures: []
	});
}

export function getRankRenaming(state: GuildDuck.IState) {
	return state.isRenamingRank;
}

export function getActiveGuildId(state: GuildDuck.IState) {
	return state.activeId;
}

export function getGuildsLoading(state: GuildDuck.IState) {
	return state.isLoadingList;
}

export function getGuildDataLoading(state: GuildDuck.IState) {
	return state.isLoadingData;
}

export function getGuilds(state: GuildDuck.IState): Guild[] {
	return Object.values(state.byId);
}

export function getGuild(state: GuildDuck.IState, id: number | string) {
	return state.byId[id];
}

export function getActiveGuild(state: GuildDuck.IState) {
	return state.activeId !== undefined ? state.byId[state.activeId] : undefined;
}

/** **Must** only be used in a context where there's actually an active guild */
export function getActiveGuildNew(state: GuildDuck.IState): Guild {
	return (state.activeId && state.byId[state.activeId]) || makeBackupGuild();
}

export function isUserAdminOfGuild(
	guildState: GuildDuck.IState,
	userState: UserDuck.IState,
	characterState: CharacterDuck.IState
): boolean {
	const guild = getActiveGuild(guildState);
	if (!guild) return false;

	const user = UserDuck.getUser(userState);
	if (!user) return false;

	const characters = CharacterDuck.getAllCharactersForGuild(characterState, guild.id);

	return guild.isUserAdmin(user, characters);
}

interface IGuildDetails {
	region: string;
	realm: string;
	name: string;
}

export function getGuildByDetails(state: GuildDuck.IState, details: IGuildDetails) {
	const match = Object.values(state.byId).find(
		(guild) =>
			guild.region === details.region &&
			guild.realm === details.realm &&
			guild.name === details.name
	);

	return match;
}
