import React from 'react';

import type {Props} from './AdminSettingsContainer';

import {RankAdmins} from './RankAdmins';
import {ImporterAdmin} from './ImporterAdmin';
import {UserAdmins} from './UserAdmins';

export const AdminSettings: React.FC<Props> = (props) => {
	return (
		<div className="settings-content admin-settings">
			<div className="settings-heading">Guild admins</div>

			<RankAdmins
				onToggleRank={props.onToggleRank}
				adminRanks={props.adminRanks}
				rankNames={props.rankNames}
				usersCharacterRanks={props.usersCharacterRanks}
			/>

			<ImporterAdmin
				onToggle={props.onToggleImporter}
				isImporterAdmin={props.isImporterAdmin}
				isUserImporter={props.isUserImporter}
			/>

			<UserAdmins adminUserIds={props.adminUserIds} guildId={props.guild.id} />
		</div>
	);
};
