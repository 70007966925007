import React from 'react';
import {Link} from 'react-router';

export default function Is404() {
	return (
		<div className="page-container">
			<div className="content-card card">
				<div className="heading">
					<i className="red lnr lnr-sad" />

					<div>Guild not found</div>
				</div>

				<div className="content">
					<div className="description">
						<p>
							Unable to locate this guild. Please verify the details were correct
							or try again later.
						</p>
					</div>

					<div className="button-group">
						<Link to="/select-guild" className="large primary faded button">
							Select guild
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
