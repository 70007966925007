import React from 'react';

import {DifficultyDisplay} from '@constants/wow';

import {dates} from '@helpers/dates';

import {useModal} from '@hooks/useModal';

import type {DroptimizerReport} from '@models/droptimizerReport';
import type {Character} from '@models/character';

import {CharacterDetails} from '../Utils/CharacterDetails';

import {Button} from '@components/Utils/Button';
import {DeleteReportModal} from './DeleteReportModal';

interface Props {
	report: DroptimizerReport;
	character: Character;
	canDelete: boolean;
}

export const Report: React.FC<Props> = (props) => {
	const deleteReportModal = useModal();

	const reportAge = dates(props.report.raidbotsReportCreatedAt).fromNow();

	return (
		<>
			{deleteReportModal.isOpen && (
				<DeleteReportModal
					onClose={deleteReportModal.close}
					character={props.character}
					report={props.report}
				/>
			)}

			<div className="report">
				<CharacterDetails
					name={props.character.name}
					class={props.character.class}
					avatarImageUrl={props.character.getAvatarUrl()}
				/>

				<div className="report-info">
					<div className="report-info-top">
						<a
							className="raidbots-link"
							href={props.report.raidbotsReportUrl}
							rel="noopener noreferrer"
							target="_blank"
						>
							View report on raidbots.com
						</a>

						{props.canDelete && (
							<Button onClick={deleteReportModal.open} className="small red">
								Delete
							</Button>
						)}
					</div>

					<div
						title={props.report.raidbotsReportCreatedAt.toISOString()}
						className="report-age"
					>
						{DifficultyDisplay[props.report.difficulty]} report generated{' '}
						{reportAge}
					</div>
				</div>
			</div>
		</>
	);
};
