import type {LootSelection} from '../../models/loot-selection';
import type {LootOption} from '../../models/loot-option';
import type {LootCoin} from '../../models/loot-coin';

export interface IState {
	readonly optionsById: {
		readonly [key: number]: LootOption;
		readonly [key: string]: LootOption;
	};

	readonly selectionsByCharacterId: {
		readonly [key: number]: LootSelection[];
		readonly [key: string]: LootSelection[];
	};

	readonly coinsByCharacterId: {
		readonly [key: number]: LootCoin[];
		readonly [key: string]: LootCoin[];
	};

	readonly isSubmittingRclc: boolean;
	readonly isSubmitting: boolean;
	readonly isDeleting: boolean;
	readonly isBumping: boolean;
}

export const CREATE = 'loot-option/CREATE';
export const CREATE_SUCCESS = 'loot-option/CREATE_SUCCESS';
export const CREATE_FAILURE = 'loot-option/CREATE_FAILURE';

export const UPDATE = 'loot-option/UPDATE';
export const UPDATE_SUCCESS = 'loot-option/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'loot-option/UPDATE_FAILURE';

export const DELETE = 'loot-option/DELETE';
export const DELETE_SUCCESS = 'loot-option/DELETE_SUCCESS';
export const DELETE_FAILURE = 'loot-option/DELETE_FAILURE';

export const REORDER = 'loot-option/REORDER';
export const REORDER_SUCCESS = 'loot-option/REORDER_SUCCESS';
export const REORDER_FAILURE = 'loot-option/REORDER_FAILURE';

export const RCLC_SUBMIT = 'loot-option/RCLC_SUBMIT';
export const RCLC_SUBMIT_SUCCESS = 'loot-option/RCLC_SUBMIT_SUCCESS';
export const RCLC_SUBMIT_FAILURE = 'loot-option/RCLC_SUBMIT_FAILURE';

export const SELECTION = 'loot-option/SELECTION';
export const SELECTION_REMOVE = 'loot-option/SELECTION_REMOVE';
export const SELECTION_SUCCESS = 'loot-option/SELECTION_SUCCESS';
export const SELECTION_FAILURE = 'loot-option/SELECTION_FAILURE';

export const SELECTION_NOTE = 'loot-selection/SELECTION_NOTE';
export const SELECTION_NOTE_SUCCESS = 'loot-selection/SELECTION_NOTE_SUCCESS';
export const SELECTION_NOTE_FAILURE = 'loot-selection/SELECTION_NOTE_FAILURE';

export const COIN = 'loot-option/COIN';
export const COIN_REMOVE = 'loot-option/COIN_REMOVE';
export const COIN_SUCCESS = 'loot-option/COIN_SUCCESS';
export const COIN_FAILURE = 'loot-option/COIN_FAILURE';

export const BUMP = 'loot-option/BUMP';
export const BUMP_SUCCESS = 'loot-option/BUMP_SUCCESS';
export const BUMP_FAILURE = 'loot-option/BUMP_FAILURE';

export interface IActions {
	CREATE: {readonly type: typeof CREATE};
	CREATE_SUCCESS: {readonly type: typeof CREATE_SUCCESS};
	CREATE_FAILURE: {readonly type: typeof CREATE_FAILURE};

	UPDATE: {readonly type: typeof UPDATE};
	UPDATE_SUCCESS: {readonly type: typeof UPDATE_SUCCESS};
	UPDATE_FAILURE: {readonly type: typeof UPDATE_FAILURE};

	DELETE: {readonly type: typeof DELETE};
	DELETE_SUCCESS: {readonly type: typeof DELETE_SUCCESS};
	DELETE_FAILURE: {readonly type: typeof DELETE_FAILURE};

	REORDER: {
		readonly type: typeof REORDER;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly option: LootOption;
		};
	};
	REORDER_SUCCESS: {
		readonly type: typeof REORDER_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	REORDER_FAILURE: {
		readonly type: typeof REORDER_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	RCLC_SUBMIT: {readonly type: typeof RCLC_SUBMIT};
	RCLC_SUBMIT_SUCCESS: {readonly type: typeof RCLC_SUBMIT_SUCCESS};
	RCLC_SUBMIT_FAILURE: {readonly type: typeof RCLC_SUBMIT_FAILURE};

	SELECTION: {
		readonly type: typeof SELECTION;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly selection: LootSelection;
		};
	};
	SELECTION_REMOVE: {
		readonly type: typeof SELECTION_REMOVE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly selection: LootSelection;
		};
	};
	SELECTION_SUCCESS: {
		readonly type: typeof SELECTION_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	SELECTION_FAILURE: {
		readonly type: typeof SELECTION_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	SELECTION_NOTE: {
		readonly type: typeof SELECTION_NOTE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly selection: LootSelection;
		};
	};
	SELECTION_NOTE_SUCCESS: {
		readonly type: typeof SELECTION_NOTE_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	SELECTION_NOTE_FAILURE: {
		readonly type: typeof SELECTION_NOTE_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	COIN: {
		readonly type: typeof COIN;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly coin: LootCoin;
		};
	};
	COIN_REMOVE: {
		readonly type: typeof COIN_REMOVE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly coin: LootCoin;
		};
	};
	COIN_SUCCESS: {
		readonly type: typeof COIN_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	COIN_FAILURE: {
		readonly type: typeof COIN_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	BUMP: {readonly type: typeof BUMP};
	BUMP_SUCCESS: {readonly type: typeof BUMP_SUCCESS};
	BUMP_FAILURE: {readonly type: typeof BUMP_FAILURE};
}
