import React from 'react';
import classnames from 'classnames';
import {FiChevronDown} from 'react-icons/fi';

import type {Classes} from '@constants/wow';

import {connect2} from '@ducks';

import {useBoolean} from '@hooks/useBoolean';

import type {Character} from '@models/character';

import {ActorSelect} from './ActorSelect';

interface TextProps {
	isSubmitting: boolean;

	class?: Classes;

	type: 'character' | 'freeform' | 'placeholder';
	children: React.ReactNode;
}

const Text: React.FC<TextProps> = (props) => {
	const textClass = classnames(
		{
			submitting: props.isSubmitting,
			'wow-style text': props.type === 'character'
		},
		props.class,
		props.type,
		'action-field-text'
	);

	return <div className={textClass}>{props.children}</div>;
};

interface OwnProps {
	onUpdateActor(data: {
		actorText: string | undefined;
		actorCharacterId: CharacterId | undefined;
	}): Promise<void>;

	isEditor: boolean;

	rosterBossId: RosterBossId;

	actorCharacter: Character | undefined;
	actorText: string | null;
}

type StateProps = EmptyObject;

type ActorProps = OwnProps & StateProps;

const ActorComp: React.FC<ActorProps> = (props) => {
	const editing = useBoolean(false);
	const submitting = useBoolean(false);

	async function handleUpdate(data: {
		actorText: string | undefined;
		actorCharacterId: CharacterId | undefined;
	}): Promise<void> {
		submitting.setTrue();
		editing.setFalse();

		await props.onUpdateActor({
			actorCharacterId: data.actorCharacterId,
			actorText: data.actorText
		});

		submitting.setFalse();
	}

	let content;
	if (props.actorCharacter) {
		content = (
			<Text
				isSubmitting={submitting.value}
				type="character"
				class={props.actorCharacter.class}
			>
				{props.actorCharacter.name}
			</Text>
		);
	} else if (props.actorText) {
		content = (
			<Text isSubmitting={submitting.value} type="freeform">
				{props.actorText}
			</Text>
		);
	} else {
		content = (
			<Text isSubmitting={submitting.value} type="placeholder">
				Character
			</Text>
		);
	}

	return (
		<div className="action-field-wrapper">
			<div
				onClick={() => props.isEditor && editing.setTrue()}
				className="action-field actor"
			>
				{content}

				{props.isEditor && <FiChevronDown className="dropdown-icon" />}
			</div>

			{editing.value && (
				<ActorSelect
					onClose={editing.setFalse}
					onSelect={(actorCharacterId) =>
						handleUpdate({actorCharacterId, actorText: undefined})
					}
					onFreeformText={(actorText) =>
						handleUpdate({actorCharacterId: undefined, actorText})
					}
					rosterBossId={props.rosterBossId}
				/>
			)}
		</div>
	);
};

function mapStateToProps(): StateProps {
	return {};
}

export const Actor = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: {};
}>(
	mapStateToProps,
	{}
)(ActorComp);
