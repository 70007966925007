import React from 'react';

import * as Images from '@helpers/images';

import {Markdown} from '../../Utils/Markdown';
import type {IRosterViewBoss} from '../RosterContainer';

interface IProps {
	onEditNotes(): void;

	isAdmin: boolean;

	boss: IRosterViewBoss;
}

export const BossNotes: React.FC<IProps> = (props) => {
	return (
		<div className="boss-tab-notes">
			<div className="left">
				<img className="boss-image" src={Images.getBossImageUrl(props.boss.bossId)} />
			</div>

			<div className="content">
				<div className="name-row">
					<div className="boss-name">{props.boss.name}</div>

					{props.isAdmin && (
						<div onClick={props.onEditNotes} className="outline button">
							Edit notes
						</div>
					)}
				</div>

				<div className="notes">
					{props.boss.notes ? (
						<Markdown content={props.boss.notes} />
					) : (
						<div className="no-notes">There are no notes for this boss</div>
					)}
				</div>
			</div>
		</div>
	);
};
