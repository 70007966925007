import React from 'react';

import {useMount} from '@hooks/useMount';

import type {ActiveUserBilling} from '@models/userBilling';

import type {IProps} from './BillingContainer';
import {NoSub} from './NoSub/NoSub';
import {ExistingSub} from './ExistingSub/ExistingSub';

export const BillingLanding: React.FC<IProps> = (props) => {
	useMount(() => {
		if (props.showUpdateSuccessBanner) props.onUpdateSuccessBanner();
		if (props.showBuffSuccessBanner) props.onBuffSuccessBanner();
		if (props.showErrorBanner) props.onErrorBanner();
	});

	return (
		<div className="new-user-billing-view setup-guild-like-view">
			{!props.billing || !props.billing.status ? (
				<NoSub sponsorGuildIds={props.sponsorGuildIds} />
			) : (
				<ExistingSub billing={props.billing as ActiveUserBilling} />
			)}
		</div>
	);
};
