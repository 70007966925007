import React from 'react';
import classnames from 'classnames';

import type {LootSelection} from '@models/loot-selection';
import type {Character} from '@models/character';
import type {RaidItem} from '@models/raid-data';
import type {LootOption} from '@models/loot-option';

import type {ISelectOption} from '../../Utils/SelectInput';
import {LootSelectionDropdown} from './LootSelectionDropdown';
import {OriginalSelection} from './OriginalSelection';
import {CharacterInfo} from './CharacterInfo';
import {ItemInfo} from './ItemInfo';

interface IProps {
	onItemSelection(id: string, optionId: number | undefined): void;

	wowheadBonus: string | undefined;
	item: RaidItem | undefined;
	character: Character | undefined;
	selection: LootSelection | undefined;
	originalSelectedLootOption: LootOption | undefined;
	selectedLootOptionId: number | undefined;
	lootSelectionOptions: ISelectOption[];

	id: string;
	rclcSelectionText: string;
}

export class Entry extends React.Component<IProps> {
	handleItemSelection = (optionId: number | undefined) => {
		this.props.onItemSelection(this.props.id, optionId);
	};

	render() {
		if (!this.props.item) return null;
		if (!this.props.character) return null;
		if (!this.props.originalSelectedLootOption) return null;

		const rowClassName = classnames(
			{},
			this.props.character.class,
			'entry-row',
			'wow-style bg-before'
		);

		return (
			<div className="entry-row-wrapper card">
				<div className={rowClassName}>
					<div className="left">
						<ItemInfo
							item={this.props.item}
							wowheadBonus={this.props.wowheadBonus}
						/>
					</div>

					<div className="middle">
						<CharacterInfo
							inGameSelectionText={this.props.rclcSelectionText}
							class={this.props.character.class}
							name={this.props.character.name}
							region={this.props.character.region}
							thumbnail={this.props.character.thumbnail}
						/>
					</div>

					<div className="right">
						{this.props.originalSelectedLootOption.id && (
							<OriginalSelection
								optionName={this.props.originalSelectedLootOption.name}
							/>
						)}

						<LootSelectionDropdown
							onSelect={this.handleItemSelection}
							selectLootOptions={this.props.lootSelectionOptions}
							selectedOptionId={this.props.selectedLootOptionId}
						/>
					</div>
				</div>
			</div>
		);
	}
}
