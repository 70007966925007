import React from 'react';

import {GuildSponsorshipDuck, connect} from '@ducks';

import type {ISettingsPageProps} from '../GuildSettingsContainer';
import {AddExternalCharacter} from './Add/AddExternalCharacter';
import {ListExternalCharacters} from './List/ListExternalCharacters';
import {UpgradeView} from './UpgradeView';

type IOwnProps = ISettingsPageProps;

interface IMapProps {
	canUseExternalCharacters: boolean;
}

type IProps = IOwnProps & IMapProps;

const ExternalCharactersComp: React.FC<IProps> = (props) => {
	return (
		<div className="settings-content external-characters-settings">
			<div className="settings-heading">Non-guild Characters</div>

			{!props.canUseExternalCharacters && <UpgradeView />}

			<div className="settings-description">
				<p>
					Characters that aren't in your guild can be added to{' '}
					<span className="brand-name">Readycheck</span>, these characters can be
					from the same realm as your guild or a different realm entirely.
				</p>

				<p>
					Permissions work the same for non-guild characters as they do for normal
					guild characters: the person who owns the in-game characters will have
					access to the guild on <span className="brand-name">Readycheck</span> and
					will be able to manage the loot sheet of their own characters.
				</p>
			</div>

			{props.canUseExternalCharacters && (
				<AddExternalCharacter region={props.guild.region} />
			)}

			<ListExternalCharacters guildId={props.guild.id} />
		</div>
	);
};

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		props.guildId
	);

	return {
		canUseExternalCharacters: fc.canUseUnlimitedExternalCharacters()
	};
}

export const ExternalCharacters = connect<IMapProps, {}, IOwnProps>(
	mapStateToProps,
	{}
)(ExternalCharactersComp);
