import React from 'react';

import Loading from '../Utils/Loading';

interface IIsLoadingProps {
	name?: string;
}

export default function IsLoading(props: IIsLoadingProps) {
	return (
		<div className="page-container">
			<div className="content-card card">
				<div className="heading">
					<i className="lnr lnr-hourglass" />

					<div>Hang tight</div>
				</div>

				<div className="content">
					<div className="description">
						<p>
							We're fetching information about <b>{props.name}</b> and its members.
						</p>
					</div>

					<Loading isSmall={true} />
				</div>
			</div>
		</div>
	);
}
