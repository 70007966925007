import React from 'react';

import {router} from '@helpers/urls';

import {CharacterDuck, UserDuck, LootDuck, connect} from '@ducks';

import type {Guild} from '../../models/guild';
import type {User} from '../../models/user';

import type {InstanceFilterOption} from './Loot';
import LootContainer from './LootContainer';

interface IOwnProps {
	guild: Guild;

	location: {
		query: {
			c?: string;
			i?: InstanceFilterOption;
			d?: string;
		};
	};
}

interface IMapProps {
	isBumping: boolean;
	isAdmin: boolean;

	disabledViewRedirect:
		| {isDisabled: false; redirectUrl: undefined}
		| {isDisabled: true; redirectUrl: string};

	user: User | undefined;
}

interface IDispatchProps {
	onBump: BoundThunk<typeof LootDuck.bumpUpdated>;
	onLootNote: BoundThunk<typeof LootDuck['updateNote']>;
	onSelect: BoundThunk<typeof LootDuck.makeSelection>;
	onCoin: BoundThunk<typeof LootDuck.makeCoin>;
}

type IProps = IMapProps & IOwnProps & IDispatchProps;

function RealLootWrapper(props: IProps) {
	if (!props.guild || !props.user) return null;

	// if the lootsheet is disabled then redirect them
	if (props.disabledViewRedirect.isDisabled) {
		const redirectUrl = props.disabledViewRedirect.redirectUrl;
		setTimeout(() => router.push(redirectUrl), 10);
		return null;
	}

	return (
		<LootContainer
			onBump={props.onBump}
			onLootNote={props.onLootNote}
			onSelect={props.onSelect}
			onCoin={props.onCoin}
			isBumping={props.isBumping}
			isAdmin={props.isAdmin}
			isDemo={false}
			location={props.location}
			guild={props.guild}
			user={props.user}
		/>
	);
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const user = UserDuck.getUser(state.user);

	let isAdmin = false;
	if (user) {
		const characters = CharacterDuck.getAllCharactersForGuild(
			state.characters,
			props.guild.id
		);
		isAdmin = props.guild.isUserAdmin(user, characters);
	}

	const isBumping = LootDuck.getIsBumping(state.loot);

	return {
		disabledViewRedirect: isAdmin
			? {isDisabled: false, redirectUrl: undefined}
			: props.guild.getDisabledViewInfo('lootsheet'),

		isBumping,
		isAdmin,

		user
	};
}

export default connect<IMapProps, IDispatchProps, IOwnProps>(mapStateToProps, {
	onBump: LootDuck.bumpUpdated,
	onLootNote: LootDuck.updateNote,
	onSelect: LootDuck.makeSelection,
	onCoin: LootDuck.makeCoin
})(RealLootWrapper);
