import React from 'react';
import classnames from 'classnames';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

import {setRecorderTag} from '../../helpers/recorder';
import {composeImportCharacterCombo} from '../../helpers/toolbox';

import type * as SetupDuck from '../../ducks/setup';

import type {IRankGroup} from './SetupGuildContainer';
import RankGroup from './RankGroup';

interface ISetupGuildProps {
	onSubmit: typeof SetupDuck.submitData;

	isSubmitting: boolean;
	isLikelyAdmin: boolean;
	ownCharacterCombos: string[];
	rankGroups: IRankGroup[];
	guildName: string;
}

interface ISetupGuildState {
	importCharacterCombos: string[];
}

export default class SetupGuild extends React.Component<
	ISetupGuildProps,
	ISetupGuildState
> {
	rankRefs: {[key: string]: RankGroup} = {};
	state: ISetupGuildState;

	constructor(props: ISetupGuildProps) {
		super(props);

		this.state = {
			importCharacterCombos: []
		};

		setRecorderTag('is_using_setup', 'true');
	}

	handleSubmit = () => {
		const rankNames: string[] = Array.from<string>({length: 10}).fill('');

		Object.keys(this.rankRefs).forEach((rank) => {
			const name = this.rankRefs[rank].getName();
			if (name) rankNames[Number(rank)] = name;
		});

		this.props.onSubmit({
			importCharacterCombos: this.state.importCharacterCombos,
			rankNames
		});
	};

	handleSelection = (data: {name: string; realm: string}) => {
		const combo = composeImportCharacterCombo(data);

		const importCharacterCombos = this.state.importCharacterCombos.includes(combo)
			? this.state.importCharacterCombos.filter((x) => x !== combo)
			: [...this.state.importCharacterCombos, combo];

		this.setState({importCharacterCombos});
	};

	handleMassSelection = (rank: number, isAdding: boolean) => {
		const importCombos = new Set(this.state.importCharacterCombos);

		this.props.rankGroups.some((group) => {
			if (group.rank !== rank) return false;

			group.characters.forEach((character) => {
				const combo = composeImportCharacterCombo(character);
				if (isAdding) importCombos.add(combo);
				else importCombos.delete(combo);
			});

			return true;
		});

		this.setState({importCharacterCombos: [...importCombos]});
	};

	renderButtons() {
		const hasOwnCharacter = this.props.ownCharacterCombos.some((combo) =>
			this.state.importCharacterCombos.includes(combo)
		);

		return (
			<Buttons
				onSubmit={this.handleSubmit}
				hasOwnCharacter={hasOwnCharacter}
				isSubmitting={this.props.isSubmitting}
				count={this.state.importCharacterCombos.length}
			/>
		);
	}

	render() {
		const ranks = this.props.rankGroups.map(({rank, characters}) => {
			return (
				<RankGroup
					key={rank}
					ref={(r: RankGroup) => (this.rankRefs[String(rank)] = r)}
					onMassSelection={this.handleMassSelection}
					onNewCharacterSelection={this.handleSelection}
					onExistingCharacterSelection={() => {}}
					importingCombos={this.state.importCharacterCombos}
					importingIds={[]}
					characters={characters}
					rank={rank}
				/>
			);
		});

		const infoText = this.props.isLikelyAdmin
			? 'Select the characters that make up your raid team. The players that own selected characters will automatically be granted access to your guild on Readycheck.'
			: `Select the characters that make up your raid team. It's ok if you aren't an officer of your guild, by default everyone in the first two ranks will have access and this process can be repeated later.`;

		return (
			<div className="page-container setup-guild setup-guild-like-view">
				<div className="heading">
					<h1 className="big">{this.props.guildName} set up</h1>

					<p>{infoText}</p>

					{this.renderButtons()}
				</div>

				<div className="content">
					<div className="rank-groups">{ranks}</div>
				</div>

				{this.renderButtons()}
			</div>
		);
	}
}

interface IButtonProps {
	onSubmit(): void;

	hasOwnCharacter: boolean;
	isSubmitting: boolean;
	count: number;
}

function Buttons(props: IButtonProps) {
	const word = props.count === 1 ? 'character' : 'characters';
	const count = props.count ? `${props.count} ` : '';

	const buttonClass = classnames(
		{
			disabled: !count || !props.hasOwnCharacter || props.isSubmitting
		},
		'large primary button'
	);

	return (
		<div className="submit-buttons">
			<LaddaButton
				onClick={props.onSubmit}
				className={buttonClass}
				loading={props.isSubmitting}
				data-style={ZOOM_OUT}
			>
				Import {count}
				{word}
			</LaddaButton>

			{!!count && !props.hasOwnCharacter && (
				<div className="need-own-character">
					You need a character that you own to complete the import
				</div>
			)}
		</div>
	);
}
