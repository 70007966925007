import {UserBillingDuck, UserDuck, BannerDuck, connect} from '@ducks';

import {router, userNewBillingUrl} from '@helpers/urls';

import type {UserBilling} from '@models/userBilling';

import {BillingLanding} from './BillingLanding';

export interface IBillingLandingQuery {
	guild?: string;
	'buff-success'?: string;
	'update-success'?: string;
	error?: string;
}

interface IOwnProps {
	location: {
		query: IBillingLandingQuery;
	};
}

interface IMapProps {
	showBuffSuccessBanner: boolean;
	showUpdateSuccessBanner: boolean;
	showErrorBanner: boolean;

	billing: UserBilling | undefined;
	sponsorGuildIds: number[];
}

interface IDispatchProps {
	onUpdateSuccessBanner(): void;
	onBuffSuccessBanner(): void;
	onErrorBanner(): void;
}

export type IProps = IMapProps & IDispatchProps & IOwnProps;

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const user = UserDuck.getActiveUser(state.user);
	const billing = UserBillingDuck.getForUser(state.userBilling, user.id);

	const sponsorGuildId = props.location.query.guild
		? Number.parseInt(props.location.query.guild, 10)
		: undefined;

	return {
		showBuffSuccessBanner: !!props.location.query['buff-success'],
		showUpdateSuccessBanner: !!props.location.query['update-success'],
		showErrorBanner: !!props.location.query.error,

		sponsorGuildIds: sponsorGuildId ? [sponsorGuildId] : [],
		billing
	};
}

function handleBanner(type: 'success' | 'error', message: string) {
	// clear search params
	router.replace(userNewBillingUrl());

	if (type === 'success') return BannerDuck.addSuccessBanner(message);
	return BannerDuck.addErrorBanner(message);
}

export const BillingContainer = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onUpdateSuccessBanner: () =>
			handleBanner('success', 'Successfully updated payment info'),
		onBuffSuccessBanner: () => handleBanner('success', 'Successfully buffed'),
		onErrorBanner: () => handleBanner('error', 'Error performing billing operation')
	}
)(BillingLanding);
