import React from 'react';

import MeowlinLoveImage from '../../images/meowlinheadhearts.png';
import ViewLootImage from '../../images/view-loot.png';
import PlanRaidsImage from '../../images/plan-raids.png';
import AutomaticSyncImage from '../../images/automatic-sync.png';
// import comingSoon from '../../images/coming-soon.png';

export const FeatureTable: React.FC = () => {
	return (
		<div className="billing-feature-table">
			<Feature
				name="Support Readycheck"
				description="Your support for Readycheck is greatly appreciated and helps the service continue to run."
				imageUrl={MeowlinLoveImage}
			/>

			<Feature
				name="Manage Multiple Raids"
				description="Create multiple rosters for the same instance to manage all of your raids - from Alt raids to Split raids."
				imageUrl={PlanRaidsImage}
			/>

			<Feature
				name="Personalized Loot Options"
				description="Tailor the loot options players can select to match how your guild already handles loot decisions."
				imageUrl={ViewLootImage}
			/>

			<Feature
				name="Filter roster characters"
				description="Limit which characters appear on each roster through tagging. A roster for Mains and a roster for Alts? No problem!"
				imageUrl={PlanRaidsImage}
			/>

			<Feature
				isBeta={true}
				name="Cross-realm characters"
				description="Top up your roster with cross-realm characters or characters from outside of your guild."
				imageUrl={AutomaticSyncImage}
			/>

			<Feature
				isBeta={true}
				name="RCLootCouncil addon import"
				description="No need to rely on raiders updating their own loot sheets - you can upload data from the RCLootCouncil addon and let Readycheck make the changes for you."
				imageUrl={ViewLootImage}
			/>
		</div>
	);
};

interface IFeatureProps {
	isBeta?: boolean;
	isComingSoon?: boolean;
	name: string;
	description: string;
	imageUrl: string;
}

function Feature(props: IFeatureProps) {
	return (
		<div className="feature">
			<img src={props.imageUrl} className="imagery" />

			<div className="info">
				<div className="name">
					{props.isBeta && <div className="beta">Early access</div>}

					{props.name}

					{props.isComingSoon && <div className="coming-soon">Coming Soon!</div>}
				</div>

				<div className="description">{props.description}</div>
			</div>
		</div>
	);
}
