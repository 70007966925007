import {UserBillingDuck, UserDuck, connect} from '@ducks';

import type {ActiveUserBilling} from '@models/userBilling';

import {ChangePlan} from './ChangePlan';

interface IMapProps {
	actioningStripePriceId: string | null;
	billing: ActiveUserBilling;
}

interface IDispatchProps {
	onSelect(stripePriceId: string): Promise<void>;
}

export type IProps = IMapProps & IDispatchProps;

function mapStateToProps(state: IRootState): IMapProps {
	const user = UserDuck.getActiveUser(state.user);
	const billing = UserBillingDuck.getForUser(state.userBilling, user.id);

	return {
		actioningStripePriceId: UserBillingDuck.getUpdatingToStripePriceId(
			state.userBilling
		),

		billing: billing as ActiveUserBilling
	};
}

export const ChangePlanContainer = connect<IMapProps, IDispatchProps, {}>(
	mapStateToProps,
	{
		onSelect: UserBillingDuck.updatePlan
	}
)(ChangePlan);
