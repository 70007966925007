import React from 'react';

import {UserBillingDuck, UserDuck, GuildDuck, connect} from '@ducks';

import type {Guild} from '@models/guild';

import {PlanSelection} from '../PlanSelection/PlanSelection';
import {SelectGuildsModal} from './SelectGuildsModal';

interface IOwnProps {
	sponsorGuildIds: number[];
}

interface IMapProps {
	actioningStripePriceId: string | null;
	currentPriceId: string | null;

	guilds: Guild[];
}

interface IDispatchProps {
	onSelect(stripePriceId: string, sponsorGuildIds: number[]): void;
}

type IProps = IOwnProps & IMapProps & IDispatchProps;

const NobSubComp: React.FC<IProps> = (props) => {
	const [selectedPlanId, setSelectedPlanId] = React.useState<string | null>(null);

	function handlePlanSelection(priceId: string): void {
		// if they're only in a single guild, then auto sponsor that one to
		// streamline the flow by removing a step
		if (props.guilds.length === 1) {
			const guild = props.guilds[0];
			props.onSelect(priceId, [guild.id]);
			return;
		}

		// otherwise show the modal for them to select the guilds
		setSelectedPlanId(priceId);
	}

	return (
		<>
			{selectedPlanId && (
				<SelectGuildsModal
					onSubmit={(guildIds) => props.onSelect(selectedPlanId, guildIds)}
					onClose={() => setSelectedPlanId(null)}
					isActioning={!!props.actioningStripePriceId}
					guilds={props.guilds}
					initialSponsorGuilIds={props.sponsorGuildIds}
					stripePriceId={selectedPlanId}
				/>
			)}

			<div className="heading">
				<h1 className="big">Buff your guild</h1>
			</div>

			<PlanSelection
				onSelect={handlePlanSelection}
				actioningStripePriceId={props.actioningStripePriceId}
				currentPriceId={props.currentPriceId}
			/>
		</>
	);
};

function mapStateToProps(state: IRootState): IMapProps {
	const user = UserDuck.getActiveUser(state.user);

	const guildById: Record<string, Guild> = {};
	user.characters.forEach((character) => {
		// ignore characters without guilds
		if (!character.guildName || !character.guildRealm) return;

		const guild = GuildDuck.getGuildByDetails(state.guilds, {
			region: character.region,
			realm: character.guildRealm,
			name: character.guildName
		});

		// don't show guilds that aren't set up
		if (!guild) return;

		// if this guild has already been handled then we can skip it
		if (guildById[guild.id]) return;

		guildById[guild.id] = guild;
	});

	return {
		actioningStripePriceId: UserBillingDuck.getSubscribingToStripePriceId(
			state.userBilling
		),

		guilds: Object.values(guildById),
		currentPriceId: null
	};
}

export const NoSub = connect<IMapProps, IDispatchProps, IOwnProps>(mapStateToProps, {
	onSelect: UserBillingDuck.subscribe
})(NobSubComp);
