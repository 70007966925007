import React from 'react';

import type {GuildDuck} from '@ducks';

import {useModal} from '@hooks/useModal';

import {SwitchField} from '@components/Utils/Switch';
import {ConfirmModal} from '@components/Utils/ConfirmModal';

interface RankRowProps {
	onChange(index: number, isAdmin: boolean): Promise<void>;

	isUsersAdminRank: boolean;
	isAdmin: boolean;
	rankName: string;
	index: number;
}

function RankRow(props: RankRowProps) {
	const confirmRemovalModal = useModal();

	function handleToggle(isToggled: boolean): void {
		if (isToggled || !props.isUsersAdminRank) {
			void props.onChange(props.index, isToggled);
			return;
		}

		confirmRemovalModal.open();
	}

	return (
		<>
			{confirmRemovalModal.isOpen && (
				<ConfirmModal
					onClose={confirmRemovalModal.close}
					onSubmit={() =>
						props.onChange(props.index, false).then(confirmRemovalModal.close)
					}
					isSubmitting={false}
					heading="Admin Removal"
					submitButtonText="Confirm"
					submitColour="red"
				>
					This will remove your admin privledges unless you have a character in one
					of the other admin ranks.
				</ConfirmModal>
			)}

			<div className="group-item">
				<SwitchField
					onChange={handleToggle}
					isControlled={true}
					label={props.rankName}
					note={`Rank ${props.index + 1}`}
					value={props.isAdmin}
					isDisabled={props.index === 0 && props.isAdmin}
				/>
			</div>
		</>
	);
}

interface RankAdminsProps {
	onToggleRank: BoundThunk<typeof GuildDuck['updateAdminRank']>;

	usersCharacterRanks: Set<number>;
	adminRanks: boolean[];
	rankNames: string[];
}

export const RankAdmins: React.FC<RankAdminsProps> = (props) => {
	const items = props.adminRanks.map((isAdmin, i) => (
		<RankRow
			// our rank array is a fixed size so the index is the unique key
			// eslint-disable-next-line react/no-array-index-key
			key={i}
			onChange={props.onToggleRank}
			isUsersAdminRank={props.usersCharacterRanks.has(i)}
			isAdmin={isAdmin}
			rankName={props.rankNames[i]}
			index={i}
		/>
	));

	return (
		<div className="settings-field-group">
			<div className="group-heading">Which ranks are admins</div>

			<div className="group-description">
				When a rank is marked as an admin rank, anyone with a character of that rank
				in your guild will be classed as an admin on{' '}
				<span className="brand-name">Readycheck</span>.
			</div>

			{items}
		</div>
	);
};
