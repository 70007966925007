import React from 'react';
import classnames from 'classnames';

import type {RaidItem} from '@models/raid-data';
import type {DroptimizerItem} from '@models/droptimizerReport';

import {generateWowheadLinkData} from '../../../../../../Loot/Item/Item';

interface LootItemRowProps {
	wowheadBonus: string;
	optionName: string;
	note: string | null;
	item: RaidItem;
	droptimizerItemData: DroptimizerItem | undefined;
}

export const LootItemRow: React.FC<LootItemRowProps> = (props) => {
	const linkData = generateWowheadLinkData(props.item.id, props.wowheadBonus);

	return (
		<div className="loot-item-row">
			<div className="left-col">
				<div className="item-info">
					<a href={linkData.url} rel="noopener noreferrer" target="_blank">
						<img src={`/static/item-icons/${props.item.id}`} />

						<div className="name">{props.item.name}</div>
					</a>
				</div>

				<div className="selection-column">
					<div className="selection-name">{props.optionName}</div>

					{!!props.droptimizerItemData && (
						<div
							className={classnames(
								{
									gain: props.droptimizerItemData.absoluteDpsGain > 0
								},
								'dps-change'
							)}
						>
							<div>
								{props.droptimizerItemData.displayAbsoluteDpsGain}{' '}
								<span className="divider">/</span>{' '}
								{props.droptimizerItemData.displayRelativeDpsGain}
							</div>
						</div>
					)}
				</div>

				{props.note && <div className="note">{props.note}</div>}
			</div>
		</div>
	);
};
