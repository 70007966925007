import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {MdDragIndicator} from 'react-icons/md';
import classnames from 'classnames';

import type {Role} from '@constants/wow';
import {BossAbility, bossAbilityById} from '@shared/constants/bossAbilities';

import {generateWowheadSpellLinkData} from '@helpers/wowhead';

import {connect, CooldownEventDuck, CooldownActionDuck} from '@ducks';

import {useBoolean} from '@hooks/useBoolean';

import type {CooldownEvent} from '@models/cooldownEvent';
import type {CooldownAction} from '@models/cooldownAction';

import {AbilityIcon} from '@components/Utils/AbilityIcon';
import {InlineEditable} from '@components/Utils/InlineEditable';
import {ActionsMenu, ConfirmationMenuItem} from '@components/Utils/Menu';
import {Actions} from './Actions/Actions';
import {EventSelect} from './EventSelect';

/** Format the "seconds from start" data into a display string */
export function formatTime(
	secondsFromStart: number | null,
	withEmptyStringDefault = false
): string {
	if (!secondsFromStart) return withEmptyStringDefault ? '' : '-';

	const minutes = Math.floor(secondsFromStart / 60)
		.toString()
		.padStart(2, '0');
	const seconds = Math.floor(secondsFromStart % 60)
		.toString()
		.padStart(2, '0');

	return `${minutes}:${seconds}`;
}

/** Convert a time input string to seconds */
function convertTimeString(displayString: string): number | null {
	// handle wiping the value
	if (!displayString) return null;

	// handle setting a new value in MM:SS format
	if (displayString.includes(':')) {
		const [minutes, seconds] = displayString.split(':');
		return Number.parseInt(minutes, 10) * 60 + Number.parseInt(seconds, 10);
	}

	// handle where value is only seconds
	return Number.parseInt(displayString, 10);
}

/** Validate whether a time input string is in the right format */
function validateTime(value: string): boolean {
	// empty string, seconds, or MM:SS
	return !value || /\d{1,4}/.test(value) || /\d?\d:\d\d/.test(value);
}

type EventNameProps = {
	isSubmitting: boolean;

	children: React.ReactNode;
} & (
	| {
			type: 'wow-ability';
			isNpc: boolean;
			wowAbilityId: WowAbilityId;
			iconName: string;
	  }
	| {type: 'freeform'}
);

const EventName: React.FC<EventNameProps> = (props) => {
	const textClass = classnames(
		{submitting: props.isSubmitting},
		props.type,
		'event-name-text'
	);

	const url =
		props.type === 'wow-ability'
			? generateWowheadSpellLinkData(props.wowAbilityId, props.isNpc).url
			: '';

	return (
		<a onClick={(e) => e.preventDefault()} href={url} className="event-name-content">
			{props.type === 'wow-ability' && (
				<AbilityIcon
					className="event-ability-icon"
					type="wow-ability"
					iconName={props.iconName}
				/>
			)}

			<div className={textClass}>{props.children}</div>
		</a>
	);
};

interface OwnProps {
	onUpdateEventName: BoundThunk<typeof CooldownEventDuck['updateNameCooldownEvent']>;
	onUpdateEventTime: BoundThunk<typeof CooldownEventDuck['updateTimeCooldownEvent']>;
	onDeleteEvent: BoundThunk<typeof CooldownEventDuck['deleteCooldownEvent']>;
	onCreateAction: BoundThunk<typeof CooldownActionDuck['createCooldownAction']>;
	onUpdateActionActor: BoundThunk<
		typeof CooldownActionDuck['updateActorCooldownAction']
	>;
	onUpdateActionAbility: BoundThunk<
		typeof CooldownActionDuck['updateAbilityCooldownAction']
	>;
	onDeleteAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;

	isEditor: boolean;

	rosterBossId: RosterBossId;
	wowBossId: WowBossId;
	rosteredRoleByCharacterId: Record<CharacterId, Role>;
	event: CooldownEvent;
	actions: CooldownAction[];
}

interface MapProps {
	isUpdatingCooldownEventName: boolean;
	isUpdatingCooldownEventTime: boolean;

	wowAbility: BossAbility | undefined;
}

type Props = OwnProps & MapProps;

const EventComp: React.FC<Props> = (props) => {
	const editing = useBoolean(false);

	const sortable = useSortable({
		id: `event-${props.event.id}`,
		disabled: !props.isEditor
	});

	async function handleUpdate(data: {
		wowAbilityId: WowAbilityId | undefined;
		name: string | undefined;
	}): Promise<void> {
		editing.setFalse();

		await props.onUpdateEventName({
			cooldownEventId: props.event.id,
			wowAbilityId: data.wowAbilityId,
			name: data.name
		});
	}

	let eventNameContent;
	if (props.event.wowAbilityId && props.wowAbility) {
		eventNameContent = (
			<EventName
				isSubmitting={props.isUpdatingCooldownEventTime}
				type="wow-ability"
				isNpc={props.wowAbility.isNpc || false}
				wowAbilityId={props.wowAbility.wowAbilityId}
				iconName={props.wowAbility.iconName}
			>
				{props.wowAbility.name}
			</EventName>
		);
	} else {
		eventNameContent = (
			<EventName isSubmitting={props.isUpdatingCooldownEventTime} type="freeform">
				{props.event.name}
			</EventName>
		);
	}

	return (
		<div
			ref={sortable.setNodeRef}
			style={{
				transform: CSS.Translate.toString(sortable.transform),
				transition: sortable.isSorting ? sortable.transition : undefined
			}}
			className={classnames(
				{dragged: sortable.isDragging, editor: props.isEditor},
				'event-row'
			)}
		>
			<div className="col col-event">
				{props.isEditor && (
					<div
						{...sortable.attributes}
						{...sortable.listeners}
						className={classnames(
							{'with-event-icon': !!props.wowAbility},
							'event-drag-handle'
						)}
					>
						<MdDragIndicator />
					</div>
				)}

				<div className="event-name">
					<div
						onClick={() => props.isEditor && editing.setTrue()}
						className="event-name-inner"
					>
						{eventNameContent}

						{editing.value && (
							<EventSelect
								onClose={editing.setFalse}
								onSelect={(wowAbilityId) =>
									handleUpdate({wowAbilityId, name: undefined})
								}
								onFreeformText={(abilityText) =>
									handleUpdate({wowAbilityId: undefined, name: abilityText})
								}
								wowBossId={props.wowBossId}
							/>
						)}
					</div>
				</div>
			</div>

			<InlineEditable
				className="col col-time"
				onValidate={validateTime}
				onSubmit={(time) =>
					props.onUpdateEventTime({
						cooldownEventId: props.event.id,
						time: convertTimeString(time)
					})
				}
				isReadonly={!props.isEditor}
				isBackgroundSubmit={true}
				isSubmitting={props.isUpdatingCooldownEventTime}
				value={formatTime(props.event.time, true)}
				display={formatTime(props.event.time, false)}
				placeholder="MM:SS"
				submitText="Save"
			/>

			<Actions
				onCreateAction={() => props.onCreateAction({cooldownEventId: props.event.id})}
				onUpdateActor={props.onUpdateActionActor}
				onUpdateAbility={props.onUpdateActionAbility}
				onDeleteAction={props.onDeleteAction}
				isEditor={props.isEditor}
				rosterBossId={props.rosterBossId}
				rosteredRoleByCharacterId={props.rosteredRoleByCharacterId}
				actions={props.actions}
			/>

			<ActionsMenu className="col col-row-menu">
				{props.isEditor && (
					<ConfirmationMenuItem
						onClick={() => props.onDeleteEvent({cooldownEventId: props.event.id})}
						confirmText="Confirm delete"
						type="alert"
					>
						Delete event
					</ConfirmationMenuItem>
				)}
			</ActionsMenu>
		</div>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	return {
		isUpdatingCooldownEventName: CooldownEventDuck.getIsUpdatingNameForCooldownEventId(
			state.cooldownEvents,
			props.event.id
		),

		isUpdatingCooldownEventTime: CooldownEventDuck.getIsUpdatingTimeForCooldownEventId(
			state.cooldownEvents,
			props.event.id
		),

		wowAbility:
			(props.event.wowAbilityId && bossAbilityById.get(props.event.wowAbilityId)) ||
			undefined
	};
}

export const Event = connect<MapProps, {}, OwnProps>(mapStateToProps, {})(EventComp);
