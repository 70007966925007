import type {Difficulties} from '@constants/wow';

import {LootDuck, RaidDataDuck, DroptimizerReportDuck} from '@ducks';

import type {RaidItem} from '@models/raid-data';
import type {Character} from '@models/character';
import type {DroptimizerItem} from '@models/droptimizerReport';

export interface DesiredItem {
	note: string | null;
	lootOptionName: string;
	raidItem: RaidItem;
	droptimizerItemData: DroptimizerItem | undefined;
}

export type DesiredItemsByCharacterId = Map<CharacterId, DesiredItem[]>;

export function getDesiredItemsByCharacterId(
	lootState: IRootState['loot'],
	raidDataState: IRootState['raidData'],
	droptimizerState: IRootState['droptimizerReports'],
	characters: Character[],
	difficulty: Difficulties,
	wowBossId: WowBossId
): DesiredItemsByCharacterId {
	const desiredItemsByCharacterId: DesiredItemsByCharacterId = new Map<
		CharacterId,
		DesiredItem[]
	>();

	characters.forEach((char) => {
		const selections = LootDuck.getSelectionsForCharacter(lootState, char.id);

		const desiredItems = selections.reduce<DesiredItem[]>((carry, selection) => {
			// only include selections from this difficulty
			if (selection.difficulty !== difficulty) return carry;

			const item = RaidDataDuck.getItemForId(raidDataState, selection.wowItemId);
			if (!item) return carry;
			if (item.sourceId !== wowBossId && !item.sourceIds?.includes(wowBossId)) {
				return carry;
			}

			const option = LootDuck.getOption(lootState, selection.optionId);
			if (!option) return carry;

			// don't show selections from options that are set to be hidden on the roster
			if (!option.isVisibleInRoster) return carry;

			const droptimizerItem = DroptimizerReportDuck.getItem(droptimizerState, {
				difficulty,
				characterId: char.id,
				wowItemId: selection.wowItemId
			});

			carry.push({
				note: selection.note,
				lootOptionName: option.name,
				raidItem: item,
				droptimizerItemData: droptimizerItem
			});

			return carry;
		}, []);

		desiredItemsByCharacterId.set(char.id, desiredItems);
	});

	return desiredItemsByCharacterId;
}
