import React from 'react';

import {ConfirmModal} from '../Utils/ConfirmModal';
import {BrandName} from '../Utils/BrandName';

interface IProps {
	onClose(): void;
	onSubmit(): void;

	isSubmitting: boolean;
}

export const CancelModal: React.FC<IProps> = (props) => {
	return (
		<ConfirmModal
			onSubmit={props.onSubmit}
			onClose={props.onClose}
			isSubmitting={props.isSubmitting}
			heading="Un-buff your guild"
			submitButtonText="Cancel Subscription"
			submitColour="red"
		>
			<span>Are you sure you want to cancel your </span>
			<BrandName />
			<span> subscription?</span>
		</ConfirmModal>
	);
};
