import {RaidItemSlots} from '@constants/wow';

import type {ItemGroup} from './getItemGroups';

enum SlotGroupType {
	TRINKET = 'trinket',
	WEAPON = 'weapon',
	HELM = 'helm',
	NECK = 'neck',
	SHOULDER = 'shoulder',
	CLOAK = 'cloak',
	CHEST = 'chest',
	BRACERS = 'bracers',
	GLOVES = 'gloves',
	BELT = 'belt',
	PANTS = 'pants',
	BOOTS = 'boots',
	RING = 'ring',
	MISC = 'misc',
	CONDUIT = 'conduit'
}

const DISPLAY_NAME_BY_SLOT_GROUP_TYPE: Record<SlotGroupType, string> = {
	[SlotGroupType.MISC]: 'Miscellaneous items',
	[SlotGroupType.CONDUIT]: 'Conduits',
	[SlotGroupType.HELM]: 'Helms',
	[SlotGroupType.NECK]: 'Necks',
	[SlotGroupType.SHOULDER]: 'Shoulders',
	[SlotGroupType.CLOAK]: 'Cloaks',
	[SlotGroupType.CHEST]: 'Chests',
	[SlotGroupType.BRACERS]: 'Wrists',
	[SlotGroupType.GLOVES]: 'Hands',
	[SlotGroupType.BELT]: 'Belts',
	[SlotGroupType.PANTS]: 'Legs',
	[SlotGroupType.BOOTS]: 'Feet',
	[SlotGroupType.RING]: 'Rings',
	[SlotGroupType.TRINKET]: 'Trinkets',
	[SlotGroupType.WEAPON]: 'Weapons & Off-hands'
};

const VALID_SLOTS_BY_SLOT_GROUP_TYPE: Record<SlotGroupType, RaidItemSlots[]> = {
	[SlotGroupType.MISC]: [RaidItemSlots.MISC],
	[SlotGroupType.CONDUIT]: [RaidItemSlots.CONDUIT],
	[SlotGroupType.HELM]: [RaidItemSlots.HEAD],
	[SlotGroupType.NECK]: [RaidItemSlots.NECK],
	[SlotGroupType.SHOULDER]: [RaidItemSlots.SHOULDER],
	[SlotGroupType.CLOAK]: [RaidItemSlots.BACK],
	[SlotGroupType.CHEST]: [RaidItemSlots.CHEST],
	[SlotGroupType.BRACERS]: [RaidItemSlots.WRIST],
	[SlotGroupType.GLOVES]: [RaidItemSlots.HANDS],
	[SlotGroupType.BELT]: [RaidItemSlots.WAIST],
	[SlotGroupType.PANTS]: [RaidItemSlots.LEGS],
	[SlotGroupType.BOOTS]: [RaidItemSlots.FEET],
	[SlotGroupType.RING]: [RaidItemSlots.FINGER],
	[SlotGroupType.TRINKET]: [RaidItemSlots.TRINKET],
	[SlotGroupType.WEAPON]: [
		RaidItemSlots.WEAPON,
		RaidItemSlots.OFF_HAND,
		RaidItemSlots.WEAPON_TOKEN
	]
};

export const SLOT_GROUP_TYPE_BY_SLOT = Object.entries(
	VALID_SLOTS_BY_SLOT_GROUP_TYPE
).reduce<Record<RaidItemSlots, SlotGroupType>>(
	(slotGroupTypeBySlot, [slotGroupType, slots]: [SlotGroupType, RaidItemSlots[]]) => {
		slots.forEach((slot) => (slotGroupTypeBySlot[slot] = slotGroupType));
		return slotGroupTypeBySlot;
	},

	// Doesn't type correctly because all fields are required in this initial object
	// but they all do get included as part of the `reduce` operation
	// eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
	{} as any
);

export function getSlotItemGroups(): Map<SlotGroupType, ItemGroup> {
	const entries = Object.values(SlotGroupType).map(
		(groupType): [SlotGroupType, ItemGroup] => {
			return [
				groupType,
				{
					slots: VALID_SLOTS_BY_SLOT_GROUP_TYPE[groupType],

					itemGroupId: groupType,
					itemGroupName: DISPLAY_NAME_BY_SLOT_GROUP_TYPE[groupType],
					itemGroupImageUrl: undefined,

					wowItemIds: []
				}
			];
		}
	);

	return new Map<SlotGroupType, ItemGroup>(entries);
}
