import React from 'react';
import {connect} from 'react-redux';

import type {Regions} from '@constants/wow';

import {CharacterDuck} from '@ducks';

import {AddExternalCharacterModal} from './AddExternalCharacterModal';
import {Button} from '../../../Utils/Button';

interface IOwnProps {
	region: Regions;
}

interface IMapProps {
	isSubmitting: boolean;
}

interface IDispatchProps {
	onAddExternalCharacter: typeof CharacterDuck.addExternal;
}

type IProps = IOwnProps & IMapProps & IDispatchProps;

const AddExternalCharacterComp: React.FC<IProps> = (props) => {
	const [isShowingModal, setIsShowingModal] = React.useState(false);

	return (
		<>
			{isShowingModal && (
				<AddExternalCharacterModal
					onSubmit={(data) =>
						props.onAddExternalCharacter(data, () => setIsShowingModal(false))
					}
					onClose={() => setIsShowingModal(false)}
					isSubmitting={props.isSubmitting}
					region={props.region}
				/>
			)}

			<div className="settings-field-group add-external-character">
				<Button
					onClick={() => setIsShowingModal(true)}
					className="medium faded primary"
				>
					Add non-guild character
				</Button>
			</div>
		</>
	);
};

function mapStateToProps(state: IRootState): IMapProps {
	return {
		isSubmitting: CharacterDuck.getIsAddingExternal(state.characters)
	};
}

export const AddExternalCharacter = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onAddExternalCharacter: CharacterDuck.addExternal
	}
)(AddExternalCharacterComp);
