import React from 'react';
import Select, {
	components,
	OptionProps,
	InputProps,
	StylesConfig,
	OnChangeValue
} from 'react-select';

import {sortCharactersByName} from '@helpers/toolbox';

import {connect2, CharacterDuck} from '@ducks';

import type {Character} from '@models/character';

import {Pane} from '@components/Utils/Pane';
import type {Size} from '@components/Utils/Input';
import {Image} from '@components/Utils/Image';

const selectSize: Size = 'regular';

interface SelectOption {
	character: Character;

	// react select defaults
	label: string;
	value: number;
}

const selectStyleOverrides: StylesConfig<SelectOption, false> = {
	control: (base) => ({...base, minWidth: 240, margin: 8}),
	menu: (base) => ({
		...base,
		position: 'relative',
		border: 'none !important',
		background: 'transparent !important',
		boxShadow: 'none !important'
	})
};

const CustomSelectInput: React.FC<InputProps<SelectOption, false>> = (props) => {
	return (
		<components.Input
			{...props}
			className={`${props.className || ''} input ${selectSize}`}
		/>
	);
};

const CustomSelectOption: React.FC<OptionProps<SelectOption, false>> = (props) => {
	const {character} = props.data;

	return (
		<components.Option {...props}>
			<Image src={character.getAvatarUrl()} className="option-image" />

			<div className={`option-name wow-style text ${character.class}`}>
				{character.name}
			</div>
		</components.Option>
	);
};

interface OwnProps {
	onSelect(characterId: CharacterId): void;
	onClose(): void;

	adminUserIds: UserId[];
	guildId: GuildId;
}

interface StateProps {
	options: SelectOption[];
}

type UserAdminSelectProps = OwnProps & StateProps;

const UserAdminSelectComp: React.FC<UserAdminSelectProps> = (props) => {
	function handleChange(value: OnChangeValue<SelectOption, false>): void {
		if (!value) return;
		props.onSelect(value.character.id);
		props.onClose();
	}

	return (
		<Pane onClose={props.onClose} className="user-admin-select" withBorder={true}>
			<Select<SelectOption>
				onChange={handleChange}
				className={`react-select ${selectSize}`}
				classNamePrefix="react-select-prefix"
				components={{
					DropdownIndicator: null,
					IndicatorSeparator: null,
					Input: CustomSelectInput,
					Option: CustomSelectOption
				}}
				autoFocus={true}
				backspaceRemovesValue={false}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				menuIsOpen={true}
				options={props.options}
				placeholder="Select character"
				noOptionsMessage={() => 'No matching character'}
				styles={selectStyleOverrides}
			/>
		</Pane>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const characters = CharacterDuck.getCharactersForGuild(
		state.characters,
		props.guildId,
		{
			withMissing: false,
			withUnimported: false,
			withUnrostered: false,
			withoutExternal: false
		}
	)
		.filter((character) => {
			// hide characters who don't have an owner
			if (!character.userId) return false;

			// hide characters who are already an admin
			if (props.adminUserIds.includes(character.userId)) return false;

			return true;
		})
		.sort(sortCharactersByName);

	const options = characters.map((character): SelectOption => {
		return {
			character,

			label: character.name,
			value: character.id
		};
	});

	return {
		options
	};
}

export const UserAdminSelect = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: {};
}>(
	mapStateToProps,
	{}
)(UserAdminSelectComp);
