const prefix = 'readycheck.';
let enabled = true;

export enum LocalStorageKeys {
	RAID_DATA_INSTANCES = 'raidData.instances',
	RAID_DATA_INSTANCES_HASH = 'raidData.instancesHash',
	RAID_DATA_ITEMS = 'raidData.items',
	RAID_DATA_ITEMS_HASH = 'raidData.itemsHash',

	DEMO_RAID_DATA_INSTANCES = 'demo.raidData.instances',
	DEMO_RAID_DATA_INSTANCES_HASH = 'demo.raidData.instancesHash',
	DEMO_RAID_DATA_ITEMS = 'demo.raidData.items',
	DEMO_RAID_DATA_ITEMS_HASH = 'demo.raidData.itemsHash'
}

// need to check if we can even us LS, safari
// private browsing disables it completely
try {
	localStorage.setItem('test', 'test');
	localStorage.removeItem('test');
} catch {
	enabled = false;
}

export function setItem(key: string, value: string) {
	if (!enabled) return;

	localStorage.setItem(`${prefix}${key}`, value);
}

export function getItem(key: string) {
	if (!enabled) return undefined;

	return localStorage.getItem(`${prefix}${key}`);
}

export function removeItem(key: string) {
	if (!enabled) return;

	localStorage.removeItem(`${prefix}${key}`);
}

export function getKeys() {
	if (!enabled) return [];

	return Object.keys(localStorage).filter((key) => key.startsWith(prefix));
}
