import React from 'react';

import {transformInputString} from './transformInputString';
import type {IImportedEntry} from './RclcView';

import {Button, ButtonGroup} from '../Utils/Button';
import {Input} from '../Utils/Input';

interface IProps {
	onImport(entries: IImportedEntry[]): void;
	onError(message: string): void;
}

export class Importer extends React.PureComponent<IProps> {
	fields: {
		data: React.RefObject<Input>;
	} = {
		data: React.createRef()
	};

	handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const value = this.fields.data.current?.getValue();
		if (!value) return;

		try {
			const entries = transformInputString(value);
			this.props.onImport(entries);
		} catch (e) {
			this.props.onError(e.message);
		}
	};

	render() {
		return (
			<div className="importer-container">
				<div className="intro-section">
					<h1>Import from RCLootCouncil</h1>

					<p>
						Streamline the process of keeping your guild's loot sheet up to date by
						importing loot changes directly from RCLC.
					</p>
				</div>

				<form onSubmit={this.handleSubmit}>
					<Input
						ref={this.fields.data}
						className="import-field"
						autoFocus={true}
						isTextarea={true}
						label="Paste the JSON export from RCLC below"
					/>

					<ButtonGroup alignment="center">
						<Button className="large primary" type="submit">
							Import loot
						</Button>
					</ButtonGroup>
				</form>
			</div>
		);
	}
}
