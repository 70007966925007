import React from 'react';

interface IProps {
	isAssigned: boolean;
}

export const AssignmentIcon: React.FC<IProps> = (props) => {
	let icon;
	if (props.isAssigned) icon = <i className="lnr lnr-checkmark-circle" />;

	return <div className="assigned-icon-wrapper">{icon}</div>;
};
