import React from 'react';

import type {DroptimizerItem} from '@models/droptimizerReport';

import type {ISelectOption} from '../../Utils/SelectInput';
import type {IItemWant} from '../LootContainer';
import {EditSelection} from './EditSelection';
import {ViewSelection} from './ViewSelection';

interface IProps {
	onSelection(optionId: number | undefined): Promise<void> | undefined;
	onUpdateNote(note: string | null): Promise<void>;

	isAllowedToEdit: boolean;
	/** Whether this character has any droptimizer data */
	hasDroptimizerData: boolean;
	droptimizerItemData: DroptimizerItem | undefined;
	want: IItemWant | undefined;
	lootOptions: ISelectOption[];
}

export const Selection: React.FC<IProps> = (props) => {
	if (props.isAllowedToEdit) {
		return (
			<EditSelection
				onUpdateNote={props.onUpdateNote}
				onSelection={props.onSelection}
				hasDroptimizerData={props.hasDroptimizerData}
				droptimizerItemData={props.droptimizerItemData}
				lootOptions={props.lootOptions}
				want={props.want}
			/>
		);
	}

	return <ViewSelection want={props.want} />;
};
