import * as wow from '../constants/wow';

import roleMelee from '../images/role-melee.png';
import roleRanged from '../images/role-ranged.png';
import roleHealer from '../images/role-healer.png';
import roleTank from '../images/role-tank.png';

import roleMeleeNew from '../images/role-melee-new.png';
import roleRangedNew from '../images/role-ranged-new.png';
import roleHealerNew from '../images/role-healer-new.png';
import roleTankNew from '../images/role-tank-new.png';

const roleImageUrl = {
	[wow.ROLES.MELEE]: roleMelee,
	[wow.ROLES.RANGED]: roleRanged,
	[wow.ROLES.HEALER]: roleHealer,
	[wow.ROLES.TANK]: roleTank
};

export function getRoleImageUrl(role: wow.ROLES) {
	return roleImageUrl[role];
}

const roleImageNewUrl = {
	[wow.ROLES.MELEE]: roleMeleeNew,
	[wow.ROLES.RANGED]: roleRangedNew,
	[wow.ROLES.HEALER]: roleHealerNew,
	[wow.ROLES.TANK]: roleTankNew
};

export function getRoleImageNewUrl(role: wow.ROLES) {
	return roleImageNewUrl[role];
}

const rosterPaths = require.context('../images/roster/', false, /.png$/);
const bossImagePaths: {[key: string]: string} = {};
rosterPaths.keys().forEach((file) => {
	const match = /\.\/(.+)\.png/.exec(file);
	if (!match) return;
	bossImagePaths[match[1]] = rosterPaths(file);
});

export function getBossImageUrl(id: string) {
	return bossImagePaths[id] || bossImagePaths.missing;
}

const bgRequire = require.context('../images/bg/', false, /.svg/);
const bgPaths: string[] = [];
bgRequire.keys().forEach((key) => bgPaths.push(bgRequire(key)));

function random(lower: number, upper: number) {
	return lower + Math.floor(Math.random() * (upper - lower + 1));
}

function randomPattern() {
	const index = random(0, bgPaths.length - 1);
	return bgPaths[index] || bgPaths[0];
}

export function getBgImageUrl() {
	return randomPattern();
}

const thumbPaths = require.context('../images/thumbs/', false, /.jpg/);
const thumbImagePaths: {[key: string]: string} = {};
thumbPaths.keys().forEach((file) => {
	const match = /\.\/(.+)\.jpg/.exec(file);
	if (!match) return;
	thumbImagePaths[match[1]] = thumbPaths(file);
});

interface ICharDetails {
	faction: string;
	gender: string;
	race: string;
}

export function getCharacterThumbUrl(data: ICharDetails) {
	const key = `${data.faction}-${data.race}-${data.gender}`
		.toLowerCase()
		.replace(/ /, '-');
	return thumbImagePaths[key];
}

export function getSpellIconUrl(iconName: string): string {
	return `https://wow.zamimg.com/images/wow/icons/large/${iconName}.jpg`;
}
