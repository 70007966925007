import React from 'react';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import classnames from 'classnames';

import Modal from '../../Utils/Modal';

interface IProps {
	onClose(): void;
	onSubmit(): void;

	isAdminUnroster: boolean;
	isSubmitting: boolean;

	name: string;
}

export default function RevokeCharacter(props: IProps) {
	const cancelClass = classnames(
		{
			disabled: props.isSubmitting
		},
		'grey button'
	);

	const deleteClass = classnames(
		{
			disabled: props.isSubmitting
		},
		'red button'
	);

	let title;
	let body;
	let text;
	if (props.isAdminUnroster) {
		title = 'Remove from Raid Team';
		text = 'Remove';
		body = (
			<p>
				<span>Are you sure you want to remove </span>
				<strong>{props.name}</strong>
				<span> from the raid team? They will still retain access to </span>
				<span className="brand-name">Readycheck</span>
				<span> but will no longer appear on your guild's rosters.</span>
			</p>
		);
	} else {
		title = 'Revoke Access';
		text = 'Revoke';
		body = (
			<p>
				<span>Are you sure you want to remove </span>
				<strong>{props.name}</strong>
				<span> from </span>
				<span className="brand-name">Readycheck</span>
				<span>
					? This will revoke the owner's access unless they have additional
					characters.
				</span>
			</p>
		);
	}

	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content">
				<div className="heading">{title}</div>

				<div className="description">
					{body}

					<span>
						This will remove the character from all bosses they're currently
						assigned to.
					</span>
				</div>

				<div className="actions">
					<div onClick={props.onClose} className={cancelClass}>
						Cancel
					</div>

					<LaddaButton
						onClick={props.onSubmit}
						className={deleteClass}
						loading={props.isSubmitting}
						data-style={ZOOM_OUT}
					>
						{text}
					</LaddaButton>
				</div>
			</div>
		</Modal>
	);
}

export {RevokeCharacter};
