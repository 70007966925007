import {getBossImageUrl} from '@helpers/images';

import {connect, RosterBossDuck} from '@ducks';

import type {RaidInstance} from '@models/raid-data';

import {BossSelectorComp} from './BossSelector';

interface BossTile {
	rosterBossId: RosterBossId;
	bossName: string;
	bossImageUrl: string;
	bossUrl: string;
}

interface OwnProps {
	generateBossUrl(rosterBossId: RosterBossId): string;

	selectedRosterBossId: RosterBossId | null;
	rosterId: RosterId;
	raidInstance: RaidInstance;
}

interface MapProps {
	bossTiles: BossTile[];
}

export type Props = OwnProps & MapProps;

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const rosterBosses = RosterBossDuck.getBossesForRoster(
		state.rosterBosses,
		props.rosterId
	);

	const bossTiles = rosterBosses.map((boss): BossTile => {
		const raidBoss = props.raidInstance.bosses.find((x) => x.id === boss.bossId);

		return {
			bossUrl: props.generateBossUrl(boss.id),
			bossImageUrl: getBossImageUrl(boss.bossId),
			bossName: raidBoss ? raidBoss.name : `Boss ${boss.id}`,
			rosterBossId: boss.id
		};
	});

	return {
		bossTiles
	};
}

export const BossSelector = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(BossSelectorComp);
