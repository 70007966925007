import React from 'react';
import classnames from 'classnames';

import {groupByProperty} from '@helpers/toolbox';

import {connect2, GuildDuck, CharacterDuck} from '@ducks';

import {useModal} from '@hooks/useModal';
import {useBoolean} from '@hooks/useBoolean';

import type {Character} from '@models/character';

import {BrandName} from '@components/Utils/BrandName';
import {Button, ButtonGroup} from '@components/Utils/Button';
import {Image} from '@components/Utils/Image';
import {GroupHeading} from '../Utils/GroupHeading';
import {UserAdminSelect} from './UserAdminSelect';

interface CharacterRowProps {
	onRevoke(): void;

	main: Character;
	alts: Character[];
}

const CharacterRow: React.FC<CharacterRowProps> = (props) => {
	const revoking = useBoolean(false);

	const characterClass = classnames(
		props.main.class,
		'settings-character-row wow-style bg-before'
	);

	const altNames = props.alts
		.slice(0, 2)
		.map((character) => character.name)
		.join(', ');

	return (
		<div className={characterClass}>
			<div className="left">
				<Image src={props.main.getAvatarUrl()} className="thumbnail" />

				<div className="details">
					<div className="name">{props.main.name}</div>

					<div className="secondary">{altNames}</div>
				</div>
			</div>

			{revoking.value ? (
				<>
					<ButtonGroup withNoTopMarging={true}>
						<Button onClick={revoking.setFalse} className="small grey">
							Cancel
						</Button>

						<Button onClick={props.onRevoke} className="small red">
							Confirm
						</Button>
					</ButtonGroup>
				</>
			) : (
				<Button onClick={revoking.setTrue} className="small red outline">
					Revoke admin
				</Button>
			)}
		</div>
	);
};

interface AdminCharacterGroup {
	adminUserId: UserId;

	main: Character;
	alts: Character[];
}

interface OwnProps {
	adminUserIds: UserId[];
	guildId: GuildId;
}

interface StateProps {
	adminCharacterGroups: AdminCharacterGroup[];
}

interface DispatchProps {
	onAddUserAdmin: BoundThunk<typeof GuildDuck['addUserAdmin']>;
	onRemoveUserAdmin: BoundThunk<typeof GuildDuck['removeUserAdmin']>;
}

type UserAdminsProps = OwnProps & StateProps & DispatchProps;

const UserAdminsComp: React.FC<UserAdminsProps> = (props) => {
	const addAdminMenu = useModal(false);

	const userAdminItems = props.adminCharacterGroups.map((group) => {
		return (
			<CharacterRow
				key={group.main.id}
				onRevoke={() =>
					props.onRemoveUserAdmin({
						adminUserId: group.adminUserId
					})
				}
				main={group.main}
				alts={group.alts}
			/>
		);
	});

	return (
		<>
			<div className="settings-field-group user-admins">
				<GroupHeading
					heading="Individual admins"
					description={
						<span>
							<p>
								People who own an "Individual admin" character will be classed as
								an admin on <BrandName />.
							</p>

							<p>
								A player must have signed into <BrandName /> recently for their
								characters to be eligible to become admins.
							</p>
						</span>
					}
				>
					<div className="action-wrapper">
						<Button onClick={addAdminMenu.open} className="primary">
							Add admin
						</Button>

						{addAdminMenu.isOpen && (
							<UserAdminSelect
								onSelect={(characterId) => props.onAddUserAdmin({characterId})}
								onClose={addAdminMenu.close}
								adminUserIds={props.adminUserIds}
								guildId={props.guildId}
							/>
						)}
					</div>
				</GroupHeading>

				<div className="user-admin-items">
					{userAdminItems.length ? (
						userAdminItems
					) : (
						<div className="empty-text">No individual characters are admins</div>
					)}
				</div>
			</div>
		</>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	// sort characters in such a way that hopefully the main is first
	const characters = CharacterDuck.getCharactersForGuild(
		state.characters,
		props.guildId,
		{
			withMissing: true,
			withUnimported: false,
			withUnrostered: false,
			withoutExternal: false
		}
	).sort((a, b) => {
		if (a.rank !== b.rank) return a.rank < b.rank ? -1 : 1;

		return a.name.localeCompare(b.name);
	});

	const charactersByUserId = groupByProperty(characters, 'userId');

	const adminCharacterGroups = props.adminUserIds
		.reduce<AdminCharacterGroup[]>((groups, adminUserId): AdminCharacterGroup[] => {
			const playerCharacters = charactersByUserId.get(adminUserId);
			if (!playerCharacters || !playerCharacters.length) return groups;

			const [main, ...alts] = playerCharacters;

			return [
				...groups,
				{
					adminUserId,
					main,
					alts
				}
			];
		}, [])
		.sort((a, b) => a.main.name.localeCompare(b.main.name));

	return {
		adminCharacterGroups
	};
}

export const UserAdmins = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: DispatchProps;
}>(mapStateToProps, {
	onAddUserAdmin: GuildDuck.addUserAdmin,
	onRemoveUserAdmin: GuildDuck.removeUserAdmin
})(UserAdminsComp);
