import React from 'react';

export default function MyAccount() {
	return (
		<div className="settings-content my-account">
			<i className="lnr lnr-ghost-hipster" />

			<h2>I needed some content here</h2>
		</div>
	);
}
