import {Role, ROLES_ORDERED} from '../../../constants/wow';

import type {IBossAssignments} from '../../../ducks/roster-boss';

/** Will only contain entries for a character that's actually assigned */
export type AssignedRoleByCharacterId = Map<number, Role>;

/**
 * Produces a Map<charId, role> for a boss' assignments that indicates
 * which role a certain character is assigned to
 */
export function calculateAssignedRoleByCharacterId(
	roleAssignmentsForBoss: IBossAssignments
): AssignedRoleByCharacterId {
	const assignedRoleByCharacterId: AssignedRoleByCharacterId = new Map<number, Role>();

	ROLES_ORDERED.forEach((role) => {
		const assignmentsForRole = roleAssignmentsForBoss[role];
		assignmentsForRole.forEach((characterId) => {
			assignedRoleByCharacterId.set(characterId, role);
		});
	});

	return assignedRoleByCharacterId;
}
