import React from 'react';
import classnames from 'classnames';

import type {Role} from '@constants/wow';

import {getRoleImageNewUrl} from '@helpers/images';

import type {Character} from '@models/character';

import {TimeAgo} from '../../../../Utils/TimeAgo';
import {Image} from '../../../../Utils/Image';

interface RoleIconProps {
	lastUpdatedAt: number | undefined;
	role: Role;
}

const RoleIcon: React.FC<RoleIconProps> = (props) => {
	const iconWrapperClass = classnames(
		{
			'tooltip tooltip-top': !!props.lastUpdatedAt
		},
		'role-icon-wrapper'
	);

	return (
		<div className="role-icon">
			<div
				className={iconWrapperClass}
				data-hint={
					props.lastUpdatedAt
						? `Updated ${TimeAgo.getDate(props.lastUpdatedAt)}`
						: ''
				}
			>
				<Image src={getRoleImageNewUrl(props.role)} />
			</div>
		</div>
	);
};

interface AssignmentProps {
	onRemove(): void;
	onClick(): void;

	isShowingRemove: boolean;
	character: Character;
}

const Assignment: React.FC<AssignmentProps> = (props) => {
	const assignmentClass = classnames(
		{
			'with-remove': props.isShowingRemove
		},
		props.character.class,
		'wow-style bg-before',
		'current-assignment'
	);

	function handleRemove(e: React.MouseEvent<HTMLDivElement>): void {
		e.preventDefault();
		e.stopPropagation();

		props.onRemove();
	}

	return (
		<div onClick={props.onClick} className={assignmentClass}>
			<div className="name">{props.character.name}</div>

			{props.isShowingRemove && (
				<div onClick={handleRemove} className="remove-character">
					<i className="lnr lnr-cross" />
				</div>
			)}
		</div>
	);
};

interface RoleGroupProps {
	onUnassignedCharacter(characterId: CharacterId): void;
	onSearch(characterId: CharacterId): void;

	canRemoveCharacters: boolean;

	lastUpdatedAt: number | undefined;
	role: Role;

	assignedCharacters: Character[];
}

export const RoleGroup: React.FC<RoleGroupProps> = (props) => {
	const assignedItems = props.assignedCharacters.map((character) => (
		<Assignment
			key={character.id}
			onRemove={() => props.onUnassignedCharacter(character.id)}
			onClick={() => props.onSearch(character.id)}
			isShowingRemove={props.canRemoveCharacters}
			character={character}
		/>
	));

	return (
		<div className="role-group">
			<RoleIcon lastUpdatedAt={props.lastUpdatedAt} role={props.role} />

			<div className="content">
				{assignedItems.length ? (
					<div className="assignments">{assignedItems}</div>
				) : (
					<div className="no-assignments">
						No {props.role} are currently rostered
					</div>
				)}
			</div>
		</div>
	);
};
