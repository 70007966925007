import React from 'react';

import {connect2, DroptimizerReportDuck} from '@ducks';

import {ConfirmModal} from '@components/Utils/ConfirmModal';
import {Input} from '@components/Utils/Input';

interface OwnProps {
	onClose(): void;
}

interface MapProps {
	isSubmitting: boolean;
}

interface DispatchProps {
	onSubmit: BoundThunk<typeof DroptimizerReportDuck['submitDroptimizerReport']>;
}

type Props = OwnProps & MapProps & DispatchProps;

const SubmitReportModalComp: React.FC<Props> = (props) => {
	const $reportUrl = React.useRef<Input>(null);

	async function handleSubmit(): Promise<void> {
		const reportUrl = $reportUrl.current?.getValue();
		if (!reportUrl) return;

		await props.onSubmit({reportUrl});

		props.onClose();
	}

	return (
		<ConfirmModal
			modalClassName="normal-alignment"
			onSubmit={handleSubmit}
			onClose={props.onClose}
			isSubmitting={props.isSubmitting}
			heading="Submit Droptimizer report"
			submitButtonText="Submit"
			submitColour="primary"
		>
			<Input
				ref={$reportUrl}
				autoFocus={true}
				label="Discord channel webhook URL"
				placeholder="e.g. https://www.raidbots.com/simbot/report/x8SdUPUpx3f9HL4yEx68CA"
				note={`The URL of the report on raidbots.com, or via the "Share Report URL" button on the page`}
			/>
		</ConfirmModal>
	);
};

function mapStateToProps(state: IRootState): MapProps {
	return {
		isSubmitting: DroptimizerReportDuck.getIsSubmittingReport(state.droptimizerReports)
	};
}

export const SubmitReportModal = connect2<{
	Own: OwnProps;
	State: MapProps;
	Dispatch: DispatchProps;
}>(mapStateToProps, {
	onSubmit: DroptimizerReportDuck.submitDroptimizerReport
})(SubmitReportModalComp);
