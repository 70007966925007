import React from 'react';
import classnames from 'classnames';

import * as wow from '../../../constants/wow';

import * as images from '../../../helpers/images';

import type {Character} from '../../../models/character';

import type {
	ICharMetaMap,
	AssignmentsByUserId,
	BossKillByCharacterId
} from '../RosterContainer';
import {CharacterRow} from './CharacterRow';
import {RoleLimit} from './RoleLimit';

function getOtherAssignedAltCharacters(
	character: Character,
	assignmentsByUserId: AssignmentsByUserId
): Array<{role: wow.Role; character: Character}> {
	if (!character.userId) return [];

	const assignmentsForUserId = assignmentsByUserId.get(character.userId);
	if (!assignmentsForUserId) return [];

	// filter out this character
	return assignmentsForUserId.filter((x) => x.character.id !== character.id);
}

interface IProps {
	onRoster(role: string, charId: number, isActive: boolean): void;
	onUpdateRoleLimit(role: string, limit: number): void;
	onChangeTab(role: wow.Role): void;

	isShowingKillCount: boolean;

	wowheadBonus: string;
	selectedRole: wow.ROLES;
	characterMetaMap: ICharMetaMap;
	wowBossId: WowBossId;
	bossId: number;

	roleLimits: {[key: string]: number};
	roleUpdatedAt: {[key: string]: number};
	roleGroups: {[key: string]: Character[]};

	roleAssignments: {
		melee: number[];
		ranged: number[];
		healers: number[];
		tanks: number[];
	};

	bossKillByCharacterId: BossKillByCharacterId;
	assignedRoleByCharacterId: Map<number, wow.Role>;
	assignmentsByUserId: AssignmentsByUserId;
}

export const AssignmentControls: React.FC<IProps> = (props) => {
	const isRoleAtAssignmentLimit =
		props.roleAssignments[props.selectedRole].length >=
		props.roleLimits[props.selectedRole];

	const characterRows = props.roleGroups[props.selectedRole].map((char) => {
		const isAssigned = props.roleAssignments[props.selectedRole].includes(char.id);

		const assignedRole = props.assignedRoleByCharacterId.get(char.id);
		const otherAssignedRole =
			assignedRole && assignedRole !== props.selectedRole ? assignedRole : undefined;

		return (
			<CharacterRow
				key={char.id}
				onToggle={() =>
					(isAssigned || !isRoleAtAssignmentLimit) &&
					props.onRoster(props.selectedRole, char.id, !isAssigned)
				}
				isShowingKillCount={props.isShowingKillCount}
				isAssigned={isAssigned}
				otherAssignedRole={otherAssignedRole}
				otherAssignedAltCharacters={getOtherAssignedAltCharacters(
					char,
					props.assignmentsByUserId
				)}
				wowheadBonus={props.wowheadBonus}
				wowBossId={props.wowBossId}
				characterMeta={props.characterMetaMap[char.id]}
				bossKillInfo={props.bossKillByCharacterId.get(char.id)}
				character={char}
			/>
		);
	});

	const tabs = wow.ROLES_ORDERED.map((role) => {
		const roleClass = classnames(
			{
				active: role === props.selectedRole
			},
			'tab'
		);

		return (
			<div key={role} onClick={() => props.onChangeTab(role)} className={roleClass}>
				<img src={images.getRoleImageUrl(role)} />
			</div>
		);
	});

	const contentClass = classnames(
		{
			limited: isRoleAtAssignmentLimit
		},
		'content'
	);

	return (
		<div className="new-assignments">
			<div className="tabs">{tabs}</div>

			<RoleLimit
				onSubmit={(limit) => props.onUpdateRoleLimit(props.selectedRole, limit)}
				roleLabel={props.selectedRole}
				currentRoleAssignments={props.roleAssignments[props.selectedRole].length}
				limit={props.roleLimits[props.selectedRole]}
			/>

			{isRoleAtAssignmentLimit && (
				<div className="limited-banner">
					No more {props.selectedRole} can be assigned because the limit has been
					reached
				</div>
			)}

			<div className={contentClass}>{characterRows}</div>
		</div>
	);
};
