import type {
	CreateCooldownEventInput,
	CreateCooldownEventResult,
	DeleteCooldownEventInput,
	DeleteCooldownEventResult,
	UpdateNameCooldownEventInput,
	UpdateNameCooldownEventResult,
	UpdateTimeCooldownEventInput,
	UpdateTimeCooldownEventResult,
	ReorderCooldownEventInput,
	ReorderCooldownEventResult
} from 'api-types';

import {CooldownEventDuck, BannerDuck, Rpc} from '@ducks';

import {optimistic} from '../../helpers/toolbox';
import api from '../../helpers/api';

import type {CooldownSheetId} from '@models/cooldownSheet';
import {CooldownEvent, CooldownEventId} from '@models/cooldownEvent';

/** Create a new CooldownAction on a CooldownSheet */
export function createCooldownEvent(data: {
	cooldownSheetId: CooldownSheetId;
	wowAbilityId: WowAbilityId | undefined;
	eventName: string | undefined;
}): Thunk<void> {
	return (dispatchEvent) => {
		dispatchEvent<CooldownEventDuck.Actions['CREATE']>({
			type: CooldownEventDuck.CREATE
		});

		return api
			.call<CreateCooldownEventInput, CreateCooldownEventResult>(
				Rpc.COOLDOWN_EVENT_CREATE,
				{
					cooldownSheetId: data.cooldownSheetId,
					wowAbilityId: data.wowAbilityId,
					eventName: data.eventName
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownEventDuck.Actions['CREATE_SUCCESS']>({
						type: CooldownEventDuck.CREATE_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownEventDuck.Actions['CREATE_FAILURE']>({
						type: CooldownEventDuck.CREATE_FAILURE
					});
				}
			);
	};
}

/** Update the name of a CooldownEvent */
export function updateNameCooldownEvent(data: {
	cooldownEventId: CooldownEventId;

	wowAbilityId: WowAbilityId | undefined;
	name: string | undefined;
}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();

		const cooldownEvent = CooldownEventDuck.getCooldownEventForCooldownEventId(
			state.cooldownEvents,
			data.cooldownEventId
		);
		if (!cooldownEvent) return Promise.resolve();

		const optimisticId = optimistic.getId();
		dispatchEvent<CooldownEventDuck.Actions['UPDATE_NAME']>({
			...optimistic.begin(optimisticId),
			type: CooldownEventDuck.UPDATE_NAME,
			payload: {
				cooldownEvent: new CooldownEvent({
					...cooldownEvent,
					wowAbilityId: data.wowAbilityId || null,
					// not strictly correct as it should be the ability name if there is an
					// ability being set, but it's only used as a fallback anyway
					name: data.name || ''
				})
			}
		});

		return api
			.call<UpdateNameCooldownEventInput, UpdateNameCooldownEventResult>(
				Rpc.COOLDOWN_EVENT_UPDATE_NAME,
				{
					cooldownEventId: data.cooldownEventId,
					wowAbilityId: data.wowAbilityId,
					name: data.name
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownEventDuck.Actions['UPDATE_NAME_SUCCESS']>({
						...optimistic.commit(optimisticId),
						type: CooldownEventDuck.UPDATE_NAME_SUCCESS,
						payload: {cooldownEventId: data.cooldownEventId}
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownEventDuck.Actions['UPDATE_NAME_FAILURE']>({
						...optimistic.revert(optimisticId),
						type: CooldownEventDuck.UPDATE_NAME_FAILURE,
						payload: {cooldownEventId: data.cooldownEventId}
					});
				}
			);
	};
}

/** Update the time of a CooldownEvent */
export function updateTimeCooldownEvent(data: {
	cooldownEventId: CooldownEventId;
	time: number | null;
}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();

		const cooldownEvent = CooldownEventDuck.getCooldownEventForCooldownEventId(
			state.cooldownEvents,
			data.cooldownEventId
		);
		if (!cooldownEvent) return Promise.resolve();

		const optimisticId = optimistic.getId();
		dispatchEvent<CooldownEventDuck.Actions['UPDATE_TIME']>({
			...optimistic.begin(optimisticId),
			type: CooldownEventDuck.UPDATE_TIME,
			payload: {
				cooldownEvent: new CooldownEvent({...cooldownEvent, time: data.time})
			}
		});

		return api
			.call<UpdateTimeCooldownEventInput, UpdateTimeCooldownEventResult>(
				Rpc.COOLDOWN_EVENT_UPDATE_TIME,
				{
					cooldownEventId: data.cooldownEventId,
					time: data.time
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownEventDuck.Actions['UPDATE_TIME_SUCCESS']>({
						...optimistic.commit(optimisticId),
						type: CooldownEventDuck.UPDATE_TIME_SUCCESS,
						payload: {cooldownEventId: data.cooldownEventId}
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownEventDuck.Actions['UPDATE_TIME_FAILURE']>({
						...optimistic.revert(optimisticId),
						type: CooldownEventDuck.UPDATE_TIME_FAILURE,
						payload: {cooldownEventId: data.cooldownEventId}
					});
				}
			);
	};
}

/** Delete a CooldownEvent */
export function deleteCooldownEvent(data: {
	cooldownEventId: CooldownEventId;
}): Thunk<void> {
	return (dispatchEvent) => {
		const optimisticId = optimistic.getId();
		dispatchEvent<CooldownEventDuck.Actions['DELETE']>({
			...optimistic.begin(optimisticId),
			type: CooldownEventDuck.DELETE,
			payload: {cooldownEventId: data.cooldownEventId}
		});

		return api
			.call<DeleteCooldownEventInput, DeleteCooldownEventResult>(
				Rpc.COOLDOWN_EVENT_DELETE,
				{
					cooldownEventId: data.cooldownEventId
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownEventDuck.Actions['DELETE_SUCCESS']>({
						...optimistic.commit(optimisticId),
						type: CooldownEventDuck.DELETE_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownEventDuck.Actions['DELETE_FAILURE']>({
						...optimistic.revert(optimisticId),
						type: CooldownEventDuck.DELETE_FAILURE
					});
				}
			);
	};
}

/** Update the order of a CooldownEvent */
export function reorderCooldownEvent(data: {
	cooldownEventId: CooldownEventId;
	order: number;
}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();

		const cooldownEvent = CooldownEventDuck.getCooldownEventForCooldownEventId(
			state.cooldownEvents,
			data.cooldownEventId
		);
		if (!cooldownEvent) return Promise.resolve();

		// during optimistic updates there may be multiple events with the same order so we
		// need to bump the updatedAt date to tie break and move the newer one later
		const isLowerShuffle = data.order > cooldownEvent.order;
		const timeAdjustment = 1000 * 60 * 1 * (isLowerShuffle ? -1 : 1);
		const newUpdatedAt = new Date(Date.now() + timeAdjustment);

		const optimisticId = optimistic.getId();
		dispatchEvent<CooldownEventDuck.Actions['REORDER']>({
			...optimistic.begin(optimisticId),
			type: CooldownEventDuck.REORDER,
			payload: {
				cooldownEvent: new CooldownEvent({
					...cooldownEvent,
					updatedAt: newUpdatedAt,
					order: data.order
				})
			}
		});

		return api
			.call<ReorderCooldownEventInput, ReorderCooldownEventResult>(
				Rpc.COOLDOWN_EVENT_REORDER,
				{
					cooldownEventId: data.cooldownEventId,
					order: data.order
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownEventDuck.Actions['REORDER_SUCCESS']>({
						...optimistic.commit(optimisticId),
						type: CooldownEventDuck.REORDER_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownEventDuck.Actions['REORDER_FAILURE']>({
						...optimistic.revert(optimisticId),
						type: CooldownEventDuck.REORDER_FAILURE
					});
				}
			);
	};
}
