import React from 'react';

import {RealmList} from '@shared/constants/realms';
import {Regions} from '@constants/wow';

import {LabelledFilterSelect, ISelectOption} from '../../../Utils/FilterSelect';
import {Modal} from '../../../Utils/Modal';
import {Button} from '../../../Utils/Button';
import {Input} from '../../../Utils/Input';

const usRealms: ISelectOption[] = RealmList.us.map(
	(x): ISelectOption => ({
		label: x.name,
		value: x.slug
	})
);
const euRealms: ISelectOption[] = RealmList.eu.map(
	(x): ISelectOption => ({
		label: x.name,
		value: x.slug
	})
);

interface IProps {
	onClose(): void;
	onSubmit(data: {name: string; realm: string}): void;

	isSubmitting: boolean;
	region: Regions;
}

interface IState {
	realm: string | null;
	realmSlug: string | null;
}

export class AddExternalCharacterModal extends React.Component<IProps, IState> {
	state: IState = {realm: null, realmSlug: null};

	fields: {
		name: Input | null;
	} = {
		name: null
	};

	handleSubmit = () => {
		const characterName = this.fields.name?.getValue();
		const realm = this.state.realm;

		if (!characterName || !realm) return;

		this.props.onSubmit({
			name: characterName,
			realm
		});
	};

	handleRealmChange = (item: ISelectOption | null) => {
		this.setState({realm: item?.label || null, realmSlug: item?.value || null});
	};

	render() {
		const realmOptions = this.props.region === Regions.US ? usRealms : euRealms;

		return (
			<Modal onClose={this.props.onClose}>
				<div className="modal-content no-overflow">
					<div className="heading">Add non-guild character</div>

					<div className="left description">
						<p>
							You may add characters that aren't in your guild to{' '}
							<span className="brand-name">Readycheck</span>, these characters can
							be from the same realm as your guild or a different realm entirely.
						</p>

						<p>
							Permissions work the same for non-guild characters as they do for
							normal guild characters: the person who owns the in-game characters
							will have access to the guild on{' '}
							<span className="brand-name">Readycheck</span> and will be able to
							manage the loot sheet of their own characters.
						</p>
					</div>

					<div className="inputs">
						<Input
							ref={(r) => (this.fields.name = r)}
							autoFocus={true}
							label="Character name"
							size="big"
						/>

						<LabelledFilterSelect
							onChange={this.handleRealmChange}
							options={realmOptions}
							placeholder="Select realm"
							label="Realm"
							value={this.state.realmSlug}
						/>
					</div>

					<div className="actions">
						<Button onClick={this.props.onClose} className="grey">
							Back
						</Button>

						<Button
							onClick={this.handleSubmit}
							isLoading={this.props.isSubmitting}
							className="primary"
						>
							Add character
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
}
