import React from 'react';
import classnames from 'classnames';

import {CLASS_TO_DISPLAY} from '@constants/wow';

import {useModal} from '@hooks/useModal';

import type {CharacterDuck} from '@ducks';

import type {Character} from '@models/character';

import {RevokeCharacter} from '../../RemoveCharacters/RevokeCharacter';
import {UpdateExternalCharacterModal} from './UpdateExternalCharacterModal';
import {InfoIcon} from '../../../Utils/InfoIcon';
import {Menu} from '../../../Utils/Menu';

interface IProps {
	onUpdateCharacter: typeof CharacterDuck.updateExternal;
	onRevokeCharacer: typeof CharacterDuck.revoke;

	isUpdating: boolean;
	isRevoking: boolean;

	character: Character;
}

export const ExternalCharacter: React.FC<IProps> = (props) => {
	const revokeModal = useModal();
	const updateModal = useModal();
	let menu: Menu | null = null;

	const wrapperClass = classnames(
		{
			'is-missing': props.character.isMissing
		},
		'character-row-wrapper'
	);

	const characterClass = classnames(
		props.character.class,
		'settings-character-row wow-style bg-before'
	);

	return (
		<>
			{revokeModal.isOpen && (
				<RevokeCharacter
					onSubmit={() =>
						props.onRevokeCharacer(props.character.id, revokeModal.close)
					}
					onClose={revokeModal.close}
					isAdminUnroster={false}
					isSubmitting={props.isRevoking}
					name={props.character.name}
				/>
			)}

			{updateModal.isOpen && (
				<UpdateExternalCharacterModal
					onSubmit={(data) =>
						props.onUpdateCharacter(props.character.id, data, updateModal.close)
					}
					onClose={updateModal.close}
					isSubmitting={props.isUpdating}
					region={props.character.region}
					name={props.character.name}
					realm={props.character.realm}
				/>
			)}

			<div className={wrapperClass}>
				<div className={characterClass}>
					<div className="left">
						<img src={props.character.getAvatarUrl()} className="thumbnail" />

						<div className="details">
							<div className="name">{props.character.name}</div>

							<div className="secondary">
								{CLASS_TO_DISPLAY[props.character.class]}
							</div>
						</div>
					</div>

					<div className="right">
						<div className="location-details">
							<div className="guild-name">{props.character.guildName}</div>

							<div className="realm">{props.character.realm}</div>
						</div>

						<div className="overflow-menu">
							<i
								onClick={() => menu?.toggleExpanded()}
								className="lnr lnr-ellipsis"
							/>

							<Menu ref={(r) => (menu = r)}>
								<div onClick={updateModal.open}>Update character</div>

								<div onClick={revokeModal.open} className="alert">
									Revoke access
								</div>
							</Menu>
						</div>
					</div>
				</div>

				{props.character.isMissing && (
					<div className="character-is-missing">
						Error syncing {props.character.name}
						<InfoIcon content="Readycheck was unable to sync this character - perhaps they've renamed or changed realm? Occasionally hiccups happen and Readycheck is unable to find a character, this usually resolves itself" />
					</div>
				)}
			</div>
		</>
	);
};
