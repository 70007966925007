import React from 'react';

import {getItemLevel, getWowheadPrefix} from '@constants';

import type {DroptimizerItem} from '@models/droptimizerReport';

import type * as LootContainer from '../LootContainer';
import type {ISelectOption} from '../../Utils/SelectInput';
import {TimeAgo} from '@components/Utils/TimeAgo';
import {Characters} from './Characters';
import {Selection} from './Selection';

export function generateWowheadLinkData(wowItemId: string, bonus?: string) {
	const prefix = getWowheadPrefix(bonus);
	let url = `${prefix}/item=${wowItemId}`;
	let rel = '';

	if (bonus) {
		const itemLevelOverride = getItemLevel({
			itemId: wowItemId,
			bonus
		});

		rel = `bonus=${bonus}`;
		if (itemLevelOverride) rel = `${rel}&ilvl=${itemLevelOverride}`;

		url = `${url}&${rel}`;
	}

	return {url, rel};
}

interface IItemProps {
	onSelect(charId: number, wowItemId: string, optionId?: number): Promise<void>;
	onUpdateNote(charId: number, wowItemId: string, note: string | null): Promise<void>;
	onSearch(id: number): void;

	selectedRoles: string[];
	/** Filter Wants to just the selected loot option */
	selectedLootOptionIds: LootOptionId[];

	item: LootContainer.ILootViewItem;
	optionOptions: ISelectOption[];
	isAllowedToEdit: boolean;

	/** Whether this character has any droptimizer data */
	characterHasDroptimizerData: boolean;
	droptimizerItemData: DroptimizerItem | undefined;
	isShowingDroptimizerRelativeGain: boolean;
	characterClass?: string;
	characterId?: number;
}

export default class Item extends React.Component<IItemProps> {
	handleSelection = (id: number) => {
		if (!this.props.characterId) return undefined;

		const optionId = id || undefined;
		return this.props.onSelect(this.props.characterId, this.props.item.id, optionId);
	};

	handleNoteUpdate = (note: string | null) => {
		if (!this.props.characterId) return Promise.resolve();
		return this.props.onUpdateNote(this.props.characterId, this.props.item.id, note);
	};

	handleCharacterClick = (e: React.MouseEvent<HTMLDivElement>, characterId: number) => {
		// there's a shortcut key combination that can be used to quickly
		// remove an item selection from a player
		if (this.props.isAllowedToEdit && e.shiftKey && (e.ctrlKey || e.metaKey)) {
			void this.props.onSelect(characterId, this.props.item.id, undefined);
			return;
		}

		this.props.onSearch(characterId);
	};

	render() {
		if (this.props.characterClass) {
			const allowed: {[key: string]: string[]} = this.props.item.allowed;

			const isAllowed = this.props.selectedRoles.some((role) => {
				if (allowed[role].includes(this.props.characterClass!)) return true;
				return false;
			});

			if (!isAllowed) return null;
		}

		const linkData = generateWowheadLinkData(
			this.props.item.id,
			this.props.item.wowheadBonus
		);

		return (
			<div className="loot-item-row">
				<div className="left-col">
					<div className="item-col">
						<div className="item-info">
							<a href={linkData.url} rel="noopener noreferrer" target="_blank">
								<img
									loading="lazy"
									className={this.props.item.slot}
									src={`/static/item-icons/${this.props.item.id}`}
								/>

								<div className="name">{this.props.item.name}</div>
							</a>
						</div>

						{!!this.props.item.wantedByCharacter?.selectionPerformedAt && (
							<div className="selection-updated">
								<span>Selected </span>
								<TimeAgo
									date={this.props.item.wantedByCharacter.selectionPerformedAt.getTime()}
								/>
							</div>
						)}
					</div>

					{!!this.props.characterId && (
						<Selection
							onUpdateNote={this.handleNoteUpdate}
							onSelection={this.handleSelection}
							isAllowedToEdit={this.props.isAllowedToEdit}
							hasDroptimizerData={this.props.characterHasDroptimizerData}
							droptimizerItemData={this.props.droptimizerItemData}
							want={this.props.item.wantedByCharacter}
							lootOptions={this.props.optionOptions}
						/>
					)}
				</div>

				<Characters
					onCharacterClick={this.handleCharacterClick}
					isShowingDroptimizerRelativeGain={
						this.props.isShowingDroptimizerRelativeGain
					}
					selectedLootOptionIds={this.props.selectedLootOptionIds}
					wantedBy={this.props.item.wantedBy}
				/>
			</div>
		);
	}
}
