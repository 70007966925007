import React from 'react';

import {Classes, CLASS_TO_DISPLAY} from '@constants/wow';

import {Image} from '../../../../../../Utils/Image';

interface Props {
	isAssigned: boolean;

	name: string;
	avatarImageUrl: string;
	class: Classes;
}

export const CharacterDetails: React.FC<Props> = (props) => {
	return (
		<div className="character-details">
			<div className={`class-colour-block wow-style bg ${props.class}`} />

			{/* {props.isAssigned && <div className="assigned-chip">ASSIGNED</div>} */}

			<div className="details">
				<Image src={props.avatarImageUrl} />

				<div className="naming">
					<div className="primary">{props.name}</div>
					<div className="secondary">{CLASS_TO_DISPLAY[props.class]}</div>
				</div>
			</div>
		</div>
	);
};
