import React from 'react';
import classnames from 'classnames';
import {FiMoreVertical} from 'react-icons/fi';

import {useBoolean} from '@hooks/useBoolean';

import {ButtonGroup, Button} from '@components/Utils/Button';

interface IMenuProps {
	onChange?(isExpanded: boolean): void;

	className?: string;
	children?: any;
}

interface IState {
	isExpanded: boolean;
}

export default class Menu extends React.Component<IMenuProps, IState> {
	menu: HTMLDivElement;

	state = {
		isExpanded: false
	};

	componentWillUnmount() {
		this.removeEvent();
	}

	private addEvent() {
		document.addEventListener('click', this.handleDocumentClick);
	}

	private removeEvent() {
		document.removeEventListener('click', this.handleDocumentClick);
	}

	private readonly handleDocumentClick = (e: MouseEvent) => {
		let hasDataKeepMenu = false;
		let node: HTMLElement | null = e.target as HTMLElement;
		while (node && !hasDataKeepMenu) {
			if (node.dataset?.keepMenu) hasDataKeepMenu = true;
			else node = node.parentElement;
		}

		if (!this.menu || hasDataKeepMenu) return;

		this.close();
	};

	private update(isExpanded: boolean) {
		this.setState({isExpanded}, () => {
			if (isExpanded) this.addEvent();
			else this.removeEvent();

			if (this.props.onChange) this.props.onChange(isExpanded);
		});
	}

	toggleExpanded = () => {
		this.update(!this.state.isExpanded);
	};

	close = () => {
		this.update(false);
	};

	render() {
		if (!this.state.isExpanded) return false;

		const menuClassName = classnames('menu-widget', this.props.className);

		return (
			<div ref={(r: HTMLDivElement) => (this.menu = r)} className={menuClassName}>
				<div className="content">{this.props.children}</div>
			</div>
		);
	}
}

export {Menu};

export const Divider: React.FC = () => {
	return <div className="menu-ignore menu-divider" />;
};

interface MenuItemProps {
	onClick(): void;
	type?: 'alert';
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
	return (
		<div onClick={props.onClick} className={props.type}>
			{props.children}
		</div>
	);
};

interface ConfirmationMenuItemProps {
	onClick(): void;
	confirmText: string;
	type?: 'alert';
}

export const ConfirmationMenuItem: React.FC<ConfirmationMenuItemProps> = (props) => {
	const confirmation = useBoolean(false);

	if (confirmation.value) {
		return (
			<ButtonGroup withNoTopMarging={true}>
				<Button onClick={confirmation.setFalse} className="small grey">
					Cancel
				</Button>

				<Button onClick={props.onClick} className="small red">
					{props.children}
				</Button>
			</ButtonGroup>
		);
	}

	return (
		<div onClick={confirmation.setTrue} className={props.type} data-keep-menu={true}>
			{props.children}
		</div>
	);
};

interface ActionsMenuProps {
	className?: string;
	buttonClassName?: string;
	itemsClassName?: string;
}

export const ActionsMenu: React.FC<ActionsMenuProps> = (props) => {
	const menuRef = React.useRef<Menu>(null);
	const menuClassName = classnames(props.className, 'actions-menu-widget');
	const buttonClassName = classnames(props.buttonClassName, 'actions-menu-button');
	const itemsClassName = classnames(props.itemsClassName, 'actions-menu-items');

	// if there's no menu items don't show the menu
	if (!props.children) return null;

	return (
		<div className={menuClassName}>
			<div
				onClick={() => menuRef.current?.toggleExpanded()}
				className={buttonClassName}
			>
				<FiMoreVertical />
			</div>

			<Menu ref={menuRef} className={itemsClassName}>
				{props.children}
			</Menu>
		</div>
	);
};
