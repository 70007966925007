import type {
	IFetchBossKillsForInstanceInput,
	IFetchBossKillsForInstanceResult
} from 'api-types';

import * as rpc from '../../../shared/constants/rpc';

import api from '../../helpers/api';

import {BossKillsDuck, GuildDuck} from '..';

export function fetchForInstance(encounterInstanceId: string): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);
		if (!guildId) return Promise.reject();

		dispatch<BossKillsDuck.IActions['FETCH']>({
			type: BossKillsDuck.FETCH,
			payload: {
				encounterInstanceId
			}
		});

		return api
			.call<IFetchBossKillsForInstanceInput, IFetchBossKillsForInstanceResult>(
				rpc.BOSS_KILLS_FETCH,
				{
					encounterInstanceId,
					guildId
				}
			)
			.then(
				(message) => {
					dispatch<BossKillsDuck.IActions['FETCH_SUCCESS']>({
						type: BossKillsDuck.FETCH_SUCCESS,
						payload: {
							encounterInstanceId,
							bossKills: message.data.bossKills
						}
					});
				},

				() => {
					dispatch<BossKillsDuck.IActions['FETCH_FAILURE']>({
						type: BossKillsDuck.FETCH_FAILURE,
						payload: {
							encounterInstanceId
						}
					});
				}
			);
	};
}
