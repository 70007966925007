import type {ILootSelectionClass} from '../models/loot-selection';
import type {ILootOptionClass} from '../models/loot-option';
import type {ILootCoinClass} from '../models/loot-coin';
import type {IRosterRoleAssignmentClass} from '../models/roster-role-assignment';
import type {IRosterBossClass} from '../models/roster-boss';
import type {IRosterClass} from '../models/roster';
import type {ICharacterClass} from '../models/character';
import type {IUserBillingClass} from '../models/userBilling';
import type {IGuildSponsorshipClass} from '../models/guildSponsorship';
import type {IGuildClass} from '../models/guild';
import type {IUserClass} from '../models/user';
import type {ITagAssignmentClass} from '../models/tag-assignment';
import type {ITagClass} from '../models/tag';
import type {CooldownSheetData} from '@models/cooldownSheet';
import type {CooldownEventData} from '@models/cooldownEvent';
import type {CooldownActionData} from '@models/cooldownAction';
import type {DroptimizerReportData} from '@models/droptimizerReport';

export const USER_UPDATE = 'feed:users.update';

export const GUILD_INSERT = 'feed:guilds.insert';
export const GUILD_UPDATE = 'feed:guilds.update';
export const GUILD_DELETE = 'feed:guilds.delete';

export const CHARACTER_INSERT = 'feed:characters.insert';
export const CHARACTER_UPDATE = 'feed:characters.update';
export const CHARACTER_DELETE = 'feed:characters.delete';

export const ROSTER_INSERT = 'feed:rosters.insert';
export const ROSTER_UPDATE = 'feed:rosters.update';
export const ROSTER_DELETE = 'feed:rosters.delete';

export const ROSTER_BOSS_INSERT = 'feed:roster_bosses.insert';
export const ROSTER_BOSS_UPDATE = 'feed:roster_bosses.update';
export const ROSTER_BOSS_DELETE = 'feed:roster_bosses.delete';

export const ROSTER_ROLE_ASSIGNMENT_INSERT = 'feed:roster_role_assignments.insert';
export const ROSTER_ROLE_ASSIGNMENT_DELETE = 'feed:roster_role_assignments.delete';

export const LOOT_OPTION_INSERT = 'feed:loot_options.insert';
export const LOOT_OPTION_UPDATE = 'feed:loot_options.update';
export const LOOT_OPTION_DELETE = 'feed:loot_options.delete';

export const LOOT_SELECTION_INSERT = 'feed:loot_selections.insert';
export const LOOT_SELECTION_UPDATE = 'feed:loot_selections.update';
export const LOOT_SELECTION_DELETE = 'feed:loot_selections.delete';

export const LOOT_COIN_INSERT = 'feed:loot_coins.insert';
export const LOOT_COIN_UPDATE = 'feed:loot_coins.update';
export const LOOT_COIN_DELETE = 'feed:loot_coins.delete';

export const USER_BILLING_INSERT = 'feed:user_billing.insert';
export const USER_BILLING_UPDATE = 'feed:user_billing.update';
export const USER_BILLING_DELETE = 'feed:user_billing.delete';

export const GUILD_SPONSORSHIP_INSERT = 'feed:guild_sponsorships.insert';
export const GUILD_SPONSORSHIP_UPDATE = 'feed:guild_sponsorships.update';
export const GUILD_SPONSORSHIP_DELETE = 'feed:guild_sponsorships.delete';

export const TAG_ASSIGNMENT_INSERT = 'feed:tag_assignments.insert';
export const TAG_ASSIGNMENT_UPDATE = 'feed:tag_assignments.update';
export const TAG_ASSIGNMENT_DELETE = 'feed:tag_assignments.delete';

export const TAG_INSERT = 'feed:tags.insert';
export const TAG_UPDATE = 'feed:tags.update';
export const TAG_DELETE = 'feed:tags.delete';

export const COOLDOWN_SHEET_INSERT = 'feed:cooldown_sheets.insert';
export const COOLDOWN_SHEET_UPDATE = 'feed:cooldown_sheets.update';
export const COOLDOWN_SHEET_DELETE = 'feed:cooldown_sheets.delete';

export const COOLDOWN_EVENT_INSERT = 'feed:cooldown_events.insert';
export const COOLDOWN_EVENT_UPDATE = 'feed:cooldown_events.update';
export const COOLDOWN_EVENT_DELETE = 'feed:cooldown_events.delete';

export const COOLDOWN_ACTION_INSERT = 'feed:cooldown_actions.insert';
export const COOLDOWN_ACTION_UPDATE = 'feed:cooldown_actions.update';
export const COOLDOWN_ACTION_DELETE = 'feed:cooldown_actions.delete';

export const DROPTIMIZER_REPORT_INSERT = 'feed:droptimizer_reports.insert';
export const DROPTIMIZER_REPORT_UPDATE = 'feed:droptimizer_reports.update';
export const DROPTIMIZER_REPORT_DELETE = 'feed:droptimizer_reports.delete';

interface IInsert<Name, Record> {
	readonly type: Name;
	readonly payload: {
		readonly newRecord: Record;
	};
}

interface IUpdate<Name, Record> {
	readonly type: Name;
	readonly payload: {
		readonly oldRecord: Record;
		readonly newRecord: Record;
	};
}

interface IDelete<Name, Record> {
	readonly type: Name;
	readonly payload: {
		readonly oldRecord: Record;
	};
}

// actions
export interface IActions {
	// user
	USER_UPDATE: {
		readonly type: typeof USER_UPDATE;
		readonly payload: {
			readonly oldRecord: IUserClass;
			readonly newRecord: IUserClass;
		};
	};

	// guild
	GUILD_INSERT: IInsert<typeof GUILD_INSERT, IGuildClass>;
	GUILD_UPDATE: IUpdate<typeof GUILD_UPDATE, IGuildClass>;
	GUILD_DELETE: IDelete<typeof GUILD_DELETE, IGuildClass>;

	// character
	CHARACTER_INSERT: {
		readonly type: typeof CHARACTER_INSERT;
		readonly payload: {
			readonly newRecord: ICharacterClass;
		};
	};

	CHARACTER_UPDATE: {
		readonly type: typeof CHARACTER_UPDATE;
		readonly payload: {
			readonly oldRecord: ICharacterClass;
			readonly newRecord: ICharacterClass;
		};
	};

	CHARACTER_DELETE: {
		readonly type: typeof CHARACTER_DELETE;
		readonly payload: {
			readonly oldRecord: ICharacterClass;
		};
	};

	// roster
	ROSTER_INSERT: {
		readonly type: typeof ROSTER_INSERT;
		readonly payload: {
			readonly newRecord: IRosterClass;
		};
	};

	ROSTER_UPDATE: {
		readonly type: typeof ROSTER_UPDATE;
		readonly payload: {
			readonly oldRecord: IRosterClass;
			readonly newRecord: IRosterClass;
		};
	};

	ROSTER_DELETE: {
		readonly type: typeof ROSTER_DELETE;
		readonly payload: {
			readonly oldRecord: IRosterClass;
		};
	};

	// roster boss
	ROSTER_BOSS_INSERT: {
		readonly type: typeof ROSTER_BOSS_INSERT;
		readonly payload: {
			readonly newRecord: IRosterBossClass;
		};
	};

	ROSTER_BOSS_UPDATE: {
		readonly type: typeof ROSTER_BOSS_UPDATE;
		readonly payload: {
			readonly oldRecord: IRosterBossClass;
			readonly newRecord: IRosterBossClass;
		};
	};

	ROSTER_BOSS_DELETE: {
		readonly type: typeof ROSTER_BOSS_DELETE;
		readonly payload: {
			readonly oldRecord: IRosterBossClass;
		};
	};

	// roster role assignment
	ROSTER_ROLE_ASSIGNMENT_INSERT: {
		readonly type: typeof ROSTER_ROLE_ASSIGNMENT_INSERT;
		readonly payload: {
			readonly newRecord: IRosterRoleAssignmentClass;
		};
	};

	ROSTER_ROLE_ASSIGNMENT_DELETE: {
		readonly type: typeof ROSTER_ROLE_ASSIGNMENT_DELETE;
		readonly payload: {
			readonly oldRecord: IRosterRoleAssignmentClass;
		};
	};

	// loot option
	LOOT_OPTION_INSERT: {
		readonly type: typeof LOOT_OPTION_INSERT;
		readonly payload: {
			readonly newRecord: ILootOptionClass;
		};
	};

	LOOT_OPTION_UPDATE: {
		readonly type: typeof LOOT_OPTION_UPDATE;
		readonly payload: {
			readonly oldRecord: ILootOptionClass;
			readonly newRecord: ILootOptionClass;
		};
	};

	LOOT_OPTION_DELETE: {
		readonly type: typeof LOOT_OPTION_DELETE;
		readonly payload: {
			readonly oldRecord: ILootOptionClass;
		};
	};

	// loot selection
	LOOT_SELECTION_INSERT: {
		readonly type: typeof LOOT_SELECTION_INSERT;
		readonly payload: {
			readonly newRecord: ILootSelectionClass;
		};
	};

	LOOT_SELECTION_UPDATE: {
		readonly type: typeof LOOT_SELECTION_UPDATE;
		readonly payload: {
			readonly oldRecord: ILootSelectionClass;
			readonly newRecord: ILootSelectionClass;
		};
	};

	LOOT_SELECTION_DELETE: {
		readonly type: typeof LOOT_SELECTION_DELETE;
		readonly payload: {
			readonly oldRecord: ILootSelectionClass;
		};
	};

	// loot coin
	LOOT_COIN_INSERT: {
		readonly type: typeof LOOT_COIN_INSERT;
		readonly payload: {
			readonly newRecord: ILootCoinClass;
		};
	};

	LOOT_COIN_UPDATE: {
		readonly type: typeof LOOT_COIN_UPDATE;
		readonly payload: {
			readonly oldRecord: ILootCoinClass;
			readonly newRecord: ILootCoinClass;
		};
	};

	LOOT_COIN_DELETE: {
		readonly type: typeof LOOT_COIN_DELETE;
		readonly payload: {
			readonly oldRecord: ILootCoinClass;
		};
	};

	// user billing
	USER_BILLING_INSERT: {
		readonly type: typeof USER_BILLING_INSERT;
		readonly payload: {
			readonly newRecord: IUserBillingClass;
		};
	};

	USER_BILLING_UPDATE: {
		readonly type: typeof USER_BILLING_UPDATE;
		readonly payload: {
			readonly oldRecord: IUserBillingClass;
			readonly newRecord: IUserBillingClass;
		};
	};

	USER_BILLING_DELETE: {
		readonly type: typeof USER_BILLING_DELETE;
		readonly payload: {
			readonly oldRecord: IUserBillingClass;
		};
	};

	// guild sponsorships
	GUILD_SPONSORSHIP_INSERT: IInsert<
		typeof GUILD_SPONSORSHIP_INSERT,
		IGuildSponsorshipClass
	>;
	GUILD_SPONSORSHIP_UPDATE: IUpdate<
		typeof GUILD_SPONSORSHIP_UPDATE,
		IGuildSponsorshipClass
	>;
	GUILD_SPONSORSHIP_DELETE: IDelete<
		typeof GUILD_SPONSORSHIP_DELETE,
		IGuildSponsorshipClass
	>;

	// tag assignment
	TAG_ASSIGNMENT_INSERT: {
		readonly type: typeof TAG_ASSIGNMENT_INSERT;
		readonly payload: {
			readonly newRecord: ITagAssignmentClass;
		};
	};

	TAG_ASSIGNMENT_UPDATE: {
		readonly type: typeof TAG_ASSIGNMENT_UPDATE;
		readonly payload: {
			readonly oldRecord: ITagAssignmentClass;
			readonly newRecord: ITagAssignmentClass;
		};
	};

	TAG_ASSIGNMENT_DELETE: {
		readonly type: typeof TAG_ASSIGNMENT_DELETE;
		readonly payload: {
			readonly oldRecord: ITagAssignmentClass;
		};
	};

	// tag
	TAG_INSERT: {
		readonly type: typeof TAG_INSERT;
		readonly payload: {
			readonly newRecord: ITagClass;
		};
	};

	TAG_UPDATE: {
		readonly type: typeof TAG_UPDATE;
		readonly payload: {
			readonly oldRecord: ITagClass;
			readonly newRecord: ITagClass;
		};
	};

	TAG_DELETE: {
		readonly type: typeof TAG_DELETE;
		readonly payload: {
			readonly oldRecord: ITagClass;
		};
	};

	// cooldown sheets
	COOLDOWN_SHEET_INSERT: IInsert<typeof COOLDOWN_SHEET_INSERT, CooldownSheetData>;
	COOLDOWN_SHEET_UPDATE: IUpdate<typeof COOLDOWN_SHEET_UPDATE, CooldownSheetData>;
	COOLDOWN_SHEET_DELETE: IDelete<typeof COOLDOWN_SHEET_DELETE, CooldownSheetData>;

	// cooldown events
	COOLDOWN_EVENT_INSERT: IInsert<typeof COOLDOWN_EVENT_INSERT, CooldownEventData>;
	COOLDOWN_EVENT_UPDATE: IUpdate<typeof COOLDOWN_EVENT_UPDATE, CooldownEventData>;
	COOLDOWN_EVENT_DELETE: IDelete<typeof COOLDOWN_EVENT_DELETE, CooldownEventData>;

	// cooldown actions
	COOLDOWN_ACTION_INSERT: IInsert<typeof COOLDOWN_ACTION_INSERT, CooldownActionData>;
	COOLDOWN_ACTION_UPDATE: IUpdate<typeof COOLDOWN_ACTION_UPDATE, CooldownActionData>;
	COOLDOWN_ACTION_DELETE: IDelete<typeof COOLDOWN_ACTION_DELETE, CooldownActionData>;

	// droptimizer reports
	DROPTIMIZER_REPORT_INSERT: IInsert<
		typeof DROPTIMIZER_REPORT_INSERT,
		DroptimizerReportData
	>;
	DROPTIMIZER_REPORT_UPDATE: IUpdate<
		typeof DROPTIMIZER_REPORT_UPDATE,
		DroptimizerReportData
	>;
	DROPTIMIZER_REPORT_DELETE: IDelete<
		typeof DROPTIMIZER_REPORT_DELETE,
		DroptimizerReportData
	>;
}
