import React from 'react';
import classnames from 'classnames';

import Menu from './Menu';

export interface ISelectOption {
	isHidden?: boolean;
	name: string;
	id: any;
}

interface IProps {
	onChange(id: any): void;

	isDisabled?: boolean;

	keepOpenAfterSelection?: boolean;

	className?: string;
	selectedId?: any;
	selectedIds?: Array<string | number>;
	highlightId?: any;
	placeholder?: string;
	note?: any;
	label?: string;
	size?: string;

	items: ISelectOption[];
}

interface IState {
	isExpanded: boolean;
}

export default class SelectInput extends React.Component<IProps, IState> {
	menu: Menu;

	static defaultProps = {
		size: 'medium'
	};

	state: IState = {
		isExpanded: false
	};

	private readonly handleExpand = (isExpanded: boolean) => {
		this.setState({isExpanded});
	};

	handleMenuClick = () => {
		// do nothing if the menu is disabled
		if (this.props.isDisabled) return;

		// do nothing if it's already open, and we want to keep it open
		if (this.state.isExpanded && this.props.keepOpenAfterSelection) return;

		this.menu.toggleExpanded();
	};

	render() {
		const pickerClassName = classnames(
			{
				expanded: this.state.isExpanded,
				disabled: this.props.isDisabled
			},
			this.props.className,
			'select-input input-group'
		);

		const inputClassName = classnames('input', this.props.size);

		let selectedOption: ISelectOption | undefined;
		let selectedOptionCount = 0;
		const opts = this.props.items.map((option) => {
			if (option.isHidden) return null;

			const isSelected =
				option.id === this.props.selectedId ||
				this.props.selectedIds?.includes(option.id);

			const optionClass = classnames({
				highlighted: option.id === this.props.highlightId,
				selected: isSelected
			});

			if (isSelected) {
				selectedOption = option;
				selectedOptionCount += 1;
			}

			return (
				<div
					key={option.id}
					onClick={() => !this.props.isDisabled && this.props.onChange(option.id)}
					className={optionClass}
					data-keep-menu={this.props.keepOpenAfterSelection}
				>
					{option.name}
				</div>
			);
		});

		return (
			<div className={pickerClassName} onClick={this.handleMenuClick}>
				{this.props.label && <label>{this.props.label}</label>}

				<div className={inputClassName}>
					{selectedOptionCount > 1
						? `${selectedOption?.name} + ${selectedOptionCount - 1}`
						: selectedOption?.name}
					{!selectedOption && (
						<div className="placeholder">
							{this.props.placeholder || 'Select an option...'}
						</div>
					)}

					<i className="lnr lnr-chevron-down" />
				</div>

				<Menu ref={(r: Menu) => (this.menu = r)} onChange={this.handleExpand}>
					{opts}
				</Menu>

				{this.props.children}

				{this.props.note && <div className="note">{this.props.note}</div>}
			</div>
		);
	}
}

export {SelectInput};
