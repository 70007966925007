import React from 'react';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {
	DragEndEvent,
	DndContext,
	PointerSensor,
	useSensors,
	useSensor
} from '@dnd-kit/core';

import type {Role} from '@constants/wow';

import {connect, CooldownEventDuck, CooldownActionDuck, RosterBossDuck} from '@ducks';

import type {CooldownSheet} from '@models/cooldownSheet';
import type {CooldownEvent} from '@models/cooldownEvent';
import type {CooldownAction} from '@models/cooldownAction';

import {AddEvent} from './AddEvent';
import {Event} from './Event';

interface EventGroup {
	event: CooldownEvent;
	actions: CooldownAction[];
}

type RosteredRoleByCharacterId = Record<CharacterId, Role>;

interface OwnProps {
	onCreateEvent: BoundThunk<typeof CooldownEventDuck['createCooldownEvent']>;
	onUpdateEventName: BoundThunk<typeof CooldownEventDuck['updateNameCooldownEvent']>;
	onUpdateEventTime: BoundThunk<typeof CooldownEventDuck['updateTimeCooldownEvent']>;
	onReorderEvent: BoundThunk<typeof CooldownEventDuck['reorderCooldownEvent']>;
	onDeleteEvent: BoundThunk<typeof CooldownEventDuck['deleteCooldownEvent']>;
	onCreateAction: BoundThunk<typeof CooldownActionDuck['createCooldownAction']>;
	onUpdateActionActor: BoundThunk<
		typeof CooldownActionDuck['updateActorCooldownAction']
	>;
	onUpdateActionAbility: BoundThunk<
		typeof CooldownActionDuck['updateAbilityCooldownAction']
	>;
	onDeleteAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;

	isEditor: boolean;

	rosterBossId: RosterBossId;
	cooldownSheet: CooldownSheet;
}

interface MapProps {
	eventGroups: EventGroup[];
	rosteredRoleByCharacterId: RosteredRoleByCharacterId;
}

type Props = OwnProps & MapProps;

const TableComp: React.FC<Props> = (props) => {
	const sensors = useSensors(useSensor(PointerSensor));

	const eventSortableIds = props.eventGroups.map((x) => `event-${x.event.id}`);
	const eventItems = props.eventGroups.map((group) => (
		<Event
			key={group.event.id}
			onUpdateEventName={props.onUpdateEventName}
			onUpdateEventTime={props.onUpdateEventTime}
			onDeleteEvent={props.onDeleteEvent}
			onCreateAction={props.onCreateAction}
			onUpdateActionActor={props.onUpdateActionActor}
			onUpdateActionAbility={props.onUpdateActionAbility}
			onDeleteAction={props.onDeleteAction}
			isEditor={props.isEditor}
			rosterBossId={props.rosterBossId}
			wowBossId={props.cooldownSheet.wowBossId}
			rosteredRoleByCharacterId={props.rosteredRoleByCharacterId}
			event={group.event}
			actions={group.actions}
		/>
	));

	function handleDragEnd({active, over}: DragEndEvent): void {
		// no-op if not dropped anywhere useful
		if (!over) return;

		// console.log(active, over);

		// no-op if dropped on self
		if (active.id === over.id) return;

		// find the item we dropped on
		const activeEvent = props.eventGroups.find(
			(group) => active.id === `event-${group.event.id}`
		)?.event;
		if (!activeEvent) return;

		// find the item we dropped on
		const overEvent = props.eventGroups.find(
			(group) => over.id === `event-${group.event.id}`
		)?.event;
		if (!overEvent) return;

		void props.onReorderEvent({
			cooldownEventId: activeEvent.id,
			order: overEvent.order
		});
	}

	async function handleCreate(data: {
		wowAbilityId: WowAbilityId | undefined;
		name: string | undefined;
	}): Promise<void> {
		await props.onCreateEvent({
			cooldownSheetId: props.cooldownSheet.id,
			wowAbilityId: data.wowAbilityId,
			eventName: data.name
		});
	}

	return (
		<div className="cooldown-sheet-table">
			<div className="col-marker event" />
			<div className="col-marker time" />

			<div className="headings">
				<div className="col col-event">Event</div>
				<div className="col col-time">Time</div>
				<div className="col col-actions">Actions</div>
			</div>

			{!!eventItems.length && (
				<DndContext
					onDragEnd={handleDragEnd}
					sensors={sensors}
					modifiers={[restrictToVerticalAxis]}
				>
					<SortableContext
						items={eventSortableIds}
						strategy={verticalListSortingStrategy}
					>
						<div className="events">{eventItems}</div>
					</SortableContext>
				</DndContext>
			)}

			{props.isEditor && (
				<AddEvent onCreate={handleCreate} wowBossId={props.cooldownSheet.wowBossId} />
			)}
		</div>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const events = CooldownEventDuck.getCooldownEventsForCooldownSheetId(
		state.cooldownEvents,
		props.cooldownSheet.id
	);

	const eventGroups = events.map((event): EventGroup => {
		const actions = CooldownActionDuck.getCooldownActionsForCooldownEventId(
			state.cooldownActions,
			event.id
		);

		return {
			event,
			actions
		};
	});

	const assignedCharacterIdsByRole = RosterBossDuck.getAssignmentsForBoss(
		state.rosterBosses,
		props.rosterBossId
	);

	const assignmentEntries = Object.entries(assignedCharacterIdsByRole) as [
		Role,
		CharacterId[]
	][];

	const rosteredRoleByCharacterId = assignmentEntries.reduce<RosteredRoleByCharacterId>(
		(tempRosterdRoleByCharacterId, [role, characterIds]): RosteredRoleByCharacterId => {
			characterIds.forEach((charId) => {
				// mutating for performance reasons as this `mapStateToProps` likely gets rerun a lot
				tempRosterdRoleByCharacterId[charId] = role;
			});

			return tempRosterdRoleByCharacterId;
		},
		{}
	);

	return {
		rosteredRoleByCharacterId,
		eventGroups
	};
}

export const Table = connect<MapProps, {}, OwnProps>(mapStateToProps, {})(TableComp);
