import io from 'socket.io-client';

function log(...args: any[]) {
	if (process.env.NODE_ENV === 'development') {
		console.warn(...args);
	}
}

interface IHandlers {
	onChangeMessage(this: void, message: any): void;
	onMessage(this: void, message: any): void;

	onReconnect(this: void): void;
	onClose(this: void): void;

	onConnect?(this: void): void;
}

interface ISocketOptions extends IHandlers {
	path: string;
}

export interface ISendMessage {
	fn: string;

	data: {
		[key: string]: any;
	};

	echo: {
		callId: number;
	};
}

export interface IReceivedMessage<Data extends object = Record<string, any>> {
	[key: string]: any;

	ok: boolean;
	error?: string;

	data: DeepJsonify<Data>;
}

export interface IReceivedChangeMessage {
	table: string;
	type: string;
	newRecord?: {
		[key: string]: any;
	};
	oldRecord?: {
		[key: string]: any;
	};
}

export default class Socket {
	hasPreviouslyConnected = false;
	shouldFireClose = false;
	socket: SocketIOClient.Socket;
	queue: ISendMessage[] = [];
	handlers: IHandlers;
	path: string;

	constructor(opts: ISocketOptions) {
		this.handlers = {
			onChangeMessage: opts.onChangeMessage,
			onMessage: opts.onMessage,

			onReconnect: opts.onReconnect,
			onClose: opts.onClose
		};

		this.path = opts.path;
	}

	connect(onConnect: IHandlers['onConnect']) {
		this.shouldFireClose = true;

		// if we're already connected we need to close it
		this.disconnect();

		if (onConnect) this.handlers.onConnect = onConnect;

		this.socket = io({
			path: this.path
		});

		this.socket.on('connect', this.onOpen);
		this.socket.on('disconnect', this.onClose);
		this.socket.on('error', this.onError);
		this.socket.on('msg', this.onMessage);
		this.socket.on('feed_connected', this.onFeedConnected);
		this.socket.on('change', this.onChange);

		this.socket.on('welcome', (...args: any[]) => log('[SOCKET] welcome', ...args));
		this.socket.on('welcome-demo', (...args: any[]) =>
			log('[SOCKET] welcome-demo', ...args)
		);
	}

	disconnect(suppressClose = false) {
		if (this.socket?.connected) {
			if (suppressClose) this.shouldFireClose = false;

			this.socket.close();
		}
	}

	onMessage = (message: IReceivedMessage) => {
		log('[SOCKET] RECEIVED message:', message);

		if (message.error) {
			console.error('[SOCKET] message failed:', message);
		}

		// ignore welcome message
		if (message.echo === 'welcome') return;

		this.handlers.onMessage(message);
	};

	onChange = (message: IReceivedChangeMessage) => {
		log('[SOCKET] RECEIVED change:', message);

		this.handlers.onChangeMessage(message);
	};

	onOpen = () => {
		log('[SOCKET] client connected', this.path);

		// send any queued messages so far
		while (this.queue.length) {
			const message = this.queue.shift();
			log('[SOCKET] sending queued', message);
			this.socket.emit('msg', message);
		}

		if (this.handlers.onConnect) {
			this.handlers.onConnect();
			delete this.handlers.onConnect;
		}

		// only want to trigger this on a reconnection
		if (this.hasPreviouslyConnected && this.handlers.onReconnect) {
			this.handlers.onReconnect();
		}

		this.hasPreviouslyConnected = true;
	};

	onClose = () => {
		log('[SOCKET] client closed');

		if (this.shouldFireClose && this.handlers.onClose) {
			this.handlers.onClose();
		}
	};

	onError = (e: any) => {
		log('[SOCKET] client error', e);
	};

	onFeedConnected = () => {
		console.log('[SOCKET] change feed has reconnected');
	};

	send(message: ISendMessage) {
		if (typeof message !== 'object') {
			console.error('[SOCKET] not an object:', message);
			return;
		}

		try {
			// queue the socket calls until it's actually connected
			if (!this.socket || !this.socket.connected) {
				this.queue.push(message);
				return;
			}

			log('[SOCKET] sending:', message);
			this.socket.emit('msg', message);
		} catch (e) {
			console.error('[SOCKET]', message, e);
		}
	}
}
