import React from 'react';
import {connect} from 'react-redux';
import {browserHistory as router} from 'react-router';

import * as RosterDuck from '../../ducks/roster';
import * as GuildDuck from '../../ducks/guild';

import {Guild} from '../../models/guild';

interface IProps {
	rosterId: number;
	guildId: number;
}

function redirect(guildId: number, rosterId: number) {
	const base = Guild.createBaseUrl(guildId);
	router.replace(`${base}/roster/${rosterId}`);
}

class RedirectToDefaultRosterContainer extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		if (props.rosterId) redirect(props.guildId, props.rosterId);
	}

	componentWillReceiveProps(newProps: IProps) {
		if (newProps.guildId && newProps.rosterId) {
			redirect(newProps.guildId, newProps.rosterId);
		}
	}

	render() {
		return null;
	}
}

function mapStateToProps(state: IRootState) {
	const guildId = GuildDuck.getActiveGuildId(state.guilds)!;
	const rosters = RosterDuck.getRostersForGuild(state.rosters, guildId, true);

	return {
		rosterId: rosters[0]?.id,
		guildId
	};
}

export default connect(mapStateToProps)(RedirectToDefaultRosterContainer);
