import React from 'react';

import type {Difficulties} from '@constants/wow';

import {PageHeadingRow} from '../Utils/PageHeading';
import {RosterDetails} from './RosterDetails';
import {VersionSwitcher} from './VersionSwitcher';
import {CharacterSearch} from './CharacterSearch';
import {VersionHelp} from './VersionHelp';
import {Actions} from './Actions';

interface Props {
	onSearchCharacter(charId: CharacterId | null): void;
	onNavigateToRosterReorder(): void;
	onNavigateToRoster(): void;

	isAdmin: boolean;
	isDemo: boolean;

	canUseAssignmentsView: boolean;

	difficulty: Difficulties;
	rosterName: string;

	searchedCharacterId: CharacterId | null;
	rosterId: RosterId;
	guildId: GuildId;
}

export const RosterHeading: React.FC<Props> = (props) => {
	return (
		<>
			<PageHeadingRow className="roster-heading">
				<RosterDetails difficulty={props.difficulty} rosterName={props.rosterName} />

				<div className="right-group">
					{!props.isDemo && (
						<VersionSwitcher
							guildId={props.guildId}
							rosterId={props.rosterId}
							currentVersion="new"
						/>
					)}

					{props.canUseAssignmentsView && (
						<CharacterSearch
							onSearchCharacter={props.onSearchCharacter}
							searchedCharacterId={props.searchedCharacterId}
							guildId={props.guildId}
						/>
					)}

					<Actions
						onNavigateToRosterReorder={props.onNavigateToRosterReorder}
						onNavigateToRoster={props.onNavigateToRoster}
						isAdmin={props.isAdmin}
						isDemo={props.isDemo}
						rosterId={props.rosterId}
					/>
				</div>
			</PageHeadingRow>

			<VersionHelp />
		</>
	);
};
