import React from 'react';

import type {Props} from '.';
import {RoleGroup} from './RoleGroup';
import {SectionHeading} from '../../../Utils/SectionHeading';

export const ChangesSection: React.FC<Props> = (props) => {
	const roleGroupItems = props.roleChanges.map((data) => (
		<RoleGroup key={data.role} role={data.role} changes={data.changes} />
	));

	return (
		<div className="section-changes secondary-section">
			<SectionHeading heading="Changes between this boss and the previous" />

			<div className="role-groups">{roleGroupItems}</div>
		</div>
	);
};
