import React from 'react';

import {RealmList} from '@shared/constants/realms';
import {Regions} from '@constants/wow';

import {LabelledFilterSelect, ISelectOption} from '../../../Utils/FilterSelect';
import {Modal} from '../../../Utils/Modal';
import {Button} from '../../../Utils/Button';
import {Input} from '../../../Utils/Input';

const usRealms: ISelectOption[] = RealmList.us.map(
	(x): ISelectOption => ({
		label: x.name,
		value: x.name
	})
);
const euRealms: ISelectOption[] = RealmList.eu.map(
	(x): ISelectOption => ({
		label: x.name,
		value: x.name
	})
);

interface IProps {
	onClose(): void;
	onSubmit(data: {name: string; realm: string}): void;

	isSubmitting: boolean;
	region: Regions;
	name: string;
	realm: string;
}

interface IState {
	realm: string | null;
}

export class UpdateExternalCharacterModal extends React.Component<IProps, IState> {
	state: IState = {realm: this.props.realm};

	fields: {
		name: Input | null;
	} = {
		name: null
	};

	handleSubmit = () => {
		const characterName = this.fields.name?.getValue();
		const realm = this.state.realm;

		if (!characterName || !realm) return;

		this.props.onSubmit({
			name: characterName,
			realm
		});
	};

	handleRealmChange = (item: ISelectOption | null) => {
		this.setState({realm: item?.value || null});
	};

	render() {
		const realmOptions = this.props.region === Regions.US ? usRealms : euRealms;

		return (
			<Modal onClose={this.props.onClose}>
				<div className="modal-content no-overflow">
					<div className="heading">Update character</div>

					<div className="left description">
						<p>
							You may update this character to reflect that the character has
							changed their name or transferred to a different realm.
						</p>

						<p>
							Performing an update will keep the boss assignments and loot
							selections for this character.
						</p>
					</div>

					<div className="inputs">
						<Input
							ref={(r) => (this.fields.name = r)}
							autoFocus={true}
							defaultValue={this.props.name}
							label="Character name"
							size="big"
						/>

						<LabelledFilterSelect
							onChange={this.handleRealmChange}
							options={realmOptions}
							placeholder="Select realm"
							label="Realm"
							value={this.state.realm}
						/>
					</div>

					<div className="actions">
						<Button onClick={this.props.onClose} className="grey">
							Back
						</Button>

						<Button
							onClick={this.handleSubmit}
							isLoading={this.props.isSubmitting}
							className="primary"
						>
							Update character
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
}
