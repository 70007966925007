import {loadScript} from './clarity-snippet';

import type {User} from '../models/user';

let hasLoaded = false;

type Tag =
	| 'user_id'
	| 'is_using_setup'
	| 'is_using_billing'
	| 'is_using_reimport'
	| 'is_using_settings';

const pendingTags: Array<{tag: Tag; value: string}> = [];

export function setRecorderTag(key: Tag, value: string) {
	if (!hasLoaded) {
		pendingTags.push({
			tag: key,
			value
		});
	}

	if (window.clarity) window.clarity('set', key, value);
}

export function loadRecorder(user: User) {
	if (!hasLoaded) {
		loadScript();
		hasLoaded = true;

		pendingTags.push({
			tag: 'user_id',
			value: `${user.id}`
		});

		pendingTags.forEach(({tag, value}) => {
			setRecorderTag(tag, value);
		});
	}
}
