import type {UserBilling} from '@models/userBilling';

export interface IState {
	readonly userBillingByUserId: Record<number, UserBilling>;

	readonly subscribingToStripePriceId: string | null;
	readonly updatingToStripePriceId: string | null;

	readonly isUpdatingPayment: boolean;
	readonly isCancelling: boolean;
	readonly isRestoring: boolean;
}

export const SUBSCRIBE = 'user-billing/SUBSCRIBE';
export const SUBSCRIBE_SUCCESS = 'user-billing/SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'user-billing/SUBSCRIBE_FAILURE';

export const UPDATE_PAYMENT = 'user-billing/UPDATE_PAYMENT';
export const UPDATE_PAYMENT_SUCCESS = 'user-billing/UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILURE = 'user-billing/UPDATE_PAYMENT_FAILURE';

export const UPDATE_PLAN = 'user-billing/UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'user-billing/UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILURE = 'user-billing/UPDATE_PLAN_FAILURE';

export const CANCEL = 'user-billing/CANCEL';
export const CANCEL_SUCCESS = 'user-billing/CANCEL_SUCCESS';
export const CANCEL_FAILURE = 'user-billing/CANCEL_FAILURE';

export const RESTORE = 'user-billing/RESTORE';
export const RESTORE_SUCCESS = 'user-billing/RESTORE_SUCCESS';
export const RESTORE_FAILURE = 'user-billing/RESTORE_FAILURE';

export interface IActions {
	SUBSCRIBE: {
		readonly type: typeof SUBSCRIBE;
		readonly payload: {
			readonly stripePriceId: string;
		};
	};
	SUBSCRIBE_SUCCESS: {readonly type: typeof SUBSCRIBE_SUCCESS};
	SUBSCRIBE_FAILURE: {readonly type: typeof SUBSCRIBE_FAILURE};

	UPDATE_PAYMENT: {readonly type: typeof UPDATE_PAYMENT};
	UPDATE_PAYMENT_SUCCESS: {readonly type: typeof UPDATE_PAYMENT_SUCCESS};
	UPDATE_PAYMENT_FAILURE: {readonly type: typeof UPDATE_PAYMENT_FAILURE};

	UPDATE_PLAN: {
		readonly type: typeof UPDATE_PLAN;
		readonly payload: {
			readonly stripePriceId: string;
		};
	};
	UPDATE_PLAN_SUCCESS: {readonly type: typeof UPDATE_PLAN_SUCCESS};
	UPDATE_PLAN_FAILURE: {readonly type: typeof UPDATE_PLAN_FAILURE};

	CANCEL: {readonly type: typeof CANCEL};
	CANCEL_SUCCESS: {readonly type: typeof CANCEL_SUCCESS};
	CANCEL_FAILURE: {readonly type: typeof CANCEL_FAILURE};

	RESTORE: {readonly type: typeof RESTORE};
	RESTORE_SUCCESS: {readonly type: typeof RESTORE_SUCCESS};
	RESTORE_FAILURE: {readonly type: typeof RESTORE_FAILURE};
}
