import {connect} from 'react-redux';

import * as toolbox from '../../../helpers/toolbox';

import {
	GuildSponsorshipDuck,
	CharacterDuck,
	GuildDuck,
	TagDuck,
	SettingsDuck
} from '@ducks';

import type {TagAssignment} from '../../../models/tag-assignment';
import type {Tag} from '../../../models/tag';
import type {Character} from '@models/character';

import TagSettings, {IMapProps, IGroup} from './TagSettings';

interface IOwnProps {
	baseUrl: string;
	guildId: number;

	params: {
		tagId?: string;
	};
}

type TagAssignmentByCharacterId = Record<CharacterId, TagAssignment>;

enum StatusGroupId {
	TAGGED = 0,
	UNTAGGED = 1
}

function getGroups(
	characters: Character[],
	tagAssignmentsByCharacterId: TagAssignmentByCharacterId,
	rankNames: string[],
	isGroupingByStatus: boolean
): IGroup[] {
	const rankGroupMap: Record<number, IGroup> = {};

	if (isGroupingByStatus) {
		// add in the two groups that represent the two valid status'
		rankGroupMap[StatusGroupId.TAGGED] = {
			name: 'Tagged Characters',
			id: StatusGroupId.TAGGED,
			characterGroups: []
		};

		rankGroupMap[StatusGroupId.UNTAGGED] = {
			name: 'Untagged Characters',
			id: StatusGroupId.UNTAGGED,
			characterGroups: []
		};

		// add the characters to the group based on their tag assignments
		characters.forEach((char) => {
			const isTagged = !!tagAssignmentsByCharacterId[char.id];
			const groupId = isTagged ? StatusGroupId.TAGGED : StatusGroupId.UNTAGGED;

			rankGroupMap[groupId].characterGroups.push({
				isTagged,
				character: char
			});
		});

		return Object.values(rankGroupMap);
	}

	characters.forEach((char) => {
		if (!rankGroupMap[char.rank]) {
			rankGroupMap[char.rank] = {
				name: rankNames[char.rank],
				id: char.rank,
				characterGroups: []
			};
		}

		rankGroupMap[char.rank].characterGroups.push({
			isTagged: !!tagAssignmentsByCharacterId[char.id],
			character: char
		});
	});

	// sort the rank groups so GM goes first
	return Object.values(rankGroupMap).sort((a, b) => a.id - b.id);
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const isGroupingByStatus = SettingsDuck.getGuildSettingsGroupTagsByStatus(
		state.settings
	);

	const tags = TagDuck.getTagsForGuild(state.tag, props.guildId, true);
	const guild = GuildDuck.getActiveGuild(state.guilds);

	let selectedTag: Tag | undefined;
	if (props.params.tagId && props.params.tagId !== 'new') {
		const tagId = Number.parseInt(props.params.tagId, 10);
		selectedTag = tags.find((x) => x.id === tagId);
	}

	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		props.guildId
	);

	let groups: IGroup[] = [];

	if (guild && selectedTag) {
		const characters = CharacterDuck.getCharactersForGuild(
			state.characters,
			props.guildId
		).sort(toolbox.sortCharactersByName);

		const assignments = TagDuck.getAssignmentsByTagId(state.tag, selectedTag.id);
		const assignmentByCharId: {[key: number]: TagAssignment} = {};
		assignments.forEach((x) => (assignmentByCharId[x.characterId] = x));

		groups = getGroups(
			characters,
			assignmentByCharId,
			guild.fullRankNames,
			isGroupingByStatus
		);
	}

	return {
		canUseTagging: fc.canUseTagging(),

		isGroupingByStatus,
		isSubmitting: TagDuck.getTagsSubmitting(state.tag),
		isDeleting: TagDuck.getTagsDeleting(state.tag),

		baseUrl: `${props.baseUrl}/tags`,

		tag: selectedTag,
		groups,
		tags
	};
}

export default connect(mapStateToProps, {
	onGroupByStatus: SettingsDuck.setGuildSettingsGroupTagsByStatus,
	onReorder: TagDuck.reorderTag,
	onCreate: TagDuck.createTag,
	onUpdate: TagDuck.updateTag,
	onDelete: TagDuck.deleteTag,
	onTag: TagDuck.makeAssignment
})(TagSettings);
