import {router} from '@helpers/urls';
import * as LS from '@helpers/localstorage';

import {connect, UserDuck} from '@ducks';

import {Guild} from '@models/guild';

interface IMapProps {
	url: string;
}

type IProps = IMapProps;

const IndexRedirectComp: React.FC<IProps> = (props) => {
	router.replace(props.url);

	return null;
};

export const IndexRedirectContainer = connect<IMapProps, {}, {}>((state) => {
	const user = UserDuck.getActiveUser(state.user);
	const previousId = LS.getItem(`${user.id}.activeGuildId`);

	const url = previousId ? Guild.createBaseUrl(previousId) : '/select-guild';

	return {
		url
	};
}, {})(IndexRedirectComp);
