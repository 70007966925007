import React from 'react';
import classnames from 'classnames';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

import {composeImportCharacterCombo} from '../../helpers/toolbox';

import type * as ReimportDuck from '../../ducks/reimport';

import type {Character} from '../../models/character';

import type {IRankGroup} from './ReimportContainer';
import RankGroup from '../SetupGuild/RankGroup';

interface IReimportProps {
	onSubmit: typeof ReimportDuck.submit;

	isSubmitting: boolean;
	rankGroups: IRankGroup[];
	guildName: string;
}

interface IReimportState {
	importCharacterCombos: string[];
	importIds: number[];
}

export default class Reimport extends React.Component<IReimportProps, IReimportState> {
	state: IReimportState = {
		importCharacterCombos: [],
		importIds: []
	};

	handleSubmit = () => {
		this.props.onSubmit({
			importCharacterCombos: this.state.importCharacterCombos,
			importIds: this.state.importIds
		});
	};

	handleComboSelection = (data: {name: string; realm: string}) => {
		const combo = composeImportCharacterCombo(data);

		const importCharacterCombos = this.state.importCharacterCombos.includes(combo)
			? this.state.importCharacterCombos.filter((x) => x !== combo)
			: [...this.state.importCharacterCombos, combo];

		this.setState({importCharacterCombos});
	};

	handleIdSelection = (data: {id: number}) => {
		const importIds = this.state.importIds.includes(data.id)
			? this.state.importIds.filter((x) => x !== data.id)
			: [...this.state.importIds, data.id];

		this.setState({importIds});
	};

	handleMassSelection = (rank: number, isAdding: boolean) => {
		const importCombos = new Set(this.state.importCharacterCombos);
		const importIds = new Set(this.state.importIds);

		this.props.rankGroups.some((group) => {
			if (group.rank !== rank) return false;

			group.characters.forEach((char) => {
				if ((char as Character).id) {
					const c: Character = char as Character;

					if (isAdding) importIds.add(c.id);
					else importIds.delete(c.id);
				} else {
					const combo = composeImportCharacterCombo(char);

					if (isAdding) importCombos.add(combo);
					else importCombos.delete(combo);
				}
			});

			return true;
		});

		this.setState({
			importCharacterCombos: [...importCombos],
			importIds: [...importIds]
		});
	};

	renderButtons() {
		return (
			<Buttons
				onSubmit={this.handleSubmit}
				isSubmitting={this.props.isSubmitting}
				count={this.state.importCharacterCombos.length + this.state.importIds.length}
			/>
		);
	}

	render() {
		const ranks = this.props.rankGroups.map((group) => (
			<RankGroup
				key={group.rank}
				onMassSelection={this.handleMassSelection}
				onNewCharacterSelection={this.handleComboSelection}
				onExistingCharacterSelection={this.handleIdSelection}
				importingCombos={this.state.importCharacterCombos}
				importingIds={this.state.importIds}
				isRankEditingDisabled={true}
				characters={group.characters}
				rankName={group.rankName}
				rank={group.rank}
			/>
		));

		return (
			<div className="page-container reimport setup-guild-like-view">
				<div className="heading">
					<h1 className="big">Add to {this.props.guildName}</h1>

					<p>
						Select the characters that make up your raid team. The players that own
						selected characters will automatically be granted access to your guild
						on Readycheck.
					</p>

					{this.renderButtons()}
				</div>

				<div className="content">
					<div className="rank-groups">{ranks}</div>
				</div>

				{this.renderButtons()}
			</div>
		);
	}
}

interface IButtonProps {
	onSubmit(): void;

	isSubmitting: boolean;
	count: number;
}

function Buttons(props: IButtonProps) {
	const word = props.count === 1 ? 'character' : 'characters';
	const count = props.count ? `${props.count} ` : '';

	const buttonClass = classnames(
		{
			disabled: !count || props.isSubmitting
		},
		'large primary button'
	);

	return (
		<div className="submit-buttons">
			<LaddaButton
				onClick={props.onSubmit}
				className={buttonClass}
				loading={props.isSubmitting}
				data-style={ZOOM_OUT}
			>
				Import {count}
				{word}
			</LaddaButton>
		</div>
	);
}
