import type {Difficulties} from '@constants/wow';

import type {DroptimizerReportDuck} from '@ducks';

import type {DroptimizerReport, DroptimizerItem} from '@models/droptimizerReport';

/** Get whether a DroptimizerReport is being submitted for creation */
export function getIsSubmittingReport(state: DroptimizerReportDuck.State): boolean {
	return state.isSubmittingReport;
}

/** Get a list of droptimizer reports */
export function getDroptimizerReports(
	state: DroptimizerReportDuck.State
): readonly DroptimizerReport[] {
	return Object.values(state.reportById);
}

export function getItem(
	state: DroptimizerReportDuck.State,
	data: {
		difficulty: Difficulties;
		characterId: CharacterId;
		wowItemId: WowItemId;
	}
): DroptimizerItem | undefined {
	return state.itemStorageByDifficulty[data.difficulty]?.[data.characterId]?.[
		data.wowItemId
	];
}

export function getItemByWowItemIdForCharacter(
	state: DroptimizerReportDuck.State,
	data: {
		difficulty: Difficulties;
		characterId: CharacterId;
	}
): DroptimizerReportDuck.ItemByWowItemId | undefined {
	return state.itemStorageByDifficulty[data.difficulty]?.[data.characterId];
}
