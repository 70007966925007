import type {UserBillingDuck} from '@ducks';

import type {UserBilling} from '@models/userBilling';

export function getSubscribingToStripePriceId(state: UserBillingDuck.IState) {
	return state.subscribingToStripePriceId;
}

export function getUpdatingToStripePriceId(state: UserBillingDuck.IState) {
	return state.updatingToStripePriceId;
}

export function getIsUpdatingPayment(state: UserBillingDuck.IState) {
	return state.isUpdatingPayment;
}

export function getIsCancelling(state: UserBillingDuck.IState) {
	return state.isCancelling;
}

export function getIsRestoring(state: UserBillingDuck.IState) {
	return state.isRestoring;
}

export function getForUser(
	state: UserBillingDuck.IState,
	userId: number
): UserBilling | undefined {
	return state.userBillingByUserId[userId];
}
