import {TagDuck} from '@ducks';

function getUsedTagIds(tagState: IRootState['tag'], tagIds: TagId[]): TagId[] {
	return tagIds.filter((tagId) => {
		const tag = TagDuck.getTag(tagState, tagId);
		if (!tag || tag.isDeleted) return false;
		return true;
	});
}

function getTagCharacterIds(tagState: IRootState['tag'], tagIds: TagId[]): TagId[] {
	return tagIds.reduce<CharacterId[]>((charIds, tagId) => {
		const tagAssignments = TagDuck.getAssignmentsByTagId(tagState, tagId);
		const taggedCharacterIds = tagAssignments.map((x) => x.characterId);

		return [...charIds, ...taggedCharacterIds];
	}, []);
}

/** Retrieves the Character IDs of relevant included/excluded tags */
export function getTaggedCharacterIds(
	tagState: IRootState['tag'],
	data: {
		includedTagIds: TagId[];
		excludedTagIds: TagId[];
	}
): {
	includedTagCharacterIds: CharacterId[];
	excludedTagCharacterIds: CharacterId[];
} {
	const validIncludedTagIds = getUsedTagIds(tagState, data.includedTagIds);
	const validExcludedTagIds = getUsedTagIds(tagState, data.excludedTagIds);

	return {
		includedTagCharacterIds: getTagCharacterIds(tagState, validIncludedTagIds),
		excludedTagCharacterIds: getTagCharacterIds(tagState, validExcludedTagIds)
	};
}
