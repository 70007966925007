import type {Role} from '@constants/wow';

/**
 * Produces a Map<CharId, Role> for a boss' assignments that indicates
 * which role a certain character is assigned to
 *
 * Only characters that are assigned to a boss will have an entry
 */
export function getAssignedRoleByCharacterId(
	assignedCharacterIdsByRole: Record<Role, CharacterId[]>
): Map<CharacterId, Role> {
	const assignedRoleByCharacterId = new Map<CharacterId, Role>();

	Object.entries(assignedCharacterIdsByRole).forEach(([role, charIds]) => {
		charIds.forEach((charId) => {
			assignedRoleByCharacterId.set(charId, role as Role);
		});
	});

	return assignedRoleByCharacterId;
}
