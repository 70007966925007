import type {RosterViewVersion} from '@constants';

import type {IUserCharacter} from '../../shared/types';
export type {IUserCharacter};

import {Character} from './character';

export interface IUserClass {
	id: number;

	createdAt: number;

	bnetAccountId: string;
	bnetBattleTag: string;
	bnetRegion: string;

	readonly rosterViewVersion: RosterViewVersion;
	readonly hasViewedRosterRedesignHelp: boolean;

	hasValidBnetToken: boolean;

	characters: IUserCharacter[];
}

export class User implements IUserClass {
	readonly id: number;

	readonly createdAt: number;

	readonly bnetAccountId: string;
	readonly bnetBattleTag: string;
	readonly bnetRegion: string;

	readonly rosterViewVersion: RosterViewVersion;
	readonly hasViewedRosterRedesignHelp: boolean;

	hasValidBnetToken: boolean;

	readonly characters: IUserCharacter[];

	constructor(data: IUserClass) {
		if (!data) throw new Error('invalid user data');

		this.id = data.id;

		this.createdAt = data.createdAt;

		this.bnetAccountId = data.bnetAccountId;
		this.bnetBattleTag = data.bnetBattleTag;
		this.bnetRegion = data.bnetRegion;

		this.rosterViewVersion = data.rosterViewVersion;
		this.hasViewedRosterRedesignHelp = data.hasViewedRosterRedesignHelp;

		this.hasValidBnetToken = data.hasValidBnetToken;

		this.characters = data.characters;
	}

	createComboKeys() {
		return this.characters.map((combo) => Character.createComboKey(combo));
	}
}
