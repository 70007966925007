import React from 'react';
import {Link} from 'react-router';

interface IIs404 {
	name: string;
}

export default function Is404(props: IIs404) {
	return (
		<div className="page-container">
			<div className="content-card card">
				<div className="heading">
					<i className="red lnr lnr-sad" />

					<div>Error fetching guild</div>
				</div>

				<div className="content">
					<div className="description">
						<p>
							We were unable to retrieve information about <b>{props.name}</b> and
							its members. Please verify the details were correct or try again
							later.
						</p>
					</div>

					<div className="button-group">
						<Link to="/select-guild" className="large primary faded button">
							Select guild
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
