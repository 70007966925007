import React from 'react';

import {connect, CharacterDuck} from '@ducks';

import {sortCharactersByName} from '@helpers/toolbox';

import {ISelectOption, FilterSelect} from '../../Utils/FilterSelect';

interface OwnProps {
	onSearchCharacter(charId: CharacterId | null): void;

	searchedCharacterId: CharacterId | null;
	guildId: GuildId;
}

interface MapProps {
	options: ISelectOption[];
}

type Props = OwnProps & MapProps;

const CharacterSearchComponent: React.FC<Props> = (props) => {
	return (
		<div className="character-search">
			<FilterSelect
				onChange={(opt: ISelectOption | null) => props.onSearchCharacter(opt?.value)}
				size="regular"
				placeholder="Search for character"
				options={props.options}
				value={props.searchedCharacterId}
			/>
		</div>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	// This list of characters doesn't reflect the characters that are allowed to be used
	// in the Roster as it doesn't take into account the tagging feature
	const guildCharacters = CharacterDuck.getCharactersForGuild(
		state.characters,
		props.guildId,
		{
			withMissing: true
		}
	);

	return {
		options: guildCharacters
			.sort(sortCharactersByName)
			.map((character): ISelectOption => {
				return {
					label: character.name,
					value: character.id
				};
			})
	};
}

export const CharacterSearch = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(CharacterSearchComponent);
