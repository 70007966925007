import type {Difficulties} from '../../constants/wow';

import type {IImportedEntry} from './RclcView';

interface IRclcEntry {
	/** The name of the player winning the item. If not provided, the realm is assumed to be the same as your own. e.g. Ricardette-Area52 */
	player: string;

	/** The date of award in m/dd/yy format. */
	date: string;

	/** Time of the award in hh:mm:ss format. */
	time: string;

	itemID: number;

	/** e.g. item:184286::::::::60:270 */
	itemString: string;

	/** The clear text of the response */
	response: string;

	/** ID of the winner's response to the item. This number corresponds to the index of the response in the options menu, i.e. the default "Offspec/Greed" has ID 2. If a set of buttons have been created for that particular item group, then those responses are used. */
	responseID: string;

	/** Number of votes for the winner. */
	votes: number;

	/** The winner's class. Not strictly required, but can have undocumented effects if missing. e.g. "MONK" */
	class: string;

	/** Name-difficulty of the instance. e.g. "Castle Nathria-Heroic" */
	instance: string;

	/** Name of the encounter where the item was looted. e.g. "Stone Legion Generals" */
	boss: string;

	/** An item the winner was wearing when receiving the loot. e.g. "[Unbound Changeling]", empty string if no item  */
	gear1: string;

	/** Same as Gear1, but if there's two slots */
	gear2: string;

	/** True if the award is one of the award reasons. Otherwise empty or false. */
	isAwardReason: 'true' | 'false' | '';

	/** The winner's note as sent to the council. */
	note: string;

	/** Source of the loot (boss or playername). It's only really required as I need something to mark the end. */
	owner: string;
}

export function transformInputString(str: string): IImportedEntry[] {
	try {
		const json: IRclcEntry[] = JSON.parse(str);

		return json.map((rawEntry): IImportedEntry => {
			const [characterName, characterRealm] = rawEntry.player.split('-');

			const difficultySplits = rawEntry.instance.split('-');
			const rawDifficulty = difficultySplits[difficultySplits.length - 1];

			return {
				uniqueId: `${rawEntry.date}-${rawEntry.time}-${rawEntry.player}-${rawEntry.itemString}`,

				difficulty: rawDifficulty.toLowerCase() as Difficulties,
				selectionText: rawEntry.response,
				itemId: `${rawEntry.itemID}`,
				characterRealm,
				characterName
			};
		});
	} catch (e) {
		console.error('Import error:', e);

		throw new Error('Invalid data or unknown data format');
	}
}
