import type {Regions, Classes, Role} from '@constants/wow';

import * as images from '../helpers/images';
import * as toolbox from '../helpers/toolbox';

interface ICreateComboKey {
	region: string;
	guildRealm: string | undefined;
	guildName: string | undefined;
	realm: string;
	name: string;
}

export interface ICharacterData {
	bnetLastModified: string;

	combo: string;

	faction: string;
	name: string;
	realm: string;
	class: Classes;
	race: string;
	gender: string;
	level: number;
	achievementPoints: number;
	thumbnail: string;

	region: Regions;

	// as far as the ui is concerned all the characters are in guilds
	// XXX this no longer holds true for external characters
	guildRealm: string;
	guildName: string;
	rank: number;

	roles: Role[];
}

export interface ICharacterClass extends ICharacterData {
	id: CharacterId;
	isRoleConfirmed: boolean;
	isImported: boolean;
	isRostered: boolean;
	isMissing: boolean;
	readonly isExternal: boolean;

	lootUpdatedAt?: string;

	userId: UserId | null;

	guildId: GuildId;
}

export class Character implements ICharacterClass {
	readonly id: CharacterId;

	readonly isRoleConfirmed: boolean;
	readonly isImported: boolean;
	readonly isRostered: boolean;
	readonly isMissing: boolean;
	readonly isExternal: boolean;
	readonly combo: string;

	readonly lootUpdatedAt?: string;

	readonly userId: UserId | null;

	readonly bnetLastModified: string;

	readonly faction: string;
	readonly name: string;
	readonly realm: string;
	readonly class: Classes;
	readonly race: string;
	readonly gender: string;
	readonly level: number;
	readonly achievementPoints: number;
	readonly thumbnail: string;

	readonly region: Regions;
	readonly guildRealm: string;
	readonly guildName: string;
	readonly rank: number;
	readonly guildId: GuildId;

	readonly roles: Role[];

	constructor(data?: ICharacterClass) {
		if (!data) throw new Error('invalid character data');

		this.id = data.id;

		this.isRoleConfirmed = data.isRoleConfirmed;
		this.isImported = data.isImported;
		this.isRostered = data.isRostered;
		this.isMissing = data.isMissing;
		this.isExternal = data.isExternal;
		this.combo = data.combo;

		this.lootUpdatedAt = data.lootUpdatedAt;

		this.userId = data.userId;

		this.bnetLastModified = data.bnetLastModified;

		this.faction = data.faction;
		this.name = data.name;
		this.realm = data.realm;
		this.class = data.class;
		this.race = data.race;
		this.gender = data.gender;
		this.level = data.level;
		this.achievementPoints = data.achievementPoints;
		this.thumbnail = data.thumbnail;

		this.region = data.region;
		this.guildRealm = data.guildRealm || '';
		this.guildName = data.guildName || '';
		this.rank = data.rank;
		this.guildId = data.guildId;

		this.roles = data.roles;
	}

	getAvatarUrl() {
		if (this.guildId === 0) return images.getCharacterThumbUrl(this);

		return toolbox.makeAvatarUrl(this.thumbnail, this.region);
	}

	static createComboKey(combo: ICreateComboKey) {
		return toolbox.createCharacterCombo(combo);
	}
}
