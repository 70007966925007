import React from 'react';

import {SwitchField} from '../../Utils/Switch';

const SHOW_CONDUITS_FOR_IDS = new Set(['13224', '13561', '13742']);

interface IProps {
	onToggleDroptimizerRelativeGain(value: boolean): void;
	onToggleLootShowConduits(value: boolean): void;
	onToggleLootGroupBySlot(value: boolean): void;

	isShowingDroptimizerRelativeGain: boolean;
	isShowingGroupBySlot: boolean;
	isShowingConduits: boolean;
	wowInstanceId: string;
}

export const FilterItems: React.FC<IProps> = (props) => {
	return (
		<div className="filter-items">
			{SHOW_CONDUITS_FOR_IDS.has(props.wowInstanceId) && (
				<SwitchField
					onChange={props.onToggleLootShowConduits}
					isControlled={true}
					label="Show conduits"
					value={props.isShowingConduits}
				/>
			)}

			<SwitchField
				onChange={props.onToggleDroptimizerRelativeGain}
				isControlled={true}
				label="Display relative Droptimizer gain"
				value={props.isShowingDroptimizerRelativeGain}
			/>

			<SwitchField
				onChange={props.onToggleLootGroupBySlot}
				isControlled={true}
				label="Group by item slot"
				value={props.isShowingGroupBySlot}
			/>
		</div>
	);
};
