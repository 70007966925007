import React from 'react';
import classnames from 'classnames';

import type {Role} from '@constants/wow';

import type {Character} from '@models/character';

import {CharacterDetails} from './CharacterDetails';
import {MetaStats} from './MetaStats';
import {MetaIcons} from './MetaIcons';
import {Actions} from './Actions';

interface Props {
	onToggleAssignment(): void;
	onToggleLoot(): void;

	character: Character;

	/** Whether this role has met it's set limit */
	isAtRoleLimit: boolean;

	/** Whether the character is assigned to this boss */
	isAssigned: boolean;

	/** How many bosses this character has been assigned to in this roster */
	assignedBossCount: number;

	/** Whether the lootsheet should be classes as outdated */
	isLootsheetOutdated: boolean;
	/** The point at which the lootsheet was last updated */
	lootsheetAgeInDays: number;

	/** How many lootsheet selections for this character/boss combo */
	desiredItemsCount: number;

	/**
	 * If the character is already assigned to the boss as a different role,
	 * what is that role
	 */
	otherAssignedRole: Role | undefined;

	/** Alts of this character that are assigned to this boss */
	otherAssignedAltCharacters: Array<{
		role: Role;
		character: Character;
	}>;

	/** Whether kill count is enabled for this boss */
	isShowingKillCount: boolean;
	/** How many times this character has killed the boss on this difficulty */
	killCount: number | undefined;
	/** MS of last kill */
	lastKillAt: number | undefined;
}

export const Row: React.FC<Props> = (props) => {
	const rowClass = classnames(
		{
			assigned: props.isAssigned
		},
		'character-row'
	);

	return (
		<div className={rowClass}>
			<CharacterDetails
				isAssigned={props.isAssigned}
				name={props.character.name}
				class={props.character.class}
				avatarImageUrl={props.character.getAvatarUrl()}
			/>

			<div className="character-meta">
				<MetaStats
					onToggleLoot={props.onToggleLoot}
					characterName={props.character.name}
					desiredItemsCount={props.desiredItemsCount}
					totalAssignmentCount={props.assignedBossCount}
					isShowingKillCount={props.isShowingKillCount}
					killCount={props.killCount}
					lastKillAt={props.lastKillAt}
				/>

				<MetaIcons
					characterName={props.character.name}
					isLootsheetOutdated={props.isLootsheetOutdated}
					lootsheetAgeInDays={props.lootsheetAgeInDays}
					otherAssignedAltCharacters={props.otherAssignedAltCharacters}
					otherAssignedRole={props.otherAssignedRole}
				/>
			</div>

			<Actions
				onToggleAssignment={props.onToggleAssignment}
				isAtRoleLimit={props.isAtRoleLimit}
				isAssigned={props.isAssigned}
			/>
		</div>
	);
};
