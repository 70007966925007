import React from 'react';

import {makeAvatarUrl} from '@helpers/toolbox';

interface IProps {
	inGameSelectionText: string;
	thumbnail: string;
	region: string;
	name: string;
	class: string;
}

export const CharacterInfo: React.FC<IProps> = (props) => {
	return (
		<div className="character-info">
			<img src={makeAvatarUrl(props.thumbnail, props.region)} />

			<div className="details">
				<div className="name">{props.name}</div>

				<div className="secondary">{props.inGameSelectionText}</div>
			</div>
		</div>
	);
};
