import * as Feed from '@constants/feed';

import {RootAction, DroptimizerReportDuck, GuildDuck, DemoDuck} from '@ducks';

import {DroptimizerReport} from '@models/droptimizerReport';

function composeBaseItemStorage(): DroptimizerReportDuck.ItemStorageByDifficulty {
	return {
		normal: {},
		heroic: {},
		mythic: {}
	};
}

function composeItemsForReports(
	reportById: DroptimizerReportDuck.State['reportById']
): DroptimizerReportDuck.ItemStorageByDifficulty {
	const itemStorageByDifficulty = composeBaseItemStorage();

	Object.values(reportById).forEach((report) => {
		const existingDifficulty = itemStorageByDifficulty[report.difficulty] || {};

		// start with their existing items
		const characterItemsByWowItemId = existingDifficulty[report.characterId] || {};

		// add in items from this report
		report.items.forEach((item) => {
			const existingItem = characterItemsByWowItemId[item.wowItemId];

			// if there's already info for this item, only update it if new sim is better
			const shouldUpdate =
				!existingItem || existingItem.absoluteDpsGain < item.absoluteDpsGain;

			if (shouldUpdate) characterItemsByWowItemId[item.wowItemId] = item;
		});

		// update the state object
		itemStorageByDifficulty[report.difficulty] = {
			// re-include any items that already exist for this difficulty
			...existingDifficulty,

			[report.characterId]: characterItemsByWowItemId
		};
	});

	return itemStorageByDifficulty;
}

const initialState: DroptimizerReportDuck.State = {
	reportById: {},
	itemStorageByDifficulty: composeBaseItemStorage(),

	isSubmittingReport: false
};

export default function reducer(
	state = initialState,
	action: RootAction
): DroptimizerReportDuck.State {
	switch (action.type) {
		// guild payload
		case GuildDuck.FETCH_DATA_SUCCESS:
		case DemoDuck.FETCH_SUCCESS: {
			const newEntries = action.payload.droptimizerReports.map((report) => [
				report.id,
				report
			]);

			const reportById = Object.fromEntries(newEntries);

			return {
				...state,

				reportById,
				itemStorageByDifficulty: composeItemsForReports(reportById)
			};
		}

		// submit new report
		case DroptimizerReportDuck.SUBMIT: {
			return {...state, isSubmittingReport: true};
		}
		case DroptimizerReportDuck.SUBMIT_SUCCESS:
		case DroptimizerReportDuck.SUBMIT_FAILURE: {
			return {...state, isSubmittingReport: false};
		}

		// feed
		case Feed.DROPTIMIZER_REPORT_INSERT:
		case Feed.DROPTIMIZER_REPORT_UPDATE: {
			const reportById = {
				...state.reportById,
				[action.payload.newRecord.id]: new DroptimizerReport(action.payload.newRecord)
			};

			return {
				...state,

				reportById,
				itemStorageByDifficulty: composeItemsForReports(reportById)
			};
		}

		case Feed.DROPTIMIZER_REPORT_DELETE: {
			const {[action.payload.oldRecord.id]: deleted, ...reportById} = state.reportById;

			return {
				...state,

				reportById,
				itemStorageByDifficulty: composeItemsForReports(reportById)
			};
		}

		default:
			return state;
	}
}
