import React from 'react';

import {Difficulties, DifficultyDisplay} from '@constants/wow';

import {PageHeadingDetails} from '../Utils/PageHeading';

interface IProps {
	difficulty: Difficulties;
	rosterName: string;
}

export const RosterDetails: React.FC<IProps> = (props) => {
	return (
		<PageHeadingDetails
			heading={props.rosterName}
			subheading={DifficultyDisplay[props.difficulty].toUpperCase()}
		/>
	);
};
