import React from 'react';
import classnames from 'classnames';

import type {Role} from '@constants/wow';

import type {CooldownActionDuck} from '@ducks';

import type {CooldownAction} from '@models/cooldownAction';

import {AddAction} from './AddAction';
import {Action} from '../Action/Action';

interface ActionsProps {
	onUpdateActor: BoundThunk<typeof CooldownActionDuck['updateActorCooldownAction']>;
	onUpdateAbility: BoundThunk<typeof CooldownActionDuck['updateAbilityCooldownAction']>;
	onDeleteAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;
	onCreateAction(): Promise<void>;

	isEditor: boolean;

	rosterBossId: RosterBossId;
	rosteredRoleByCharacterId: Record<CharacterId, Role>;
	actions: CooldownAction[];
}

export const Actions: React.FC<ActionsProps> = (props) => {
	const actionItems = props.actions.map((action) => {
		return (
			<Action
				key={action.id}
				onUpdateActor={props.onUpdateActor}
				onUpdateAbility={props.onUpdateAbility}
				onDeleteAction={props.onDeleteAction}
				isEditor={props.isEditor}
				rosterBossId={props.rosterBossId}
				rosteredRoleByCharacterId={props.rosteredRoleByCharacterId}
				action={action}
			/>
		);
	});

	const actionItemsClass = classnames(
		{
			empty: !actionItems.length
		},
		'action-items'
	);

	return (
		<div className="col col-actions">
			<div className={actionItemsClass}>
				{actionItems}

				{props.isEditor && <AddAction onCreate={props.onCreateAction} />}
			</div>
		</div>
	);
};
