/**
 * This file is used as the root of the roster view for an actual guild.
 * RosterPage itself is shared between "demo" mode and "real" mode.
 */

import React from 'react';

import {RosterViewVersion} from '@constants';

import {
	rosterUrl,
	rosterOldUrl,
	guildSettingsRosterUrl,
	guildSettingsRosterReorderUrl,
	router
} from '@helpers/urls';

import {
	connect,
	RosterDuck,
	UserDuck,
	CharacterDuck,
	RaidDataDuck,
	RosterBossDuck,
	BossKillsDuck,
	CooldownSheetDuck,
	CooldownEventDuck,
	CooldownActionDuck
} from '@ducks';

import {usePrevious} from '@hooks/usePrevious';

import type {RaidInstance} from '@models/raid-data';
import type {Roster} from '@models/roster';
import type {Guild} from '@models/guild';

import {RosterPage} from './RosterPage';

interface IOwnProps {
	guild: Guild;

	params: {
		rosterId: string;
		rosterBossId?: string;
	};
}

interface IMapProps {
	isUpdatingNotes: boolean;
	isAdmin: boolean;

	disabledViewRedirect:
		| {isDisabled: false; redirectUrl: undefined}
		| {isDisabled: true; redirectUrl: string};

	isLegacyRosterMode: boolean;

	raidInstance: RaidInstance | undefined;
	roster: Roster | undefined;
}

interface IDispatchProps {
	onFetchCooldownSheet: BoundThunk<
		typeof CooldownSheetDuck['fetchCooldownSheetForRosterBoss']
	>;
	onCreateCooldownEvent: BoundThunk<typeof CooldownEventDuck['createCooldownEvent']>;
	onUpdateCooldownEventName: BoundThunk<
		typeof CooldownEventDuck['updateNameCooldownEvent']
	>;
	onUpdateCooldownEventTime: BoundThunk<
		typeof CooldownEventDuck['updateTimeCooldownEvent']
	>;
	onReorderCooldownEvent: BoundThunk<typeof CooldownEventDuck['reorderCooldownEvent']>;
	onDeleteCooldownEvent: BoundThunk<typeof CooldownEventDuck['deleteCooldownEvent']>;
	onCreateCooldownAction: BoundThunk<typeof CooldownActionDuck['createCooldownAction']>;
	onUpdateCooldownActionActor: BoundThunk<
		typeof CooldownActionDuck['updateActorCooldownAction']
	>;
	onUpdateCooldownActionAbility: BoundThunk<
		typeof CooldownActionDuck['updateAbilityCooldownAction']
	>;
	onDeleteCooldownAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;
	onFetchBossKills: BoundThunk<typeof BossKillsDuck['fetchForInstance']>;
	onUpdateNotes: BoundThunk<typeof RosterBossDuck.updateNotes>;
	onUpdateLimit: BoundThunk<typeof RosterBossDuck.updateLimit>;
	onUnrosterAll: BoundThunk<typeof RosterBossDuck['unrosterAll']>;
	onRoster: BoundThunk<typeof RosterBossDuck.rosterCharacter>;
	onEnterSettings: typeof UserDuck['setUrl'];
}

type IProps = IOwnProps & IMapProps & IDispatchProps;

function useFetchBossKills(
	raidInstance: RaidInstance | undefined,
	onFetchBossKills: (encounterInstanceId: string) => void
): void {
	const prev = usePrevious(raidInstance);

	React.useEffect((): void => {
		// only fetch kills if it's a new roster and kill counts are supproted
		if (
			!raidInstance ||
			!raidInstance.isKillCountSupported ||
			raidInstance.encounterInstanceId === prev?.encounterInstanceId
		) {
			return;
		}

		onFetchBossKills(raidInstance.encounterInstanceId);
	}, [raidInstance, prev, onFetchBossKills]);
}

const RosterCheck: React.FC<IProps> = (props) => {
	useFetchBossKills(props.raidInstance, props.onFetchBossKills);

	// handle the roster not existing
	if (!props.roster || !props.raidInstance) return null;

	// if the roster is disabled then redirect them
	if (props.disabledViewRedirect.isDisabled) {
		const redirectUrl = props.disabledViewRedirect.redirectUrl;
		setTimeout(() => router.push(redirectUrl), 10);
		return null;
	}

	// if the user is set to show the legacy roster then redirect them to that view
	if (props.isLegacyRosterMode) {
		const roster = props.roster;
		setTimeout(() => router.replace(rosterOldUrl(roster.guildId, roster.id)), 0);
		return null;
	}

	const routerRosterBossId =
		(props.params.rosterBossId && Number.parseInt(props.params.rosterBossId, 10)) ||
		null;

	function generateBossUrl(rosterBossId: RosterBossId) {
		if (!props.roster) return '/';

		return rosterUrl(props.roster.guildId, props.roster.id, rosterBossId);
	}

	return (
		<RosterPage
			onFetchCooldownSheet={props.onFetchCooldownSheet}
			onCreateCooldownEvent={props.onCreateCooldownEvent}
			onUpdateCooldownEventName={props.onUpdateCooldownEventName}
			onUpdateCooldownEventTime={props.onUpdateCooldownEventTime}
			onReorderCooldownEvent={props.onReorderCooldownEvent}
			onDeleteCooldownEvent={props.onDeleteCooldownEvent}
			onCreateCooldownAction={props.onCreateCooldownAction}
			onUpdateCooldownActionActor={props.onUpdateCooldownActionActor}
			onUpdateCooldownActionAbility={props.onUpdateCooldownActionAbility}
			onDeleteCooldownAction={props.onDeleteCooldownAction}
			onEnterSettings={props.onEnterSettings}
			onUpdateNotes={props.onUpdateNotes}
			onUpdateLimit={props.onUpdateLimit}
			onUnrosterAll={props.onUnrosterAll}
			onRoster={props.onRoster}
			generateBossUrl={generateBossUrl}
			isUpdatingNotes={props.isUpdatingNotes}
			isAdmin={props.isAdmin}
			isDemo={false}
			rosterSettingsUrl={guildSettingsRosterUrl(props.roster.guildId, props.roster.id)}
			rosterReorderUrl={guildSettingsRosterReorderUrl(
				props.roster.guildId,
				props.roster.id
			)}
			routerRosterBossId={routerRosterBossId}
			raidInstance={props.raidInstance}
			roster={props.roster}
		/>
	);
};

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const user = UserDuck.getActiveUser(state.user);

	const userCharacters = CharacterDuck.getAllCharactersForGuild(
		state.characters,
		props.guild.id
	);

	const isAdmin = props.guild.isUserAdmin(user, userCharacters);

	const rosterId = Number.parseInt(props.params.rosterId, 10);
	const roster = RosterDuck.getRoster(state.rosters, rosterId);

	const raidInstance = roster
		? RaidDataDuck.getRaidInstance(state.raidData, roster.wowInstanceId)
		: undefined;

	return {
		isUpdatingNotes: RosterBossDuck.getIsUpdatingNotes(state.rosterBosses),
		isAdmin,

		disabledViewRedirect: isAdmin
			? {isDisabled: false, redirectUrl: undefined}
			: props.guild.getDisabledViewInfo('roster'),

		isLegacyRosterMode: user.rosterViewVersion === RosterViewVersion.ONE,

		raidInstance,
		roster
	};
}

export const RealRosterPage = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onFetchCooldownSheet: CooldownSheetDuck.fetchCooldownSheetForRosterBoss,
		onCreateCooldownEvent: CooldownEventDuck.createCooldownEvent,
		onUpdateCooldownEventName: CooldownEventDuck.updateNameCooldownEvent,
		onUpdateCooldownEventTime: CooldownEventDuck.updateTimeCooldownEvent,
		onReorderCooldownEvent: CooldownEventDuck.reorderCooldownEvent,
		onDeleteCooldownEvent: CooldownEventDuck.deleteCooldownEvent,
		onCreateCooldownAction: CooldownActionDuck.createCooldownAction,
		onUpdateCooldownActionActor: CooldownActionDuck.updateActorCooldownAction,
		onUpdateCooldownActionAbility: CooldownActionDuck.updateAbilityCooldownAction,
		onDeleteCooldownAction: CooldownActionDuck.deleteCooldownAction,
		onFetchBossKills: BossKillsDuck.fetchForInstance,
		onUpdateNotes: RosterBossDuck.updateNotes,
		onUpdateLimit: RosterBossDuck.updateLimit,
		onUnrosterAll: RosterBossDuck.unrosterAll,
		onRoster: RosterBossDuck.rosterCharacter,
		onEnterSettings: UserDuck.setUrl
	}
)(RosterCheck);
