import React from 'react';
import {connect} from 'react-redux';

import * as BannerDuck from '../../ducks/banner';

import classnames from 'classnames';

interface IBannerProps {
	readonly banner?: BannerDuck.IBanner;
}

function Banner(props: IBannerProps) {
	if (!props.banner) {
		return null;
	}

	const bannerClass = classnames(
		{
			show: !!props.banner.message
		},
		props.banner.type,
		'status-banner'
	);

	return <div className={bannerClass}>{props.banner.message}</div>;
}

function mapStateToProps(state: IRootState) {
	return {
		banner: BannerDuck.getBanner(state.banners)
	};
}

export default connect(mapStateToProps)(Banner);
