import React from 'react';
import {connect} from 'react-redux';
import {browserHistory as router} from 'react-router';

import {DEFAULT_ADMIN_RANKS} from '../../constants';

import {
	sortCharactersByName,
	filterUserCharactersToGuild,
	composeImportCharacterCombo
} from '../../helpers/toolbox';

import * as GuildDuck from '../../ducks/guild';
import * as SetupDuck from '../../ducks/setup';
import * as UserDuck from '../../ducks/user';

import type {SetupCharacter, SetupGuild} from '../../models/setup';
import type {IUserCharacter} from '../../models/user';
import {Guild} from '../../models/guild';

import SetupGuildView from './SetupGuild';
import NotMember from './NotMember';
import IsLoading from './IsLoading';
import IsAlreadySetup from './IsAlreadySetup';
import Is404 from './Is404';

export interface IRankGroup {
	characters: SetupCharacter[];
	rank: number;
}

interface IParams {
	region: string;
	realm: string;
	name: string;
}

interface IOwnProps {
	params: IParams;
}

interface IDispatchProps {
	onSubmit: typeof SetupDuck.submitData;
	onReset: typeof SetupDuck.resetSetup;
	onFetch: typeof SetupDuck.fetchData;
}

interface IMapProps {
	isSubmitting?: boolean;
	isLoading?: boolean;
	isAlreadySetup?: boolean;
	is404?: boolean;

	ownCharacterNames?: string[];
	rankGroups?: IRankGroup[];
	guild?: SetupGuild;

	isLikelyAdmin?: boolean;
	isInitiationRequired?: boolean;
	isNotMember?: boolean;
}

type IProps = IDispatchProps & IMapProps & IOwnProps;

class SetupGuildContainer extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		props.onReset({
			region: props.params.region,
			realm: props.params.realm,
			name: props.params.name
		});

		if (!props.isNotMember && props.isInitiationRequired) {
			this.initiate();
		}
	}

	componentWillReceiveProps({isNotMember, isInitiationRequired}: IProps) {
		if (!isNotMember && isInitiationRequired && !this.props.isInitiationRequired) {
			this.initiate();
		}
	}

	initiate() {
		this.props.onFetch();
	}

	render() {
		if (this.props.isNotMember) return <NotMember />;
		if (this.props.isLoading) return <IsLoading name={this.props.params.name} />;
		if (this.props.isAlreadySetup) {
			return <IsAlreadySetup name={this.props.params.name} />;
		}
		if (this.props.is404) return <Is404 name={this.props.params.name} />;

		return (
			<SetupGuildView
				onSubmit={this.props.onSubmit}
				isSubmitting={!!this.props.isSubmitting}
				isLikelyAdmin={!!this.props.isLikelyAdmin}
				guildName={this.props.guild ? this.props.guild.name : ''}
				ownCharacterCombos={this.props.ownCharacterNames || []}
				rankGroups={this.props.rankGroups || []}
			/>
		);
	}
}

function makeRankGroups(characters: SetupCharacter[]) {
	const ranks: {[key: string]: IRankGroup} = {};

	characters.forEach((char) => {
		if (!ranks[char.rank]) {
			ranks[char.rank] = {
				rank: char.rank,
				characters: []
			};
		}

		ranks[char.rank].characters.push(char);
	});

	return Object.values(ranks).sort((a, b) => a.rank - b.rank);
}

function mapStateToProps(state: IRootState, props: IOwnProps) {
	const realm = decodeURIComponent(props.params.realm);
	const name = decodeURIComponent(props.params.name);
	const region = props.params.region;

	let r: IMapProps;

	const user = UserDuck.getUser(state.user);
	let userGuildCharacters: IUserCharacter[] = [];
	if (user) {
		userGuildCharacters = filterUserCharactersToGuild({
			characters: user.characters,
			guildRegion: region,
			guildRealm: realm,
			guildName: name
		});
	}

	if (!userGuildCharacters.length) {
		r = {isNotMember: true};
		return r;
	}

	const realGuild = GuildDuck.getGuildByDetails(state.guilds, {
		region,
		realm,
		name
	});

	if (realGuild) {
		window.setTimeout(() => {
			router.replace(Guild.createBaseUrl(realGuild.id));
		});
	}

	const isLoading = SetupDuck.getSetupLoading(state.setup);
	const guild = SetupDuck.getSetupGuild(state.setup);
	if (!guild) {
		r = {
			isAlreadySetup: SetupDuck.getSetupAlreadySetup(state.setup),
			is404: SetupDuck.getSetup404(state.setup),
			isInitiationRequired: true,
			isLoading
		};
		return r;
	}

	const characters = SetupDuck.getSetupMembers(state.setup);
	characters.sort(sortCharactersByName);

	const ownCharacterNames = userGuildCharacters.map((char) =>
		composeImportCharacterCombo(char)
	);
	const isLikelyAdmin = ownCharacterNames.some((userCharName) => {
		const char = characters.find((x) => x.name === userCharName);
		if (char && DEFAULT_ADMIN_RANKS.includes(char.rank)) return true;
		return false;
	});

	r = {
		isSubmitting: SetupDuck.getSetupSubmitting(state.setup),
		rankGroups: makeRankGroups(characters),
		isLoading,
		guild,

		ownCharacterNames,
		isLikelyAdmin
	};

	return r;
}

export default connect(mapStateToProps, {
	onSubmit: SetupDuck.submitData,
	onReset: SetupDuck.resetSetup,
	onFetch: SetupDuck.fetchData
})(SetupGuildContainer);
