import type {
	FetchCooldownSheetForBossInput,
	FetchCooldownSheetForBossResult
} from 'api-types';

import {CooldownSheetDuck, BannerDuck, Rpc} from '@ducks';

import api from '../../helpers/api';

import {CooldownSheet} from '@models/cooldownSheet';
import {CooldownEvent} from '@models/cooldownEvent';
import {CooldownAction} from '@models/cooldownAction';

/** Fetch a CooldownSheet for a given RosterBoss */
export function fetchCooldownSheetForRosterBoss(rosterBossId: RosterBossId): Thunk<void> {
	return (dispatchEvent) => {
		dispatchEvent<CooldownSheetDuck.Actions['FETCH_FOR_BOSS']>({
			type: CooldownSheetDuck.FETCH_FOR_BOSS,
			payload: {rosterBossId}
		});

		return api
			.call<FetchCooldownSheetForBossInput, FetchCooldownSheetForBossResult>(
				Rpc.COOLDOWN_SHEET_FETCH_FOR_BOSS,
				{
					rosterBossId
				}
			)
			.then(
				(message) => {
					dispatchEvent<CooldownSheetDuck.Actions['FETCH_FOR_BOSS_SUCCESS']>({
						type: CooldownSheetDuck.FETCH_FOR_BOSS_SUCCESS,
						payload: {
							rosterBossId,

							cooldownSheet: new CooldownSheet(message.data.cooldownSheet),
							cooldownEvents: message.data.cooldownEvents.map(
								(x) => new CooldownEvent(x)
							),
							cooldownActions: message.data.cooldownActions.map(
								(x) => new CooldownAction(x)
							)
						}
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownSheetDuck.Actions['FETCH_FOR_BOSS_FAILURE']>({
						type: CooldownSheetDuck.FETCH_FOR_BOSS_FAILURE,
						payload: {rosterBossId}
					});
				}
			);
	};
}
