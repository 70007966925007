import React from 'react';

import type {Props} from '.';
import {BuffTypes} from './data';

import {BuffGroup} from './BuffGroup';
import {BuffRow} from './BuffRow';

export const BuffSectionComp: React.FC<Props> = (props) => {
	const buffStam = props.buffByType.get(BuffTypes.BUFF_STAMINA);
	const buffAttackPower = props.buffByType.get(BuffTypes.BUFF_ATTACK_POWER);
	const buffInt = props.buffByType.get(BuffTypes.BUFF_INTELLECT);
	const buffVers = props.buffByType.get(BuffTypes.BUFF_VERSATILITY);
	const buffSkyfury = props.buffByType.get(BuffTypes.BUFF_SKYFURY);
	const debuffPhysicalDamage = props.buffByType.get(BuffTypes.DEBUFF_PHYSICAL_DAMAGE);
	const debuffMagicalDamage = props.buffByType.get(BuffTypes.DEBUFF_MAGICAL_DAMAGE);
	const debuffDamage = props.buffByType.get(BuffTypes.DEBUFF_DAMAGE);

	const utilLust = props.buffByType.get(BuffTypes.UTIL_LUST);
	const utilBattleRes = props.buffByType.get(BuffTypes.UTIL_BATTLE_REZ);
	const utilHealthstones = props.buffByType.get(BuffTypes.UTIL_HEALTHSTONES);
	const utilGateway = props.buffByType.get(BuffTypes.UTIL_WARLOCK_GATE);
	const utilRunSpeed = props.buffByType.get(BuffTypes.UTIL_RUN_SPEED);
	const utilRunSpeedCdr = props.buffByType.get(BuffTypes.UTIL_RUN_SPEED_CDR);
	const utilBossDamageReduction = props.buffByType.get(
		BuffTypes.UTIL_BOSS_DAMAGE_REDUCTION
	);

	const personalImmunities = props.buffByType.get(BuffTypes.PERSONAL_IMMUNITIES);
	const externalImmunities = props.buffByType.get(BuffTypes.EXTERNAL_IMMUNITIES);
	const raidDamageMitigation = props.buffByType.get(BuffTypes.RAID_DAMAGE_MITIGATION);

	return (
		<div className="section-buffs secondary-section">
			<BuffGroup sectionName="Buffs">
				{buffStam && <BuffRow buff={buffStam} />}
				{buffAttackPower && <BuffRow buff={buffAttackPower} />}
				{buffInt && <BuffRow buff={buffInt} />}
				{buffVers && <BuffRow buff={buffVers} />}
				{buffSkyfury && <BuffRow buff={buffSkyfury} />}
				{debuffPhysicalDamage && <BuffRow buff={debuffPhysicalDamage} />}
				{debuffMagicalDamage && <BuffRow buff={debuffMagicalDamage} />}
				{debuffDamage && <BuffRow buff={debuffDamage} />}
			</BuffGroup>

			<BuffGroup sectionName="Utilities">
				{utilLust && <BuffRow buff={utilLust} />}
				{utilBattleRes && <BuffRow buff={utilBattleRes} />}
				{utilHealthstones && <BuffRow buff={utilHealthstones} />}
				{utilGateway && <BuffRow buff={utilGateway} />}
				{utilRunSpeed && <BuffRow buff={utilRunSpeed} />}
				{utilRunSpeedCdr && <BuffRow buff={utilRunSpeedCdr} />}
				{utilBossDamageReduction && <BuffRow buff={utilBossDamageReduction} />}
			</BuffGroup>

			<BuffGroup sectionName="Cooldowns">
				{personalImmunities && <BuffRow buff={personalImmunities} />}
				{externalImmunities && <BuffRow buff={externalImmunities} />}
				{raidDamageMitigation && <BuffRow buff={raidDamageMitigation} />}
			</BuffGroup>
		</div>
	);
};
