import React from 'react';
import {
	components,
	OptionProps,
	InputProps,
	StylesConfig,
	GroupBase,
	OnChangeValue
} from 'react-select';
import Select, {CreatableProps} from 'react-select/creatable';

import type {Role} from '@constants/wow';

import {sortCharactersByName} from '@helpers/toolbox';
import {getRoleImageNewUrl} from '@helpers/images';

import {connect2, CharacterDuck, RosterBossDuck} from '@ducks';

import type {Character} from '@models/character';

import {Pane} from '@components/Utils/Pane';
import type {Size} from '@components/Utils/Input';
import {Image} from '@components/Utils/Image';

export const selectSize: Size = 'regular';

interface SelectOption {
	assignedRole: Role;
	character: Character;

	// needed for sorting names using existing helper
	characterName: string;

	// react select defaults
	label: string;
	value: number;
}

export function createCustomSelectComponents<Option>() {
	const selectStyleOverrides: StylesConfig<Option, false> = {
		control: (base) => ({...base, minWidth: 240, margin: 8}),
		menu: (base) => ({
			...base,
			position: 'relative',
			border: 'none !important',
			background: 'transparent !important',
			boxShadow: 'none !important'
		})
	};

	const formatCreateLabel: CreatableProps<
		Option,
		false,
		GroupBase<Option>
		// eslint-disable-next-line unicorn/consistent-function-scoping
	>['formatCreateLabel'] = (inputValue) => {
		return `Custom: "${inputValue}"`;
	};

	const CustomSelectInput: React.FC<InputProps<Option, false>> = (props) => {
		return (
			<components.Input
				{...props}
				className={`${props.className || ''} input ${selectSize}`}
			/>
		);
	};

	return {
		selectStyleOverrides,
		formatCreateLabel,
		CustomSelectInput
	};
}

const CustomReactSelect = createCustomSelectComponents<SelectOption>();

const CustomSelectOption: React.FC<OptionProps<SelectOption, false>> = (props) => {
	// since this is a creatable select we also need to handle the creation option case
	// eslint-disable-next-line no-underscore-dangle
	if ((props.data as {__isNew__?: unknown}).__isNew__) {
		return <components.Option {...props} />;
	}

	const {character, assignedRole} = props.data;

	return (
		<components.Option {...props}>
			<Image
				src={getRoleImageNewUrl(assignedRole)}
				className="action-select-option-image"
			/>

			<div className={`action-select-option-name wow-style text ${character.class}`}>
				{character.name}
			</div>
		</components.Option>
	);
};

interface OwnProps {
	onSelect(characterId: CharacterId): void;
	onFreeformText(text: string): void;
	onClose(): void;

	rosterBossId: RosterBossId;
}

interface StateProps {
	options: SelectOption[];
}

type ActorSelectProps = OwnProps & StateProps;

const ActorSelectComp: React.FC<ActorSelectProps> = (props) => {
	function handleCreate(text: string): void {
		if (!text || !text.trim()) return;
		props.onFreeformText(text);
	}

	function handleChange(value: OnChangeValue<SelectOption, false>): void {
		if (!value) return;
		props.onSelect(value.character.id);
	}

	return (
		<Pane onClose={props.onClose} className="action-field-select" withBorder={true}>
			<Select<SelectOption>
				onCreateOption={handleCreate}
				onChange={handleChange}
				className={`react-select ${selectSize}`}
				classNamePrefix="react-select-prefix"
				components={{
					DropdownIndicator: null,
					IndicatorSeparator: null,
					Input: CustomReactSelect.CustomSelectInput,
					Option: CustomSelectOption
				}}
				autoFocus={true}
				backspaceRemovesValue={false}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				menuIsOpen={true}
				options={props.options}
				placeholder="Select character"
				noOptionsMessage={() => 'No characters are assigned to this boss'}
				formatCreateLabel={CustomReactSelect.formatCreateLabel}
				styles={CustomReactSelect.selectStyleOverrides}
			/>
		</Pane>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const assignedCharacterIdsByRole = RosterBossDuck.getAssignmentsForBoss(
		state.rosterBosses,
		props.rosterBossId
	);

	const assignmentEntries = Object.entries(assignedCharacterIdsByRole) as [
		Role,
		CharacterId[]
	][];

	const options: SelectOption[] = assignmentEntries
		.reduce<SelectOption[]>((tempOptions, [role, characterIds]): SelectOption[] => {
			// create options for each of the characters in this role
			return characterIds.reduce<SelectOption[]>(
				(characterOptions, characterId): SelectOption[] => {
					// if there's no character then return the options we've already got
					const character = CharacterDuck.getCharacterForId(
						state.characters,
						characterId
					);
					if (!character) return characterOptions;

					return [
						...characterOptions,

						// make a new option for this character
						{
							assignedRole: role,
							character,

							characterName: character.name,

							label: character.name,
							value: character.id
						}
					];
				},
				tempOptions
			);
		}, [])
		.sort(sortCharactersByName);

	return {
		options
	};
}

export const ActorSelect = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: {};
}>(
	mapStateToProps,
	{}
)(ActorSelectComp);
