import React from 'react';

import {SelectInput, ISelectOption} from '../../Utils/SelectInput';

interface IProps {
	onSelect(optionId: number | undefined): void;

	selectLootOptions: ISelectOption[];
	selectedOptionId: number | undefined;
}

export const LootSelectionDropdown: React.FC<IProps> = (props) => {
	const neverOption: ISelectOption = {
		name: 'No longer needs item',
		id: 0
	};

	const options: ISelectOption[] = [neverOption, ...props.selectLootOptions];

	return (
		<SelectInput
			onChange={(optionId) => props.onSelect(optionId === 0 ? undefined : optionId)}
			items={options}
			className="selection-dropdown"
			selectedId={props.selectedOptionId || neverOption.id}
		>
			{props.children}
		</SelectInput>
	);
};
