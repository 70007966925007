import React from 'react';

import type {BossViewType} from '@constants';

import {BossHeadingRow} from '../../BossElements';
import {ViewSwitcher} from '../../ViewSwitcher';
import {BossDetails} from './BossDetails';
import {SectionToggles} from './SectionToggles';
import {Actions} from './Actions';

interface Props {
	onChangeView(view: BossViewType): void;
	onUnrosterAll(): Promise<void>;

	viewType: BossViewType;
	isAdmin: boolean;
	isDemo: boolean;

	rosterBossId: RosterBossId;
	rosterId: RosterId;
	bossName: string;
}

export const BossHeading: React.FC<Props> = (props) => {
	return (
		<BossHeadingRow
			leftColumn={
				<BossDetails rosterBossId={props.rosterBossId} bossName={props.bossName} />
			}
			rightColumn={
				<>
					<SectionToggles isAdmin={props.isAdmin} />

					<ViewSwitcher
						onChangeView={props.onChangeView}
						isAdmin={props.isAdmin}
						viewType={props.viewType}
					/>

					<Actions
						onUnrosterAll={props.onUnrosterAll}
						isAdmin={props.isAdmin}
						isDemo={props.isDemo}
						rosterBossId={props.rosterBossId}
						rosterId={props.rosterId}
						bossName={props.bossName}
					/>
				</>
			}
		/>
	);
};
