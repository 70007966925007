import React from 'react';

import HeaderContainer from './Header/HeaderContainer';

interface IProps {
	children: any;
}

export default function GeneralContainer(props: IProps) {
	return (
		<div className="page-container">
			<HeaderContainer isHeaderFixed={false} />

			{props.children}
		</div>
	);
}
