import type {TagAssignment} from '../../models/tag-assignment';
import type {Tag} from '../../models/tag';

export interface IState {
	readonly tagById: {
		readonly [key: number]: Tag;
		readonly [key: string]: Tag;
	};

	readonly assignmentsByTagId: {
		readonly [key: number]: TagAssignment[];
		readonly [key: string]: TagAssignment[];
	};

	readonly isSubmitting: boolean;
	readonly isDeleting: boolean;
}

export const CREATE = 'tag/CREATE';
export const CREATE_SUCCESS = 'tag/CREATE_SUCCESS';
export const CREATE_FAILURE = 'tag/CREATE_FAILURE';

export const UPDATE = 'tag/UPDATE';
export const UPDATE_SUCCESS = 'tag/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'tag/UPDATE_FAILURE';

export const DELETE = 'tag/DELETE';
export const DELETE_SUCCESS = 'tag/DELETE_SUCCESS';
export const DELETE_FAILURE = 'tag/DELETE_FAILURE';

export const REORDER = 'tag/REORDER';
export const REORDER_SUCCESS = 'tag/REORDER_SUCCESS';
export const REORDER_FAILURE = 'tag/REORDER_FAILURE';

export const ASSIGNMENT = 'tag-assignment/ASSIGNMENT';
export const ASSIGNMENT_REMOVE = 'tag-assignment/ASSIGNMENT_REMOVE';
export const ASSIGNMENT_SUCCESS = 'tag-assignment/ASSIGNMENT_SUCCESS';
export const ASSIGNMENT_FAILURE = 'tag-assignment/ASSIGNMENT_FAILURE';

export interface IActions {
	CREATE: {readonly type: typeof CREATE};
	CREATE_SUCCESS: {readonly type: typeof CREATE_SUCCESS};
	CREATE_FAILURE: {readonly type: typeof CREATE_FAILURE};

	UPDATE: {readonly type: typeof UPDATE};
	UPDATE_SUCCESS: {readonly type: typeof UPDATE_SUCCESS};
	UPDATE_FAILURE: {readonly type: typeof UPDATE_FAILURE};

	DELETE: {readonly type: typeof DELETE};
	DELETE_SUCCESS: {readonly type: typeof DELETE_SUCCESS};
	DELETE_FAILURE: {readonly type: typeof DELETE_FAILURE};

	REORDER: {
		readonly type: typeof REORDER;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly tag: Tag;
		};
	};
	REORDER_SUCCESS: {
		readonly type: typeof REORDER_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	REORDER_FAILURE: {
		readonly type: typeof REORDER_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	ASSIGNMENT: {
		readonly type: typeof ASSIGNMENT;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly assignment: TagAssignment;
		};
	};
	ASSIGNMENT_REMOVE: {
		readonly type: typeof ASSIGNMENT_REMOVE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly assignment: TagAssignment;
		};
	};
	ASSIGNMENT_SUCCESS: {
		readonly type: typeof ASSIGNMENT_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	ASSIGNMENT_FAILURE: {
		readonly type: typeof ASSIGNMENT_FAILURE;
		readonly optimist: Optimist.IRevert;
	};
}
