import * as Feed from '@constants/feed';

import {RootAction, UserBillingDuck, UserDuck} from '@ducks';

import {UserBilling} from '@models/userBilling';

const initialState: UserBillingDuck.IState = {
	userBillingByUserId: {},

	subscribingToStripePriceId: null,
	updatingToStripePriceId: null,

	isUpdatingPayment: false,
	isCancelling: false,
	isRestoring: false
};

export default function reducer(
	state = initialState,
	action: RootAction
): UserBillingDuck.IState {
	switch (action.type) {
		// load inital records
		case UserDuck.FETCH_SUCCESS: {
			const {billing} = action.payload;
			if (!billing) return state;

			return {
				...state,

				userBillingByUserId: {
					...state.userBillingByUserId,
					[billing.userId]: billing
				}
			};
		}

		// subscribe start
		case UserBillingDuck.SUBSCRIBE: {
			return {
				...state,
				subscribingToStripePriceId: action.payload.stripePriceId
			};
		}

		case UserBillingDuck.SUBSCRIBE_SUCCESS:
		case UserBillingDuck.SUBSCRIBE_FAILURE: {
			return {
				...state,
				subscribingToStripePriceId: null
			};
		}

		// update plan
		case UserBillingDuck.UPDATE_PLAN: {
			return {
				...state,
				updatingToStripePriceId: action.payload.stripePriceId
			};
		}

		case UserBillingDuck.UPDATE_PLAN_SUCCESS:
		case UserBillingDuck.UPDATE_PLAN_FAILURE: {
			return {
				...state,
				updatingToStripePriceId: null
			};
		}

		// update-payment start
		case UserBillingDuck.UPDATE_PAYMENT: {
			return {
				...state,
				isUpdatingPayment: true
			};
		}

		case UserBillingDuck.UPDATE_PAYMENT_SUCCESS:
		case UserBillingDuck.UPDATE_PAYMENT_FAILURE: {
			return {
				...state,
				isUpdatingPayment: false
			};
		}

		// cancel
		case UserBillingDuck.CANCEL: {
			return {
				...state,
				isCancelling: true
			};
		}

		case UserBillingDuck.CANCEL_SUCCESS:
		case UserBillingDuck.CANCEL_FAILURE: {
			return {
				...state,
				isCancelling: false
			};
		}

		// restore
		case UserBillingDuck.RESTORE: {
			return {
				...state,
				isRestoring: true
			};
		}

		case UserBillingDuck.RESTORE_SUCCESS:
		case UserBillingDuck.RESTORE_FAILURE: {
			return {
				...state,
				isRestoring: false
			};
		}

		// feed
		case Feed.USER_BILLING_INSERT:
		case Feed.USER_BILLING_UPDATE: {
			return {
				...state,

				userBillingByUserId: {
					...state.userBillingByUserId,
					[action.payload.newRecord.userId]: new UserBilling(
						action.payload.newRecord
					)
				}
			};
		}

		// don't handle feed deletions as they won't be deleted

		default:
			return state;
	}
}
