import React from 'react';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import classnames from 'classnames';

import Modal from '../../Utils/Modal';

interface IProps {
	onClose(): void;
	onDelete(): void;

	isDeleting: boolean;

	optionName: string;
}

export default function DeleteRoster(props: IProps) {
	const cancelClass = classnames(
		{
			disabled: props.isDeleting
		},
		'grey button'
	);

	const deleteClass = classnames(
		{
			disabled: props.isDeleting
		},
		'red button'
	);

	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content">
				<div className="heading">Delete loot option</div>

				<div className="description">
					<span>Are you sure you want to delete the </span>
					<strong>{props.optionName}</strong>
					<span> loot option? This action cannot be undone.</span>
				</div>

				<div className="actions">
					<div onClick={props.onClose} className={cancelClass}>
						Cancel
					</div>

					<LaddaButton
						onClick={props.onDelete}
						className={deleteClass}
						loading={props.isDeleting}
						data-style={ZOOM_OUT}
					>
						Delete
					</LaddaButton>
				</div>
			</div>
		</Modal>
	);
}
