import React from 'react';

import {
	pricingData,
	ProductTypeLabels,
	ProductTypeDescriptions,
	ProductTypeButtonLabels
} from '@shared/billing/plans';

import {FeatureTable} from '../FeatureTable';
import {Plan} from './Plan';

interface IProps {
	onSelect(stripePriceId: string): void;

	actioningStripePriceId: string | null;
	currentPriceId: string | null;
}

export const PlanSelection: React.FC<IProps> = (props) => {
	const planItems = pricingData.map((plan) => (
		<Plan
			key={plan.id}
			onSelect={() => props.onSelect(plan.id)}
			isCurrent={props.currentPriceId === plan.id}
			isLoading={props.actioningStripePriceId === plan.id}
			isDisabled={
				!!props.actioningStripePriceId && props.actioningStripePriceId !== plan.id
			}
			title={ProductTypeLabels[plan.type]}
			description={ProductTypeDescriptions[plan.type]}
			cta={ProductTypeButtonLabels[plan.type]}
			priceText={plan.priceText}
			period="mo"
		/>
	));

	return (
		<div className="plan-selection">
			<div className="plans">{planItems}</div>
			<div className="currency-text">All prices are in USD</div>

			<div className="feature-table-wrapper">
				<div className="perks-heading">
					Unlock the following perks for everyone in your guild
				</div>

				<FeatureTable />
			</div>
		</div>
	);
};
