export * from '../../shared/constants';

import {BillingStatus} from '../../shared/constants';

export const BillingStatusDisplay: Record<BillingStatus, string> = {
	[BillingStatus.TRIALLING]: 'Trialing',
	[BillingStatus.CANCELLED]: 'Canceled',
	[BillingStatus.PAST_DUE]: 'Past due',
	[BillingStatus.ACTIVE]: 'Active'
};

export enum BossViewType {
	ROSTER = 'roster',
	NOTES = 'notes',
	COOLDOWN_SHEET = 'cooldown_sheet'
}
