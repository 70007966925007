import React from 'react';

import {connect, SettingsDuck} from '@ducks';

import Switch from '../Utils/Switch';
import Modal from '../Utils/Modal';

interface IOwnProps {
	onClose(): void;
	names: string[];
}

interface IMapProps {
	isCsv: boolean;
}

interface IDispatchProps {
	onToggleCsv: BoundThunk<typeof SettingsDuck.setExportCharactersAsCsv>;
}

type IProps = IOwnProps & IMapProps & IDispatchProps;

const CopyRosteredModalComp: React.FC<IProps> = (props) => {
	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content copy-rostered-modal">
				<div className="heading">Rostered character names</div>

				<div className="inputs">
					<Switch
						onChange={(isCsv) => props.onToggleCsv(isCsv)}
						isControlled={true}
						label="Separate names with a comma"
						value={props.isCsv}
					/>

					<textarea
						className="input"
						value={props.names.join(props.isCsv ? ', ' : '\n')}
						autoFocus={true}
						readOnly={true}
					/>
				</div>
			</div>
		</Modal>
	);
};

function mapStateToProps(state: IRootState) {
	return {
		isCsv: SettingsDuck.getExportCharactersAsCsv(state.settings)
	};
}

export const CopyRosteredModal = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onToggleCsv: SettingsDuck.setExportCharactersAsCsv
	}
)(CopyRosteredModalComp);
