import React from 'react';

import * as wow from '../../constants/wow';

import * as images from '../../helpers/images';

interface ICharacterChange {
	id: number;
	class: string;
	name: string;
	change: 'added' | 'removed';
}
interface IChangeSet {
	[role: string]: ICharacterChange[];
}

interface IProps {
	changes: IChangeSet;
}

export default function RosterChanges(props: IProps) {
	let hasChanges = false;

	const groups = wow.ROLES_ORDERED.map((role) => {
		if (!props.changes[role] || !props.changes[role].length) {
			return null;
		}

		hasChanges = true;

		const changes = props.changes[role].map((char) => {
			return (
				<div key={char.id} className={`change-box ${char.change}`}>
					{char.name}
				</div>
			);
		});

		return (
			<div key={role} className="role">
				<div className="role-icon-wrapper">
					<img src={images.getRoleImageUrl(role)} />
				</div>

				<div className="content">
					<div className="changes">{changes}</div>
				</div>
			</div>
		);
	});

	if (!hasChanges) return null;

	return <div className="change-assignments">{groups}</div>;
}
