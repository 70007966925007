import React from 'react';
import classnames from 'classnames';
import useHover from 'ahooks/es/useHover';

import type {Buff} from '.';
import {BuffTypes} from './data';

import {BuffContributors} from './BuffContributors';

const BUFF_TYPE_DISPLAY: Record<BuffTypes, string> = {
	[BuffTypes.BUFF_STAMINA]: 'Stamina',
	[BuffTypes.BUFF_ATTACK_POWER]: 'Attack Power',
	[BuffTypes.BUFF_INTELLECT]: 'Intellect',
	[BuffTypes.BUFF_VERSATILITY]: 'Versatility',
	[BuffTypes.BUFF_SKYFURY]: 'Skyfury',
	[BuffTypes.DEBUFF_PHYSICAL_DAMAGE]: 'Physical Damage',
	[BuffTypes.DEBUFF_MAGICAL_DAMAGE]: 'Magical Damage',
	[BuffTypes.DEBUFF_DAMAGE]: 'Damage',

	[BuffTypes.UTIL_LUST]: 'Bloodlust',
	[BuffTypes.UTIL_BATTLE_REZ]: 'Battle Res',
	[BuffTypes.UTIL_HEALTHSTONES]: 'Healthstone',
	[BuffTypes.UTIL_WARLOCK_GATE]: 'Gateway',
	[BuffTypes.UTIL_RUN_SPEED]: 'Run Speed',
	[BuffTypes.UTIL_RUN_SPEED_CDR]: 'Run Speed CDR',
	[BuffTypes.UTIL_BOSS_DAMAGE_REDUCTION]: 'Boss Damage',

	[BuffTypes.PERSONAL_IMMUNITIES]: 'Personal Immunities',
	[BuffTypes.EXTERNAL_IMMUNITIES]: 'External Immunities',
	[BuffTypes.RAID_DAMAGE_MITIGATION]: 'Raid Damage Reduction'
};

const BUFF_TYPE_FORMAT: Record<BuffTypes, 'count' | 'boolean'> = {
	[BuffTypes.BUFF_STAMINA]: 'boolean',
	[BuffTypes.BUFF_ATTACK_POWER]: 'boolean',
	[BuffTypes.BUFF_INTELLECT]: 'boolean',
	[BuffTypes.BUFF_VERSATILITY]: 'boolean',
	[BuffTypes.BUFF_SKYFURY]: 'boolean',
	[BuffTypes.DEBUFF_PHYSICAL_DAMAGE]: 'boolean',
	[BuffTypes.DEBUFF_MAGICAL_DAMAGE]: 'boolean',
	[BuffTypes.DEBUFF_DAMAGE]: 'boolean',

	[BuffTypes.UTIL_LUST]: 'boolean',
	[BuffTypes.UTIL_BATTLE_REZ]: 'count',
	[BuffTypes.UTIL_HEALTHSTONES]: 'boolean',
	[BuffTypes.UTIL_WARLOCK_GATE]: 'count',
	[BuffTypes.UTIL_RUN_SPEED]: 'count',
	[BuffTypes.UTIL_RUN_SPEED_CDR]: 'boolean',
	[BuffTypes.UTIL_BOSS_DAMAGE_REDUCTION]: 'boolean',

	[BuffTypes.PERSONAL_IMMUNITIES]: 'count',
	[BuffTypes.EXTERNAL_IMMUNITIES]: 'count',
	[BuffTypes.RAID_DAMAGE_MITIGATION]: 'count'
};

function getBuffValueContent(data: {
	isCount: boolean;
	isAvailable: boolean;
	count: number;
}) {
	if (data.isCount) return data.count;

	if (data.isAvailable) return <i className="lnr lnr-check" />;
	return <i className="lnr lnr-cross" />;
}

interface BuffRowProps {
	buff: Buff;
}

export const BuffRow: React.FC<BuffRowProps> = (props) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const isHovering = useHover(ref);

	const name = BUFF_TYPE_DISPLAY[props.buff.type];

	const value = getBuffValueContent({
		isCount: BUFF_TYPE_FORMAT[props.buff.type] === 'count',
		isAvailable: props.buff.isAvailable,
		count: props.buff.count
	});

	const isActive = !!props.buff.count;
	const rowClass = classnames(
		{
			active: isActive,
			inactive: !isActive
		},
		'buff-row'
	);

	return (
		<div ref={ref} className={rowClass}>
			{isHovering && isActive && (
				<BuffContributors title={name} contributors={props.buff.contributors} />
			)}

			<div className="value">{value}</div>
			<div className="name">{name}</div>
		</div>
	);
};
