import React from 'react';
import classnames from 'classnames';
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda';

import * as wow from '../../../constants/wow';

import type * as CharacterDuck from '../../../ducks/character';

import type {Character} from '../../../models/character';

import type {IRankGroup} from './RemoveCharactersContainer';
import RevokeCharacter from './RevokeCharacter';

interface IProps {
	onRestore: typeof CharacterDuck.restore;
	onRevoke: typeof CharacterDuck.revoke;

	userCharacterIds: number[];
	restoringIds: number[];
	isRevoking: boolean;

	rankGroups: IRankGroup[];
	guildName: string;
}

interface IState {
	isAdminUnroster: boolean;
	isShowingRevoke: boolean;
	revokeName?: string;
	revokeId?: number;
}

export default class RemoveCharacters extends React.Component<IProps, IState> {
	state: IState = {
		isShowingRevoke: false,
		isAdminUnroster: false
	};

	componentWillReceiveProps(nextProps: IProps) {
		if (this.props.isRevoking && !nextProps.isRevoking) {
			this.setState({isShowingRevoke: false});
		}
	}

	handleSubmitRevoke = () => {
		this.props.onRevoke(this.state.revokeId!);
	};

	handleTriggerRevoke = (id: number, name: string, isAdmin: boolean) => {
		this.setState({
			isAdminUnroster: isAdmin,
			isShowingRevoke: true,
			revokeName: name,
			revokeId: id
		});
	};

	handleRestore = (id: number) => {
		this.props.onRestore(id);
	};

	render() {
		const groups = this.props.rankGroups.map((group) => (
			<RankGroup
				key={group.rank}
				onRevoke={this.handleTriggerRevoke}
				onRestore={this.handleRestore}
				isAdminRank={group.isAdmin}
				restoringIds={this.props.restoringIds}
				userCharacterIds={this.props.userCharacterIds}
				characters={group.characters}
				name={group.rankName}
			/>
		));

		return (
			<div className="settings-content remove-characters">
				{this.state.isShowingRevoke && (
					<RevokeCharacter
						onClose={() => this.setState({isShowingRevoke: false})}
						onSubmit={this.handleSubmitRevoke}
						isAdminUnroster={this.state.isAdminUnroster}
						isSubmitting={this.props.isRevoking}
						name={this.state.revokeName!}
					/>
				)}

				<div className="settings-heading">Revoke access / remove characters</div>

				<div className="help-block">
					<p>
						Only people who own one of the characters listed here, or a character
						from one of the admin ranks, will be granted access to{' '}
						<strong>{this.props.guildName}</strong>
						<span> on </span>
						<span className="brand-name">Readycheck</span>.
					</p>

					<p>
						If you no longer want someone to be able to view the content, if they no
						longer need viewing rights, or you no longer want a character to appear
						in the raid team - you may revoke their access by removing their
						character(s).
					</p>
				</div>

				<div className="rank-groups">{groups}</div>
			</div>
		);
	}
}

interface IRankGroupProps {
	onRevoke(id: number, name: string, isAdmin: boolean): void;
	onRestore(id: number): void;

	isAdminRank: boolean;

	userCharacterIds: number[];
	restoringIds: number[];
	characters: Character[];
	name: string;
}

function RankGroup(props: IRankGroupProps) {
	const characters = props.characters.map((char) => (
		<CharacterRow
			key={char.id}
			onRevoke={() => props.onRevoke(char.id, char.name, props.isAdminRank)}
			onRestore={() => props.onRestore(char.id)}
			isLastUserCharacter={
				props.userCharacterIds.length <= 1 && props.userCharacterIds[0] === char.id
			}
			isRestoring={props.restoringIds.includes(char.id)}
			isAdmin={props.isAdminRank}
			character={char}
		/>
	));

	return (
		<div className="rank-group">
			<div className="rank-info">
				<span className="rank-name">{props.name}</span>

				{props.isAdminRank && (
					<span className="admin-rank">
						Admin ranks always have access to Readycheck
					</span>
				)}
			</div>

			<div className="characters">{characters}</div>
		</div>
	);
}

interface ICharacterRowProps {
	onRevoke(): void;
	onRestore(): void;

	isLastUserCharacter: boolean;
	isRestoring: boolean;
	isAdmin: boolean;
	character: Character;
}

function CharacterRow(props: ICharacterRowProps) {
	const char = props.character;

	const characterClass = classnames(
		char.class,
		'settings-character-row wow-style bg-before'
	);

	const buttonClass = classnames(
		{
			disabled: props.isRestoring,
			'red outline': char.isRostered,
			primary: !char.isRostered
		},
		'button'
	);

	return (
		<div className={characterClass}>
			<div className="left">
				<img src={char.getAvatarUrl()} className="thumbnail" />

				<div className="details">
					<div className="name">{char.name}</div>

					<div className="secondary">{wow.CLASS_TO_DISPLAY[char.class]}</div>
				</div>
			</div>

			{!char.isRostered && (
				<LaddaButton
					onClick={props.onRestore}
					className={buttonClass}
					loading={props.isRestoring}
					data-style={EXPAND_RIGHT}
				>
					{props.isAdmin ? 'Add to Raid Team' : 'Restore Access'}
				</LaddaButton>
			)}

			{char.isRostered && (props.isAdmin || !props.isLastUserCharacter) && (
				<LaddaButton
					onClick={props.onRevoke}
					className={buttonClass}
					loading={props.isRestoring}
					data-style={EXPAND_RIGHT}
				>
					{props.isAdmin ? 'Remove from Raid Team' : 'Revoke Access'}
				</LaddaButton>
			)}

			{char.isRostered && !props.isAdmin && props.isLastUserCharacter && (
				<div className="last-character">Cannot revoke your last character</div>
			)}
		</div>
	);
}
