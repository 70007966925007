import React from 'react';

import {BillingStatusDisplay, BillingStatus} from '@constants';

import {IPricingData, ProductTypeLabels, FrequencyDisplay} from '@shared/billing/plans';

import {dates} from '@helpers/dates';

import {useModal} from '@hooks/useModal';

import type {ActiveUserBilling} from '@models/userBilling';

import {ButtonGroup, Button, LinkButton} from '../../Utils/Button';
import {CancelModal} from '../CancelModal';
import {RestoreModal} from './RestoreModal';

interface IFieldProps {
	fieldName: string;
	value: string;
}

const Field: React.FC<IFieldProps> = (props) => {
	return (
		<div className="manage-field">
			<div className="field-name">{props.fieldName}</div>
			<div className="field-value">{props.value}</div>
		</div>
	);
};

interface IProps {
	onUpdatePayment(): void;
	onCancel(): Promise<void>;
	onRestore(): Promise<void>;

	isUpdatingPayment: boolean;
	isCancelling: boolean;
	isRestoring: boolean;

	changePlanUrl: string;
	billing: ActiveUserBilling;
	pricingData: IPricingData;
}

export const Manage: React.FC<IProps> = (props) => {
	const cancelModal = useModal();
	const restoreModal = useModal();

	const formattedNext = props.billing.currentPeriodEndAt
		? dates(props.billing.currentPeriodEndAt).format('MMMM DD')
		: undefined;

	const isCancelled = props.billing.status === BillingStatus.CANCELLED;

	return (
		<>
			{cancelModal.isOpen && (
				<CancelModal
					onSubmit={() => props.onCancel().then(cancelModal.close)}
					onClose={cancelModal.close}
					isSubmitting={props.isCancelling}
				/>
			)}

			{restoreModal.isOpen && (
				<RestoreModal
					onSubmit={() => props.onRestore().then(restoreModal.close)}
					onClose={restoreModal.close}
					isSubmitting={props.isRestoring}
				/>
			)}

			<div className="manage-billing card">
				<div className="content">
					<div className="fields">
						<Field
							fieldName="Plan type"
							value={ProductTypeLabels[props.pricingData.type]}
						/>
						<Field
							fieldName="Status"
							value={BillingStatusDisplay[props.billing.status]}
						/>
						<Field fieldName="Price" value={`${props.pricingData.priceText} USD`} />
						<Field
							fieldName="Frequency"
							value={FrequencyDisplay[props.pricingData.frequency]}
						/>

						{formattedNext && !isCancelled && (
							<Field fieldName="Next charge" value={formattedNext} />
						)}

						{formattedNext && isCancelled && (
							<Field fieldName="Perks end" value={formattedNext} />
						)}
					</div>

					<ButtonGroup alignment="vertical">
						<Button
							onClick={props.onUpdatePayment}
							isLoading={props.isUpdatingPayment}
							className="fluid outline"
						>
							Update payment info
						</Button>

						<LinkButton to={props.changePlanUrl} className="fluid outline">
							Change plan
						</LinkButton>

						{isCancelled ? (
							<Button
								onClick={restoreModal.open}
								className="cancel-button fluid primary"
							>
								Restore subscription
							</Button>
						) : (
							<Button
								onClick={cancelModal.open}
								className="cancel-button fluid red outline"
							>
								Cancel subscription
							</Button>
						)}
					</ButtonGroup>
				</div>
			</div>
		</>
	);
};
