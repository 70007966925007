import React from 'react';

import {IPricingData, getPricingDataForStripePriceId} from '@shared/billing/plans';

import {userBillingChangePlanUrl} from '@helpers/urls';

import {UserBillingDuck, connect} from '@ducks';

import type {ActiveUserBilling} from '@models/userBilling';

import {ManageSponsorshipsContainer} from './ManageSponsorships/ManageSponsorshipsContainer';
import {FeatureTable} from '../FeatureTable';
import {Manage} from './Manage';

interface IOwnProps {
	billing: ActiveUserBilling;
}

interface IDispatchProps {
	onUpdatePayment: BoundThunk<typeof UserBillingDuck.updatePayment>;
	onRestore: BoundThunk<typeof UserBillingDuck.restore>;
	onCancel: BoundThunk<typeof UserBillingDuck.cancel>;
}

interface IMapProps {
	isUpdatingPayment: boolean;
	isCancelling: boolean;
	isRestoring: boolean;

	pricingData: IPricingData;
}

type IProps = IMapProps & IDispatchProps & IOwnProps;

const ExistingSubComp: React.FC<IProps> = (props) => {
	return (
		<>
			<div className="heading">
				<h1 className="big">Manage billing</h1>
			</div>

			<div className="existing-sub">
				<Manage
					onUpdatePayment={props.onUpdatePayment}
					onCancel={props.onCancel}
					onRestore={props.onRestore}
					isUpdatingPayment={props.isUpdatingPayment}
					isCancelling={props.isCancelling}
					isRestoring={props.isRestoring}
					changePlanUrl={userBillingChangePlanUrl()}
					billing={props.billing}
					pricingData={props.pricingData}
				/>

				{props.pricingData.canBuffGuilds && (
					<ManageSponsorshipsContainer
						billing={props.billing}
						pricingData={props.pricingData}
					/>
				)}

				<FeatureTable />
			</div>
		</>
	);
};

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const pricingData = getPricingDataForStripePriceId(props.billing.stripePriceId);

	return {
		isUpdatingPayment: UserBillingDuck.getIsUpdatingPayment(state.userBilling),
		isCancelling: UserBillingDuck.getIsCancelling(state.userBilling),
		isRestoring: UserBillingDuck.getIsRestoring(state.userBilling),

		pricingData
	};
}

export const ExistingSub = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onUpdatePayment: UserBillingDuck.updatePayment,
		onRestore: UserBillingDuck.restore,
		onCancel: UserBillingDuck.cancel
	}
)(ExistingSubComp);
