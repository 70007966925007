import type {Role} from '@constants/wow';

import {dates} from '@helpers/dates';

import type {DesiredItem, DesiredItemsByCharacterId} from './desiredItemsByCharacterId';
import type {AssignmentsByUserId} from './assignmentsByUserId';
import type {BossKillsByCharacterId} from './bossKillsByCharacterId';

import type {Character} from '@models/character';

function getOtherAssignedAltCharacters(
	character: Character,
	assignmentsByUserId: AssignmentsByUserId
): Array<{role: Role; character: Character}> {
	if (!character.userId) return [];

	const assignmentsForUserId = assignmentsByUserId.get(character.userId);
	if (!assignmentsForUserId) return [];

	// filter out this character
	return assignmentsForUserId.filter((x) => x.character.id !== character.id);
}

export interface CharacterRow {
	character: Character;

	/** Whether the character is assigned to this boss */
	isAssigned: boolean;

	/** How many bosses this character has been assigned to in this roster */
	assignedBossCount: number;

	/** Whether the lootsheet should be classes as outdated */
	isLootsheetOutdated: boolean;
	/** The point at which the lootsheet was last updated */
	lootsheetAgeInDays: number;

	/** The lootsheet selections for this character/boss combo */
	desiredItems: DesiredItem[];

	/**
	 * If the character is already assigned to the boss as a different role,
	 * what is that role
	 */
	otherAssignedRole: Role | undefined;

	/** Alts of this character that are assigned to this boss */
	otherAssignedAltCharacters: Array<{
		role: Role;
		character: Character;
	}>;

	/** How many times this character has killed the boss on this difficulty */
	killCount: number | undefined;
	/** MS of last kill */
	lastKillAt: number | undefined;
}

export function getCharacterRows(
	state: IRootState,
	data: {
		desiredItemsByCharacterId: DesiredItemsByCharacterId;
		bossKillsByCharacterId: BossKillsByCharacterId;
		assignedCountByCharacterId: Map<CharacterId, number>;
		assignedRoleByCharacterId: Map<CharacterId, Role>;
		assignmentsByUserId: AssignmentsByUserId;
		assignedCharacterIds: CharacterId[];
		characters: Character[];
		selectedRole: Role;
	}
): CharacterRow[] {
	return data.characters.map((char): CharacterRow => {
		const lootsheetAgeInDays = char.lootUpdatedAt
			? dates().diff(char.lootUpdatedAt, 'days')
			: 0;
		const isLootsheetOutdated = lootsheetAgeInDays >= 7;

		// work out if this character is assigned to a different role
		const assignedRole = data.assignedRoleByCharacterId.get(char.id);
		const otherAssignedRole =
			assignedRole && assignedRole !== data.selectedRole ? assignedRole : undefined;

		const bossKills = data.bossKillsByCharacterId.get(char.id);

		return {
			character: char,

			isAssigned: data.assignedCharacterIds.includes(char.id),

			assignedBossCount: data.assignedCountByCharacterId.get(char.id) || 0,

			isLootsheetOutdated,
			lootsheetAgeInDays,

			desiredItems: data.desiredItemsByCharacterId.get(char.id) || [],

			otherAssignedRole,

			otherAssignedAltCharacters: getOtherAssignedAltCharacters(
				char,
				data.assignmentsByUserId
			),

			killCount: bossKills?.count,
			lastKillAt: bossKills?.lastKillAt
		};
	});
}
