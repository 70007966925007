import type {Difficulties} from '@constants/wow';

import type {
	DroptimizerReport,
	DroptimizerReportId,
	DroptimizerItem
} from '@models/droptimizerReport';

export type ItemByWowItemId = Record<WowItemId, DroptimizerItem>;
export type ItemStorageByCharacterId = Record<CharacterId, ItemByWowItemId>;
export type ItemStorageByDifficulty = Record<Difficulties, ItemStorageByCharacterId>;

export interface State {
	readonly reportById: Record<DroptimizerReportId, DroptimizerReport>;
	readonly itemStorageByDifficulty: ItemStorageByDifficulty;

	readonly isSubmittingReport: boolean;
}

export const SUBMIT = 'droptimizer/SUBMIT';
export const SUBMIT_SUCCESS = 'droptimizer/SUBMIT_SUCCESS';
export const SUBMIT_FAILURE = 'droptimizer/SUBMIT_FAILURE';

export interface Actions {
	SUBMIT: {readonly type: typeof SUBMIT};
	SUBMIT_SUCCESS: {readonly type: typeof SUBMIT_SUCCESS};
	SUBMIT_FAILURE: {readonly type: typeof SUBMIT_FAILURE};
}
