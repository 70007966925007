import {connect} from 'react-redux';

import {GuildSponsorshipDuck, LootDuck} from '@ducks';

import type {LootOption} from '../../../models/loot-option';

import LootSettings, {IMapProps} from './LootSettings';

interface IOwnProps {
	baseUrl: string;
	guildId: number;

	params: {
		optionId?: string;
	};
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const options = LootDuck.getOptionsForGuild(state.loot, props.guildId, true);

	let selectedOption: LootOption | undefined;
	if (props.params.optionId && props.params.optionId !== 'new') {
		const optionId = Number.parseInt(props.params.optionId, 10);
		selectedOption = options.find((opt) => opt.id === optionId);
	}

	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		props.guildId
	);

	return {
		canUseCustomLootOptions: fc.canUseCustomLootOptions(),

		isSubmitting: LootDuck.getOptionsSubmitting(state.loot),
		isDeleting: LootDuck.getOptionsDeleting(state.loot),

		baseUrl: `${props.baseUrl}/loot-options`,
		option: selectedOption,
		options
	};
}

export default connect(mapStateToProps, {
	onReorder: LootDuck.reorderOption,
	onCreate: LootDuck.createOption,
	onUpdate: LootDuck.updateOption,
	onDelete: LootDuck.deleteOption
})(LootSettings);
