import React from 'react';
import classnames from 'classnames';

interface ISwitchProps {
	onChange?(isSwitched: boolean): void;

	withTopMargin?: boolean;

	label: string;
	note?: any;
	defaultValue?: boolean;
	value?: boolean;

	textOn?: string;
	textOff?: string;

	isControlled?: boolean;
	isDisabled?: boolean;
}

interface IState {
	isSwitched: boolean;
}

export default class SwitchField extends React.Component<ISwitchProps, IState> {
	state: IState;

	constructor(props: ISwitchProps) {
		super(props);

		if (!props.isControlled) {
			this.state = {
				isSwitched: !!this.props.defaultValue
			};
		}
	}

	getValidationValue() {
		return true;
	}

	getValue() {
		return this.props.isControlled ? this.props.value : this.state.isSwitched;
	}

	setValue(isSwitched: boolean) {
		this.setState({isSwitched});
	}

	handleChange = () => {
		const isSwitched = !this.getValue();

		if (this.props.isControlled) {
			if (this.props.onChange) this.props.onChange(isSwitched);
		} else {
			this.setState({isSwitched}, () => {
				if (this.props.onChange) this.props.onChange(isSwitched);
			});
		}
	};

	render() {
		const isSwitched = this.getValue();

		const switchClassName = classnames(
			{
				'with-top-margin': this.props.withTopMargin
			},
			'switch-field'
		);

		const buttonClassName = classnames(
			{
				disabled: this.props.isDisabled,
				switched: isSwitched
			},
			'switch-button'
		);

		return (
			<div className={switchClassName}>
				<div className="switch-top">
					<div className="label">{this.props.label}</div>

					<div onClick={this.handleChange} className={buttonClassName}>
						<input type="checkbox" value={String(isSwitched)} />

						<label
							data-on-text={this.props.textOn}
							data-off-text={this.props.textOff}
						/>
					</div>
				</div>

				{this.props.note && <div className="switch-bottom">{this.props.note}</div>}
			</div>
		);
	}
}

export {SwitchField};
