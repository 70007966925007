import {RosterBossDuck} from '@ducks';

/** Determines how many bosses each character is assigned to for a given roster */
export function getTotalAssignmentCounts(
	rosterBossState: IRootState['rosterBosses'],
	rosterId: RosterId
): Map<CharacterId, number> {
	const assignedCountByCharacterId = new Map<CharacterId, number>();

	const rosterBosses = RosterBossDuck.getBossesForRoster(rosterBossState, rosterId);

	rosterBosses.forEach((rosterBoss) => {
		const assignedCharIdsByRole = RosterBossDuck.getAssignmentsForBoss(
			rosterBossState,
			rosterBoss.id
		);

		Object.values(assignedCharIdsByRole).forEach((charIds) => {
			charIds.forEach((charId) => {
				assignedCountByCharacterId.set(
					charId,
					// increment the current value
					(assignedCountByCharacterId.get(charId) || 0) + 1
				);
			});
		});
	});

	return assignedCountByCharacterId;
}
