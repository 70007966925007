import type {
	CreateCooldownActionInput,
	CreateCooldownActionResult,
	UpdateActorCooldownActionInput,
	UpdateActorCooldownActionResult,
	UpdateAbilityCooldownActionInput,
	UpdateAbilityCooldownActionResult,
	DeleteCooldownActionInput,
	DeleteCooldownActionResult
} from 'api-types';

import {CooldownActionDuck, BannerDuck, Rpc} from '@ducks';

import {optimistic} from '../../helpers/toolbox';
import api from '../../helpers/api';

import {CooldownAction, CooldownActionId} from '@models/cooldownAction';
import type {CooldownEventId} from '@models/cooldownEvent';

/** Create a new CooldownAction on a CooldownEvent */
export function createCooldownAction(data: {
	cooldownEventId: CooldownEventId;
}): Thunk<void> {
	return (dispatchEvent) => {
		dispatchEvent<CooldownActionDuck.Actions['CREATE']>({
			type: CooldownActionDuck.CREATE
		});

		return api
			.call<CreateCooldownActionInput, CreateCooldownActionResult>(
				Rpc.COOLDOWN_ACTION_CREATE,
				{
					cooldownEventId: data.cooldownEventId
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownActionDuck.Actions['CREATE_SUCCESS']>({
						type: CooldownActionDuck.CREATE_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownActionDuck.Actions['CREATE_FAILURE']>({
						type: CooldownActionDuck.CREATE_FAILURE
					});
				}
			);
	};
}

/** Update the actor of a CooldownAction */
export function updateActorCooldownAction(data: {
	cooldownActionId: CooldownActionId;
	actorCharacterId: CharacterId | undefined;
	actorText: string | undefined;
}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();

		const cooldownAction = CooldownActionDuck.getCooldownActionForCooldownActionId(
			state.cooldownActions,
			data.cooldownActionId
		);
		if (!cooldownAction) return Promise.resolve();

		const optimisticId = optimistic.getId();
		dispatchEvent<CooldownActionDuck.Actions['UPDATE_ACTOR']>({
			...optimistic.begin(optimisticId),
			type: CooldownActionDuck.UPDATE_ACTOR,
			payload: {
				cooldownAction: new CooldownAction({
					...cooldownAction,
					actorCharacterId: data.actorCharacterId || null,
					// not strictly correct as it should be the character name if there is a
					// character being set, but it's only used as a fallback anyway
					actorText: data.actorText || null
				})
			}
		});

		return api
			.call<UpdateActorCooldownActionInput, UpdateActorCooldownActionResult>(
				Rpc.COOLDOWN_ACTION_UPDATE_ACTOR,
				{
					cooldownActionId: data.cooldownActionId,
					actorCharacterId: data.actorCharacterId,
					freeformActorText: data.actorText
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownActionDuck.Actions['UPDATE_ACTOR_SUCCESS']>({
						...optimistic.commit(optimisticId),
						type: CooldownActionDuck.UPDATE_ACTOR_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownActionDuck.Actions['UPDATE_ACTOR_FAILURE']>({
						...optimistic.revert(optimisticId),
						type: CooldownActionDuck.UPDATE_ACTOR_FAILURE
					});
				}
			);
	};
}

/** Update the ability of a CooldownAction */
export function updateAbilityCooldownAction(data: {
	cooldownActionId: CooldownActionId;
	wowAbilityId: WowAbilityId | undefined;
	abilityText: string | undefined;
}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();

		const cooldownAction = CooldownActionDuck.getCooldownActionForCooldownActionId(
			state.cooldownActions,
			data.cooldownActionId
		);
		if (!cooldownAction) return Promise.resolve();

		const optimisticId = optimistic.getId();
		dispatchEvent<CooldownActionDuck.Actions['UPDATE_ABILITY']>({
			...optimistic.begin(optimisticId),
			type: CooldownActionDuck.UPDATE_ABILITY,
			payload: {
				cooldownAction: new CooldownAction({
					...cooldownAction,
					wowAbilityId: data.wowAbilityId || null,
					// not strictly correct as it should be the ability name if there is an
					// ability being set, but it's only used as a fallback anyway
					abilityText: data.abilityText || null
				})
			}
		});

		return api
			.call<UpdateAbilityCooldownActionInput, UpdateAbilityCooldownActionResult>(
				Rpc.COOLDOWN_ACTION_UPDATE_ABILITY,
				{
					cooldownActionId: data.cooldownActionId,
					wowAbilityId: data.wowAbilityId,
					freeformAbilityText: data.abilityText
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownActionDuck.Actions['UPDATE_ABILITY_SUCCESS']>({
						...optimistic.commit(optimisticId),
						type: CooldownActionDuck.UPDATE_ABILITY_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownActionDuck.Actions['UPDATE_ABILITY_FAILURE']>({
						...optimistic.revert(optimisticId),
						type: CooldownActionDuck.UPDATE_ABILITY_FAILURE
					});
				}
			);
	};
}

/** Delete a CooldownAction */
export function deleteCooldownAction(data: {
	cooldownActionId: CooldownActionId;
}): Thunk<void> {
	return (dispatchEvent) => {
		const optimisticId = optimistic.getId();
		dispatchEvent<CooldownActionDuck.Actions['DELETE']>({
			...optimistic.begin(optimisticId),
			type: CooldownActionDuck.DELETE,
			payload: {cooldownActionId: data.cooldownActionId}
		});

		return api
			.call<DeleteCooldownActionInput, DeleteCooldownActionResult>(
				Rpc.COOLDOWN_ACTION_DELETE,
				{
					cooldownActionId: data.cooldownActionId
				}
			)
			.then(
				() => {
					dispatchEvent<CooldownActionDuck.Actions['DELETE_SUCCESS']>({
						...optimistic.commit(optimisticId),
						type: CooldownActionDuck.DELETE_SUCCESS
					});
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<CooldownActionDuck.Actions['DELETE_FAILURE']>({
						...optimistic.revert(optimisticId),
						type: CooldownActionDuck.DELETE_FAILURE
					});
				}
			);
	};
}
