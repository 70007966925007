import React from 'react';
import classnames from 'classnames';
import {browserHistory as router} from 'react-router';

import type {Role} from '../../constants/wow';
import type {BossViewType} from '@constants';

import * as images from '../../helpers/images';

import type * as RosterBossDuck from '../../ducks/roster-boss';
import type * as SettingsDuck from '../../ducks/settings';
import type * as UserDuck from '../../ducks/user';
import type {BossKillsDuck} from '../../ducks';

import type {Character} from '../../models/character';
import type {Roster} from '../../models/roster';

import image from '../../images/view-loot.png';

import type {IRosterViewBoss, ICharMetaMap} from './RosterContainer';
import {ISelectOption, FilterSelect} from '../Utils/FilterSelect';
import {BossShell} from './BossShell';
import {VersionSwitcher} from '@components/RosterNew/RosterHeading/VersionSwitcher';
import {Image} from '@components/Utils/Image';

export interface IRoleGroups {
	[key: string]: Character[];
}

interface ICharacterChange {
	id: number;
	class: string;
	name: string;
	change: 'added' | 'removed';
}

export interface IChangesByRole {
	[role: string]: ICharacterChange[];
}

interface IChanges {
	[bossId: number]: IChangesByRole;
}

export interface IRosterMapProps {
	isUpdatingNotes: boolean;
	isAdmin: boolean;
	isDemo: boolean;

	reorderRostersUrl: string;

	filterOptions: ISelectOption[];
	characterMetaMap: ICharMetaMap;
	bosses: IRosterViewBoss[];
	characterById: Map<number, Character>;
	roleGroups: IRoleGroups;
	changes?: IChanges;
	roster?: Roster;
	userId?: number;

	encounterInstanceId: string;
	isKillCountSupported: boolean;
	wowheadBonus: string;

	canUseAssignmentsView: boolean;
	canUseNotesView: boolean;

	isShowingAdminControls: boolean;
	isAlphabeticalSort: boolean;
	isShowingChanges: boolean;
	selectedRole: Role;

	initialBossView: BossViewType | undefined;
}

type IRosterProps = IRosterMapProps & {
	onEnterSettings: typeof UserDuck.setUrl;
	onFetchBossKills: typeof BossKillsDuck.fetchForInstance;
	onUpdateNotes: typeof RosterBossDuck.updateNotes;
	onUpdateLimit: typeof RosterBossDuck.updateLimit;
	onUnrosterAll: typeof RosterBossDuck.unrosterAll;
	onRoster: typeof RosterBossDuck.rosterCharacter;

	onToggleAdminControls: typeof SettingsDuck.setRosterIsShowingAdminControls;
	onToggleAlphabeticalSort: typeof SettingsDuck.setRosterIsAlphabeticalSort;
	onToggleChanges: typeof SettingsDuck.setRosterIsShowingChanges;
	onSelectRole: typeof SettingsDuck.setRosterSelectedRole;
};

interface IRosterState {
	bossViewType: BossViewType | undefined;
	searchId?: number;
}

export default class RosterView extends React.Component<IRosterProps, IRosterState> {
	state: IRosterState = {
		bossViewType: undefined
	};

	constructor(props: IRosterProps) {
		super(props);

		this.state.bossViewType = props.initialBossView;

		if (props.isKillCountSupported) {
			props.onFetchBossKills(props.encounterInstanceId);
		}
	}

	handleSelect = (opt: {value: number} | null) => {
		this.setState({searchId: opt ? opt.value : undefined});
	};

	handleSearch = (id: number) => {
		this.handleSelect({value: id});
	};

	handleToggleChanges = () => {
		this.props.onToggleChanges(!this.props.isShowingChanges);
	};

	handleToggleAdminControls = () => {
		this.props.onToggleAdminControls(!this.props.isShowingAdminControls);
	};

	handleToggleAlphabeticalSort = () => {
		this.props.onToggleAlphabeticalSort(!this.props.isAlphabeticalSort);
	};

	handleChangeTab = (role: Role) => {
		this.props.onSelectRole(role);
	};

	handleGoToReorder = () => {
		this.props.onEnterSettings(
			window.location.href.slice(window.location.origin.length)
		);
		router.push(this.props.reorderRostersUrl);
	};

	render() {
		if (!this.props.roster) return false;

		let bossList = this.props.bosses;
		let character: Character | undefined;

		if (this.state.searchId) {
			character = this.props.characterById.get(this.state.searchId);

			if (!character) bossList = [];
			else {
				// filter out any bosses that don't contain this character
				bossList = bossList.filter((boss) =>
					Object.values(boss.roleAssignments).some((assignments) =>
						assignments.includes(character!.id)
					)
				);
			}
		}

		let bosses: JSX.Element[] | JSX.Element;
		if (character) {
			bosses = bossList.map((boss) => <MinimalBoss key={boss.id} boss={boss} />);
		} else {
			bosses = bossList.map((boss) => (
				<BossShell
					key={boss.id}
					onChangeViewTab={(bossViewType) => this.setState({bossViewType})}
					onUpdateNotes={this.props.onUpdateNotes}
					onUpdateLimit={this.props.onUpdateLimit}
					onChangeRoleTab={this.handleChangeTab}
					onGoToReorder={this.handleGoToReorder}
					onUnrosterAll={this.props.onUnrosterAll}
					onRoster={this.props.onRoster}
					onSearch={this.handleSearch}
					isUpdatingNotes={this.props.isUpdatingNotes}
					isShowingKillCount={this.props.isKillCountSupported}
					isShowingAdminControls={this.props.isShowingAdminControls}
					isShowingChanges={this.props.isShowingChanges}
					isAdmin={this.props.isAdmin}
					isDemo={this.props.isDemo}
					canUseAssignmentsView={this.props.canUseAssignmentsView}
					canUseNotesView={this.props.canUseNotesView}
					bossViewType={this.state.bossViewType}
					roleGroups={this.props.roleGroups}
					characterById={this.props.characterById}
					wowheadBonus={this.props.wowheadBonus}
					selectedRole={this.props.selectedRole}
					characterMetaMap={this.props.characterMetaMap}
					changes={this.props.changes?.[boss.id]}
					boss={boss}
				/>
			));
		}

		if (!bosses.length && this.state.searchId) {
			let text = <span>No matches found</span>;

			if (character) {
				const name = <strong>{character.name}</strong>;
				text = <span>{name} isn't assigned to any bosses</span>;
			}

			bosses = <div className="no-matches">{text}</div>;
		}

		if (!this.props.canUseAssignmentsView && !this.props.canUseNotesView) {
			bosses = (
				<div className="all-views-disabled">
					<div className="empty-state">
						<Image src={image} />

						<div className="main">All views disabled</div>

						<div className="secondary">
							The admins of your guild have disabled all of the legacy roster
							views. If you believe this to be a mistake, please reach out to your
							officer team and get them to have a look in Readycheck's Guild
							Settings.
						</div>
					</div>
				</div>
			);
		}

		const changesClass = classnames(
			{
				'tooltip-medium': !this.props.isShowingChanges,
				active: this.props.isShowingChanges
			},
			'tooltip tooltip-left'
		);

		const adminControlsClass = classnames(
			{
				'tooltip-medium': !this.props.isShowingAdminControls,
				active: this.props.isShowingAdminControls
			},
			'tooltip tooltip-left'
		);

		const sortClass = classnames(
			{
				'tooltip-medium': !this.props.isAlphabeticalSort,
				active: this.props.isAlphabeticalSort
			},
			'tooltip tooltip-left'
		);

		const bossesClass = classnames(
			{
				minimal: !!character
			},
			'bosses'
		);

		return (
			<div className="roster-view">
				<div className="title-row">
					<div className="view-title">
						<div className="name">{this.props.roster.name}</div>

						<div className="difficulty">
							{this.props.roster.difficulty.toUpperCase()}
						</div>
					</div>

					<VersionSwitcher
						guildId={this.props.roster.guildId}
						rosterId={this.props.roster.id}
						currentVersion="old"
					/>
				</div>

				{this.props.canUseAssignmentsView && (
					<div className="filter-controls">
						<FilterSelect
							onChange={this.handleSelect}
							placeholder="Show bosses for a character"
							options={this.props.filterOptions}
							value={this.state.searchId}
						/>

						{this.props.isAdmin && (
							<div className="filter-admin">
								<div
									className={adminControlsClass}
									onClick={this.handleToggleAdminControls}
									data-hint={
										this.props.isShowingAdminControls
											? 'Hide modify assignments controls'
											: 'Show the controls used to modify which characters are assigned to the boss'
									}
								>
									<i className="lnr lnr-users-plus" />
								</div>

								<div
									className={changesClass}
									onClick={this.handleToggleChanges}
									data-hint={
										this.props.isShowingChanges
											? 'Hide changes'
											: 'Show which characters need to be added or removed between each boss'
									}
								>
									<i className="lnr lnr-tab" />
								</div>

								<div
									className={sortClass}
									onClick={this.handleToggleAlphabeticalSort}
									data-hint={
										this.props.isAlphabeticalSort
											? 'Sort assignments by character class and name'
											: 'Sort assignments by character name'
									}
								>
									<i className="smaller lnr lnr-sort-alpha-asc" />
								</div>
							</div>
						)}
					</div>
				)}

				{character && !!bossList.length && (
					<div className="character-assignment-text">
						<strong>{character.name}</strong>
						<span> is assigned to the following </span>
						<span>
							{bossList.length === 1 ? 'boss' : `${bossList.length} bosses`}
						</span>
					</div>
				)}

				<div className={bossesClass}>{bosses}</div>
			</div>
		);
	}
}

interface IMinimalBossProps {
	boss: IRosterViewBoss;
}

function MinimalBoss(props: IMinimalBossProps) {
	return (
		<div className="minimal-boss">
			<div className="panel">
				<img src={images.getBossImageUrl(props.boss.bossId)} />

				<div className="name">{props.boss.name}</div>
			</div>
		</div>
	);
}
