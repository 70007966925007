import React from 'react';

import {useStateful} from '@hooks/useStateful';
import {useBoolean} from '@hooks/useBoolean';

import {connect2, RosterDuck, RosterBossDuck, RaidDataDuck} from '@ducks';

import {ConfirmModal} from '@components/Utils/ConfirmModal';
import {SwitchField} from '@components/Utils/Switch';

interface BossItem {
	name: string;
	rosterBossId: RosterBossId;
}

interface OwnProps {
	onClose(): void;

	currentRosterBossId: RosterBossId;
	rosterId: RosterId;
}

interface StateProps {
	bossItems: BossItem[];
}

interface DispatchProps {
	onSubmit: BoundThunk<typeof RosterBossDuck['copyAssignmentsToBosses']>;
}

type Props = OwnProps & StateProps & DispatchProps;

const CopyAssignmentsToBossesComp: React.FC<Props> = (props) => {
	const submitting = useBoolean(false);
	const destinationRosterBossIds = useStateful<RosterBossId[]>([]);

	async function handleSubmit(): Promise<void> {
		submitting.setTrue();

		await props.onSubmit({
			sourceRosterBossId: props.currentRosterBossId,
			destinationRosterBossIds: destinationRosterBossIds.value
		});

		submitting.setFalse();
		props.onClose();
	}

	function handleBossToggle(isSwitched: boolean, rosterBossId: RosterBossId): void {
		if (isSwitched) {
			destinationRosterBossIds.setValue([
				...destinationRosterBossIds.value,
				rosterBossId
			]);
		} else {
			destinationRosterBossIds.setValue(
				destinationRosterBossIds.value.filter((x) => x !== rosterBossId)
			);
		}
	}

	const bossItems = props.bossItems.map((bossItem) => (
		<SwitchField
			key={bossItem.rosterBossId}
			onChange={(isSwitched) => handleBossToggle(isSwitched, bossItem.rosterBossId)}
			isDisabled={bossItem.rosterBossId === props.currentRosterBossId}
			withTopMargin={true}
			defaultValue={false}
			label={bossItem.name}
		/>
	));

	return (
		<ConfirmModal
			modalClassName="normal-alignment"
			onSubmit={handleSubmit}
			onClose={props.onClose}
			isSubmitting={submitting.value}
			heading="Copy assignments to other bosses"
			submitButtonText="Copy assignments"
			submitColour="primary"
		>
			<p>
				You may replace the rostered characters of other bosses with the assignments
				from this boss.
			</p>

			<p>Doing this will remove the existing assignments of any bosses you select.</p>

			<div className="boss-items" style={{marginTop: '2rem'}}>
				{bossItems}
			</div>
		</ConfirmModal>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const roster = RosterDuck.getRoster(state.rosters, props.rosterId);

	const rosterBosses = RosterBossDuck.getBossesForRoster(
		state.rosterBosses,
		props.rosterId
	);
	const wowBosses =
		(roster &&
			RaidDataDuck.getRaidInstance(state.raidData, roster.wowInstanceId)?.bosses) ||
		[];

	const bossItems = rosterBosses.reduce<BossItem[]>((items, rosterBoss): BossItem[] => {
		const wowBoss = wowBosses.find((x) => rosterBoss.bossId === x.id);
		if (!wowBoss) return items;

		return [
			...items,
			{
				name: wowBoss.name,
				rosterBossId: rosterBoss.id
			}
		];
	}, []);

	return {
		bossItems
	};
}

export const CopyAssignmentsToBosses = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: DispatchProps;
}>(mapStateToProps, {
	onSubmit: RosterBossDuck.copyAssignmentsToBosses
})(CopyAssignmentsToBossesComp);
