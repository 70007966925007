import React from 'react';

interface PageHeadingDetailsProps {
	heading: string;
	subheading: string | null;
}

export const PageHeadingDetails: React.FC<PageHeadingDetailsProps> = (props) => {
	return (
		<div className="roster-page-heading-details">
			<div className="heading">{props.heading}</div>
			{props.subheading && <div className="subheading">{props.subheading}</div>}
		</div>
	);
};

interface PageHeadingRowProps {
	className?: string;
}

export const PageHeadingRow: React.FC<PageHeadingRowProps> = (props) => {
	return (
		<div className={`${props.className || ''} roster-page-heading-row`}>
			{props.children}
		</div>
	);
};
