import type {CooldownSheet, CooldownSheetId} from '@models/cooldownSheet';
import type {CooldownEvent} from '@models/cooldownEvent';
import type {CooldownAction} from '@models/cooldownAction';

export interface State {
	readonly sheetById: Record<CooldownSheetId, CooldownSheet>;

	readonly isFetchingByRosterBossId: Record<RosterBossId, boolean>;
}

export const FETCH_FOR_BOSS = 'cooldown-sheet/FETCH_FOR_BOSS';
export const FETCH_FOR_BOSS_SUCCESS = 'cooldown-sheet/FETCH_FOR_BOSS_SUCCESS';
export const FETCH_FOR_BOSS_FAILURE = 'cooldown-sheet/FETCH_FOR_BOSS_FAILURE';

export interface Actions {
	FETCH_FOR_BOSS: {
		readonly type: typeof FETCH_FOR_BOSS;
		readonly payload: {
			readonly rosterBossId: RosterBossId;
		};
	};
	FETCH_FOR_BOSS_SUCCESS: {
		readonly type: typeof FETCH_FOR_BOSS_SUCCESS;
		readonly payload: {
			readonly rosterBossId: RosterBossId;

			readonly cooldownSheet: CooldownSheet;
			readonly cooldownEvents: CooldownEvent[];
			readonly cooldownActions: CooldownAction[];
		};
	};
	FETCH_FOR_BOSS_FAILURE: {
		readonly type: typeof FETCH_FOR_BOSS_FAILURE;
		readonly payload: {
			readonly rosterBossId: RosterBossId;
		};
	};
}
