import * as Feed from '@constants/feed';

import {RootAction, CooldownSheetDuck} from '@ducks';

import {CooldownSheet} from '@models/cooldownSheet';

const initialState: CooldownSheetDuck.State = {
	sheetById: {},

	isFetchingByRosterBossId: {}
};

export default function reducer(
	state = initialState,
	action: RootAction
): CooldownSheetDuck.State {
	switch (action.type) {
		// fetch for boss
		case CooldownSheetDuck.FETCH_FOR_BOSS: {
			return {
				...state,
				isFetchingByRosterBossId: {
					...state.isFetchingByRosterBossId,
					[action.payload.rosterBossId]: true
				}
			};
		}

		case CooldownSheetDuck.FETCH_FOR_BOSS_SUCCESS: {
			// remove the entry for this boss
			const {[action.payload.rosterBossId]: deleted, ...rest} =
				state.isFetchingByRosterBossId;

			return {
				...state,
				isFetchingByRosterBossId: rest,
				sheetById: {
					...state.sheetById,
					[action.payload.cooldownSheet.id]: action.payload.cooldownSheet
				}
			};
		}

		case CooldownSheetDuck.FETCH_FOR_BOSS_FAILURE: {
			// remove the entry for this boss
			const {[action.payload.rosterBossId]: deleted, ...rest} =
				state.isFetchingByRosterBossId;

			return {
				...state,
				isFetchingByRosterBossId: rest
			};
		}

		// feed
		case Feed.COOLDOWN_SHEET_INSERT:
		case Feed.COOLDOWN_SHEET_UPDATE: {
			return {
				...state,

				sheetById: {
					...state.sheetById,
					[action.payload.newRecord.id]: new CooldownSheet(action.payload.newRecord)
				}
			};
		}

		case Feed.COOLDOWN_SHEET_DELETE: {
			const {[action.payload.oldRecord.id]: deleted, ...rest} = state.sheetById;

			return {
				...state,
				sheetById: rest
			};
		}

		default:
			return state;
	}
}
