import React from 'react';
import {connect} from 'react-redux';

import {demoRosterUrl} from '@helpers/urls';

import {RosterDuck, GuildDuck, DemoDuck, RaidDataDuck} from '../../ducks';

import type {RaidInstance} from '@models/raid-data';
import type {Roster} from '../../models/roster';
import type {Guild} from '../../models/guild';

import {RosterPage} from '../RosterNew/Page/RosterPage';

interface OwnProps {
	params: {
		rosterBossId?: string;
	};
}

interface MapProps {
	isAdmin: boolean;

	raidInstance: RaidInstance | undefined;
	roster: Roster | undefined;
	guild: Guild | undefined;
}

interface DispatchProps {
	onUpdateNotes: typeof DemoDuck.updateNotes;
	onUpdateLimit: typeof DemoDuck.updateLimit;
	onUnrosterAll: typeof DemoDuck.unrosterAll;
	onRoster: typeof DemoDuck.rosterCharacter;
}

type Props = OwnProps & MapProps & DispatchProps;

function DemoRosterWrapper(props: Props) {
	if (!props.roster || !props.guild || !props.raidInstance) return null;

	const routerRosterBossId =
		(props.params.rosterBossId && Number.parseInt(props.params.rosterBossId, 10)) ||
		null;

	function generateBossUrl(rosterBossId: RosterBossId) {
		if (!props.roster) return '/';

		return demoRosterUrl(rosterBossId);
	}

	return (
		<RosterPage
			onFetchCooldownSheet={(() => {}) as any}
			onCreateCooldownEvent={(() => {}) as any}
			onUpdateCooldownEventName={(() => {}) as any}
			onUpdateCooldownEventTime={(() => {}) as any}
			onReorderCooldownEvent={(() => {}) as any}
			onDeleteCooldownEvent={(() => {}) as any}
			onCreateCooldownAction={(() => {}) as any}
			onUpdateCooldownActionActor={(() => {}) as any}
			onUpdateCooldownActionAbility={(() => {}) as any}
			onDeleteCooldownAction={(() => {}) as any}
			onEnterSettings={(() => {}) as any}
			onUpdateNotes={props.onUpdateNotes as any}
			onUpdateLimit={props.onUpdateLimit as any}
			onUnrosterAll={props.onUnrosterAll as any}
			onRoster={props.onRoster as any}
			generateBossUrl={generateBossUrl}
			isUpdatingNotes={false}
			isAdmin={props.isAdmin}
			isDemo={true}
			rosterSettingsUrl="/" // not shown in demo
			rosterReorderUrl="/" // not shown in demo
			routerRosterBossId={routerRosterBossId}
			raidInstance={props.raidInstance}
			roster={props.roster}
		/>
	);
}

function mapStateToProps(state: IRootState) {
	const guild = GuildDuck.getActiveGuild(state.guilds);
	const roster = guild && RosterDuck.getRostersForGuild(state.rosters, guild.id)[0];

	const raidInstance = roster
		? RaidDataDuck.getRaidInstance(state.raidData, roster.wowInstanceId)
		: undefined;

	return {
		isAdmin: true,

		raidInstance,
		roster,
		guild
	};
}

export default connect<MapProps, DispatchProps>(mapStateToProps, {
	onUpdateNotes: DemoDuck.updateNotes,
	onUpdateLimit: DemoDuck.updateLimit,
	onUnrosterAll: DemoDuck.unrosterAll,
	onRoster: DemoDuck.rosterCharacter
})(DemoRosterWrapper);
