import React from 'react';
import classnames from 'classnames';

interface Props {
	onClick: (() => void) | null;
	value: string;
}

export const ViewNote: React.FC<Props> = (props) => {
	if (!props.value) return null;

	const noteClass = classnames(
		{
			clickable: !!props.onClick
		},
		'view-note'
	);

	return (
		<div onClick={props.onClick || undefined} className={noteClass}>
			{props.value}
		</div>
	);
};
