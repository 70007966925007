import {connect} from 'react-redux';

import * as GuildDuck from '../../ducks/guild';
import * as UserDuck from '../../ducks/user';

import {Guild} from '../../models/guild';

import SelectGuild from './SelectGuild';

export interface IGuildOption {
	isSetup: boolean;

	faction: string;
	realm: string;
	name: string;
	url: string;
}

function mapStateToProps(state: IRootState) {
	const user = UserDuck.getUser(state.user);
	if (!user) return {guilds: []};

	const guilds = GuildDuck.getGuilds(state.guilds);

	// build up the lists of guilds and filter out duplicates
	const guildByKey: {[key: string]: IGuildOption} = {};
	user.characters.forEach((char) => {
		if (!char.guildName || !char.guildRealm) return;

		const key = `${char.guildRealm}:${char.guildName}`;
		if (guildByKey[key]) return;

		const guild = GuildDuck.getGuildByDetails(state.guilds, {
			region: char.region,
			realm: char.guildRealm,
			name: char.guildName
		});

		let url;
		if (guild) {
			url = Guild.createBaseUrl(guild.id);
		} else {
			const encodedRealm = encodeURIComponent(char.guildRealm);
			const encodedName = encodeURIComponent(char.guildName);
			url = `/setup-guild/${char.region}/${encodedRealm}/${encodedName}`;
		}

		const guildOption: IGuildOption = {
			isSetup: !!guild,

			faction: char.faction,
			realm: guild ? guild.realm : char.guildRealm,
			name: guild ? guild.name : char.guildName,
			url
		};

		guildByKey[key] = guildOption;
	});

	// the guilds of external characters won't be added form the flow above because
	// it goes off the character's actual guild, not the guilds the character has
	// access to, so we need to go through all the guilds the server sent as well
	// to add in any missing tiles
	guilds.forEach((guild) => {
		// don't need to handle guilds that have already been handled by the character
		const key = `${guild.realm}:${guild.name}`;
		if (guildByKey[key]) return;

		const guildOption: IGuildOption = {
			isSetup: !!guild,

			faction: guild.faction,
			realm: guild.realm,
			name: guild.name,
			url: Guild.createBaseUrl(guild.id)
		};

		guildByKey[key] = guildOption;
	});

	const guildTiles = Object.values(guildByKey);
	const notSetupGuilds = guildTiles.filter((x) => !x.isSetup);
	const setupGuilds = guildTiles.filter((x) => x.isSetup);

	return {
		battletag: user.bnetBattleTag,
		region: user.bnetRegion,

		notSetupGuilds,
		setupGuilds
	};
}

export default connect(mapStateToProps)(SelectGuild);
