export * from './vaultItemHack';

export const DEFAULT_ADMIN_RANKS = [0, 1];

export const DEMO_GUILD_ID = 0;

export const NO_GUILD_STRING = '__no_guild__';

export const DISCORD_WEBHOOK_REGEX = /^https:\/\/discord(app)?.com\/api\/webhooks\//;

export enum AuthErrors {
	ERROR_SYNCING_CHARACTERS_SHARING = 'error-syncing-characters-sharing',
	ERROR_SYNCING_CHARACTERS = 'error-syncing-characters',
	MISSING_SCOPE = 'missing-scope',
	NO_USER = 'no-user'
}

export enum ErrorCodes {
	BAD_DATA = 'bad-data',

	DISCORD_NOT_AUTHORISED_WEBHOOK = 'discord-not-authorised-webhook',
	DISCORD_REQUEST_ERROR = 'discord-request-error',

	ROSTER_BOSS_NOT_FOUND = 'roster-boss-not-found',
	ROSTER_BOSS_FROM_DIFFERENT_ROSTER = 'roster-boss-from-different-roster',
	ROSTER_NOT_FOUND = 'roster-not-found',

	CHARACTER_NOT_FOUND = 'character-not-found',
	CHARACTER_UNKNOWN_OWNER = 'character-unknown-owner',

	COOLDOWN_SHEET_EXISTS_FOR_BOSS = 'cooldown-sheet-exists-for-boss',
	COOLDOWN_SHEET_NOT_FOUND = 'cooldown-sheet-not-found',
	COOLDOWN_EVENT_NOT_FOUND = 'cooldown-event-not-found',
	COOLDOWN_ACTION_NOT_FOUND = 'cooldown-action-not-found',

	DROPTIMIZER_REPORT_NOT_FOUND = 'droptimizer-report-not-found',

	WOW_ABILITY_NOT_FOUND = 'wow-ability-not-found',

	ADMIN_PERMISSION_REQUIRED = 'admin-permission-required',
	GUILD_MEMBERSHIP_REQUIRED = 'guild-membership-required'
}

export enum BillingStatus {
	TRIALLING = 'trialing',
	CANCELLED = 'canceled',
	PAST_DUE = 'past_due',
	ACTIVE = 'active'
}

export enum RosterViewVersion {
	ONE = '1',
	TWO = '2'
}
