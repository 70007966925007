import React from 'react';

import type {BossViewType} from '@constants';

import {useModal} from '@hooks/useModal';

import type {CooldownSheetId} from '@models/cooldownSheet';

import {Button} from '@components/Utils/Button';
import {PageHeadingDetails} from '../../Utils/PageHeading';
import {BossHeadingRow} from '../BossElements';
import {ViewSwitcher} from '../ViewSwitcher';
import {ExportMrt} from './ExportMrt';

interface Props {
	onChangeView(view: BossViewType): void;

	isAdmin: boolean;

	viewType: BossViewType;
	bossName: string;

	cooldownSheetId: CooldownSheetId | undefined;
}

export const BossHeading: React.FC<Props> = (props) => {
	const exportMrt = useModal();

	return (
		<>
			{exportMrt.isOpen && props.cooldownSheetId && (
				<ExportMrt
					onClose={exportMrt.close}
					cooldownSheetId={props.cooldownSheetId}
				/>
			)}

			<BossHeadingRow
				leftColumn={<PageHeadingDetails heading={props.bossName} subheading={null} />}
				rightColumn={
					<>
						<Button onClick={exportMrt.open} className="medium primary">
							MRT export
						</Button>

						<ViewSwitcher
							onChangeView={props.onChangeView}
							isAdmin={props.isAdmin}
							viewType={props.viewType}
						/>
					</>
				}
			/>
		</>
	);
};
