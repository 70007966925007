// XXX omitting guildId for efficiency

interface ILootSelectionData {
	// guildId: number

	characterId: number;
	optionId: number;

	selectionPerformedAt: string | null;
	difficulty: string;
	wowItemId: WowItemId;

	// noteUpdatedAt: string | null;
	note: string | null;
}

export interface ILootSelectionClass extends ILootSelectionData {}

export class LootSelection
	implements OmitStrict<ILootSelectionClass, 'selectionPerformedAt'>
{
	// readonly guildId: number;

	readonly characterId: number;
	readonly optionId: number;

	readonly selectionPerformedAt: Date | null;
	readonly difficulty: string;
	readonly wowItemId: WowItemId;

	// readonly noteUpdatedAt: Date | null;
	readonly note: string | null;

	constructor(data?: ILootSelectionClass) {
		if (!data) throw new Error('invalid loot-selection data');

		// this.guildId = data.guildId;

		this.characterId = data.characterId;
		this.optionId = data.optionId;

		this.selectionPerformedAt = data.selectionPerformedAt
			? new Date(data.selectionPerformedAt)
			: null;
		this.difficulty = data.difficulty;
		this.wowItemId = data.wowItemId;

		this.note = data.note;
	}
}
