import React from 'react';

import {userNewBillingUrl, router} from '@helpers/urls';

import {useModal} from '@hooks/useModal';

import {PlanSelection} from '../PlanSelection/PlanSelection';
import {LinkButton, ButtonGroup} from '../../Utils/Button';
import {ChangePlanModal} from './ChangePlanModal';
import type {IProps} from './ChangePlanContainer';

export const ChangePlan: React.FC<IProps> = (props) => {
	const [newStripePriceId, setNewStripePriceId] = React.useState<string | null>(null);
	const changePlanModal = useModal();

	const handleStartPlanChange = (stripePriceId: string): void => {
		setNewStripePriceId(stripePriceId);
		changePlanModal.open();
	};

	const handlePlanChange = (stripePriceId: string): void => {
		void props.onSelect(stripePriceId).then(() => {
			router.push(userNewBillingUrl());
		});
	};

	return (
		<>
			{newStripePriceId && changePlanModal.isOpen && (
				<ChangePlanModal
					onSubmit={() => handlePlanChange(newStripePriceId)}
					onClose={changePlanModal.close}
					isSubmitting={!!props.actioningStripePriceId}
					newStripePriceId={newStripePriceId}
					billing={props.billing}
				/>
			)}

			<div className="new-user-billing-view setup-guild-like-view change-plan-view">
				<div className="heading">
					<h1 className="big">Switch plans</h1>

					<ButtonGroup alignment="center">
						<LinkButton to={userNewBillingUrl()} className="underlined link">
							Back to plan management
						</LinkButton>
					</ButtonGroup>
				</div>

				<PlanSelection
					onSelect={handleStartPlanChange}
					actioningStripePriceId={props.actioningStripePriceId}
					currentPriceId={props.billing.stripePriceId}
				/>
			</div>
		</>
	);
};
