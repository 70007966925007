import React from 'react';

interface GroupHeadingProps {
	heading: string;
	description?: React.ReactNode;
	children?: React.ReactNode;
}

export const GroupHeading: React.FC<GroupHeadingProps> = (props) => {
	return (
		<div className="group-heading-row">
			<div className="group-heading-content">
				<div className="group-heading">{props.heading}</div>

				{props.description && (
					<div className="group-description">{props.description}</div>
				)}
			</div>

			{props.children}
		</div>
	);
};
