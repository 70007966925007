import type {IPricingData} from '@shared/billing/plans';

import {UserDuck, GuildDuck, GuildSponsorshipDuck, connect} from '@ducks';

import type {GuildSponsorship} from '@models/guildSponsorship';
import type {ActiveUserBilling} from '@models/userBilling';
import type {User} from '@models/user';

import {ManageSponsorships} from './ManageSponsorships';

interface ISponsorshipItem {
	isSponsored: boolean;

	guildId: number;
	guildName: string;
	guildRealm: string;
}

interface IOwnProps {
	billing: ActiveUserBilling;
	pricingData: IPricingData;
}

interface IMapProps {
	unsponsoringGuildId: number | null;
	sponsoringGuildId: number | null;

	maxSponsoredCount: number;
	sponsoredCount: number;

	items: ISponsorshipItem[];
}

interface IDispatchProps {
	onUnsponsor: BoundThunk<typeof GuildSponsorshipDuck.unsponsor>;
	onSponsor: BoundThunk<typeof GuildSponsorshipDuck.sponsor>;
}

export type IProps = IOwnProps & IMapProps & IDispatchProps;

function makeSponsorshipItems(
	user: User,
	guildState: GuildDuck.IState,
	guildSponsorships: GuildSponsorship[]
): ISponsorshipItem[] {
	const itemByGuildId: Record<string, ISponsorshipItem> = {};

	guildSponsorships.forEach((sponsorship) => {
		itemByGuildId[sponsorship.guildId] = {
			isSponsored: !!sponsorship.status,

			guildId: sponsorship.guildId,
			guildRealm: sponsorship.guildRealm,
			guildName: sponsorship.guildName
		};
	});

	user.characters.forEach((character) => {
		// ignore characters without guilds
		if (!character.guildName || !character.guildRealm) return;

		const guild = GuildDuck.getGuildByDetails(guildState, {
			region: character.region,
			realm: character.guildRealm,
			name: character.guildName
		});

		// don't show guilds that aren't set up
		if (!guild) return;

		// if this guild has already been handled then we can skip it
		if (itemByGuildId[guild.id]) return;

		itemByGuildId[guild.id] = {
			isSponsored: false,

			guildId: guild.id,
			guildRealm: guild.realm,
			guildName: guild.name
		};
	});

	return Object.values(itemByGuildId);
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const user = UserDuck.getActiveUser(state.user);

	const guildSponsorships = GuildSponsorshipDuck.getSponsorshipsForBillingId(
		state.guildSponsorships,
		props.billing.id
	);

	const items = makeSponsorshipItems(user, state.guilds, guildSponsorships);

	return {
		maxSponsoredCount: props.pricingData.maxBuffedGuilds,
		sponsoredCount: guildSponsorships.length,

		unsponsoringGuildId: GuildSponsorshipDuck.getUnsponsoringGuildId(
			state.guildSponsorships
		),
		sponsoringGuildId: GuildSponsorshipDuck.getSponsoringGuildId(
			state.guildSponsorships
		),

		items
	};
}

export const ManageSponsorshipsContainer = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onUnsponsor: GuildSponsorshipDuck.unsponsor,
		onSponsor: GuildSponsorshipDuck.sponsor
	}
)(ManageSponsorships);
