import React from 'react';
import classnames from 'classnames';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {Link} from 'react-router';

interface IButtonProps {
	onClick?(): void;

	type?: 'button' | 'submit';
	isDisabled?: boolean;
	isLoading?: boolean;

	className?: string;
}

export const Button: React.FC<IButtonProps> = (props) => {
	const className = classnames(
		{
			submitting: props.isLoading,
			disabled: props.isDisabled
		},
		props.className,
		'button'
	);

	return (
		<LaddaButton
			onClick={props.onClick}
			className={className}
			loading={props.isLoading}
			data-style={ZOOM_OUT}
			type={props.type || 'button'}
		>
			{props.children}
		</LaddaButton>
	);
};

interface ILinkButtonProps {
	className: string;
	to: string;
}

export const LinkButton: React.FC<ILinkButtonProps> = (props) => {
	const className = classnames(props.className, 'button');

	return (
		<Link to={props.to} className={className}>
			{props.children}
		</Link>
	);
};

interface IButtonGroupProps {
	alignment?: 'left' | 'center' | 'right' | 'vertical';
	withTinyTopMargin?: boolean;
	withNoTopMarging?: boolean;
}

export const ButtonGroup: React.FC<IButtonGroupProps> = (props) => {
	const buttonClass = classnames(
		{
			'tiny-top-margin': props.withTinyTopMargin,
			'no-top-margin': props.withNoTopMarging
		},
		props.alignment || 'right',
		'button-group'
	);

	return <div className={buttonClass}>{props.children}</div>;
};
