import React from 'react';
import classnames from 'classnames';

interface ILoadingProps {
	className?: string;
	isCentered?: boolean;
	isSmall?: boolean;
}

export function Loading(props: ILoadingProps) {
	const loadingClass = classnames(
		{
			centered: props.isCentered,
			small: props.isSmall
		},
		props.className,
		'loading-component'
	);

	return (
		<div className={loadingClass}>
			<div className="r1" />
			<div className="r2" />
			<div className="r3" />
			<div className="r4" />
			<div className="r5" />
		</div>
	);
}

export default Loading;
