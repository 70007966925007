import React from 'react';

import {RegionPicker} from './RegionPicker';
import Support from '../Utils/Support';

interface IProps {
	onRegionSelect(region: string): void;
	onRegionClose(): void;
	onConnect(): void;
	isSelectingRegion: boolean;
	isBnetDown: boolean;
}

export const InternalError: React.FC<IProps> = (props: IProps) => {
	return (
		<div className="page-container">
			<Support />

			{props.isSelectingRegion && (
				<RegionPicker
					onClose={props.onRegionClose}
					onClick={props.onRegionSelect}
					isCacheBust={false}
				/>
			)}

			<div className="auth-page">
				<div className="auth-content card">
					<div className="heading">Well...</div>

					<div className="content">
						<div className="description">
							{!props.isBnetDown && (
								<p>
									We encounted some kind of error trying to get you all ready and
									connected, sorry about that. Issues tend to go away, fancy
									giving it another go?
								</p>
							)}

							{props.isBnetDown && (
								<p className="bnet-down">
									Battle.net appears to be undergoing some issues which are
									preventing you from authenticating correctly - please try again
									shortly to see if the issues have been resolved.
								</p>
							)}

							<p>
								<span>If the problem persists please head over to the </span>
								<a
									href="https://readycheck.io/contact"
									target="_blank"
									rel="noreferrer noopener"
								>
									contact page
								</a>
								<span> and reach out to us and we'll see what we can do.</span>
							</p>
						</div>

						<div
							onClick={props.onConnect}
							className="auth-button large primary faded button"
						>
							Give it another go
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
