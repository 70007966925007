import React from 'react';
import {connect} from 'react-redux';
import type {PlainRoute} from 'react-router';

import * as GuildDuck from '../../ducks/guild';
import * as UserDuck from '../../ducks/user';

import {Guild} from '../../models/guild';
import type {User} from '../../models/user';

import Loading from '../Utils/Loading';
import GuildNav from './GuildNav';
import NoGuild from './NoGuild';

interface IOwnProps {
	routes: PlainRoute[];

	params: {
		guildId: string;
	};

	location: {
		pathname: string;

		query: {
			c?: string;
		};
	};
}

interface IDispatchProps {
	onFetch: typeof GuildDuck.fetchGuildData;
	onActive: typeof GuildDuck.setActive;
}

interface IMapProps {
	isHeaderFixed: boolean;
	isLoading: boolean;
	basePath: string;

	activeCharacterQuery: string | undefined;
	activePathname: string;

	guild: Guild;
	user: User;
}

type IProps = IDispatchProps & IMapProps & IOwnProps;

function getId(props: IProps) {
	return Number.parseInt(props.params.guildId, 10);
}

class GuildContainer extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		if (this.props.guild) {
			this.setup(getId(props));
		}
	}

	componentWillReceiveProps(newProps: IProps) {
		const oldId = getId(this.props);
		const newId = getId(newProps);

		if (newProps.guild && oldId !== newId) {
			this.setup(newId);
		}
	}

	setup(id: number) {
		if (!id) return;

		this.props.onActive(this.props.user, id);
		this.props.onFetch(id);
	}

	render() {
		let content;
		if (!this.props.guild) content = <NoGuild />;
		else if (this.props.isLoading) content = <Loading isCentered={true} />;
		else {
			content = React.cloneElement(this.props.children as any, {
				guild: this.props.guild
			});
		}

		return (
			<div className="page-container">
				<GuildNav
					isHeaderFixed={this.props.isHeaderFixed}
					isLoading={this.props.isLoading}
					basePath={this.props.basePath}
					activeCharacterQuery={this.props.activeCharacterQuery}
					activePathname={this.props.activePathname}
				/>

				{content}
			</div>
		);
	}
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const guildId = props.params.guildId;

	return {
		isHeaderFixed: props.routes.some((x) => x.isHeaderFixed),

		isLoading: GuildDuck.getGuildDataLoading(state.guilds),
		basePath: Guild.createBaseUrl(guildId),

		activeCharacterQuery: props.location.query.c,
		activePathname: props.location.pathname,

		guild: GuildDuck.getGuild(state.guilds, guildId),
		user: UserDuck.getActiveUser(state.user)
	};
}

export default connect(mapStateToProps, {
	onFetch: GuildDuck.fetchGuildData,
	onActive: GuildDuck.setActive
})(GuildContainer);
