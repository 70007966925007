import React from 'react';
import classnames from 'classnames';

import type {Role} from '@constants/wow';

import {connect2, CooldownActionDuck, CharacterDuck} from '@ducks';

import type {CooldownAction} from '@models/cooldownAction';
import type {Character} from '@models/character';

import {ActionsMenu, ConfirmationMenuItem} from '@components/Utils/Menu';
import {Actor} from './Actor';
import {Ability} from './Ability';

interface OwnProps {
	onUpdateActor: BoundThunk<typeof CooldownActionDuck['updateActorCooldownAction']>;
	onUpdateAbility: BoundThunk<typeof CooldownActionDuck['updateAbilityCooldownAction']>;
	onDeleteAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;

	isEditor: boolean;

	rosterBossId: RosterBossId;
	rosteredRoleByCharacterId: Record<CharacterId, Role>;
	action: CooldownAction;
}

interface StateProps {
	actorCharacter: Character | undefined;
	actorRosteredRole: Role | undefined;
}

type ActionProps = OwnProps & StateProps;

const ActionComp: React.FC<ActionProps> = (props) => {
	return (
		<div className={classnames({editable: props.isEditor}, 'action')}>
			<Actor
				onUpdateActor={(data) =>
					props.onUpdateActor({cooldownActionId: props.action.id, ...data})
				}
				isEditor={props.isEditor}
				rosterBossId={props.rosterBossId}
				actorCharacter={props.actorCharacter}
				actorText={props.action.actorText}
			/>

			<Ability
				onUpdateAbility={(data) =>
					props.onUpdateAbility({cooldownActionId: props.action.id, ...data})
				}
				isEditor={props.isEditor}
				wowAbilityId={props.action.wowAbilityId}
				abilityText={props.action.abilityText}
				actorClass={props.actorCharacter?.class}
				actorRole={props.actorRosteredRole}
			/>

			<ActionsMenu>
				{props.isEditor && (
					<ConfirmationMenuItem
						onClick={() =>
							props.onDeleteAction({cooldownActionId: props.action.id})
						}
						confirmText="Confirm delete"
						type="alert"
					>
						Delete action
					</ConfirmationMenuItem>
				)}
			</ActionsMenu>
		</div>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const actorCharacter =
		(props.action.actorCharacterId &&
			CharacterDuck.getCharacterForId(
				state.characters,
				props.action.actorCharacterId
			)) ||
		undefined;

	const actorRosteredRole =
		(props.action.actorCharacterId &&
			props.rosteredRoleByCharacterId[props.action.actorCharacterId]) ||
		undefined;

	return {
		actorCharacter,
		actorRosteredRole
	};
}

export const Action = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: {};
}>(
	mapStateToProps,
	{}
)(ActionComp);
