import {useLayoutEffect} from 'react';

// taken from https://usehooks.com/useLockBodyScroll/
export function useLockBodyScroll() {
	useLayoutEffect(() => {
		// get the original value to re-set later
		const originalStyle = window.getComputedStyle(document.body).overflow;

		// prevent scrolling on mount
		document.body.style.overflow = 'hidden';

		// re-enable scrolling when component unmounts
		return () => {
			document.body.style.overflow = originalStyle;
		};
	}, []); // no deps ensures it only runs on mount and unmount
}
