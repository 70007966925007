import {connect, CharacterDuck} from '@ducks';

import {getBossesForCharacter, BossTile} from './bosses';
export type {BossTile};

import type {Character} from '@models/character';
import type {RaidInstance} from '@models/raid-data';

import {CharacterViewComp} from './CharacterView';

interface OwnProps {
	raidInstance: RaidInstance;

	characterId: CharacterId;
	rosterId: RosterId;
}

interface MapProps {
	character: Character | undefined;
	bossTiles: BossTile[];
}

export type Props = OwnProps & MapProps;

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const character = CharacterDuck.getCharacterForId(state.characters, props.characterId);

	// Early exit if there's no Character found
	if (!character) {
		return {
			character: undefined,
			bossTiles: []
		};
	}

	const bossTiles = getBossesForCharacter(state, {
		raidInstance: props.raidInstance,
		characterId: character.id,
		rosterId: props.rosterId
	});

	return {
		character,
		bossTiles
	};
}

export const CharacterView = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(CharacterViewComp);
