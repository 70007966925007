import {connect} from 'react-redux';

import {CharacterDuck, RosterDuck, UserDuck} from '@ducks';

import {guildSettingsRostersUrl} from '@helpers/urls';

import type {Roster} from '@models/roster';
import {Guild} from '@models/guild';

import RosterList from './RosterList';

interface IOwnProps {
	guild: Guild;
}

interface IMapProps {
	isAdmin: boolean;

	disabledViewRedirect:
		| {isDisabled: false; redirectUrl: undefined}
		| {isDisabled: true; redirectUrl: string};

	guildSettingsRostersUrl: string;
	baseUrl: string;
	rosters: Roster[];
}

export type IProps = IOwnProps & IMapProps;

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const rosters = RosterDuck.getRostersForGuild(state.rosters, props.guild.id);

	const user = UserDuck.getUser(state.user);

	let isAdmin = false;
	if (user) {
		const characters = CharacterDuck.getAllCharactersForGuild(
			state.characters,
			props.guild.id
		);
		isAdmin = props.guild.isUserAdmin(user, characters);
	}

	return {
		isAdmin,

		disabledViewRedirect: isAdmin
			? {isDisabled: false, redirectUrl: undefined}
			: props.guild.getDisabledViewInfo('roster-list'),

		guildSettingsRostersUrl: guildSettingsRostersUrl(props.guild.id),
		baseUrl: Guild.createBaseUrl(props.guild.id),
		rosters
	};
}

export default connect(mapStateToProps)(RosterList);
