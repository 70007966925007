import React from 'react';

import {ConfirmModal} from '../../Utils/ConfirmModal';
import {BrandName} from '../../Utils/BrandName';

interface IProps {
	onClose(): void;
	onSubmit(): void;

	isSubmitting: boolean;
}

export const RestoreModal: React.FC<IProps> = (props) => {
	return (
		<ConfirmModal
			onSubmit={props.onSubmit}
			onClose={props.onClose}
			isSubmitting={props.isSubmitting}
			heading="Re-buff your guild"
			submitButtonText="Restore Subscription"
			submitColour="primary"
		>
			<span>Are you sure you want to restore your </span>
			<BrandName />
			<span> subscription?</span>
		</ConfirmModal>
	);
};
