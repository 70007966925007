import type {CooldownSheetId} from './cooldownSheet';

export type CooldownEventId = Nominal<number, 'CooldownEvent'>;

export interface CooldownEventData {
	readonly id: CooldownEventId;
	readonly cooldownSheetId: CooldownSheetId;
	readonly guildId: GuildId;
	/** Nullable as events may be created by the system */
	readonly createdByUserId: UserId | null;

	readonly createdAt: string | Date;
	readonly updatedAt: string | Date;
	readonly editedAt: string | Date | null;

	readonly wowAbilityId: WowAbilityId | null;
	readonly name: string;
	/** Seconds from the start of the fight */
	readonly time: number | null;
	/** Integer ordering */
	readonly order: number;
}

export class CooldownEvent
	implements Omit<CooldownEventData, 'createdAt' | 'updatedAt' | 'editedAt'>
{
	readonly id: CooldownEventId;
	readonly cooldownSheetId: CooldownSheetId;
	readonly guildId: GuildId;
	/** Nullable as events may be created by the system */
	readonly createdByUserId: UserId | null;

	readonly createdAt: Date;
	readonly updatedAt: Date;
	readonly editedAt: Date | null;

	readonly wowAbilityId: WowAbilityId | null;
	readonly name: string;
	/** Seconds from the start of the fight */
	readonly time: number | null;
	/** Integer ordering */
	readonly order: number;

	constructor(data: CooldownEventData) {
		this.id = data.id;
		this.cooldownSheetId = data.cooldownSheetId;
		this.guildId = data.guildId;
		this.createdByUserId = data.createdByUserId;

		this.createdAt = new Date(data.createdAt);
		this.updatedAt = new Date(data.updatedAt);
		this.editedAt = data.editedAt ? new Date(data.editedAt) : null;

		this.wowAbilityId = data.wowAbilityId;
		this.name = data.name;
		this.time = data.time;
		this.order = data.order;
	}
}
