import React from 'react';
import classnames from 'classnames';

enum Status {
	NEUTRAL = 'neutral',
	LOADING = 'loading',
	SUCCESS = 'success',
	ERROR = 'error'
}

const TooltipMessages: Record<Status, string | undefined> = {
	[Status.NEUTRAL]: undefined,
	[Status.LOADING]: undefined,
	[Status.SUCCESS]: 'Changes saved',
	[Status.ERROR]: 'Error saving changes'
};

/**
 * Since the component ref is nullable, to make good DX this is able
 * to be `undefined` to allow for `actionId = this.actioning?.getActionId()`
 */
type ActionId = number | undefined;

interface IProps {
	className?: string;
}

interface IState {
	readonly status: Status;
	readonly actionId: ActionId;
}

export class Actioning extends React.Component<IProps, IState> {
	state: IState = {
		status: Status.NEUTRAL,
		actionId: undefined
	};

	getActionId() {
		return (this.state.actionId || 0) + 1;
	}

	reset = () => {
		// console.log('reset', actionId);

		this.setState({status: Status.NEUTRAL});
	};

	load = (actionId: ActionId) => {
		// console.log('load', actionId);

		this.setState({status: Status.LOADING, actionId});
	};

	success = (actionId: ActionId) => {
		// console.log('success', actionId);

		// don't enter the final state if there's been a more recent load
		if (this.state.actionId !== actionId) return;

		this.setState({status: Status.SUCCESS}, () =>
			this.queueNeutralTransition(actionId)
		);
	};

	error = (actionId: ActionId) => {
		// console.log('error', actionId);

		// don't enter the final state if there's been a more recent load
		if (this.state.actionId !== actionId) return;

		this.setState({status: Status.ERROR});
	};

	private queueNeutralTransition(actionId: ActionId) {
		window.setTimeout(() => {
			// since time has passed, we want to be sure no new action has started
			if (this.state.actionId !== actionId) return;

			this.reset();
		}, 5000);
	}

	render() {
		const containerClass = classnames(
			{
				'tooltip tooltip-top-closer':
					this.state.status === Status.SUCCESS || this.state.status === Status.ERROR
			},
			'actioning-icon',
			this.props.className
		);

		const iconClass = classnames(
			{
				neutral: this.state.status === Status.NEUTRAL,
				'load lnr-sync': this.state.status === Status.LOADING,
				'success lnr-checkmark-circle': this.state.status === Status.SUCCESS,
				'error lnr-warning': this.state.status === Status.ERROR
			},
			'lnr'
		);

		return (
			<div className={containerClass} data-hint={TooltipMessages[this.state.status]}>
				<i className={iconClass} />
			</div>
		);
	}
}
