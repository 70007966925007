import React from 'react';
import classnames from 'classnames';
import {Link, browserHistory as router} from 'react-router';
import Reorder from 'react-reorder';

import * as toolbox from '../../../helpers/toolbox';

import type * as LootDuck from '../../../ducks/loot';

import type {LootOption} from '../../../models/loot-option';

import viewLoot from '../../../images/view-loot.png';

import * as BillingLink from '../../Utils/BillingLink';
import LootDetailsContent from './LootDetailsContent';

export interface IMapProps {
	canUseCustomLootOptions: boolean;

	isSubmitting: boolean;
	isDeleting: boolean;

	options: LootOption[];
	option?: LootOption;

	baseUrl: string;
}

interface IListProps extends IMapProps {
	onReorder: typeof LootDuck.reorderOption;
	onCreate: typeof LootDuck.createOption;
	onUpdate: typeof LootDuck.updateOption;
	onDelete: typeof LootDuck.deleteOption;
}

export default class LootSettings extends React.Component<IListProps> {
	handleReorder = (e: any, oldIndex: number, newIndex: number) => {
		const reordered: LootOption[] = Reorder.reorder(
			this.props.options,
			oldIndex,
			newIndex
		);

		const before = reordered[newIndex + 1];
		const after = reordered[newIndex - 1];
		const order = toolbox.calculateNewOrder(
			before ? before.order : 0,
			after ? after.order : 0
		);

		const option = reordered[newIndex];
		this.props.onReorder(option.id, order);
	};

	render() {
		const options = this.props.options.map((option) => (
			<ListItem
				key={option.id}
				isSelected={this.props.option ? this.props.option.id === option.id : false}
				baseUrl={this.props.baseUrl}
				option={option}
			/>
		));

		return (
			<div className="settings-content no-padding loot-options">
				<div className="settings-split-content">
					<div className="list-content-container">
						<div className="settings-heading">Loot options</div>

						<div className="list-content">
							{this.props.canUseCustomLootOptions && (
								<Link
									to={`${this.props.baseUrl}/new`}
									activeClassName="active"
									className="list-item add-new"
								>
									<div className="name">Create new option</div>
								</Link>
							)}

							{this.props.canUseCustomLootOptions && (
								<div className="help-item">Drag to reorder options</div>
							)}

							<Reorder
								onReorder={this.handleReorder}
								reorderId="guild-setting-loot-options"
								className="list-items-reorder"
								lock="horizontal"
								disabled={!this.props.canUseCustomLootOptions}
								holdTime={150}
							>
								{options}
							</Reorder>
						</div>
					</div>

					{!this.props.canUseCustomLootOptions && <UpgradeView />}

					{this.props.canUseCustomLootOptions && (
						<LootDetailsContent
							onCreate={this.props.onCreate}
							onUpdate={this.props.onUpdate}
							onDelete={this.props.onDelete}
							isSubmitting={this.props.isSubmitting}
							isDeleting={this.props.isDeleting}
							option={this.props.option}
						/>
					)}
				</div>
			</div>
		);
	}
}

interface IItemProps {
	isSelected: boolean;
	option: LootOption;
	className?: string;
	baseUrl: string;
}

function ListItem(props: IItemProps) {
	const {option, baseUrl, className, isSelected, ...rest} = props;
	const itemClass = classnames(
		{
			active: isSelected
		},
		className,
		'list-item'
	);

	return (
		<div
			onClick={() => router.push(`${baseUrl}/${option.id}`)}
			className={itemClass}
			{...rest}
		>
			<div className="name">{option.name}</div>
		</div>
	);
}

function UpgradeView() {
	return (
		<div className="details-content hero-view">
			<img className="hero-icon" src={viewLoot} />

			<div className="hero-description">
				To create create personalized loot options that suit your guild (perhaps the
				loot options you use in-game?) you'll need a buff.
			</div>

			{BillingLink.createCustomLootOptionsLink(undefined, 'large primary button')}
		</div>
	);
}
