import {connect} from 'react-redux';

import * as wow from '../../constants/wow';

import {sortCharactersByName} from '../../helpers/toolbox';

import * as CharacterDuck from '../../ducks/character';
import * as GuildDuck from '../../ducks/guild';

import SelectRoles from './SelectRoles';

interface IOwnProps {
	isEmbeddedView?: boolean;
}

function mapStateToProps(state: IRootState, props: IOwnProps) {
	const guildId = GuildDuck.getActiveGuildId(state.guilds)!;
	let characters = CharacterDuck.getCharactersForGuild(state.characters, guildId);

	// filter out the "pures" that already have their one role selected
	characters = characters.filter((char) => {
		const allowed = wow.CLASS_ALLOWED_ROLES[char.class];
		if (!allowed) return false;

		// if they can be multiple roles we always show them
		if (allowed.length > 1) return true;

		// if they're already the one role they can be we ignore them
		if (char.roles.includes(allowed[0])) return false;

		return true;
	});

	characters.sort(sortCharactersByName);

	return {
		isEmbeddedView: !!props.isEmbeddedView,
		characters,
		guildId
	};
}

export default connect(mapStateToProps, {
	onSelect: CharacterDuck.setRole
})(SelectRoles);
