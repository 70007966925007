import {Classes, Role, Spec, Covenant, SPEC_TO_CLASS, SPEC_TO_ROLE} from './wow';

interface BaseCharacterAbility {
	wowAbilityId: WowAbilityId;
	name: string;
	/** e.g. ability_revendreth_paladin */
	iconName: string;
	/** In seconds */
	cooldown: number;

	specs: Spec[];
	covenant: Covenant | null;

	replacesWowAbilityIds: WowAbilityId[];

	isTalent: boolean;
	isDisabled?: boolean;
}

export interface CharacterAbility extends BaseCharacterAbility {
	classes: Set<Classes>;
	roles: Set<Role>;
}

const baseCharacterAbilities: BaseCharacterAbility[] = [
	// Death Knight
	{
		wowAbilityId: '51052' as WowAbilityId,
		name: 'Anti-Magic Zone',
		iconName: 'spell_deathknight_antimagiczone',
		cooldown: 120,

		specs: [Spec.DK_BLOOD, Spec.DK_FROST, Spec.DK_UNHOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '48707' as WowAbilityId,
		name: 'Anti-Magic Shell',
		iconName: 'spell_shadow_antimagicshell',
		cooldown: 60,

		specs: [Spec.DK_BLOOD, Spec.DK_FROST, Spec.DK_UNHOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	// Demon Hunter
	{
		wowAbilityId: '196718' as WowAbilityId,
		name: 'Darkness',
		iconName: 'ability_demonhunter_darkness',
		cooldown: 180,

		specs: [Spec.DH_HAVOC],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	// Druid
	{
		wowAbilityId: '106898' as WowAbilityId,
		name: 'Stampeding Roar',
		iconName: 'spell_druid_stampedingroar_cat',
		cooldown: 120,

		specs: [
			Spec.DRUID_BALANCE,
			Spec.DRUID_FERAL,
			Spec.DRUID_GUARDIAN,
			Spec.DRUID_RESTO
		],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '319454' as WowAbilityId,
		name: 'Heart of the Wild',
		iconName: 'spell_holy_blessingofagility',
		cooldown: 300,

		specs: [
			Spec.DRUID_BALANCE,
			Spec.DRUID_FERAL,
			Spec.DRUID_GUARDIAN,
			Spec.DRUID_RESTO
		],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '740' as WowAbilityId,
		name: 'Tranquility',
		iconName: 'spell_nature_tranquility',
		cooldown: 180,

		specs: [Spec.DRUID_RESTO],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '33891' as WowAbilityId,
		name: 'Incarnation: Tree of Life',
		iconName: 'ability_druid_improvedtreeform',
		cooldown: 180,

		specs: [Spec.DRUID_RESTO],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '197721' as WowAbilityId,
		name: 'Flourish',
		iconName: 'spell_druid_wildburst',
		cooldown: 90,

		specs: [Spec.DRUID_RESTO],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '323764' as WowAbilityId,
		name: 'Convoke the Spirits',
		iconName: 'ability_ardenweald_druid',
		cooldown: 120,

		specs: [
			Spec.DRUID_BALANCE,
			Spec.DRUID_FERAL,
			Spec.DRUID_GUARDIAN,
			Spec.DRUID_RESTO
		],
		covenant: Covenant.NIGHT_FAE,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	// Hunter

	// Mage

	// Monk
	{
		wowAbilityId: '115310' as WowAbilityId,
		name: 'Revival',
		iconName: 'spell_monk_revival',
		cooldown: 180,

		specs: [Spec.MONK_MISTWEAVER],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '322118' as WowAbilityId,
		name: "Invoke Yu'lon, the Jade Serpent",
		iconName: 'ability_monk_dragonkick',
		cooldown: 180,

		specs: [Spec.MONK_MISTWEAVER],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '310454' as WowAbilityId,
		name: 'Weapons of Order',
		iconName: 'ability_bastion_monk',
		cooldown: 120,

		specs: [Spec.MONK_BREWMASTER, Spec.MONK_MISTWEAVER, Spec.MONK_WINDWALKER],
		covenant: Covenant.KYRIAN,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '116849' as WowAbilityId,
		name: 'Life Cocoon',
		iconName: 'ability_monk_chicocoon',
		cooldown: 120,

		specs: [Spec.MONK_MISTWEAVER],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	// Paladin
	{
		wowAbilityId: '105809' as WowAbilityId,
		name: 'Holy Avenger',
		iconName: 'ability_paladin_holyavenger',
		cooldown: 180,

		specs: [Spec.PALADIN_HOLY, Spec.PALADIN_RET, Spec.PALADIN_PROT],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '216331' as WowAbilityId,
		name: 'Avenging Crusader',
		iconName: 'ability_paladin_veneration',
		cooldown: 120,

		specs: [Spec.PALADIN_HOLY],
		covenant: null,

		replacesWowAbilityIds: ['31884' as WowAbilityId],

		isTalent: true
	},

	{
		wowAbilityId: '31821' as WowAbilityId,
		name: 'Aura Mastery',
		iconName: 'spell_holy_auramastery',
		cooldown: 180,

		specs: [Spec.PALADIN_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '31884' as WowAbilityId,
		name: 'Avenging Wrath',
		iconName: 'spell_holy_avenginewrath',
		cooldown: 120,

		specs: [Spec.PALADIN_PROT, Spec.PALADIN_HOLY, Spec.PALADIN_RET],
		covenant: null,

		replacesWowAbilityIds: ['216331' as WowAbilityId],

		isTalent: false
	},

	{
		wowAbilityId: '316958' as WowAbilityId,
		name: 'Ashen Hallow',
		iconName: 'ability_revendreth_paladin',
		cooldown: 240,

		specs: [Spec.PALADIN_RET, Spec.PALADIN_PROT, Spec.PALADIN_HOLY],
		covenant: Covenant.VENTHYR,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '1022' as WowAbilityId,
		name: 'Blessing of Protection',
		iconName: 'spell_holy_sealofprotection',
		cooldown: 300,

		specs: [Spec.PALADIN_PROT, Spec.PALADIN_HOLY, Spec.PALADIN_RET],
		covenant: null,

		replacesWowAbilityIds: ['204018' as WowAbilityId],

		isTalent: false
	},

	{
		wowAbilityId: '204018' as WowAbilityId,
		name: 'Blessing of Spellwarding',
		iconName: 'spell_holy_blessingofprotection',
		cooldown: 180,

		specs: [Spec.PALADIN_PROT],
		covenant: null,

		replacesWowAbilityIds: ['1022' as WowAbilityId],

		isTalent: true
	},

	{
		wowAbilityId: '642' as WowAbilityId,
		name: 'Divine Shield',
		iconName: 'spell_holy_divineshield',
		cooldown: 300,

		specs: [Spec.PALADIN_PROT, Spec.PALADIN_RET, Spec.PALADIN_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '199448' as WowAbilityId,
		name: 'Blessing of Sacrifice',
		iconName: 'spell_holy_sealofsacrifice',
		cooldown: 120,

		specs: [Spec.PALADIN_PROT, Spec.PALADIN_RET, Spec.PALADIN_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	// Priest
	{
		wowAbilityId: '62618' as WowAbilityId,
		name: 'Power Word: Barrier',
		iconName: 'spell_holy_powerwordbarrier',
		cooldown: 180,

		specs: [Spec.PRIEST_DISC],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '246287' as WowAbilityId,
		name: 'Evangelism',
		iconName: 'spell_holy_divineillumination',
		cooldown: 90,

		specs: [Spec.PRIEST_DISC],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	// {
	// 	wowAbilityId: '34433' as WowAbilityId,
	// 	name: 'Shadowfiend',
	// 	iconName: 'spell_shadow_shadowfiend',
	// 	cooldown: 180,

	// 	specs: [Spec.PRIEST_DISC, Spec.PRIEST_SHADOW],
	// 	covenant: null,

	// 	replacesWowAbilityIds: [],

	// 	isTalent: false
	// },

	{
		wowAbilityId: '47536' as WowAbilityId,
		name: 'Rapture',
		iconName: 'spell_holy_rapture',
		cooldown: 90,

		specs: [Spec.PRIEST_DISC],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '109964' as WowAbilityId,
		name: 'Spirit Shell',
		iconName: 'ability_shaman_astralshift',
		cooldown: 90,

		specs: [Spec.PRIEST_DISC],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '325013' as WowAbilityId,
		name: 'Boon of the Ascended',
		iconName: 'ability_bastion_priest',
		cooldown: 180,

		specs: [Spec.PRIEST_DISC, Spec.PRIEST_HOLY, Spec.PRIEST_SHADOW],
		covenant: Covenant.KYRIAN,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '265202' as WowAbilityId,
		name: 'Holy Word: Salvation',
		iconName: 'ability_priest_archangel',
		cooldown: 300,

		specs: [Spec.PRIEST_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '64843' as WowAbilityId,
		name: 'Divine Hymn',
		iconName: 'spell_holy_divinehymn',
		cooldown: 180,

		specs: [Spec.PRIEST_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '200183' as WowAbilityId,
		name: 'Apotheosis',
		iconName: 'ability_priest_ascension',
		cooldown: 120,

		specs: [Spec.PRIEST_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '15286' as WowAbilityId,
		name: 'Vampiric Embrace',
		iconName: 'spell_shadow_unsummonbuilding',
		cooldown: 120,

		specs: [Spec.PRIEST_SHADOW],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '73325' as WowAbilityId,
		name: 'Leap of Faith',
		iconName: 'priest_spell_leapoffaith_a',
		cooldown: 90,

		specs: [Spec.PRIEST_SHADOW, Spec.PRIEST_DISC, Spec.PRIEST_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '10060' as WowAbilityId,
		name: 'Power Infusion',
		iconName: 'spell_holy_powerinfusion',
		cooldown: 120,

		specs: [Spec.PRIEST_SHADOW, Spec.PRIEST_DISC, Spec.PRIEST_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '33206' as WowAbilityId,
		name: 'Pain Suppression',
		iconName: 'spell_holy_painsupression',
		cooldown: 180,

		specs: [Spec.PRIEST_DISC],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '47788' as WowAbilityId,
		name: 'Guardian Spirit',
		iconName: 'spell_holy_guardianspirit',
		cooldown: 180,

		specs: [Spec.PRIEST_HOLY],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '32375' as WowAbilityId,
		name: 'Mass Dispel',
		iconName: 'spell_arcane_massdispel',
		cooldown: 45,

		specs: [Spec.PRIEST_HOLY, Spec.PRIEST_DISC, Spec.PRIEST_SHADOW],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	// Rogue

	// Shaman
	{
		wowAbilityId: '108280' as WowAbilityId,
		name: 'Healing Tide Totem',
		iconName: 'ability_shaman_healingtide',
		cooldown: 180,

		specs: [Spec.SHAMAN_RESTO],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '98008' as WowAbilityId,
		name: 'Spirit Link Totem',
		iconName: 'spell_shaman_spiritlink',
		cooldown: 180,

		specs: [Spec.SHAMAN_RESTO],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '207399' as WowAbilityId,
		name: 'Ancestral Protection Totem',
		iconName: 'spell_nature_reincarnation',
		cooldown: 300,

		specs: [Spec.SHAMAN_RESTO],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '114049' as WowAbilityId,
		name: 'Ascendance',
		iconName: 'spell_fire_elementaldevastation',
		cooldown: 180,

		specs: [Spec.SHAMAN_RESTO, Spec.SHAMAN_ELE, Spec.SHAMAN_ENHANCE],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '108281' as WowAbilityId,
		name: 'Ancestral Guidance',
		iconName: 'ability_shaman_ancestralguidance',
		cooldown: 120,

		specs: [Spec.SHAMAN_ELE],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	{
		wowAbilityId: '16191' as WowAbilityId,
		name: 'Mana Tide Totem',
		iconName: 'spell_frost_summonwaterelemental',
		cooldown: 180,

		specs: [Spec.SHAMAN_RESTO],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '8143' as WowAbilityId,
		name: 'Tremor Totem',
		iconName: 'spell_nature_tremortotem',
		cooldown: 60,

		specs: [Spec.SHAMAN_RESTO, Spec.SHAMAN_ELE, Spec.SHAMAN_ENHANCE],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	},

	{
		wowAbilityId: '192077' as WowAbilityId,
		name: 'Wind Rush Totem',
		iconName: 'ability_shaman_windwalktotem',
		cooldown: 120,

		specs: [Spec.SHAMAN_RESTO, Spec.SHAMAN_ELE, Spec.SHAMAN_ENHANCE],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: true
	},

	// Warlock

	// Warrior
	{
		wowAbilityId: '97462' as WowAbilityId,
		name: 'Rallying Cry',
		iconName: 'ability_warrior_rallyingcry',
		cooldown: 180,

		specs: [Spec.WARRIOR_ARMS, Spec.WARRIOR_FURY, Spec.WARRIOR_PROT],
		covenant: null,

		replacesWowAbilityIds: [],

		isTalent: false
	}
];

export const characterAbilities: CharacterAbility[] = baseCharacterAbilities.map(
	(baseAbility): CharacterAbility => {
		const classes = baseAbility.specs.map((spec) => SPEC_TO_CLASS[spec]);
		const roles = baseAbility.specs.map((spec) => SPEC_TO_ROLE[spec]);

		return {
			...baseAbility,

			classes: new Set(classes),
			roles: new Set(roles)
		};
	}
);

// Convert `characterAbilities` to be a Map keyed by the ability ID
export const characterAbilityById: Map<WowAbilityId, CharacterAbility> = new Map<
	WowAbilityId,
	CharacterAbility
>(
	characterAbilities.map((ability): [WowAbilityId, CharacterAbility] => [
		ability.wowAbilityId,
		ability
	])
);
