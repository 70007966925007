import {connect} from 'react-redux';

import type {Difficulties} from '@constants/wow';

import {RaidDataDuck, CharacterDuck, LootDuck} from '@ducks';

import type {LootSelection} from '@models/loot-selection';
import type {Character} from '@models/character';
import type {RaidItem, RaidInstance} from '@models/raid-data';
import type {LootOption} from '@models/loot-option';

import type {ISelectOption} from '../../Utils/SelectInput';
import type {IImportedEntry} from '../RclcView';
import {EntriesComponent} from './Entries';

interface IEntryRow {
	wowheadBonus: string | undefined;
	item: RaidItem | undefined;
	character: Character | undefined;
	selection: LootSelection | undefined;
	originalSelectedLootOption: LootOption | undefined;

	/** Unique ID derived from RCLC data */
	id: string;
	difficulty: Difficulties;
	rclcSelectionText: string;
}

export interface IOwnProps {
	onSubmit(rclcSelections: LootDuck.IRclcSelection[]): void;
	onCancel(): void;

	guildId: number;

	isSubmitting: boolean;
	doneUrl: string;
	entries: IImportedEntry[];
}

export interface IMapProps {
	wowheadBonusesBySourceId: WowheadBonusesBySourceId;
	lootSelectionOptions: ISelectOption[];
	rows: IEntryRow[];
}

type WowheadBonusesBySourceId = Map<string, Record<Difficulties, string>>;

/** Build up a lookup map of wowhead bonus IDs */
function getWowheadBonusesMap(instances: RaidInstance[]): WowheadBonusesBySourceId {
	const wowheadBonusesBySourceId: WowheadBonusesBySourceId = new Map<
		string,
		Record<Difficulties, string>
	>();

	// for each boss in each instance, record the wowhead bonus IDs
	instances.forEach((instance) => {
		instance.bosses.forEach((boss) => {
			wowheadBonusesBySourceId.set(boss.id, instance.wowheadBonuses);
		});
	});

	return wowheadBonusesBySourceId;
}

function getRowDataForEntry(
	state: IRootState,
	guildId: number,
	entry: IImportedEntry,
	wowheadBonusesBySourceId: WowheadBonusesBySourceId
): IEntryRow {
	const character = CharacterDuck.getCharacterForName(
		state.characters,
		guildId,
		entry.characterName
	);

	const allCharacterLootSelections = character
		? LootDuck.getSelectionsForCharacter(state.loot, character.id)
		: [];
	const selection = allCharacterLootSelections.find(
		(x) => x.wowItemId === entry.itemId && x.difficulty === entry.difficulty
	);

	const selectedLootOption = selection
		? LootDuck.getOption(state.loot, selection.optionId)
		: undefined;

	const item = RaidDataDuck.getItemForId(state.raidData, entry.itemId);

	return {
		rclcSelectionText: entry.selectionText,
		difficulty: entry.difficulty,
		id: entry.uniqueId,

		wowheadBonus: item
			? wowheadBonusesBySourceId.get(item.sourceId)?.[entry.difficulty]
			: undefined,

		originalSelectedLootOption: selectedLootOption,
		item,
		character,
		selection
	};
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const instances = RaidDataDuck.getAllRaidInstances(state.raidData);
	const wowheadBonusesBySourceId = getWowheadBonusesMap(instances);

	const rows = props.entries.map((entry) =>
		getRowDataForEntry(state, props.guildId, entry, wowheadBonusesBySourceId)
	);

	// sort rows with issues to the start so they're harder to miss
	// rows.sort((a, b) => {
	// 	if (!a.item || !a.character) return -1;
	// 	if (!b.item || !b.character) return 1;

	// 	return 0;
	// });

	const lootOptions = LootDuck.getOptionsForGuild(state.loot, props.guildId, true);
	const lootSelectionOptions: ISelectOption[] = lootOptions.map(
		(option): ISelectOption => ({
			id: option.id,
			name: option.name
		})
	);

	return {
		wowheadBonusesBySourceId,
		lootSelectionOptions,
		rows
	};
}

export const Entries = connect<IMapProps, {}, IOwnProps>(mapStateToProps)(
	EntriesComponent
);
