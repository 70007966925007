import type {IEncounter, CharacterEncounters} from '../../models/boss-kills';

type ICharacterDataByCharacterId = Map<number, IEncounter>;
export type IEncounterDataByEncounterId = Map<string, ICharacterDataByCharacterId>;

export interface IState {
	// XXX keep a record of when data was fetched and somehow refetch
	// it after some period of time

	readonly byEncounterId: IEncounterDataByEncounterId;
}

// types
export const FETCH = 'boss-kills/FETCH';
export const FETCH_SUCCESS = 'boss-kills/FETCH_SUCCESS';
export const FETCH_FAILURE = 'boss-kills/FETCH_FAILURE';

export interface IActions {
	FETCH: {
		readonly type: typeof FETCH;
		readonly payload: {
			encounterInstanceId: string;
		};
	};
	FETCH_FAILURE: {
		readonly type: typeof FETCH_FAILURE;
		readonly payload: {
			encounterInstanceId: string;
		};
	};
	FETCH_SUCCESS: {
		readonly type: typeof FETCH_SUCCESS;
		readonly payload: {
			encounterInstanceId: string;
			bossKills: readonly CharacterEncounters[];
		};
	};
}
