import React from 'react';

import {Regions, REGIONS} from '@constants/wow';

import Loading from '../Utils/Loading';
import Support from '../Utils/Support';

interface IProps {
	region: Regions;
}

export const LoadingCharacters: React.FC<IProps> = (props) => {
	return (
		<div className="page-container">
			<Support />

			<div className="auth-page character-loader">
				<div className="auth-content card">
					<div className="heading">Retrieving updated character list</div>

					<div className="content">
						<div className="centered region">{REGIONS[props.region]}</div>

						<div className="description centered">
							This may take several seconds.
						</div>
					</div>

					<Loading />
				</div>
			</div>
		</div>
	);
};
