import type {Difficulties} from '../../../constants/wow';

import {BossKillsDuck} from '../../../ducks';

import type {IEncounterMode} from '../../../models/boss-kills';

export type BossKillByCharacterId = Map<number, IEncounterMode>;

export function getBossKillByCharacterIdForEncounterId(data: {
	bossKillState: BossKillsDuck.IState;
	difficulty: Difficulties;
	encounterId: string;
}): BossKillByCharacterId {
	const killDataByCharacterId = BossKillsDuck.getkillDataByCharacterIdForEncounter(
		data.bossKillState,
		data.encounterId
	);

	const bossKillByCharacterId: BossKillByCharacterId = new Map<number, IEncounterMode>();

	killDataByCharacterId?.forEach((killData, characterId) => {
		const difficultyKillData = killData[data.difficulty];

		// if there's no data for this difficutly then ignore the character
		if (!difficultyKillData) return;

		bossKillByCharacterId.set(characterId, difficultyKillData);
	});

	return bossKillByCharacterId;
}
