import React from 'react';
import classnames from 'classnames';
// import Textarea from 'react-textarea-autosize';

export type Size = 'small' | 'regular' | 'medium' | 'big';

interface IInputProps {
	onChange?(value: string): void;
	onSubmit?(): void;
	onEsc?(): void;

	size?: Size;

	// type
	// textareaProps

	isTextarea?: boolean;

	defaultValue?: string;
	placeholder?: string;
	autoFocus?: boolean;

	className?: string;
	isDisabled?: boolean;
	isErrored?: boolean;
	isOptional?: boolean;

	label?: string;
	labelHint?: string;
	note?: string | JSX.Element;

	ref?: React.RefObject<Input> | ((ref: Input | null) => void);
}

export default class Input extends React.Component<IInputProps> {
	id: string;
	input?: HTMLInputElement;
	textarea?: HTMLTextAreaElement;

	static defaultProps = {
		type: 'text',
		size: ''
	};

	componentDidMount() {
		this.id = `input-${Date.now()}-${Math.random().toFixed(5)}`;
	}

	getValidationValue() {
		return this.getValue();
	}

	getValue() {
		if (this.props.isDisabled) return this.props.defaultValue || '';

		const ele = this.getElement();
		if (ele) return ele.value.trim();

		return '';
	}

	getElement() {
		if (this.textarea) return this.textarea;
		if (this.input) return this.input;

		return undefined;
	}

	setValue(value = '') {
		const ele = this.getElement();
		if (ele) ele.value = value;
	}

	reset() {
		this.setValue('');
	}

	focus() {
		const ele = this.getElement();
		if (ele) ele.focus();
	}

	isOptional() {
		return this.props.isOptional;
	}

	handleChange = () => {
		const value = this.getValue();

		if (this.props.onChange) this.props.onChange(value);
	};

	handleKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Escape') this.props.onEsc?.();

		if (e.which === 13 && this.props.onSubmit) {
			this.props.onSubmit();
		}
	};

	renderInput() {
		const {
			// type,
			size,

			placeholder,
			autoFocus,

			// textareaProps,
			isTextarea,

			onChange,
			onSubmit,
			onEsc,

			isDisabled,
			isErrored,

			// withActionButton,
			// children,

			// exclude these from getting into props
			isOptional,
			labelHint,
			className,
			note,

			...props
		} = this.props as Omit<IInputProps, 'ref'>;

		const inputClassName = classnames(
			{
				error: isErrored
			},
			size,
			'input'
		);

		const baseProps = {
			className: inputClassName,
			id: this.id,
			placeholder,
			autoFocus,

			onKeyUp: (onSubmit || onEsc) && this.handleKeyUp,
			onChange: onChange && this.handleChange,
			// type,

			...props
		};

		let input;
		if (isDisabled) {
			input = <div {...baseProps}>{this.props.defaultValue}</div>;
		} else if (isTextarea) {
			input = (
				<textarea
					{...baseProps}
					ref={(r: HTMLTextAreaElement) => (this.textarea = r)}
				/>
			);
		} else {
			const inputProps = {
				ref: (r: HTMLInputElement) => (this.input = r),
				...baseProps
			};
			input = <input {...inputProps} />;
		}
		const wrapperClassName = classnames(
			{
				// 'with-action-button': withActionButton
			},
			'input-child-wrapper'
		);

		return <div className={wrapperClassName}>{input}</div>;
	}

	render() {
		const {label, labelHint, note, className, isDisabled} = this.props;

		const hint = labelHint && <span className="hint">{labelHint}</span>;

		const groupClassName = classnames(
			{
				disabled: isDisabled
			},
			className,
			'input-group'
		);

		return (
			<div className={groupClassName}>
				{label && (
					<label htmlFor={this.id}>
						{label} {hint}
					</label>
				)}

				{this.renderInput()}

				{note && <div className="note">{note}</div>}
			</div>
		);
	}
}

export {Input};
