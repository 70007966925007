import {connect} from 'react-redux';

import {GuildSponsorshipDuck, RaidDataDuck, RosterDuck, TagDuck} from '@ducks';

import type {Roster} from '../../../models/roster';

import RosterSettings, {IMapProps} from './RosterSettings';

interface IOwnProps {
	baseUrl: string;
	guildId: number;

	params: {
		rosterId?: string;
	};
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const raidInstances = RaidDataDuck.getAllRaidInstances(state.raidData);

	// add in the name of the instance to each roster
	const rosters = RosterDuck.getRostersForGuild(state.rosters, props.guildId);

	let selectedRoster: Roster | undefined;
	if (props.params.rosterId && props.params.rosterId !== 'new') {
		const rosterId = Number.parseInt(props.params.rosterId, 10);
		selectedRoster = RosterDuck.getRoster(state.rosters, rosterId);
	}

	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		props.guildId
	);
	const canUseMultipleRostersForInstance = fc.canUseMultipleRostersForInstance();
	const canUseTagging = fc.canUseTagging();

	let instanceOptions = raidInstances.map((instance) => ({
		name: instance.name,
		id: instance.id
	}));

	// filter out any instances that already have rosters
	let isHidingInstances = false;
	if (!canUseMultipleRostersForInstance) {
		const filteredOptions = instanceOptions.filter(
			(opt) =>
				(selectedRoster && selectedRoster.wowInstanceId === opt.id) ||
				!rosters.some((r) => r.wowInstanceId === opt.id)
		);

		if (instanceOptions.length !== filteredOptions.length) {
			isHidingInstances = true;
		}

		instanceOptions = filteredOptions;
	}

	const tags = TagDuck.getTagsForGuild(state.tag, props.guildId, true);
	const tagOptions = tags.map((tag) => ({
		name: tag.name,
		id: tag.id
	}));

	return {
		canUseMultipleRostersForInstance,
		canUseTagging,

		isSubmitting: RosterDuck.getRostersSubmitting(state.rosters),
		isDeleting: RosterDuck.getRostersDeleting(state.rosters),
		isHidingInstances,

		baseUrl: `${props.baseUrl}/rosters`,
		settingsUrl: props.baseUrl,
		roster: selectedRoster,
		rosters,

		instanceOptions,
		tagOptions
	};
}

export default connect(mapStateToProps, {
	onCreate: RosterDuck.createRoster,
	onUpdate: RosterDuck.updateRoster,
	onDelete: RosterDuck.deleteRoster
})(RosterSettings);
