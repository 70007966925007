import type {CooldownEventDuck} from '@ducks';

import type {CooldownSheetId} from '@models/cooldownSheet';
import type {CooldownEvent, CooldownEventId} from '@models/cooldownEvent';

/** Get whether a CooldownEvent is in the process of being created */
export function getIsCreatingEvent(state: CooldownEventDuck.State): boolean {
	return state.isCreatingEvent;
}

/** Get whether a CooldownEvent is in the process of having a name update */
export function getIsUpdatingNameForCooldownEventId(
	state: CooldownEventDuck.State,
	cooldownEventId: CooldownEventId
): boolean {
	return !!state.isUpdatingNameById[cooldownEventId];
}

/** Get whether a CooldownEvent is in the process of having a time update */
export function getIsUpdatingTimeForCooldownEventId(
	state: CooldownEventDuck.State,
	cooldownEventId: CooldownEventId
): boolean {
	return !!state.isUpdatingTimeById[cooldownEventId];
}

/** Get a single CooldownEvent based on its ID */
export function getCooldownEventForCooldownEventId(
	state: CooldownEventDuck.State,
	cooldownEventId: CooldownEventId
): CooldownEvent | undefined {
	return state.eventById[cooldownEventId];
}

/** Get a CooldownEvents for a given CooldownSheet ID */
export function getCooldownEventsForCooldownSheetId(
	state: CooldownEventDuck.State,
	cooldownSheetId: CooldownSheetId
): CooldownEvent[] {
	return Object.values(state.eventById)
		.filter((event) => event.cooldownSheetId === cooldownSheetId)
		.sort((a, b) => {
			// during optimistic updates there may be multiple events with the same order so
			// we need to tie break and move the newer one later
			if (a.order === b.order) {
				return b.updatedAt.getTime() - a.updatedAt.getTime(); // DSC
			}

			return a.order - b.order; // ASC
		});
}
