import type {Role, Difficulties} from '@constants/wow';

import {connect, SettingsDuck, RosterBossDuck, CharacterDuck} from '@ducks';

import {getBossKillByCharacterIdForEncounterId} from './bossKillsByCharacterId';
import {getDesiredItemsByCharacterId} from './desiredItemsByCharacterId';
import {CharacterRow, getCharacterRows} from './characterRows';
import {getTotalAssignmentCounts} from './totalAssignmentCounts';
import {getAssignedRoleByCharacterId} from './assignedRole';
import {getAssignmentsByUserId} from './assignmentsByUserId';
import {getCharactersByUserId} from './charactersByUserId';
import {getTaggedCharacterIds} from './taggedCharacterIds';
import {getCharacters} from './characters';

import type {RosterBoss} from '@models/roster-boss';

import {AssignmentControls} from '../AssignmentControls';

interface OwnProps {
	onUpdateLimit: BoundThunk<typeof RosterBossDuck.updateLimit>;
	onRoster: BoundThunk<typeof RosterBossDuck.rosterCharacter>;

	wowEncounterId: WowBossEncounterId;

	/** Whether kill count is enabled for this boss */
	isShowingKillCount: boolean;
	wowheadBonus: string;
	difficulty: Difficulties;
	rosterBoss: RosterBoss;
	includedTagIds: TagId[];
	excludedTagIds: TagId[];
}

interface MapProps {
	selectedRole: Role;
	characterRows: CharacterRow[];
	assignedCount: number;
}

interface DispatchProps {
	onSelectRole: BoundThunk<typeof SettingsDuck.setRosterSelectedRole>;
}

export type Props = OwnProps & MapProps & DispatchProps;

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const selectedRole = SettingsDuck.getRosterSelectedRole(state.settings);
	const isAlphabeticalSort = SettingsDuck.getRosterIsAlphabeticalSort(state.settings);

	const allRoleAssignedCharacterIds = RosterBossDuck.getAssignmentsForBoss(
		state.rosterBosses,
		props.rosterBoss.id
	);
	const assignedCharacterIds = allRoleAssignedCharacterIds[selectedRole];

	const assignedRoleByCharacterId = getAssignedRoleByCharacterId(
		allRoleAssignedCharacterIds
	);

	const {includedTagCharacterIds, excludedTagCharacterIds} = getTaggedCharacterIds(
		state.tag,
		{
			includedTagIds: props.includedTagIds,
			excludedTagIds: props.excludedTagIds
		}
	);

	const guildCharacters = CharacterDuck.getCharactersForGuild(
		state.characters,
		props.rosterBoss.guildId,
		{
			withMissing: true
		}
	);

	const charactersByUserId = getCharactersByUserId(guildCharacters);

	const assignmentsByUserId = getAssignmentsByUserId(
		charactersByUserId,
		assignedRoleByCharacterId
	);

	const allowedCharacters = getCharacters({
		isAlphabeticalSort,
		selectedRole,
		includedTagCharacterIds,
		excludedTagCharacterIds,
		assignedCharacterIds,
		guildCharacters
	});

	const assignedCountByCharacterId = getTotalAssignmentCounts(
		state.rosterBosses,
		props.rosterBoss.rosterId
	);

	const bossKillsByCharacterId = getBossKillByCharacterIdForEncounterId(
		state.bossKills,
		props.difficulty,
		props.wowEncounterId
	);

	const desiredItemsByCharacterId = getDesiredItemsByCharacterId(
		state.loot,
		state.raidData,
		state.droptimizerReports,
		allowedCharacters,
		props.difficulty,
		props.rosterBoss.bossId
	);

	const characterRows = getCharacterRows(state, {
		characters: allowedCharacters,
		desiredItemsByCharacterId,
		bossKillsByCharacterId,
		assignedCountByCharacterId,
		assignedRoleByCharacterId,
		assignmentsByUserId,
		assignedCharacterIds,
		selectedRole
	});

	return {
		assignedCount: assignedCharacterIds.length,
		selectedRole,
		characterRows
	};
}

export const AssignmentControlsSection = connect<MapProps, DispatchProps, OwnProps>(
	mapStateToProps,
	{
		onSelectRole: SettingsDuck.setRosterSelectedRole
	}
)(AssignmentControls);
