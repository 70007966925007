import React from 'react';
import {Link, browserHistory as router} from 'react-router';

import {DifficultyDisplay} from '@constants/wow';

import type {Roster} from '../../models/roster';

import {LinkButton, ButtonGroup} from '../Utils/Button';
import type {IProps} from './RosterListContainer';

export default function RosterList(props: IProps) {
	// if the roster is disabled then redirect them
	if (props.disabledViewRedirect.isDisabled) {
		const redirectUrl = props.disabledViewRedirect.redirectUrl;
		setTimeout(() => router.push(redirectUrl), 10);
		return null;
	}

	const items = props.rosters.map((roster) => (
		<RosterItem key={roster.id} baseUrl={props.baseUrl} roster={roster} />
	));

	return (
		<div className="roster-list-view">
			<div className="heading">
				<h1 className="big">Browse raid rosters</h1>

				{props.isAdmin && (
					<ButtonGroup alignment="center">
						<LinkButton
							to={props.guildSettingsRostersUrl}
							className="underlined link"
						>
							Manage raid rosters
						</LinkButton>
					</ButtonGroup>
				)}
			</div>

			<div className="content">{items}</div>
		</div>
	);
}

interface IItemProps {
	roster: Roster;
	baseUrl: string;
}

function RosterItem(props: IItemProps) {
	return (
		<Link to={`${props.baseUrl}/roster/${props.roster.id}`} className="browser-item">
			<div className="name">{props.roster.name}</div>

			<div className="details">
				<div className="instance-name">{props.roster.wowInstanceName}</div>

				<div className="difficulty">{DifficultyDisplay[props.roster.difficulty]}</div>
			</div>
		</Link>
	);
}
