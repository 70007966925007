import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router';

import {CLASS_TO_DISPLAY} from '../../constants/wow';

import {makeAvatarUrl} from '../../helpers/toolbox';

import type {Character} from '../../models/character';

function getUrlForCharacterId(baseUrl: string, characterId: number) {
	const charPart = `c=${characterId}`;
	const joinChar = baseUrl.includes('?') ? '&' : '?';

	return `${baseUrl}${joinChar}${charPart}`;
}

interface IOptionProps {
	character: Character;
	url: string;
}

const Option: React.FC<IOptionProps> = (props) => {
	const characterClass = classnames(
		props.character.class,
		'character wow-style bg-before'
	);

	return (
		<Link className={characterClass} to={props.url} activeClassName="active">
			<img src={makeAvatarUrl(props.character.thumbnail, props.character.region)} />

			<div className="details">
				<div className="name">{props.character.name}</div>

				<div className="secondary">{CLASS_TO_DISPLAY[props.character.class]}</div>
			</div>
		</Link>
	);
};

interface IPaneProps {
	characters: Character[];
	baseLootUrl: string;
}

export const Pane: React.FC<IPaneProps> = (props) => {
	const options = props.characters.map((char) => (
		<Option
			key={char.id}
			character={char}
			url={getUrlForCharacterId(props.baseLootUrl, char.id)}
		/>
	));

	return <div className="your-loot-sheet-selector">{options}</div>;
};

interface IProps {
	characters: Character[];
	baseLootUrl: string;
	isActive: boolean;
}

export const YourLootSheet: React.FC<IProps> = (props) => {
	const hasMultipleCharacters = props.characters.length !== 1;
	const url = hasMultipleCharacters
		? '' // don't want this to actually be a link in this case
		: getUrlForCharacterId(props.baseLootUrl, props.characters[0].id);

	const linkClassName = classnames(
		{
			active: props.isActive
		},
		'nav-item'
	);

	return (
		<div className="your-loot-sheet">
			<Link className={linkClassName} to={url}>
				Your Loot Sheet
			</Link>

			{hasMultipleCharacters && (
				<Pane characters={props.characters} baseLootUrl={props.baseLootUrl} />
			)}
		</div>
	);
};
