import React from 'react';

import {ConfirmModal} from '@components/Utils/ConfirmModal';

interface Props {
	onSubmit(): Promise<void>;
	onClose(): void;

	bossName: string;
}

export const ClearBossModal: React.FC<Props> = (props) => {
	const [isSubmitting, setIsSubmitting] = React.useState(false);

	async function handleSubmit() {
		setIsSubmitting(true);

		await props.onSubmit();

		setIsSubmitting(false);
		props.onClose();
	}

	return (
		<ConfirmModal
			onSubmit={handleSubmit}
			onClose={props.onClose}
			isSubmitting={isSubmitting}
			heading="Remove all assignments"
			submitColour="red"
			submitButtonText="Remove all"
		>
			Are you sure you want to remove all of the assignments from {props.bossName}?
		</ConfirmModal>
	);
};
