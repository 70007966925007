import React from 'react';
import Select, {components, InputProps} from 'react-select';
import classnames from 'classnames';

import type {Size} from './Input';

export interface ISelectOption {
	label: string;
	value: any;
}

const SelectInput: React.FC<InputProps<ISelectOption, false>> = (props) => {
	// @ts-expect-error Include custom input size prop
	const {customInputSize} = props.selectProps;

	return (
		<components.Input
			{...props}
			className={`${props.className || ''} input ${customInputSize}`}
		/>
	);
};

interface IProps {
	onChange(opt: any): void;

	size?: Size;
	className?: string;

	options: ISelectOption[];
	placeholder: string;
	value: any;
}

export function FilterSelect(props: IProps) {
	const size = props.size ?? 'big';

	const selectedValue =
		(props.value && props.options.find((x) => x.value === props.value)) || undefined;

	const selectClass = classnames(
		'react-select filter-select-input-group',
		size,
		props.className
	);

	return (
		<Select
			onChange={props.onChange}
			placeholder={props.placeholder}
			className={selectClass}
			classNamePrefix="react-select-prefix"
			options={props.options}
			value={selectedValue}
			blurInputOnSelect={true}
			openMenuOnFocus={true}
			isClearable={true}
			noOptionsMessage={() => 'No results found'}
			components={{
				IndicatorSeparator: null,
				Input: SelectInput
			}}
			// @ts-expect-error Include custom input size prop
			customInputSize={size}
		/>
	);
}

interface IWrappedProps extends IProps {
	label: string;
}

export const LabelledFilterSelect: React.FC<IWrappedProps> = (props) => {
	return (
		<div className="input-group">
			<label>{props.label}</label>

			<FilterSelect {...props} />
		</div>
	);
};
