import React from 'react';

import {Button, ButtonGroup} from '../../Utils/Button';

interface IProps {
	onSelect(): void;

	isCurrent: boolean;
	isLoading: boolean;
	isDisabled: boolean;

	title: string;
	cta: string;
	description: string;
	priceText: string;
	period: string;
}

export const Plan: React.FC<IProps> = (props) => {
	return (
		<div className="plan">
			<div className="title">{props.title}</div>
			<div className="description">{props.description}</div>

			<div className="price-section">
				<span className="price">{props.priceText}</span>
				<span className="period">/ {props.period}</span>
			</div>

			<ButtonGroup alignment="center">
				{props.isCurrent ? (
					<Button isDisabled={true} className="primary">
						Current plan
					</Button>
				) : (
					<Button
						onClick={props.onSelect}
						isLoading={props.isLoading}
						isDisabled={props.isDisabled}
						className="primary"
					>
						{props.cta}
					</Button>
				)}
			</ButtonGroup>
		</div>
	);
};
