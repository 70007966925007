import React from 'react';

import type {IProps} from './ManageSponsorshipsContainer';
import {SponsorshipItem} from './SponsorshipItem';
import {NoBuffsWarning} from './NoBuffsWarning';

export const ManageSponsorships: React.FC<IProps> = (props) => {
	const canSponsorMore = props.sponsoredCount < props.maxSponsoredCount;

	const items = props.items.map((item) => (
		<SponsorshipItem
			key={item.guildId}
			onUnsponsor={props.onUnsponsor}
			onSponsor={props.onSponsor}
			isUnsponsoring={props.unsponsoringGuildId === item.guildId}
			isSponsoring={props.sponsoringGuildId === item.guildId}
			canSponsorMore={canSponsorMore}
			isSponsored={item.isSponsored}
			guildRealm={item.guildRealm}
			guildName={item.guildName}
			guildId={item.guildId}
		/>
	));

	return (
		<div className="buffed-guilds">
			<div className="buffs-info">
				<div className="buff-usage">
					<span className="stats">
						{props.sponsoredCount} / {props.maxSponsoredCount}
					</span>
					&#32;guild buffs used
				</div>

				{!props.sponsoredCount && <NoBuffsWarning />}

				<div className="buffs">{items}</div>
			</div>
		</div>
	);
};
