import React from 'react';
import classnames from 'classnames';

import * as wow from '../../../constants/wow';

import * as images from '../../../helpers/images';

interface IRoleFilterProps {
	onClick(role: wow.Role, isSelected: boolean): void;

	selectedRoles: wow.Role[];
	charClass: wow.Classes;
}

export const RoleFilter: React.FC<IRoleFilterProps> = (props) => {
	const allowedRoles = wow.ROLES_ORDERED.filter((role) =>
		wow.CLASS_ALLOWED_ROLES[props.charClass].includes(role)
	);

	// if there's no choice to be made then the controls can be hidden
	if (allowedRoles.length <= 1) return null;

	const roleBoxes = allowedRoles.map((role) => {
		const isSelected = props.selectedRoles.includes(role);

		const roleClass = classnames(
			{
				selected: isSelected
			},
			'role tooltip tooltip-top delayed'
		);

		return (
			<div
				key={role}
				onClick={() => props.onClick(role, !isSelected)}
				data-hint={wow.ROLES_TO_DISPLAY[role]}
				className={roleClass}
			>
				<img src={images.getRoleImageUrl(role)} />
			</div>
		);
	});

	return (
		<div className="role-filter-section">
			<div className="label">Show loot for roles:</div>

			<div className="role-selection">{roleBoxes}</div>
		</div>
	);
};
