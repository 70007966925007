import type {ICreateExternalCharacterInput, IUpdateExternalCharacterInput} from '$types';

import type {Role} from '@constants/wow';
import * as rpc from '../../shared/constants/rpc';
import * as feed from '../constants/feed';

import * as toolbox from '../helpers/toolbox';
import api from '../helpers/api';

import * as BannerDuck from './banner';
import * as GuildDuck from './guild';
import * as DemoDuck from './demo';
import type {RootAction} from '.';

import {Character} from '../models/character';
import type {User} from '../models/user';

interface IById {
	[key: number]: Character;
	[key: string]: Character;
}

export interface IState {
	readonly byId: IById;

	readonly restoringIds: number[];
	readonly isRevoking: boolean;
	readonly isAddingExternal: boolean;
	readonly isUpdatingExternal: boolean;
}

// types
const SET_ROLE = 'character/SET_ROLE';
const SET_ROLE_SUCCESS = 'character/SET_ROLE_SUCCESS';
const SET_ROLE_FAILURE = 'character/SET_ROLE_FAILURE';

const RESTORE = 'character/RESTORE';
const RESTORE_SUCCESS = 'character/RESTORE_SUCCESS';
const RESTORE_FAILURE = 'character/RESTORE_FAILURE';

const REVOKE = 'character/REVOKE';
const REVOKE_SUCCESS = 'character/REVOKE_SUCCESS';
const REVOKE_FAILURE = 'character/REVOKE_FAILURE';

const ADD_EXTERNAL = 'character/ADD_EXTERNAL';
const ADD_EXTERNAL_SUCCESS = 'character/ADD_EXTERNAL_SUCCESS';
const ADD_EXTERNAL_FAILURE = 'character/ADD_EXTERNAL_FAILURE';

const UPDATE_EXTERNAL = 'character/UPDATE_EXTERNAL';
const UPDATE_EXTERNAL_SUCCESS = 'character/UPDATE_EXTERNAL_SUCCESS';
const UPDATE_EXTERNAL_FAILURE = 'character/UPDATE_EXTERNAL_FAILURE';

export interface IActions {
	SET_ROLE: {
		readonly type: typeof SET_ROLE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			character: Character;
		};
	};

	SET_ROLE_SUCCESS: {
		readonly type: typeof SET_ROLE_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};

	SET_ROLE_FAILURE: {
		readonly type: typeof SET_ROLE_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	RESTORE: {
		readonly type: typeof RESTORE;
		readonly payload: {
			id: number;
		};
	};
	RESTORE_SUCCESS: {
		readonly type: typeof RESTORE_SUCCESS;
		readonly payload: {
			id: number;
		};
	};
	RESTORE_FAILURE: {
		readonly type: typeof RESTORE_FAILURE;
		readonly payload: {
			id: number;
		};
	};

	REVOKE: {readonly type: typeof REVOKE};
	REVOKE_SUCCESS: {readonly type: typeof REVOKE_SUCCESS};
	REVOKE_FAILURE: {readonly type: typeof REVOKE_FAILURE};

	ADD_EXTERNAL: {readonly type: typeof ADD_EXTERNAL};
	ADD_EXTERNAL_SUCCESS: {readonly type: typeof ADD_EXTERNAL_SUCCESS};
	ADD_EXTERNAL_FAILURE: {readonly type: typeof ADD_EXTERNAL_FAILURE};

	UPDATE_EXTERNAL: {readonly type: typeof UPDATE_EXTERNAL};
	UPDATE_EXTERNAL_SUCCESS: {readonly type: typeof UPDATE_EXTERNAL_SUCCESS};
	UPDATE_EXTERNAL_FAILURE: {readonly type: typeof UPDATE_EXTERNAL_FAILURE};
}

// selectors
interface IGetCharacterOptions {
	withUnimported?: boolean;
	withUnrostered?: boolean;
	withoutExternal?: boolean;
	withMissing?: boolean;
}

export function getCharactersForGuild(
	state: IState,
	guildId: number | undefined,
	opts: IGetCharacterOptions = {}
) {
	return Object.values(state.byId).filter((char) => {
		if (char.guildId !== guildId) return false;

		if (!opts.withUnrostered && !char.isRostered) return false;
		if (!opts.withUnimported && !char.isImported) return false;
		if (opts.withoutExternal && char.isExternal) return false;
		if (!opts.withMissing && char.isMissing) return false;

		return true;
	});
}

export function getAllCharactersForGuild(state: IState, guildId: number | undefined) {
	return getCharactersForGuild(state, guildId, {
		withUnimported: true,
		withUnrostered: true,
		withoutExternal: false,
		withMissing: true
	});
}

export function getRosteredExternalCharactersForGuild(state: IState, guildId: number) {
	return Object.values(state.byId).filter((char) => {
		if (char.guildId !== guildId) return false;

		if (!char.isExternal) return false;
		if (!char.isRostered) return false;

		return true;
	});
}

export function getMissingRosteredExternalCharacters(state: IState, guildId: number) {
	return getRosteredExternalCharactersForGuild(state, guildId).filter(
		(x) => x.isMissing
	);
}

export function getCharactersForUser(state: IState, guildId: number, user: User) {
	const keys = user.createComboKeys();

	return Object.values(state.byId).filter(
		(char) =>
			!char.isMissing &&
			char.isRostered &&
			char.guildId === guildId &&
			keys.includes(char.combo)
	);
}

export function getCharacterIdsForUser(state: IState, guildId: number, user: User) {
	return getCharactersForUser(state, guildId, user).map((char) => char.id);
}

export function getCharacterForId(
	state: IState,
	characterId: CharacterId
): Character | undefined {
	return state.byId[characterId];
}

export function getRevoking(state: IState) {
	return state.isRevoking;
}

export function getRestoringIds(state: IState) {
	return [...state.restoringIds];
}

export function getIsAddingExternal(state: IState) {
	return state.isAddingExternal;
}

export function getIsUpdatingExternal(state: IState) {
	return state.isUpdatingExternal;
}

function getCharacter(state: IState, id: number): Character | undefined {
	return state.byId[id];
}

export function getCharacterForName(state: IState, guildId: number, name: string) {
	return Object.values(state.byId).find((char) => {
		if (char.name !== name) return false;
		if (char.guildId !== guildId) return false;

		return true;
	});
}

// actions
export function setRole(id: CharacterId, role: Role, isSelected: boolean): Thunk<void> {
	return (dispatch, getState) => {
		const character = getCharacter(getState().characters, id);
		if (!character) return Promise.resolve();

		const newRoles = isSelected
			? [...character.roles, role]
			: character.roles.filter((r) => r !== role);

		const optimisticId = toolbox.optimistic.getId();
		const action: IActions['SET_ROLE'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: SET_ROLE,
			payload: {
				character: new Character({
					...character,
					roles: newRoles
				})
			}
		};

		dispatch(action);

		const payload = {
			isSelected,
			role,
			id
		};

		return api.call(rpc.CHARACTERS_SET_ROLE, payload).then(
			() => {
				const successAction: IActions['SET_ROLE_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: SET_ROLE_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: IActions['SET_ROLE_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: SET_ROLE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function revoke(id: number, cb?: () => void): Thunk<void> {
	return (dispatch) => {
		dispatch<IActions['REVOKE']>({type: REVOKE});

		return api.call(rpc.CHARACTERS_REVOKE, {id}).then(
			() => {
				if (cb) cb();

				dispatch<IActions['REVOKE_SUCCESS']>({
					type: REVOKE_SUCCESS
				});
			},

			(message) => {
				dispatch(BannerDuck.addErrorBanner(message.error));
				dispatch<IActions['REVOKE_FAILURE']>({
					type: REVOKE_FAILURE
				});
			}
		);
	};
}

export function restore(id: number): Thunk<void> {
	return (dispatch) => {
		const action: IActions['RESTORE'] = {
			type: RESTORE,
			payload: {id}
		};

		dispatch(action);

		return api.call(rpc.CHARACTERS_RESTORE, {id}).then(
			() => {
				const successAction: IActions['RESTORE_SUCCESS'] = {
					type: RESTORE_SUCCESS,
					payload: {id}
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: IActions['RESTORE_FAILURE'] = {
					type: RESTORE_FAILURE,
					payload: {id}
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function addExternal(
	data: {name: string; realm: string},
	cb?: () => void
): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);
		if (!guildId) return Promise.reject();

		dispatch<IActions['ADD_EXTERNAL']>({
			type: ADD_EXTERNAL
		});

		const payload: ICreateExternalCharacterInput = {
			guildId,
			name: data.name,
			realm: data.realm
		};

		return api.call(rpc.CHARACTERS_ADD_EXTERNAL, payload).then(
			() => {
				if (cb) cb();

				dispatch(BannerDuck.addSuccessBanner('Character added'));
				dispatch<IActions['ADD_EXTERNAL_SUCCESS']>({
					type: ADD_EXTERNAL_SUCCESS
				});
			},

			(message) => {
				dispatch(BannerDuck.addErrorBanner(message.error));
				dispatch<IActions['ADD_EXTERNAL_FAILURE']>({
					type: ADD_EXTERNAL_FAILURE
				});
			}
		);
	};
}

export function updateExternal(
	characterId: number,
	data: {name: string; realm: string},
	cb?: () => void
): Thunk<void> {
	return (dispatch) => {
		dispatch<IActions['UPDATE_EXTERNAL']>({
			type: UPDATE_EXTERNAL
		});

		const payload: IUpdateExternalCharacterInput = {
			characterId,
			name: data.name,
			realm: data.realm
		};

		return api.call(rpc.CHARACTERS_UPDATE_EXTERNAL, payload).then(
			() => {
				if (cb) cb();

				dispatch(BannerDuck.addSuccessBanner('Character updated'));
				dispatch<IActions['UPDATE_EXTERNAL_SUCCESS']>({
					type: UPDATE_EXTERNAL_SUCCESS
				});
			},

			(message) => {
				dispatch(BannerDuck.addErrorBanner(message.error));
				dispatch<IActions['UPDATE_EXTERNAL_FAILURE']>({
					type: UPDATE_EXTERNAL_FAILURE
				});
			}
		);
	};
}

// reducer
const initialState: IState = {
	byId: {},

	restoringIds: [],
	isRevoking: false,
	isAddingExternal: false,
	isUpdatingExternal: false
};

export default function reducer(state = initialState, action: RootAction): IState {
	switch (action.type) {
		// guild payload
		case GuildDuck.FETCH_DATA_SUCCESS:
		case DemoDuck.FETCH_SUCCESS: {
			const charactersById: {[key: number]: Character} = {};
			action.payload.characters.forEach((char) => {
				charactersById[char.id] = char;
			});

			return {
				...state,

				// remove characters from other guilds
				byId: charactersById
			};
		}

		// set role
		case SET_ROLE: {
			return {
				...state,

				byId: {
					...state.byId,
					[action.payload.character.id]: action.payload.character
				}
			};
		}

		// restore character
		case RESTORE: {
			return {
				...state,
				restoringIds: [...state.restoringIds, action.payload.id]
			};
		}

		case RESTORE_SUCCESS:
		case RESTORE_FAILURE: {
			return {
				...state,
				restoringIds: state.restoringIds.filter((id) => id !== action.payload.id)
			};
		}

		// revoke character
		case REVOKE: {
			return {
				...state,
				isRevoking: true
			};
		}

		case REVOKE_SUCCESS:
		case REVOKE_FAILURE: {
			return {
				...state,
				isRevoking: false
			};
		}

		// add external character
		case ADD_EXTERNAL: {
			return {
				...state,
				isAddingExternal: true
			};
		}

		case ADD_EXTERNAL_SUCCESS:
		case ADD_EXTERNAL_FAILURE: {
			return {
				...state,
				isAddingExternal: false
			};
		}

		// update external character
		case UPDATE_EXTERNAL: {
			return {
				...state,
				isUpdatingExternal: true
			};
		}

		case UPDATE_EXTERNAL_SUCCESS:
		case UPDATE_EXTERNAL_FAILURE: {
			return {
				...state,
				isUpdatingExternal: false
			};
		}

		// feed
		case feed.CHARACTER_INSERT:
		case feed.CHARACTER_UPDATE: {
			return {
				...state,

				byId: {
					...state.byId,
					[action.payload.newRecord.id]: new Character(action.payload.newRecord)
				}
			};
		}

		case feed.CHARACTER_DELETE: {
			const byId: IById = {};
			Object.values(state.byId).forEach((char) => {
				if (char.id === action.payload.oldRecord.id) return;

				byId[char.id] = char;
			});

			return {
				...state,
				byId
			};
		}

		default:
			return state;
	}
}
