import {Role, ROLES_ORDERED} from '@constants/wow';

import {connect, RosterBossDuck, CharacterDuck, SettingsDuck} from '@ducks';

import {sortCharactersByClassAndName, sortCharactersByName} from '@helpers/toolbox';

import type {Character} from '@models/character';
import type {RosterBoss} from '@models/roster-boss';

import {CurrentAssignments as Comp} from './CurrentAssignments';

interface RoleGroupData {
	role: Role;
	assignedCharacters: Character[];
	lastUpdatedAt: number | undefined;
}

interface OwnProps {
	onRoster: BoundThunk<typeof RosterBossDuck['rosterCharacter']>;
	onSearchCharacter(charId: CharacterId): void;

	isShowingAssignmentControls: boolean;
	rosterBoss: RosterBoss;
}

interface MapProps {
	canRemoveCharacters: boolean;
	roleGroupData: RoleGroupData[];
}

export type Props = OwnProps & MapProps;

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const isAlphabeticalSort = SettingsDuck.getRosterIsAlphabeticalSort(state.settings);

	const assignedCharacterIdsByRole = RosterBossDuck.getAssignmentsForBoss(
		state.rosterBosses,
		props.rosterBoss.id
	);

	const roleGroupData = ROLES_ORDERED.map((role): RoleGroupData => {
		const characters = assignedCharacterIdsByRole[role]
			.reduce<Character[]>((chars, charId) => {
				const char = CharacterDuck.getCharacterForId(state.characters, charId);
				if (!char) return chars;

				return [...chars, char];
			}, [])
			.sort(isAlphabeticalSort ? sortCharactersByName : sortCharactersByClassAndName);

		return {
			assignedCharacters: characters,
			lastUpdatedAt: props.rosterBoss.roleUpdatedAt[role],
			role
		};
	});

	return {
		canRemoveCharacters: props.isShowingAssignmentControls,
		roleGroupData
	};
}

export const CurrentAssignments = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(Comp);
