import {browserHistory as router} from 'react-router';

import type {RpcSetupSubmitInput, RpcSetupSubmitResult} from 'api-types';

import * as rpc from '../../shared/constants/rpc';

import api from '../helpers/api';

import * as BannerDuck from './banner';
import * as GuildDuck from './guild';
import type {RootAction} from '.';

import {SetupCharacter, SetupGuild} from '../models/setup';
import {Guild} from '../models/guild';

export interface IState {
	readonly isSubmitting: boolean;
	readonly isLoading: boolean;

	readonly isAlreadySetup: boolean;
	readonly is404: boolean;

	readonly region?: string;
	readonly realm?: string;
	readonly name?: string;

	readonly members?: SetupCharacter[];
	readonly guild?: SetupGuild;
	readonly id?: number;
}

// types
const RESET = 'setup/RESET';

const INITIATE = 'setup/INITIATE';
const INITIATE_SUCCESS = 'setup/INITIATE_SUCCESS';
const INITIATE_FAILURE = 'setup/INITIATE_FAILURE';

const SUBMIT = 'setup/SUBMIT';
const SUBMIT_SUCCESS = 'setup/SUBMIT_SUCCESS';
const SUBMIT_FAILURE = 'setup/SUBMIT_FAILURE';

export interface IActions {
	RESET: {
		readonly type: typeof RESET;
		readonly payload: {
			readonly region: string;
			readonly realm: string;
			readonly name: string;
		};
	};

	INITIATE: {
		readonly type: typeof INITIATE;
	};

	INITIATE_SUCCESS: {
		readonly type: typeof INITIATE_SUCCESS;
		readonly payload: {
			readonly id: number;
			readonly members: SetupCharacter[];
			readonly guild: SetupGuild;
		};
	};

	INITIATE_FAILURE: {
		readonly type: typeof INITIATE_FAILURE;
		readonly payload: {
			readonly isAlreadySetup: boolean;
			readonly is404: boolean;
		};
	};

	SUBMIT: {
		readonly type: typeof SUBMIT;
	};

	SUBMIT_SUCCESS: {
		readonly type: typeof SUBMIT_SUCCESS;
	};

	SUBMIT_FAILURE: {
		readonly type: typeof SUBMIT_FAILURE;
	};
}

// selectors
export function getSetupSubmitting(state: IState) {
	return state.isSubmitting;
}

export function getSetupLoading(state: IState) {
	return state.isLoading;
}

export function getSetupAlreadySetup(state: IState) {
	return state.isAlreadySetup;
}

export function getSetup404(state: IState) {
	return state.is404;
}

export function getSetupGuild(state: IState) {
	return state.guild;
}

export function getSetupMembers(state: IState) {
	return state.members ? [...state.members] : [];
}

// actions
interface ISetupReset {
	region: string;
	realm: string;
	name: string;
}

export function resetSetup(data: ISetupReset): IActions['RESET'] {
	return {
		type: RESET,
		payload: {...data}
	};
}

export function fetchData(): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		if (getSetupLoading(state.setup)) return Promise.resolve();

		const action: IActions['INITIATE'] = {type: INITIATE};
		dispatch(action);

		const payload = {
			region: state.setup.region,
			realm: state.setup.realm,
			name: state.setup.name
		};

		return api.call(rpc.SETUP_INITIATE, payload).then(
			(message) => {
				const successAction: IActions['INITIATE_SUCCESS'] = {
					type: INITIATE_SUCCESS,
					payload: {
						members: message.data.members.map(
							(char: any) => new SetupCharacter(char)
						),
						guild: new SetupGuild(message.data.guild),
						id: message.data.id
					}
				};

				dispatch(successAction);
			},

			(message) => {
				const isAlreadySetup = message.error === 'already-set-up';
				const is404 = message.error === 'not-found';

				const failureAction: IActions['INITIATE_FAILURE'] = {
					type: INITIATE_FAILURE,
					payload: {
						isAlreadySetup,
						is404
					}
				};

				dispatch(failureAction);

				// only show banner if it isn't an error we properly handle
				if (!isAlreadySetup && !is404) {
					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			}
		);
	};
}

interface ISubmitData {
	rankNames: string[];
	importCharacterCombos: string[];
}

export function submitData(data: ISubmitData): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		if (!state.setup.id || getSetupSubmitting(state.setup)) {
			return Promise.resolve();
		}

		const action: IActions['SUBMIT'] = {type: SUBMIT};
		dispatch(action);

		const payload = {
			id: state.setup.id,
			rankNames: data.rankNames,
			importCharacterCombos: data.importCharacterCombos
		};

		return api
			.call<RpcSetupSubmitInput, RpcSetupSubmitResult>(rpc.SETUP_SUBMIT, payload)
			.then(
				(message) => {
					// we won't have the guild they just joined in the cache at this point
					// so just refetch all the guilds to ensure its there and not show the
					// error message while waiting for the changefeed
					void dispatch(GuildDuck.fetchGuilds());

					const successAction: IActions['SUBMIT_SUCCESS'] = {type: SUBMIT_SUCCESS};
					dispatch(successAction);

					const baseUrl = Guild.createBaseUrl(message.data.guild.id);
					router.push(`${baseUrl}/select-roles`);
				},

				(message) => {
					const failureAction: IActions['SUBMIT_FAILURE'] = {
						type: SUBMIT_FAILURE
					};

					dispatch(failureAction);
					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

// reducer
const initialState: IState = {
	isSubmitting: false,
	isLoading: false,

	isAlreadySetup: false,
	is404: false,

	region: undefined,
	realm: undefined,
	name: undefined,

	members: undefined,
	guild: undefined,
	id: undefined
};

export default function reducer(
	state: IState = initialState,
	action: RootAction
): IState {
	switch (action.type) {
		// reset
		case RESET: {
			return {
				isSubmitting: false,
				isLoading: false,
				isAlreadySetup: false,
				is404: false,
				region: action.payload.region,
				realm: action.payload.realm,
				name: action.payload.name,
				members: undefined,
				guild: undefined,
				id: undefined
			};
		}

		// init
		case INITIATE: {
			return {
				...state,
				isLoading: true
			};
		}

		case INITIATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				members: action.payload.members,
				guild: action.payload.guild,
				id: action.payload.id
			};
		}

		case INITIATE_FAILURE: {
			return {
				...state,
				isLoading: false,
				isAlreadySetup: action.payload.isAlreadySetup,
				is404: action.payload.is404
			};
		}

		// submit
		case SUBMIT: {
			return {
				...state,
				isSubmitting: true
			};
		}

		case SUBMIT_SUCCESS: {
			return {
				...state,
				isSubmitting: false
			};
		}

		case SUBMIT_FAILURE: {
			return {
				...state,
				isSubmitting: false
			};
		}

		default:
			return state;
	}
}
