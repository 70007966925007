import type {BillingStatus} from '../constants';
import {ProductTypes} from './plans';

type AllProductTypes = `${ProductTypes}` | 'free';

export enum FeatureLimits {
	LIMITED_EXTERNAL_CHARACTERS = 0
}

export enum Features {
	/** more than one roster for each instance */
	MULTIPLE_ROSTERS_FOR_INSTANCE = 'multiple_rosters_for_instance',

	/** show the basic (numbers etc) loot info on roster characters */
	SHOW_BASIC_LOOT_IN_ROSTER = 'show_basic_loot_in_roster',

	/** hide characters from certain roles in certain rosters */
	// IGNORE_CHARACTERS_IN_ROSTERS = 'ignore_characters_in_rosters',

	/** hide ranks from certain roles in certain rosters */
	// IGNORE_RANKS_IN_ROSTERS = 'ignore_ranks_in_rosters',

	/** create place holder characters to be used in the roster / loot sheet */
	// PLACEHOLDER_CHARACTERS = 'placeholder_characters',

	/** personalise the loot options selectable in the loot sheet */
	CUSTOM_LOOT_OPTIONS = 'custom_loot_otpions',

	/** allow grouping characters by tag to filter rosters */
	TAGGING = 'tagging',

	/** Use a limited about of External Characters */
	LIMITED_EXTERNAL_CHARACTERS = 'limited_external_characters',

	/** Use a unlimited about of External Characters */
	UNLIMITED_EXTERNAL_CHARACTERS = 'unlimited_external_characters',

	/** RCLootCouncil addon loot import */
	RCLC_IMPORT = 'rclc_import'
}

const buffedGuildPerks: Features[] = [
	Features.CUSTOM_LOOT_OPTIONS,
	Features.MULTIPLE_ROSTERS_FOR_INSTANCE,
	Features.TAGGING,

	// beta
	Features.UNLIMITED_EXTERNAL_CHARACTERS,
	Features.RCLC_IMPORT
];

const FeatureTable: Record<AllProductTypes, Features[]> = {
	free: [Features.SHOW_BASIC_LOOT_IN_ROSTER],

	// inherits from free plan
	[ProductTypes.MULTIPLE]: buffedGuildPerks,
	[ProductTypes.SINGLE]: buffedGuildPerks
};

interface ISponsorship {
	productType: ProductTypes | null;
	status: BillingStatus | null;
}

export class FeatureChecker {
	activeProductTypes: Record<string, boolean> = {};
	activeFeatures: Set<Features> = new Set();

	constructor(data: {
		sponsorships: ISponsorship[];
		additionalAccessFeatures: Features[];
	}) {
		this.activeProductTypes.free = true;
		// this.activeProductTypes[ProductTypes.SINGLE] = true;

		// mark each (active) sponsorship's plan as an active plan
		data.sponsorships.forEach((sponsorship) => {
			// don't count inactive sponsorships
			if (!sponsorship.status || !sponsorship.productType) return;

			this.activeProductTypes[sponsorship.productType] = true;
		});

		const activeTypes = Object.keys(this.activeProductTypes) as AllProductTypes[];

		[
			// mark each active plan's features as active features
			...activeTypes.reduce<Features[]>((features, planType): Features[] => {
				return [...features, ...(FeatureTable[planType] || [])];
			}, []),

			// mark each of the bonus features as an active feature
			...data.additionalAccessFeatures
		].forEach((feature) => {
			this.activeFeatures.add(feature);
		});
	}

	private canX(feature: Features) {
		return this.activeFeatures.has(feature);
	}

	canUseMultipleRostersForInstance = () =>
		this.canX(Features.MULTIPLE_ROSTERS_FOR_INSTANCE);
	canShowBasicLootInRoster = () => this.canX(Features.SHOW_BASIC_LOOT_IN_ROSTER);
	// canUsePlaceholderCharacters = () => this.canX(FEATURES.PLACEHOLDER_CHARACTERS);
	canUseCustomLootOptions = () => this.canX(Features.CUSTOM_LOOT_OPTIONS);
	canUseTagging = () => this.canX(Features.TAGGING);
	canUseLimitedExternalCharacters = () =>
		this.canX(Features.LIMITED_EXTERNAL_CHARACTERS);
	canUseUnlimitedExternalCharacters = () =>
		this.canX(Features.UNLIMITED_EXTERNAL_CHARACTERS);
	canUseRclcImport = () => this.canX(Features.RCLC_IMPORT);

	getExternalCharactersLimit(): number {
		if (this.canUseUnlimitedExternalCharacters()) return Number.POSITIVE_INFINITY;
		if (this.canUseLimitedExternalCharacters()) {
			return FeatureLimits.LIMITED_EXTERNAL_CHARACTERS;
		}

		return 0;
	}
}
