import {useCallback, useState} from 'react';

export function useBoolean(initialState = false) {
	const [value, setState] = useState(initialState);

	const toggle = useCallback(() => setState((x) => !x), []);
	const setTrue = useCallback(() => setState(true), []);
	const setFalse = useCallback(() => setState(false), []);
	const setValue = useCallback((x: boolean) => setState(x), []);

	return {
		value,
		toggle,
		setTrue,
		setFalse,
		setValue
	};
}
