import React from 'react';
import classnames from 'classnames';

type Position =
	| 'top-left'
	| 'top-right'
	| 'top'
	| 'bottom-left'
	| 'bottom-right'
	| 'bottom'
	| 'left'
	| 'right';

type Size = 'small' | 'medium' | 'large';

interface IProps {
	className?: string;
	content: string;
	position?: Position;
	size?: Size;
}

export const InfoIcon: React.FC<IProps> = (props) => {
	const position: Position = props.position ?? 'top';
	const size: Size = props.size ?? 'large';

	const tooltipClass = classnames(
		{
			[`tooltip-${position}`]: !!position,
			[`tooltip-${size}`]: !!size
		},
		props.className,
		'info-icon tooltip'
	);

	return (
		<div data-hint={props.content} className={tooltipClass}>
			<i className="lnr lnr-notification-circle" />
		</div>
	);
};
