import React from 'react';

import SelectRoles from '../../SelectRoles/SelectRolesContainer';
import {TextContent} from '../../SelectRoles/SelectRoles';

export const Roles: React.FC = () => {
	return (
		<div className="settings-content role-settings">
			<div className="settings-heading">{TextContent.title}</div>

			<div className="settings-field-group">
				<div className="group-description">{TextContent.description}</div>
				<div className="group-description">{TextContent.note}</div>
				<SelectRoles isEmbeddedView={true} />
			</div>
		</div>
	);
};
