import React from 'react';
import {FiPlusCircle, FiLoader} from 'react-icons/fi';

import {useBoolean} from '@hooks/useBoolean';

interface AddActionProps {
	onCreate(): Promise<void>;
}

export const AddAction: React.FC<AddActionProps> = (props) => {
	const actioning = useBoolean(false);

	async function handleCreate() {
		// only allow one to be done at a time
		if (actioning.value) return;

		actioning.setTrue();
		await props.onCreate();
		actioning.setFalse();
	}

	return (
		<div className="add-action-container">
			{actioning.value ? (
				<div className="actioning">
					<FiLoader />
				</div>
			) : (
				<div onClick={handleCreate} className="dormant">
					<FiPlusCircle />
				</div>
			)}
		</div>
	);
};
