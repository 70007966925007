import React from 'react';

import type {Role} from '../../constants/wow';

import * as images from '../../helpers/images';

import type * as RosterBossDuck from '../../ducks/roster-boss';

import type {Character} from '../../models/character';

import type {IRosterViewBoss, ICharMetaMap} from './RosterContainer';
import type {IRoleGroups, IChangesByRole} from './RosterView';
import CurrentAssignments from './CurrentAssignments';
import {AssignmentControls} from './AssignmentControls/AssignmentControls';
import RosterChanges from './RosterChanges';

interface IProps {
	onUpdateLimit: typeof RosterBossDuck.updateLimit;
	onRoster: typeof RosterBossDuck.rosterCharacter;

	onChangeRoleTab(role: Role): void;
	onSearch(id: number): void;

	isShowingKillCount: boolean;
	isShowingAdminControls: boolean;
	isShowingChanges: boolean;
	isAdmin: boolean;

	wowheadBonus: string;
	selectedRole: Role;
	characterById: Map<number, Character>;
	characterMetaMap: ICharMetaMap;
	roleGroups: IRoleGroups;
	boss: IRosterViewBoss;
	changes?: IChangesByRole;
}

export class BossAssignments extends React.Component<IProps> {
	handleRosterCharacter = (role: string, characterId: number, isActive: boolean) => {
		this.props.onRoster({
			bossId: this.props.boss.id,
			characterId,
			isActive,
			role
		});
	};

	handleChangeLimit = (role: string, limit: number) => {
		this.props.onUpdateLimit({
			bossId: this.props.boss.id,
			limit,
			role
		});
	};

	getRosteredCount() {
		let assignedCount = 0;

		Object.values(this.props.boss.roleAssignments).forEach((assigned) => {
			assignedCount += assigned.length;
		});

		return assignedCount;
	}

	render() {
		const isShowingAdminControls =
			this.props.isAdmin && this.props.isShowingAdminControls;

		return (
			<div className="boss-tab-content">
				<div className="top-row">
					<div className="panel">
						<img
							loading="lazy"
							src={images.getBossImageUrl(this.props.boss.bossId)}
						/>

						<div className="name">{this.props.boss.name}</div>

						<div className="assigned-count">{this.getRosteredCount()} rostered</div>
					</div>

					<CurrentAssignments
						onRoster={this.handleRosterCharacter}
						onSearch={this.props.onSearch}
						isShowingX={isShowingAdminControls}
						roleAssignments={this.props.boss.roleAssignments}
						roleUpdatedAt={this.props.boss.roleUpdatedAt}
						characterById={this.props.characterById}
					/>
				</div>

				{isShowingAdminControls && (
					<AssignmentControls
						onUpdateRoleLimit={this.handleChangeLimit}
						onChangeTab={this.props.onChangeRoleTab}
						onRoster={this.handleRosterCharacter}
						isShowingKillCount={this.props.isShowingKillCount}
						selectedRole={this.props.selectedRole}
						characterMetaMap={this.props.characterMetaMap}
						bossKillByCharacterId={this.props.boss.bossKillByCharacterId}
						assignedRoleByCharacterId={this.props.boss.assignedRoleByCharacterId}
						assignmentsByUserId={this.props.boss.assignmentsByUserId}
						roleLimits={this.props.boss.roleLimits}
						roleAssignments={this.props.boss.roleAssignments}
						roleUpdatedAt={this.props.boss.roleUpdatedAt}
						roleGroups={this.props.roleGroups}
						wowheadBonus={this.props.wowheadBonus}
						wowBossId={this.props.boss.bossId}
						bossId={this.props.boss.id}
					/>
				)}

				{this.props.isAdmin && this.props.isShowingChanges && (
					<RosterChanges changes={this.props.changes!} />
				)}
			</div>
		);
	}
}
