import React from 'react';

import type {Props} from './MiscSettingsContainer';
import {SwitchField} from '../../Utils/Switch';
import {GroupHeading} from '../Utils/GroupHeading';

// eslint-disable-next-line react/prefer-stateless-function
export class MiscSettingsComp extends React.Component<Props> {
	render() {
		return (
			<div className="settings-content misc-settings">
				<div className="settings-heading">Misc settings</div>

				<div className="settings-field-group">
					<GroupHeading heading="Admin only views" />

					<div className="group-item">
						<SwitchField
							onChange={(value) =>
								this.props.onToggleRosterAdminOnly({
									isRosterAssignmentsAdminOnly: value
								})
							}
							isControlled={true}
							label="Limit roster's assignments view to admins"
							note="The roster's assignments view will only be visible to admins of the guild, everyone else won't have access to it"
							value={this.props.guild.isRosterAssignmentsAdminOnly}
						/>
					</div>

					<div className="group-item">
						<SwitchField
							onChange={(value) =>
								this.props.onToggleRosterAdminOnly({
									isRosterNotesAdminOnly: value
								})
							}
							isControlled={true}
							label="Limit roster's notes view to admins"
							note="The roster's notes view will only be visible to admins of the guild, everyone else won't have access to it"
							value={this.props.guild.isRosterNotesAdminOnly}
						/>
					</div>

					<div className="group-item">
						<SwitchField
							onChange={(value) =>
								this.props.onToggleRosterAdminOnly({
									isRosterCooldownsAdminOnly: value
								})
							}
							isControlled={true}
							label="Limit roster's cooldowns view to admins (coming soon)"
							note="The roster's cooldown view will only be visible to admins of the guild, everyone else won't have access to it"
							value={this.props.guild.isRosterCooldownsAdminOnly}
						/>
					</div>

					<div className="group-item">
						<SwitchField
							onChange={(value) => this.props.onToggleLootSheetAdminOnly(value)}
							isControlled={true}
							label="Limit loot sheet view to admins"
							note="The loot sheet view will only be visible to admins of the guild, everyone else won't have access to it"
							value={this.props.guild.isLootSheetAdminOnly}
						/>
					</div>
				</div>
			</div>
		);
	}
}
