import React from 'react';
import {
	components,
	OptionProps,
	InputProps,
	StylesConfig,
	GroupBase,
	OnChangeValue
} from 'react-select';
import Select, {CreatableProps} from 'react-select/creatable';

import {BossAbility, bossAbilitiesByWowBossId} from '@shared/constants/bossAbilities';

import {generateWowheadSpellLinkData, forceRemoveWowheadTooltip} from '@helpers/wowhead';
import {getSpellIconUrl} from '@helpers/images';

import {Pane} from '@components/Utils/Pane';
import {Image} from '@components/Utils/Image';
import type {Size} from '@components/Utils/Input';

const selectSize: Size = 'regular';

interface SelectOption {
	ability: BossAbility;

	// react select defaults
	label: string;
	value: string;
}

const selectStyleOverrides: StylesConfig<SelectOption, false> = {
	control: (base) => ({...base, minWidth: 240, margin: 8}),
	menu: (base) => ({
		...base,
		position: 'relative',
		border: 'none !important',
		background: 'transparent !important',
		boxShadow: 'none !important'
	})
};

const formatCreateLabel: CreatableProps<
	SelectOption,
	false,
	GroupBase<SelectOption>
>['formatCreateLabel'] = (inputValue) => {
	return `Custom: "${inputValue}"`;
};

const CustomSelectInput: React.FC<InputProps<SelectOption, false>> = (props) => {
	return (
		<components.Input
			{...props}
			className={`${props.className || ''} input ${selectSize}`}
		/>
	);
};

const CustomSelectOption: React.FC<OptionProps<SelectOption, false>> = (props) => {
	// since this is a creatable select we also need to handle the creation option case
	// eslint-disable-next-line no-underscore-dangle
	if ((props.data as {__isNew__?: unknown}).__isNew__) {
		return <components.Option {...props} />;
	}

	const {ability} = props.data;

	const wowheadLink = generateWowheadSpellLinkData(ability.wowAbilityId, false);

	return (
		<a onClick={(e) => e.preventDefault()} href={wowheadLink.url}>
			<components.Option {...props}>
				<Image
					src={getSpellIconUrl(ability.iconName)}
					className="event-name-select-option-image"
				/>

				<div className="event-name-select-option-name">{ability.name}</div>
			</components.Option>
		</a>
	);
};

interface EventSelectProps {
	onSelect(wowAbilityId: WowAbilityId): void;
	onFreeformText(text: string): void;
	onClose(): void;

	wowBossId: WowBossId;
}

export const EventSelect: React.FC<EventSelectProps> = (props) => {
	function handleCreate(text: string): void {
		if (!text || !text.trim()) return;
		props.onFreeformText(text);
	}

	function handleChange(value: OnChangeValue<SelectOption, false>): void {
		if (!value) return;
		props.onSelect(value.ability.wowAbilityId);
	}

	const options =
		bossAbilitiesByWowBossId.get(props.wowBossId)?.map((ability): SelectOption => {
			return {
				ability,

				label: ability.name,
				value: ability.wowAbilityId
			};
		}) || [];

	return (
		<Pane
			onDismount={forceRemoveWowheadTooltip}
			onClick={(e) => e.stopPropagation()}
			onClose={props.onClose}
			className="event-name-select"
			withBorder={true}
		>
			<Select<SelectOption>
				onCreateOption={handleCreate}
				onChange={handleChange}
				className={`react-select ${selectSize}`}
				classNamePrefix="react-select-prefix"
				components={{
					DropdownIndicator: null,
					IndicatorSeparator: null,
					Input: CustomSelectInput,
					Option: CustomSelectOption
				}}
				autoFocus={true}
				backspaceRemovesValue={false}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				isClearable={false}
				menuIsOpen={true}
				options={options}
				placeholder="Create custom or select ability"
				formatCreateLabel={formatCreateLabel}
				styles={selectStyleOverrides}
			/>
		</Pane>
	);
};
