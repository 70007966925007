import {BEGIN, COMMIT, REVERT} from 'redux-optimist';

export * from '../../shared/helpers/toolbox';

export function makeAvatarUrl(path: string, region: string) {
	return `https://render-${region}.worldofwarcraft.com/character/${path}`;
}

export function calculateNewOrder(before: number, after: number) {
	// if there's neither of them, then it's the only item
	// in the list, so order is just 1
	if (!before && !after) return 1;

	// if there's no `before` then we must be moving to the
	// first spot in the list so order is just half of `after`
	if (!before) return after / 2;

	// if there's no `after` then we must be moving to the
	// end spot in the list so just increment `before` for order
	if (!after) return before + 1;

	// spot is in the middle of two other items, so pick the
	// number in the middle of those two spots
	return (after - before) / 2 + before;
}

const typeCounts: {[key: string]: number} = {};
export function generateId(type = '') {
	if (!typeCounts[type]) typeCounts[type] = 0;

	typeCounts[type] += 1;

	return typeCounts[type];
}

export const optimistic = {
	getId() {
		return generateId('optimistic');
	},

	begin(id: number): {optimist: Optimist.IBegin} {
		return {
			optimist: {
				type: BEGIN,
				id
			}
		};
	},

	commit(id: number): {optimist: Optimist.ICommit} {
		return {
			optimist: {
				type: COMMIT,
				id
			}
		};
	},

	revert(id: number): {optimist: Optimist.IRevert} {
		return {
			optimist: {
				type: REVERT,
				id
			}
		};
	}
};
