import type {
	ISponsorGuildInput,
	ISponsorGuildResult,
	IUnsponsorGuildInput,
	IUnsponsorGuildResult
} from 'api-types';

import {GuildSponsorshipDuck, BannerDuck} from '@ducks';

import * as rpc from '../../../shared/constants/rpc';

import api from '../../helpers/api';

export function sponsor(guildId: number): Thunk<void> {
	return (dispatch) => {
		dispatch<GuildSponsorshipDuck.IActions['SPONSOR']>({
			type: GuildSponsorshipDuck.SPONSOR,
			payload: {
				guildId
			}
		});

		return api
			.call<ISponsorGuildInput, ISponsorGuildResult>(
				rpc.GUILD_SPONSORSHIP_CREATE,
				{
					guildId
				},
				// intentionally slowed to make it feel like it "worked"
				{slowMo: true}
			)
			.then(
				() => {
					dispatch(BannerDuck.addSuccessBanner('Buffed guild'));
					dispatch<GuildSponsorshipDuck.IActions['SPONSOR_SUCCESS']>({
						type: GuildSponsorshipDuck.SPONSOR_SUCCESS
					});
				},
				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<GuildSponsorshipDuck.IActions['SPONSOR_FAILURE']>({
						type: GuildSponsorshipDuck.SPONSOR_FAILURE
					});
				}
			);
	};
}

export function unsponsor(guildId: number): Thunk<void> {
	return (dispatch) => {
		dispatch<GuildSponsorshipDuck.IActions['UNSPONSOR']>({
			type: GuildSponsorshipDuck.UNSPONSOR,
			payload: {
				guildId
			}
		});

		return api
			.call<IUnsponsorGuildInput, IUnsponsorGuildResult>(
				rpc.GUILD_SPONSORSHIP_DELETE,
				{
					guildId
				},
				// intentionally slowed to make it feel like it "worked"
				{slowMo: true}
			)
			.then(
				() => {
					dispatch(BannerDuck.addSuccessBanner('Un-buffed guild'));
					dispatch<GuildSponsorshipDuck.IActions['UNSPONSOR_SUCCESS']>({
						type: GuildSponsorshipDuck.UNSPONSOR_SUCCESS
					});
				},
				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<GuildSponsorshipDuck.IActions['UNSPONSOR_FAILURE']>({
						type: GuildSponsorshipDuck.UNSPONSOR_FAILURE
					});
				}
			);
	};
}
