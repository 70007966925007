import {DEMO_GUILD_ID} from '../constants';
import {
	USER_SIGN_OUT,
	STRIPE_SUBSCRIBE_SUCCESS,
	STRIPE_UPDATE_PAYMENT_SUCCESS
} from '../constants/rpc';

export enum PathSegments {
	USER_BILLING_NEW = 'billing',
	CHANGE_PLAN = 'change-plan',

	DEMO = 'demo',
	DEMO_ROSTER = 'roster',

	GUILD = 'guild',

	ROSTERS = 'rosters',
	ROSTER = 'roster',
	ROSTER_V1 = 'roster-old',
	LOOT = 'loot',
	RCLC = 'rclc',
	DROPTIMIZER_REPORTS = 'droptimizer-reports',

	GUILD_SETTINGS = 'guild-settings',
	GUILD_SETTINGS_ROSTERS = 'rosters',
	GUILD_SETTINGS_ROSTER_REORDER = 'roster-bosses',
	GUILD_SETTINGS_EXTERNAL_CHARACTERS = 'external-characters',

	ALL_VIEWS_DISABLED = 'all-views-disabled'
}

export const baseUrl = () => '/';

export const signOutUrl = () => `/api/${USER_SIGN_OUT}`;
export const supportUrl = () => 'https://readycheck.io/contact';

export const userNewBillingUrl = () => `${baseUrl()}${PathSegments.USER_BILLING_NEW}`;
export const userBillingChangePlanUrl = () =>
	`${userNewBillingUrl()}/${PathSegments.CHANGE_PLAN}`;

export const demoUrl = () => `${baseUrl()}${PathSegments.DEMO}`;
export const demoRosterUrl = (rosterBossId: number | undefined): string => {
	const base = `${demoUrl()}/${PathSegments.DEMO_ROSTER}`;
	if (!rosterBossId) return base;

	return `${base}/${rosterBossId}`;
};

export const guildUrl = (guildId: number) => {
	if (guildId === DEMO_GUILD_ID) return demoUrl();

	return `${baseUrl()}${PathSegments.GUILD}/${guildId}`;
};

export const guildSettingsUrl = (guildId: number) =>
	`${guildUrl(guildId)}/${PathSegments.GUILD_SETTINGS}`;
export const guildSettingsRostersUrl = (guildId: number) =>
	`${guildSettingsUrl(guildId)}/${PathSegments.GUILD_SETTINGS_ROSTERS}`;
export const guildSettingsRosterUrl = (guildId: number, rosterId: number) =>
	`${guildSettingsRostersUrl(guildId)}/${rosterId}`;
export const guildSettingsRosterReorderUrl = (guildId: number, rosterId: number) =>
	`${guildSettingsUrl(guildId)}/${
		PathSegments.GUILD_SETTINGS_ROSTER_REORDER
	}/${rosterId}`;
export const guildSettingsExternalCharactersUrl = (guildId: number) =>
	`${guildSettingsUrl(guildId)}/${PathSegments.GUILD_SETTINGS_EXTERNAL_CHARACTERS}`;

export const rosterOldUrl = (guildId: number, rosterId: number) =>
	`${guildUrl(guildId)}/${PathSegments.ROSTER_V1}/${rosterId}`;
export const rosterUrl = (
	guildId: number,
	rosterId: number,
	rosterBossId: number | undefined
) => {
	const base = `${guildUrl(guildId)}/${PathSegments.ROSTER}/${rosterId}`;
	if (!rosterBossId) return base;

	return `${base}/${rosterBossId}`;
};

export const rostersUrl = (guildId: number) =>
	`${guildUrl(guildId)}/${PathSegments.ROSTERS}`;

export const lootUrl = (guildId: number) => `${guildUrl(guildId)}/${PathSegments.LOOT}`;
export const rclcUrl = (guildId: number) => `${guildUrl(guildId)}/${PathSegments.RCLC}`;
export const droptimizerReportsUrl = (guildId: number) =>
	`${guildUrl(guildId)}/${PathSegments.DROPTIMIZER_REPORTS}`;
export const allViewsDisabled = (guildId: number) =>
	`${guildUrl(guildId)}/${PathSegments.ALL_VIEWS_DISABLED}`;

// API stuff
export const apiBaseUrl = () => '/api';

export const stripeSubscribeSuccessUrl = () =>
	`${apiBaseUrl()}/${STRIPE_SUBSCRIBE_SUCCESS}`;
export const stripeUpdatePaymentSuccessUrl = () =>
	`${apiBaseUrl()}/${STRIPE_UPDATE_PAYMENT_SUCCESS}`;
