import * as wow from '../constants/wow';

import * as LS from '../helpers/localstorage';
import {dates} from '@helpers/dates';

import * as UserDuck from './user';
import type {RootAction} from '.';

export interface IState {
	readonly rosterIsShowingAdminControls: boolean;
	readonly rosterIsShowingBuffsSection: boolean;
	readonly rosterIsAlphabeticalSort: boolean;
	readonly rosterIsShowingChanges: boolean;
	readonly rosterSelectedRole: wow.Role;

	readonly exportCharactersAsCsv: boolean;
	readonly exportChangesFormatter: string | undefined;

	readonly lootShowConduits: boolean;
	readonly lootGroupBySlot: boolean;

	readonly isDroptimizerRelativeGain: boolean;

	readonly guildSettingsGroupTagsByStatus: boolean;
}

// types
const ROSTER_TOGGLE_ADMIN_CONTROLS = 'settings/ROSTER_TOGGLE_ADMIN_CONTROLS';
const ROSTER_TOGGLE_BUFFS_SECTION = 'settings/ROSTER_TOGGLE_BUFFS_SECTION';
const ROSTER_TOGGLE_CHANGES = 'settings/ROSTER_TOGGLE_CHANGES';
const ROSTER_TOGGLE_ALPHABETICAL_SORT = 'settings/ROSTER_TOGGLE_ALPHABETICAL_SORT';
const ROSTER_SELECTED_ROLE = 'settings/ROSTER_SELECTED_ROLE';

const TOGGLE_EXPORT_CHARACTERS_AS_CSV = 'settings/TOGGLE_EXPORT_CHARACTERS_AS_CSV';
const SET_EXPORT_CHANGES_FORMATTER = 'settings/CHANGES_FORMATTER';

const LOOT_SHOW_CONDUITS = 'settings/LOOT_SHOW_CONDUITS';
const LOOT_GROUP_BY_SLOT = 'settings/LOOT_GROUP_BY_SLOT';

const DROPTIMIZER_RELATIVE_GAIN = 'settings/DROPTIMIZER_RELATIVE_GAIN';

const GUILD_SETTINGS_GROUP_TAGS_BY_STATUS =
	'settings/GUILD_SETTINGS_GROUP_TAGS_BY_STATUS';

export interface IActions {
	ROSTER_TOGGLE_ADMIN_CONTROLS: {
		readonly type: typeof ROSTER_TOGGLE_ADMIN_CONTROLS;
		readonly payload: {
			rosterIsShowingAdminControls: boolean;
		};
	};

	ROSTER_TOGGLE_BUFFS_SECTION: {
		readonly type: typeof ROSTER_TOGGLE_BUFFS_SECTION;
		readonly payload: {
			rosterIsShowingBuffsSection: boolean;
		};
	};

	ROSTER_TOGGLE_CHANGES: {
		readonly type: typeof ROSTER_TOGGLE_CHANGES;
		readonly payload: {
			rosterIsShowingChanges: boolean;
		};
	};

	ROSTER_TOGGLE_ALPHABETICAL_SORT: {
		readonly type: typeof ROSTER_TOGGLE_ALPHABETICAL_SORT;
		readonly payload: {
			rosterIsAlphabeticalSort: boolean;
		};
	};

	ROSTER_SELECTED_ROLE: {
		readonly type: typeof ROSTER_SELECTED_ROLE;
		readonly payload: {
			rosterSelectedRole: wow.Role;
		};
	};

	TOGGLE_EXPORT_CHARACTERS_AS_CSV: {
		readonly type: typeof TOGGLE_EXPORT_CHARACTERS_AS_CSV;
		readonly payload: {
			exportCharactersAsCsv: boolean;
		};
	};

	SET_EXPORT_CHANGES_FORMATTER: {
		readonly type: typeof SET_EXPORT_CHANGES_FORMATTER;
		readonly payload: {
			exportChangesFormatter: string;
		};
	};

	LOOT_SHOW_CONDUITS: {
		readonly type: typeof LOOT_SHOW_CONDUITS;
		readonly payload: {
			lootShowConduits: boolean;
		};
	};

	LOOT_GROUP_BY_SLOT: {
		readonly type: typeof LOOT_GROUP_BY_SLOT;
		readonly payload: {
			lootGroupBySlot: boolean;
		};
	};

	DROPTIMIZER_RELATIVE_GAIN: {
		readonly type: typeof DROPTIMIZER_RELATIVE_GAIN;
		readonly payload: {
			isDroptimizerRelativeGain: boolean;
		};
	};

	GUILD_SETTINGS_GROUP_TAGS_BY_STATUS: {
		readonly type: typeof GUILD_SETTINGS_GROUP_TAGS_BY_STATUS;
		readonly payload: {
			guildSettingsGroupTagsByStatus: boolean;
		};
	};
}

// selectors
export function getRosterIsShowingAdminControls(state: IState) {
	return state.rosterIsShowingAdminControls;
}

export function getRosterIsShowingBuffsSection(state: IState) {
	return state.rosterIsShowingBuffsSection;
}

export function getRosterIsShowingChanges(state: IState) {
	return state.rosterIsShowingChanges;
}

export function getRosterIsAlphabeticalSort(state: IState) {
	return state.rosterIsAlphabeticalSort;
}

export function getRosterSelectedRole(state: IState) {
	return state.rosterSelectedRole;
}

export function getExportCharactersAsCsv(state: IState) {
	return state.exportCharactersAsCsv;
}

export function getExportChangesFormatter(state: IState) {
	return state.exportChangesFormatter;
}

export function getLootShowConduits(state: IState) {
	return state.lootShowConduits;
}

export function getLootGroupBySlot(state: IState) {
	return state.lootGroupBySlot;
}

export function getIsDroptimizerRelativeGain(state: IState) {
	return state.isDroptimizerRelativeGain;
}

export function getGuildSettingsGroupTagsByStatus(state: IState) {
	return state.guildSettingsGroupTagsByStatus;
}

// actions
export function setRosterIsShowingAdminControls(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		const action: IActions['ROSTER_TOGGLE_ADMIN_CONTROLS'] = {
			type: ROSTER_TOGGLE_ADMIN_CONTROLS,
			payload: {rosterIsShowingAdminControls: value}
		};

		dispatch(action);

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.rosterIsShowingAdminControls`, String(value));
	};
}

export function setRosterIsShowingBuffsSection(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		const action: IActions['ROSTER_TOGGLE_BUFFS_SECTION'] = {
			type: ROSTER_TOGGLE_BUFFS_SECTION,
			payload: {rosterIsShowingBuffsSection: value}
		};

		dispatch(action);

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.rosterIsShowingBuffsSection`, String(value));
	};
}

export function setRosterIsShowingChanges(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		const action: IActions['ROSTER_TOGGLE_CHANGES'] = {
			type: ROSTER_TOGGLE_CHANGES,
			payload: {rosterIsShowingChanges: value}
		};

		dispatch(action);

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.rosterIsShowingChanges`, String(value));
	};
}

export function setRosterIsAlphabeticalSort(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		const action: IActions['ROSTER_TOGGLE_ALPHABETICAL_SORT'] = {
			type: ROSTER_TOGGLE_ALPHABETICAL_SORT,
			payload: {rosterIsAlphabeticalSort: value}
		};

		dispatch(action);

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.rosterIsAlphabeticalSort`, String(value));
	};
}

export function setRosterSelectedRole(value: wow.Role): ThunkCb<void> {
	return (dispatch, getState) => {
		const action: IActions['ROSTER_SELECTED_ROLE'] = {
			type: ROSTER_SELECTED_ROLE,
			payload: {rosterSelectedRole: value}
		};

		dispatch(action);

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.rosterSelectedRole`, value);
	};
}

export function setExportCharactersAsCsv(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		const action: IActions['TOGGLE_EXPORT_CHARACTERS_AS_CSV'] = {
			type: TOGGLE_EXPORT_CHARACTERS_AS_CSV,
			payload: {exportCharactersAsCsv: value}
		};

		dispatch(action);

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.exportCharactersAsCsv`, String(value));
	};
}

export function setExportChangesFormatter(value: string): ThunkCb<void> {
	return (dispatch, getState) => {
		const action: IActions['SET_EXPORT_CHANGES_FORMATTER'] = {
			type: SET_EXPORT_CHANGES_FORMATTER,
			payload: {exportChangesFormatter: value}
		};

		dispatch(action);

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.exportChangesFormatter`, value);
	};
}

export function setLootShowConduits(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		dispatch<IActions['LOOT_SHOW_CONDUITS']>({
			type: LOOT_SHOW_CONDUITS,
			payload: {lootShowConduits: value}
		});

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.lootShowConduits`, String(value));
	};
}

export function setLootGroupBySlot(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		dispatch<IActions['LOOT_GROUP_BY_SLOT']>({
			type: LOOT_GROUP_BY_SLOT,
			payload: {lootGroupBySlot: value}
		});

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.lootGroupBySlot`, String(value));
	};
}

export function setDroptimizerRelativeGain(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		dispatch<IActions['DROPTIMIZER_RELATIVE_GAIN']>({
			type: DROPTIMIZER_RELATIVE_GAIN,
			payload: {isDroptimizerRelativeGain: value}
		});

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.isDroptimizerRelativeGain`, String(value));
	};
}

export function setGuildSettingsGroupTagsByStatus(value: boolean): ThunkCb<void> {
	return (dispatch, getState) => {
		dispatch<IActions['GUILD_SETTINGS_GROUP_TAGS_BY_STATUS']>({
			type: GUILD_SETTINGS_GROUP_TAGS_BY_STATUS,
			payload: {guildSettingsGroupTagsByStatus: value}
		});

		const user = UserDuck.getUser(getState().user);
		if (user) LS.setItem(`${user.id}.guildSettingsGroupTagsByStatus`, String(value));
	};
}

// reducer
const initialState: IState = {
	rosterIsShowingAdminControls: true,
	rosterIsShowingBuffsSection: true,
	rosterIsAlphabeticalSort: false,
	rosterIsShowingChanges: false,
	rosterSelectedRole: wow.Role.MELEE,

	exportCharactersAsCsv: false,
	exportChangesFormatter: undefined,

	lootShowConduits: false,
	lootGroupBySlot: false,

	isDroptimizerRelativeGain: false,

	guildSettingsGroupTagsByStatus: false
};

export default function reducer(state = initialState, action: RootAction): IState {
	switch (action.type) {
		case GUILD_SETTINGS_GROUP_TAGS_BY_STATUS:
		case LOOT_SHOW_CONDUITS:
		case LOOT_GROUP_BY_SLOT:
		case DROPTIMIZER_RELATIVE_GAIN:
		case ROSTER_TOGGLE_ADMIN_CONTROLS:
		case ROSTER_TOGGLE_BUFFS_SECTION:
		case ROSTER_TOGGLE_CHANGES:
		case ROSTER_TOGGLE_ALPHABETICAL_SORT:
		case ROSTER_SELECTED_ROLE:
		case TOGGLE_EXPORT_CHARACTERS_AS_CSV:
		case SET_EXPORT_CHANGES_FORMATTER: {
			return {...state, ...action.payload};
		}

		case UserDuck.FETCH:
			return initialState;

		case UserDuck.FETCH_SUCCESS: {
			const userId = action.payload.user.id;

			const newState = {...state};

			// roster stuff
			const rosterIsShowingAdminControls = LS.getItem(
				`${userId}.rosterIsShowingAdminControls`
			);
			if (rosterIsShowingAdminControls) {
				newState.rosterIsShowingAdminControls =
					rosterIsShowingAdminControls === 'true';
			}

			const rosterIsShowingBuffsSection = LS.getItem(
				`${userId}.rosterIsShowingBuffsSection`
			);
			if (rosterIsShowingBuffsSection) {
				newState.rosterIsShowingBuffsSection = rosterIsShowingBuffsSection === 'true';
			}

			const rosterIsShowingChanges = LS.getItem(`${userId}.rosterIsShowingChanges`);
			if (rosterIsShowingChanges) {
				newState.rosterIsShowingChanges = rosterIsShowingChanges === 'true';
			}

			const rosterIsAlphabeticalSort = LS.getItem(
				`${userId}.rosterIsAlphabeticalSort`
			);
			if (rosterIsAlphabeticalSort) {
				newState.rosterIsAlphabeticalSort = rosterIsAlphabeticalSort === 'true';
			}

			const rosterSelectedRole = LS.getItem(`${userId}.rosterSelectedRole`);
			if (rosterSelectedRole) {
				newState.rosterSelectedRole = rosterSelectedRole as wow.Role;
			}

			// export stuff
			const exportCharactersAsCsv = LS.getItem(`${userId}.exportCharactersAsCsv`);
			if (exportCharactersAsCsv) {
				newState.exportCharactersAsCsv = exportCharactersAsCsv === 'true';
			}

			const exportChangesFormatter = LS.getItem(`${userId}.exportChangesFormatter`);
			if (exportChangesFormatter) {
				newState.exportChangesFormatter = exportChangesFormatter;
			}

			// loot stuff
			const lootShowConduits = LS.getItem(`${userId}.lootShowConduits`);
			if (lootShowConduits) {
				newState.lootShowConduits = lootShowConduits === 'true';
			} else {
				// default to off, unless the account was created before this toggle
				newState.lootShowConduits = dates(action.payload.user.createdAt).isBefore(
					'2021-02-13',
					'day'
				);
			}

			const lootGroupBySlot = LS.getItem(`${userId}.lootGroupBySlot`);
			if (lootGroupBySlot) {
				newState.lootGroupBySlot = lootGroupBySlot === 'true';
			}

			const isDroptimizerRelativeGain = LS.getItem(
				`${userId}.isDroptimizerRelativeGain`
			);
			if (isDroptimizerRelativeGain) {
				newState.isDroptimizerRelativeGain = isDroptimizerRelativeGain === 'true';
			}

			const guildSettingsGroupTagsByStatus = LS.getItem(
				`${userId}.guildSettingsGroupTagsByStatus`
			);
			if (guildSettingsGroupTagsByStatus) {
				newState.guildSettingsGroupTagsByStatus =
					guildSettingsGroupTagsByStatus === 'true';
			}

			return newState;
		}

		default:
			return state;
	}
}
