import {connect2, GuildDuck} from '@ducks';

import type {Guild} from '@models/guild';

import {MiscSettingsComp} from './MiscSettings';

interface OwnProps {
	guild: Guild;
}

interface StateProps extends EmptyObject {}

interface DispatchProps {
	onToggleRosterAdminOnly: BoundThunk<typeof GuildDuck['updateRosterAdminOnly']>;
	onToggleLootSheetAdminOnly: BoundThunk<typeof GuildDuck['updateLootSheetAdminOnly']>;
}

export type Props = OwnProps & StateProps & DispatchProps;

function mapStateToProps(): StateProps {
	return {};
}

export const MiscSettingsContainer = connect2<{
	Own: OwnProps;
	State: StateProps;
	Dispatch: DispatchProps;
}>(mapStateToProps, {
	onToggleRosterAdminOnly: GuildDuck.updateRosterAdminOnly,
	onToggleLootSheetAdminOnly: GuildDuck.updateLootSheetAdminOnly
})(MiscSettingsComp);
