import type {CooldownActionDuck} from '@ducks';

import type {CooldownAction, CooldownActionId} from '@models/cooldownAction';
import type {CooldownEventId} from '@models/cooldownEvent';

/** Get a single CooldownAction based on its ID */
export function getCooldownActionForCooldownActionId(
	state: CooldownActionDuck.State,
	cooldownActionId: CooldownActionId
): CooldownAction | undefined {
	return state.actionById[cooldownActionId];
}

/** Get a CooldownActions for a given CooldownEvent ID */
export function getCooldownActionsForCooldownEventId(
	state: CooldownActionDuck.State,
	cooldownEventId: CooldownEventId
): CooldownAction[] {
	return Object.values(state.actionById).reduce<CooldownAction[]>((actions, action) => {
		if (action.cooldownEventId !== cooldownEventId) return actions;

		actions.push(action);
		return actions;
	}, []);
}
