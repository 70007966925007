import React from 'react';

export default function Support() {
	return (
		<a
			className="support-beacon"
			href="https://readycheck.io/contact"
			target="_blank"
			rel="noreferrer noopener"
		>
			<i className="lnr lnr-bubble-question" />
		</a>
	);
}
