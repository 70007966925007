export interface ILootOptionClass {
	id: number;
	guildId: number;

	isVisibleInRoster: boolean;
	isDeleted: boolean;

	name: string;
	colour: string;
	order: number;
}

export class LootOption implements ILootOptionClass {
	readonly id: number;
	readonly guildId: number;

	readonly isVisibleInRoster: boolean;
	readonly isDeleted: boolean;

	readonly name: string;
	readonly colour: string;
	readonly order: number;

	constructor(data?: ILootOptionClass) {
		if (!data) throw new Error('invalid loot-option data');

		this.id = data.id;
		this.guildId = data.guildId;

		this.isVisibleInRoster = data.isVisibleInRoster;
		this.isDeleted = data.isDeleted;

		this.name = data.name;
		this.colour = data.colour;
		this.order = Number(data.order);
	}
}
