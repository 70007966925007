export * from '@shared/constants/wow';
import {Classes, Role, ORDERED_ROLES} from '@shared/constants/wow';

export {Role as ROLES, ORDERED_ROLES as ROLES_ORDERED};

export const REGIONS: {[key: string]: string} = {
	us: 'Americas (US / SEA)',
	eu: 'Europe (EU)',
	apac: 'Asia (KR / TW)'
};

export const FACTIONS = {
	ALLIANCE: 'Alliance',
	HORDE: 'Horde'
};

export const ORDERED_EXPORT_ROLES = [Role.TANK, Role.MELEE, Role.RANGED, Role.HEALER];

export const ROLES_TO_DISPLAY: {[key: string]: string} = {
	[Role.MELEE]: 'Melee',
	[Role.RANGED]: 'Ranged',
	[Role.HEALER]: 'Healer',
	[Role.TANK]: 'Tank'
};

export const CLASS_TO_DISPLAY: {[key: string]: string} = {
	warrior: 'Warrior',
	paladin: 'Paladin',
	hunter: 'Hunter',
	rogue: 'Rogue',
	priest: 'Priest',
	death_knight: 'Death Knight',
	shaman: 'Shaman',
	mage: 'Mage',
	warlock: 'Warlock',
	monk: 'Monk',
	druid: 'Druid',
	demon_hunter: 'Demon Hunter',
	evoker: 'Evoker'
};

export const CLASS_ALLOWED_ROLES: Record<Classes, Role[]> = {
	warrior: [Role.MELEE, Role.TANK],
	paladin: [Role.MELEE, Role.HEALER, Role.TANK],
	hunter: [Role.MELEE, Role.RANGED],
	rogue: [Role.MELEE],
	priest: [Role.RANGED, Role.HEALER],
	death_knight: [Role.MELEE, Role.TANK],
	shaman: [Role.MELEE, Role.RANGED, Role.HEALER],
	mage: [Role.RANGED],
	warlock: [Role.RANGED],
	monk: [Role.MELEE, Role.HEALER, Role.TANK],
	druid: [Role.MELEE, Role.RANGED, Role.HEALER, Role.TANK],
	demon_hunter: [Role.MELEE, Role.TANK],
	evoker: [Role.RANGED, Role.HEALER]
};
