import type {Character} from '@models/character';

export type CharactersByUserId = Map<UserId, Character[]>;

export function getCharactersByUserId(characters: Character[]): CharactersByUserId {
	const charactersByUserId: CharactersByUserId = new Map<UserId, Character[]>();

	characters.forEach((character) => {
		// ignore characters that don't have a user
		if (!character.userId) return;

		// add this character to the user's character list
		charactersByUserId.set(character.userId, [
			...(charactersByUserId.get(character.userId) || []),
			character
		]);
	});

	return charactersByUserId;
}
