import React from 'react';

import {DifficultyDisplay} from '@constants/wow';

import {connect2, DroptimizerReportDuck} from '@ducks';

import {useBoolean} from '@hooks/useBoolean';

import type {DroptimizerReport} from '@models/droptimizerReport';
import type {Character} from '@models/character';

import {ConfirmModal} from '@components/Utils/ConfirmModal';

interface OwnProps {
	onClose(): void;
	report: DroptimizerReport;
	character: Character;
}

interface DispatchProps {
	onSubmit: BoundThunk<typeof DroptimizerReportDuck['deleteDroptimizerReport']>;
}

type Props = OwnProps & DispatchProps;

const DeleteReportModalComp: React.FC<Props> = (props) => {
	const submitting = useBoolean(false);

	async function handleSubmit(): Promise<void> {
		submitting.setTrue();

		await props.onSubmit({id: props.report.id});

		submitting.setFalse();
		props.onClose();
	}

	return (
		<ConfirmModal
			modalClassName="normal-alignment"
			onSubmit={handleSubmit}
			onClose={props.onClose}
			isSubmitting={submitting.value}
			heading="Delete Droptimizer report"
			submitButtonText="Delete"
			submitColour="red"
		>
			You are about to delete the{' '}
			<strong>{DifficultyDisplay[props.report.difficulty]}</strong> droptimizer report
			for <strong>{props.character.name}</strong>
		</ConfirmModal>
	);
};

export const DeleteReportModal = connect2<{
	Own: OwnProps;
	State: {};
	Dispatch: DispatchProps;
}>(() => ({}), {
	onSubmit: DroptimizerReportDuck.deleteDroptimizerReport
})(DeleteReportModalComp);
