import type {Store} from 'redux';

import Socket, {IReceivedChangeMessage, IReceivedMessage, ISendMessage} from './socket';

import * as BannerDuck from '../ducks/banner';

interface IRequests {
	[key: string]: {
		ts: number;
		resolve: any;
		reject: any;
	};
}

interface ICallHTTPOpts {
	skipJSONParsing?: boolean;
	[key: string]: any;
}

interface ICallOpts {
	error?: boolean;
	delay?: boolean;
	mock?: boolean;

	/** Intentionally slow down this request to improve the UX */
	slowMo?: boolean;
}

type GetHttpResponse<Data> =
	| {
			data: DeepJsonify<Data>;
			error: null;
	  }
	| {
			data: null;
			error: string;
	  };

export interface IChangeAction {
	type: string;

	payload: {
		newRecord?: {
			[key: string]: any;
		};

		oldRecord?: {
			[key: string]: any;
		};
	};
}

let store: Store<IRootState>;
export const syncApiWithStore = (s: Store<IRootState>) => {
	store = s;
};

function handleChange(message: IReceivedChangeMessage) {
	if (message.table === 'users' && message.newRecord && message.oldRecord) {
		const hasChanged =
			message.newRecord.forcedSessionClear !== message.oldRecord.forcedSessionClear;
		const isForced = message.newRecord.forcedSessionClear;

		if (isForced && hasChanged) {
			window.location.reload();
		}
	}

	const action: IChangeAction = {
		type: `feed:${message.table}.${message.type}`,
		payload: {
			newRecord: message.newRecord,
			oldRecord: message.oldRecord
		}
	};

	store.dispatch(action);
}

let count = 0;
const requests: IRequests = {};

type ReconnectionHandler = (isReconnected: boolean) => void;

export class API {
	private readonly reconnectionHandlers: ReconnectionHandler[] = [];
	// private hasBeenConnected: boolean = false;
	private readonly socket: Socket;

	constructor(isDemo = false) {
		// every so often check if there's requests we should
		// just remove because they're obviously broken
		setInterval(() => {
			Object.keys(requests).forEach((key) => {
				const request = requests[key];

				// timeout if the request if it is old
				if (request.ts < Date.now() - 5 * 60 * 1000) {
					request.reject({error: 'Request timed out'});

					delete requests[key];
				}
			});
		}, 10 * 1000);

		this.socket = new Socket({
			path: isDemo ? '/api/demo/ws' : '/api/user/ws',

			onReconnect: this.handleSocketReconnect,
			onClose: this.handleSocketClose,

			onMessage: this.handleSocketMessage,
			onChangeMessage: handleChange
		});
	}

	connect() {
		this.socket.connect(this.handleSocketConnect);
	}

	disconnect() {
		this.socket.disconnect(true);
	}

	async getHttp<ResultData extends object>(
		endpoint: string,
		opts: ICallHTTPOpts = {}
	): Promise<GetHttpResponse<ResultData>> {
		const res = await fetch(`/api/${endpoint}`, {
			...opts,
			credentials: 'include'
		});

		if (!res.ok) {
			const body = await res.json();

			if (process.env.NODE_ENV === 'development') {
				console.error('[HTTP] error', body);
			}

			return {
				error: body.error,
				data: null
			};
		}

		const json: DeepJsonify<ResultData> = await res.json();
		if (process.env.NODE_ENV === 'development') {
			console.warn('[HTTP] result', json);
		}

		return {
			error: null,
			data: json
		};
	}

	callHTTP(endpoint = '', opts: ICallHTTPOpts = {}) {
		const {skipJSONParsing, ...fetchOpts} = opts;

		let p = fetch(`/api/${endpoint}`, {
			...fetchOpts,
			credentials: 'include'
		});

		if (!skipJSONParsing) {
			p = p.then((r) => r.json());
		}

		if (process.env.NODE_ENV === 'development') {
			p.then((r) => console.warn('[HTTP] result', endpoint, r)).catch((e) =>
				console.error('[HTTP] error', e)
			);
		}

		return p;
	}

	postHTTP(endpoint = '', data = {}, opts: ICallHTTPOpts = {}) {
		return this.callHTTP(endpoint, {
			method: 'POST',
			body: JSON.stringify(data),

			headers: {
				'Content-Type': 'application/json'
			},

			...opts
		});
	}

	call<
		InputData extends object = never,
		ResultData extends object = Record<string, any>
		// >(fn: string, data: NoInfer<InputData>, opts: ICallOpts = {}) {
	>(fn: string, data: InputData, opts: ICallOpts = {}) {
		if (!fn) console.error('API.call fn value is', fn);

		return new Promise<IReceivedMessage<ResultData>>((resolve, reject) => {
			// if isn't pre-connection queuing and the socket is down
			// we want to just error out and tell them what happened
			// if (this.hasBeenConnected && !getSocketStatus(store.getState())) {
			// 	reject({error: 'Unable to make request while connection is down'});
			// 	return;
			// }

			count += 1;
			const callId = count;

			requests[callId] = {
				ts: Date.now(),
				resolve,
				reject
			};

			const message = {
				echo: {callId},
				data,
				fn
			};

			if (opts.mock) this.mock(message, opts);
			else if (opts.delay) {
				window.setTimeout(() => this.socket.send(message), 2000);
			} else if (opts.slowMo) {
				window.setTimeout(() => this.socket.send(message), 1500);
			} else this.socket.send(message);
		});
	}

	private mock(message: ISendMessage, opts: ICallOpts) {
		const mock: IReceivedMessage = {
			...message,
			ok: true
		};

		if (opts.error) {
			mock.error = 'some error';
			mock.ok = false;
		}

		setTimeout(() => this.handleSocketMessage(mock), 750);
	}

	private readonly handleSocketMessage = (message: IReceivedMessage) => {
		const request = message && requests[message.echo.callId];
		if (!request) {
			console.warn('API request not found for:', message);
			return;
		}

		delete requests[message.echo];

		if (message.ok) request.resolve(message);
		else request.reject(message);
	};

	private readonly handleSocketConnect = () => {
		// this.hasBeenConnected = true;

		// need to trigger a banner being removed on connection
		// in case theres issues during the initial connection
		store.dispatch(BannerDuck.removeSocketBanner() as any);
	};

	private readonly handleSocketReconnect = () => {
		window.location.reload();

		store.dispatch(BannerDuck.removeSocketBanner() as any);

		// call any reconnection handlers we have
		this.reconnectionHandlers.forEach((fn) => fn(true));
	};

	private readonly handleSocketClose = () => {
		store.dispatch(BannerDuck.addSocketBanner() as any);
	};

	registerReconnectionHandler(fn: ReconnectionHandler) {
		if (!this.reconnectionHandlers.includes(fn)) {
			this.reconnectionHandlers.push(fn);
		}
	}
}

const api = new API();
export default api;

export const demoAPI = new API(true);

if (process.env.NODE_ENV === 'development') {
	window.api = api;
}
