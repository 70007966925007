import * as toolbox from '../helpers/toolbox';

import type {RootAction} from '.';

enum BannerType {
	SOCKET = 'socket',
	ERROR = 'error',
	SUCCESS = 'success'
}

export interface IBanner {
	id: number;

	message: string;
	type: BannerType;
}

export interface IState {
	isSocketBannerDisabled: boolean;

	banners: IBanner[];
}

// types
const ADD = 'banner/ADD';
const REMOVE = 'banner/REMOVE';

const REMOVE_SOCKET = 'banner/REMOVE_SOCKET';
const HIDE_SOCKET = 'banner/HIDE_SOCKET';
const SHOW_SOCKET = 'banner/SHOW_SOCKET';

export interface IActions {
	ADD: {
		readonly type: typeof ADD;
		readonly payload: {
			readonly banner: IBanner;
		};
	};

	REMOVE: {
		readonly type: typeof REMOVE;
		readonly payload: {
			readonly id: number;
		};
	};

	REMOVE_SOCKET: {readonly type: typeof REMOVE_SOCKET};
	HIDE_SOCKET: {readonly type: typeof HIDE_SOCKET};
	SHOW_SOCKET: {readonly type: typeof SHOW_SOCKET};
}

// selectors
export function getBanner(state: IState): IBanner | undefined {
	const list = state.isSocketBannerDisabled
		? state.banners.filter((x) => x.type !== BannerType.SOCKET)
		: state.banners;

	return list[state.banners.length - 1];
}

// actions
function setBanner(
	type: BannerType,
	message: string,
	showForever = false
): ThunkCb<void> {
	return (dispatch) => {
		const id = toolbox.generateId('banner');

		const addAction: IActions['ADD'] = {
			type: ADD,
			payload: {
				banner: {
					message,
					type,
					id
				}
			}
		};

		dispatch(addAction);

		if (!showForever) {
			// clear the banner after some time
			const timer = type !== BannerType.ERROR ? 3500 : 7000;

			window.setTimeout(() => {
				const removeAction: IActions['REMOVE'] = {
					type: REMOVE,
					payload: {id}
				};

				dispatch(removeAction);
			}, timer);
		}
	};
}

export function addSuccessBanner(message: string) {
	return setBanner(BannerType.SUCCESS, message);
}

export function addErrorBanner(message: string) {
	return setBanner(BannerType.ERROR, message || 'Something broke :(');
}

export function addSocketBanner() {
	return setBanner(BannerType.SOCKET, 'Connection to the server has been lost', true);
}

export function removeSocketBanner(): ThunkCb<void> {
	return (dispatch) => {
		const action: IActions['REMOVE_SOCKET'] = {type: REMOVE_SOCKET};
		dispatch(action);
	};
}

export function hideSocketBanners(): ThunkCb<void> {
	return (dispatch, getState) => {
		dispatch<IActions['HIDE_SOCKET']>({
			type: HIDE_SOCKET
		});

		// make a dev alert if socket banners are forgotten to be turned on
		window.setTimeout(() => {
			const state = getState();
			if (state.banners.isSocketBannerDisabled) {
				console.error('Socket banners are still being hidden after 10 seconds');
			}
		}, 1000 * 10);
	};
}

export function showSocketBanners(): ThunkCb<void> {
	return (dispatch) => {
		dispatch<IActions['SHOW_SOCKET']>({
			type: SHOW_SOCKET
		});
	};
}

// reducer
function hasSocketBanner(state: IState) {
	return state.banners.some((b) => b.type === 'socket');
}

const initialState: IState = {
	isSocketBannerDisabled: false,

	banners: []
};

export default function reducer(
	state: IState = initialState,
	action: RootAction
): IState {
	switch (action.type) {
		case ADD: {
			// don't add multiple socket banners
			if (action.payload.banner.type === 'socket' && hasSocketBanner(state)) {
				return state;
			}

			return {
				...state,
				banners: [...state.banners, action.payload.banner]
			};
		}

		case REMOVE: {
			return {
				...state,
				banners: state.banners.filter((b) => b.id !== action.payload.id)
			};
		}

		case REMOVE_SOCKET: {
			return {
				...state,
				banners: state.banners.filter((b) => b.type !== 'socket')
			};
		}

		case HIDE_SOCKET: {
			return {
				...state,
				isSocketBannerDisabled: true
			};
		}

		case SHOW_SOCKET: {
			return {
				...state,
				isSocketBannerDisabled: false
			};
		}

		default: {
			return state;
		}
	}
}
