import React from 'react';
import classnames from 'classnames';

import {ORDERED_EXPORT_ROLES, Role} from '../../constants/wow';
import {BossViewType} from '@constants';

import type * as RosterBossDuck from '../../ducks/roster-boss';

import type {IRosterViewBoss, ICharMetaMap} from './RosterContainer';
import type {IRoleGroups, IChangesByRole} from './RosterView';

import type {Character} from '../../models/character';

import {CopyRosteredModal} from './CopyRosteredModal';
import {CopyChangesModal} from './CopyChangesModal';
import {BossNotesEditor} from '../RosterNew/BossView/BossNotesView/BossNotesEditor';
import {BossAssignments} from './BossAssignments';
import {ClearBossModal} from './ClearBossModal';
import {BossNotes} from './Notes/BossNotes';
import Menu from '../Utils/Menu';

interface IProps {
	onUpdateLimit: typeof RosterBossDuck.updateLimit;
	onUpdateNotes: typeof RosterBossDuck.updateNotes;
	onUnrosterAll: typeof RosterBossDuck.unrosterAll;
	onRoster: typeof RosterBossDuck.rosterCharacter;

	onChangeViewTab(bossViewType: BossViewType): void;
	onChangeRoleTab(role: Role): void;
	onSearch(id: number): void;
	onGoToReorder(): void;

	isUpdatingNotes: boolean;

	bossViewType: BossViewType | undefined;

	canUseAssignmentsView: boolean;
	canUseNotesView: boolean;

	isShowingKillCount: boolean;
	isShowingAdminControls: boolean;
	isShowingChanges: boolean;
	isAdmin: boolean;
	isDemo: boolean;

	wowheadBonus: string;
	selectedRole: Role;
	characterMetaMap: ICharMetaMap;
	characterById: Map<number, Character>;
	roleGroups: IRoleGroups;
	boss: IRosterViewBoss;
	changes?: IChangesByRole;
}

interface IState {
	isShowingCopyRosteredModal: boolean;
	isShowingCopyChangesModal: boolean;
	isShowingBossNotesEditor: boolean;
	isShowingClearBossModal: boolean;
}

export class BossShell extends React.Component<IProps, IState> {
	menu: Menu | null;

	state: IState = {
		isShowingCopyRosteredModal: false,
		isShowingCopyChangesModal: false,
		isShowingBossNotesEditor: false,
		isShowingClearBossModal: false
	};

	handleUpdateNotes = (notes: string) => {
		this.props.onUpdateNotes(this.props.boss.id, notes, () => {
			this.setState({isShowingBossNotesEditor: false});
		});
	};

	handleClearAll = () => {
		this.props.onUnrosterAll(this.props.boss.id);
		this.setState({isShowingClearBossModal: false});
	};

	getRosteredNames() {
		const assignedCharacterNames: string[] = [];

		ORDERED_EXPORT_ROLES.forEach((role) => {
			this.props.roleGroups[role].forEach((char) => {
				const isActive = this.props.boss.roleAssignments[role].includes(char.id);
				if (!isActive) return;

				assignedCharacterNames.push(char.name);
			});
		});

		return assignedCharacterNames;
	}

	render() {
		return (
			<div className="boss">
				{this.state.isShowingCopyRosteredModal && (
					<CopyRosteredModal
						onClose={() => this.setState({isShowingCopyRosteredModal: false})}
						names={this.getRosteredNames()}
					/>
				)}

				{this.state.isShowingCopyChangesModal && this.props.changes && (
					<CopyChangesModal
						onClose={() => this.setState({isShowingCopyChangesModal: false})}
						changes={this.props.changes}
					/>
				)}

				{this.state.isShowingClearBossModal && (
					<ClearBossModal
						onClose={() => this.setState({isShowingClearBossModal: false})}
						onSubmit={this.handleClearAll}
						characterCount={this.getRosteredNames().length}
						bossName={this.props.boss.name}
					/>
				)}

				{this.state.isShowingBossNotesEditor && this.props.isAdmin && (
					<BossNotesEditor
						onClose={() => this.setState({isShowingBossNotesEditor: false})}
						onSubmit={this.handleUpdateNotes}
						isSubmitting={this.props.isUpdatingNotes}
						notes={this.props.boss.notes}
						name={this.props.boss.name}
					/>
				)}

				<div className="content-tabs">
					{this.props.canUseNotesView && (
						<div
							onClick={() => this.props.onChangeViewTab(BossViewType.NOTES)}
							className={classnames(
								{active: this.props.bossViewType === BossViewType.NOTES},
								'tab'
							)}
						>
							<div className="text">NOTES</div>
						</div>
					)}

					{this.props.canUseAssignmentsView && (
						<div
							onClick={() => this.props.onChangeViewTab(BossViewType.ROSTER)}
							className={classnames(
								{active: this.props.bossViewType === BossViewType.ROSTER},
								'tab'
							)}
						>
							<div className="text">ASSIGNMENTS</div>
						</div>
					)}
				</div>

				<div className="overflow-menu">
					<i
						onClick={() => this.menu?.toggleExpanded()}
						className="lnr lnr-ellipsis"
					/>

					<Menu ref={(r) => (this.menu = r)}>
						{this.props.isAdmin && !this.props.isDemo && (
							<div onClick={this.props.onGoToReorder}>Reorder roster bosses</div>
						)}

						{this.props.isAdmin && (
							<div onClick={() => this.setState({isShowingBossNotesEditor: true})}>
								Edit boss notes
							</div>
						)}

						{this.props.isAdmin && (
							<div onClick={() => this.setState({isShowingClearBossModal: true})}>
								Remove all assignments from boss
							</div>
						)}

						<div onClick={() => this.setState({isShowingCopyRosteredModal: true})}>
							Copy names of rostered characters
						</div>

						<div onClick={() => this.setState({isShowingCopyChangesModal: true})}>
							Copy rostered character changes
						</div>
					</Menu>
				</div>

				{this.props.bossViewType === BossViewType.NOTES && (
					<BossNotes
						onEditNotes={() => this.setState({isShowingBossNotesEditor: true})}
						isAdmin={this.props.isAdmin}
						boss={this.props.boss}
					/>
				)}

				{this.props.bossViewType === BossViewType.ROSTER && (
					<BossAssignments
						onChangeRoleTab={this.props.onChangeRoleTab}
						onUpdateLimit={this.props.onUpdateLimit}
						onRoster={this.props.onRoster}
						onSearch={this.props.onSearch}
						isShowingKillCount={this.props.isShowingKillCount}
						isShowingAdminControls={this.props.isShowingAdminControls}
						isShowingChanges={this.props.isShowingChanges}
						isAdmin={this.props.isAdmin}
						wowheadBonus={this.props.wowheadBonus}
						selectedRole={this.props.selectedRole}
						characterMetaMap={this.props.characterMetaMap}
						characterById={this.props.characterById}
						roleGroups={this.props.roleGroups}
						changes={this.props.changes}
						boss={this.props.boss}
					/>
				)}
			</div>
		);
	}
}
