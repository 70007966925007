import React from 'react';

import {RegionPicker} from './RegionPicker';
import Support from '../Utils/Support';

interface IProps {
	onRegionSelect(region: string): void;
	onRegionClose(): void;
	onConnect(): void;
	isSelectingRegion: boolean;
	btag: string | undefined;
}

export const SyncIssues: React.FC<IProps> = (props) => {
	const accountText = props.btag || 'your account';

	return (
		<div className="page-container">
			<Support />

			{props.isSelectingRegion && (
				<RegionPicker
					onClose={props.onRegionClose}
					onClick={props.onRegionSelect}
					isCacheBust={false}
				/>
			)}

			<div className="auth-page">
				<div className="auth-content card">
					<div className="heading">Issues syncing your characters</div>

					<div className="content">
						<div className="description">
							<p>
								<span className="brand-name">Readycheck</span> was unable to sync
								the characters for <b>{accountText}</b>.
							</p>

							<p>
								Please make sure you're signed into the correct Blizzard account
								if you have multiple accounts or share your computer.
							</p>

							<p>
								Only characters that appear on worldofwarcraft.com are able to be
								synced.
							</p>

							<p>
								<span>
									If you continue to have issues, please head over to the{' '}
								</span>
								<a
									href="https://readycheck.io/contact"
									target="_blank"
									rel="noreferrer noopener"
								>
									contact page
								</a>
								<span>
									{' '}
									and reach out to us and and we'll see what we can do to help.
								</span>
							</p>
						</div>

						<div
							onClick={props.onConnect}
							className="auth-button large primary faded button"
						>
							Try again
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
