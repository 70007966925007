import React from 'react';

import Modal from '../Utils/Modal';

interface IProps {
	onSubmit(): void;
	onClose(): void;

	characterCount: number;
	bossName: string;
}

export const ClearBossModal: React.FC<IProps> = (props) => {
	const plural = props.characterCount === 1 ? 'assignment' : 'assignments';

	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content clear-boss-modal">
				<div className="heading">Remove all assignments</div>

				<p>
					Are you sure you want to remove {props.characterCount} {plural} from{' '}
					{props.bossName}?
				</p>

				<div className="actions">
					<div onClick={props.onClose} className="grey button">
						Back
					</div>

					<div onClick={props.onSubmit} className="red button">
						Remove all
					</div>
				</div>
			</div>
		</Modal>
	);
};
