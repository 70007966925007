import React from 'react';

import {connect, RosterDuck} from '@ducks';

import {ConfirmModal} from '@components/Utils/ConfirmModal';

interface OwnProps {
	onClose(): void;

	rosterId: RosterId;
}

interface MapProps {
	isSubmitting: boolean;
}

interface DispatchProps {
	onSubmit: BoundThunk<typeof RosterDuck['removeAllCharacterAssignments']>;
}

type Props = OwnProps & MapProps & DispatchProps;

const ClearRosterAssignmentsModalComp: React.FC<Props> = (props) => {
	async function handleSubmit(): Promise<void> {
		await props.onSubmit(props.rosterId);
		props.onClose();
	}

	return (
		<ConfirmModal
			onSubmit={handleSubmit}
			onClose={props.onClose}
			isSubmitting={props.isSubmitting}
			heading="Remove all assignments from roster"
			submitColour="red"
			submitButtonText="Remove all"
		>
			Are you sure you want to remove all of the assignments from this roster?
		</ConfirmModal>
	);
};

function mapStateToProps(state: IRootState): MapProps {
	return {
		isSubmitting: RosterDuck.getIsUnrosteringAll(state.rosters)
	};
}

export const ClearRosterAssignmentsModal = connect<MapProps, DispatchProps, OwnProps>(
	mapStateToProps,
	{
		onSubmit: RosterDuck.removeAllCharacterAssignments
	}
)(ClearRosterAssignmentsModalComp);
