import type {GuildSponsorship} from '@models/guildSponsorship';

export interface IState {
	readonly guildSponsorshipById: Record<number, GuildSponsorship>;

	readonly sponsoringGuildId: number | null;
	readonly unsponsoringGuildId: number | null;
}

export const SPONSOR = 'guild-sponsorship/SPONSOR';
export const SPONSOR_SUCCESS = 'guild-sponsorship/SPONSOR_SUCCESS';
export const SPONSOR_FAILURE = 'guild-sponsorship/SPONSOR_FAILURE';

export const UNSPONSOR = 'guild-sponsorship/UNSPONSOR';
export const UNSPONSOR_SUCCESS = 'guild-sponsorship/UNSPONSOR_SUCCESS';
export const UNSPONSOR_FAILURE = 'guild-sponsorship/UNSPONSOR_FAILURE';

export interface IActions {
	SPONSOR: {
		readonly type: typeof SPONSOR;
		readonly payload: {
			readonly guildId: number;
		};
	};
	SPONSOR_SUCCESS: {readonly type: typeof SPONSOR_SUCCESS};
	SPONSOR_FAILURE: {readonly type: typeof SPONSOR_FAILURE};

	UNSPONSOR: {
		readonly type: typeof UNSPONSOR;
		readonly payload: {
			readonly guildId: number;
		};
	};
	UNSPONSOR_SUCCESS: {readonly type: typeof UNSPONSOR_SUCCESS};
	UNSPONSOR_FAILURE: {readonly type: typeof UNSPONSOR_FAILURE};
}
