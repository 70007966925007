import React from 'react';
import classnames from 'classnames';

import {dates} from '@helpers/dates';

interface StatProps {
	onClick?: (() => void) | undefined;

	tooltip: string;

	/** Whether to show the '-' placeholder */
	isEmpty: boolean;
	value: string | number | undefined;
	description: string;
}

const Stat: React.FC<StatProps> = (props) => {
	const innerClass = classnames(
		{
			clickable: props.onClick && !props.isEmpty
		},
		'item-inner tooltip tooltip-top tooltip-medium delayed'
	);

	return (
		<div className="meta-item">
			<div
				onClick={props.isEmpty ? undefined : props.onClick}
				className={innerClass}
				data-hint={props.tooltip}
			>
				{props.isEmpty ? (
					<div className="stat-description">-</div>
				) : (
					<>
						<span className="stat-value">{props.value}</span>{' '}
						<span className="stat-description">{props.description}</span>
					</>
				)}
			</div>
		</div>
	);
};

interface BossKillMetaProps {
	characterName: string;

	/** How many times this character has killed the boss on this difficulty */
	killCount: number | undefined;
	/** MS of last kill */
	lastKillAt: number | undefined;
}

const BossKillMeta: React.FC<BossKillMetaProps> = (props) => {
	const countWord = props.killCount === 1 ? 'kill' : 'kills';

	let tooltip: string;
	if (props.killCount === undefined) {
		tooltip = 'No information about kill count';
	} else if (!props.killCount) {
		tooltip = `${props.characterName} hasn't killed this boss`;
	} else {
		const timesWord = props.killCount === 1 ? 'time' : 'times';
		tooltip = `${props.characterName} has killed this boss ${props.killCount} ${timesWord}`;

		if (props.lastKillAt) {
			const timeago = dates(props.lastKillAt).fromNow();
			tooltip = `${tooltip}, the most recent kill was ${timeago}`;
		}
	}

	return (
		<Stat
			tooltip={tooltip}
			isEmpty={props.killCount === undefined}
			value={props.killCount}
			description={countWord}
		/>
	);
};

interface MetaStatsProps {
	onToggleLoot(): void;

	characterName: string;

	/** The number of bosses they're rostered to in total */
	totalAssignmentCount: number;

	/** Whether kill count is enabled for this boss */
	isShowingKillCount: boolean;
	/** How many times this character has killed the boss on this difficulty */
	killCount: number | undefined;
	/** MS of last kill */
	lastKillAt: number | undefined;

	/** The number of items this character has selected from this boss */
	desiredItemsCount: number;
}

export const MetaStats: React.FC<MetaStatsProps> = (props) => {
	return (
		<div className="character-meta-stats">
			<Stat
				tooltip="Number of bosses this character is assigned to"
				isEmpty={!props.totalAssignmentCount}
				value={props.totalAssignmentCount}
				description={props.totalAssignmentCount === 1 ? 'boss' : 'bosses'}
			/>

			{props.isShowingKillCount && (
				<BossKillMeta
					characterName={props.characterName}
					killCount={props.killCount}
					lastKillAt={props.lastKillAt}
				/>
			)}

			<Stat
				onClick={props.onToggleLoot}
				tooltip="Number of items this character wants from this boss"
				isEmpty={!props.desiredItemsCount}
				value={props.desiredItemsCount}
				description={props.desiredItemsCount === 1 ? 'item' : 'items'}
			/>
		</div>
	);
};
