import React from 'react';

import * as BillingLink from '../../Utils/BillingLink';

import AutomaticSyncImage from '../../../images/automatic-sync.png';

export function UpgradeView() {
	return (
		<div className="hero-view">
			<img className="hero-icon" src={AutomaticSyncImage} />

			<div className="hero-description">
				Top up your roster with cross-realm characters or characters from outside of
				your guild.
			</div>

			{BillingLink.createExternalCharactersLink(
				'Get early access',
				'large primary button'
			)}
		</div>
	);
}
