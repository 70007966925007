import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router';

import * as wow from '../../constants/wow';

import * as images from '../../helpers/images';

import type * as CharacterDuck from '../../ducks/character';

import type {Character} from '../../models/character';
import {Guild} from '../../models/guild';

export const TextContent = {
	title: 'Configure character roles',
	description:
		'Define all the roles that each character usually performs within your raids.',
	note: `Classes that can only be one role won't appear in this list.`
};

interface ISelectRolesProps {
	onSelect: typeof CharacterDuck.setRole;

	isEmbeddedView: boolean;

	characters: Character[];
	guildId: number;
}

export default function SelectRoles(props: ISelectRolesProps) {
	const characters = props.characters.map((char) => (
		<CharacterRow key={char.id} onSelect={props.onSelect} character={char} />
	));

	return (
		<div className={classnames({embedded: props.isEmbeddedView}, 'select-roles')}>
			{!props.isEmbeddedView && (
				<div className="heading">
					<h1 className="big">{TextContent.title}</h1>

					<p>{TextContent.description}</p>

					<Buttons guildId={props.guildId} />

					<div className="note">{TextContent.note}</div>
				</div>
			)}

			<div className="content">
				<div className="character-rows">{characters}</div>
			</div>

			{!props.isEmbeddedView && <Buttons guildId={props.guildId} />}
		</div>
	);
}

interface ICharacterRowProps {
	onSelect: typeof CharacterDuck.setRole;

	character: Character;
}

function CharacterRow(props: ICharacterRowProps) {
	const char = props.character;

	const characterClass = classnames(
		{
			roleless: !char.roles.length
		},
		char.class,
		'character-row wow-style bg-before'
	);

	const roleBoxes = wow.ROLES_ORDERED.map((role) => {
		const isRole = char.roles.includes(role);

		const roleClass = classnames(
			{
				disabled: !wow.CLASS_ALLOWED_ROLES[char.class].includes(role),
				selected: isRole
			},
			'role tooltip tooltip-top delayed'
		);

		return (
			<div
				key={role}
				onClick={() => props.onSelect(char.id, role, !isRole)}
				data-hint={wow.ROLES_TO_DISPLAY[role]}
				className={roleClass}
			>
				<img src={images.getRoleImageUrl(role)} />

				{isRole && (
					<div className="checkmark-circle">
						<i className="lnr lnr-check" />
					</div>
				)}
			</div>
		);
	});

	return (
		<div className={characterClass}>
			<div className="left">
				<img src={char.getAvatarUrl()} className="thumbnail" />

				<div className="details">
					<div className="name">{char.name}</div>

					<div className="secondary">{wow.CLASS_TO_DISPLAY[char.class]}</div>
				</div>
			</div>

			<div className="role-selection">{roleBoxes}</div>
		</div>
	);
}

interface IButtonProps {
	guildId: number;
}

function Buttons(props: IButtonProps) {
	return (
		<div className="submit-buttons">
			<Link to={Guild.createBaseUrl(props.guildId)} className="large primary button">
				Done
			</Link>
		</div>
	);
}
