import React from 'react';
import classnames from 'classnames';

import {useClickAway} from '@hooks/useClickAway';
import {useUnmount} from '@hooks/useUnmount';

const noop = () => {};

interface PaneProps {
	onDismount?(): void;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	onClose(): void;

	className?: string;
	withBorder?: boolean;

	children: React.ReactNode;
}

export const Pane: React.FC<PaneProps> = (props) => {
	const $ref = React.useRef(null);

	useClickAway(props.onClose, $ref);
	useUnmount(props.onDismount || noop);

	return (
		<div
			ref={$ref}
			onClick={props.onClick}
			className={classnames(
				{'with-border': props.withBorder},
				'pane-widget',
				props.className
			)}
		>
			{props.children}
		</div>
	);
};
