import {connect} from 'react-redux';

import * as toolbox from '../../../helpers/toolbox';

import * as CharacterDuck from '../../../ducks/character';
import * as GuildDuck from '../../../ducks/guild';
import * as UserDuck from '../../../ducks/user';

import type {Character} from '../../../models/character';

import RemoveCharacters from './RemoveCharacters';

export interface IRankGroup {
	isAdmin: boolean;
	characters: Character[];
	rankName: string;
	rank: number;
}

interface IOwnProps {
	guildId: number;
}

function mapStateToProps(state: IRootState, props: IOwnProps) {
	const guild = GuildDuck.getActiveGuild(state.guilds);
	const user = UserDuck.getUser(state.user);

	let rankGroups: IRankGroup[] = [];
	let userCharacterIds: number[] = [];

	if (guild) {
		const rankGroupMap: {[rankNumber: string]: IRankGroup} = {};

		const characters = CharacterDuck.getCharactersForGuild(
			state.characters,
			props.guildId,
			{
				withUnrostered: true,
				withMissing: true
			}
		).sort(toolbox.sortCharactersByName);

		characters.forEach((char) => {
			if (!rankGroupMap[char.rank]) {
				rankGroupMap[char.rank] = {
					isAdmin: !!guild.adminRanks[char.rank],
					rankName: guild.fullRankNames[char.rank],
					rank: char.rank,
					characters: []
				};
			}

			rankGroupMap[char.rank].characters.push(char);
		});

		rankGroups = Object.values(rankGroupMap).sort((a, b) => a.rank - b.rank);

		if (user) {
			userCharacterIds = CharacterDuck.getCharacterIdsForUser(
				state.characters,
				guild.id,
				user
			);
		}
	}

	return {
		restoringIds: CharacterDuck.getRestoringIds(state.characters),
		isRevoking: CharacterDuck.getRevoking(state.characters),
		guildName: guild ? guild.name : '',
		userCharacterIds,
		rankGroups
	};
}

export default connect(mapStateToProps, {
	onRestore: CharacterDuck.restore,
	onRevoke: CharacterDuck.revoke
})(RemoveCharacters);
