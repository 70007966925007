import React from 'react';
import {connect} from 'react-redux';
import Reorder from 'react-reorder';
import classnames from 'classnames';

import * as Toolbox from '../../../helpers/toolbox';
import * as Images from '../../../helpers/images';

import * as RosterBossDuck from '../../../ducks/roster-boss';
import * as RosterDuck from '../../../ducks/roster';
import * as RaidDataDuck from '../../../ducks/raid-data';

import type {RosterBoss} from '../../../models/roster-boss';
import type {IRaidBoss} from '../../../models/raid-data';
import type {Roster} from '../../../models/roster';

interface IBoss {
	rosterBoss: RosterBoss;
	raidBoss: IRaidBoss;
}

interface IBossItemProps extends IBoss {
	className?: string;
}

const BossItem: React.FC<IBossItemProps> = (props) => {
	const {raidBoss, rosterBoss, className, ...rest} = props;

	const itemClassName = classnames(className, 'roster-boss-item');

	return (
		<div className={itemClassName} {...rest}>
			<img src={Images.getBossImageUrl(rosterBoss.bossId)} />

			<div className="name">{raidBoss.name}</div>
		</div>
	);
};

interface IOwnProps {
	baseUrl: string;
	guildId: number;

	params: {
		rosterId: string;
	};
}

interface IProps extends IOwnProps {
	onReorder: typeof RosterBossDuck.reorder;

	roster: Roster | undefined;
	bosses: IBoss[];
}

class RosterBossesComp extends React.Component<IProps> {
	handleReorder = (e: any, oldIndex: number, newIndex: number) => {
		const reordered: IBoss[] = Reorder.reorder(this.props.bosses, oldIndex, newIndex);

		const after = reordered[newIndex + 1];
		const before = reordered[newIndex - 1];
		const order = Toolbox.calculateNewOrder(
			before ? before.rosterBoss.order : 0,
			after ? after.rosterBoss.order : 0
		);

		const boss = reordered[newIndex];
		this.props.onReorder(boss.rosterBoss.id, order);
	};

	render() {
		if (!this.props.roster) return <div />;

		const items = this.props.bosses.map((boss) => (
			<BossItem
				key={boss.rosterBoss.id}
				rosterBoss={boss.rosterBoss}
				raidBoss={boss.raidBoss}
			/>
		));

		return (
			<div className="settings-content roster-bosses-settings">
				<div className="settings-heading">Reorder Bosses</div>

				<div className="help-block">
					<span>Here you may control the order in which bosses appear for </span>
					<strong>{this.props.roster.name}</strong>
					<span>, allowing </span>
					<span className="brand-name">Readycheck</span>
					<span> to reflect the order you plan to kill them in.</span>
				</div>

				<Reorder
					onReorder={this.handleReorder}
					reorderId="guild-setting-roster-bosses"
					className="roster-bosses-list"
					lock="horizontal"
				>
					{items}
				</Reorder>
			</div>
		);
	}
}

function mapStateToProps(state: IRootState, props: IOwnProps) {
	const rosterId = Number.parseInt(props.params.rosterId, 10);
	const roster = RosterDuck.getRoster(state.rosters, rosterId);

	const bosses: IBoss[] = [];
	if (roster) {
		const rosterBosses = RosterBossDuck.getBossesForRoster(
			state.rosterBosses,
			roster.id
		);
		const raidInstance = RaidDataDuck.getRaidInstance(
			state.raidData,
			roster.wowInstanceId
		);

		rosterBosses.forEach((rosterBoss) => {
			const raidBoss = raidInstance
				? raidInstance.bosses.find((b) => b.id === rosterBoss.bossId)
				: undefined;
			if (!raidBoss) return;

			bosses.push({
				rosterBoss,
				raidBoss
			});
		});

		bosses.sort((a, b) => a.rosterBoss.order - b.rosterBoss.order);
	}

	return {
		bosses,
		roster
	};
}

export const RosterBosses = connect(mapStateToProps, {
	onReorder: RosterBossDuck.reorder
})(RosterBossesComp);
