import type {createLogger as CreateLogger} from 'redux-logger';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

// import api from './helpers/api';

import createReducer, {RootAction} from './ducks';

export default function configureStore() {
	const reducer = createReducer();
	// const wares: any[] = [thunk.withExtraArgument(api)];
	const wares: any[] = [thunk];

	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
		const {createLogger} = require('redux-logger');

		const logger = (createLogger as typeof CreateLogger)({
			duration: true,
			timestamp: false,
			level: {
				prevState: 'log',
				action: 'log',
				nextState: 'log',
				error: 'error'
			},

			// the typings are wrong for this, so just hack around it
			collapsed(...args: any[]) {
				return !args[2].error;
			}
		});

		wares.push(logger);
	}

	const middleware = applyMiddleware(...wares);
	const store = createStore<IRootState, RootAction, {}, {}>(reducer, middleware);

	return store;
}
