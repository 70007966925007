import React from 'react';

import {dates} from '@helpers/dates';

interface IProps {
	date: number;
}

export class TimeAgo extends React.Component<IProps> {
	static getDate(date: number) {
		// beyond 1 week, just show date
		const threshold = 1000 * 60 * 60 * 24 * 7;

		if (Math.abs(Date.now() - date) < threshold) {
			return dates(date).fromNow();
		}

		return dates(date).format('D MMM YYYY');
	}

	timer: number;

	componentDidMount() {
		this.tick();
	}

	componentWillUnmount() {
		window.clearTimeout(this.timer);
	}

	tick = () => {
		this.forceUpdate();
		this.timer = window.setTimeout(this.tick, 15_000);
	};

	render() {
		const {date, ...props} = this.props;

		return <span {...props}>{TimeAgo.getDate(date)}</span>;
	}
}

export default TimeAgo;
