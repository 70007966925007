import React from 'react';
import {Link} from 'react-router';

import type * as GuildDuck from '../../../ducks/guild';

import RankDetailsContent from './RankDetailsContent';

export interface IMapProps {
	isSubmitting: boolean;

	rankNames: string[];
	rankName: string;
	index: number;

	baseUrl: string;
}

interface IProps extends IMapProps {
	onUpdate: typeof GuildDuck.renameRank;
}

export default function RankSettings(props: IProps) {
	const items = props.rankNames.map((name, i) => (
		// eslint-disable-next-line react/no-array-index-key
		<ListItem key={i} baseUrl={props.baseUrl} rankName={name} index={i} />
	));

	return (
		<div className="settings-content no-padding loot-options">
			<div className="settings-split-content">
				<div className="list-content-container">
					<div className="settings-heading">Rank names</div>

					<div className="list-content">{items}</div>
				</div>

				<RankDetailsContent
					onUpdate={props.onUpdate}
					isSubmitting={props.isSubmitting}
					rankName={props.rankName}
					index={props.index}
				/>
			</div>
		</div>
	);
}

interface IItemProps {
	baseUrl: string;
	rankName: string;
	index: number;
}

function ListItem(props: IItemProps) {
	return (
		<Link
			to={`${props.baseUrl}/${props.index}`}
			activeClassName="active"
			className="list-item"
		>
			<div className="name">{props.rankName}</div>

			<div className="description">Rank {props.index + 1}</div>
		</Link>
	);
}
