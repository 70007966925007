import React from 'react';

import type {RaidItem} from '@models/raid-data';

import {generateWowheadLinkData} from '../../Loot/Item/Item';

interface IProps {
	item: RaidItem;
	wowheadBonus: string | undefined;
}

export const ItemInfo: React.FC<IProps> = (props) => {
	const linkData = generateWowheadLinkData(props.item.id, props.wowheadBonus);

	return (
		<div className="item-info">
			<a href={linkData.url} rel="noopener noreferrer" target="_blank">
				<img
					className={props.item.slot}
					src={`/static/item-icons/${props.item.id}`}
				/>

				<div className="name">{props.item.name}</div>
			</a>
		</div>
	);
};
