import type {Role} from '../../../constants/wow';

import type {Character} from '../../../models/character';

interface IAssignment {
	role: Role;
	character: Character;
}

export type AssignmentsByUserId = Map<number, IAssignment[]>;

export function calculateAssignmentsByUserId(data: {
	charactersByUserId: Map<number, Character[]>;
	assignedRoleByCharacterId: Map<number, Role>;
}): AssignmentsByUserId {
	const assignmentsByUserId: AssignmentsByUserId = new Map<number, IAssignment[]>();

	data.charactersByUserId.forEach((characters, userId) => {
		const assignments: IAssignment[] = [];

		characters.forEach((character) => {
			const assignedRole = data.assignedRoleByCharacterId.get(character.id);

			if (assignedRole) {
				assignments.push({
					role: assignedRole,
					character
				});
			}
		});

		assignmentsByUserId.set(userId, assignments);
	});

	return assignmentsByUserId;
}
