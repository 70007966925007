import type {
	ISubscribeWithStripeInput,
	ISubscribeWithStripeResult,
	IUpdatePaymentWithStripeInput,
	IUpdatePaymentWithStripeResult,
	IUpdatePlanWithStripeInput,
	IUpdatePlanWithStripeResult,
	ICancelWithStripeInput,
	ICancelWithStripeResult,
	IRestoreWithStripeInput,
	IRestoreWithStripeResult
} from 'api-types';

import {UserBillingDuck, BannerDuck} from '@ducks';

import * as rpc from '../../../shared/constants/rpc';

import api from '../../helpers/api';

export function subscribe(stripePriceId: string, sponsorGuildIds: number[]): Thunk<void> {
	return (dispatch) => {
		const stripe = UserBillingDuck.getStripe();
		if (!stripe) {
			dispatch(BannerDuck.addErrorBanner('Error loading payment provider'));
			console.error('Stripe not loaded for subscribe');
			return Promise.reject();
		}

		dispatch<UserBillingDuck.IActions['SUBSCRIBE']>({
			type: UserBillingDuck.SUBSCRIBE,
			payload: {
				stripePriceId
			}
		});

		return api
			.call<ISubscribeWithStripeInput, ISubscribeWithStripeResult>(
				rpc.BILLING_SUBSCRIBE_STRIPE,
				{stripePriceId, sponsorGuildIds}
			)
			.then(
				async (message) => {
					try {
						// hide the socket banner to avoid the split setting of "error"
						dispatch(BannerDuck.hideSocketBanners());

						await stripe.redirectToCheckout({
							sessionId: message.data.stripeSessionId
						});
					} catch (e) {
						// enable showing socket banners again since the redirect failed
						dispatch(BannerDuck.showSocketBanners());

						dispatch(BannerDuck.addErrorBanner('Error redirecting to checkout'));
						console.error('Error redirecting to stripe checkout: subscribe', e);
					}

					dispatch<UserBillingDuck.IActions['SUBSCRIBE_SUCCESS']>({
						type: UserBillingDuck.SUBSCRIBE_SUCCESS
					});
				},
				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<UserBillingDuck.IActions['SUBSCRIBE_FAILURE']>({
						type: UserBillingDuck.SUBSCRIBE_FAILURE
					});
				}
			);
	};
}

export function updatePayment(): Thunk<void> {
	return (dispatch) => {
		const stripe = UserBillingDuck.getStripe();
		if (!stripe) {
			dispatch(BannerDuck.addErrorBanner('Error loading payment provider'));
			console.error('Stripe not loaded for uodate-payment');
			return Promise.reject();
		}

		dispatch<UserBillingDuck.IActions['UPDATE_PAYMENT']>({
			type: UserBillingDuck.UPDATE_PAYMENT
		});

		return api
			.call<IUpdatePaymentWithStripeInput, IUpdatePaymentWithStripeResult>(
				rpc.BILLING_UPDATE_PAYMENT_STRIPE,
				{}
			)
			.then(
				async (message) => {
					try {
						// hide the socket banner to avoid the split setting of "error"
						dispatch(BannerDuck.hideSocketBanners());

						await stripe.redirectToCheckout({
							sessionId: message.data.stripeSessionId
						});
					} catch (e) {
						// enable showing socket banners again since the redirect failed
						dispatch(BannerDuck.showSocketBanners());

						dispatch(BannerDuck.addErrorBanner('Error redirecting to checkout'));
						console.error(
							'Error redirecting to stripe checkout: update-payment',
							e
						);
					}

					dispatch<UserBillingDuck.IActions['UPDATE_PAYMENT_SUCCESS']>({
						type: UserBillingDuck.UPDATE_PAYMENT_SUCCESS
					});
				},
				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<UserBillingDuck.IActions['UPDATE_PAYMENT_FAILURE']>({
						type: UserBillingDuck.UPDATE_PAYMENT_FAILURE
					});
				}
			);
	};
}

export function updatePlan(stripePriceId: string): Thunk<void> {
	return (dispatch) => {
		dispatch<UserBillingDuck.IActions['UPDATE_PLAN']>({
			type: UserBillingDuck.UPDATE_PLAN,
			payload: {
				stripePriceId
			}
		});

		return api
			.call<IUpdatePlanWithStripeInput, IUpdatePlanWithStripeResult>(
				rpc.BILLING_UPDATE_PLAN_STRIPE,
				{stripePriceId}
			)
			.then(
				() => {
					dispatch(BannerDuck.addSuccessBanner('Subscription successfully updated'));
					dispatch<UserBillingDuck.IActions['UPDATE_PLAN_SUCCESS']>({
						type: UserBillingDuck.UPDATE_PLAN_SUCCESS
					});
				},
				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<UserBillingDuck.IActions['UPDATE_PLAN_FAILURE']>({
						type: UserBillingDuck.UPDATE_PLAN_FAILURE
					});
				}
			);
	};
}

export function cancel(): Thunk<void> {
	return (dispatch) => {
		dispatch<UserBillingDuck.IActions['CANCEL']>({
			type: UserBillingDuck.CANCEL
		});

		return api
			.call<ICancelWithStripeInput, ICancelWithStripeResult>(
				rpc.BILLING_CANCEL_STRIPE,
				{}
			)
			.then(
				() => {
					dispatch(
						BannerDuck.addSuccessBanner('Subscription successfully canceled')
					);
					dispatch<UserBillingDuck.IActions['CANCEL_SUCCESS']>({
						type: UserBillingDuck.CANCEL_SUCCESS
					});
				},
				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<UserBillingDuck.IActions['CANCEL_FAILURE']>({
						type: UserBillingDuck.CANCEL_FAILURE
					});
				}
			);
	};
}

export function restore(): Thunk<void> {
	return (dispatch) => {
		dispatch<UserBillingDuck.IActions['RESTORE']>({
			type: UserBillingDuck.RESTORE
		});

		return api
			.call<IRestoreWithStripeInput, IRestoreWithStripeResult>(
				rpc.BILLING_RESTORE_STRIPE,
				{}
			)
			.then(
				() => {
					dispatch(
						BannerDuck.addSuccessBanner('Subscription successfully restored')
					);
					dispatch<UserBillingDuck.IActions['RESTORE_SUCCESS']>({
						type: UserBillingDuck.RESTORE_SUCCESS
					});
				},
				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<UserBillingDuck.IActions['RESTORE_FAILURE']>({
						type: UserBillingDuck.RESTORE_FAILURE
					});
				}
			);
	};
}
