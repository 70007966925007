import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {Link, browserHistory as router} from 'react-router';
import Mousetrap from 'mousetrap';

import {setRecorderTag} from '../../helpers/recorder';
import {PathSegments} from '@helpers/urls';

import * as CharacterDuck from '../../ducks/character';
import * as GuildDuck from '../../ducks/guild';
import * as UserDuck from '../../ducks/user';

import {Guild} from '../../models/guild';

import Banner from '../Utils/Banner';

export interface ISettingsPageProps {
	baseGuildUrl: string;
	baseUrl: string;
	guildId: number;
	guild: Guild;
}

function createMakeLink(baseUrl: string) {
	return (name: string, endpoint: string, isAlerted?: boolean) => {
		const to = `${baseUrl}/${endpoint}`;
		const linkClass = classnames({
			active: window.location.pathname.startsWith(to)
		});

		return (
			<Link className={linkClass} to={to}>
				{name}

				{isAlerted && <div className="link-alert" />}
			</Link>
		);
	};
}

interface IMapProps {
	redirectUrl: string;
	baseUrl: string;
	baseGuildUrl: string;

	hasMissingExternalCharacters: boolean;
	isAdmin: boolean;
	guildId: number;
	guild: Guild;
}

type IProps = IMapProps;

class GuildSettings extends React.Component<IProps> {
	mousetrap: MousetrapInstance;

	componentDidMount() {
		this.mousetrap = new Mousetrap(window.document.body);
		this.mousetrap.bind('esc', this.handleClose);

		if (!this.props.isAdmin) {
			window.setTimeout(() => router.replace(this.props.baseGuildUrl), 0);
		}

		setRecorderTag('is_using_settings', 'true');
	}

	componentWillUnmount() {
		this.mousetrap.reset();
	}

	handleClose = () => {
		router.push(this.props.redirectUrl);
	};

	render() {
		if (!this.props.isAdmin) return false;

		const makeLink = createMakeLink(this.props.baseUrl);

		const contentProps: ISettingsPageProps = {
			baseGuildUrl: this.props.baseGuildUrl,
			baseUrl: this.props.baseUrl,
			guildId: this.props.guildId,
			guild: this.props.guild
		};

		const content = React.cloneElement(this.props.children as any, contentProps);

		return (
			<div className="settings-page guild-settings">
				<Banner />

				<div className="panel">
					<div className="panel-content">
						<div className="group">
							<div className="group-heading">Character management</div>

							{makeLink('Add Characters', 'add-characters')}
							{makeLink('Remove Characters', 'remove-characters')}
							{makeLink(
								'Non-guild Characters',
								PathSegments.GUILD_SETTINGS_EXTERNAL_CHARACTERS,
								!!this.props.hasMissingExternalCharacters
							)}

							{makeLink('Roles / Spec', 'character-roles')}

							{makeLink('Tags', 'tags')}
						</div>

						<div className="group">
							<div className="group-heading">Guild</div>

							{makeLink('Administrators', 'admins')}
							{makeLink('Rank Names', 'rank-names')}
							{makeLink('Misc', 'misc')}
						</div>

						<div className="group">
							<div className="group-heading">Content management</div>

							{makeLink('Rosters', 'rosters')}
							{makeLink('Loot Options', 'loot-options')}
						</div>
					</div>
				</div>

				<div className="content-wrapper">
					{this.props.guild && content}

					<div className="close-button">
						<i onClick={this.handleClose} className="lnr lnr-cross-circle" />

						<div className="button-label">ESC</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: IRootState): IMapProps {
	const guildId = GuildDuck.getActiveGuildId(state.guilds)!;

	const guild = GuildDuck.getGuild(state.guilds, guildId);
	const user = UserDuck.getUser(state.user);

	let isAdmin = false;
	if (guild && user) {
		const characters = CharacterDuck.getAllCharactersForGuild(
			state.characters,
			guildId
		);
		isAdmin = guild.isUserAdmin(user, characters);
	}

	const baseGuildUrl = Guild.createBaseUrl(guildId);

	return {
		redirectUrl: UserDuck.getUrl(state.user),
		baseUrl: `${baseGuildUrl}/guild-settings`,
		baseGuildUrl,

		hasMissingExternalCharacters: !!CharacterDuck.getMissingRosteredExternalCharacters(
			state.characters,
			guildId
		).length,

		isAdmin,
		guildId,
		guild
	};
}

export default connect(mapStateToProps)(GuildSettings);
