interface ITagAssignmentData {
	// id: number

	characterId: number;
	// guildId: number
	tagId: number;
}

export interface ITagAssignmentClass extends ITagAssignmentData {}

export class TagAssignment implements ITagAssignmentClass {
	// readonly id: number;

	readonly characterId: number;
	// readonly guildId: number;
	readonly tagId: number;

	constructor(data?: ITagAssignmentClass) {
		if (!data) throw new Error('invalid tag-assignment data');

		// this.id = data.id;

		this.characterId = data.characterId;
		// this.guildId = data.guildId;
		this.tagId = data.tagId;
	}
}
