import {getBossesForRoster} from '../../../ducks/roster-boss';

import type {RaidInstance} from '../../../models/raid-data';
import type {RosterBoss} from '../../../models/roster-boss';
import type {Roster} from '../../../models/roster';

interface IGetRoster {
	raidInstanceId: string;

	selectedRosterId: number | undefined;
	rosters: Roster[];
}

function getRoster(data: IGetRoster): Roster | undefined {
	const currentRaidInstanceRosters = data.rosters.filter((x) => {
		if (x.wowInstanceId !== data.raidInstanceId) return false;
		if (x.isArchived || x.isDeleted) return false;

		return true;
	});

	currentRaidInstanceRosters.sort((a, b) => {
		// sort the selected roster first
		if (data.selectedRosterId) {
			if (a.id === data.selectedRosterId) return -1;
			if (b.id === data.selectedRosterId) return 1;
		}

		// then sort by default roster
		if (a.isDefault) return -1;
		if (b.isDefault) return 1;

		return 0;
	});

	return currentRaidInstanceRosters[0];
}

interface IGetBosses {
	rosterBossesState: IRootState['rosterBosses'];

	raidInstance: RaidInstance;

	selectedRosterId: number | undefined;
	rosters: Roster[];
}

export function getBosses(data: IGetBosses) {
	const wowBosses = [...data.raidInstance.bosses];

	const roster = getRoster({
		raidInstanceId: data.raidInstance.id,
		selectedRosterId: data.selectedRosterId,
		rosters: data.rosters
	});

	if (roster) {
		const rosterBosses = getBossesForRoster(data.rosterBossesState, roster.id);

		const rosterBossByWowBossId = new Map<string, RosterBoss>();
		rosterBosses.forEach((x) => rosterBossByWowBossId.set(x.bossId, x));

		wowBosses.sort((a, b) => {
			const aBoss = rosterBossByWowBossId.get(a.id);
			const bBoss = rosterBossByWowBossId.get(b.id);

			if (!aBoss) return 1;
			if (!bBoss) return -1;

			return aBoss.order - bBoss.order;
		});
	}

	return wowBosses;
}
