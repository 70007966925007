import React from 'react';

import {dates} from '@helpers/dates';

interface IProps {
	characterName: string;

	killCount: number | undefined;
	lastKillAt: number | undefined;
}

export const BossKillMeta: React.FC<IProps> = React.memo((props) => {
	const countWord = props.killCount === 1 ? 'kill' : 'kills';

	let tooltip: string;
	if (props.killCount === undefined) {
		tooltip = 'No information about kill count';
	} else if (!props.killCount) {
		tooltip = `${props.characterName} hasn't killed this boss`;
	} else {
		const timesWord = props.killCount === 1 ? 'time' : 'times';
		tooltip = `${props.characterName} has killed this boss ${props.killCount} ${timesWord}`;

		if (props.lastKillAt) {
			const timeago = dates(props.lastKillAt).fromNow();
			tooltip = `${tooltip}, the most recent kill was ${timeago}`;
		}
	}

	return (
		<div
			className="meta-item tooltip tooltip-top tooltip-medium delayed"
			data-hint={tooltip}
		>
			{props.killCount === undefined ? '-' : `${props.killCount} ${countWord}`}
		</div>
	);
});
