import type {Dispatch} from 'redux';

import type {
	CopyAssignmentsToBossesInput,
	CopyAssignmentsToBossesResult
} from 'api-types';

import * as rpc from '../../shared/constants/rpc';
import * as feed from '../constants/feed';

import * as toolbox from '../helpers/toolbox';
import api from '../helpers/api';

import * as BannerDuck from './banner';
import * as GuildDuck from './guild';
import * as DemoDuck from './demo';
import type {RootAction} from '.';

import type {IRosterRoleAssignmentClass} from '../models/roster-role-assignment';
import {RosterBoss} from '../models/roster-boss';

interface IById {
	[rosterBossId: number]: RosterBoss;
	[rosterBossId: string]: RosterBoss;
}

export interface IBossAssignments {
	melee: CharacterId[];
	ranged: CharacterId[];
	healers: CharacterId[];
	tanks: CharacterId[];
}

interface IRoleAssignmentsByBossId {
	[rosterBossId: number]: number[];
}

interface IAssignments {
	[role: string]: IRoleAssignmentsByBossId;

	melee: IRoleAssignmentsByBossId;
	ranged: IRoleAssignmentsByBossId;
	healers: IRoleAssignmentsByBossId;
	tanks: IRoleAssignmentsByBossId;
}

export interface IState {
	readonly assignments: IAssignments;
	readonly bossesById: IById;

	readonly isUpdatingNotes: boolean;
}

// types
export const ROSTER_CHARACTER = 'roster-boss/ROSTER_CHARACTER';
export const ROSTER_CHARACTER_SUCCESS = 'roster-boss/ROSTER_CHARACTER_SUCCESS';
const ROSTER_CHARACTER_FAILURE = 'roster-boss/ROSTER_CHARACTER_FAILURE';

export const UNROSTER_ALL = 'roster-boss/UNROSTER_ALL';
export const UNROSTER_ALL_SUCCESS = 'roster-boss/UNROSTER_ALL_SUCCESS';
const UNROSTER_ALL_FAILURE = 'roster-boss/UNROSTER_ALL_FAILURE';

export const COPY_ASSIGNMENTS_TO_BOSSES = 'roster-boss/COPY_ASSIGNMENTS_TO_BOSSES';

const REORDER = 'roster-boss/REORDER';
const REORDER_SUCCESS = 'roster-boss/REORDER_SUCCESS';
const REORDER_FAILURE = 'roster-boss/REORDER_FAILURE';

export const UPDATE_NOTES = 'roster-boss/UPDATE_NOES';
export const UPDATE_NOTES_SUCCESS = 'roster-boss/UPDATE_NOES_SUCCESS';
const UPDATE_NOTES_FAILURE = 'roster-boss/UPDATE_NOES_FAILURE';

export interface IActions {
	ROSTER_CHARACTER: {
		readonly type: typeof ROSTER_CHARACTER;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly assignment: IRosterRoleAssignmentClass;
			readonly isActive: boolean;
		};
	};
	ROSTER_CHARACTER_SUCCESS: {
		readonly type: typeof ROSTER_CHARACTER_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	ROSTER_CHARACTER_FAILURE: {
		readonly type: typeof ROSTER_CHARACTER_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	UNROSTER_ALL: {
		readonly type: typeof UNROSTER_ALL;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly rosterBossId: number;
		};
	};
	UNROSTER_ALL_SUCCESS: {
		readonly type: typeof UNROSTER_ALL_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	UNROSTER_ALL_FAILURE: {
		readonly type: typeof UNROSTER_ALL_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	COPY_ASSIGNMENTS_TO_BOSSES: {
		readonly type: typeof COPY_ASSIGNMENTS_TO_BOSSES;
	};

	REORDER: {
		readonly type: typeof REORDER;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly boss: RosterBoss;
		};
	};
	REORDER_SUCCESS: {
		readonly type: typeof REORDER_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	REORDER_FAILURE: {
		readonly type: typeof REORDER_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	UPDATE_NOTES: {readonly type: typeof UPDATE_NOTES};
	UPDATE_NOTES_SUCCESS: {readonly type: typeof UPDATE_NOTES_SUCCESS};
	UPDATE_NOTES_FAILURE: {readonly type: typeof UPDATE_NOTES_FAILURE};
}

// selectors
export function getIsUpdatingNotes(state: IState) {
	return state.isUpdatingNotes;
}

export function getForId(state: IState, id: number): RosterBoss | undefined {
	return state.bossesById[id];
}

/** Get sorted RosterBosses for a given Roster */
export function getBossesForRoster(state: IState, rosterId: number) {
	return Object.values(state.bossesById)
		.filter((boss) => boss.rosterId === rosterId)
		.sort((a, b) => a.order - b.order);
}

export function getAssignmentsForBoss(state: IState, bossId: RosterBossId) {
	return {
		melee: state.assignments.melee[bossId] || [],
		ranged: state.assignments.ranged[bossId] || [],
		healers: state.assignments.healers[bossId] || [],
		tanks: state.assignments.tanks[bossId] || []
	} as const;
}

export function getAssignmentsForRosterId(state: IState, rosterId: number) {
	const bosses = getBossesForRoster(state, rosterId);

	const roleAssignments: IBossAssignments = {
		melee: [],
		ranged: [],
		healers: [],
		tanks: []
	};

	bosses.forEach((boss) => {
		const bossRoles = getAssignmentsForBoss(state, boss.id);

		roleAssignments.melee.push(...bossRoles.melee);
		roleAssignments.ranged.push(...bossRoles.ranged);
		roleAssignments.healers.push(...bossRoles.healers);
		roleAssignments.tanks.push(...bossRoles.tanks);
	});

	return roleAssignments;
}

// actions
export function dispatchOptimisticUnrosterAllCharacters(
	dispatch: Dispatch<RootAction>,
	rosterBossId: number
) {
	const optimisticId = toolbox.optimistic.getId();

	dispatch<IActions['UNROSTER_ALL']>({
		...toolbox.optimistic.begin(optimisticId),
		type: UNROSTER_ALL,
		payload: {
			rosterBossId
		}
	});

	return optimisticId;
}

export function unrosterAll(rosterBossId: number): Thunk<void> {
	return (dispatch) => {
		const optimisticId = dispatchOptimisticUnrosterAllCharacters(
			dispatch,
			rosterBossId
		);

		const payload = {
			bossId: rosterBossId
		};

		return api.call(rpc.ROSTER_BOSS_CLEAR_CHARACTERS, payload).then(
			() => {
				dispatch<IActions['UNROSTER_ALL_SUCCESS']>({
					...toolbox.optimistic.commit(optimisticId),
					type: UNROSTER_ALL_SUCCESS
				});
			},

			(message) => {
				dispatch(BannerDuck.addErrorBanner(message.error));
				dispatch<IActions['UNROSTER_ALL_FAILURE']>({
					...toolbox.optimistic.revert(optimisticId),
					type: UNROSTER_ALL_FAILURE
				});
			}
		);
	};
}

/** Copy assignments from one boss to other bosses */
export function copyAssignmentsToBosses(data: {
	sourceRosterBossId: RosterBossId;
	destinationRosterBossIds: RosterBossId[];
}): Thunk<void> {
	return (dispatchEvent) => {
		dispatchEvent<IActions['COPY_ASSIGNMENTS_TO_BOSSES']>({
			type: COPY_ASSIGNMENTS_TO_BOSSES
		});

		return api
			.call<CopyAssignmentsToBossesInput, CopyAssignmentsToBossesResult>(
				rpc.ROSTER_BOSS_COPY_ASSIGNMENTS_TO_BOSS,
				{
					sourceRosterBossId: data.sourceRosterBossId,
					destinationRosterBossIds: data.destinationRosterBossIds
				}
			)
			.then(
				() => {
					dispatchEvent(
						BannerDuck.addSuccessBanner('Assignments successfully copied')
					);
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

interface IRosterCharacter {
	isActive: boolean;
	characterId: number;
	bossId: number;
	role: string;
}

export function dispatchOptimisticRosterCharacter(
	dispatch: Dispatch<RootAction>,
	data: IRosterCharacter
) {
	const optimisticId = toolbox.optimistic.getId();
	const action: IActions['ROSTER_CHARACTER'] = {
		...toolbox.optimistic.begin(optimisticId),
		type: ROSTER_CHARACTER,
		payload: {
			isActive: data.isActive,
			assignment: {
				characterId: data.characterId,
				bossId: data.bossId,
				role: data.role
			}
		}
	};

	dispatch(action);

	return optimisticId;
}

export function rosterCharacter(data: IRosterCharacter): Thunk<void> {
	return (dispatch) => {
		const optimisticId = dispatchOptimisticRosterCharacter(dispatch, data);

		const payload = {
			isActive: data.isActive,
			characterId: data.characterId,
			id: data.bossId,
			role: data.role
		};

		return api.call(rpc.ROSTER_CHARACTER, payload).then(
			() => {
				const successAction: IActions['ROSTER_CHARACTER_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: ROSTER_CHARACTER_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: IActions['ROSTER_CHARACTER_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: ROSTER_CHARACTER_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

interface IUpdateLimit {
	bossId: number;
	role: string;
	limit: number;
}

export function updateLimit(data: IUpdateLimit): Thunk<void> {
	return (dispatch) => {
		const payload = {
			limit: data.limit,
			role: data.role,
			id: data.bossId
		};

		return api.call(rpc.ROSTER_UPDATE_LIMIT, payload).then(
			() => {
				//
			},

			(message) => {
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function updateNotes(bossId: number, notes: string, cb: () => void): Thunk<void> {
	return (dispatch) => {
		dispatch<IActions['UPDATE_NOTES']>({
			type: UPDATE_NOTES
		});

		const payload = {
			id: bossId,
			notes
		};

		return api.call(rpc.ROSTER_BOSS_NOTES, payload).then(
			() => {
				dispatch<IActions['UPDATE_NOTES_SUCCESS']>({
					type: UPDATE_NOTES_SUCCESS
				});

				cb();
			},

			(message) => {
				dispatch(BannerDuck.addErrorBanner(message.error));
				dispatch<IActions['UPDATE_NOTES_FAILURE']>({
					type: UPDATE_NOTES_FAILURE
				});
			}
		);
	};
}

export function reorder(id: number, order: number): Thunk<void> {
	return (dispatch, getState) => {
		const boss = getForId(getState().rosterBosses, id);
		if (!boss) return Promise.resolve();

		const optimisticId = toolbox.optimistic.getId();
		const action: IActions['REORDER'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: REORDER,
			payload: {
				boss: new RosterBoss({...boss, order})
			}
		};

		dispatch(action);

		return api.call(rpc.ROSTER_BOSS_REORDER, {id, order}).then(
			() => {
				const successAction: IActions['REORDER_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: REORDER_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: IActions['REORDER_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: REORDER_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

// reducer
const initialState: IState = {
	isUpdatingNotes: false,

	bossesById: {},

	assignments: {
		melee: {},
		ranged: {},
		healers: {},
		tanks: {}
	}
};

function insertOrUpdateAssignment(state: IState, a: IRosterRoleAssignmentClass) {
	let update: number[];

	const current = state.assignments[a.role][a.bossId];
	if (!current) update = [a.characterId];
	else if (current.includes(a.characterId)) update = current;
	else update = [...current, a.characterId];

	return {
		...state.assignments,
		[a.role]: {
			...state.assignments[a.role],
			[a.bossId]: update
		}
	};
}

function deleteAssignment(state: IState, a: IRosterRoleAssignmentClass) {
	const current = state.assignments[a.role][a.bossId];
	if (!current || !current.includes(a.characterId)) return state.assignments;

	return {
		...state.assignments,
		[a.role]: {
			...state.assignments[a.role],
			[a.bossId]: current.filter((charId) => charId !== a.characterId)
		}
	};
}

export default function reducer(state = initialState, action: RootAction): IState {
	switch (action.type) {
		// guild payload
		case GuildDuck.FETCH_DATA_SUCCESS:
		case DemoDuck.FETCH_SUCCESS: {
			const bossesById: {[key: number]: RosterBoss} = {};
			action.payload.rosterBosses.forEach((boss) => {
				bossesById[boss.id] = boss;
			});

			const assignments: IAssignments = {
				melee: {},
				ranged: {},
				healers: {},
				tanks: {}
			};

			action.payload.rosterRoleAssignments.forEach((assignment) => {
				// set up the boss if it doesn't exist yet
				if (!assignments[assignment.role][assignment.bossId]) {
					assignments[assignment.role][assignment.bossId] = [];
				}

				assignments[assignment.role][assignment.bossId].push(assignment.characterId);
			});

			return {
				...state,

				// remove data from other guilds
				assignments,
				bossesById
			};
		}

		// roster character
		case ROSTER_CHARACTER: {
			return {
				...state,
				assignments: action.payload.isActive
					? insertOrUpdateAssignment(state, action.payload.assignment)
					: deleteAssignment(state, action.payload.assignment)
			};
		}

		// unroster characters
		case UNROSTER_ALL: {
			const assignments: IAssignments = {
				melee: {},
				ranged: {},
				healers: {},
				tanks: {}
			};

			Object.keys(state.assignments).forEach((role) => {
				// copy all the boss assignments over
				assignments[role] = {...state.assignments[role]};

				// delete assignments for this boss
				delete assignments[role][action.payload.rosterBossId];
			});

			return {
				...state,
				assignments
			};
		}

		// demo optimistic role update
		case DemoDuck.SET_ROLE_LIMIT: {
			const boss = state.bossesById[action.payload.bossId];
			if (!boss) return state;

			const newBoss = new RosterBoss({
				...boss,
				roleLimits: {
					...boss.roleLimits,
					[action.payload.role]: action.payload.limit
				}
			});

			return {
				...state,
				bossesById: {
					...state.bossesById,
					[action.payload.bossId]: newBoss
				}
			};
		}

		// delete options
		case UPDATE_NOTES: {
			return {...state, isUpdatingNotes: true};
		}

		case UPDATE_NOTES_SUCCESS:
		case UPDATE_NOTES_FAILURE: {
			return {...state, isUpdatingNotes: false};
		}

		// reorder
		case REORDER: {
			return {
				...state,

				bossesById: {
					...state.bossesById,
					[action.payload.boss.id]: action.payload.boss
				}
			};
		}

		// feed - bosses
		case feed.ROSTER_BOSS_INSERT:
		case feed.ROSTER_BOSS_UPDATE: {
			return {
				...state,

				bossesById: {
					...state.bossesById,
					[action.payload.newRecord.id]: new RosterBoss(action.payload.newRecord)
				}
			};
		}

		case feed.ROSTER_BOSS_DELETE: {
			const bossesById: IById = {};
			Object.values(state.bossesById).forEach((boss) => {
				if (boss.id === action.payload.oldRecord.id) return;

				bossesById[boss.id] = boss;
			});

			return {
				...state,
				bossesById
			};
		}

		// feed - assignments
		case feed.ROSTER_ROLE_ASSIGNMENT_INSERT: {
			return {
				...state,
				assignments: insertOrUpdateAssignment(state, action.payload.newRecord)
			};
		}

		case feed.ROSTER_ROLE_ASSIGNMENT_DELETE: {
			return {
				...state,
				assignments: deleteAssignment(state, action.payload.oldRecord)
			};
		}

		default:
			return state;
	}
}
