import {Stripe, loadStripe} from '@stripe/stripe-js';

export * from './selectors';
export * from './actions';
export * from './types';

import * as reducer from './reducer';
export default reducer.default;

let stripe: Stripe | null = null;

void loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!, {
	apiVersion: '2020-08-27'
}).then((x) => {
	stripe = x;
});

export function getStripe(): Stripe | null {
	return stripe;
}
