import * as rpc from '../../shared/constants/rpc';
import * as feed from '../constants/feed';

import * as toolbox from '../helpers/toolbox';
import {demoAPI} from '../helpers/api';

import * as RosterBossDuck from './roster-boss';
import * as LootDuck from './loot';
import * as BannerDuck from './banner';
import type {RootAction} from '.';

import {Character} from '../models/character';
import {LootSelection} from '../models/loot-selection';
import {LootOption} from '../models/loot-option';
import {LootCoin} from '../models/loot-coin';
import {RosterRoleAssignment} from '../models/roster-role-assignment';
import {RosterBoss} from '../models/roster-boss';
import {Roster} from '../models/roster';
import {Guild} from '../models/guild';
import {DroptimizerReport} from '@models/droptimizerReport';

export interface IState {
	readonly isLoading: boolean;
}

// types
const FETCH = 'demo/FETCH';
export const FETCH_SUCCESS = 'demo/FETCH_SUCCESS';
const FETCH_FAILURE = 'demo/FETCH_FAILURE';

export const SET_ROLE_LIMIT = 'demo/SET_ROLE_LIMIT';

export interface IActions {
	FETCH: {readonly type: typeof FETCH};
	FETCH_FAILURE: {readonly type: typeof FETCH_FAILURE};
	FETCH_SUCCESS: {
		readonly type: typeof FETCH_SUCCESS;
		readonly payload: {
			characters: Character[];
			guild: Guild;

			lootSelections: LootSelection[];
			lootOptions: LootOption[];
			lootCoins: LootCoin[];

			rosterRoleAssignments: RosterRoleAssignment[];
			rosterBosses: RosterBoss[];
			rosters: Roster[];

			droptimizerReports: DroptimizerReport[];
		};
	};

	SET_ROLE_LIMIT: {
		readonly type: typeof SET_ROLE_LIMIT;
		readonly payload: {
			bossId: number;
			role: string;
			limit: number;
		};
	};
}

// selectors
export function getIsLoading(state: IState) {
	return state.isLoading;
}

// actions
export function fetch(): Thunk<void> {
	return (dispatch) => {
		const action: IActions['FETCH'] = {type: FETCH};
		dispatch(action);

		return demoAPI.call(rpc.DEMO_DATA_FETCH, {}).then(
			(message) => {
				const successAction: IActions['FETCH_SUCCESS'] = {
					type: FETCH_SUCCESS,
					payload: {
						characters: message.data.characters.map((r: any) => new Character(r)),
						guild: new Guild(message.data.guild),

						lootSelections: message.data.lootSelections.map(
							(r: any) => new LootSelection(r)
						),
						lootOptions: message.data.lootOptions.map(
							(r: any) => new LootOption(r)
						),
						lootCoins: message.data.lootCoins.map((r: any) => new LootCoin(r)),

						rosterBosses: message.data.rosterBosses.map(
							(r: any) => new RosterBoss(r)
						),
						rosters: message.data.rosters.map((r: any) => new Roster(r)),
						rosterRoleAssignments: message.data.rosterRoleAssignments.map(
							(r: any) => new RosterRoleAssignment(r)
						),

						droptimizerReports: message.data.droptimizerReports.map(
							(x: any) => new DroptimizerReport(x)
						)
					}
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: IActions['FETCH_FAILURE'] = {
					type: FETCH_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export const makeSelection: typeof LootDuck.makeSelection = function demoMakeSelection(
	data
) {
	return (dispatch, getState) => {
		const optimisticId = LootDuck.dispatchOptimisticLootSelection(
			dispatch,
			getState(),
			data
		);

		const successAction: LootDuck.IActions['SELECTION_SUCCESS'] = {
			...toolbox.optimistic.commit(optimisticId),
			type: LootDuck.SELECTION_SUCCESS
		};

		dispatch(successAction);
		return Promise.resolve();
	};
};

export const updateNote: typeof LootDuck['updateNote'] = function demoUpdateNote(data) {
	return (dispatch, getState) => {
		const optimisticId = LootDuck.dispatchOptimisticLootNote(
			dispatch,
			getState(),
			data
		);

		if (!optimisticId) return Promise.resolve();

		dispatch<LootDuck.IActions['SELECTION_NOTE_SUCCESS']>({
			...toolbox.optimistic.commit(optimisticId),
			type: LootDuck.SELECTION_NOTE_SUCCESS
		});
		return Promise.resolve();
	};
};

export const makeCoin: typeof LootDuck.makeCoin = function demoMakeCoin(data) {
	return (dispatch, getState) => {
		const optimisticId = LootDuck.dispatchOptimisticLootCoin(
			dispatch,
			getState(),
			data
		);

		const successAction: LootDuck.IActions['COIN_SUCCESS'] = {
			...toolbox.optimistic.commit(optimisticId),
			type: LootDuck.COIN_SUCCESS
		};

		dispatch(successAction);
		return Promise.resolve();
	};
};

export const updateNotes: typeof RosterBossDuck.updateNotes = function demoUpdateNotes(
	bossId,
	notes,
	cb
) {
	return (dispatch, getState) => {
		const state = getState();
		const boss = RosterBossDuck.getForId(state.rosterBosses, bossId);
		if (!boss) return Promise.resolve();

		dispatch<RosterBossDuck.IActions['UPDATE_NOTES']>({
			type: RosterBossDuck.UPDATE_NOTES
		});

		dispatch<RosterBossDuck.IActions['UPDATE_NOTES_SUCCESS']>({
			type: RosterBossDuck.UPDATE_NOTES_SUCCESS
		});

		cb();

		dispatch<feed.IActions['ROSTER_BOSS_UPDATE']>({
			type: feed.ROSTER_BOSS_UPDATE,
			payload: {
				oldRecord: boss,
				newRecord: {
					...boss,
					notes
				}
			}
		});

		return Promise.resolve();
	};
};

export const unrosterAll: typeof RosterBossDuck.unrosterAll = function demoUnrosterAll(
	rosterBossId
) {
	return (dispatch) => {
		const optimisticId = RosterBossDuck.dispatchOptimisticUnrosterAllCharacters(
			dispatch,
			rosterBossId
		);

		dispatch<RosterBossDuck.IActions['UNROSTER_ALL_SUCCESS']>({
			...toolbox.optimistic.commit(optimisticId),
			type: RosterBossDuck.UNROSTER_ALL_SUCCESS
		});

		return Promise.resolve();
	};
};

export const rosterCharacter: typeof RosterBossDuck.rosterCharacter =
	function demoRosterCharacter(data) {
		return (dispatch) => {
			const optimisticId = RosterBossDuck.dispatchOptimisticRosterCharacter(
				dispatch,
				data
			);

			const successAction: RosterBossDuck.IActions['ROSTER_CHARACTER_SUCCESS'] = {
				...toolbox.optimistic.commit(optimisticId),
				type: RosterBossDuck.ROSTER_CHARACTER_SUCCESS
			};

			dispatch(successAction);
			return Promise.resolve();
		};
	};

export const updateLimit: typeof RosterBossDuck.updateLimit = function demoUpdateLimit(
	data
) {
	return (dispatch) => {
		const action: IActions['SET_ROLE_LIMIT'] = {
			type: SET_ROLE_LIMIT,
			payload: {
				bossId: data.bossId,
				role: data.role,
				limit: data.limit
			}
		};

		dispatch(action);
		return Promise.resolve();
	};
};

export const bumpUpdated: typeof LootDuck.bumpUpdated = function demoBumpUpdated() {
	return (dispatch) => {
		dispatch(BannerDuck.addSuccessBanner('Loot marked as current'));
		return Promise.resolve();
	};
};

// reducer
const initialState: IState = {
	isLoading: false
};

export default function reducer(state = initialState, action: RootAction): IState {
	switch (action.type) {
		// fetch guild data
		case FETCH: {
			return {
				...state,
				isLoading: true
			};
		}

		case FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false
			};
		}

		case FETCH_FAILURE: {
			return {
				...state,
				isLoading: false
			};
		}

		default:
			return state;
	}
}
