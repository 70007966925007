import type {CooldownSheetId} from './cooldownSheet';

export interface IRosterBossClass {
	id: number;
	rosterId: number;
	guildId: number;
	cooldownSheetId: CooldownSheetId | null;

	isArchived: boolean;
	isDeleted: boolean;

	// createdAt: number
	// updatedAt: number

	notes: string | null;

	bossId: WowBossId;
	order: number;

	roleLimits: {
		melee: number;
		ranged: number;
		healers: number;
		tanks: number;
	};

	roleUpdatedAt: {
		melee: number;
		ranged: number;
		healers: number;
		tanks: number;
	};
}

export class RosterBoss implements IRosterBossClass {
	readonly id: number;
	readonly rosterId: number;
	readonly guildId: number;
	readonly cooldownSheetId: CooldownSheetId | null;

	readonly isArchived: boolean;
	readonly isDeleted: boolean;

	// readonly createdAt: number;
	// readonly updatedAt: number;

	readonly notes: string | null;

	/** The WoW ID of the boss */
	readonly bossId: WowBossId;
	readonly order: number;

	readonly roleLimits: {
		readonly melee: number;
		readonly ranged: number;
		readonly healers: number;
		readonly tanks: number;
	};

	readonly roleUpdatedAt: {
		readonly melee: number;
		readonly ranged: number;
		readonly healers: number;
		readonly tanks: number;
	};

	constructor(data?: IRosterBossClass) {
		if (!data) throw new Error('invalid roster-boss data');

		this.id = data.id;
		this.rosterId = data.rosterId;
		this.guildId = data.guildId;
		this.cooldownSheetId = data.cooldownSheetId;

		this.isArchived = data.isArchived;
		this.isDeleted = data.isDeleted;

		// this.createdAt = data.createdAt;
		// this.updatedAt = data.updatedAt;

		this.notes = data.notes;

		this.bossId = data.bossId;
		this.order = Number(data.order);

		this.roleLimits = data.roleLimits;
		this.roleUpdatedAt = data.roleUpdatedAt;
	}
}
