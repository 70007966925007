import type {Regions} from '@constants/wow';
import {BossViewType} from '@constants';
import type {FeatureChecker, Features} from '@shared/billing/features';

import {lootUrl, rostersUrl, allViewsDisabled} from '@helpers/urls';

import type {Character} from './character';
import type {User} from './user';

interface IGuildData {
	// bnetLastModified: string;

	faction: string;
	region: Regions;
	name: string;
	realm: string;
	// level: number;
	// achievementPoints: number;
}

export interface IGuildClass extends IGuildData {
	id: number;
	importerId: number;

	isLootSheetAdminOnly: boolean;
	readonly isRosterAssignmentsAdminOnly: boolean;
	readonly isRosterNotesAdminOnly: boolean;
	readonly isRosterCooldownsAdminOnly: boolean;
	isImporterAdmin: boolean;

	/** Doesn't include the External rank */
	readonly normalRankNames: string[];
	/** Includes the External rank */
	readonly fullRankNames: string[];

	// rankNames: string[];
	adminRanks: boolean[];
	autoInviteRanks: boolean[];

	readonly adminUserIds: number[];

	/** Extra features this Guild has access to */
	readonly additionalAccessFeatures: Features[];
}

export class Guild implements IGuildClass {
	readonly id: number;
	readonly importerId: number;

	/** Doesn't include the External rank */
	readonly normalRankNames: string[];
	/** Includes the External rank */
	readonly fullRankNames: string[];

	readonly adminRanks: boolean[];
	readonly autoInviteRanks: boolean[];

	/**
	 * User IDs of specific people who have been granted admin access to the guild, this
	 * is in addition to the people who are admins via rank-level permissions.
	 */
	readonly adminUserIds: number[];

	readonly isLootSheetAdminOnly: boolean;
	readonly isRosterAssignmentsAdminOnly: boolean;
	readonly isRosterNotesAdminOnly: boolean;
	readonly isRosterCooldownsAdminOnly: boolean;
	readonly isImporterAdmin: boolean;

	// readonly bnetLastModified: string;

	readonly faction: string;
	readonly region: Regions;
	readonly name: string;
	readonly realm: string;
	// readonly level: number;
	// readonly achievementPoints: number;

	/** Extra features this Guild has access to */
	readonly additionalAccessFeatures: Features[];

	constructor(data?: IGuildClass) {
		if (!data) throw new Error('invalid guild data');

		this.id = data.id;
		this.importerId = data.importerId;

		// this.bnetLastModified = data.bnetLastModified;

		this.normalRankNames = data.normalRankNames;
		this.fullRankNames = data.fullRankNames;

		this.adminRanks = data.adminRanks;
		this.autoInviteRanks = data.autoInviteRanks;

		this.adminUserIds = data.adminUserIds;

		this.isLootSheetAdminOnly = data.isLootSheetAdminOnly;
		this.isRosterAssignmentsAdminOnly = data.isRosterAssignmentsAdminOnly;
		this.isRosterNotesAdminOnly = data.isRosterNotesAdminOnly;
		this.isRosterCooldownsAdminOnly = data.isRosterCooldownsAdminOnly;
		this.isImporterAdmin = data.isImporterAdmin;

		this.faction = data.faction;
		this.region = data.region;
		this.name = data.name;
		this.realm = data.realm;
		// this.level = data.level;
		// this.achievementPoints = data.achievementPoints;

		this.additionalAccessFeatures = data.additionalAccessFeatures;
	}

	isDemo() {
		return this.id === 0;
	}

	isUserAdmin(user: User, characters: Character[]) {
		// user may be granted explicit admin permission
		if (this.adminUserIds.includes(user.id)) return true;

		// user may be admin via being the importer
		if (this.isImporterAdmin && this.importerId === user.id) return true;

		// check if any of the character's ranks are an admin rank
		const keys = user.createComboKeys();

		return characters.some((char) => {
			// missing characters block admin ability
			if (char.isMissing) return false;

			// ensure the user owns the character
			if (!keys.includes(char.combo)) return false;

			if (this.adminRanks[char.rank]) return true;

			return false;
		});
	}

	/** Whether the whole set of roster features are only available to admins */
	isWholeRosterAdminOnly(): boolean {
		return (
			this.isRosterAssignmentsAdminOnly &&
			this.isRosterNotesAdminOnly &&
			this.isRosterCooldownsAdminOnly
		);
	}

	getRosterViewInfo(data: {featureChecker: FeatureChecker; isAdmin: boolean}): {
		canUseAssignmentsView: boolean;
		canUseNotesView: boolean;
		canUseCooldownView: boolean;

		initialBossView: BossViewType;
	} {
		const canUseAssignmentsView = data.isAdmin || !this.isRosterAssignmentsAdminOnly;
		const canUseNotesView = data.isAdmin || !this.isRosterNotesAdminOnly;
		const canUseCooldownView =
			!this.isDemo() && (data.isAdmin || !this.isRosterCooldownsAdminOnly);

		let viewType: BossViewType = BossViewType.ROSTER;
		if (canUseAssignmentsView) viewType = BossViewType.ROSTER;
		else if (canUseCooldownView) viewType = BossViewType.COOLDOWN_SHEET;
		else if (canUseNotesView) viewType = BossViewType.NOTES;

		// if (process.env.NODE_ENV === 'development') viewType = BossViewType.COOLDOWN_SHEET;

		return {
			canUseAssignmentsView,
			canUseCooldownView,
			canUseNotesView,

			initialBossView: viewType
		};
	}

	getDisabledViewInfo(
		view: 'lootsheet' | 'roster' | 'roster-list'
	):
		| {isDisabled: false; redirectUrl: undefined}
		| {isDisabled: true; redirectUrl: string} {
		// if we're on lootsheet and lootsheet is allowed
		if (view === 'lootsheet' && !this.isLootSheetAdminOnly) {
			return {
				isDisabled: false,
				redirectUrl: undefined
			};
		}

		// if we're on roster-list and roster is allowed
		if (view === 'roster-list' && !this.isWholeRosterAdminOnly()) {
			return {
				isDisabled: false,
				redirectUrl: undefined
			};
		}

		// if we're on roster and roster is allowed
		if (view === 'roster' && !this.isWholeRosterAdminOnly()) {
			return {
				isDisabled: false,
				redirectUrl: undefined
			};
		}

		// redirect to lootsheet is allowed
		if (!this.isLootSheetAdminOnly) {
			return {
				isDisabled: true,
				redirectUrl: lootUrl(this.id)
			};
		}

		// redirect to roster if allowed
		if (!this.isWholeRosterAdminOnly()) {
			return {
				isDisabled: true,
				redirectUrl: rostersUrl(this.id)
			};
		}

		// they can't see anything
		return {
			isDisabled: true,
			redirectUrl: allViewsDisabled(this.id)
		};
	}

	static createBaseUrl(id: number | string) {
		if (id === 0) return '/demo';

		return `/guild/${id}`;
	}
}
