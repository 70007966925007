import React from 'react';

import {BossViewType} from '@constants';

import {connect, GuildDuck, GuildSponsorshipDuck} from '@ducks';

import {SelectInput} from '@components/Utils/SelectInput';

interface OwnProps {
	onChangeView(view: BossViewType): void;

	isAdmin: boolean;

	viewType: BossViewType;
}

interface MapProps {
	canUseAssignmentsView: boolean;
	canUseNotesView: boolean;
	canUseCooldownView: boolean;
}

type Props = OwnProps & MapProps;

const ViewSwitcherComp: React.FC<Props> = (props) => {
	return (
		<SelectInput
			onChange={props.onChangeView}
			className="view-selector"
			selectedId={props.viewType}
			items={[
				{
					isHidden: !props.canUseAssignmentsView,
					id: BossViewType.ROSTER,
					name: 'Roster View'
				},

				{
					isHidden: !props.canUseCooldownView,
					id: BossViewType.COOLDOWN_SHEET,
					name: 'Cooldown View'
				},

				{
					isHidden: !props.canUseNotesView,
					id: BossViewType.NOTES,
					name: 'Notes View'
				}
			]}
		/>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const guild = GuildDuck.getActiveGuildNew(state.guilds);

	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		guild.id
	);

	const rosterViewInfo = guild.getRosterViewInfo({
		featureChecker: fc,
		isAdmin: props.isAdmin
	});

	return {
		canUseAssignmentsView: rosterViewInfo.canUseAssignmentsView,
		canUseNotesView: rosterViewInfo.canUseNotesView,
		canUseCooldownView: rosterViewInfo.canUseCooldownView
	};
}

export const ViewSwitcher = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(ViewSwitcherComp);
