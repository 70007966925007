import React from 'react';

import {AuthErrors} from '../../constants';
import {REGIONS, Regions} from '@constants/wow';

import * as UserDuck from '../../ducks/user';

import {Button, LinkButton, ButtonGroup} from '../Utils/Button';
import {LoadingCharacters} from './LoadingCharacters';
import {SharingDisabled} from './SharingDisabled';
import {InternalError} from './InternalError';
import {MissingScope} from './MissingScope';
import {SyncIssues} from './SyncIssues';
import {RegionPicker} from './RegionPicker';
import Support from '../Utils/Support';

interface IProps {
	location: {
		query: {
			[AuthErrors.ERROR_SYNCING_CHARACTERS_SHARING]?: string;
			[AuthErrors.ERROR_SYNCING_CHARACTERS]?: string;
			[AuthErrors.MISSING_SCOPE]?: string;

			btag?: string;

			'signed-out': string;
			internal: string;
			down: string;
		};
	};
}

interface IState {
	isSelectingRegion: boolean;
	loadingRegion: Regions | null;
}

export default class SignIn extends React.Component<IProps, IState> {
	state: IState = {
		isSelectingRegion: false,
		loadingRegion: null
	};

	handleConnect = () => {
		this.setState({isSelectingRegion: true});
	};

	handleRegionSelect = (region: Regions) => {
		this.setState(
			{
				isSelectingRegion: false,
				loadingRegion: region
			},
			() => {
				UserDuck.signIn(region);
			}
		);
	};

	handleRegionClose = () => {
		this.setState({isSelectingRegion: false});
	};

	render() {
		if (this.state.loadingRegion) {
			return <LoadingCharacters region={this.state.loadingRegion} />;
		}

		if (this.props.location.query.internal) {
			return (
				<InternalError
					onRegionSelect={this.handleRegionSelect}
					onRegionClose={this.handleRegionClose}
					onConnect={this.handleConnect}
					isSelectingRegion={this.state.isSelectingRegion}
					isBnetDown={!!this.props.location.query.down}
				/>
			);
		}

		const {query} = this.props.location;
		if (query[AuthErrors.MISSING_SCOPE]) {
			return <MissingScope onRegionSelect={this.handleRegionSelect} />;
		}
		if (query[AuthErrors.ERROR_SYNCING_CHARACTERS_SHARING]) {
			return (
				<SharingDisabled
					onRegionSelect={this.handleRegionSelect}
					onRegionClose={this.handleRegionClose}
					onConnect={this.handleConnect}
					isSelectingRegion={this.state.isSelectingRegion}
					btag={query.btag}
				/>
			);
		}
		if (query[AuthErrors.ERROR_SYNCING_CHARACTERS]) {
			return (
				<SyncIssues
					onRegionSelect={this.handleRegionSelect}
					onRegionClose={this.handleRegionClose}
					onConnect={this.handleConnect}
					isSelectingRegion={this.state.isSelectingRegion}
					btag={query.btag}
				/>
			);
		}

		return (
			<div className="page-container">
				<Support />

				{this.state.isSelectingRegion && (
					<RegionPicker
						onClose={this.handleRegionClose}
						onClick={this.handleRegionSelect}
						isCacheBust={false}
					/>
				)}

				<div className="auth-page sign-in">
					<div className="auth-content card">
						<div className="heading">Connect Blizzard Account</div>

						<div className="content">
							<div className="description">
								{query['signed-out'] && (
									<p className="highlight">
										If you just signed out and wish to connect a different
										Blizzard account, you must sign out of Battle.net in this
										browser to force it to prompt you for sign-in.
									</p>
								)}

								<p>
									<span>In order to use </span>
									<span className="brand-name">Readycheck</span>
									<span> you need to connect your Blizzard account.</span>
								</p>

								<p>
									<span>
										Granting us access to view your characters in World of
										Warcraft will allow us to determine which guilds you're a
										member of and give you access to the rosters for those
										guilds within{' '}
									</span>
									<span className="brand-name">Readycheck</span>.
								</p>

								<p>
									More information about Blizzard authentication can be found on
									the{' '}
									<a href="https://develop.battle.net/documentation/guides/using-oauth">
										Battle.net dev website
									</a>
									.
								</p>
							</div>

							<ButtonGroup alignment="vertical">
								<Button
									onClick={() => this.handleRegionSelect(Regions.US)}
									className="large primary"
								>
									{REGIONS[Regions.US]}
								</Button>

								<Button
									onClick={() => this.handleRegionSelect(Regions.EU)}
									className="large primary"
								>
									{REGIONS[Regions.EU]}
								</Button>

								<LinkButton to="/demo" className="large grey">
									Try Demo
								</LinkButton>
							</ButtonGroup>
						</div>
					</div>

					<div className="footer">
						<div className="disclaimer">
							All trademarks and copyrights on this site are owned by their
							respective owners.
						</div>

						<div className="terms">
							<span>By using this service you agree to our </span>
							<a
								href="https://readycheck.io/terms"
								target="_blank"
								rel="noreferrer noopener"
							>
								Terms of Use
							</a>
							<span> and </span>
							<a
								href="https://readycheck.io/privacy"
								target="_blank"
								rel="noreferrer noopener"
							>
								Privacy Policy
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
