import * as Feed from '@constants/feed';

import {RootAction, CooldownActionDuck, CooldownSheetDuck} from '@ducks';

import {CooldownAction} from '@models/cooldownAction';

const initialState: CooldownActionDuck.State = {
	actionById: {}
};

export default function reducer(
	state = initialState,
	action: RootAction
): CooldownActionDuck.State {
	switch (action.type) {
		// load events
		case CooldownSheetDuck.FETCH_FOR_BOSS_SUCCESS: {
			const newEntries = action.payload.cooldownActions.map((cooldownAction) => [
				cooldownAction.id,
				cooldownAction
			]);

			return {
				...state,

				actionById: {
					...state.actionById,
					...Object.fromEntries(newEntries)
				}
			};
		}

		// update action actor/ability
		case CooldownActionDuck.UPDATE_ACTOR:
		case CooldownActionDuck.UPDATE_ABILITY: {
			return {
				...state,

				actionById: {
					...state.actionById,
					[action.payload.cooldownAction.id]: action.payload.cooldownAction
				}
			};
		}

		// delete
		case CooldownActionDuck.DELETE: {
			const {[action.payload.cooldownActionId]: deleted, ...rest} = state.actionById;

			return {
				...state,
				actionById: rest
			};
		}

		// feed
		case Feed.COOLDOWN_ACTION_INSERT:
		case Feed.COOLDOWN_ACTION_UPDATE: {
			return {
				...state,

				actionById: {
					...state.actionById,
					[action.payload.newRecord.id]: new CooldownAction(action.payload.newRecord)
				}
			};
		}

		case Feed.COOLDOWN_ACTION_DELETE: {
			const {[action.payload.oldRecord.id]: deleted, ...rest} = state.actionById;

			return {
				...state,
				actionById: rest
			};
		}

		default:
			return state;
	}
}
