import React from 'react';

import * as BillingLink from '../Utils/BillingLink';

import ViewLootImage from '../../images/view-loot.png';

export function UpgradeView() {
	return (
		<div className="billing-required-prompt">
			<img className="billing-icon" src={ViewLootImage} />

			<div className="billing-description">
				No need to rely on raiders updating their own loot sheets - you can upload
				data from the RCLootCouncil addon and let Readycheck make the changes for you.
			</div>

			{BillingLink.createRclcImportLink('Get early access', 'large primary button')}
		</div>
	);
}
