import React from 'react';
import {connect} from 'react-redux';
import {Link, browserHistory as router} from 'react-router';
import Mousetrap from 'mousetrap';

import * as UserDuck from '../../ducks/user';

import Banner from '../Utils/Banner';

function makeLink(name = '', endpoint = '') {
	return (
		<Link to={`/user-settings/${endpoint}`} activeClassName="active">
			{name}
		</Link>
	);
}

interface IProps {
	redirectUrl: string;
}

class UserSettings extends React.Component<IProps> {
	mousetrap: MousetrapInstance;

	componentDidMount() {
		this.mousetrap = new Mousetrap(window.document.body);
		this.mousetrap.bind('esc', this.handleClose);
	}

	componentWillUnmount() {
		this.mousetrap.reset();
	}

	handleClose = () => {
		router.push(this.props.redirectUrl);
	};

	render() {
		return (
			<div className="settings-page user-settings">
				<Banner />

				<div className="panel">
					<div className="panel-content">
						<div className="group">{makeLink('My Account', 'my-account')}</div>
					</div>
				</div>

				<div className="content-wrapper">
					{this.props.children}

					<div className="close-button">
						<i onClick={this.handleClose} className="lnr lnr-cross-circle" />

						<div className="button-label">ESC</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: IRootState) {
	return {
		redirectUrl: UserDuck.getUrl(state.user)
	};
}

export default connect(mapStateToProps)(UserSettings);
