import * as Feed from '@constants/feed';

import {RootAction, CooldownEventDuck, CooldownSheetDuck} from '@ducks';

import {CooldownEvent} from '@models/cooldownEvent';

const initialState: CooldownEventDuck.State = {
	eventById: {},

	isUpdatingNameById: {},
	isUpdatingTimeById: {},
	isCreatingEvent: false
};

export default function reducer(
	state = initialState,
	action: RootAction
): CooldownEventDuck.State {
	switch (action.type) {
		// load events
		case CooldownSheetDuck.FETCH_FOR_BOSS_SUCCESS: {
			const newEntries = action.payload.cooldownEvents.map((event) => [
				event.id,
				event
			]);

			return {
				...state,

				eventById: {
					...state.eventById,
					...Object.fromEntries(newEntries)
				}
			};
		}

		// create new event
		case CooldownEventDuck.CREATE: {
			return {...state, isCreatingEvent: true};
		}
		case CooldownEventDuck.CREATE_SUCCESS:
		case CooldownEventDuck.CREATE_FAILURE: {
			return {...state, isCreatingEvent: false};
		}

		// update name event
		case CooldownEventDuck.UPDATE_NAME: {
			return {
				...state,

				isUpdatingNameById: {
					...state.isUpdatingNameById,
					[action.payload.cooldownEvent.id]: true
				},

				eventById: {
					...state.eventById,
					[action.payload.cooldownEvent.id]: action.payload.cooldownEvent
				}
			};
		}
		case CooldownEventDuck.UPDATE_NAME_SUCCESS:
		case CooldownEventDuck.UPDATE_NAME_FAILURE: {
			const {[action.payload.cooldownEventId]: deleted, ...rest} =
				state.isUpdatingNameById;

			return {
				...state,
				isUpdatingNameById: rest
			};
		}

		// update time event
		case CooldownEventDuck.UPDATE_TIME: {
			return {
				...state,

				isUpdatingTimeById: {
					...state.isUpdatingTimeById,
					[action.payload.cooldownEvent.id]: true
				},

				eventById: {
					...state.eventById,
					[action.payload.cooldownEvent.id]: action.payload.cooldownEvent
				}
			};
		}
		case CooldownEventDuck.UPDATE_TIME_SUCCESS:
		case CooldownEventDuck.UPDATE_TIME_FAILURE: {
			const {[action.payload.cooldownEventId]: deleted, ...rest} =
				state.isUpdatingTimeById;

			return {
				...state,
				isUpdatingTimeById: rest
			};
		}

		// delete
		case CooldownEventDuck.DELETE: {
			const {[action.payload.cooldownEventId]: deleted, ...rest} = state.eventById;

			return {
				...state,
				eventById: rest
			};
		}

		// basic optimistic updates
		case CooldownEventDuck.REORDER: {
			return {
				...state,

				eventById: {
					...state.eventById,
					[action.payload.cooldownEvent.id]: action.payload.cooldownEvent
				}
			};
		}

		// feed
		case Feed.COOLDOWN_EVENT_INSERT:
		case Feed.COOLDOWN_EVENT_UPDATE: {
			return {
				...state,

				eventById: {
					...state.eventById,
					[action.payload.newRecord.id]: new CooldownEvent(action.payload.newRecord)
				}
			};
		}

		case Feed.COOLDOWN_EVENT_DELETE: {
			const {[action.payload.oldRecord.id]: deleted, ...rest} = state.eventById;

			return {
				...state,
				eventById: rest
			};
		}

		default:
			return state;
	}
}
