import React from 'react';
import {Link} from 'react-router';

export default function NotMember() {
	return (
		<div className="page-container">
			<div className="content-card card">
				<div className="heading">
					<i className="red lnr lnr-cross-circle" />

					<div>You aren't a member of that guild</div>
				</div>

				<div className="content">
					<div className="description">
						<p>
							If you actually do have a character in this guild please try
							re-syncing your account. Otherwise you should select a guild from the
							guild selection page.
						</p>

						<p>
							<span>If the problem persists please head over to the </span>
							<a
								href="https://readycheck.io/contact"
								target="_blank"
								rel="noreferrer noopener"
								className="styled-link"
							>
								contact page
							</a>
							<span> and reach out to us and we'll see what we can do.</span>
						</p>
					</div>

					<div className="button-group">
						<Link to="/select-guild" className="large primary faded button">
							Select guild
						</Link>

						<Link to="/connect" className="large primary faded button">
							Re-sync
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
