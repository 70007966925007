import React from 'react';
import classnames from 'classnames';
import Headroom from 'react-headroom';
import {Link} from 'react-router';

import {baseUrl} from '@helpers/urls';

import type * as UserDuck from '../../ducks/user';

import type {Guild} from '../../models/guild';
import type {User} from '../../models/user';

import brand from '../../images/brand.png';

import {UserNav} from './UserNav';
import Banner from '../Utils/Banner';

// function getUrl() {
// 	return window.location.href.slice(window.location.origin.length);
// }

interface IProps {
	onEnterSettings: typeof UserDuck.setUrl;

	isHeaderFixed: boolean;

	guild?: Guild;
	user?: User;

	children?: React.ReactNode;
}

export default function Header(props: IProps) {
	const headerClass = classnames(
		{
			'with-sub-nav': !!props.children
		},
		'header-nav'
	);

	return (
		<Headroom disable={props.isHeaderFixed} disableInlineStyles={true}>
			<div className={headerClass}>
				<Link to={baseUrl()} className="brand">
					<img src={brand} />

					<span className="name">Readycheck</span>
				</Link>

				{props.guild && <div className="guild-name">{props.guild.name}</div>}

				<div className="links">
					{/* {props.user && (
						<a
							href="https://announcekit.app/readycheck/announcements"
							className="link announcekit-widget"
							target="_blank"
							rel="noreferrer noopener"
						>
							What's New
						</a>
					)} */}

					{props.user && (
						<Link to="/select-guild" className="link" activeClassName="active">
							Your Guilds
						</Link>
					)}

					{props.user && <UserNav user={props.user} />}

					{!props.user && (
						<Link to="/" className="link" activeClassName="active">
							Get Started
						</Link>
					)}
				</div>
			</div>

			{props.children && <div className="header-sub-nav">{props.children}</div>}

			<Banner />
		</Headroom>
	);
}

// <Link
// 	onClick={() => props.onEnterSettings(getUrl())}
// 	to="/user-settings"
// 	className="user-settings-link tooltip tooltip-bottom-left"
// 	data-hint="User Settings"
// >
// 	<i className="lnr lnr-cog" />
// </Link>
