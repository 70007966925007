import React from 'react';
import classnames from 'classnames';

import * as wow from '../../../constants/wow';

import type {IEncounterMode} from '../../../models/boss-kills';
import type {Character} from '../../../models/character';

import type {ICharMetaMap} from '../RosterContainer';
import {CharacterLoot} from './CharacterLoot';
import {AssignmentIcon} from './AssignmentIcon';
import {BossKillMeta} from './BossKillMeta';
import {MetaIcons} from './MetaIcons';

interface IProps {
	onToggle(): void;

	isShowingKillCount: boolean;
	isAssigned: boolean;
	characterMeta: ICharMetaMap[keyof ICharMetaMap] | undefined;
	character: Character;
	wowheadBonus: string;
	wowBossId: WowBossId;

	bossKillInfo: IEncounterMode | undefined;

	otherAssignedRole: wow.Role | undefined;
	otherAssignedAltCharacters: Array<{
		role: wow.Role;
		character: Character;
	}>;
}

interface IState {
	isExpanded: boolean;
}

export class CharacterRow extends React.Component<IProps, IState> {
	state: IState = {isExpanded: false};

	render() {
		const characterClass = classnames(
			{
				missing: this.props.character.isMissing,
				'other-role-assignment': !!this.props.otherAssignedRole,
				'other-alt-assignment': !!this.props.otherAssignedAltCharacters.length,
				active: this.props.isAssigned
			},
			this.props.character.class,
			'character',
			'wow-style bg-before'
		);

		const meta =
			(!this.props.character.isMissing && this.props.characterMeta) || undefined;
		const items = meta ? meta.itemsByWowBossId[this.props.wowBossId] : [];

		const itemCount = meta ? items.length : 4;
		const bossCount = meta ? meta.assignedBossCount : 5;
		const isCoining = meta
			? meta.coiningWowBossIds.includes(this.props.wowBossId)
			: false;
		const isLootsheetOutdated = meta ? meta.isLootsheetOutdated : false;

		const expandClass = classnames(
			{
				'lnr-chevron-down': !this.state.isExpanded,
				'lnr-chevron-up': this.state.isExpanded
			},
			'lnr'
		);

		const wrapperClass = classnames(
			{
				expanded: this.state.isExpanded
			},
			'character-wrapper'
		);

		return (
			<div className={wrapperClass}>
				<AssignmentIcon isAssigned={this.props.isAssigned} />

				<div className="character-middle-col">
					<div onClick={this.props.onToggle} className={characterClass}>
						<div className="left">
							<img
								src={this.props.character.getAvatarUrl()}
								className="thumbnail"
							/>

							<div className="details">
								<div className="name">{this.props.character.name}</div>

								<div className="secondary">
									{wow.CLASS_TO_DISPLAY[this.props.character.class]}
								</div>
							</div>
						</div>

						{!this.props.character.isMissing && (
							<div className="meta-container">
								<div className="meta">
									<div
										className="meta-item tooltip tooltip-top tooltip-medium delayed"
										data-hint="Number of bosses this character is in for"
									>
										{bossCount
											? `${bossCount} ${bossCount === 1 ? 'boss' : 'bosses'}`
											: '-'}
									</div>

									{this.props.isShowingKillCount && (
										<BossKillMeta
											characterName={this.props.character.name}
											killCount={this.props.bossKillInfo?.count}
											lastKillAt={this.props.bossKillInfo?.lastKillAt}
										/>
									)}

									<div
										className="meta-item tooltip tooltip-top tooltip-medium delayed"
										data-hint="Number of items this character wants from this boss"
									>
										{itemCount
											? `${itemCount} ${itemCount === 1 ? 'item' : 'items'}`
											: '-'}
									</div>

									<MetaIcons
										characterName={this.props.character.name}
										isCoining={isCoining}
										isLootsheetOutdated={isLootsheetOutdated}
										lootsheetAgeInDays={meta?.lootsheetAgeInDays || 0}
										otherAssignedRole={this.props.otherAssignedRole}
										otherAssignedAltCharacters={
											this.props.otherAssignedAltCharacters
										}
									/>
								</div>
							</div>
						)}

						{this.props.character.isMissing && (
							<div className="missing-character">
								{this.props.isAssigned
									? 'Readycheck is unable to sync this character, but it is assigned to this boss'
									: 'Readycheck is unable to sync this character'}
							</div>
						)}
					</div>

					{this.state.isExpanded && !!items.length && (
						<CharacterLoot
							wowheadBonus={this.props.wowheadBonus}
							selections={items}
						/>
					)}
				</div>

				<div className="expand-icon-wrapper">
					{!!items.length && (
						<i
							onClick={() => this.setState({isExpanded: !this.state.isExpanded})}
							className={expandClass}
						/>
					)}
				</div>
			</div>
		);
	}
}
