import type {Role} from '@constants/wow';

import type {Character} from '@models/character';

interface Assignment {
	role: Role;
	character: Character;
}

export type AssignmentsByUserId = Map<UserId, Assignment[]>;

export function getAssignmentsByUserId(
	charactersByUserId: Map<UserId, Character[]>,
	assignedRoleByCharacterId: Map<CharacterId, Role>
): AssignmentsByUserId {
	const assignmentsByUserId: AssignmentsByUserId = new Map<UserId, Assignment[]>();

	// go through each of user's characters and figure out if they're assigned already
	charactersByUserId.forEach((characters, userId) => {
		const assignments: Assignment[] = [];

		characters.forEach((character) => {
			const assignedRole = assignedRoleByCharacterId.get(character.id);

			if (assignedRole) {
				assignments.push({
					role: assignedRole,
					character
				});
			}
		});

		assignmentsByUserId.set(userId, assignments);
	});

	return assignmentsByUserId;
}
