import React from 'react';
import classnames from 'classnames';
import {Link, browserHistory as router} from 'react-router';
import Reorder from 'react-reorder';

import * as toolbox from '../../../helpers/toolbox';

import type {TagDuck, SettingsDuck} from '@ducks';

import type {Character} from '../../../models/character';
import type {Tag} from '../../../models/tag';

import planRaids from '../../../images/plan-raids.png';

import * as BillingLink from '../../Utils/BillingLink';
import TagDetailsContent from './TagDetailsContent';

export interface ICharacterGroup {
	character: Character;
	isTagged: boolean;
}

export interface IGroup {
	characterGroups: ICharacterGroup[];
	name: string;

	/** Unqique ID for the group - used for the react key */
	id: number;
}

export interface IMapProps {
	canUseTagging: boolean;

	isGroupingByStatus: boolean;
	isSubmitting: boolean;
	isDeleting: boolean;

	groups: IGroup[];
	tags: Tag[];
	tag?: Tag;

	baseUrl: string;
}

interface IListProps extends IMapProps {
	onGroupByStatus: typeof SettingsDuck['setGuildSettingsGroupTagsByStatus'];
	onTag: typeof TagDuck.makeAssignment;
	onReorder: typeof TagDuck.reorderTag;
	onCreate: typeof TagDuck.createTag;
	onUpdate: typeof TagDuck.updateTag;
	onDelete: typeof TagDuck.deleteTag;
}

export default class TagSettings extends React.Component<IListProps> {
	handleReorder = (e: any, oldIndex: number, newIndex: number) => {
		const reordered: Tag[] = Reorder.reorder(this.props.tags, oldIndex, newIndex);

		const before = reordered[newIndex + 1];
		const after = reordered[newIndex - 1];
		const order = toolbox.calculateNewOrder(
			before ? before.order : 0,
			after ? after.order : 0
		);

		const tag = reordered[newIndex];
		this.props.onReorder(tag.id, order);
	};

	render() {
		const tags = this.props.tags.map((tag) => (
			<ListItem
				key={tag.id}
				isSelected={this.props.tag ? this.props.tag.id === tag.id : false}
				baseUrl={this.props.baseUrl}
				tag={tag}
			/>
		));

		return (
			<div className="settings-content no-padding tags">
				<div className="settings-split-content">
					<div className="list-content-container">
						<div className="settings-heading">Character Tags</div>

						{this.props.canUseTagging && (
							<div className="list-content">
								<Link
									to={`${this.props.baseUrl}/new`}
									activeClassName="active"
									className="list-item add-new"
								>
									<div className="name">Create new tag</div>
								</Link>

								<div className="help-item">Drag to reorder options</div>

								<Reorder
									onReorder={this.handleReorder}
									reorderId="guild-setting-tags"
									className="list-items-reorder"
									lock="horizontal"
									holdTime={150}
								>
									{tags}
								</Reorder>
							</div>
						)}
					</div>

					{!this.props.canUseTagging && <UpgradeView />}

					{this.props.canUseTagging && (
						<TagDetailsContent
							onGroupByStatus={this.props.onGroupByStatus}
							onCreate={this.props.onCreate}
							onUpdate={this.props.onUpdate}
							onDelete={this.props.onDelete}
							onTag={this.props.onTag}
							isGroupingByStatus={this.props.isGroupingByStatus}
							isSubmitting={this.props.isSubmitting}
							isDeleting={this.props.isDeleting}
							groups={this.props.groups}
							tag={this.props.tag}
						/>
					)}
				</div>
			</div>
		);
	}
}

interface IItemProps {
	isSelected: boolean;
	tag: Tag;
	className?: string;
	baseUrl: string;
}

function ListItem(props: IItemProps) {
	const {tag, baseUrl, className, isSelected, ...rest} = props;
	const itemClass = classnames(
		{
			active: isSelected
		},
		className,
		'list-item'
	);

	return (
		<div
			onClick={() => router.push(`${baseUrl}/${tag.id}`)}
			className={itemClass}
			{...rest}
		>
			<div className="name">{tag.name}</div>
		</div>
	);
}

function UpgradeView() {
	return (
		<div className="details-content hero-view">
			<img className="hero-icon" src={planRaids} />

			<div className="hero-description">
				Filter your rosters to only show a subset of your guild's characters. A roster
				for Mains and a roster for Alts? No problem!
			</div>

			{BillingLink.createCustomLootOptionsLink(undefined, 'large primary button')}
		</div>
	);
}
