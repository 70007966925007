import type {Dispatch} from 'redux';
import {browserHistory as router} from 'react-router';

import * as rpc from '../../../shared/constants/rpc';

import * as toolbox from '../../helpers/toolbox';
import api from '../../helpers/api';

import type {RootAction} from '..';
import * as BannerDuck from '../banner';
import * as GuildDuck from '../guild';
import * as TagDuck from '.';

import {TagAssignment, ITagAssignmentClass} from '../../models/tag-assignment';
import {Tag} from '../../models/tag';
import {Guild} from '../../models/guild';

interface ICreateTag {
	name: string;
}

export function createTag(data: ICreateTag): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);

		const action: TagDuck.IActions['CREATE'] = {type: TagDuck.CREATE};
		dispatch(action);

		const payload = {
			name: data.name,
			guildId
		};

		return api.call(rpc.TAG_CREATE, payload).then(
			(message) => {
				const successAction: TagDuck.IActions['CREATE_SUCCESS'] = {
					type: TagDuck.CREATE_SUCCESS
				};

				dispatch(successAction);

				const baseUrl = Guild.createBaseUrl(guildId!);
				router.push(`${baseUrl}/guild-settings/tags/${message.data.tag.id}`);
			},

			(message) => {
				const failureAction: TagDuck.IActions['CREATE_FAILURE'] = {
					type: TagDuck.CREATE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

interface IUpdateTag {
	id: number;
	name: string;
}

export function updateTag(data: IUpdateTag): Thunk<void> {
	return (dispatch) => {
		const action: TagDuck.IActions['UPDATE'] = {type: TagDuck.UPDATE};
		dispatch(action);

		const payload = {
			name: data.name,
			id: data.id
		};

		return api.call(rpc.TAG_UPDATE, payload).then(
			() => {
				const successAction: TagDuck.IActions['UPDATE_SUCCESS'] = {
					type: TagDuck.UPDATE_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: TagDuck.IActions['UPDATE_FAILURE'] = {
					type: TagDuck.UPDATE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function deleteTag(id: number): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);

		const action: TagDuck.IActions['DELETE'] = {type: TagDuck.DELETE};
		dispatch(action);

		return api.call(rpc.TAG_DELETE, {id}).then(
			() => {
				const successAction: TagDuck.IActions['DELETE_SUCCESS'] = {
					type: TagDuck.DELETE_SUCCESS
				};

				dispatch(successAction);

				const baseUrl = Guild.createBaseUrl(guildId!);
				router.push(`${baseUrl}/guild-settings/tags/new`);
			},

			(message) => {
				const failureAction: TagDuck.IActions['DELETE_FAILURE'] = {
					type: TagDuck.DELETE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function reorderTag(id: number, order: number): Thunk<void> {
	return (dispatch, getState) => {
		const option = TagDuck.getTag(getState().tag, id);

		const optimisticId = toolbox.optimistic.getId();
		const action: TagDuck.IActions['REORDER'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: TagDuck.REORDER,
			payload: {
				tag: new Tag({...option, order})
			}
		};

		dispatch(action);

		return api.call(rpc.TAG_REORDER, {id, order}).then(
			() => {
				const successAction: TagDuck.IActions['REORDER_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: TagDuck.REORDER_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: TagDuck.IActions['REORDER_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: TagDuck.REORDER_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

interface IMakeAssignment {
	characterId: number;
	tagId: number;
	isTagged: boolean;
}

export function dispatchOptimisticTagAssignment(
	dispatch: Dispatch<RootAction>,
	state: IRootState,
	data: IMakeAssignment
) {
	const assignments = TagDuck.getAssignmentsByTagId(state.tag, data.tagId);
	const assignment = assignments.find(
		(x) => x.characterId === data.characterId && x.tagId === data.tagId
	);

	const optimisticId = toolbox.optimistic.getId();
	if (data.isTagged) {
		const newData: ITagAssignmentClass = assignment
			? {...assignment, tagId: data.tagId}
			: {
					characterId: data.characterId,
					tagId: data.tagId
			  };

		const action: TagDuck.IActions['ASSIGNMENT'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: TagDuck.ASSIGNMENT,
			payload: {
				assignment: new TagAssignment(newData)
			}
		};

		dispatch(action);
	} else if (assignment) {
		const action: TagDuck.IActions['ASSIGNMENT_REMOVE'] = {
			...toolbox.optimistic.begin(optimisticId),
			type: TagDuck.ASSIGNMENT_REMOVE,
			payload: {assignment}
		};

		dispatch(action);
	}

	return optimisticId;
}

export function makeAssignment(data: IMakeAssignment): Thunk<void> {
	return (dispatch, getState) => {
		const optimisticId = dispatchOptimisticTagAssignment(dispatch, getState(), data);

		const payload = {
			characterId: data.characterId,
			tagId: data.tagId,
			isTagged: data.isTagged
		};

		return api.call(rpc.TAG_ASSIGNMENT, payload).then(
			() => {
				const successAction: TagDuck.IActions['ASSIGNMENT_SUCCESS'] = {
					...toolbox.optimistic.commit(optimisticId),
					type: TagDuck.ASSIGNMENT_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: TagDuck.IActions['ASSIGNMENT_FAILURE'] = {
					...toolbox.optimistic.revert(optimisticId),
					type: TagDuck.ASSIGNMENT_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}
