import * as feed from '../../constants/feed';

import * as DemoDuck from '../demo';
import * as GuildDuck from '.';
import type {RootAction} from '..';

import {Guild} from '../../models/guild';

const initialState: GuildDuck.IState = {
	byId: {},

	isLoadingList: false,
	isLoadingData: false,

	isRenamingRank: false,

	activeId: undefined
};

export default function reducer(
	state = initialState,
	action: RootAction
): GuildDuck.IState {
	switch (action.type) {
		// set active
		case GuildDuck.SET_ACTIVE: {
			return {
				...state,
				activeId: action.payload.id
			};
		}

		// fetch demo guild
		case DemoDuck.FETCH_SUCCESS: {
			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.guild.id]: action.payload.guild
				}
			};
		}

		// fetch users guilds
		case GuildDuck.FETCH: {
			return {
				...state,
				isLoadingList: true
			};
		}

		case GuildDuck.FETCH_SUCCESS: {
			const guildsById: {[key: number]: Guild} = {};
			action.payload.guilds.forEach((guild) => {
				guildsById[guild.id] = guild;
			});

			return {
				...state,
				isLoadingList: false,

				byId: {
					...state.byId,
					...guildsById
				}
			};
		}

		case GuildDuck.FETCH_FAILURE: {
			return {
				...state,
				isLoadingList: false
			};
		}

		// fetch guild data
		case GuildDuck.FETCH_DATA: {
			return {
				...state,
				isLoadingData: true
			};
		}

		case GuildDuck.FETCH_DATA_SUCCESS: {
			return {
				...state,
				isLoadingData: false
			};
		}

		case GuildDuck.FETCH_DATA_FAILURE: {
			return {
				...state,
				isLoadingData: false
			};
		}

		// update rank
		case GuildDuck.RANK_RENAME: {
			return {...state, isRenamingRank: true};
		}

		case GuildDuck.RANK_RENAME_SUCCESS:
		case GuildDuck.RANK_RENAME_FAILURE: {
			return {...state, isRenamingRank: false};
		}

		// optimistic things
		case GuildDuck.ADMIN_RANK:
		case GuildDuck.ADMIN_IMPORTER:
		case GuildDuck.USER_ADMIN_ADD:
		case GuildDuck.USER_ADMIN_REMOVE:
		case GuildDuck.LOOTSHEET_ADMIN_ONLY:
		case GuildDuck.ROSTER_ADMIN_ONLY: {
			return {
				...state,

				byId: {
					...state.byId,
					[action.payload.guild.id]: action.payload.guild
				}
			};
		}

		// feed
		case feed.GUILD_UPDATE: {
			return {
				...state,

				byId: {
					...state.byId,
					[action.payload.newRecord.id]: new Guild(action.payload.newRecord)
				}
			};
		}

		default:
			return state;
	}
}
