import React from 'react';
import classnames from 'classnames';

import {useBoolean} from '@hooks/useBoolean';

import {ButtonGroup, Button} from '@components/Utils/Button';
import {Input} from '@components/Utils/Input';

interface EditableInputProps {
	onValidate: ((value: string) => boolean) | undefined;
	onSubmit(value: string): void;
	onCancel(): void;

	isSubmitting: boolean;
	value: string | undefined;

	placeholder: string;
	submitText: string;
	cancelText: string;
}

const EditableInput: React.FC<EditableInputProps> = (props) => {
	const inputRef = React.useRef<Input>(null);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		const value = inputRef.current?.getValue() || '';

		const isValid = props.onValidate ? props.onValidate(value) : !!value;
		if (!isValid) return;

		props.onSubmit(value);
	}

	return (
		<form onSubmit={handleSubmit} className="inline-editable-edit">
			<Input
				ref={inputRef}
				onEsc={() => props.onCancel()}
				className="no-bottom-margin"
				placeholder={props.placeholder}
				defaultValue={props.value}
				autoFocus={true}
			/>

			<ButtonGroup withTinyTopMargin={true}>
				<Button onClick={props.onCancel} className="small grey">
					{props.cancelText}
				</Button>

				<Button
					isLoading={props.isSubmitting}
					type="submit"
					className="small primary"
				>
					{props.submitText}
				</Button>
			</ButtonGroup>
		</form>
	);
};

interface InlineEditableProps {
	onSubmit(value: string): Promise<void>;
	onValidate?(value: string): boolean;

	isSubmitting: boolean;
	isBackgroundSubmit?: boolean;
	isReadonly?: boolean;

	className?: string;
	value: string | undefined | null;
	display?: string | React.ReactNode;

	placeholder: string;
	submitText: string;
	cancelText?: string;
}

export const InlineEditable: React.FC<InlineEditableProps> = (props) => {
	const editing = useBoolean(false);

	async function handleSubmit(value: string): Promise<void> {
		const submitPromise = props.onSubmit(value);

		// only block on submission if it isn't a background submit
		if (!props.isBackgroundSubmit) await submitPromise;

		editing.setFalse();
	}

	const containerClassName = classnames(
		{
			editing: editing.value,
			display: !editing.value,
			readonly: props.isReadonly,
			submitting: props.isSubmitting
		},
		'inline-editable',
		props.className
	);

	return (
		<>
			{editing.value ? (
				<div className={containerClassName}>
					<EditableInput
						onValidate={props.onValidate}
						onSubmit={handleSubmit}
						onCancel={editing.setFalse}
						isSubmitting={props.isSubmitting}
						value={props.value || undefined}
						placeholder={props.placeholder}
						submitText={props.submitText}
						cancelText={props.cancelText || 'Cancel'}
					/>
				</div>
			) : (
				<div
					className={containerClassName}
					onClick={() => !props.isReadonly && editing.setTrue()}
				>
					<div className="inline-editable-display">
						{props.display ?? props.value}
					</div>
				</div>
			)}
		</>
	);
};
