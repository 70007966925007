import type {CooldownAction, CooldownActionId} from '@models/cooldownAction';

export interface State {
	readonly actionById: Record<CooldownActionId, CooldownAction>;
}

export const CREATE = 'cooldown-action/CREATE';
export const CREATE_SUCCESS = 'cooldown-action/CREATE_SUCCESS';
export const CREATE_FAILURE = 'cooldown-action/CREATE_FAILURE';

export const UPDATE_ACTOR = 'cooldown-action/UPDATE_ACTOR';
export const UPDATE_ACTOR_SUCCESS = 'cooldown-action/UPDATE_ACTOR_SUCCESS';
export const UPDATE_ACTOR_FAILURE = 'cooldown-action/UPDATE_ACTOR_FAILURE';

export const UPDATE_ABILITY = 'cooldown-action/UPDATE_ABILITY';
export const UPDATE_ABILITY_SUCCESS = 'cooldown-action/UPDATE_ABILITY_SUCCESS';
export const UPDATE_ABILITY_FAILURE = 'cooldown-action/UPDATE_ABILITY_FAILURE';

export const DELETE = 'cooldown-action/DELETE';
export const DELETE_SUCCESS = 'cooldown-action/DELETE_SUCCESS';
export const DELETE_FAILURE = 'cooldown-action/DELETE_FAILURE';

export interface Actions {
	CREATE: {readonly type: typeof CREATE};
	CREATE_SUCCESS: {readonly type: typeof CREATE_SUCCESS};
	CREATE_FAILURE: {readonly type: typeof CREATE_FAILURE};

	UPDATE_ACTOR: {
		readonly type: typeof UPDATE_ACTOR;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly cooldownAction: CooldownAction;
		};
	};
	UPDATE_ACTOR_SUCCESS: {
		readonly type: typeof UPDATE_ACTOR_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	UPDATE_ACTOR_FAILURE: {
		readonly type: typeof UPDATE_ACTOR_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	UPDATE_ABILITY: {
		readonly type: typeof UPDATE_ABILITY;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly cooldownAction: CooldownAction;
		};
	};
	UPDATE_ABILITY_SUCCESS: {
		readonly type: typeof UPDATE_ABILITY_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	UPDATE_ABILITY_FAILURE: {
		readonly type: typeof UPDATE_ABILITY_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	DELETE: {
		readonly type: typeof DELETE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly cooldownActionId: CooldownActionId;
		};
	};
	DELETE_SUCCESS: {
		readonly type: typeof DELETE_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	DELETE_FAILURE: {
		readonly type: typeof DELETE_FAILURE;
		readonly optimist: Optimist.IRevert;
	};
}
