import {useMemo, useState} from 'react';

/** Like useState, except without the destructuring */
export function useStateful<T = any>(initial: T) {
	const [value, setValue] = useState(initial);

	return useMemo(
		() => ({
			value,
			setValue
		}),
		[value]
	);
}
