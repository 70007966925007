import {characterAbilityById} from '@shared/constants/characterAbilities';
import {Classes} from '@constants/wow';

import type {CooldownAction, CooldownActionId} from '@models/cooldownAction';
import type {CooldownEvent} from '@models/cooldownEvent';
import type {Character} from '@models/character';

import {formatTime} from './Event';

interface ActionGroup {
	character: Character | undefined;
	actorText: string | null;

	actions: CooldownAction[];
}

const ColourByClass: Record<Classes, string> = {
	[Classes.DEATH_KNIGHT]: '|cffc41f3b',
	[Classes.DEMON_HUNTER]: '|cffa330c9',
	[Classes.DRUID]: '|cffff7d0a',
	[Classes.HUNTER]: '|cffabd473',
	[Classes.MAGE]: '|cff69ccf0',
	[Classes.MONK]: '|cff00ff96',
	[Classes.PALADIN]: '|cfff58cba',
	[Classes.PRIEST]: '|cffffffff',
	[Classes.ROGUE]: '|cfffff569',
	[Classes.SHAMAN]: '|cff0070de',
	[Classes.WARLOCK]: '|cff9482c9',
	[Classes.WARRIOR]: '|cffc79c6e',
	[Classes.EVOKER]: '|cff33937f'
};

function formatTimeWithMarkup(timeInSeconds: number | null): string {
	if (!timeInSeconds) return '';

	const timeString = formatTime(timeInSeconds);
	return `{time:${timeString}}`;
}

function formatActor(actorText: string | null, character: Character | undefined): string {
	const colourPrefix = character ? ColourByClass[character.class] : '';
	const colourSuffix = character ? '|r' : '';

	return actorText ? `${colourPrefix}${actorText}${colourSuffix}` : '';
}

function formatAbility(action: CooldownAction, withHiddenAbilityName: boolean): string {
	const ability =
		(action.wowAbilityId && characterAbilityById.get(action.wowAbilityId)) || undefined;

	const spellIcon = ability ? `{spell:${ability?.wowAbilityId}}` : '';

	let abilityName = '';
	if (ability) {
		// only show the ability name if it hasn't been disabled
		if (!withHiddenAbilityName) abilityName = ability.name;
	} else {
		// always render freeform text since there's no icon to show
		abilityName = action.abilityText || '';
	}

	return [spellIcon, abilityName].join('');
}

function formatActionGroup(
	actionGroup: ActionGroup,
	withHiddenAbilityName: boolean
): string {
	const joinedAbilities = actionGroup.actions
		.map((action) => formatAbility(action, withHiddenAbilityName))
		.join(', ');

	return [
		formatActor(actionGroup.actorText, actionGroup.character),
		joinedAbilities
	].join(' ');
}

function createActionGroups(
	actions: EventAction[],
	withCombinedActions: boolean
): ActionGroup[] {
	const actionGroupByActorText = new Map<
		string | null | CooldownActionId,
		ActionGroup
	>();

	actions.forEach(({action, character}) => {
		// skip actions that don't have an ability
		if (!action.abilityText) return;

		const actorText = character?.name || action.actorText;

		// if we're combining actions, use the actor name as the key, otherwise de-conflict
		// by using the unique action ID
		const key = withCombinedActions ? actorText : action.id;

		// add to existing group if it exists
		const actionGroup = actionGroupByActorText.get(key);
		if (actionGroup) {
			actionGroup.actions.push(action);

			// update character if one hasn't been set yet
			// - covers the case where there's a Character for one action, but freeform for
			//   another, but the name/freeform match
			if (!actionGroup.character && !character) actionGroup.character = character;

			return;
		}

		// create a new action group
		actionGroupByActorText.set(key, {
			actions: [action],
			actorText,
			character
		});
	});

	return [...actionGroupByActorText.values()];
}

interface EventAction {
	action: CooldownAction;
	character: Character | undefined;
}

interface EventGroup {
	event: CooldownEvent;
	actions: EventAction[];
}

export function formatEventGroups(
	eventGroups: EventGroup[],
	options: {
		withCombinedActions: boolean;
		withHiddenAbilityName: boolean;
		withoutEmptyEvents: boolean;
	}
): string {
	return eventGroups
		.reduce<string[]>((list, {event, actions}): string[] => {
			const time = formatTimeWithMarkup(event.time);
			const eventName = event.name;

			const actionGroups = createActionGroups(actions, options.withCombinedActions);

			const joinedActions = actionGroups
				.map((actionGroup): string =>
					formatActionGroup(actionGroup, options.withHiddenAbilityName)
				)
				.join(' | ');

			if (options.withoutEmptyEvents && !joinedActions) return list;

			const actionsText = joinedActions ? ` - ${joinedActions}` : '';

			return [...list, `${time}${eventName}${actionsText}`.trim()];
		}, [])
		.join('\n');
}
