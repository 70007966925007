import {browserHistory as router} from 'react-router';

import type {
	PostRosterToDiscordInput,
	PostRosterToDiscordResult,
	RemoveAllCharactersFromRosterInput,
	RemoveAllCharactersFromRosterResult
} from 'api-types';

import * as rpc from '../../shared/constants/rpc';
import * as feed from '../constants/feed';

import api from '../helpers/api';

import * as BannerDuck from './banner';
import * as GuildDuck from './guild';
import * as DemoDuck from './demo';
import type {RootAction} from '.';

import {Roster} from '../models/roster';
import {Guild} from '../models/guild';

interface IById {
	[key: number]: Roster;
	[key: string]: Roster;
}

export interface IState {
	readonly byId: IById;

	readonly isUnrosteringAll: boolean;
	readonly isPostingToDiscord: boolean;
	readonly isSubmitting: boolean;
	readonly isDeleting: boolean;
}

// types
const CREATE = 'roster/CREATE';
const CREATE_SUCCESS = 'roster/CREATE_SUCCESS';
const CREATE_FAILURE = 'roster/CREATE_FAILURE';

const UPDATE = 'roster/UPDATE';
const UPDATE_SUCCESS = 'roster/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'roster/UPDATE_FAILURE';

const DELETE = 'roster/DELETE';
const DELETE_SUCCESS = 'roster/DELETE_SUCCESS';
const DELETE_FAILURE = 'roster/DELETE_FAILURE';

const POST_TO_DISCORD = 'roster/POST_TO_DISCORD';
const POST_TO_DISCORD_SUCCESS = 'roster/POST_TO_DISCORD_SUCCESS';
const POST_TO_DISCORD_FAILURE = 'roster/POST_TO_DISCORD_FAILURE';

const UNROSTER_ALL = 'roster/UNROSTER_ALL';
const UNROSTER_ALL_SUCCESS = 'roster/UNROSTER_ALL_SUCCESS';
const UNROSTER_ALL_FAILURE = 'roster/UNROSTER_ALL_FAILURE';

export interface IActions {
	CREATE: {readonly type: typeof CREATE};
	CREATE_SUCCESS: {readonly type: typeof CREATE_SUCCESS};
	CREATE_FAILURE: {readonly type: typeof CREATE_FAILURE};

	UPDATE: {readonly type: typeof UPDATE};
	UPDATE_SUCCESS: {readonly type: typeof UPDATE_SUCCESS};
	UPDATE_FAILURE: {readonly type: typeof UPDATE_FAILURE};

	DELETE: {readonly type: typeof DELETE};
	DELETE_SUCCESS: {readonly type: typeof DELETE_SUCCESS};
	DELETE_FAILURE: {readonly type: typeof DELETE_FAILURE};

	POST_TO_DISCORD: {readonly type: typeof POST_TO_DISCORD};
	POST_TO_DISCORD_SUCCESS: {readonly type: typeof POST_TO_DISCORD_SUCCESS};
	POST_TO_DISCORD_FAILURE: {readonly type: typeof POST_TO_DISCORD_FAILURE};

	UNROSTER_ALL: {readonly type: typeof UNROSTER_ALL};
	UNROSTER_ALL_SUCCESS: {readonly type: typeof UNROSTER_ALL_SUCCESS};
	UNROSTER_ALL_FAILURE: {readonly type: typeof UNROSTER_ALL_FAILURE};
}

// selectors
export function getRostersSubmitting(state: IState) {
	return state.isSubmitting;
}

export function getRostersDeleting(state: IState) {
	return state.isDeleting;
}

export function getPostingToDiscord(state: IState): boolean {
	return state.isPostingToDiscord;
}

export function getIsUnrosteringAll(state: IState): boolean {
	return state.isUnrosteringAll;
}

export function getRostersForGuild(state: IState, guildId: number, withArchived = false) {
	const rosters = Object.values(state.byId).filter((roster) => {
		if (roster.guildId !== guildId) return false;
		if (!withArchived && roster.isArchived) return false;

		return true;
	});

	rosters.sort((a, b) => {
		if (a.isDefault) return -1;
		if (b.isDefault) return 1;

		return a.createdAt > b.createdAt ? -1 : 1;
	});

	return rosters;
}

export function getRoster(state: IState, id: number): Roster | undefined {
	return state.byId[id];
}

// actions
interface ICreateRoster {
	name: string;
	difficulty: string;
	wowInstanceId: string;

	isDefault: boolean;

	tagWhitelist: number[];
	tagBlacklist: number[];
}

export function createRoster(data: ICreateRoster): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);

		const action: IActions['CREATE'] = {type: CREATE};
		dispatch(action);

		const payload = {
			guildId,

			name: data.name,
			difficulty: data.difficulty,
			wowInstanceId: data.wowInstanceId,
			isDefault: data.isDefault,
			tagWhitelist: data.tagWhitelist,
			tagBlacklist: data.tagBlacklist
		};

		return api.call(rpc.ROSTER_CREATE, payload).then(
			(message) => {
				const successAction: IActions['CREATE_SUCCESS'] = {
					type: CREATE_SUCCESS
				};

				dispatch(successAction);

				const baseUrl = Guild.createBaseUrl(guildId!);
				router.push(`${baseUrl}/guild-settings/rosters/${message.data.roster.id}`);
			},

			(message) => {
				const failureAction: IActions['CREATE_FAILURE'] = {
					type: CREATE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

interface IUpdateRoster {
	id: number;

	name: string;
	difficulty: string;

	isDefault: boolean;

	tagWhitelist: number[];
	tagBlacklist: number[];
}

export function updateRoster(data: IUpdateRoster): Thunk<void> {
	return (dispatch) => {
		const action: IActions['UPDATE'] = {type: UPDATE};
		dispatch(action);

		const payload = {
			id: data.id,
			name: data.name,
			difficulty: data.difficulty,
			isDefault: data.isDefault,
			tagWhitelist: data.tagWhitelist,
			tagBlacklist: data.tagBlacklist
		};

		return api.call(rpc.ROSTER_UPDATE, payload).then(
			() => {
				const successAction: IActions['UPDATE_SUCCESS'] = {
					type: UPDATE_SUCCESS
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: IActions['UPDATE_FAILURE'] = {
					type: UPDATE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

export function deleteRoster(id: number): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		const roster = getRoster(state.rosters, id);
		const guildId = roster?.guildId;

		const action: IActions['DELETE'] = {type: DELETE};
		dispatch(action);

		return api.call(rpc.ROSTER_DELETE, {id}).then(
			() => {
				const successAction: IActions['DELETE_SUCCESS'] = {
					type: DELETE_SUCCESS
				};

				dispatch(successAction);

				const baseUrl = Guild.createBaseUrl(guildId!);
				router.push(`${baseUrl}/guild-settings/rosters/new`);
			},

			(message) => {
				const failureAction: IActions['DELETE_FAILURE'] = {
					type: DELETE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

/** Posts a Roster's assignments to Discord as message embeds */
export function postToDiscord(data: {
	rosterBossIds: RosterBossId[];
	rosterId: RosterId;

	isShowingFirstSwapIn: boolean;
	isShowingSwaps: boolean;
	pretext: string | undefined;
	webhookUrl: string;
}): Thunk<void> {
	return (dispatch) => {
		dispatch<IActions['POST_TO_DISCORD']>({type: POST_TO_DISCORD});

		return api
			.call<PostRosterToDiscordInput, PostRosterToDiscordResult>(
				rpc.ROSTER_POST_TO_DISCORD,
				{
					rosterBossIds: data.rosterBossIds,
					rosterId: data.rosterId,

					isShowingFirstSwapIn: data.isShowingFirstSwapIn,
					isShowingSwaps: data.isShowingSwaps,
					pretext: data.pretext,
					webhookUrl: data.webhookUrl
				}
			)
			.then(
				() => {
					dispatch<IActions['POST_TO_DISCORD_SUCCESS']>({
						type: POST_TO_DISCORD_SUCCESS
					});

					dispatch(BannerDuck.addSuccessBanner('Roster posted to Discord'));
				},

				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<IActions['POST_TO_DISCORD_FAILURE']>({
						type: POST_TO_DISCORD_FAILURE
					});
				}
			);
	};
}

/** Remove all character assignments from bosses in a roster */
export function removeAllCharacterAssignments(rosterId: RosterId): Thunk<void> {
	return (dispatch) => {
		dispatch<IActions['UNROSTER_ALL']>({type: UNROSTER_ALL});

		return api
			.call<RemoveAllCharactersFromRosterInput, RemoveAllCharactersFromRosterResult>(
				rpc.ROSTER_CLEAR_CHARACTERS,
				{rosterId}
			)
			.then(
				() => {
					dispatch<IActions['UNROSTER_ALL_SUCCESS']>({
						type: UNROSTER_ALL_SUCCESS
					});

					dispatch(
						BannerDuck.addSuccessBanner(
							'All assignments have been removed from this roster'
						)
					);
				},

				(message) => {
					dispatch(BannerDuck.addErrorBanner(message.error));
					dispatch<IActions['UNROSTER_ALL_FAILURE']>({
						type: UNROSTER_ALL_FAILURE
					});
				}
			);
	};
}

// reducer
const initialState: IState = {
	byId: {},

	isUnrosteringAll: false,
	isPostingToDiscord: false,
	isSubmitting: false,
	isDeleting: false
};

export default function reducer(
	state: IState = initialState,
	action: RootAction
): IState {
	switch (action.type) {
		// guild payload
		case GuildDuck.FETCH_DATA_SUCCESS:
		case DemoDuck.FETCH_SUCCESS: {
			const rostersById: {[key: number]: Roster} = {};
			action.payload.rosters.forEach((roster) => {
				rostersById[roster.id] = roster;
			});

			return {
				...state,

				// remove characters from other guilds
				byId: rostersById
			};
		}

		// feed
		case feed.ROSTER_INSERT:
		case feed.ROSTER_UPDATE: {
			return {
				...state,

				byId: {
					...state.byId,
					[action.payload.newRecord.id]: new Roster(action.payload.newRecord)
				}
			};
		}

		case feed.ROSTER_DELETE: {
			const byId: IById = {};
			Object.values(state.byId).forEach((roster) => {
				if (roster.id === action.payload.oldRecord.id) return;

				byId[roster.id] = roster;
			});

			return {
				...state,
				byId
			};
		}

		// update / create
		case CREATE:
		case UPDATE: {
			return {...state, isSubmitting: true};
		}

		case CREATE_SUCCESS:
		case CREATE_FAILURE:
		case UPDATE_SUCCESS:
		case UPDATE_FAILURE: {
			return {...state, isSubmitting: false};
		}

		// delete
		case DELETE: {
			return {...state, isDeleting: true};
		}

		case DELETE_SUCCESS:
		case DELETE_FAILURE: {
			return {...state, isDeleting: false};
		}

		// post to discord
		case POST_TO_DISCORD: {
			return {...state, isPostingToDiscord: true};
		}

		case POST_TO_DISCORD_SUCCESS:
		case POST_TO_DISCORD_FAILURE: {
			return {...state, isPostingToDiscord: false};
		}

		// unroster all characters from roster bosses
		case UNROSTER_ALL: {
			return {...state, isUnrosteringAll: true};
		}

		case UNROSTER_ALL_SUCCESS:
		case UNROSTER_ALL_FAILURE: {
			return {...state, isUnrosteringAll: false};
		}

		default:
			return state;
	}
}
