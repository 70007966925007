import {browserHistory as router} from 'react-router';

import type {RpcReimportSubmitInput, RpcReimportSubmitResult} from 'api-types';

import * as rpc from '../../shared/constants/rpc';

import api from '../helpers/api';

import * as BannerDuck from './banner';
import * as GuildDuck from './guild';
import type {RootAction} from '.';

import {Character, ICharacterData} from '../models/character';
import {Guild} from '../models/guild';

type ReimportCharacter = Character | ICharacterData;

export interface IState {
	readonly isLoaded: boolean;
	readonly isSubmitting: boolean;
	readonly isLoading: boolean;
	readonly is404: boolean;

	readonly members: ReimportCharacter[];
}

// types
const RESET = 'reimport/RESET';

const INITIATE = 'reimport/INITIATE';
const INITIATE_SUCCESS = 'reimport/INITIATE_SUCCESS';
const INITIATE_FAILURE = 'reimport/INITIATE_FAILURE';

const SUBMIT = 'reimport/SUBMIT';
const SUBMIT_SUCCESS = 'reimport/SUBMIT_SUCCESS';
const SUBMIT_FAILURE = 'reimport/SUBMIT_FAILURE';

export interface IActions {
	RESET: {readonly type: typeof RESET};

	INITIATE: {readonly type: typeof INITIATE};
	INITIATE_FAILURE: {readonly type: typeof INITIATE_FAILURE};
	INITIATE_SUCCESS: {
		readonly type: typeof INITIATE_SUCCESS;
		readonly payload: {
			readonly members: ReimportCharacter[];
		};
	};

	SUBMIT: {readonly type: typeof SUBMIT};
	SUBMIT_SUCCESS: {readonly type: typeof SUBMIT_SUCCESS};
	SUBMIT_FAILURE: {readonly type: typeof SUBMIT_FAILURE};
}

// selectors
export function getSubmitting(state: IState) {
	return state.isSubmitting;
}

export function getLoading(state: IState) {
	return state.isLoading;
}

export function getLoaded(state: IState) {
	return state.isLoaded;
}

export function get404(state: IState) {
	return state.is404;
}

export function getMembers(state: IState) {
	return [...state.members];
}

// actions
export function reset(): IActions['RESET'] {
	return {type: RESET};
}

export function initiate(): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		if (getLoading(state.reimport)) return Promise.resolve();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);

		const action: IActions['INITIATE'] = {type: INITIATE};
		dispatch(action);

		const payload = {
			id: guildId
		};

		return api.call(rpc.REIMPORT_INITIATE, payload).then(
			(message) => {
				const successAction: IActions['INITIATE_SUCCESS'] = {
					type: INITIATE_SUCCESS,
					payload: {
						members: message.data.members.map((char: any) =>
							char.id ? new Character(char) : char
						)
					}
				};

				dispatch(successAction);
			},

			(message) => {
				const failureAction: IActions['INITIATE_FAILURE'] = {
					type: INITIATE_FAILURE
				};

				dispatch(failureAction);
				dispatch(BannerDuck.addErrorBanner(message.error));
			}
		);
	};
}

interface ISubmitData {
	importCharacterCombos: string[];
	importIds: number[];
}

export function submit(data: ISubmitData): Thunk<void> {
	return (dispatch, getState) => {
		const state = getState();
		if (getSubmitting(state.reimport)) return Promise.resolve();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);
		if (!guildId) return Promise.resolve();

		const action: IActions['SUBMIT'] = {type: SUBMIT};
		dispatch(action);

		const payload = {
			id: guildId,
			importCharacterCombos: data.importCharacterCombos,
			importIds: data.importIds
		};

		return api
			.call<RpcReimportSubmitInput, RpcReimportSubmitResult>(
				rpc.REIMPORT_SUBMIT,
				payload
			)
			.then(
				() => {
					const successAction: IActions['SUBMIT_SUCCESS'] = {type: SUBMIT_SUCCESS};
					dispatch(successAction);

					const baseUrl = Guild.createBaseUrl(guildId);
					router.push(`${baseUrl}/select-roles`);
				},

				(message) => {
					const failureAction: IActions['SUBMIT_FAILURE'] = {
						type: SUBMIT_FAILURE
					};

					dispatch(failureAction);
					dispatch(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}

// reducer
const initialState: IState = {
	isSubmitting: false,
	isLoading: false,
	isLoaded: false,
	is404: false,

	members: []
};

export default function reducer(
	state: IState = initialState,
	action: RootAction
): IState {
	switch (action.type) {
		// reset
		case RESET: {
			return {
				isSubmitting: false,
				isLoading: false,
				isLoaded: false,
				is404: false,
				members: []
			};
		}

		// init
		case INITIATE: {
			return {
				...state,
				isLoading: true
			};
		}

		case INITIATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				members: action.payload.members
			};
		}

		case INITIATE_FAILURE: {
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				is404: true
			};
		}

		// submit
		case SUBMIT: {
			return {
				...state,
				isSubmitting: true
			};
		}

		case SUBMIT_SUCCESS: {
			return {
				...state,
				isSubmitting: false
			};
		}

		case SUBMIT_FAILURE: {
			return {
				...state,
				isSubmitting: false
			};
		}

		default:
			return state;
	}
}
