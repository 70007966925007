import React from 'react';

import {GuildDuck, DemoDuck, connect} from '@ducks';

import type {Guild} from '../../models/guild';
import type {User} from '../../models/user';

import LootContainer from '../Loot/LootContainer';
import type {InstanceFilterOption} from '../Loot/Loot';

interface IOwnProps {
	location: {
		query: {
			c?: string;
			i?: InstanceFilterOption;
			d?: string;
		};
	};
}

interface IMapProps {
	isAdmin: boolean;

	guild?: Guild;
	user?: User;
}

interface IDispatchProps {
	onBump: BoundThunk<typeof DemoDuck.bumpUpdated>;
	onLootNote: BoundThunk<typeof DemoDuck['updateNote']>;
	onSelect: BoundThunk<typeof DemoDuck.makeSelection>;
	onCoin: BoundThunk<typeof DemoDuck.makeCoin>;
}

type IProps = IMapProps & IOwnProps & IDispatchProps;

function RealLootWrapper(props: IProps) {
	if (!props.guild) return null;

	return (
		<LootContainer
			onBump={props.onBump}
			onLootNote={props.onLootNote}
			onSelect={props.onSelect}
			onCoin={props.onCoin}
			isAdmin={props.isAdmin}
			isDemo={true}
			isBumping={false}
			location={props.location}
			guild={props.guild}
		/>
	);
}

function mapStateToProps(state: IRootState): IMapProps {
	const guild = GuildDuck.getActiveGuild(state.guilds);

	return {
		isAdmin: true,
		guild
	};
}

export default connect<IMapProps, IDispatchProps, IOwnProps>(mapStateToProps, {
	onBump: DemoDuck.bumpUpdated,
	onLootNote: DemoDuck.updateNote,
	onSelect: DemoDuck.makeSelection,
	onCoin: DemoDuck.makeCoin
})(RealLootWrapper);
