import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router';

import {connect, CharacterDuck, GuildDuck, UserDuck} from '@ducks';

import {droptimizerReportsUrl} from '@helpers/urls';

import HeaderContainer from '../Header/HeaderContainer';
import {YourLootSheet} from './YourLootSheet';
import {BuffButton} from './BuffButton';

import type {Character} from '../../models/character';

interface IOwnProps {
	isHeaderFixed: boolean;
	isLoading?: boolean;
	basePath?: string;

	activeCharacterQuery: string | undefined;
	activePathname: string;
}

interface IDispatchProps {
	onEnterSettings: typeof UserDuck.setUrl;
}

interface IMapProps {
	isLootSheetAdminOnly: boolean;
	isRosterAdminOnly: boolean;
	isAdmin: boolean;
	isDemo: boolean;

	userCharacters: Character[];
	userCharacterIds: string[];

	userId: number | undefined;
	guildId: number | undefined;
}

type IProps = IDispatchProps & IOwnProps & IMapProps;

function GuildNav(props: IProps) {
	return (
		<HeaderContainer isHeaderFixed={props.isHeaderFixed}>
			<div className="sub-nav-items">{!props.isLoading && <Items {...props} />}</div>
		</HeaderContainer>
	);
}

function getUrl() {
	return window.location.href.slice(window.location.origin.length);
}

const Wrapper = (props: any) => props.children;
function Items(props: IProps) {
	const isRosterHidden = props.isRosterAdminOnly && !props.isAdmin;
	const isLootSheetHidden = props.isLootSheetAdminOnly && !props.isAdmin;

	let lootUrl = `${props.basePath}/loot`;
	if (props.isDemo) lootUrl += '?i=8638';

	const isFilteredToOwnCharacter = props.activeCharacterQuery
		? props.userCharacterIds.includes(props.activeCharacterQuery)
		: false;
	const isLootsheetActive = lootUrl.startsWith(props.activePathname);

	const lootsheetClassName = classnames(
		{
			active: isLootsheetActive && !isFilteredToOwnCharacter
		},
		'nav-item'
	);

	const isYourLootSheetActive = isLootsheetActive && isFilteredToOwnCharacter;

	return (
		<Wrapper>
			<div className="left-items">
				{props.isAdmin && (
					<Link
						onClick={() => props.onEnterSettings(getUrl())}
						to={`${props.basePath}/guild-settings`}
						activeClassName="active"
						className="nav-item"
					>
						Guild Settings
					</Link>
				)}

				{!props.isDemo && !isRosterHidden && (
					<Link
						to={`${props.basePath}/rosters`}
						activeClassName="active"
						className="nav-item"
					>
						Rosters
					</Link>
				)}

				{props.isDemo && (
					<Link
						to={`${props.basePath}/roster`}
						activeClassName="active"
						className="nav-item"
					>
						Roster
					</Link>
				)}

				{!isLootSheetHidden && props.guildId !== undefined && (
					<>
						<Link to={lootUrl} className={lootsheetClassName}>
							Loot Sheet
						</Link>

						{!!props.userCharacters.length && (
							<YourLootSheet
								isActive={isYourLootSheetActive}
								baseLootUrl={lootUrl}
								characters={props.userCharacters}
							/>
						)}

						{!props.isDemo && (
							<Link
								to={droptimizerReportsUrl(props.guildId)}
								activeClassName="active"
								className="nav-item"
							>
								Droptimizer
							</Link>
						)}
					</>
				)}
			</div>

			{!props.isDemo && !!props.userId && !!props.guildId && (
				<BuffButton userId={props.userId} guildId={props.guildId} />
			)}
		</Wrapper>
	);
}

function mapStateToProps(state: IRootState): IMapProps {
	const guild = GuildDuck.getActiveGuild(state.guilds);
	const user = UserDuck.getUser(state.user);

	let userCharacters: Character[] = [];
	let isAdmin = false;
	if (guild && user) {
		const allGuildCharacters = CharacterDuck.getAllCharactersForGuild(
			state.characters,
			guild.id
		);
		isAdmin = guild.isUserAdmin(user, allGuildCharacters);

		userCharacters = CharacterDuck.getCharactersForUser(
			state.characters,
			guild.id,
			user
		);
	}

	return {
		isLootSheetAdminOnly: guild ? guild.isLootSheetAdminOnly : false,
		isRosterAdminOnly: guild ? guild.isWholeRosterAdminOnly() : false,
		isDemo: guild ? guild.isDemo() : true,
		isAdmin,

		userCharacterIds: userCharacters.map((x) => `${x.id}`),
		userCharacters,

		guildId: guild?.id,
		userId: user?.id
	};
}

export default connect<IMapProps, IDispatchProps, IOwnProps>(mapStateToProps, {
	onEnterSettings: UserDuck.setUrl
})(GuildNav);
