import React from 'react';

import {useBoolean} from '@hooks/useBoolean';

import {Input} from '../../Utils/Input';
import {ButtonGroup, Button} from '../../Utils/Button';
import {ViewNote} from './ViewNote';

interface AddNoteProps {
	onClick(): void;
}

const AddNote: React.FC<AddNoteProps> = (props) => {
	return (
		<div onClick={props.onClick} className="add-note-prompt">
			Add note about selection
		</div>
	);
};

interface NoteInputProps {
	onSubmit(value: string): void;
	onCancel(): void;
	value: string;
}

const NoteInput: React.FC<NoteInputProps> = (props) => {
	const inputRef = React.useRef<Input>(null);

	function handleSubmit() {
		const value = inputRef.current?.getValue() || '';
		props.onSubmit(value.trim());
	}

	return (
		<form className="note-input">
			<Input
				ref={inputRef}
				className="no-bottom-margin"
				isTextarea={true}
				defaultValue={props.value}
				autoFocus={true}
			/>

			<ButtonGroup withTinyTopMargin={true}>
				<Button onClick={props.onCancel} className="small grey">
					Cancel
				</Button>

				<Button onClick={handleSubmit} className="small primary">
					Save
				</Button>
			</ButtonGroup>
		</form>
	);
};

interface EditableNoteProps {
	onSubmit(value: string): void;
	value: string | null;
}

export const EditableNote: React.FC<EditableNoteProps> = (props) => {
	const editing = useBoolean(false);

	return (
		<div className="editable-note-wrapper">
			{editing.value && (
				<NoteInput
					onSubmit={(value) => {
						editing.setFalse();
						props.onSubmit(value);
					}}
					onCancel={editing.setFalse}
					value={props.value || ''}
				/>
			)}

			{!editing.value &&
				(props.value ? (
					<ViewNote onClick={editing.setTrue} value={props.value} />
				) : (
					<AddNote onClick={editing.setTrue} />
				))}
		</div>
	);
};
