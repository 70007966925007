import React from 'react';
import classnames from 'classnames';
import {FiPlus, FiMinus} from 'react-icons/fi';

import type {Role} from '@constants/wow';

import {getRoleImageNewUrl} from '@helpers/images';

import type {Character} from '@models/character';

import type {Change} from '.';

import {Image} from '../../../../Utils/Image';

interface ChangeBoxProps {
	type: 'added' | 'removed';
	character: Character;
}

const ChangeBox: React.FC<ChangeBoxProps> = (props) => {
	const changeClass = classnames(props.type, 'change-box');

	return (
		<div className={changeClass}>
			<div className="change-icon">
				{props.type === 'added' && <FiPlus size={13} />}
				{props.type === 'removed' && <FiMinus size={13} />}
			</div>

			<div className="name">{props.character.name}</div>
		</div>
	);
};

interface RoleGroupProps {
	role: Role;
	changes: Change[];
}

export const RoleGroup: React.FC<RoleGroupProps> = (props) => {
	if (!props.changes.length) return null;

	const changeItems = props.changes.map((change) => (
		<ChangeBox
			key={change.character.id}
			type={change.change}
			character={change.character}
		/>
	));

	return (
		<div className="role-group">
			<div className="role-icon">
				<Image src={getRoleImageNewUrl(props.role)} />
			</div>

			<div className="content">
				<div className="changes">{changeItems}</div>
			</div>
		</div>
	);
};
