import {BossKillsDuck, RootAction} from '..';

import type {CharacterEncounters} from '../../models/boss-kills';

function buildNewEncounterDataByEncounterId(
	bossKills: readonly CharacterEncounters[]
): BossKillsDuck.IEncounterDataByEncounterId {
	const encounterById: BossKillsDuck.IEncounterDataByEncounterId = new Map();

	bossKills.forEach(({characterId, encounters}) => {
		encounters.forEach((killInfo) => {
			const encounter = encounterById.get(killInfo.encounterId);
			encounterById.set(
				killInfo.encounterId,
				new Map([...(encounter || []), [characterId, killInfo]])
			);
		});
	});

	return encounterById;
}

const initialState: BossKillsDuck.IState = {
	byEncounterId: new Map()
};

export default function reducer(
	state = initialState,
	action: RootAction
): BossKillsDuck.IState {
	switch (action.type) {
		case BossKillsDuck.FETCH_SUCCESS: {
			return {
				...state,

				byEncounterId: new Map([
					...state.byEncounterId,
					...buildNewEncounterDataByEncounterId(action.payload.bossKills)
				])
			};
		}

		default:
			return state;
	}
}
