import React from 'react';
import classnames from 'classnames';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

import Input from '@components/Utils/Input';
import Modal from '@components/Utils/Modal';
import {Markdown} from '@components/Utils/Markdown';

interface IProps {
	onSubmit(notes: string): void;
	onClose(): void;

	isSubmitting: boolean;

	notes: string | null;
	name: string;
}

interface IState {
	isPreviewActive: boolean;
	content: string;
}

export class BossNotesEditor extends React.Component<IProps, IState> {
	state: IState = {
		isPreviewActive: false,
		content: this.props.notes || ''
	};

	handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		this.props.onSubmit(this.state.content);
	};

	render() {
		const cancelClass = classnames(
			{
				disabled: this.props.isSubmitting
			},
			'grey button'
		);

		const submitCLass = classnames(
			{
				disabled: this.props.isSubmitting
			},
			'primary button'
		);

		return (
			<Modal onClose={this.props.onClose}>
				<form
					onSubmit={this.handleSubmit}
					className="modal-content boss-notes-editor"
				>
					<div className="heading">Notes for {this.props.name}</div>

					<div className="tabs">
						<div
							onClick={() => this.setState({isPreviewActive: false})}
							className={`tab ${!this.state.isPreviewActive ? 'active' : ''}`}
						>
							Write
						</div>

						<div
							onClick={() => this.setState({isPreviewActive: true})}
							className={`tab ${this.state.isPreviewActive ? 'active' : ''}`}
						>
							Preview
						</div>

						<a
							className="formatting-help tab"
							href="https://commonmark.org/help/"
							rel="nofollow noopener noreferrer"
						>
							Formatting help
						</a>
					</div>

					<div className="notes-content">
						{this.state.isPreviewActive ? (
							<Markdown content={this.state.content} />
						) : (
							<Input
								onChange={(content) => this.setState({content})}
								isTextarea={true}
								defaultValue={this.state.content}
								placeholder="Boss strat, Weakauras etc for this boss"
								autoFocus={true}
							/>
						)}
					</div>

					<div className="actions">
						<div onClick={this.props.onClose} className={cancelClass}>
							Back
						</div>

						<LaddaButton
							className={submitCLass}
							loading={this.props.isSubmitting}
							data-style={ZOOM_OUT}
						>
							Save
						</LaddaButton>
					</div>
				</form>
			</Modal>
		);
	}
}
