import React from 'react';

import type {Difficulties} from '../../constants/wow';

import {router, lootUrl} from '@helpers/urls';

import {BannerDuck, GuildDuck, GuildSponsorshipDuck, LootDuck, connect} from '@ducks';

import {UpgradeView} from './UpgradeView';
import {Importer} from './Importer';
import {Entries} from './Entries';

export interface IImportedEntry {
	uniqueId: string;

	characterName: string;
	characterRealm: string;
	itemId: string;
	difficulty: Difficulties;

	/** e.g. "Best in Slot" "Personal Loot - Non tradeable" */
	selectionText: string;
}

interface IMapProps {
	canUseRclcImport: boolean;
	isSubmittingRclc: boolean;
	guildId: number;
	lootUrl: string;
}

interface IDispatchProps {
	onError: BoundThunk<typeof BannerDuck.addErrorBanner>;
	onSubmit: BoundThunk<typeof LootDuck.submitRclc>;
}

type IProps = IMapProps & IDispatchProps;

interface IState {
	entries: IImportedEntry[] | null;
}

class RclcViewComponent extends React.Component<IProps, IState> {
	state: IState = {
		entries: null
	};

	handleSubmit = (rclcSelection: LootDuck.IRclcSelection[]): void => {
		void this.props
			.onSubmit(this.props.guildId, rclcSelection)
			.then(() => router.push(this.props.lootUrl));
	};

	render() {
		return (
			<div className="rclc-view">
				{this.props.canUseRclcImport ? (
					<>
						{!this.state.entries && (
							<Importer
								onImport={(entries) => this.setState({entries})}
								onError={this.props.onError}
							/>
						)}

						{this.state.entries && (
							<Entries
								onCancel={() => this.setState({entries: null})}
								onSubmit={this.handleSubmit}
								isSubmitting={this.props.isSubmittingRclc}
								guildId={this.props.guildId}
								doneUrl={this.props.lootUrl}
								entries={this.state.entries}
							/>
						)}
					</>
				) : (
					<UpgradeView />
				)}
			</div>
		);
	}
}

function mapStateToProps(state: IRootState): IMapProps {
	const guild = GuildDuck.getActiveGuildNew(state.guilds);
	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		guild.id
	);

	return {
		canUseRclcImport: fc.canUseRclcImport(),
		isSubmittingRclc: LootDuck.getIsSubmittingRclc(state.loot),
		guildId: guild.id,
		lootUrl: lootUrl(guild.id)
	};
}

export const RclcView = connect<IMapProps, IDispatchProps, {}>(mapStateToProps, {
	onError: BannerDuck.addErrorBanner,
	onSubmit: LootDuck.submitRclc
})(RclcViewComponent);
