import {groupByProperty} from '../helpers/toolbox';

export interface BossAbility {
	isNpc?: boolean;
	wowAbilityId: WowAbilityId;
	name: string;
	iconName: string;

	wowBossId: WowBossId;
}

const castleNathria: BossAbility[] = [
	// Shriekwing
	{
		wowAbilityId: '345397' as WowAbilityId,
		name: 'Wave of Blood',
		iconName: 'ability_ironmaidens_whirlofblood',

		wowBossId: '172145' as WowBossId
	},
	{
		wowAbilityId: '328921' as WowAbilityId,
		name: 'Blood Shroud',
		iconName: 'ability_deathwing_bloodcorruption_earth',

		wowBossId: '172145' as WowBossId
	},
	{
		wowAbilityId: '329362' as WowAbilityId,
		name: 'Echoing Sonar',
		iconName: 'spell_nature_wispsplode',

		wowBossId: '172145' as WowBossId
	},
	{
		wowAbilityId: '342863' as WowAbilityId,
		name: 'Echoing Screech',
		iconName: 'spell_nature_wispsplode',

		wowBossId: '172145' as WowBossId
	},
	{
		wowAbilityId: '330711' as WowAbilityId,
		name: 'Earsplitting Shriek',
		iconName: 'spell_shadow_deathscream',

		wowBossId: '172145' as WowBossId
	},
	{
		wowAbilityId: '342074' as WowAbilityId,
		name: 'Echolocation',
		iconName: 'ability_creature_cursed_05',

		wowBossId: '172145' as WowBossId
	},
	{
		wowAbilityId: '328857' as WowAbilityId,
		name: 'Exsanguinating Bite',
		iconName: 'ability_racial_cannibalize',

		wowBossId: '172145' as WowBossId
	},
	{
		wowAbilityId: '343005' as WowAbilityId,
		name: 'Blind Swipe',
		iconName: 'ability_racial_flayer',

		wowBossId: '172145' as WowBossId
	},

	// Huntsman
	{
		wowAbilityId: '334797' as WowAbilityId,
		name: 'Rip Soul',
		iconName: 'ability_druid_markofursol',

		wowBossId: '165066' as WowBossId
	},
	{
		wowAbilityId: '334939' as WowAbilityId,
		name: 'Vicious Lunge',
		iconName: 'spell_druid_bloodythrash',

		wowBossId: '165066' as WowBossId
	},
	{
		wowAbilityId: '334757' as WowAbilityId,
		name: 'Shades of Bargast',
		iconName: 'inv_felhound3_shadow_mount',

		wowBossId: '165066' as WowBossId
	},
	{
		wowAbilityId: '334852' as WowAbilityId,
		name: 'Petrifying Howl',
		iconName: 'inv_sinstonegolempet',

		wowBossId: '165066' as WowBossId
	},
	{
		wowAbilityId: '334404' as WowAbilityId,
		name: 'Spreadshot',
		iconName: 'ability_hunter_runningshot',

		wowBossId: '165066' as WowBossId
	},
	{
		wowAbilityId: '335114' as WowAbilityId,
		name: 'Sinseeker',
		iconName: 'ability_hunter_assassinate2',

		wowBossId: '165066' as WowBossId
	},
	{
		wowAbilityId: '334504' as WowAbilityId,
		name: "Huntsman's Bond",
		iconName: 'achievement_raid_revendrethraid_huntsmangargon',

		wowBossId: '165066' as WowBossId
	},

	// Hungering Destroyer
	{
		wowAbilityId: '332295' as WowAbilityId,
		name: 'Growing Hunger',
		iconName: 'spell_deathknight_iceboundfortitude',

		wowBossId: '164261' as WowBossId
	},
	{
		wowAbilityId: '329774' as WowAbilityId,
		name: 'Overwhelm',
		iconName: 'spell_shadow_manaburn',

		wowBossId: '164261' as WowBossId
	},
	{
		wowAbilityId: '329298' as WowAbilityId,
		name: 'Gluttonous Miasma',
		iconName: 'ability_deathknight_frozencenter',

		wowBossId: '164261' as WowBossId
	},
	{
		wowAbilityId: '329455' as WowAbilityId,
		name: 'Desolate',
		iconName: 'ability_argus_soulburst',

		wowBossId: '164261' as WowBossId
	},
	{
		wowAbilityId: '334266' as WowAbilityId,
		name: 'Volatile Ejection',
		iconName: 'achievement_boss_lordmarrowgar',

		wowBossId: '164261' as WowBossId
	},
	{
		wowAbilityId: '329742' as WowAbilityId,
		name: 'Expunge',
		iconName: 'ability_argus_soulbombdebufflarge',

		wowBossId: '164261' as WowBossId
	},
	{
		wowAbilityId: '334522' as WowAbilityId,
		name: 'Consume',
		iconName: 'ability_argus_deathfog',

		wowBossId: '164261' as WowBossId
	},
	{
		wowAbilityId: '334755' as WowAbilityId,
		name: 'Essence Sap',
		iconName: 'spell_shadow_focusedpower',

		wowBossId: '164261' as WowBossId
	},

	// Sun King
	{
		wowAbilityId: '339251' as WowAbilityId,
		name: 'Drained Soul',
		iconName: 'spell_animarevendreth_debuff',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '325506' as WowAbilityId,
		name: 'Concussive Smash',
		iconName: 'ability_earthen_pillar',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '341473' as WowAbilityId,
		name: 'Crimson Flurry',
		iconName: 'ability_revendreth_rogue',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '325440' as WowAbilityId,
		name: 'Vanquishing Strike',
		iconName: 'inv_warbreaker',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '333145' as WowAbilityId,
		name: 'Return to Stone',
		iconName: 'inv_shoulder_04',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '333002' as WowAbilityId,
		name: 'Vulgar Brand',
		iconName: 'spell_animarevendreth_nova',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '325590' as WowAbilityId,
		name: 'Scornful Blast',
		iconName: 'sha_spell_fire_blueflamering_nightmare',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '328248' as WowAbilityId,
		name: 'Door of Shadows',
		iconName: 'ability_venthyr_doorofshadows',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '328254' as WowAbilityId,
		name: 'Shattering Ruby',
		iconName: 'inv_misc_gem_bloodstone_02',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '325665' as WowAbilityId,
		name: 'Soul Infusion',
		iconName: 'ability_warlock_soullink',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '326455' as WowAbilityId,
		name: 'Fiery Strike',
		iconName: 'spell_fire_burnout',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '329518' as WowAbilityId,
		name: 'Blazing Surge',
		iconName: 'ability_rhyolith_magmaflow_wave',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '325877' as WowAbilityId,
		name: 'Ember Blast',
		iconName: 'spell_fire_selfdestruct',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '336398' as WowAbilityId,
		name: 'Fragmentation',
		iconName: 'archaeology_5_0_crackedmogurunestone',

		wowBossId: '24664' as WowBossId
	},
	{
		wowAbilityId: '343026' as WowAbilityId,
		name: 'Cloak of Flames',
		iconName: 'ability_creature_cursed_01',

		wowBossId: '24664' as WowBossId
	},

	// Xy'mox
	{
		wowAbilityId: '328437' as WowAbilityId,
		name: 'Dimensional Tear',
		iconName: 'creatureportrait_portal_alteracvalleyhorde',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '325361' as WowAbilityId,
		name: 'Glyph of Destruction',
		iconName: 'ability_mage_incantersabsorbtion',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '329458' as WowAbilityId,
		name: 'Rift Blast',
		iconName: 'spell_animabastion_beam',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '325399' as WowAbilityId,
		name: 'Hyperlight Spark',
		iconName: 'ability_shootwand',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '340842' as WowAbilityId,
		name: 'Soul Singe',
		iconName: 'spell_necro_deathrift',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '329770' as WowAbilityId,
		name: 'Root of Extinction',
		iconName: 'inv_wand_1h_ardenweald_d_01',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '340860' as WowAbilityId,
		name: 'Withering Touch',
		iconName: 'spell_animaardenweald_debuff',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '328880' as WowAbilityId,
		name: 'Edge of Annihilation',
		iconName: 'inv_archaeology_80_witch_guillotineaxe',

		wowBossId: '166644' as WowBossId
	},
	{
		wowAbilityId: '326271' as WowAbilityId,
		name: 'Stasis Trap',
		iconName: 'ability_monk_forcesphere',

		wowBossId: '166644' as WowBossId
	},

	// Lady Inerva Darkvein
	{
		wowAbilityId: '342287' as WowAbilityId,
		name: 'Lesser Sins and Suffering',
		iconName: 'ui_venthyranimaboss_orblines',

		wowBossId: '167517' as WowBossId
	},
	{
		wowAbilityId: '334017' as WowAbilityId,
		name: 'Condemn',
		iconName: 'inv_misc_volatilelife',

		wowBossId: '167517' as WowBossId
	},
	{
		wowAbilityId: '325184' as WowAbilityId,
		name: 'Loose Anima',
		iconName: 'ability_siege_engineer_purification_beam',

		wowBossId: '167517' as WowBossId
	},
	{
		wowAbilityId: '325225' as WowAbilityId,
		name: 'Container Breach',
		iconName: 'ability_vehicle_liquidpyrite',

		wowBossId: '167517' as WowBossId
	},
	{
		wowAbilityId: '331844' as WowAbilityId,
		name: 'Focus Anima',
		iconName: 'ability_revendreth_paladin',

		wowBossId: '167517' as WowBossId
	},
	{
		wowAbilityId: '325379' as WowAbilityId,
		name: 'Expose Desires',
		iconName: 'ui_venthyranimaboss_heartsword',

		wowBossId: '167517' as WowBossId
	},
	{
		wowAbilityId: '341623' as WowAbilityId,
		name: 'Expose Cognition',
		iconName: 'ui_venthyranimaboss_heartsword',

		wowBossId: '167517' as WowBossId
	},

	// The Council of Blood
	{
		wowAbilityId: '337110' as WowAbilityId,
		name: 'Dreadbolt Volley',
		iconName: 'spell_animarevendreth_missile',

		wowBossId: '166971' as WowBossId
	},
	{
		wowAbilityId: '347350' as WowAbilityId,
		name: 'Dancing Fever',
		iconName: 'ability_deathknight_hemorrhagicfever',

		wowBossId: '166971' as WowBossId
	},
	{
		wowAbilityId: '328497' as WowAbilityId,
		name: 'Danse Macabre',
		iconName: 'ability_deathwing_bloodcorruption_death',

		wowBossId: '166971' as WowBossId
	},
	{
		wowAbilityId: '331634' as WowAbilityId,
		name: 'Dark Recital',
		iconName: 'ability_warlock_soullink',

		wowBossId: '166971' as WowBossId
	},
	{
		wowAbilityId: '327619' as WowAbilityId,
		name: 'Waltz of Blood',
		iconName: 'ability_rogue_shadowdance',

		wowBossId: '166971' as WowBossId
	},
	{
		isNpc: true,
		wowAbilityId: '169924' as WowAbilityId,
		name: 'Veteran Stoneguard',
		iconName: 'sha_spell_warlock_demonsoul_nightmare',

		wowBossId: '166971' as WowBossId
	},
	{
		isNpc: true,
		wowAbilityId: '175992' as WowAbilityId,
		name: 'Dutiful Attendant',
		iconName: 'sha_spell_warlock_demonsoul_nightmare',

		wowBossId: '166971' as WowBossId
	},

	// Sludgefist
	{
		wowAbilityId: '335300' as WowAbilityId,
		name: 'Chain Link',
		iconName: 'inv_misc_steelweaponchain',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '335297' as WowAbilityId,
		name: 'Giant Fists',
		iconName: 'spell_warlock_demonicservitude',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '331209' as WowAbilityId,
		name: 'Hateful Gaze',
		iconName: 'spell_shadow_spectralsight',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '332318' as WowAbilityId,
		name: 'Destructive Stomp',
		iconName: 'spell_nature_earthquake',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '332572' as WowAbilityId,
		name: 'Falling Rubble',
		iconName: 'shaman_pvp_rockshield',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '332687' as WowAbilityId,
		name: 'Colossal Roar',
		iconName: 'ability_garrosh_hellscreams_warsong',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '341250' as WowAbilityId,
		name: 'Gruesome Rage',
		iconName: 'spell_winston_rage',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '335354' as WowAbilityId,
		name: 'Chain Slam',
		iconName: 'inv_belt_18',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '340803' as WowAbilityId,
		name: 'Seismic Shift',
		iconName: 'inv_misc_volatileearth',

		wowBossId: '174733' as WowBossId
	},
	{
		wowAbilityId: '341102' as WowAbilityId,
		name: 'Fractured Boulder',
		iconName: 'inv_stone_11',

		wowBossId: '174733' as WowBossId
	},

	// Stone Legion
	{
		wowAbilityId: '343063' as WowAbilityId,
		name: 'Stone Spike',
		iconName: 'inv_weapon_shortblade_08',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '339645' as WowAbilityId,
		name: "Stone Breaker's Combo",
		iconName: 'ability_warrior_shatteringthrow',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '334929' as WowAbilityId,
		name: 'Serrated Swipe',
		iconName: 'ability_xavius_nightmareblades',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '334765' as WowAbilityId,
		name: 'Heart Rend',
		iconName: 'spell_fire_flameblades',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '333387' as WowAbilityId,
		name: 'Wicked Blade',
		iconName: 'spell_warrior_wildstrike',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '329636' as WowAbilityId,
		name: 'Hardened Stone Form',
		iconName: 'trade_archaeology_druidprieststatueset',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '342733' as WowAbilityId,
		name: 'Ravenous Feast',
		iconName: 'ability_druid_ferociousbite',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '336231' as WowAbilityId,
		name: 'Cluster Bombardment',
		iconName: 'spell_animarevendreth_missile',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '332393' as WowAbilityId,
		name: 'Anima Orb',
		iconName: 'spell_animarevendreth_orb',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '343086' as WowAbilityId,
		name: 'Ricocheting Shuriken',
		iconName: 'inv_misc_sawblade_01',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '342425' as WowAbilityId,
		name: 'Stone Fist',
		iconName: 'spell_shadow_unholystrength',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '334500' as WowAbilityId,
		name: 'Seismic Upheaval',
		iconName: 'spell_nature_earthquake',

		wowBossId: '165318' as WowBossId
	},
	{
		wowAbilityId: '344500' as WowAbilityId,
		name: 'Reverberating Eruption',
		iconName: 'inv_elementalearth2',

		wowBossId: '165318' as WowBossId
	},

	// Sire
	{
		wowAbilityId: '328936' as WowAbilityId,
		name: 'Inevitable',
		iconName: 'spell_warlock_demonsoul',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '327089' as WowAbilityId,
		name: 'Feeding Time',
		iconName: 'inv_misc_monsterfang_01',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '327227' as WowAbilityId,
		name: 'Command: Ravage',
		iconName: 'spell_shadow_charm',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '326699' as WowAbilityId,
		name: 'Burden of Sin',
		iconName: 'inv_artifact_powerofthedarkside',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '326851' as WowAbilityId,
		name: 'Blood Price',
		iconName: 'ability_ironmaidens_whirlofblood',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '326707' as WowAbilityId,
		name: 'Cleansing Pain',
		iconName: 'spell_animarevendreth_wave',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '328276' as WowAbilityId,
		name: 'March of the Penitent',
		iconName: 'sha_spell_shadow_shadesofdarkness_nightmare',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '329875' as WowAbilityId,
		name: 'Carnage',
		iconName: 'ability_gouge',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '329974' as WowAbilityId,
		name: 'Impale',
		iconName: 'ability_backstab',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '330137' as WowAbilityId,
		name: 'Massacre',
		iconName: 'ability_revendreth_warrior',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '329181' as WowAbilityId,
		name: 'Wracking Pain',
		iconName: 'spell_animarevendreth_wave',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '330627' as WowAbilityId,
		name: 'Hand of Destruction',
		iconName: 'spell_shadow_unholystrength',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '326005' as WowAbilityId,
		name: 'Indignation',
		iconName: 'spell_animarevendreth_nova',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '332797' as WowAbilityId,
		name: 'Fatal Finesse',
		iconName: 'spell_animarevendreth_groundstate',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '333979' as WowAbilityId,
		name: 'Sinister Reflection',
		iconName: 'spell_shadow_charm',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '327796' as WowAbilityId,
		name: 'Night Hunter',
		iconName: 'sha_ability_rogue_bloodyeye_nightmare',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '335873' as WowAbilityId,
		name: 'Rancor',
		iconName: 'ability_warlock_moltencore',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '336162' as WowAbilityId,
		name: 'Crescendo',
		iconName: 'spell_animarevendreth_missile',

		wowBossId: '168938' as WowBossId
	},
	{
		wowAbilityId: '336008' as WowAbilityId,
		name: 'Smoldering Ire',
		iconName: 'spell_animarevendreth_orb',

		wowBossId: '168938' as WowBossId
	}
];

const sanctumOfDomination: BossAbility[] = [
	// Tarragrue
	{
		wowAbilityId: '347668' as WowAbilityId,
		name: 'Grasp of Death',
		iconName: 'spell_animamaw_buff',

		wowBossId: '152253' as WowBossId
	},
	{
		wowAbilityId: '352389' as WowAbilityId,
		name: "Remnant: Mort'regar's Echoes",
		iconName: 'spell_animamaw_nova',

		wowBossId: '152253' as WowBossId
	},
	{
		wowAbilityId: '352398' as WowAbilityId,
		name: 'Remnant: Soulforge Heat',
		iconName: 'ability_mage_fierypayback',

		wowBossId: '152253' as WowBossId
	},
	{
		wowAbilityId: '346985' as WowAbilityId,
		name: 'Overpower',
		iconName: 'ability_titankeeper_titanicsmash',

		wowBossId: '152253' as WowBossId
	},
	{
		wowAbilityId: '352368' as WowAbilityId,
		name: 'Remnant of Forgotten Torments',
		iconName: 'spell_animamaw_groundstate',

		wowBossId: '152253' as WowBossId
	},

	// Eye of the Jailer
	{
		wowAbilityId: '350828' as WowAbilityId,
		name: 'Deathlink',
		iconName: 'ability_felarakkoa_eyeofterrok',

		wowBossId: '179128' as WowBossId
	},
	{
		wowAbilityId: '349028' as WowAbilityId,
		name: 'Titanic Death Gaze',
		iconName: 'ability_argus_deathfog',

		wowBossId: '179128' as WowBossId
	},
	{
		wowAbilityId: '348969' as WowAbilityId,
		name: 'Immediate Extermination',
		iconName: 'ability_blackhand_marked4death',

		wowBossId: '179128' as WowBossId
	},

	// The Nine
	{
		wowAbilityId: '350287' as WowAbilityId,
		name: 'Song of Dissolution',
		iconName: 'spell_animamaw_debuff',

		wowBossId: '178738' as WowBossId
	},
	{
		wowAbilityId: '350039' as WowAbilityId,
		name: "Arthura's Crushing Gaze",
		iconName: 'spell_ice_lament',

		wowBossId: '178738' as WowBossId
	},
	{
		wowAbilityId: '351399' as WowAbilityId,
		name: 'Resentment',
		iconName: 'spell_animamaw_buff',

		wowBossId: '178738' as WowBossId
	},
	{
		wowAbilityId: '350098' as WowAbilityId,
		name: "Aradne's Falling Strike",
		iconName: 'inv_icon_wingbroken05c',

		wowBossId: '178738' as WowBossId
	},
	{
		wowAbilityId: '350541' as WowAbilityId,
		name: 'Fragments of Destiny',
		iconName: 'spell_frost_ice-shards',

		wowBossId: '178738' as WowBossId
	},

	// Soulrender
	{
		wowAbilityId: '350801' as WowAbilityId,
		name: 'Unleashed Tyranny',
		iconName: 'sha_spell_fire_ragnaros_supernova_nightmare',

		wowBossId: '175727' as WowBossId
	},
	{
		wowAbilityId: '348985' as WowAbilityId,
		name: 'Warmonger Shackles',
		iconName: 'inv_misc_steelweaponchain',

		wowBossId: '175727' as WowBossId
	},
	{
		wowAbilityId: '350648' as WowAbilityId,
		name: 'Brand of Torment',
		iconName: 'inv_pet_reliquarysuffering',

		wowBossId: '175727' as WowBossId
	},

	// Remnant
	{
		wowAbilityId: '351066' as WowAbilityId,
		name: 'Shatter',
		iconName: 'achievement_boss_lichking',

		wowBossId: '175729' as WowBossId
	},
	{
		wowAbilityId: '350073' as WowAbilityId,
		name: 'Torment',
		iconName: 'ability_argus_blightorb',

		wowBossId: '175729' as WowBossId
	},
	{
		wowAbilityId: '350469' as WowAbilityId,
		name: 'Malevolence',
		iconName: 'ability_warlock_eradication',

		wowBossId: '175729' as WowBossId
	},

	// Painsmith
	{
		wowAbilityId: '355568' as WowAbilityId,
		name: 'Cruciform Axe',
		iconName: 'inv_axe_2h_mawraid_diff',

		wowBossId: '176523' as WowBossId
	},
	{
		wowAbilityId: '348508' as WowAbilityId,
		name: 'Reverberating Hammer',
		iconName: 'inv_mace_2h_maw_c_01',

		wowBossId: '176523' as WowBossId
	},
	{
		wowAbilityId: '355778' as WowAbilityId,
		name: 'Dualblade Scythe',
		iconName: 'inv_polearm_2h_mawnecromancerboss_d_01_grey',

		wowBossId: '176523' as WowBossId
	},
	{
		wowAbilityId: '355504' as WowAbilityId,
		name: 'Shadowsteel Chains',
		iconName: 'ability_deathknight_deathchain',

		wowBossId: '176523' as WowBossId
	},
	{
		wowAbilityId: '356844' as WowAbilityId,
		name: 'Black Flames',
		iconName: 'ability_rhyolith_magmaflow_wave',

		wowBossId: '176523' as WowBossId
	},
	{
		wowAbilityId: '348456' as WowAbilityId,
		name: 'Flameclasp Trap',
		iconName: 'ability_hunter_steeltrap',

		wowBossId: '176523' as WowBossId
	},

	// Guardian
	{
		wowAbilityId: '352538' as WowAbilityId,
		name: 'Purging Protocol',
		iconName: 'spell_progenitor_areadenial',

		wowBossId: '175731' as WowBossId
	},
	{
		wowAbilityId: '352589' as WowAbilityId,
		name: 'Meltdown',
		iconName: 'spell_progenitor_nova',

		wowBossId: '175731' as WowBossId
	},
	{
		wowAbilityId: '355352' as WowAbilityId,
		name: 'Obliterate',
		iconName: 'spell_progenitor_orb2',

		wowBossId: '175731' as WowBossId
	},
	{
		wowAbilityId: '350735' as WowAbilityId,
		name: 'Elimination Pattern',
		iconName: 'inv_mace_2h_progenitorbroken_d_01',

		wowBossId: '175731' as WowBossId
	},

	// Fatescribe
	{
		wowAbilityId: '351680' as WowAbilityId,
		name: 'Invoke Destiny',
		iconName: 'spell_animamaw_buff',

		wowBossId: '175730' as WowBossId
	},
	{
		wowAbilityId: '354964' as WowAbilityId,
		name: 'Runic Affinity',
		iconName: 'ability_boss_fatescribe_rune4',

		wowBossId: '175730' as WowBossId
	},

	// KT
	{
		wowAbilityId: '356957' as WowAbilityId,
		name: 'Frozen Destruction',
		iconName: 'ability_mage_coldasice',

		wowBossId: '176974' as WowBossId
	},
	{
		wowAbilityId: '348756' as WowAbilityId,
		name: 'Frost Blast',
		iconName: 'spell_frost_glacier',

		wowBossId: '176974' as WowBossId
	},
	{
		wowAbilityId: '359660' as WowAbilityId,
		name: 'Sinister Miasma',
		iconName: 'spell_necro_inevitableend',

		wowBossId: '176974' as WowBossId
	},

	// Sylvanas
	{
		wowAbilityId: '347609' as WowAbilityId,
		name: 'Wailing Arrow',
		iconName: 'ability_theblackarrow',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '349458' as WowAbilityId,
		name: 'Domination Chains',
		iconName: 'inv_belt_44',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '348109' as WowAbilityId,
		name: 'Banshee Wail',
		iconName: 'spell_shadow_soulleech_3',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '355540' as WowAbilityId,
		name: 'Ruin',
		iconName: 'ability_ironmaidens_convulsiveshadows',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '351317' as WowAbilityId,
		name: 'Decrepit Orb',
		iconName: 'spell_animamaw_orb',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '351117' as WowAbilityId,
		name: 'Crushing Dread',
		iconName: 'spell_shadow_gathershadows',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '354147' as WowAbilityId,
		name: 'Raze',
		iconName: 'spell_animamaw_groundstate',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '347704' as WowAbilityId,
		name: 'Veil of Darkness',
		iconName: 'ability_argus_deathfog',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '353955' as WowAbilityId,
		name: 'Banshee Scream',
		iconName: 'ability_warlock_improvedsoulleech',

		wowBossId: '179687' as WowBossId
	},
	{
		wowAbilityId: '354068' as WowAbilityId,
		name: "Banshee's Fury",
		iconName: 'spell_shadow_shadowfury',

		wowBossId: '179687' as WowBossId
	}
];

const sepulcherOfTheFirstOnes: BossAbility[] = [
	// Vigilant Guardian
	{
		wowAbilityId: '360412' as WowAbilityId,
		name: 'Exposed Core',
		iconName: 'inv_radientazeritecore',

		wowBossId: '180773' as WowBossId
	},
	{
		wowAbilityId: '364962' as WowAbilityId,
		name: 'Core Overload',
		iconName: 'spell_fire_selfdestruct',

		wowBossId: '180773' as WowBossId
	},
	{
		wowAbilityId: '364843' as WowAbilityId,
		name: 'Fractured Core',
		iconName: 'inv_achievement_raid_progenitorraid_progenitor_defensewall_boss',

		wowBossId: '180773' as WowBossId
	},
	{
		wowAbilityId: '366822' as WowAbilityId,
		name: 'Radioactive Core',
		iconName: 'inv_achievement_raid_progenitorraid_progenitor_defensewall_boss',

		wowBossId: '180773' as WowBossId
	},

	// Skolex
	{
		wowAbilityId: '359829' as WowAbilityId,
		name: 'Dust Flail',
		iconName: 'ability_butcher_whirl',

		wowBossId: '183937' as WowBossId
	},
	{
		wowAbilityId: '359976' as WowAbilityId,
		name: 'Riftmaw',
		iconName: 'inv_netherportal',

		wowBossId: '183937' as WowBossId
	},
	{
		wowAbilityId: '360448' as WowAbilityId,
		name: 'Retch',
		iconName: 'spell_nature_acid_01',

		wowBossId: '183937' as WowBossId
	},
	{
		wowAbilityId: '359770' as WowAbilityId,
		name: 'Ravening Burrow',
		iconName: 'ability_argus_soulburst',

		wowBossId: '183937' as WowBossId
	},

	// Artificer Xy'mox
	{
		wowAbilityId: '363413' as WowAbilityId,
		name: 'Genesis Rings',
		iconName: 'spell_progenitor_areadenial',

		wowBossId: '183501' as WowBossId
	},
	{
		wowAbilityId: '362801' as WowAbilityId,
		name: 'Glyph of Relocation',
		iconName: 'inv_axe_2h_broker_c_01',

		wowBossId: '183501' as WowBossId
	},
	{
		wowAbilityId: '362849' as WowAbilityId,
		name: 'Hyperlight Sparknova',
		iconName: 'spell_broker_nova',

		wowBossId: '183501' as WowBossId
	},
	{
		wowAbilityId: '363114' as WowAbilityId,
		name: 'Genesis Supernova',
		iconName: 'spell_progenitor_nova',

		wowBossId: '183501' as WowBossId
	},
	{
		wowAbilityId: '362721' as WowAbilityId,
		name: 'Dimensional Tear',
		iconName: 'spell_arcane_portalironforge',

		wowBossId: '183501' as WowBossId
	},
	{
		wowAbilityId: '366752' as WowAbilityId,
		name: 'Continuum Stasis Trap',
		iconName: 'spell_broker_buff',

		wowBossId: '183501' as WowBossId
	},
	{
		wowAbilityId: '364373' as WowAbilityId,
		name: 'Gunship Barrage',
		iconName: 'spell_broker_missile',

		wowBossId: '183501' as WowBossId
	},

	// Dausegne
	{
		wowAbilityId: '361751' as WowAbilityId,
		name: 'Disintegration Halo',
		iconName: 'spell_progenitor_areadenial',

		wowBossId: '181224' as WowBossId
	},
	{
		wowAbilityId: '360959' as WowAbilityId,
		name: 'Staggering Barrage',
		iconName: 'spell_progenitor_beam',

		wowBossId: '181224' as WowBossId
	},
	{
		wowAbilityId: '361966' as WowAbilityId,
		name: 'Infused Strikes',
		iconName: 'spell_progenitor_debuff',

		wowBossId: '181224' as WowBossId
	},
	{
		wowAbilityId: '361722' as WowAbilityId,
		name: 'Inevitable Dominion',
		iconName: 'spell_animamaw_groundstate',

		wowBossId: '181224' as WowBossId
	},
	{
		wowAbilityId: '361967' as WowAbilityId,
		name: 'Unleashed Infusion',
		iconName: 'spell_progenitor_nova',

		wowBossId: '181224' as WowBossId
	},
	{
		wowAbilityId: '361651' as WowAbilityId,
		name: 'Siphoned Barrier',
		iconName: 'inv_inscription_vantusrune_progenitor',

		wowBossId: '181224' as WowBossId
	},
	{
		wowAbilityId: '361513' as WowAbilityId,
		name: 'Obliteration Arc',
		iconName: 'spell_progenitor_missile',

		wowBossId: '181224' as WowBossId
	},

	// Prototype Pantheon
	{
		wowAbilityId: '360295' as WowAbilityId,
		name: 'Necrotic Ritual',
		iconName: 'ability_warlock_cremation',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '360687' as WowAbilityId,
		name: "Runecarver's Deathtouch",
		iconName: 'spell_necro_deathsdoor',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '365272' as WowAbilityId,
		name: 'Humbling Strikes',
		iconName: 'ability_bastion_druid',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '361278' as WowAbilityId,
		name: 'Pinning Volley',
		iconName: 'spell_animabastion_missile',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '365041' as WowAbilityId,
		name: 'Windswept Wings',
		iconName: 'inv_icon_wing06b',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '361305' as WowAbilityId,
		name: 'Wild Stampede',
		iconName: 'spell_druid_wildcharge',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '366234' as WowAbilityId,
		name: 'Animastorm',
		iconName: 'spell_animaardenweald_nova',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '361608' as WowAbilityId,
		name: 'Burden of Sin',
		iconName: 'inv_artifact_powerofthedarkside',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '361788' as WowAbilityId,
		name: 'Hand of Destruction',
		iconName: 'spell_shadow_unholystrength',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '364865' as WowAbilityId,
		name: 'Sinful Projection',
		iconName: 'ability_revendreth_shaman',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '361689' as WowAbilityId,
		name: 'Wracking Pain',
		iconName: 'spell_animarevendreth_wave',

		wowBossId: '181549' as WowBossId
	},
	{
		wowAbilityId: '361568' as WowAbilityId,
		name: 'Withering Seeds',
		iconName: 'ability_ardenweald_mage',

		wowBossId: '181549' as WowBossId
	},

	// Lihuvim
	{
		wowAbilityId: '363018' as WowAbilityId,
		name: 'Protoform Cascade',
		iconName: 'spell_progenitor_nova',

		wowBossId: '182169' as WowBossId
	},
	{
		wowAbilityId: '362624' as WowAbilityId,
		name: 'Manifest Mote',
		iconName: 'spell_progenitor_orb2',

		wowBossId: '182169' as WowBossId
	},
	{
		wowAbilityId: '363676' as WowAbilityId,
		name: 'Deconstructing Energy',
		iconName: 'inv_progenitor_protoformsynthesis',

		wowBossId: '182169' as WowBossId
	},
	{
		wowAbilityId: '363130' as WowAbilityId,
		name: 'Synthesize',
		iconName: 'spell_progenitor_beam',

		wowBossId: '182169' as WowBossId
	},
	{
		wowAbilityId: '363088' as WowAbilityId,
		name: 'Cosmic Shift',
		iconName: 'spell_progenitor_areadenial',

		wowBossId: '182169' as WowBossId
	},

	// Halondrus
	{
		wowAbilityId: '367079' as WowAbilityId,
		name: 'Seismic Tremors',
		iconName: 'spell_sandexplosion',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '360114' as WowAbilityId,
		name: 'Ephemeral Fissure',
		iconName: 'inv_enchant_dustvision',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '361597' as WowAbilityId,
		name: 'Ephemeral Eruption',
		iconName: 'spell_broker_nova',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '359235' as WowAbilityId,
		name: 'Reclamation Form',
		iconName: 'inv_achievement_raid_progenitorraid_progenium_keeper',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '368965' as WowAbilityId,
		name: 'Eternity Engine',
		iconName: 'inv_trinket_progenitorraid_01_yellow',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '359236' as WowAbilityId,
		name: 'Relocation Form',
		iconName: 'inv_progenitorbotminemount',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '362129' as WowAbilityId,
		name: 'Quaking Steps',
		iconName: 'inv_misc_volatileearth',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '362056' as WowAbilityId,
		name: 'Shatter',
		iconName: 'spell_progenitor_nova',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '369207' as WowAbilityId,
		name: 'Planetcracker Beam',
		iconName: 'spell_shaman_earthquake',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '360115' as WowAbilityId,
		name: 'Reclaim',
		iconName: 'ability_skyreach_wind',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '365294' as WowAbilityId,
		name: 'Crushing Prism',
		iconName: 'inv_progenitor_runevessel',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '368529' as WowAbilityId,
		name: 'Eternity Overdrive',
		iconName: 'inv_trinket_progenitorraid_01_orange',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '359720' as WowAbilityId,
		name: 'Lightshatter Beam',
		iconName: 'spell_progenitor_beam',

		wowBossId: '184915' as WowBossId
	},
	{
		wowAbilityId: '361676' as WowAbilityId,
		name: 'Earthbreaker Missiles',
		iconName: 'inv_misc_missilesmallcluster_yellow',

		wowBossId: '184915' as WowBossId
	},

	// Anduin
	{
		wowAbilityId: '362405' as WowAbilityId,
		name: 'Kingsmourne Hungers',
		iconName: 'ability_deathknight_hungeringruneblade',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '365030' as WowAbilityId,
		name: 'Wicked Star',
		iconName: 'spell_priest_divinestar_shadow2',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '365293' as WowAbilityId,
		name: 'Befouled Barrier',
		iconName: 'inv_soulbarrier',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '361989' as WowAbilityId,
		name: 'Blasphemy',
		iconName: 'ability_priest_focusedwill',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '361815' as WowAbilityId,
		name: 'Hopebreaker',
		iconName: 'spell_animamaw_debuff',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '365805' as WowAbilityId,
		name: 'Empowered Hopebreaker',
		iconName: 'inv_sword_1h_artifactruneblade_d_01',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '362543' as WowAbilityId,
		name: 'Remorseless Winter',
		iconName: 'ability_deathknight_shroudofwinter',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '362862' as WowAbilityId,
		name: 'Army of the Dead',
		iconName: 'spell_deathknight_armyofthedead',

		wowBossId: '181954' as WowBossId
	},
	{
		wowAbilityId: '362505' as WowAbilityId,
		name: "Domination's Grasp",
		iconName: 'spell_animamaw_buff',

		wowBossId: '181954' as WowBossId
	},

	// Lords of Dread
	{
		wowAbilityId: '360417' as WowAbilityId,
		name: 'Infiltration of Dread',
		iconName: 'ability_rogue_dirtydeeds',

		wowBossId: '26533' as WowBossId
	},
	{
		wowAbilityId: '360319' as WowAbilityId,
		name: 'Unto Darkness',
		iconName: 'spell_nature_insect_swarm2',

		wowBossId: '26533' as WowBossId
	},
	{
		wowAbilityId: '360006' as WowAbilityId,
		name: 'Cloud of Carrion',
		iconName: 'spell_shadow_carrionswarm',

		wowBossId: '26533' as WowBossId
	},
	{
		wowAbilityId: '361923' as WowAbilityId,
		name: 'Ravenous Hunger',
		iconName: 'spell_shadow_devouringplague',

		wowBossId: '26533' as WowBossId
	},
	{
		wowAbilityId: '360300' as WowAbilityId,
		name: 'Swarm of Decay',
		iconName: 'spell_nature_naturetouchdecay',

		wowBossId: '26533' as WowBossId
	},
	{
		wowAbilityId: '360304' as WowAbilityId,
		name: 'Swarm of Darkness',
		iconName: 'ability_demonhunter_darkness',

		wowBossId: '26533' as WowBossId
	},

	// Rygelon
	{
		wowAbilityId: '362390' as WowAbilityId,
		name: 'Manifest Cosmos',
		iconName: 'inv_elemental_primal_mana',

		wowBossId: '182777' as WowBossId
	},
	{
		wowAbilityId: '364386' as WowAbilityId,
		name: 'Gravitational Collapse',
		iconName: 'sha_spell_warlock_demonsoul',

		wowBossId: '182777' as WowBossId
	},
	{
		wowAbilityId: '361548' as WowAbilityId,
		name: 'Dark Eclipse',
		iconName: 'spell_animamaw_debuff',

		wowBossId: '182777' as WowBossId
	},
	{
		wowAbilityId: '361463' as WowAbilityId,
		name: 'Dark Collapse',
		iconName: 'spell_animamaw_groundstate',

		wowBossId: '182777' as WowBossId
	},
	{
		wowAbilityId: '362207' as WowAbilityId,
		name: 'The Singularity',
		iconName: 'ability_argus_blightorb',

		wowBossId: '182777' as WowBossId
	},

	// Jailed
	{
		wowAbilityId: '362058' as WowAbilityId,
		name: 'Relentless Domination',
		iconName: 'inv_cape_special_domination_c_01',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '362415' as WowAbilityId,
		name: 'Torment',
		iconName: 'ability_creature_cursed_05',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '362631' as WowAbilityId,
		name: 'Chains of Oppression',
		iconName: 'inv_misc_steelweaponchain',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '362617' as WowAbilityId,
		name: 'Oppression',
		iconName: 'spell_animamaw_wave',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '362189' as WowAbilityId,
		name: 'Misery',
		iconName: 'warrior_talent_icon_bloodandthunder',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '362194' as WowAbilityId,
		name: 'Suffering',
		iconName: 'inv_mace_1h_stratholme_d_02',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '360282' as WowAbilityId,
		name: 'Rune of Damnation',
		iconName: 'ability_domination_rune09',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '366665' as WowAbilityId,
		name: 'Unholy Eruption',
		iconName: 'spell_animamaw_nova',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '360565' as WowAbilityId,
		name: 'Decimator',
		iconName: 'inv_misc_bone_skull_02',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '366285' as WowAbilityId,
		name: 'Rune of Compulsion',
		iconName: 'ability_domination_rune08',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '365033' as WowAbilityId,
		name: 'Desolation',
		iconName: 'spell_animamaw_missile',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '365219' as WowAbilityId,
		name: 'Chains of Anguish',
		iconName: 'ability_demonhunter_infernalchains',

		wowBossId: '185421' as WowBossId
	},
	{
		wowAbilityId: '365224' as WowAbilityId,
		name: 'Chain Breaker',
		iconName: 'spell_animamaw_wave',

		wowBossId: '185421' as WowBossId
	}
];

const bossAbilities: BossAbility[] = [
	...castleNathria,
	...sanctumOfDomination,
	...sepulcherOfTheFirstOnes
].sort((a, b) => (a.name > b.name ? 1 : -1));

// detect duplicates
// if (process.env.NODE_ENV === 'development') {
// 	bossAbilities.forEach((ability) => {
// 		const same = bossAbilities.filter((x) => x.wowAbilityId === ability.wowAbilityId);
// 		if (same.length > 1) {
// 			window.alert(`Duplicate ability: ${ability.wowAbilityId}`);
// 		}
// 	});
// }

// Convert array to be a Map keyed by the ability ID
export const bossAbilityById: Map<WowAbilityId, BossAbility> = new Map<
	WowAbilityId,
	BossAbility
>(
	bossAbilities.map((ability): [WowAbilityId, BossAbility] => [
		ability.wowAbilityId,
		ability
	])
);

export const bossAbilitiesByWowBossId = groupByProperty(bossAbilities, 'wowBossId');
