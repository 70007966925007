import React from 'react';

import type {Classes} from '@constants/wow';

import type {DroptimizerReportDuck} from '@ducks';

import type {ISelectOption} from '../Utils/SelectInput';
import {Image} from '../Utils/Image';
import type * as LootContainer from './LootContainer';
import Item from './Item/Item';

interface ItemGroupProps {
	onSelect(charId: number, wowItemId: string, optionId?: number): Promise<void>;
	onUpdateNote(charId: number, wowItemId: string, note: string | null): Promise<void>;
	// onCoin(wowBossId: string, isCoining: boolean): void;
	onSearch(id: number): void;

	characterDroptimizerItemByWowItemId: DroptimizerReportDuck.ItemByWowItemId | undefined;
	characterClass: Classes | undefined;
	characterId?: number;

	selectedRoles: string[];
	/** Filter Wants to just the selected loot option */
	selectedLootOptionIds: LootOptionId[];

	itemGroupId: string;
	itemGroupName: string;
	itemGroupImageUrl: string | undefined;

	isShowingDroptimizerRelativeGain: boolean;
	isAllowedToEdit: boolean;

	wowItemIds: WowItemId[];
	itemMap: LootContainer.ILootViewItemByWowItemId;
	optionOptions: ISelectOption[];
}

export class ItemGroup extends React.Component<ItemGroupProps> {
	// handleCoin = (isSwitched: boolean) => {
	// 	this.props.onCoin(this.props.boss.id, isSwitched);
	// };

	// renderViewCoin() {
	// 	if (!this.props.boss.isCharacterCoining) return false;

	// 	return (
	// 		<div className="coin-control view">
	// 			Intends to use a coin
	// 			<i className="lnr lnr-checkmark-circle" />
	// 		</div>
	// 	);
	// }

	// renderCoin() {
	// 	if (!this.props.isAllowedToEdit) return this.renderViewCoin();

	// 	return (
	// 		<div className="coin-control edit">
	// 			<Switch
	// 				onChange={this.handleCoin}
	// 				isControlled={true}
	// 				label="Using a coin on this boss"
	// 				value={this.props.boss.isCharacterCoining}
	// 			/>
	// 		</div>
	// 	);
	// }

	render() {
		// don't show the group if there's no items
		if (!this.props.wowItemIds.length) return null;

		// has droptimizer data if any of the items have droptimizer info
		const characterHasDroptimizerData = this.props.wowItemIds.some((wowItemId) => {
			return !!this.props.characterDroptimizerItemByWowItemId?.[wowItemId];
		});

		const items = this.props.wowItemIds.map((wowItemId) => {
			return (
				<Item
					key={wowItemId}
					onUpdateNote={this.props.onUpdateNote}
					onSelect={this.props.onSelect}
					onSearch={this.props.onSearch}
					isShowingDroptimizerRelativeGain={
						this.props.isShowingDroptimizerRelativeGain
					}
					isAllowedToEdit={this.props.isAllowedToEdit}
					selectedRoles={this.props.selectedRoles}
					selectedLootOptionIds={this.props.selectedLootOptionIds}
					optionOptions={this.props.optionOptions}
					characterHasDroptimizerData={characterHasDroptimizerData}
					droptimizerItemData={
						this.props.characterDroptimizerItemByWowItemId?.[wowItemId]
					}
					characterId={this.props.characterId}
					characterClass={this.props.characterClass}
					item={this.props.itemMap[wowItemId]}
				/>
			);
		});

		return (
			<div className="boss">
				<div className="header">
					<div className="boss-info">
						{this.props.itemGroupImageUrl && (
							<Image src={this.props.itemGroupImageUrl} />
						)}

						<div className="name">{this.props.itemGroupName}</div>
					</div>

					{/* {this.props.characterId && this.renderCoin()} */}
				</div>

				<div className="items">{items}</div>
			</div>
		);
	}
}
