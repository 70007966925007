import React from 'react';

import {connect, RosterBossDuck} from '@ducks';

import {PageHeadingDetails} from '../../../Utils/PageHeading';

interface OwnProps {
	rosterBossId: RosterBossId;
	bossName: string;
}

interface MapProps {
	rosteredCount: number;
}

type Props = OwnProps & MapProps;

export const BossDetailsComp: React.FC<Props> = (props) => {
	return (
		<PageHeadingDetails
			heading={props.bossName}
			subheading={`${props.rosteredCount} rostered`}
		/>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const roleAssignments = RosterBossDuck.getAssignmentsForBoss(
		state.rosterBosses,
		props.rosterBossId
	);

	// count the total number of assignments for the boss
	const rosteredCount = Object.values(roleAssignments).reduce<number>(
		(count, assignments) => {
			return count + assignments.length;
		},
		0
	);

	return {
		rosteredCount
	};
}

export const BossDetails = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(BossDetailsComp);
