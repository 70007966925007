import React from 'react';

import type {RaidItem} from '../../../models/raid-data';

import {generateWowheadLinkData} from '../../Loot/Item/Item';

interface IProps {
	wowheadBonus: string;
	optionName: string;
	note: string | null;
	item: RaidItem;
}

export const LootItemRow: React.FC<IProps> = (props) => {
	const linkData = generateWowheadLinkData(props.item.id, props.wowheadBonus);

	return (
		<div className="loot-item-row">
			<div className="left-col">
				<div className="item-info">
					<a href={linkData.url} rel="noopener noreferrer" target="_blank">
						<img src={`/static/item-icons/${props.item.id}`} />

						<div className="name">{props.item.name}</div>
					</a>
				</div>

				<div className="selection-column">{props.optionName}</div>

				{props.note && <div className="note">{props.note}</div>}
			</div>
		</div>
	);
};
