import type * as tstb from 'ts-toolbelt';

export enum ProductTypes {
	SINGLE = 'single',
	MULTIPLE = 'multiple'
}

export enum Frequency {
	MONTHLY = 'monthly'
}

export const ProductTypeLabels: Record<ProductTypes, string> = {
	[ProductTypes.SINGLE]: 'Single guild',
	[ProductTypes.MULTIPLE]: 'Multiple guilds'
};

export const ProductTypeButtonLabels: Record<ProductTypes, string> = {
	[ProductTypes.SINGLE]: 'Buff a single guild',
	[ProductTypes.MULTIPLE]: 'Buff multiple guilds'
};

export const ProductTypeDescriptions: Record<ProductTypes, string> = {
	[ProductTypes.SINGLE]: 'Includes all the perks below',
	[ProductTypes.MULTIPLE]: 'Includes all the perks below'
};

export const FrequencyDisplay: Record<Frequency, string> = {
	[Frequency.MONTHLY]: 'Monthly'
};

interface IStripePlanIds {
	SINGLE_MONTHLY: string;

	MULTIPLE_MONTHLY: string;
}

export const DevStripePlanIds: IStripePlanIds = {
	SINGLE_MONTHLY: 'early-bird',

	MULTIPLE_MONTHLY: 'price_1IqImmBzbeGvlZidhu06D4lX'
};

export const ProdStripePlanIds: IStripePlanIds = {
	SINGLE_MONTHLY: 'early-bird',

	MULTIPLE_MONTHLY: 'price_1IuGmQBzbeGvlZidZx8ZbUk1'
};

export function getStripePlanIds(): IStripePlanIds {
	return process.env.NODE_ENV === 'production' ? ProdStripePlanIds : DevStripePlanIds;
}

export function getValidStripePlanIds(): string[] {
	const ids = getStripePlanIds();
	return Object.values(ids);
}

const planIds = getStripePlanIds();

export interface IPricingData {
	id: string;
	type: ProductTypes;
	frequency: Frequency;

	priceText: string;
	priceInCents: number;

	canBuffGuilds: boolean;
	maxBuffedGuilds: number;
}

type ListOfIds = tstb.Union.ListOf<keyof IStripePlanIds>;
type NumberOfIds = tstb.List.Length<ListOfIds>;
type PricingDataArray = ReadonlyTupleOf<IPricingData, NumberOfIds>;

export const pricingData: PricingDataArray = [
	{
		id: planIds.SINGLE_MONTHLY,
		type: ProductTypes.SINGLE,
		frequency: Frequency.MONTHLY,

		priceText: '$4.99',
		priceInCents: 499,

		canBuffGuilds: true,
		maxBuffedGuilds: 1
	},

	{
		id: planIds.MULTIPLE_MONTHLY,
		type: ProductTypes.MULTIPLE,
		frequency: Frequency.MONTHLY,

		priceText: '$9.99',
		priceInCents: 999,

		canBuffGuilds: true,
		maxBuffedGuilds: 5
	}
];

const pricingDataByStripePriceId: Map<string, IPricingData> = new Map<
	string,
	IPricingData
>(pricingData.map((data) => [data.id, data]));

export function getPricingDataForStripePriceId(id: string): IPricingData {
	// need to ensure that we always have a record of all the plans
	return pricingDataByStripePriceId.get(id)!;
}
