import type {
	SubmitDroptimizerReportInput,
	SubmitDroptimizerReportResult,
	DeleteDroptimizerReportInput,
	DeleteDroptimizerReportResult
} from 'api-types';

import {DroptimizerReportDuck, GuildDuck, BannerDuck, Rpc} from '@ducks';

import api from '../../helpers/api';

import type {DroptimizerReportId} from '@models/droptimizerReport';

/** Create a new DroptimizerReport */
export function submitDroptimizerReport(data: {reportUrl: string}): Thunk<void> {
	return (dispatchEvent, getState) => {
		const state = getState();
		const guildId = GuildDuck.getActiveGuildId(state.guilds);
		if (!guildId) return Promise.resolve();

		dispatchEvent<DroptimizerReportDuck.Actions['SUBMIT']>({
			type: DroptimizerReportDuck.SUBMIT
		});

		return api
			.call<SubmitDroptimizerReportInput, SubmitDroptimizerReportResult>(
				Rpc.DROPTIMIZER_SUBMIT,
				{
					guildId,
					reportUrl: data.reportUrl
				}
			)
			.then(
				() => {
					dispatchEvent<DroptimizerReportDuck.Actions['SUBMIT_SUCCESS']>({
						type: DroptimizerReportDuck.SUBMIT_SUCCESS
					});
					dispatchEvent(
						BannerDuck.addSuccessBanner('Droptimizer report submitted successfully')
					);
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
					dispatchEvent<DroptimizerReportDuck.Actions['SUBMIT_FAILURE']>({
						type: DroptimizerReportDuck.SUBMIT_FAILURE
					});
				}
			);
	};
}

/** Delete a new DroptimizerReport */
export function deleteDroptimizerReport(data: {id: DroptimizerReportId}): Thunk<void> {
	return (dispatchEvent) => {
		return api
			.call<DeleteDroptimizerReportInput, DeleteDroptimizerReportResult>(
				Rpc.DROPTIMIZER_DELETE,
				{id: data.id}
			)
			.then(
				() => {
					dispatchEvent(
						BannerDuck.addSuccessBanner('Droptimizer report deleted successfully')
					);
				},

				(message) => {
					dispatchEvent(BannerDuck.addErrorBanner(message.error));
				}
			);
	};
}
