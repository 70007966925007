import React from 'react';

import * as wow from '../../constants/wow';

import Modal from '../Utils/Modal';

interface IProps {
	onClick(region: string): void;
	onClose(): void;

	isCacheBust: boolean;
}

export const RegionPicker: React.FC<IProps> = (props) => {
	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content auth-region-picker">
				<div className="heading">Select region</div>

				<div className="regions">
					<div
						onClick={() => props.onClick(props.isCacheBust ? 'us-sc2' : 'us')}
						className="large primary button"
					>
						{wow.REGIONS.us}
					</div>

					<div
						onClick={() => props.onClick(props.isCacheBust ? 'eu-sc2' : 'eu')}
						className="large primary button"
					>
						{wow.REGIONS.eu}
					</div>
				</div>

				<div onClick={props.onClose} className="underlined link button cancel-button">
					cancel
				</div>
			</div>
		</Modal>
	);
};
