import React from 'react';
import {CgNotes} from 'react-icons/cg';
import classnames from 'classnames';

import type * as LootContainer from '../LootContainer';
import {TimeAgo} from '@components/Utils/TimeAgo';

interface IProps {
	onCharacterClick(e: React.MouseEvent<HTMLDivElement>, characterId: number): void;

	isShowingDroptimizerRelativeGain: boolean;

	/** Filter Wants to just the selected loot option */
	selectedLootOptionIds: LootOptionId[];
	wantedBy: LootContainer.IItemWant[];
}

export const Characters: React.FC<IProps> = (props) => {
	const chars = props.wantedBy.map((want) => {
		// if there's loot option filtering going on, possibly filter this Want
		if (
			props.selectedLootOptionIds.length &&
			!props.selectedLootOptionIds.includes(want.optionId)
		) {
			return null;
		}

		const tooltip = composeTooltip({
			selectionPerformedAt: want.selectionPerformedAt,
			note: want.note
		});

		const containerClass = classnames(
			{
				'tooltip tooltip-medium tooltip-user-content tooltip-top delayed': !!tooltip
			},
			'character-box'
		);

		const contentClass = classnames(
			'box-content',
			'wow-style bg font-colour',
			want.characterClass
		);

		return (
			<div
				key={want.characterId}
				onClick={(e) => props.onCharacterClick(e, want.characterId)}
				className={containerClass}
				data-hint={tooltip || undefined}
			>
				<div className={contentClass}>
					<div className="char-name">
						{!props.isShowingDroptimizerRelativeGain &&
							want.droptimizerItemData && (
								<span className="dps">
									{want.droptimizerItemData.displayAbsoluteDpsGain}
								</span>
							)}
						{props.isShowingDroptimizerRelativeGain && want.droptimizerItemData && (
							<span className="dps">
								{want.droptimizerItemData.displayRelativeDpsGain}
							</span>
						)}

						{want.characterName}
					</div>

					<div className="bottom-row">
						{!!want.note && <CgNotes className="note-icon" />}

						<div className="want-label">{want.optionName}</div>
					</div>
				</div>
			</div>
		);
	});

	return <div className="characters">{chars}</div>;
};

function composeTooltip(data: {
	note: string | null;
	selectionPerformedAt: Date | null;
}): string | undefined {
	const chunks: string[] = [];

	if (data.note) chunks.push(data.note);

	if (data.selectionPerformedAt) {
		chunks.push(`Selected ${TimeAgo.getDate(data.selectionPerformedAt.getTime())}`);
	}

	return chunks.join('\n\n').trim() || undefined;
}
