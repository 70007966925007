import {Role} from '@constants/wow';

import {connect, RosterBossDuck, CharacterDuck} from '@ducks';

import {BuffTypes, BUFF_DATA_BY_CLASS} from './data';

import type {RosterBoss} from '@models/roster-boss';
import type {Character} from '@models/character';

import {BuffSectionComp} from './BuffsSection';

type AssignedCharactersByRole = Record<Role, Character[]>;

interface BuffContributor {
	character: Character;
	abilityName: string;
	isTalent: boolean;
}

export interface Buff {
	type: BuffTypes;
	isAvailable: boolean;
	count: number;

	contributors: BuffContributor[];
}

interface OwnProps {
	rosterBoss: RosterBoss;
}

interface MapProps {
	buffByType: Map<BuffTypes, Buff>;
}

export type Props = OwnProps & MapProps;

function getCharacters(
	characterState: IRootState['characters'],
	characterIds: CharacterId[]
): Character[] {
	return characterIds
		.map((charId) => CharacterDuck.getCharacterForId(characterState, charId))
		.filter((x): x is Character => !!x);
}

function getAssignedCharactersByRole(
	rosterBossState: IRootState['rosterBosses'],
	characterState: IRootState['characters'],
	rosterBossId: RosterBossId
): AssignedCharactersByRole {
	const assignedCharacterIdsByRole = RosterBossDuck.getAssignmentsForBoss(
		rosterBossState,
		rosterBossId
	);

	return {
		[Role.HEALER]: getCharacters(
			characterState,
			assignedCharacterIdsByRole[Role.HEALER]
		),
		[Role.MELEE]: getCharacters(characterState, assignedCharacterIdsByRole[Role.MELEE]),
		[Role.RANGED]: getCharacters(
			characterState,
			assignedCharacterIdsByRole[Role.RANGED]
		),
		[Role.TANK]: getCharacters(characterState, assignedCharacterIdsByRole[Role.TANK])
	};
}

function getBuffs(
	assignedCharactersByRole: AssignedCharactersByRole
): Map<BuffTypes, Buff> {
	const contributorsByBuff: Map<BuffTypes, BuffContributor[]> = new Map(
		Object.values(BuffTypes).map((buff) => [buff, []])
	);

	Object.entries(assignedCharactersByRole).forEach(([role, characters]) => {
		characters.forEach((character) => {
			const typedBuffAbilities = BUFF_DATA_BY_CLASS.get(character.class)?.get(
				role as Role
			);
			if (!typedBuffAbilities) return;

			typedBuffAbilities.forEach((typedBuffAbility) => {
				contributorsByBuff.set(typedBuffAbility.type, [
					...(contributorsByBuff.get(typedBuffAbility.type) || []),
					{
						abilityName: typedBuffAbility.buffAbility.abilityName,
						isTalent: typedBuffAbility.buffAbility.isTalent,
						character
					}
				]);
			});
		});
	});

	const buffEntries = [...contributorsByBuff.entries()].map(
		([type, contributors]): [BuffTypes, Buff] => {
			return [
				type,
				{
					type,
					isAvailable: !!contributors.length,
					count: contributors.length,
					contributors
				}
			];
		}
	);

	return new Map<BuffTypes, Buff>(buffEntries);
}

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const assignedCharactersByRole = getAssignedCharactersByRole(
		state.rosterBosses,
		state.characters,
		props.rosterBoss.id
	);

	return {
		buffByType: getBuffs(assignedCharactersByRole)
	};
}

export const BuffsSection = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(BuffSectionComp);
