import React from 'react';

interface BuffGroupProps {
	sectionName: string;
}

export const BuffGroup: React.FC<BuffGroupProps> = (props) => {
	return (
		<div className="buff-group">
			<div className="title">{props.sectionName}</div>

			<div className="content">{props.children}</div>
		</div>
	);
};
