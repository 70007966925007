import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router';

import {Image} from '../../Utils/Image';

interface Props {
	isSelected: boolean;

	bossUrl: string;
	bossImageUrl: string;
	bossName: string;
}

export const BossTile: React.FC<Props> = (props) => {
	const tileClass = classnames(
		{
			selected: props.isSelected,
			unselected: !props.isSelected
		},
		'boss-selector-tile delayed tooltip tooltip-bottom'
	);

	return (
		<Link to={props.bossUrl} className={tileClass} data-hint={props.bossName}>
			<Image src={props.bossImageUrl} />
		</Link>
	);
};
