import * as feed from '../../constants/feed';

import * as GuildDuck from '../guild';
import * as TagDuck from '.';
import type {RootAction} from '..';

import {TagAssignment, ITagAssignmentClass} from '../../models/tag-assignment';
import {Tag} from '../../models/tag';

const initialState: TagDuck.IState = {
	assignmentsByTagId: {},
	tagById: {},

	isSubmitting: false,
	isDeleting: false
};

function insertOrUpdateAssignment(state: TagDuck.IState, newAss: TagAssignment) {
	let assignments: TagAssignment[] = state.assignmentsByTagId[newAss.tagId] || [];

	// filter out the old assignment
	assignments = assignments.filter(
		(x) => x.characterId !== newAss.characterId || x.tagId !== newAss.tagId
	);

	return {
		...state.assignmentsByTagId,

		// add it into the list and re-set the list
		[newAss.tagId]: [...assignments, newAss]
	};
}

function deleteAssignment(state: TagDuck.IState, oldAss: ITagAssignmentClass) {
	let assignments: TagAssignment[] = state.assignmentsByTagId[oldAss.tagId];

	// ezpz exit if there's no selections for whatever reason
	if (!assignments || !assignments.length) return state.assignmentsByTagId;

	// filter out the old assignment
	assignments = assignments.filter(
		(x) => x.characterId !== oldAss.characterId || x.tagId !== oldAss.tagId
	);

	return {
		...state.assignmentsByTagId,
		[oldAss.tagId]: assignments
	};
}

export default function reducer(
	state = initialState,
	action: RootAction
): TagDuck.IState {
	switch (action.type) {
		// guild payload
		case GuildDuck.FETCH_DATA_SUCCESS: {
			const assignmentsByTagId: {[key: number]: TagAssignment[]} = {};
			action.payload.tagAssignments.forEach((x) => {
				if (!assignmentsByTagId[x.tagId]) {
					assignmentsByTagId[x.tagId] = [];
				}

				assignmentsByTagId[x.tagId].push(x);
			});

			const tagById: {[key: number]: Tag} = {};
			action.payload.tags.forEach((x) => {
				tagById[x.id] = x;
			});

			return {
				...state,

				// remove data from other guilds
				assignmentsByTagId,
				tagById
			};
		}

		// update / create tag
		case TagDuck.CREATE:
		case TagDuck.UPDATE: {
			return {...state, isSubmitting: true};
		}

		case TagDuck.CREATE_SUCCESS:
		case TagDuck.CREATE_FAILURE:
		case TagDuck.UPDATE_SUCCESS:
		case TagDuck.UPDATE_FAILURE: {
			return {...state, isSubmitting: false};
		}

		// delete options
		case TagDuck.DELETE: {
			return {...state, isDeleting: true};
		}

		case TagDuck.DELETE_SUCCESS:
		case TagDuck.DELETE_FAILURE: {
			return {...state, isDeleting: false};
		}

		// reorder options
		case TagDuck.REORDER: {
			return {
				...state,

				tagById: {
					...state.tagById,
					[action.payload.tag.id]: action.payload.tag
				}
			};
		}

		// make item selection
		case TagDuck.ASSIGNMENT: {
			return {
				...state,
				assignmentsByTagId: insertOrUpdateAssignment(state, action.payload.assignment)
			};
		}

		case TagDuck.ASSIGNMENT_REMOVE: {
			return {
				...state,
				assignmentsByTagId: deleteAssignment(state, action.payload.assignment)
			};
		}

		// feed - options
		case feed.TAG_INSERT:
		case feed.TAG_UPDATE: {
			return {
				...state,

				tagById: {
					...state.tagById,
					[action.payload.newRecord.id]: new Tag(action.payload.newRecord)
				}
			};
		}

		case feed.TAG_DELETE: {
			const tagById: {[key: number]: Tag} = {};
			Object.values(state.tagById).forEach((tag) => {
				if (tag.id === action.payload.oldRecord.id) return;

				tagById[tag.id] = tag;
			});

			return {
				...state,
				tagById
			};
		}

		// feed - selections
		case feed.TAG_ASSIGNMENT_INSERT:
		case feed.TAG_ASSIGNMENT_UPDATE: {
			const assignment = new TagAssignment(action.payload.newRecord);

			return {
				...state,
				assignmentsByTagId: insertOrUpdateAssignment(state, assignment)
			};
		}

		case feed.TAG_ASSIGNMENT_DELETE: {
			return {
				...state,
				assignmentsByTagId: deleteAssignment(state, action.payload.oldRecord)
			};
		}

		default:
			return state;
	}
}
