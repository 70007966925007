import React from 'react';

import {BillingStatus, BillingStatusDisplay} from '@constants';

import {
	ProductTypeLabels,
	ProductTypes,
	FrequencyDisplay,
	Frequency,
	getPricingDataForStripePriceId
} from '@shared/billing/plans';

import type {ActiveUserBilling} from '@models/userBilling';

import {ConfirmModal} from '../../Utils/ConfirmModal';

interface IFieldProps {
	fieldName: string;
	value: string;
}

const Field: React.FC<IFieldProps> = (props) => {
	return (
		<div className="field">
			<div className="field-name">{props.fieldName}</div>
			<div className="field-value">{props.value}</div>
		</div>
	);
};

interface ISectionProps {
	heading: string;

	type: ProductTypes;
	status: BillingStatus;
	frequency: Frequency;
	priceText: string;
}

const Section: React.FC<ISectionProps> = (props) => {
	return (
		<div className="section">
			<div className="section-heading">{props.heading}</div>

			<Field fieldName="Plan type" value={ProductTypeLabels[props.type]} />
			<Field fieldName="Status" value={BillingStatusDisplay[props.status]} />
			<Field fieldName="Price" value={`${props.priceText} USD`} />
			<Field fieldName="Frequency" value={FrequencyDisplay[props.frequency]} />
		</div>
	);
};

interface IProps {
	onClose(): void;
	onSubmit(): void;

	isSubmitting: boolean;

	billing: ActiveUserBilling;
	newStripePriceId: string;
}

export const ChangePlanModal: React.FC<IProps> = (props) => {
	const currentPricingData = getPricingDataForStripePriceId(props.billing.stripePriceId);
	const newPricingData = getPricingDataForStripePriceId(props.newStripePriceId);

	return (
		<ConfirmModal
			onSubmit={props.onSubmit}
			onClose={props.onClose}
			isSubmitting={props.isSubmitting}
			modalClassName="change-plan-modal"
			heading="Confirm plan switch"
			submitButtonText="Switch plans"
			submitColour="primary"
		>
			<Section
				heading="Current plan"
				status={props.billing.status}
				type={currentPricingData.type}
				frequency={currentPricingData.frequency}
				priceText={currentPricingData.priceText}
			/>

			<i className="change-arrow lnr lnr-arrow-right" />

			<Section
				heading="New plan"
				status={BillingStatus.ACTIVE}
				type={newPricingData.type}
				frequency={newPricingData.frequency}
				priceText={newPricingData.priceText}
			/>
		</ConfirmModal>
	);
};
