import React from 'react';

import {ROLES_ORDERED} from '../../constants/wow';

import {connect, SettingsDuck} from '@ducks';

import type {IChangesByRole} from './RosterView';
import Switch from '../Utils/Switch';
import Modal from '../Utils/Modal';

interface IOwnProps {
	onClose(): void;
	changes: IChangesByRole;
}

interface IMapProps {
	formatter: string | undefined;
	isCsv: boolean;
}

interface IDispatchProps {
	onFormatterUpdate: BoundThunk<typeof SettingsDuck['setExportChangesFormatter']>;
	onToggleCsv: BoundThunk<typeof SettingsDuck['setExportCharactersAsCsv']>;
}

type IProps = IOwnProps & IMapProps & IDispatchProps;

interface IState {
	formatter: string;
}

class CopyChangesModalComp extends React.Component<IProps, IState> {
	state: IState;

	constructor(props: IProps) {
		super(props);

		this.state = {
			formatter:
				this.props.formatter === undefined
					? '/rw IN: {{added}} OUT: {{removed}}'
					: this.props.formatter
		};
	}

	handleFormatterUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({formatter: e.target.value});
		this.props.onFormatterUpdate(e.target.value);
	};

	getNames() {
		const added: string[] = [];
		const removed: string[] = [];

		ROLES_ORDERED.forEach((role) => {
			const changes = this.props.changes[role];
			if (!changes) return;

			changes.forEach((change) => {
				if (change.change === 'added') added.push(change.name);
				if (change.change === 'removed') removed.push(change.name);
			});
		});

		return {added, removed};
	}

	render() {
		const {added, removed} = this.getNames();

		const addedNewline = added.join('\n');
		const addedCsv = added.join(', ');

		const removedNewline = removed.join('\n');
		const removedCsv = removed.join(', ');

		return (
			<Modal onClose={this.props.onClose}>
				<div className="modal-content copy-changes-modal">
					<div className="heading">Rostered character changes</div>

					<div className="inputs">
						<Switch
							onChange={(isCsv) => this.props.onToggleCsv(isCsv)}
							isControlled={true}
							label="Separate names with a comma"
							value={this.props.isCsv}
						/>

						<label>Added</label>
						<textarea
							className="input"
							value={this.props.isCsv ? addedCsv : addedNewline}
							autoFocus={true}
							readOnly={true}
						/>

						<label>Removed</label>
						<textarea
							className="input"
							value={this.props.isCsv ? removedCsv : removedNewline}
							readOnly={true}
						/>

						<label>Formatted</label>
						<p>
							Type any text you want (e.g. a raid warning),{' '}
							<code>{'{{added}}'}</code> and <code>{'{{removed}}'}</code> will be
							replaced with comma seperated names of the characters from above
						</p>
						<textarea
							onChange={this.handleFormatterUpdate}
							className="input"
							value={this.state.formatter}
						/>
						<textarea
							className="input"
							value={this.state.formatter
								.replace(/{{added}}/gi, addedCsv)
								.replace(/{{removed}}/gi, removedCsv)}
							readOnly={true}
						/>
					</div>
				</div>
			</Modal>
		);
	}
}

function mapStateToProps(state: IRootState): IMapProps {
	return {
		isCsv: SettingsDuck.getExportCharactersAsCsv(state.settings),
		formatter: SettingsDuck.getExportChangesFormatter(state.settings)
	};
}

export const CopyChangesModal = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		onFormatterUpdate: SettingsDuck.setExportChangesFormatter,
		onToggleCsv: SettingsDuck.setExportCharactersAsCsv
	}
)(CopyChangesModalComp);
