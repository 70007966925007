import React from 'react';

import {useModal} from '@hooks/useModal';

import {ActionsMenu, MenuItem} from '@components/Utils/Menu';
import {CopyRosteredModal} from './CopyRosteredModal';
import {ClearBossModal} from './ClearBossModal';
import {CopyChangesModal} from './CopyChangesModal';
import {CopyAssignmentsToBosses} from './CopyAssignmentsToBossesModal';

interface Props {
	onUnrosterAll(): Promise<void>;

	isAdmin: boolean;
	isDemo: boolean;

	rosterBossId: RosterBossId;
	rosterId: RosterId;
	bossName: string;
}

export const Actions: React.FC<Props> = (props) => {
	const removeAllAssignmentsModal = useModal();
	const copyRosteredNamesModal = useModal();
	const copyChangesModal = useModal();
	const copyAssignmentsToBosses = useModal();

	return (
		<>
			{removeAllAssignmentsModal.isOpen && (
				<ClearBossModal
					onSubmit={props.onUnrosterAll}
					onClose={removeAllAssignmentsModal.close}
					bossName={props.bossName}
				/>
			)}

			{copyRosteredNamesModal.isOpen && (
				<CopyRosteredModal
					onClose={copyRosteredNamesModal.close}
					rosterBossId={props.rosterBossId}
				/>
			)}

			{copyChangesModal.isOpen && (
				<CopyChangesModal
					onClose={copyChangesModal.close}
					rosterBossId={props.rosterBossId}
				/>
			)}

			{copyAssignmentsToBosses.isOpen && (
				<CopyAssignmentsToBosses
					onClose={copyAssignmentsToBosses.close}
					currentRosterBossId={props.rosterBossId}
					rosterId={props.rosterId}
				/>
			)}

			<ActionsMenu>
				{props.isAdmin && (
					<MenuItem onClick={removeAllAssignmentsModal.open}>
						Remove all assignments from boss
					</MenuItem>
				)}

				<MenuItem onClick={copyRosteredNamesModal.open}>
					Copy names of rostered characters
				</MenuItem>

				<MenuItem onClick={copyChangesModal.open}>
					Copy rostered character changes
				</MenuItem>

				{!props.isDemo && props.isAdmin && (
					<MenuItem onClick={copyAssignmentsToBosses.open}>
						Copy assignments to another boss
					</MenuItem>
				)}
			</ActionsMenu>
		</>
	);
};
