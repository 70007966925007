import React from 'react';

import type {LootDuck} from '@ducks';

import {BrandName} from '../../Utils/BrandName';
import {SubmitControls} from './SubmitControls';
import {NoRows} from './NoRows';
import {Entry} from '../Entry/Entry';

import type {IOwnProps, IMapProps} from './index';

type IProps = IOwnProps & IMapProps;

/** <RowId, LootOptionId> */
type NewSelectionByRowId = Record<string, number | undefined>;

interface IState {
	newSelectionByRowId: NewSelectionByRowId;
}

export class EntriesComponent extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const newSelectionByRowId: NewSelectionByRowId = {};
		props.rows.forEach((row) => {
			if (row.item && row.character) {
				newSelectionByRowId[row.id] = undefined;
			}
		});

		this.state = {
			newSelectionByRowId
		};
	}

	handleSubmit = () => {
		const rclcSelections = Object.entries(this.state.newSelectionByRowId).reduce<
			LootDuck.IRclcSelection[]
		>((carry, [id, optionId]) => {
			const row = this.props.rows.find((x) => x.id === id);

			// this shouldn't happen as it means that the rows have changed since import
			if (!row) return carry;

			// if there wasn't an original option in the first place then skip the entry
			// as RC is already in the desired state
			if (!row.originalSelectedLootOption) return carry;

			// can't submit rows for missing characters/items
			if (!row.character || !row.item) return carry;

			const rclcSelection: LootDuck.IRclcSelection = {
				characterId: row.character.id,
				difficulty: row.difficulty,
				wowItemId: row.item.id,
				optionId
			};

			return [...carry, rclcSelection];
		}, []);

		this.props.onSubmit(rclcSelections);
	};

	handleItemSelection = (rowId: string, optionId: number | undefined): void => {
		this.setState({
			newSelectionByRowId: {
				...this.state.newSelectionByRowId,
				[rowId]: optionId
			}
		});
	};

	render() {
		const rows = this.props.rows.map((row) => (
			<Entry
				key={row.id}
				onItemSelection={this.handleItemSelection}
				id={row.id}
				rclcSelectionText={row.rclcSelectionText}
				wowheadBonus={row.wowheadBonus}
				item={row.item}
				character={row.character}
				selection={row.selection}
				originalSelectedLootOption={row.originalSelectedLootOption}
				selectedLootOptionId={this.state.newSelectionByRowId[row.id]}
				lootSelectionOptions={this.props.lootSelectionOptions}
			/>
		));

		const hasRows = !!rows.length;

		return (
			<div className="entries">
				<div className="intro-section">
					<h1>Set new loot preferences</h1>

					{hasRows ? (
						<>
							<p>
								You may make changes to people's <BrandName /> loot preferences as
								a result of this RCLC import.{' '}
								<b>
									By default existing preferences will be removed on submission
								</b>{' '}
								as the player has received the item, but you may override this by
								selecting a different loot option via the dropdown.
							</p>

							<SubmitControls
								onSubmit={this.handleSubmit}
								onCancel={this.props.onCancel}
								isSubmitting={this.props.isSubmitting}
							/>
						</>
					) : (
						<NoRows onCancel={this.props.onCancel} doneUrl={this.props.doneUrl} />
					)}
				</div>

				{hasRows && <div className="entry-rows">{rows}</div>}

				{hasRows && (
					<SubmitControls
						onSubmit={this.handleSubmit}
						onCancel={undefined}
						isSubmitting={this.props.isSubmitting}
					/>
				)}
			</div>
		);
	}
}
