import React from 'react';

import type {BossViewType} from '@constants';

import {router} from '@helpers/urls';

import {
	connect,
	RosterBossDuck,
	UserDuck,
	CooldownSheetDuck,
	CooldownEventDuck,
	CooldownActionDuck,
	GuildSponsorshipDuck,
	GuildDuck
} from '@ducks';

import type {RaidInstance} from '@models/raid-data';
import type {Roster} from '@models/roster';

import {RosterHeading} from '../RosterHeading/RosterHeading';
import {BossSelector} from '../BossSelector';
import {CharacterView} from '../CharacterView';
import {BossView} from '../BossView';

interface IOwnProps {
	onFetchCooldownSheet: BoundThunk<
		typeof CooldownSheetDuck['fetchCooldownSheetForRosterBoss']
	>;
	onCreateCooldownEvent: BoundThunk<typeof CooldownEventDuck['createCooldownEvent']>;
	onUpdateCooldownEventName: BoundThunk<
		typeof CooldownEventDuck['updateNameCooldownEvent']
	>;
	onUpdateCooldownEventTime: BoundThunk<
		typeof CooldownEventDuck['updateTimeCooldownEvent']
	>;
	onReorderCooldownEvent: BoundThunk<typeof CooldownEventDuck['reorderCooldownEvent']>;
	onDeleteCooldownEvent: BoundThunk<typeof CooldownEventDuck['deleteCooldownEvent']>;
	onCreateCooldownAction: BoundThunk<typeof CooldownActionDuck['createCooldownAction']>;
	onUpdateCooldownActionActor: BoundThunk<
		typeof CooldownActionDuck['updateActorCooldownAction']
	>;
	onUpdateCooldownActionAbility: BoundThunk<
		typeof CooldownActionDuck['updateAbilityCooldownAction']
	>;
	onDeleteCooldownAction: BoundThunk<typeof CooldownActionDuck['deleteCooldownAction']>;
	onUpdateNotes: BoundThunk<typeof RosterBossDuck.updateNotes>;
	onUpdateLimit: BoundThunk<typeof RosterBossDuck.updateLimit>;
	onUnrosterAll: BoundThunk<typeof RosterBossDuck['unrosterAll']>;
	onRoster: BoundThunk<typeof RosterBossDuck.rosterCharacter>;
	onEnterSettings: typeof UserDuck['setUrl'];
	generateBossUrl(rosterBossId: RosterBossId): string;

	isUpdatingNotes: boolean;
	isAdmin: boolean;
	isDemo: boolean;

	rosterSettingsUrl: string;
	rosterReorderUrl: string;

	routerRosterBossId: RosterBossId | null;
	raidInstance: RaidInstance;
	roster: Roster;
}

interface IMapProps {
	canUseAssignmentsView: boolean;

	initialBossView: BossViewType;
	selectedRosterBossId: RosterBossId | null;
}

interface IDispatchProps extends EmptyObject {}

type IProps = IOwnProps & IMapProps & IDispatchProps;

const RosterPageComp: React.FC<IProps> = (props) => {
	const [searchedCharacterId, setSearchedCharacterId] =
		React.useState<CharacterId | null>(null);

	function handleNavigateToRoster(): void {
		props.onEnterSettings(window.location.href.slice(window.location.origin.length));
		router.push(props.rosterSettingsUrl);
	}

	function handleNavigateToRosterReorder(): void {
		props.onEnterSettings(window.location.href.slice(window.location.origin.length));
		router.push(props.rosterReorderUrl);
	}

	return (
		<div className="roster-view-new">
			<RosterHeading
				onSearchCharacter={(charId) => setSearchedCharacterId(charId)}
				onNavigateToRoster={handleNavigateToRoster}
				onNavigateToRosterReorder={handleNavigateToRosterReorder}
				isAdmin={props.isAdmin}
				isDemo={props.isDemo}
				canUseAssignmentsView={props.canUseAssignmentsView}
				difficulty={props.roster.difficulty}
				rosterName={props.roster.name}
				searchedCharacterId={searchedCharacterId}
				guildId={props.roster.guildId}
				rosterId={props.roster.id}
			/>

			{searchedCharacterId ? (
				<CharacterView
					raidInstance={props.raidInstance}
					characterId={searchedCharacterId}
					rosterId={props.roster.id}
				/>
			) : (
				<>
					<BossSelector
						generateBossUrl={props.generateBossUrl}
						selectedRosterBossId={props.selectedRosterBossId}
						raidInstance={props.raidInstance}
						rosterId={props.roster.id}
					/>

					{props.selectedRosterBossId && (
						<div className="page-content">
							<BossView
								onFetchCooldownSheet={props.onFetchCooldownSheet}
								onCreateCooldownEvent={props.onCreateCooldownEvent}
								onUpdateCooldownEventName={props.onUpdateCooldownEventName}
								onUpdateCooldownEventTime={props.onUpdateCooldownEventTime}
								onReorderCooldownEvent={props.onReorderCooldownEvent}
								onDeleteCooldownEvent={props.onDeleteCooldownEvent}
								onCreateCooldownAction={props.onCreateCooldownAction}
								onUpdateCooldownActionActor={props.onUpdateCooldownActionActor}
								onUpdateCooldownActionAbility={
									props.onUpdateCooldownActionAbility
								}
								onDeleteCooldownAction={props.onDeleteCooldownAction}
								onSearchCharacter={(charId) => setSearchedCharacterId(charId)}
								onUpdateNotes={props.onUpdateNotes}
								onUpdateLimit={props.onUpdateLimit}
								onUnrosterAll={props.onUnrosterAll}
								onRoster={props.onRoster}
								isUpdatingNotes={props.isUpdatingNotes}
								isAdmin={props.isAdmin}
								isDemo={props.isDemo}
								initialBossView={props.initialBossView}
								difficulty={props.roster.difficulty}
								raidInstance={props.raidInstance}
								rosterBossId={props.selectedRosterBossId}
								includedTagIds={props.roster.tagWhitelist}
								excludedTagIds={props.roster.tagBlacklist}
								rosterId={props.roster.id}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	const selectedRosterBossId =
		props.routerRosterBossId ||
		RosterBossDuck.getBossesForRoster(state.rosterBosses, props.roster.id)[0]?.id;

	const guild = GuildDuck.getActiveGuildNew(state.guilds);

	const fc = GuildSponsorshipDuck.createFcForGuildId(
		state.guildSponsorships,
		state.guilds,
		guild.id
	);

	const rosterViewInfo = guild.getRosterViewInfo({
		featureChecker: fc,
		isAdmin: props.isAdmin
	});

	return {
		canUseAssignmentsView: rosterViewInfo.canUseAssignmentsView,

		initialBossView: rosterViewInfo.initialBossView,
		selectedRosterBossId
	};
}

export const RosterPage = connect<IMapProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{}
)(RosterPageComp);
