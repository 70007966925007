import React from 'react';

import {Modal} from './Modal';
import {Button} from './Button';

interface IProps {
	onClose(this: void): void;
	onSubmit(this: void): void;

	isSubmitting: boolean;

	modalClassName?: string;
	heading: string;

	submitColour: 'red' | 'primary';
	submitButtonText: string;

	closeButtonText?: string;
}

export const ConfirmModal: React.FC<IProps> = (props) => {
	return (
		<Modal onClose={props.onClose}>
			<div className={`modal-content ${props.modalClassName}`}>
				<div className="heading">{props.heading}</div>

				<div className="modal-inner-content description">{props.children}</div>

				<div className="actions">
					<Button
						onClick={props.onClose}
						isDisabled={props.isSubmitting}
						className="grey"
					>
						{props.closeButtonText || 'Back'}
					</Button>

					<Button
						onClick={props.onSubmit}
						isLoading={props.isSubmitting}
						className={props.submitColour}
					>
						{props.submitButtonText}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
