import React from 'react';

import {connect, RosterBossDuck, SettingsDuck} from '@ducks';

import {getRoleChanges} from '../../ChangesSection';

import {SwitchField} from '@components/Utils/Switch';
import {Modal} from '@components/Utils/Modal';

interface OwnProps {
	onClose(): void;

	rosterBossId: RosterBossId;
}

interface MapProps {
	formatter: string | undefined;
	isCsv: boolean;

	addedNames: string[];
	removedNames: string[];
}

interface DispatchProps {
	onFormatterUpdate: BoundThunk<typeof SettingsDuck['setExportChangesFormatter']>;
	onToggleCsv: BoundThunk<typeof SettingsDuck['setExportCharactersAsCsv']>;
}

type Props = OwnProps & MapProps & DispatchProps;

const CopyChangesModalComp: React.FC<Props> = (props) => {
	const [formatter, setFormatter] = React.useState(() => {
		return props.formatter === undefined
			? '/rw IN: {{added}} OUT: {{removed}}'
			: props.formatter;
	});

	const addedNewline = props.addedNames.join('\n');
	const addedCsv = props.addedNames.join(', ');

	const removedNewline = props.removedNames.join('\n');
	const removedCsv = props.removedNames.join(', ');

	function handleFormatterUpdate(e: React.ChangeEvent<HTMLTextAreaElement>) {
		setFormatter(e.target.value);
		props.onFormatterUpdate(e.target.value);
	}

	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content copy-changes-modal">
				<div className="heading">Rostered character changes</div>

				<div className="inputs">
					<SwitchField
						onChange={(isCsv) => props.onToggleCsv(isCsv)}
						isControlled={true}
						label="Separate names with a comma"
						value={props.isCsv}
					/>

					<label>Added</label>
					<textarea
						className="input"
						value={props.isCsv ? addedCsv : addedNewline}
						autoFocus={true}
						readOnly={true}
					/>

					<label>Removed</label>
					<textarea
						className="input"
						value={props.isCsv ? removedCsv : removedNewline}
						readOnly={true}
					/>

					<label>Formatted</label>
					<p>
						Type any text you want (e.g. a raid warning), <code>{'{{added}}'}</code>{' '}
						and <code>{'{{removed}}'}</code> will be replaced with comma seperated
						names of the characters from above
					</p>
					<textarea
						onChange={handleFormatterUpdate}
						className="input"
						value={formatter}
					/>
					<textarea
						className="input"
						value={formatter
							.replace(/{{added}}/gi, addedCsv)
							.replace(/{{removed}}/gi, removedCsv)}
						readOnly={true}
					/>
				</div>
			</div>
		</Modal>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const rosterBoss = RosterBossDuck.getForId(state.rosterBosses, props.rosterBossId);

	const roleChanges = rosterBoss
		? getRoleChanges(
				state.rosterBosses,
				state.characters,
				rosterBoss.rosterId,
				props.rosterBossId
		  )
		: [];

	const addedNames: string[] = [];
	const removedNames: string[] = [];

	roleChanges.forEach(({changes}) => {
		changes.forEach((change) => {
			if (change.change === 'added') addedNames.push(change.character.name);
			else removedNames.push(change.character.name);
		});
	});

	return {
		formatter: SettingsDuck.getExportChangesFormatter(state.settings),
		isCsv: SettingsDuck.getExportCharactersAsCsv(state.settings),

		addedNames,
		removedNames
	};
}

export const CopyChangesModal = connect<MapProps, DispatchProps, OwnProps>(
	mapStateToProps,
	{
		onFormatterUpdate: SettingsDuck.setExportChangesFormatter,
		onToggleCsv: SettingsDuck.setExportCharactersAsCsv
	}
)(CopyChangesModalComp);
