import React from 'react';
import {AiOutlineSortAscending, AiOutlineUsergroupAdd} from 'react-icons/ai';
import {BsArrowLeftRight} from 'react-icons/bs';
import {GiAura} from 'react-icons/gi';

import {connect, SettingsDuck} from '@ducks';

import {Toggle} from './Toggle';

interface OwnProps {
	isAdmin: boolean;
}

interface MapProps {
	isShowingAssignmentControls: boolean;
	isShowingBuffsSection: boolean;
	isShowingChanges: boolean;
	isAlphabeticalSort: boolean;
}

interface DispatchProps {
	onToggleAssignmentControlsSection: BoundThunk<
		typeof SettingsDuck.setRosterIsShowingAdminControls
	>;
	onToggleBuffsSection: BoundThunk<
		typeof SettingsDuck['setRosterIsShowingBuffsSection']
	>;
	onToggleChangesSection: BoundThunk<typeof SettingsDuck.setRosterIsShowingChanges>;
	onToggleAlphabeticalSort: BoundThunk<typeof SettingsDuck.setRosterIsAlphabeticalSort>;
}

type Props = OwnProps & MapProps & DispatchProps;

const SectionTogglesComp: React.FC<Props> = (props) => {
	return (
		<div className="section-toggles">
			{props.isAdmin && (
				<Toggle
					onToggle={() =>
						props.onToggleAssignmentControlsSection(
							!props.isShowingAssignmentControls
						)
					}
					isActive={props.isShowingAssignmentControls}
					tooltipOn="Hide modify assignments controls"
					tooltipOff="Show the controls used to modify which characters are assigned to the boss"
				>
					<AiOutlineUsergroupAdd />
				</Toggle>
			)}

			<Toggle
				onToggle={() => props.onToggleBuffsSection(!props.isShowingBuffsSection)}
				isActive={props.isShowingBuffsSection}
				tooltipOn="Hide buffs/utilities/cooldowns"
				tooltipOff="Show the buffs/utilities/cooldowns available to your raid"
			>
				<GiAura />
			</Toggle>

			<Toggle
				onToggle={() => props.onToggleChangesSection(!props.isShowingChanges)}
				isActive={props.isShowingChanges}
				tooltipOn="Hide changes between bosses"
				tooltipOff="Show which characters need to be added or removed between each boss"
			>
				<BsArrowLeftRight />
			</Toggle>

			<Toggle
				onToggle={() => props.onToggleAlphabeticalSort(!props.isAlphabeticalSort)}
				isActive={props.isAlphabeticalSort}
				tooltipOn="Sort assignments by character class and name"
				tooltipOff="Sort assignments by character name"
			>
				<AiOutlineSortAscending />
			</Toggle>
		</div>
	);
};

function mapStateToProps(state: IRootState): MapProps {
	return {
		isShowingAssignmentControls: SettingsDuck.getRosterIsShowingAdminControls(
			state.settings
		),
		isShowingBuffsSection: SettingsDuck.getRosterIsShowingBuffsSection(state.settings),
		isShowingChanges: SettingsDuck.getRosterIsShowingChanges(state.settings),
		isAlphabeticalSort: SettingsDuck.getRosterIsAlphabeticalSort(state.settings)
	};
}

export const SectionToggles = connect<MapProps, DispatchProps, OwnProps>(
	mapStateToProps,
	{
		onToggleAssignmentControlsSection: SettingsDuck.setRosterIsShowingAdminControls,
		onToggleBuffsSection: SettingsDuck.setRosterIsShowingBuffsSection,
		onToggleChangesSection: SettingsDuck.setRosterIsShowingChanges,
		onToggleAlphabeticalSort: SettingsDuck.setRosterIsAlphabeticalSort
	}
)(SectionTogglesComp);
