/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-template */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export function loadScript() {
	(function(c,l,a,r,i,t,y){
		c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
		t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
		y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
	})(window, document, "clarity", "script", process.env.CLARITY_CODE);
}

/* eslint-enable unicorn/prefer-query-selector */
/* eslint-enable @typescript-eslint/restrict-plus-operands */
/* eslint-enable prefer-template */
/* eslint-enable prefer-rest-params */
/* eslint-enable func-names */
