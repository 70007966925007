import React from 'react';
import classnames from 'classnames';

import {Button} from '../../../Utils/Button';

interface ISponsorProps {
	onUnsponsor(): void;
	onSponsor(): void;

	/** Whether more guilds can be sponsored, some plans have limits */
	canSponsorMore: boolean;
	/** Whether the guild is sponsored */
	isSponsored: boolean;

	/** A current sponsor request is in progress */
	isSponsoring: boolean;
	/** A current unsponsor request is in progress */
	isUnsponsoring: boolean;
}

const Sponsor: React.FC<ISponsorProps> = (props) => {
	if (props.isSponsored) {
		return (
			<Button
				onClick={props.onUnsponsor}
				isLoading={props.isUnsponsoring}
				className="underlined link"
			>
				Un-buff guild
			</Button>
		);
	}

	const wrapperClass = classnames({
		'tooltip tooltip-top tooltip-large': !props.canSponsorMore
	});

	return (
		<div
			className={wrapperClass}
			data-hint="You've reached your maximum number of buffs - Un-buff a guild before buffing this one"
		>
			<Button
				onClick={props.onSponsor}
				isLoading={props.isSponsoring}
				isDisabled={!props.canSponsorMore}
				className="primary"
			>
				Apply buff to guild
			</Button>
		</div>
	);
};

interface IProps {
	onUnsponsor(guildId: number): void;
	onSponsor(guildId: number): void;

	/** Whether more guilds can be sponsored, some plans have limits */
	canSponsorMore: boolean;
	/** Whether the guild is sponsored */
	isSponsored: boolean;

	/** A current sponsor request is in progress */
	isSponsoring: boolean;
	/** A current unsponsor request is in progress */
	isUnsponsoring: boolean;

	guildId: number;
	guildName: string;
	guildRealm: string;
}

export const SponsorshipItem: React.FC<IProps> = (props) => {
	return (
		<div className="buff-item">
			{props.isSponsored && <div className="buff-tag">Buffed</div>}

			<div className="details">
				<div className="guild-details">
					<span className="name">{props.guildName}</span>
					<span className="realm">{props.guildRealm}</span>
				</div>
			</div>

			<Sponsor
				onUnsponsor={() => props.onUnsponsor(props.guildId)}
				onSponsor={() => props.onSponsor(props.guildId)}
				isSponsored={props.isSponsored}
				canSponsorMore={props.canSponsorMore}
				isSponsoring={props.isSponsoring}
				isUnsponsoring={props.isUnsponsoring}
			/>
		</div>
	);
};
