import type {ProductTypes} from '../../shared/billing/plans';
import type {BillingStatus} from '@constants';

export interface IGuildSponsorshipClass {
	readonly id: number;
	readonly userBillingId: number;
	readonly guildId: number;

	readonly createdAt: string;

	// info about the guild being sponsored
	readonly guildName: string;
	readonly guildRealm: string;

	/** Which feature set this sponsorship enables */
	readonly productType: ProductTypes | null;

	/** Status of the billing that's providing this sponsorship */
	readonly status: BillingStatus | null;
}

export class GuildSponsorship implements IGuildSponsorshipClass {
	readonly id: number;
	readonly userBillingId: number;
	readonly guildId: number;

	readonly createdAt: string;

	// info about the guild being sponsored
	readonly guildName: string;
	readonly guildRealm: string;

	/** Which feature set this sponsorship enables */
	readonly productType: ProductTypes | null;

	/** Status of the billing that's providing this sponsorship */
	readonly status: BillingStatus | null;

	constructor(data: IGuildSponsorshipClass) {
		this.id = data.id;
		this.userBillingId = data.userBillingId;
		this.guildId = data.guildId;

		this.createdAt = data.createdAt;

		this.guildName = data.guildName;
		this.guildRealm = data.guildRealm;

		this.productType = data.productType;

		this.status = data.status;
	}
}
