import type {RaidItemSlots} from '@constants/wow';

import {getBossImageUrl} from '@helpers/images';

import {getSlotItemGroups} from './getSlotItemGroups';
import {getBosses} from './getBosses';

import type {RaidInstance} from '@models/raid-data';
import type {Roster} from '@models/roster';

export interface ItemGroup {
	slots: RaidItemSlots[];

	/** RaidBossId (not RosterBoss) OR Slots */
	itemGroupId: string;

	/** Boss name OR slot name */
	itemGroupName: string;

	/** Boss image url OR Slot image url */
	itemGroupImageUrl: string | undefined;

	wowItemIds: WowItemId[];
}

/** Returns a set of ItemGroups that could represent either Slots or Bosses */
export function getItemGroups(data: {
	rosterBossesState: IRootState['rosterBosses'];
	isGroupingBySlot: boolean;
	selectedRosterId: number | undefined;
	raidInstances: RaidInstance[];
	rosters: Roster[];
}): Map<ItemGroup['itemGroupId'], ItemGroup> {
	if (data.isGroupingBySlot) return getSlotItemGroups();

	const bossItemGroups = new Map<string, ItemGroup>();

	data.raidInstances.forEach((raidInstance) => {
		const orderedWowBosses = getBosses({
			rosterBossesState: data.rosterBossesState,
			raidInstance,
			rosters: data.rosters,
			selectedRosterId: data.selectedRosterId
		});

		// add in each of the bosses
		orderedWowBosses.forEach((boss) => {
			bossItemGroups.set(boss.id, {
				itemGroupId: boss.id,
				itemGroupName: boss.name,
				itemGroupImageUrl: getBossImageUrl(boss.id),

				wowItemIds: [],
				slots: []
			});
		});

		// add in the instance boss
		bossItemGroups.set(raidInstance.id, {
			itemGroupId: raidInstance.id,
			itemGroupName: `${raidInstance.name} BoEs`,
			itemGroupImageUrl: getBossImageUrl(raidInstance.id),

			wowItemIds: [],
			slots: []
		});
	});

	return bossItemGroups;
}
