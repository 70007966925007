import type {CooldownSheetId} from './cooldownSheet';
import type {CooldownEventId} from './cooldownEvent';

export type CooldownActionId = Nominal<number, 'CooldownAction'>;

export interface CooldownActionData {
	readonly id: CooldownActionId;
	readonly cooldownEventId: CooldownEventId;
	readonly cooldownSheetId: CooldownSheetId;
	readonly guildId: GuildId;
	readonly createdByUserId: UserId;

	readonly createdAt: string | Date;
	readonly updatedAt: string | Date;
	readonly editedAt: string | Date | null;

	/**
	 * May be null due to freeform text for actors being allowed to cover "Everyone" and
	 * "Left side" references
	 */
	readonly actorCharacterId: CharacterId | null;
	/**
	 * May either be freeform text or a cached character name
	 *
	 * Data resolved from `actorId` takes priority, but this can act as a fallback if the
	 * character data isn't found
	 */
	readonly actorText: string | null;

	/**
	 * May be null due to freeform text for abilities being allowed to cover actions that
	 * aren't standard abilities as well as things not yet added to the RC data set
	 */
	readonly wowAbilityId: WowAbilityId | null;
	/**
	 * May either be freeform text or a cached ability name
	 *
	 * Data resolved from `wowAbilityId` takes priority, but this can act as a fallback if
	 * the ability data isn't found
	 */
	readonly abilityText: string | null;

	/** Integer ordering */
	readonly order: number;
}

export class CooldownAction
	implements Omit<CooldownActionData, 'createdAt' | 'updatedAt' | 'editedAt'>
{
	readonly id: CooldownActionId;
	readonly cooldownEventId: CooldownEventId;
	readonly cooldownSheetId: CooldownSheetId;
	readonly guildId: GuildId;
	readonly createdByUserId: UserId;

	readonly createdAt: Date;
	readonly updatedAt: Date;
	readonly editedAt: Date | null;

	/**
	 * May be null due to freeform text for actors being allowed to cover "Everyone" and
	 * "Left side" references
	 */
	readonly actorCharacterId: CharacterId | null;
	/**
	 * May either be freeform text or a cached character name
	 *
	 * Data resolved from `actorId` takes priority, but this can act as a fallback if the
	 * character data isn't found
	 */
	readonly actorText: string | null;

	/**
	 * May be null due to freeform text for abilities being allowed to cover actions that
	 * aren't standard abilities as well as things not yet added to the RC data set
	 */
	readonly wowAbilityId: WowAbilityId | null;
	/**
	 * May either be freeform text or a cached ability name
	 *
	 * Data resolved from `wowAbilityId` takes priority, but this can act as a fallback if
	 * the ability data isn't found
	 */
	readonly abilityText: string | null;

	/** Integer ordering */
	readonly order: number;

	constructor(data: CooldownActionData) {
		this.id = data.id;
		this.cooldownEventId = data.cooldownEventId;
		this.cooldownSheetId = data.cooldownSheetId;
		this.guildId = data.guildId;
		this.createdByUserId = data.createdByUserId;

		this.createdAt = new Date(data.createdAt);
		this.updatedAt = new Date(data.updatedAt);
		this.editedAt = data.editedAt ? new Date(data.editedAt) : null;

		this.actorCharacterId = data.actorCharacterId;
		this.actorText = data.actorText;

		this.wowAbilityId = data.wowAbilityId;
		this.abilityText = data.abilityText;

		this.order = data.order;
	}
}
