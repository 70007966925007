import type {Difficulties} from '@constants/wow';

export interface IRosterClass {
	id: number;
	creatorId: number;
	guildId: number;

	isDefault: boolean;
	isArchived: boolean;
	isDeleted: boolean;

	createdAt: string;
	updatedAt: string;

	wowInstanceName: string;
	wowInstanceId: WowInstanceId;
	difficulty: Difficulties;

	name: string;

	tagWhitelist: number[];
	tagBlacklist: number[];

	readonly discordWebhookPretext: string | null;
	readonly discordWebhookUrl: string | null;
	readonly isShowingDiscordSwaps: boolean;
	readonly isShowingDiscordFirstSwapIn: boolean;
}

export class Roster implements IRosterClass {
	readonly id: number;
	readonly creatorId: number;
	readonly guildId: number;

	readonly isDefault: boolean;
	readonly isArchived: boolean;
	readonly isDeleted: boolean;

	readonly createdAt: string;
	readonly updatedAt: string;

	readonly wowInstanceName: string;
	readonly wowInstanceId: WowInstanceId;
	readonly difficulty: Difficulties;

	readonly name: string;

	readonly tagWhitelist: number[];
	readonly tagBlacklist: number[];

	readonly discordWebhookPretext: string | null;
	readonly discordWebhookUrl: string | null;
	readonly isShowingDiscordSwaps: boolean;
	readonly isShowingDiscordFirstSwapIn: boolean;

	constructor(data?: IRosterClass) {
		if (!data) throw new Error('invalid roster data');

		this.id = data.id;
		this.creatorId = data.creatorId;
		this.guildId = data.guildId;

		this.isDefault = data.isDefault;
		this.isArchived = data.isArchived;
		this.isDeleted = data.isDeleted;

		this.createdAt = data.createdAt;
		this.updatedAt = data.updatedAt;

		this.wowInstanceName = data.wowInstanceName;
		this.wowInstanceId = data.wowInstanceId;
		this.difficulty = data.difficulty;

		this.name = data.name;

		this.tagWhitelist = data.tagWhitelist;
		this.tagBlacklist = data.tagBlacklist;

		this.discordWebhookPretext = data.discordWebhookPretext;
		this.discordWebhookUrl = data.discordWebhookUrl;
		this.isShowingDiscordSwaps = data.isShowingDiscordSwaps;
		this.isShowingDiscordFirstSwapIn = data.isShowingDiscordFirstSwapIn;
	}
}
