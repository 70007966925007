import React from 'react';

import type {Props} from '.';
import {RoleGroup} from './RoleGroup';
import {SectionHeading} from '../../../Utils/SectionHeading';

export const CurrentAssignments: React.FC<Props> = (props) => {
	const roleGroupItems = props.roleGroupData.map((data) => (
		<RoleGroup
			key={data.role}
			onUnassignedCharacter={(charId) =>
				props.onRoster({
					bossId: props.rosterBoss.id,
					characterId: charId,
					isActive: false,
					role: data.role
				})
			}
			onSearch={props.onSearchCharacter}
			canRemoveCharacters={props.canRemoveCharacters}
			assignedCharacters={data.assignedCharacters}
			lastUpdatedAt={data.lastUpdatedAt}
			role={data.role}
		/>
	));

	return (
		<div className="section-current-assignments secondary-section">
			<SectionHeading heading="Currently assigned characters" />

			<div className="role-groups">{roleGroupItems}</div>
		</div>
	);
};
