import React from 'react';

import type {Props} from '.';
import {Tabs} from './Tabs';
import {RoleLimit} from './RoleLimit';
import {CharacterRow} from './Character';

export const AssignmentControls: React.FC<Props> = (props) => {
	const limit = props.rosterBoss.roleLimits[props.selectedRole];
	const isLimited = props.assignedCount >= limit;

	const characterItems = props.characterRows.map((data) => (
		<CharacterRow
			key={data.character.id}
			onToggleAssignment={() =>
				props.onRoster({
					bossId: props.rosterBoss.id,
					characterId: data.character.id,
					isActive: !data.isAssigned,
					role: props.selectedRole
				})
			}
			wowheadBonus={props.wowheadBonus}
			character={data.character}
			isAtRoleLimit={isLimited}
			isAssigned={data.isAssigned}
			assignedBossCount={data.assignedBossCount}
			isLootsheetOutdated={data.isLootsheetOutdated}
			lootsheetAgeInDays={data.lootsheetAgeInDays}
			desiredItems={data.desiredItems}
			otherAssignedAltCharacters={data.otherAssignedAltCharacters}
			otherAssignedRole={data.otherAssignedRole}
			isShowingKillCount={props.isShowingKillCount}
			killCount={data.killCount}
			lastKillAt={data.lastKillAt}
		/>
	));

	return (
		<div className="section-assignment-controls">
			<Tabs onSelectRole={props.onSelectRole} selectedRole={props.selectedRole} />

			<div className="tab-content">
				<RoleLimit
					onUpdate={(newLimit) =>
						props.onUpdateLimit({
							bossId: props.rosterBoss.id,
							role: props.selectedRole,
							limit: newLimit
						})
					}
					isLimited={isLimited}
					assignmentCount={props.assignedCount}
					limit={limit}
					roleLabel={props.selectedRole}
				/>

				<div className="character-rows">{characterItems}</div>
			</div>
		</div>
	);
};
