import React from 'react';

import {DelayedFallback} from '../DelayedFallback';

const MarkdownAsync = React.lazy(
	() => import(/* webpackChunkName: "MarkdownAsync" */ './MarkdownInternal')
);

interface IProps {
	content: string;
}

export const Markdown: React.FC<IProps> = (props) => {
	return (
		<React.Suspense fallback={<DelayedFallback />}>
			<MarkdownAsync content={props.content} />
		</React.Suspense>
	);
};
