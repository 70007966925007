import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router';

import {Features} from '../../../shared/billing/features';

import {userNewBillingUrl} from '@helpers/urls';

import {connect, GuildDuck} from '@ducks';

import type {IBillingLandingQuery} from '../Billing/BillingContainer';

interface ILocation {
	pathname: string;
	query: IBillingLandingQuery;
}

interface IOwnProps {
	text?: string;
	feature?: Features;
	className?: string;
}

interface IMapProps {
	guildId?: number;
}

type IProps = IMapProps & IOwnProps;

function BillingLinkComponent(props: IProps) {
	// XXX could handle the `feature` prop too

	const location: ILocation = {
		pathname: userNewBillingUrl(),
		query: {
			guild: `${props.guildId}`
		}
	};

	const linkClass = classnames(props.className, 'billing-link nav-item');

	return (
		<Link to={location} className={linkClass}>
			{props.text || 'Buff your guild'}
		</Link>
	);
}

function mapStateToProps(state: IRootState): IMapProps {
	return {
		guildId: GuildDuck.getActiveGuildId(state.guilds)
	};
}

export const BillingLink = connect<IMapProps, {}, IOwnProps>(
	mapStateToProps,
	{}
)(BillingLinkComponent);

export function createMultipleRostersForInstanceLink(text?: string, className?: string) {
	return (
		<BillingLink
			feature={Features.MULTIPLE_ROSTERS_FOR_INSTANCE}
			className={className}
			text={text}
		/>
	);
}

export function createCustomLootOptionsLink(text?: string, className?: string) {
	return (
		<BillingLink
			feature={Features.CUSTOM_LOOT_OPTIONS}
			className={className}
			text={text}
		/>
	);
}

export function createExternalCharactersLink(text?: string, className?: string) {
	return (
		<BillingLink
			feature={Features.UNLIMITED_EXTERNAL_CHARACTERS}
			className={className}
			text={text}
		/>
	);
}

export function createRclcImportLink(text?: string, className?: string) {
	return (
		<BillingLink feature={Features.RCLC_IMPORT} className={className} text={text} />
	);
}

export function createTags(text?: string, className?: string) {
	return <BillingLink feature={Features.TAGGING} className={className} text={text} />;
}
