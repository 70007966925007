import 'babel-polyfill';

import React from 'react';

if (process.env.NODE_ENV === 'production' && window.Raven) {
	window.Raven.config(
		'https://75ecc2c628044257a69d1628dbae9728@sentry.io/250193'
	).install();

	window.onunhandledrejection = function handlerejection(evt: any) {
		if (window.Raven) window.Raven.captureException(evt.reason);
	};
}

import './styles/base.styl';
import './styles/icon-font.styl';

import configureStore from './store';
export const store = configureStore();
if (process.env.NODE_ENV === 'development') {
	window.store = store;
}

import {syncApiWithStore} from './helpers/api';
syncApiWithStore(store);

import makeRouter from './routes';

import {Provider} from 'react-redux';
import {render} from 'react-dom';

render(
	<Provider store={store}>{makeRouter(store)}</Provider>,
	document.querySelector('#root')
);
