import React from 'react';
import classnames from 'classnames';

import type {Role} from '@constants/wow';

import {useBoolean} from '@hooks/useBoolean';

import type {DroptimizerItem} from '@models/droptimizerReport';
import type {Character} from '@models/character';
import type {RaidItem} from '@models/raid-data';

import {CharacterLoot} from './CharacterLoot';
import {Row} from './CharacterRow/Row';

interface Props {
	onToggleAssignment(): void;

	wowheadBonus: string;
	character: Character;

	/** Whether this role has met it's set limit */
	isAtRoleLimit: boolean;

	/** Whether the character is assigned to this boss */
	isAssigned: boolean;

	/** How many bosses this character has been assigned to in this roster */
	assignedBossCount: number;

	/** Whether the lootsheet should be classes as outdated */
	isLootsheetOutdated: boolean;
	/** The point at which the lootsheet was last updated */
	lootsheetAgeInDays: number;

	/** The lootsheet selections for this character/boss combo */
	desiredItems: Array<{
		note: string | null;
		lootOptionName: string;
		raidItem: RaidItem;
		droptimizerItemData: DroptimizerItem | undefined;
	}>;

	/**
	 * If the character is already assigned to the boss as a different role,
	 * what is that role
	 */
	otherAssignedRole: Role | undefined;

	/** Alts of this character that are assigned to this boss */
	otherAssignedAltCharacters: Array<{
		role: Role;
		character: Character;
	}>;

	/** Whether kill count is enabled for this boss */
	isShowingKillCount: boolean;
	/** How many times this character has killed the boss on this difficulty */
	killCount: number | undefined;
	/** MS of last kill */
	lastKillAt: number | undefined;
}

export const CharacterRow: React.FC<Props> = (props) => {
	const characterClass = classnames({assigned: props.isAssigned}, 'character');
	const expandedItems = useBoolean(false);

	return (
		<div className={characterClass}>
			<Row
				onToggleLoot={expandedItems.toggle}
				onToggleAssignment={props.onToggleAssignment}
				character={props.character}
				isAtRoleLimit={props.isAtRoleLimit}
				isAssigned={props.isAssigned}
				assignedBossCount={props.assignedBossCount}
				isLootsheetOutdated={props.isLootsheetOutdated}
				lootsheetAgeInDays={props.lootsheetAgeInDays}
				desiredItemsCount={props.desiredItems.length}
				otherAssignedAltCharacters={props.otherAssignedAltCharacters}
				otherAssignedRole={props.otherAssignedRole}
				isShowingKillCount={props.isShowingKillCount}
				killCount={props.killCount}
				lastKillAt={props.lastKillAt}
			/>

			{expandedItems.value && !!props.desiredItems.length && (
				<CharacterLoot
					onClose={expandedItems.setFalse}
					wowheadBonus={props.wowheadBonus}
					selections={props.desiredItems}
				/>
			)}
		</div>
	);
};
