import React from 'react';

import {Image} from '@components/Utils/Image';

import image from '../images/view-loot.png';

export const AllViewsDisabled: React.FC = () => {
	return (
		<div className="all-views-disabled">
			<div className="empty-state">
				<Image src={image} />

				<div className="main">All views disabled</div>

				<div className="secondary">
					The admins of your guild have disabled all of the views Readycheck has to
					offer. If you believe this to be a mistake, please reach out to your
					officer team and get them to have a look in Readycheck's Guild Settings.
				</div>
			</div>
		</div>
	);
};
