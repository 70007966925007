import React from 'react';

import type {Difficulties} from '@constants/wow';
import type {BossViewType} from '@constants';

import {connect, RosterBossDuck, SettingsDuck} from '@ducks';

import type {RosterBoss} from '@models/roster-boss';
import type {IRaidBoss} from '@models/raid-data';

import {BossHeading} from './BossHeading';
import {AssignmentControlsSection} from './AssignmentsControlsSection';
import {CurrentAssignments} from './CurrentAssignmentsSection';
import {BuffsSection} from './BuffsSection';
import {ChangesSection} from './ChangesSection';
import {BossView, BossContent} from '../BossElements';

interface OwnProps {
	onUpdateLimit: BoundThunk<typeof RosterBossDuck.updateLimit>;
	onUnrosterAll: BoundThunk<typeof RosterBossDuck['unrosterAll']>;
	onRoster: BoundThunk<typeof RosterBossDuck.rosterCharacter>;
	onSearchCharacter(charId: CharacterId): void;
	onChangeView(view: BossViewType): void;

	viewType: BossViewType;

	/** Whether kill count is enabled for this boss */
	isShowingKillCount: boolean;
	isAdmin: boolean;
	isDemo: boolean;

	rosterBoss: RosterBoss;
	raidBoss: IRaidBoss;
	wowheadBonus: string;
	difficulty: Difficulties;
	includedTagIds: TagId[];
	excludedTagIds: TagId[];
}

interface MapProps {
	isShowingChangesSection: boolean;
	isShowingBuffsSection: boolean;
	isShowingAssignmentControlsSection: boolean;
}

type Props = OwnProps & MapProps;

const BossRosterViewComp: React.FC<Props> = (props) => {
	return (
		<BossView viewName="boss-roster-view">
			<BossHeading
				onChangeView={props.onChangeView}
				onUnrosterAll={() => props.onUnrosterAll(props.rosterBoss.id)}
				viewType={props.viewType}
				isAdmin={props.isAdmin}
				isDemo={props.isDemo}
				rosterBossId={props.rosterBoss.id}
				rosterId={props.rosterBoss.rosterId}
				bossName={props.raidBoss.name}
			/>

			<BossContent
				isBossy={!props.isShowingAssignmentControlsSection}
				wowBossId={props.rosterBoss.bossId}
				leftColumn={() => (
					<AssignmentControlsSection
						onUpdateLimit={props.onUpdateLimit}
						onRoster={props.onRoster}
						isShowingKillCount={props.isShowingKillCount}
						wowEncounterId={props.raidBoss.encounterId}
						wowheadBonus={props.wowheadBonus}
						difficulty={props.difficulty}
						rosterBoss={props.rosterBoss}
						includedTagIds={props.includedTagIds}
						excludedTagIds={props.excludedTagIds}
					/>
				)}
				rightColumn={() => (
					<>
						<CurrentAssignments
							onRoster={props.onRoster}
							onSearchCharacter={props.onSearchCharacter}
							isShowingAssignmentControls={
								props.isShowingAssignmentControlsSection
							}
							rosterBoss={props.rosterBoss}
						/>

						{props.isShowingBuffsSection && (
							<BuffsSection rosterBoss={props.rosterBoss} />
						)}

						{props.isShowingChangesSection && (
							<ChangesSection rosterBoss={props.rosterBoss} />
						)}
					</>
				)}
			/>
		</BossView>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const isShowingBuffsSection = SettingsDuck.getRosterIsShowingBuffsSection(
		state.settings
	);
	const isShowingChangesSection = SettingsDuck.getRosterIsShowingChanges(state.settings);
	const isShowingAssingmentControlsSection =
		SettingsDuck.getRosterIsShowingAdminControls(state.settings);

	return {
		isShowingAssignmentControlsSection:
			props.isAdmin && isShowingAssingmentControlsSection,
		isShowingBuffsSection,
		isShowingChangesSection
	};
}

export const BossRosterView = connect<MapProps, {}, OwnProps>(
	mapStateToProps,
	{}
)(BossRosterViewComp);
