import React from 'react';

import {getRoleImageNewUrl} from '@helpers/images';

import type {Props, BossTile} from '.';

import {Image} from '../../Utils/Image';

const MinimalBoss: React.FC<BossTile> = (props) => {
	return (
		<div className="minimal-boss">
			<Image className="boss-image" src={props.bossImageUrl} />
			<Image className="role-image" src={getRoleImageNewUrl(props.assignedRole)} />

			<div className="name">{props.bossName}</div>
		</div>
	);
};

const ViewWrapper: React.FC = (props) => {
	return <div className="character-view-section">{props.children}</div>;
};

const Message: React.FC = (props) => {
	return <div className="message-text">{props.children}</div>;
};

export const CharacterViewComp: React.FC<Props> = (props) => {
	// Handle no character being found
	if (!props.character) {
		return (
			<ViewWrapper>
				<Message>No matching character found</Message>
			</ViewWrapper>
		);
	}

	// Handle the character not being assigned to anything
	if (!props.bossTiles.length) {
		return (
			<ViewWrapper>
				<Message>
					<strong>{props.character.name}</strong> isn't assigned to any bosses
				</Message>
			</ViewWrapper>
		);
	}

	// Otherwise show the Character's bosses

	const tiles = props.bossTiles.map((bossTile) => (
		<MinimalBoss key={bossTile.rosterBossId} {...bossTile} />
	));

	return (
		<ViewWrapper>
			<Message>
				<strong>{props.character.name}</strong> is assigned to the following{' '}
				{props.bossTiles.length === 1 ? 'boss' : `${props.bossTiles.length} bosses`}
			</Message>

			<div className="boss-tiles">{tiles}</div>
		</ViewWrapper>
	);
};
