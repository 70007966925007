import {routerReducer, RouterState} from 'react-router-redux';
import optimist from 'redux-optimist';
import {combineReducers, bindActionCreators} from 'redux';
import type {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {MapStateToPropsParam, connect as originalConnect} from 'react-redux';

import type {IActions as IFeedActions} from '../constants/feed';

import * as UserDuck from './user';
import * as CharacterDuck from './character';
import * as LootDuck from './loot';
import * as GuildDuck from './guild';
import * as SetupDuck from './setup';
import * as ReimportDuck from './reimport';
import * as RosterDuck from './roster';
import * as RosterBossDuck from './roster-boss';
import * as RaidDataDuck from './raid-data';
import * as UserBillingDuck from './user-billing';
import * as GuildSponsorshipDuck from './guild-sponsorship';
import * as DemoDuck from './demo';
import * as BannerDuck from './banner';
import * as SettingsDuck from './settings';
import * as TagDuck from './tag';
import * as BossKillsDuck from './boss-kills';
import * as CooldownSheetDuck from './cooldownSheet';
import * as CooldownEventDuck from './cooldownEvent';
import * as CooldownActionDuck from './cooldownAction';
import * as DroptimizerReportDuck from './droptimizerReport';

// export all the ducks from a single point
export {
	UserDuck,
	CharacterDuck,
	LootDuck,
	GuildDuck,
	SetupDuck,
	ReimportDuck,
	RosterDuck,
	RosterBossDuck,
	RaidDataDuck,
	UserBillingDuck,
	GuildSponsorshipDuck,
	DemoDuck,
	BannerDuck,
	SettingsDuck,
	TagDuck,
	BossKillsDuck,
	CooldownSheetDuck,
	CooldownEventDuck,
	CooldownActionDuck,
	DroptimizerReportDuck
};

export * as Rpc from '../../shared/constants/rpc';

export type RootAction =
	| IFeedActions[keyof IFeedActions]
	| DemoDuck.IActions[keyof DemoDuck.IActions]
	| UserDuck.IActions[keyof UserDuck.IActions]
	| CharacterDuck.IActions[keyof CharacterDuck.IActions]
	| UserBillingDuck.IActions[keyof UserBillingDuck.IActions]
	| GuildSponsorshipDuck.IActions[keyof GuildSponsorshipDuck.IActions]
	| LootDuck.IActions[keyof LootDuck.IActions]
	| GuildDuck.IActions[keyof GuildDuck.IActions]
	| ReimportDuck.IActions[keyof ReimportDuck.IActions]
	| SetupDuck.IActions[keyof SetupDuck.IActions]
	| RosterBossDuck.IActions[keyof RosterBossDuck.IActions]
	| RosterDuck.IActions[keyof RosterDuck.IActions]
	| TagDuck.IActions[keyof TagDuck.IActions]
	| RaidDataDuck.IActions[keyof RaidDataDuck.IActions]
	| BannerDuck.IActions[keyof BannerDuck.IActions]
	| SettingsDuck.IActions[keyof SettingsDuck.IActions]
	| BossKillsDuck.IActions[keyof BossKillsDuck.IActions]
	| CooldownSheetDuck.Actions[keyof CooldownSheetDuck.Actions]
	| CooldownEventDuck.Actions[keyof CooldownEventDuck.Actions]
	| CooldownActionDuck.Actions[keyof CooldownActionDuck.Actions]
	| DroptimizerReportDuck.Actions[keyof DroptimizerReportDuck.Actions];

declare global {
	interface IRootState {
		user: UserDuck.IState;
		setup: SetupDuck.IState;
		reimport: ReimportDuck.IState;

		loot: LootDuck.IState;

		tag: TagDuck.IState;

		characters: CharacterDuck.IState;
		userBilling: UserBillingDuck.IState;
		guildSponsorships: GuildSponsorshipDuck.IState;
		guilds: GuildDuck.IState;

		bossKills: BossKillsDuck.IState;
		rosterBosses: RosterBossDuck.IState;
		rosters: RosterDuck.IState;

		cooldownSheets: CooldownSheetDuck.State;
		cooldownEvents: CooldownEventDuck.State;
		cooldownActions: CooldownActionDuck.State;

		droptimizerReports: DroptimizerReportDuck.State;

		raidData: RaidDataDuck.IState;

		demo: DemoDuck.IState;

		settings: SettingsDuck.IState;
		banners: BannerDuck.IState;
		routing: RouterState;
	}

	type Thunk<T> = ThunkAction<Promise<T>, IRootState, void, RootAction>;
	type ThunkCb<T> = ThunkAction<T, IRootState, void, RootAction>;
	type RootDispatch = ThunkDispatch<IRootState, void, RootAction>;

	type BoundThunk<T extends (...args: any[]) => ThunkAction<any, any, any, any>> = (
		...args: Parameters<T>
	) => ReturnType<ReturnType<T>>;
}

export default function createReducer() {
	return optimist(
		combineReducers<IRootState>({
			user: UserDuck.default,
			setup: SetupDuck.default,
			reimport: ReimportDuck.default,

			loot: LootDuck.default,

			tag: TagDuck.default,

			characters: CharacterDuck.default,
			userBilling: UserBillingDuck.default,
			guildSponsorships: GuildSponsorshipDuck.default,
			guilds: GuildDuck.default,

			bossKills: BossKillsDuck.default,
			rosterBosses: RosterBossDuck.default,
			rosters: RosterDuck.default,

			cooldownSheets: CooldownSheetDuck.default,
			cooldownEvents: CooldownEventDuck.default,
			cooldownActions: CooldownActionDuck.default,

			droptimizerReports: DroptimizerReportDuck.default,

			raidData: RaidDataDuck.default,

			demo: DemoDuck.default,

			settings: SettingsDuck.default,
			banners: BannerDuck.default,
			routing: routerReducer
		})
	);
}

type UnboxPromiseToThunk<T> = Thunk<UnboxPromise<T>>;

type MaybeBoundDispatchProps<T extends Record<string, any>> = {
	[K in keyof T]:
		| T[K]
		| ((...args: Parameters<T[K]>) => UnboxPromiseToThunk<ReturnType<T[K]>>);
};

export function connect<
	StateProps extends Record<string, any>,
	DispatchProps extends Record<string, any>,
	OwnProps extends Record<string, any>
>(
	mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, IRootState>,
	dispatchProps: MaybeBoundDispatchProps<DispatchProps>
) {
	const dispatchStuff = (dispatch: RootDispatch) =>
		bindActionCreators(dispatchProps, dispatch);

	return originalConnect(mapStateToProps, dispatchStuff);
}

interface ConnectGenerics {
	Own: Record<string, any>;
	State: Record<string, any>;
	Dispatch: Record<string, any>;
}

export function connect2<Generics extends ConnectGenerics>(
	mapStateToProps: MapStateToPropsParam<Generics['State'], Generics['Own'], IRootState>,
	dispatchProps: MaybeBoundDispatchProps<Generics['Dispatch']>
) {
	const dispatchStuff = (dispatch: RootDispatch) =>
		bindActionCreators(dispatchProps, dispatch);

	return originalConnect(mapStateToProps, dispatchStuff);
}
