import React from 'react';

import type {BossViewType} from '@constants';

import {useCopyToClipboard} from '@hooks/useCopyToClipboard';

import {Button} from '@components/Utils/Button';
import {ActionsMenu, MenuItem} from '@components/Utils/Menu';
import {PageHeadingDetails} from '../../Utils/PageHeading';
import {BossHeadingRow} from '../BossElements';
import {ViewSwitcher} from '../ViewSwitcher';

interface Props {
	onChangeView(view: BossViewType): void;
	onEditNotes(): void;

	isAdmin: boolean;

	viewType: BossViewType;
	bossName: string;
	notes: string | null;
}

export const BossHeading: React.FC<Props> = (props) => {
	const [, copy] = useCopyToClipboard();

	return (
		<BossHeadingRow
			leftColumn={<PageHeadingDetails heading={props.bossName} subheading={null} />}
			rightColumn={
				<>
					{props.isAdmin && (
						<Button onClick={props.onEditNotes} className="medium outline grey">
							Edit notes
						</Button>
					)}

					<ViewSwitcher
						onChangeView={props.onChangeView}
						isAdmin={props.isAdmin}
						viewType={props.viewType}
					/>

					<ActionsMenu>
						{props.isAdmin && (
							<MenuItem onClick={props.onEditNotes}>Edit boss notes</MenuItem>
						)}

						<MenuItem onClick={() => copy(props.notes || '')}>Copy notes</MenuItem>
					</ActionsMenu>
				</>
			}
		/>
	);
};
