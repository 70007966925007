import React from 'react';
import classnames from 'classnames';

import * as wow from '../../constants/wow';

import {makeAvatarUrl, composeImportCharacterCombo} from '../../helpers/toolbox';

import type {Character, ICharacterData} from '../../models/character';
import type {SetupCharacter} from '../../models/setup';

type VariableSetupCharacter = Character | ICharacterData | SetupCharacter;

function checkIfExistingCharacter(data: VariableSetupCharacter): data is Character {
	return !!(data as Character).id;
}

interface IRankGroupProps {
	onMassSelection(rank: number, isAdding: boolean): void;
	onExistingCharacterSelection(data: {id: number}): void;
	onNewCharacterSelection(data: {name: string; realm: string}): void;

	importingCombos: string[];
	importingIds: number[];

	characters: VariableSetupCharacter[];

	isRankEditingDisabled?: boolean;
	rankName?: string;
	rank: number;
}

interface IRankGroupState {
	expanded: boolean;
}

export default class RankGroup extends React.Component<IRankGroupProps, IRankGroupState> {
	state: IRankGroupState;
	input: HTMLInputElement;

	constructor(props: IRankGroupProps) {
		super(props);

		this.state = {
			expanded: props.rank < 6
		};
	}

	getName = () => {
		return this.input.value.trim();
	};

	handleExpand = () => {
		this.setState({expanded: !this.state.expanded});
	};

	render() {
		const expandedClass = classnames(
			{
				'lnr-chevron-right': !this.state.expanded,
				'lnr-chevron-down': this.state.expanded
			},
			'lnr expand-control'
		);

		let selectedCount = 0;
		const characters = this.props.characters.map((char) => {
			const isSelected = checkIfExistingCharacter(char)
				? this.props.importingIds.includes(char.id)
				: this.props.importingCombos.includes(composeImportCharacterCombo(char));

			if (isSelected) selectedCount += 1;

			return (
				<CharacterBox
					key={char.name}
					onExistingCharacterSelection={this.props.onExistingCharacterSelection}
					onNewCharacterSelection={this.props.onNewCharacterSelection}
					isSelected={isSelected}
					character={char}
				/>
			);
		});

		return (
			<div className="rank-group">
				<div className="rank-label">
					<div className="controls">
						{this.props.isRankEditingDisabled && (
							<div className="rank-name view">{this.props.rankName}</div>
						)}

						{!this.props.isRankEditingDisabled && (
							<input
								ref={(r: HTMLInputElement) => (this.input = r)}
								className="rank-name edit medium input"
								placeholder={`Set a name for Rank ${this.props.rank + 1}`}
							/>
						)}

						<i onClick={this.handleExpand} className={expandedClass} />

						<div className="selected-text">
							{selectedCount} of {this.props.characters.length} selected
						</div>
					</div>

					<div className="actions">
						<div
							onClick={() => this.props.onMassSelection(this.props.rank, true)}
							className="add mass-toggle"
						>
							Add all
						</div>

						<div
							onClick={() => this.props.onMassSelection(this.props.rank, false)}
							className="remove mass-toggle"
						>
							Remove all
						</div>
					</div>
				</div>

				{this.state.expanded && <div className="characters">{characters}</div>}
			</div>
		);
	}
}

interface ICharacterBoxProps {
	onExistingCharacterSelection(data: {id: number}): void;
	onNewCharacterSelection(data: {name: string; realm: string}): void;

	character: VariableSetupCharacter;
	isSelected: boolean;
}

function CharacterBox(props: ICharacterBoxProps) {
	const characterClass = classnames(
		{
			selected: props.isSelected
		},
		props.character.class,
		'character wow-style bg-before'
	);

	const clickFn = () => {
		if (checkIfExistingCharacter(props.character)) {
			props.onExistingCharacterSelection({id: props.character.id});
		} else {
			props.onNewCharacterSelection({
				name: props.character.name,
				realm: props.character.realm
			});
		}
	};

	return (
		<div onClick={clickFn} className={characterClass}>
			<img src={makeAvatarUrl(props.character.thumbnail, props.character.region)} />

			<div className="details">
				<div className="name">{props.character.name}</div>

				<div className="secondary">{wow.CLASS_TO_DISPLAY[props.character.class]}</div>
			</div>
		</div>
	);
}
