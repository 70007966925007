import React from 'react';

import type {Props} from '.';
import {BossTile} from './BossTile';

export const BossSelectorComp: React.FC<Props> = (props) => {
	const bossItems = props.bossTiles.map((data) => (
		<BossTile
			key={data.rosterBossId}
			isSelected={props.selectedRosterBossId === data.rosterBossId}
			bossUrl={data.bossUrl}
			bossImageUrl={data.bossImageUrl}
			bossName={data.bossName}
		/>
	));

	return <div className="boss-selector-section">{bossItems}</div>;
};
