import React from 'react';
import {connect} from 'react-redux';

import {demoAPI} from '../../helpers/api';

import * as RaidDataDuck from '../../ducks/raid-data';
import * as GuildDuck from '../../ducks/guild';
import * as DemoDuck from '../../ducks/demo';

import Loading from '../Utils/Loading';
import Support from '../Utils/Support';
import GuildNav from '../Guild/GuildNav';

interface IOwnProps {
	location: {
		pathname: string;

		query: {
			c?: string;
		};
	};
}

interface IDispatchProps {
	fetchRaidData: typeof RaidDataDuck.fetchRaidData;
	fetchDemoData: typeof DemoDuck.fetch;
	onActive: typeof GuildDuck.setActive;
}

interface IMapProps {
	hasFetched: boolean;
	isLoading: boolean;

	activeCharacterQuery: string | undefined;
	activePathname: string;

	basePath: string;
}

type IProps = IDispatchProps & IMapProps;

class DemoContainer extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);

		demoAPI.connect();

		this.handleFetchData();
	}

	componentWillUnmount() {
		demoAPI.disconnect();
	}

	handleFetchData = () => {
		this.props.fetchRaidData(true);
		this.props.fetchDemoData();
		this.props.onActive(undefined, 0);
	};

	render() {
		let content = this.props.children;

		if (this.props.isLoading) {
			content = <Loading isCentered={true} />;
		} else if (this.props.hasFetched) {
			content = (
				<div className="page-container">
					<GuildNav
						isHeaderFixed={false}
						isLoading={false}
						basePath={this.props.basePath}
						activeCharacterQuery={this.props.activeCharacterQuery}
						activePathname={this.props.activePathname}
					/>

					{this.props.children}
				</div>
			);
		}

		return (
			<div className="app">
				<Support />

				{content}
			</div>
		);
	}
}

function getLoading(state: IRootState) {
	return (
		RaidDataDuck.getRaidDataLoading(state.raidData) || DemoDuck.getIsLoading(state.demo)
	);
}

function mapStateToProps(state: IRootState, props: IOwnProps): IMapProps {
	return {
		hasFetched: !!GuildDuck.getGuild(state.guilds, 0),
		isLoading: getLoading(state),

		activeCharacterQuery: props.location.query.c,
		activePathname: props.location.pathname,

		basePath: '/demo'
	};
}

export default connect(mapStateToProps, {
	fetchRaidData: RaidDataDuck.fetchRaidData,
	onActive: GuildDuck.setActive,
	fetchDemoData: DemoDuck.fetch
})(DemoContainer);
