import React from 'react';

import type {Role} from '@constants/wow';

import {getRoleImageNewUrl} from '@helpers/images';

import type {Character} from '@models/character';

import LootImage from '../../../../../../../images/loot_small.png';

import {Image} from '../../../../../../Utils/Image';

interface IMetaIconProps {
	tooltip: string;
	type?: 'info' | 'warning';
}

const MetaIcon: React.FC<IMetaIconProps> = (props) => {
	const type = props.type || 'warning';

	return (
		<div
			onClick={(e) => e.stopPropagation()}
			className={`meta-icon tooltip tooltip-top tooltip-medium delayed type-${type}`}
			data-hint={props.tooltip}
		>
			{props.children}
		</div>
	);
};

interface IProps {
	characterName: string;

	/** Whether the lootsheet should be classes as outdated */
	isLootsheetOutdated: boolean;
	/** The point at which the lootsheet was last updated */
	lootsheetAgeInDays: number;

	/**
	 * If the character is already assigned to the boss as a different role,
	 * what is that role
	 */
	otherAssignedRole: Role | undefined;

	/** Alts of this character that are assigned to this boss */
	otherAssignedAltCharacters: Array<{
		role: Role;
		character: Character;
	}>;

	// XXX absences indicator
}

export const MetaIcons: React.FC<IProps> = (props) => {
	const lootsheetDaysText =
		props.lootsheetAgeInDays === 1 ? '1 day' : `${props.lootsheetAgeInDays} days`;

	const assignedAlt = props.otherAssignedAltCharacters[0];

	return (
		<div className="character-meta-icons">
			{/* {props.isCoining && (
				<MetaIcon
					type="info"
					tooltip={`${props.characterName} is planning on coining this boss`}
				>
					<i className="lnr lnr-circle" />
				</MetaIcon>
			)} */}

			{props.isLootsheetOutdated && (
				<MetaIcon
					tooltip={`${props.characterName} last updated their lootsheet ${lootsheetDaysText} ago`}
				>
					<Image className="loot-outdated" src={LootImage} />
				</MetaIcon>
			)}

			{props.otherAssignedRole && (
				<MetaIcon
					tooltip={`${props.characterName} has already been assigned to ${props.otherAssignedRole}`}
				>
					<Image src={getRoleImageNewUrl(props.otherAssignedRole)} />
				</MetaIcon>
			)}

			{assignedAlt && (
				<MetaIcon
					tooltip={`Alt ${assignedAlt.character.name} has already been assigned to ${assignedAlt.role}`}
				>
					<Image
						src={assignedAlt.character.getAvatarUrl()}
						className="character-thumb"
					/>
				</MetaIcon>
			)}
		</div>
	);
};
