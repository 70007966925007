import React from 'react';

import {connect2, DroptimizerReportDuck, CharacterDuck, UserDuck} from '@ducks';

import {useModal} from '@hooks/useModal';

import type {DroptimizerReport} from '@models/droptimizerReport';
import type {Character} from '@models/character';
import type {Guild} from '@models/guild';

import {Button, ButtonGroup} from '../Utils/Button';
import {Report} from './Report';
import {SubmitReportModal} from './SubmitReportModal';

interface ReportItem {
	canDelete: boolean;
	droptimizerReport: DroptimizerReport;
	character: Character;
}

interface OwnProps {
	guild: Guild;
}

interface MapProps {
	reportItems: ReportItem[];
}

type Props = OwnProps & MapProps;

const DroptimizerReportsComp: React.FC<Props> = (props) => {
	const submitReportModal = useModal();

	return (
		<>
			{submitReportModal.isOpen && (
				<SubmitReportModal onClose={submitReportModal.close} />
			)}

			<div className="droptimizer-list-view">
				<div className="heading">
					<h1 className="big">Droptimizer reports</h1>

					<p>
						Adding a Droptimizer report extends the Loot Sheet interface with
						information about how much of an upgrade each item is.
					</p>

					<ButtonGroup alignment="center">
						<Button onClick={submitReportModal.open} className="primary">
							Submit Droptimizer report
						</Button>
					</ButtonGroup>
				</div>

				<div className="content">
					{props.reportItems.map((reportItem) => (
						<Report
							key={reportItem.droptimizerReport.id}
							canDelete={reportItem.canDelete}
							report={reportItem.droptimizerReport}
							character={reportItem.character}
						/>
					))}
				</div>
			</div>
		</>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): MapProps {
	const user = UserDuck.getUser(state.user);
	const userComboKeys = user?.createComboKeys() || [];

	let isAdmin = false;
	if (user) {
		const characters = CharacterDuck.getAllCharactersForGuild(
			state.characters,
			props.guild.id
		);
		isAdmin = props.guild.isUserAdmin(user, characters);
	}

	const droptimizerReports = DroptimizerReportDuck.getDroptimizerReports(
		state.droptimizerReports
	);

	const reportItems = droptimizerReports.reduce<ReportItem[]>(
		(items, droptimizerReport): ReportItem[] => {
			const character = CharacterDuck.getCharacterForId(
				state.characters,
				droptimizerReport.characterId
			);

			// if there's no character, then ignore this report
			if (!character) return items;

			return [
				...items,

				{
					canDelete: isAdmin || userComboKeys.includes(character.combo),

					droptimizerReport,
					character
				}
			];
		},
		[]
	);

	// show newest reports first
	reportItems.sort(
		(a, b) =>
			b.droptimizerReport.raidbotsReportCreatedAt.getTime() -
			a.droptimizerReport.raidbotsReportCreatedAt.getTime()
	);

	return {
		reportItems
	};
}

export const DroptimizerReportsView = connect2<{
	Own: {};
	State: MapProps;
	Dispatch: {};
}>(
	mapStateToProps,
	{}
)(DroptimizerReportsComp);
