import React from 'react';
import classnames from 'classnames';

import {getSpellIconUrl} from '@helpers/images';

import {Image} from '@components/Utils/Image';

type DynamicAbilityIconProps =
	| {
			type: 'placeholder';
	  }
	| {
			type: 'custom';
	  }
	| {
			type: 'wow-ability';
			iconName: string;
	  };

type AbilityIconProps = {
	className?: string;
} & DynamicAbilityIconProps;

export const AbilityIcon: React.FC<AbilityIconProps> = (props) => {
	const iconClass = classnames(props.type, 'util-ability-icon', props.className);

	let content;
	switch (props.type) {
		case 'wow-ability': {
			content = <Image src={getSpellIconUrl(props.iconName)} />;
			break;
		}

		case 'custom': {
			content = <Image src={getSpellIconUrl('inv_misc_questionmark')} />;
			break;
		}

		case 'placeholder': {
			content = <div className="placeholder" />;
			break;
		}

		default:
			break;
	}

	return <div className={iconClass}>{content}</div>;
};
