import {useBoolean} from './useBoolean';

export function useModal(initialState = false) {
	const bool = useBoolean(initialState);

	return {
		/** Whether the widget is open or closed */
		isOpen: bool.value,

		/** Toggle the widget */
		toggle: bool.toggle,

		/** Open the widget */
		open: bool.setTrue,

		/** Close the widget */
		close: bool.setFalse
	};
}
