import React from 'react';
import classnames from 'classnames';
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda';
import {Link} from 'react-router';
import isEqual from 'lodash/isEqual';

import * as wow from '../../../constants/wow';

import type * as RosterDuck from '../../../ducks/roster';

import type {Roster} from '../../../models/roster';

import * as BillingLink from '../../Utils/BillingLink';
import SelectInput, {ISelectOption} from '../../Utils/SelectInput';
import Switch from '../../Utils/Switch';
import Input from '../../Utils/Input';
import DeleteRoster from './DeleteRoster';

const difficultyOptions = Object.values(wow.Difficulties)
	.reverse()
	.map((difficulty) => ({
		name: wow.DifficultyDisplay[difficulty],
		id: difficulty
	}));

interface ITagSectionProps {
	onAdd(tagId: number): void;
	onRemove(tagId: number): void;

	isDisabled: boolean;

	label: string;
	placeholder: string;
	description: string;
	options: ISelectOption[];
	selectedIds: number[];
}

const TagSection: React.FC<ITagSectionProps> = (props) => {
	const options = props.options.filter((opt) => !props.selectedIds.includes(opt.id));

	const shownTags = props.options.filter((opt) => props.selectedIds.includes(opt.id));
	const shownTagItems = shownTags.map((opt) => (
		<div key={opt.id} className="tag-pill">
			{opt.name}

			<i onClick={() => props.onRemove(opt.id)} className="lnr lnr-cross-circle" />
		</div>
	));

	return (
		<div className="tag-section">
			<SelectInput
				onChange={props.onAdd}
				isDisabled={props.isDisabled}
				label={props.label}
				placeholder={props.placeholder}
				note={props.description}
				items={options}
				size=""
			/>

			{!!shownTagItems.length && <div className="tag-pills">{shownTagItems}</div>}
		</div>
	);
};

interface IDetailsProps {
	onCreate: typeof RosterDuck.createRoster;
	onUpdate: typeof RosterDuck.updateRoster;
	onDelete: typeof RosterDuck.deleteRoster;

	canUseTagging: boolean;

	isHidingInstances: boolean;
	isSubmitting: boolean;
	isDeleting: boolean;

	settingsUrl: string;
	instanceOptions: ISelectOption[];
	tagOptions: ISelectOption[];
	roster?: Roster;
}

interface IDetailsState {
	isShowingDelete: boolean;
	isDisabled: boolean;

	wowInstanceId: string;
	difficulty: string;
	tagWhitelist: number[];
	tagBlacklist: number[];
}

export default class RosterDetailsContent extends React.Component<
	IDetailsProps,
	IDetailsState
> {
	fields: {[key: string]: any} = {};
	state: IDetailsState;

	constructor(props: IDetailsProps) {
		super(props);

		this.state = {
			isDisabled: !props.roster,
			isShowingDelete: false,

			wowInstanceId: props.roster
				? props.roster.wowInstanceId
				: props.instanceOptions[0].id,
			difficulty: props.roster ? props.roster.difficulty : wow.DEFAULT_DIFFICULTY,

			tagWhitelist: props.roster ? props.roster.tagWhitelist : [],
			tagBlacklist: props.roster ? props.roster.tagBlacklist : []
		};
	}

	componentDidMount() {
		this.checkForDisabled();
	}

	componentWillReceiveProps(nextProps: IDetailsProps) {
		const oldId = this.props.roster?.id;
		const newId = nextProps.roster?.id;

		if (oldId !== newId) this.resetView(nextProps.roster);
		else this.checkForDisabled(nextProps.roster);
	}

	checkForDisabledHelper = (roster: Roster | undefined) => {
		const name = this.fields.name.getValue();
		if (!name) return true;

		const {wowInstanceId, difficulty, tagWhitelist, tagBlacklist} = this.state;
		if (!wowInstanceId || !difficulty) return true;

		// if there's no roster then we're all good
		if (!roster) return false;

		const isTagWhitelistEqual = isEqual(roster.tagWhitelist, tagWhitelist);
		const isTagBlacklistEqual = isEqual(roster.tagBlacklist, tagBlacklist);
		// console.log(isTagBlacklistEqual, isTagWhitelistEqual);

		const isDefault = this.fields.default.getValue();
		if (
			isTagWhitelistEqual &&
			isTagBlacklistEqual &&
			name === roster.name &&
			isDefault === roster.isDefault &&
			wowInstanceId === roster.wowInstanceId &&
			difficulty === roster.difficulty
		) {
			return true;
		}

		return false;
	};

	checkForDisabled = (roster: Roster | undefined = this.props.roster) => {
		const isDisabled = this.checkForDisabledHelper(roster);

		if (isDisabled !== this.state.isDisabled) {
			this.setState({isDisabled});
		}
	};

	resetView(roster?: Roster) {
		this.fields.name.setValue(roster ? roster.name : '');
		this.fields.name.focus();

		this.fields.default.setValue(roster ? roster.isDefault : true);

		this.setState(
			{
				isShowingDelete: false,

				wowInstanceId: roster
					? roster.wowInstanceId
					: this.props.instanceOptions[0].id,
				difficulty: roster ? roster.difficulty : wow.DEFAULT_DIFFICULTY,

				tagWhitelist: roster ? roster.tagWhitelist : [],
				tagBlacklist: roster ? roster.tagBlacklist : []
			},
			this.checkForDisabled
		);
	}

	handleInstance = (wowInstanceId: string) => {
		this.setState({wowInstanceId}, this.checkForDisabled);
	};

	handleDifficulty = (difficulty: string) => {
		this.setState({difficulty}, this.checkForDisabled);
	};

	handleWhitelistAdd = (tagId: number) => {
		this.setState(
			{
				tagBlacklist: this.state.tagBlacklist.filter((id) => id !== tagId),
				tagWhitelist: [...this.state.tagWhitelist, tagId]
			},
			this.checkForDisabled
		);
	};

	handleWhitelistRemove = (tagId: number) => {
		this.setState(
			{tagWhitelist: this.state.tagWhitelist.filter((id) => id !== tagId)},
			this.checkForDisabled
		);
	};

	handleBlacklistAdd = (tagId: number) => {
		this.setState(
			{
				tagWhitelist: this.state.tagWhitelist.filter((id) => id !== tagId),
				tagBlacklist: [...this.state.tagBlacklist, tagId]
			},
			this.checkForDisabled
		);
	};

	handleBlacklistRemove = (tagId: number) => {
		this.setState(
			{tagBlacklist: this.state.tagBlacklist.filter((id) => id !== tagId)},
			this.checkForDisabled
		);
	};

	handleSubmit = () => {
		const name = this.fields.name.getValue();
		if (!name) return;

		const data = {
			isDefault: this.fields.default.getValue(),
			difficulty: this.state.difficulty,
			tagWhitelist: this.state.tagWhitelist,
			tagBlacklist: this.state.tagBlacklist,
			name
		};

		if (this.props.roster) {
			this.props.onUpdate({
				...data,
				id: this.props.roster.id
			});
		} else {
			this.props.onCreate({
				...data,
				wowInstanceId: this.state.wowInstanceId
			});
		}
	};

	handleDelete = () => {
		this.props.onDelete(this.props.roster!.id);
	};

	render() {
		const buttonClass = classnames(
			{
				disabled: this.state.isDisabled || this.props.isSubmitting
			},
			'medium faded primary button'
		);

		const hideSubmit = this.props.roster && this.state.isDisabled;

		let note;
		if (this.props.isHidingInstances) {
			note = (
				<span>
					{BillingLink.createMultipleRostersForInstanceLink()}
					<span> to create multiple rosters for the same instance.</span>
				</span>
			);
		}

		return (
			<div className="details-content roster-settings-content">
				{this.state.isShowingDelete && (
					<DeleteRoster
						onClose={() => this.setState({isShowingDelete: false})}
						onDelete={this.handleDelete}
						isDeleting={this.props.isDeleting}
						rosterName={this.props.roster!.name}
					/>
				)}

				<div className="fields">
					<Input
						ref={(r: Input) => (this.fields.name = r)}
						onChange={() => this.checkForDisabled()}
						autoFocus={true}
						label="Roster name"
						placeholder="e.g. Main Raid"
						defaultValue={this.props.roster?.name}
					/>

					<SelectInput
						onChange={this.handleInstance}
						isDisabled={!!this.props.roster}
						label="Instance"
						items={this.props.instanceOptions}
						selectedId={this.state.wowInstanceId}
						size=""
						note={note}
					/>

					<SelectInput
						onChange={this.handleDifficulty}
						label="Difficulty"
						items={difficultyOptions}
						selectedId={this.state.difficulty}
						size=""
					/>

					<Switch
						ref={(r: Switch) => (this.fields.default = r)}
						onChange={() => this.checkForDisabled()}
						label="Make this the default roster"
						note="The default roster should be the one you use for the main raid."
						defaultValue={this.props.roster ? this.props.roster.isDefault : true}
					/>

					<div className="tagging">
						{!this.props.canUseTagging && (
							<div className="tagging-note">
								{BillingLink.createTags()}
								<span> to filter the characters shown in rosters.</span>
							</div>
						)}

						<TagSection
							onAdd={this.handleWhitelistAdd}
							onRemove={this.handleWhitelistRemove}
							isDisabled={!this.props.canUseTagging}
							label="Include characters from these tags"
							placeholder="Add tag..."
							description="This roster will only include characters that have one of these tags"
							options={this.props.tagOptions}
							selectedIds={this.state.tagWhitelist}
						/>

						<TagSection
							onAdd={this.handleBlacklistAdd}
							onRemove={this.handleBlacklistRemove}
							isDisabled={!this.props.canUseTagging}
							label="Exclude characters from these tags"
							placeholder="Add tag..."
							description="This roster will not include characters that have any of these tags"
							options={this.props.tagOptions}
							selectedIds={this.state.tagBlacklist}
						/>
					</div>

					{this.props.roster && (
						<Link
							to={`${this.props.settingsUrl}/roster-bosses/${this.props.roster.id}`}
							className="roster-bosses-link"
						>
							Click here to reorder bosses for this roster
						</Link>
					)}
				</div>

				<div className="button-group">
					{this.props.roster && (
						<div
							onClick={() => this.setState({isShowingDelete: true})}
							className="medium left red outline button"
						>
							Delete roster
						</div>
					)}

					{!hideSubmit && (
						<LaddaButton
							onClick={this.handleSubmit}
							className={buttonClass}
							loading={this.props.isSubmitting}
							data-style={EXPAND_RIGHT}
						>
							{this.props.roster ? 'Save changes' : 'Create roster'}
						</LaddaButton>
					)}
				</div>
			</div>
		);
	}
}
