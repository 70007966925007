import type {Difficulties} from '@constants/wow';

import {BossKillsDuck} from '@ducks';

import type {IEncounterMode} from '@models/boss-kills';

export type BossKillsByCharacterId = Map<CharacterId, IEncounterMode>;

/** Get the kill down for a given encounter/difficulty combo for each character */
export function getBossKillByCharacterIdForEncounterId(
	bossKillState: IRootState['bossKills'],
	difficulty: Difficulties,
	encounterId: WowBossEncounterId
): BossKillsByCharacterId {
	const killDataByCharacterId = BossKillsDuck.getkillDataByCharacterIdForEncounter(
		bossKillState,
		encounterId
	);

	const bossKillByCharacterId: BossKillsByCharacterId = new Map<
		CharacterId,
		IEncounterMode
	>();

	killDataByCharacterId?.forEach((killData, characterId) => {
		const difficultyKillData = killData[difficulty];

		// if there's no data for this difficutly then ignore the character
		if (!difficultyKillData) return;

		bossKillByCharacterId.set(characterId, difficultyKillData);
	});

	return bossKillByCharacterId;
}
