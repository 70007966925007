import React from 'react';
import classnames from 'classnames';

interface IProps {
	onUpdate(limit: number): void;

	isLimited: boolean;

	roleLabel: string;
	assignmentCount: number;
	limit: number;
}

export class RoleLimit extends React.Component<IProps> {
	field: HTMLInputElement | null;

	// ensure the value is always up to date
	componentWillReceiveProps(nextProps: IProps) {
		if (this.field && this.props.limit !== nextProps.limit) {
			this.field.value = String(nextProps.limit);
		}
	}

	handleUpdate = () => {
		if (!this.field) return;

		if (!this.field.value) {
			this.field.value = String(this.props.limit);
			return;
		}

		const value = Number.parseInt(this.field.value, 10);

		// no updated needed
		if (value === this.props.limit) return;

		// reset input back to valid value
		if (Number.isNaN(value)) {
			this.field.value = String(this.props.limit);
			return;
		}

		this.props.onUpdate(value);
	};

	handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.which === 13) this.field?.blur();
	};

	render() {
		return (
			<div className="role-limit">
				<div className="role-limit-control">
					<div className="leading">A maximum of</div>

					<div className={classnames({limited: this.props.isLimited}, 'limit')}>
						<input
							ref={(r) => (this.field = r)}
							type="number"
							onKeyPress={this.handleKey}
							onBlur={this.handleUpdate}
							className="small input hide-input-controls"
							defaultValue={String(this.props.limit)}
						/>
					</div>

					<div className="trailing">
						{this.props.roleLabel} can be assigned to this boss
					</div>

					<div
						data-hint={`If you have a set number of ${this.props.roleLabel} that you intend to bring in for this boss, you may use this to ensure you don't exceed that number`}
						className="help-icon tooltip tooltip-top tooltip-medium"
					>
						<i className="lnr lnr-notification-circle" />
					</div>
				</div>

				{this.props.isLimited && (
					<div className="limited-banner">
						No more {this.props.roleLabel} can be assigned because the limit has
						been reached
					</div>
				)}
			</div>
		);
	}
}
