import React from 'react';
import classnames from 'classnames';

import {getBossImageUrl} from '@helpers/images';

import {PageHeadingRow} from '../Utils/PageHeading';
import {Image} from '@components/Utils/Image';

interface BossViewProps {
	viewName: 'boss-notes-view' | 'boss-roster-view' | 'boss-cooldown-view';
}

export const BossView: React.FC<BossViewProps> = (props) => {
	return <div className={`boss-view-section ${props.viewName}`}>{props.children}</div>;
};

interface BossContentProps {
	isBossy: boolean;
	wowBossId: WowBossId;

	leftColumn: (() => React.ReactElement) | null;
	rightColumn: (() => React.ReactElement) | null;
}

export const BossContent: React.FC<BossContentProps> = (props) => {
	const contentClass = classnames(
		{
			bossy: props.isBossy
		},
		'roster-boss-content'
	);

	return (
		<div className={contentClass}>
			<div className="main-column">
				{props.isBossy ? (
					<Image className="boss-image" src={getBossImageUrl(props.wowBossId)} />
				) : (
					props.leftColumn?.()
				)}
			</div>

			<div className="secondary-column">{props.rightColumn?.()}</div>
		</div>
	);
};

interface BossHeadingRowProps {
	leftColumn: React.ReactElement | null;
	rightColumn: React.ReactElement | null;
}

export const BossHeadingRow: React.FC<BossHeadingRowProps> = (props) => {
	return (
		<PageHeadingRow>
			{props.leftColumn}

			<div className="right-group">{props.rightColumn}</div>
		</PageHeadingRow>
	);
};
