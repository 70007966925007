import React from 'react';

import type {RaidItem} from '@models/raid-data';
import type {DroptimizerItem} from '@models/droptimizerReport';

import {LootItemRow} from './LootItemRow';

interface DesiredItem {
	note: string | null;
	lootOptionName: string;
	raidItem: RaidItem;
	droptimizerItemData: DroptimizerItem | undefined;
}

interface CharacterLootProps {
	onClose(): void;

	wowheadBonus: string;
	selections: DesiredItem[];
}

export const CharacterLoot: React.FC<CharacterLootProps> = (props) => {
	const itemRows = props.selections.map((selection) => (
		<LootItemRow
			key={selection.raidItem.id}
			wowheadBonus={props.wowheadBonus}
			optionName={selection.lootOptionName}
			note={selection.note}
			item={selection.raidItem}
			droptimizerItemData={selection.droptimizerItemData}
		/>
	));

	return <div className="character-loot">{itemRows}</div>;
};
