import React from 'react';
import {Link} from 'react-router';

import {userNewBillingUrl, signOutUrl} from '@helpers/urls';

import {UserDuck} from '@ducks';

import type {User} from '@models/user';

import {Menu, Divider} from '../Utils/Menu';

interface IProps {
	user: User;
}

export const UserNav: React.FC<IProps> = (props) => {
	let menu: Menu | null = null;

	return (
		<div className="user-nav">
			<div onClick={() => menu?.toggleExpanded()} className="toggler link">
				<div className="disabled">{props.user.bnetBattleTag}</div>
				<i className="lnr lnr-chevron-down" />
			</div>

			<Menu ref={(r) => (menu = r)}>
				<a href={signOutUrl()}>Re-sync characters</a>
				<Link to={userNewBillingUrl()}>Billing / Premium perks</Link>

				<Divider />

				<div onClick={() => UserDuck.signOut()}>Sign Out</div>
			</Menu>
		</div>
	);
};
