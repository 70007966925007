// http
export const USER_FETCH = 'user';
export const USER_SIGN_OUT = 'sign-out';
export const STRIPE_WEBHOOKS = 'stripe-webhooks';
export const STRIPE_SUBSCRIBE_SUCCESS = 'stripe/subscribe/success';
export const STRIPE_UPDATE_PAYMENT_SUCCESS = 'stripe/update-payment/success';

// socket
export const USER_BUMP = 'user.bump';
export const USER_UPDATE_ROSTER_VERSION = 'user.update-roster-verion';
export const USER_VIEWED_ROSTER_REDESIGN_HELP = 'user.viewed-roster-redesign-help';

export const DEMO_DATA_FETCH = 'demo.fetch';

export const RAID_DATA_FETCH = 'raid-data.fetch';

export const BILLING_CANCEL_STRIPE = 'billing.cancel-stripe';
export const BILLING_RESTORE_STRIPE = 'billing.restore-stripe';
export const BILLING_SUBSCRIBE_STRIPE = 'billing.subscribe-stripe';
export const BILLING_UPDATE_PAYMENT_STRIPE = 'billing.update-payment-stripe';
export const BILLING_UPDATE_PLAN_STRIPE = 'billing.update-plan-stripe';

export const GUILD_SPONSORSHIP_CREATE = 'guild-sponsorship.create';
export const GUILD_SPONSORSHIP_DELETE = 'guild-sponsorship.delete';

export const SETUP_INITIATE = 'setup.initiate';
export const SETUP_SUBMIT = 'setup.submit';

export const GUILDS_FETCH_ALL = 'guilds.fetch-all';
export const GUILDS_FETCH_DATA = 'guilds.fetch-data';

export const GUILD_RANK_RENAME = 'guilds.rank-rename';
export const GUILD_ADMIN_IMPORTER = 'guilds.admin-importer';
export const GUILD_ADMIN_RANK = 'guilds.admin-rank';
export const GUILD_RANK_AUTO_INVITE = 'guilds.rank-auto-invite';
export const GUILD_ROSTER_ADMIN_ONLY = 'guilds.roster-admin-only';
export const GUILD_LOOTSHEET_ADMIN_ONLY = 'guilds.lootsheet-admin-only';

export const GUILD_USER_ADMIN_ADD = 'guild-user-admin.add';
export const GUILD_USER_ADMIN_REMOVE = 'guild-user-admin.remove';

export const REIMPORT_INITIATE = 'reimport.initiate';
export const REIMPORT_SUBMIT = 'reimport.submit';

export const CHARACTERS_SET_ROLE = 'characters.set-role';
export const CHARACTERS_REVOKE = 'characters.revoke';
export const CHARACTERS_RESTORE = 'characters.restore';
export const CHARACTERS_ADD_EXTERNAL = 'characters.add-external';
export const CHARACTERS_UPDATE_EXTERNAL = 'characters.update-external';

export const ROSTER_CREATE = 'rosters.create';
export const ROSTER_UPDATE = 'rosters.update';
export const ROSTER_DELETE = 'rosters.delete';
export const ROSTER_POST_TO_DISCORD = 'rosters.post-to-discord';

export const ROSTER_CHARACTER = 'rosters.roster-character';
export const ROSTER_UPDATE_LIMIT = 'rosters.update-limit';
export const ROSTER_CLEAR_CHARACTERS = 'rosters.clear-characters';

export const ROSTER_BOSS_COPY_ASSIGNMENTS_TO_BOSS =
	'roster-bosses.copy-assignments-to-bosses';
export const ROSTER_BOSS_CLEAR_CHARACTERS = 'roster-bosses.clear-characters';
export const ROSTER_BOSS_REORDER = 'roster-bosses.reorder';
export const ROSTER_BOSS_NOTES = 'roster-bosses.notes';

export const LOOT_RCLC = 'loot.rclc';
export const LOOT_SELECTION = 'loot.selection';
export const LOOT_NOTE = 'loot.note';
export const LOOT_COIN = 'loot.coin';
export const LOOT_BUMP = 'loot.bump';

export const LOOT_OPTION_CREATE = 'loot-option.create';
export const LOOT_OPTION_UPDATE = 'loot-option.update';
export const LOOT_OPTION_DELETE = 'loot-option.delete';
export const LOOT_OPTION_REORDER = 'loot-option.reorder';

export const TAG_ASSIGNMENT = 'tag.assignment';

export const TAG_CREATE = 'tag.create';
export const TAG_UPDATE = 'tag.update';
export const TAG_DELETE = 'tag.delete';
export const TAG_REORDER = 'tag.reorder';

export const BOSS_KILLS_FETCH = 'boss-kills.fetch';

export const COOLDOWN_SHEET_CREATE_FOR_BOSS = 'cooldown-sheet.create-for-boss';
export const COOLDOWN_SHEET_FETCH_FOR_BOSS = 'cooldown-sheet.fetch-for-boss';
export const COOLDOWN_EVENT_CREATE = 'cooldown-event.create';
export const COOLDOWN_EVENT_DELETE = 'cooldown-event.delete';
export const COOLDOWN_EVENT_REORDER = 'cooldown-event.reorder';
export const COOLDOWN_EVENT_UPDATE_NAME = 'cooldown-event.update-name';
export const COOLDOWN_EVENT_UPDATE_TIME = 'cooldown-event.update-time';
export const COOLDOWN_ACTION_CREATE = 'cooldown-action.create';
export const COOLDOWN_ACTION_UPDATE_ACTOR = 'cooldown-action.update-actor';
export const COOLDOWN_ACTION_UPDATE_ABILITY = 'cooldown-action.update-ability';
export const COOLDOWN_ACTION_DELETE = 'cooldown-action.delete';

export const DROPTIMIZER_SUBMIT = 'droptimizer.submit';
export const DROPTIMIZER_DELETE = 'droptimizer.delete';
