import type {Character} from '../../models/character';
import type {LootSelection} from '../../models/loot-selection';
import type {LootOption} from '../../models/loot-option';
import type {LootCoin} from '../../models/loot-coin';
import type {RosterRoleAssignment} from '../../models/roster-role-assignment';
import type {RosterBoss} from '../../models/roster-boss';
import type {Roster} from '../../models/roster';
import type {GuildSponsorship} from '@models/guildSponsorship';
import type {Guild} from '../../models/guild';
import type {TagAssignment} from '../../models/tag-assignment';
import type {Tag} from '../../models/tag';
import type {DroptimizerReport} from '../../models/droptimizerReport';

export interface IState {
	readonly isLoadingData: boolean;
	readonly isLoadingList: boolean;
	readonly activeId?: number;

	readonly isRenamingRank: boolean;

	readonly byId: {
		[key: number]: Guild;
		[key: string]: Guild;
	};
}

// types
export const FETCH = 'guild/FETCH';
export const FETCH_SUCCESS = 'guild/FETCH_SUCCESS';
export const FETCH_FAILURE = 'guild/FETCH_FAILURE';

export const FETCH_DATA = 'guild/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'guild/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'guild/FETCH_DATA_FAILURE';

export const SET_ACTIVE = 'guild/SET_ACTIVE';

export const RANK_RENAME = 'guild/RANK_RENAME';
export const RANK_RENAME_SUCCESS = 'guild/RANK_RENAME_SUCCESS';
export const RANK_RENAME_FAILURE = 'guild/RANK_RENAME_FAILURE';

export const ADMIN_RANK = 'guild/ADMIN_RANK';
export const ADMIN_RANK_SUCCESS = 'guild/ADMIN_RANK_SUCCESS';
export const ADMIN_RANK_FAILURE = 'guild/ADMIN_RANK_FAILURE';

export const AUTO_INVITE_RANK = 'guild/AUTO_INVITE_RANK';
export const AUTO_INVITE_RANK_SUCCESS = 'guild/AUTO_INVITE_RANK_SUCCESS';
export const AUTO_INVITE_RANK_FAILURE = 'guild/AUTO_INVITE_RANK_FAILURE';

export const USER_ADMIN_ADD = 'guild/USER_ADMIN_ADD';
export const USER_ADMIN_ADD_SUCCESS = 'guild/USER_ADMIN_ADD_SUCCESS';
export const USER_ADMIN_ADD_FAILURE = 'guild/USER_ADMIN_ADD_FAILURE';

export const USER_ADMIN_REMOVE = 'guild/USER_ADMIN_REMOVE';
export const USER_ADMIN_REMOVE_SUCCESS = 'guild/USER_ADMIN_REMOVE_SUCCESS';
export const USER_ADMIN_REMOVE_FAILURE = 'guild/USER_ADMIN_REMOVE_FAILURE';

export const ADMIN_IMPORTER = 'guild/ADMIN_IMPORTER';
export const ADMIN_IMPORTER_SUCCESS = 'guild/ADMIN_IMPORTER_SUCCESS';
export const ADMIN_IMPORTER_FAILURE = 'guild/ADMIN_IMPORTER_FAILURE';

export const ROSTER_ADMIN_ONLY = 'guild/ROSTER_ADMIN_ONLY';
export const ROSTER_ADMIN_ONLY_SUCCESS = 'guild/ROSTER_ADMIN_ONLY_SUCCESS';
export const ROSTER_ADMIN_ONLY_FAILURE = 'guild/ROSTER_ADMIN_ONLY_FAILURE';

export const LOOTSHEET_ADMIN_ONLY = 'guild/LOOTSHEET_ADMIN_ONLY';
export const LOOTSHEET_ADMIN_ONLY_SUCCESS = 'guild/LOOTSHEET_ADMIN_ONLY_SUCCESS';
export const LOOTSHEET_ADMIN_ONLY_FAILURE = 'guild/LOOTSHEET_ADMIN_ONLY_FAILURE';

export interface IActions {
	SET_ACTIVE: {
		readonly type: typeof SET_ACTIVE;
		readonly payload: {
			id?: number;
		};
	};

	FETCH: {readonly type: typeof FETCH};
	FETCH_FAILURE: {readonly type: typeof FETCH_FAILURE};
	FETCH_SUCCESS: {
		readonly type: typeof FETCH_SUCCESS;
		readonly payload: {
			guilds: Guild[];
		};
	};

	FETCH_DATA: {readonly type: typeof FETCH_DATA};
	FETCH_DATA_FAILURE: {readonly type: typeof FETCH_DATA_FAILURE};
	FETCH_DATA_SUCCESS: {
		readonly type: typeof FETCH_DATA_SUCCESS;
		readonly payload: {
			characters: Character[];

			guildSponsorships: GuildSponsorship[];

			lootSelections: LootSelection[];
			lootOptions: LootOption[];
			lootCoins: LootCoin[];

			tags: Tag[];
			tagAssignments: TagAssignment[];

			rosterRoleAssignments: RosterRoleAssignment[];
			rosterBosses: RosterBoss[];
			rosters: Roster[];

			droptimizerReports: DroptimizerReport[];
		};
	};

	RANK_RENAME: {readonly type: typeof RANK_RENAME};
	RANK_RENAME_SUCCESS: {readonly type: typeof RANK_RENAME_SUCCESS};
	RANK_RENAME_FAILURE: {readonly type: typeof RANK_RENAME_FAILURE};

	AUTO_INVITE_RANK: {
		readonly type: typeof AUTO_INVITE_RANK;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly guild: Guild;
		};
	};
	AUTO_INVITE_RANK_SUCCESS: {
		readonly type: typeof AUTO_INVITE_RANK_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	AUTO_INVITE_RANK_FAILURE: {
		readonly type: typeof AUTO_INVITE_RANK_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	ADMIN_RANK: {
		readonly type: typeof ADMIN_RANK;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly guild: Guild;
		};
	};
	ADMIN_RANK_SUCCESS: {
		readonly type: typeof ADMIN_RANK_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	ADMIN_RANK_FAILURE: {
		readonly type: typeof ADMIN_RANK_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	USER_ADMIN_ADD: {
		readonly type: typeof USER_ADMIN_ADD;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly guild: Guild;
		};
	};
	USER_ADMIN_ADD_SUCCESS: {
		readonly type: typeof USER_ADMIN_ADD_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	USER_ADMIN_ADD_FAILURE: {
		readonly type: typeof USER_ADMIN_ADD_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	USER_ADMIN_REMOVE: {
		readonly type: typeof USER_ADMIN_REMOVE;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly guild: Guild;
		};
	};
	USER_ADMIN_REMOVE_SUCCESS: {
		readonly type: typeof USER_ADMIN_REMOVE_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	USER_ADMIN_REMOVE_FAILURE: {
		readonly type: typeof USER_ADMIN_REMOVE_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	ADMIN_IMPORTER: {
		readonly type: typeof ADMIN_IMPORTER;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly guild: Guild;
		};
	};
	ADMIN_IMPORTER_SUCCESS: {
		readonly type: typeof ADMIN_IMPORTER_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	ADMIN_IMPORTER_FAILURE: {
		readonly type: typeof ADMIN_IMPORTER_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	ROSTER_ADMIN_ONLY: {
		readonly type: typeof ROSTER_ADMIN_ONLY;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly guild: Guild;
		};
	};
	ROSTER_ADMIN_ONLY_SUCCESS: {
		readonly type: typeof ROSTER_ADMIN_ONLY_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	ROSTER_ADMIN_ONLY_FAILURE: {
		readonly type: typeof ROSTER_ADMIN_ONLY_FAILURE;
		readonly optimist: Optimist.IRevert;
	};

	LOOTSHEET_ADMIN_ONLY: {
		readonly type: typeof LOOTSHEET_ADMIN_ONLY;
		readonly optimist: Optimist.IBegin;
		readonly payload: {
			readonly guild: Guild;
		};
	};
	LOOTSHEET_ADMIN_ONLY_SUCCESS: {
		readonly type: typeof LOOTSHEET_ADMIN_ONLY_SUCCESS;
		readonly optimist: Optimist.ICommit;
	};
	LOOTSHEET_ADMIN_ONLY_FAILURE: {
		readonly type: typeof LOOTSHEET_ADMIN_ONLY_FAILURE;
		readonly optimist: Optimist.IRevert;
	};
}
