import React from 'react';
import classnames from 'classnames';

interface Props {
	onToggle(): void;

	isActive: boolean;

	tooltipOn: string;
	tooltipOff: string;
}

export const Toggle: React.FC<Props> = (props) => {
	const toggleClass = classnames(
		{
			'tooltip-medium': !props.isActive,
			active: props.isActive
		},
		'toggle tooltip tooltip-left'
	);

	return (
		<div
			onClick={props.onToggle}
			className={toggleClass}
			data-hint={props.isActive ? props.tooltipOn : props.tooltipOff}
		>
			{props.children}
		</div>
	);
};
