import React from 'react';

import {Button, ButtonGroup} from '../../Utils/Button';

interface IProps {
	onSubmit(): void;
	onCancel: (() => void) | undefined;

	isSubmitting: boolean;
}

export const SubmitControls: React.FC<IProps> = (props) => {
	return (
		<ButtonGroup alignment="center">
			{props.onCancel && (
				<Button onClick={props.onCancel} className="small underlined link">
					Cancel
				</Button>
			)}

			<Button
				onClick={props.onSubmit}
				isLoading={props.isSubmitting}
				className="large primary"
			>
				Submit
			</Button>
		</ButtonGroup>
	);
};
