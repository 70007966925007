import React from 'react';
import classnames from 'classnames';

import * as wow from '../../constants/wow';

import * as images from '../../helpers/images';

import type {Character} from '../../models/character';

import TimeAgo from '../Utils/TimeAgo';

interface IProps {
	onRoster(role: string, charId: number, isActive: boolean): void;
	onSearch(id: number): void;

	isShowingX: boolean;

	roleUpdatedAt: {[key: string]: number};
	roleAssignments: {[key: string]: number[]};
	characterById: Map<number, Character>;
}

export default function CurrentAssignments(props: IProps) {
	const groups = wow.ROLES_ORDERED.map((role) => {
		const assignments: JSX.Element[] = [];

		props.roleAssignments[role].forEach((charId) => {
			const char = props.characterById.get(charId);
			if (!char) return;

			assignments.push(
				<AssignmentCharacterBox
					key={char.id}
					onClickX={(id: number) => props.onRoster(role, id, false)}
					onClick={props.onSearch}
					isShowingX={props.isShowingX}
					character={char}
				/>
			);
		});

		const imageWrapperClass = classnames(
			{
				tooltip: !!props.roleUpdatedAt[role]
			},
			'tooltip-top role-icon-wrapper'
		);

		return (
			<div key={role} className="role">
				<div
					className={imageWrapperClass}
					data-hint={`Updated ${TimeAgo.getDate(props.roleUpdatedAt[role])}`}
				>
					<img src={images.getRoleImageUrl(role)} />
				</div>

				<div className="content">
					{!!assignments.length && <div className="assignments">{assignments}</div>}

					{!assignments.length && (
						<div className="no-one-rostered">No {role} are currently rostered</div>
					)}
				</div>
			</div>
		);
	});

	return <div className="current-assignments">{groups}</div>;
}

interface ICharacterBoxProps {
	onClickX(id: number): void;
	onClick(id: number): void;

	isShowingX: boolean;
	character: Character;
}

function AssignmentCharacterBox(props: ICharacterBoxProps) {
	const characterClass = classnames(
		{
			admin: props.isShowingX
		},
		props.character.class,
		'character-box',
		'wow-style bg-before'
	);

	const onRemoveFn = (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		props.onClickX(props.character.id);
	};

	return (
		<div onClick={() => props.onClick(props.character.id)} className={characterClass}>
			{props.character.name}

			{props.isShowingX && (
				<div onClick={onRemoveFn} className="remove-character">
					<i className="lnr lnr-cross" />
				</div>
			)}
		</div>
	);
}
