import React from 'react';

import {connect2, CooldownEventDuck, CooldownActionDuck, CharacterDuck} from '@ducks';

import {useBoolean} from '@hooks/useBoolean';

import {formatEventGroups} from './mrt';

import type {CooldownSheetId} from '@models/cooldownSheet';
import type {CooldownAction} from '@models/cooldownAction';
import type {CooldownEvent} from '@models/cooldownEvent';
import type {Character} from '@models/character';

import {SwitchField} from '@components/Utils/Switch';
import {Modal} from '@components/Utils/Modal';

interface EventAction {
	action: CooldownAction;
	character: Character | undefined;
}

interface EventGroup {
	event: CooldownEvent;
	actions: EventAction[];
}

interface OwnProps {
	onClose(): void;

	cooldownSheetId: CooldownSheetId;
}

interface StateProps {
	eventGroups: EventGroup[];
}

type Props = OwnProps & StateProps;

const ExportMrtComp: React.FC<Props> = (props) => {
	const combineActions = useBoolean(false);
	const hideAbilityName = useBoolean(false);
	const skipEmptyEvents = useBoolean(false);

	return (
		<Modal onClose={props.onClose}>
			<div className="modal-content cooldowns-export-mrt-modal">
				<div className="heading">MRT export</div>

				<div className="inputs">
					<SwitchField
						onChange={(x) => combineActions.setValue(x)}
						withTopMargin={true}
						isControlled={true}
						value={combineActions.value}
						label="Combine multiple actions for an actor together"
					/>

					<SwitchField
						onChange={(x) => hideAbilityName.setValue(x)}
						withTopMargin={true}
						isControlled={true}
						value={hideAbilityName.value}
						label="Hide the names of abilities"
					/>

					<SwitchField
						onChange={(x) => skipEmptyEvents.setValue(x)}
						withTopMargin={true}
						isControlled={true}
						value={skipEmptyEvents.value}
						label="Hide events which have no actions"
					/>

					<textarea
						style={{marginTop: '2rem'}}
						className="input"
						value={formatEventGroups(props.eventGroups, {
							withCombinedActions: combineActions.value,
							withHiddenAbilityName: hideAbilityName.value,
							withoutEmptyEvents: skipEmptyEvents.value
						})}
						autoFocus={true}
						readOnly={true}
					/>
				</div>
			</div>
		</Modal>
	);
};

function mapStateToProps(state: IRootState, props: OwnProps): StateProps {
	const events = CooldownEventDuck.getCooldownEventsForCooldownSheetId(
		state.cooldownEvents,
		props.cooldownSheetId
	);

	const eventGroups = events.map((event): EventGroup => {
		const actions = CooldownActionDuck.getCooldownActionsForCooldownEventId(
			state.cooldownActions,
			event.id
		);

		const eventActions = actions.map((action): EventAction => {
			return {
				action,
				character:
					(action.actorCharacterId &&
						CharacterDuck.getCharacterForId(
							state.characters,
							action.actorCharacterId
						)) ||
					undefined
			};
		});

		return {
			event,
			actions: eventActions
		};
	});

	return {
		eventGroups
	};
}

export const ExportMrt = connect2<{Own: OwnProps; State: StateProps; Dispatch: {}}>(
	mapStateToProps,
	{}
)(ExportMrtComp);
