import React from 'react';
import classnames from 'classnames';
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda';

import type * as GuildDuck from '../../../ducks/guild';

import Input from '../../Utils/Input';

interface IDetailsProps {
	onUpdate: typeof GuildDuck.renameRank;

	isSubmitting: boolean;

	rankName: string;
	index: number;
}

interface IDetailsState {
	isDisabled: boolean;
}

export default class RosterDetailsContent extends React.Component<
	IDetailsProps,
	IDetailsState
> {
	fields: {[key: string]: any} = {};
	state: IDetailsState;

	constructor(props: IDetailsProps) {
		super(props);

		this.state = {
			isDisabled: !props.rankName
		};
	}

	componentDidMount() {
		this.checkForDisabled();
	}

	componentWillReceiveProps(nextProps: IDetailsProps) {
		const oldId = this.props.index;
		const newId = nextProps.index;

		if (oldId !== newId) this.resetView(nextProps.rankName);
		else this.checkForDisabled(nextProps.rankName);
	}

	checkForDisabledHelper = (rankName: string) => {
		const name = this.fields.name.getValue();
		if (!name) return true;

		if (name === rankName) return true;

		return false;
	};

	checkForDisabled = (rankName: string = this.props.rankName) => {
		const isDisabled = this.checkForDisabledHelper(rankName);

		if (isDisabled !== this.state.isDisabled) {
			this.setState({isDisabled});
		}
	};

	resetView(rankName = '') {
		this.fields.name.setValue(rankName);
		this.fields.name.focus();

		this.checkForDisabled(rankName);
	}

	handleSubmit = () => {
		const name = this.fields.name.getValue();
		if (!name) return;

		const data = {
			index: this.props.index,
			name
		};

		this.props.onUpdate(data);
	};

	render() {
		const buttonClass = classnames(
			{
				disabled: this.state.isDisabled || this.props.isSubmitting
			},
			'medium faded primary button'
		);

		const hideSubmit = this.state.isDisabled;

		return (
			<div className="details-content">
				<div className="help-block">
					<p>
						<span>Unfortunately </span>
						<span className="brand-name">Readycheck</span>
						<span>
							{' '}
							isn't able to access the names of the ranks within the guild, so
							please give
						</span>
						<span> them names to make it easier to distinguish between them.</span>
					</p>
				</div>

				<div className="fields">
					<Input
						ref={(r: Input) => (this.fields.name = r)}
						onChange={() => this.checkForDisabled()}
						autoFocus={true}
						label="Rank name"
						placeholder="e.g. SUPREME COMMANDER"
						defaultValue={this.props.rankName}
					/>
				</div>

				<div className="button-group">
					{!hideSubmit && (
						<LaddaButton
							onClick={this.handleSubmit}
							className={buttonClass}
							loading={this.props.isSubmitting}
							data-style={EXPAND_RIGHT}
						>
							Save changes
						</LaddaButton>
					)}
				</div>
			</div>
		);
	}
}
