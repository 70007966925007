import React from 'react';
import classnames from 'classnames';

import {Role, ORDERED_ROLES} from '@constants/wow';

import {getRoleImageNewUrl} from '@helpers/images';

import {Image} from '../../../../Utils/Image';

interface TabProps {
	onSelect(): void;
	isSelected: boolean;
	roleImageUrl: Role;
}

const Tab: React.FC<TabProps> = (props) => {
	const tabClass = classnames(
		{
			active: props.isSelected
		},
		'tab'
	);

	return (
		<div onClick={props.onSelect} className={tabClass}>
			<Image src={props.roleImageUrl} />
		</div>
	);
};

interface TabsProps {
	onSelectRole(role: Role): void;
	selectedRole: Role;
}

export const Tabs: React.FC<TabsProps> = (props) => {
	const tabItems = ORDERED_ROLES.map((role) => (
		<Tab
			key={role}
			onSelect={() => props.onSelectRole(role)}
			isSelected={role === props.selectedRole}
			roleImageUrl={getRoleImageNewUrl(role)}
		/>
	));

	return <div className="tabs">{tabItems}</div>;
};
